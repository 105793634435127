import { IconButton, IconButtonProps, SxProps, Theme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

interface IExpandButtonProps extends IconButtonProps {
  expanded: boolean;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

type ExpandButtonToggleType = (initialState: boolean) => {
  expanded: boolean;
  toggleExpandButton: () => void;
};
export const ExpandButton: React.FC<IExpandButtonProps> & {
  useToggle: ExpandButtonToggleType;
} = ({ expanded, onClick, sx, iconSx, ...others }) => {
  return (
    <IconButton sx={sx} onClick={onClick} {...others}>
      {expanded ? <ExpandLessIcon sx={iconSx} /> : <ExpandMoreIcon sx={iconSx} />}
    </IconButton>
  );
};

ExpandButton.useToggle = (initialState: boolean) => {
  const [expanded, setExpanded] = useState(initialState);
  const toggleExpandButton = () => {
    setExpanded(!expanded);
  };

  return { expanded, toggleExpandButton };
};
