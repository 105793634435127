import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductBoardInfoType,
	BaseInfoType,
	DisplayerInfoType,
	KioskInfoType,
	PosInfoType,
	WaiverInfoType,
} from '@kinderlabs-pos/shared-data-type';
import qs from 'qs';

export const canDeleteStoreInfoAsync =
	(rootUrl: string) =>
	async ({ storeId }: DeleteStoreInfoRequestType) =>
		(
			await posAxiosClient.get<CanDeleteResponsType>(`${rootUrl}/store/can-delete`, {
				params: { storeId: storeId },
			})
		).data;

export type DeleteStoreInfoRequestType = { storeId: number };
export type DevieceType = 'POS' | 'WAIVER' | 'DISPLAYER' | 'KIOSK' | 'KIOSK-BOARD' | 'POS-BOARD';

export type CanDeleteResponsType = {
	[storeId: number]: {
		posBoardInfos: (Omit<AdminProductBoardInfoType, 'storeId'> &
			BaseInfoType & { type: DevieceType; storeInfoId: number })[];
		kioskBoardInfos: (Omit<AdminProductBoardInfoType, 'storeId'> &
			BaseInfoType & { type: DevieceType; storeInfoId: number })[];
		posInfos: (Pick<PosInfoType, 'id' | 'name' | 'posType'> &
			BaseInfoType & { type: DevieceType; storeInfoId: number })[];
		waiverInfos: (WaiverInfoType & BaseInfoType & { type: DevieceType })[];
		displayerInfos: (DisplayerInfoType & BaseInfoType & { type: DevieceType })[];
		kioskInfos: (KioskInfoType & BaseInfoType & { type: DevieceType })[];
	};
};
