import { rootUrl } from '../../const';
import { createMutInfoAsync } from './createMutInfo';
import { deleteMutInfoListAsync } from './deleteMutInfoListAsync';
import { getMutInfoDeatilAsync } from './getMutInfoDetailAsync';
import { getMutInfoHistoryAsync } from './getMutInfoHistoryAsync';
import { getMutInfoListAsync} from './getMutInfoListAsync';
import { getMutInfoListInStoreAsync } from './getMutInfoListInStoreAsync';
import { updateMutInfoAsync } from './updateMutInfoAsync';

export const AdminMutInfoApis = {
  getList : getMutInfoListAsync(rootUrl),
  getListInStore: getMutInfoListInStoreAsync(rootUrl),
  getListDetail : getMutInfoDeatilAsync(rootUrl),
  getListHistory : getMutInfoHistoryAsync(rootUrl),
  createInfo : createMutInfoAsync(rootUrl),
  deleteInfo : deleteMutInfoListAsync(rootUrl),
  updateInfo : updateMutInfoAsync(rootUrl)
};
