const yellow = '#FCC24F';
const pink = '#EB5C89';
const lightPink = '#FF5C89';
const deepMint = '#4FAAA8';
const mint = '#5CBFBD';
const greenMint = '#3EB489';
const paleGray = '#F8F8FF';
const lightGray = '#D8D8E2';
const darkGray = '#1F2026';
const gray = '#9191A5';
const navy = '#282B5F';
const gold = '#F7A500';
const registerBackground = 'linear-gradient(180deg, #92FAF8 0%, #5CBFBD 100%)';
const excelGreen = '#1B7345';

export const vaunceColors = {
	yellow: yellow,
	pink: pink,
	lightPink: lightPink,
	deepMint: deepMint,
	greenMint: greenMint,
	mint: mint,
	paleGray: paleGray,
	lightGray: lightGray,
	darkGray: darkGray,
	gray: gray,
	navy: navy,
	gold: gold,
	registerBackground: registerBackground,
	excelGreen: excelGreen,
};
