import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AgeType,
	DdiziInfoType,
	GuestMemberStatisticsFilterType,
	GuestVisitByStatisticsType,
	GuestVisitDateType,
	TierType,
	VisitUserGendersType,
	VisitorStayTimeByDateResultDTO,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { StatisticsApis } from '.';
import { rootUrl } from '../../const';
import {
	GuestmemberStatisticsResponseType,
	매장고객대기현황Type,
	매장별고객방문매출요약Type,
} from './SalesStatistics';

type GuestVisitByTimeStatisticsType = {
	storeName: string;
	inAndOut: {
		time: string;
		in: number;
		out: number;
	}[];
};

const get이용객입퇴장Statistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		ddiziType,
		from,
		to,
		dateType,
	}: {
		storeIdList?: number[];
		ddiziType?: DdiziInfoType['ddiziType'];
		from: Dayjs;
		to: Dayjs;
		dateType: GuestVisitDateType;
	}) =>
		(
			await posAxiosClient.get<GuestVisitByStatisticsType[]>(
				`${rootUrl}/statistics/visitor/lambda`,
				{
					params: {
						storeIdList,
						ddiziType,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						fromTime: StatisticsApis.salesStatisticsStartTime,
						toTime: StatisticsApis.salesStatisticsEndTime,
						dateType: dateType,
					},
				}
			)
		).data;

const get일별체류시간Statistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		ddiziType,
		from,
		to,
	}: {
		storeIdList?: number[];
		ddiziType?: DdiziInfoType['ddiziType'];
		from: Dayjs;
		to: Dayjs;
	}) =>
		(
			await posAxiosClient.get<VisitorStayTimeByDateResultDTO[]>(
				`${rootUrl}/statistics/visitor/lambda/stay-time/date`,
				{
					params: {
						storeIdList,
						ddiziType: ddiziType ?? undefined,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get매장고객대기현황Statistics =
	(rootUrl: string) =>
	async ({ storeIdList, date }: { storeIdList?: number[]; date: Dayjs }) =>
		(
			await posAxiosClient.get<매장고객대기현황Type[]>(
				`${rootUrl}/report-bff/monitoring/visitor-revenue`,
				{
					params: {
						storeIdList,
						date: date.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get매장별고객방문매출요약Statistics =
	(rootUrl: string) =>
	async ({ storeIdList, from, to }: { storeIdList?: number[]; from: Dayjs; to: Dayjs }) =>
		(
			await posAxiosClient.get<매장별고객방문매출요약Type[]>(
				`${rootUrl}/report-bff/summary/visitor-revenue`,
				{
					params: {
						storeIdList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get시간대별이용객입퇴장Statistics =
	(rootUrl: string) =>
	async ({ storeIdList, from, to }: { storeIdList?: number[]; from: Dayjs; to: Dayjs }) =>
		(
			await posAxiosClient.get<GuestVisitByTimeStatisticsType[]>(
				`${rootUrl}/statistics/visitor/time`,
				{
					params: {
						storeIdList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get고객통계조회Statistics =
	(rootUrl: string) =>
	async (data: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		ageGroupList?: AgeType[];
		gender?: VisitUserGendersType;
		tierList?: TierType[];
		indicator: GuestMemberStatisticsFilterType['indicator'];
	}) =>
		(
			await posAxiosClient.get<GuestmemberStatisticsResponseType>(
				`${rootUrl}/statistics/guest/indicator`,
				{
					params: {
						...data,
					},
				}
			)
		).data;

export const GuestVisitStatisticsApis = {
	get매장별고객방문매출요약: get매장별고객방문매출요약Statistics(rootUrl),
	get시간대별이용객입퇴장: get시간대별이용객입퇴장Statistics(rootUrl),
	get이용객입퇴장: get이용객입퇴장Statistics(rootUrl),
	get매장고객대기현황: get매장고객대기현황Statistics(rootUrl),
	get일별체류시간: get일별체류시간Statistics(rootUrl),
	get고객통계: get고객통계조회Statistics(rootUrl),
};
