import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { rootUrl } from '../const';

type ApiBody<T> = {
	code: number;
	message: string;
	body: T;
};

// 아주 임시로 활용되는 것이라 크게 신경 안써도 됨
type ResponseType = {
	sunbun: number;
	memberInfo: { name: string };
};
const registerAsync =
	(rootUrl: string) =>
	async ({ vaunceRegisterId, storeId }: { vaunceRegisterId: string; storeId: number }) =>
		(
			await posAxiosClient.post<ApiBody<ResponseType>>(`${rootUrl}/mobile-waiver/register`, {
				vaunceRegisterId,
				storeId,
			})
		).data;

export const MobileWaiverAPI = {
	register: registerAsync(rootUrl),
};
