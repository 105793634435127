import {
	CouponStatusLabel,
	VaunceMemberInfoType,
	isCouponUsable,
} from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import { DialogContent, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

interface IMemberInfoDialogProps extends XlDialogProps {
	vaunceMemberInfo: VaunceMemberInfoType;
	visitCount?: number;
}
export const MemberInfoDialog = ({
	open,
	closeDialog,
	visitCount,
	vaunceMemberInfo,
}: IMemberInfoDialogProps) => {
	const vaunceMemberInfoData = useMemo(() => {
		return [
			{
				label: '이름',
				value: vaunceMemberInfo.username,
			},
			{
				label: 'ID',
				value:
					vaunceMemberInfo.providerType === 'LOCAL'
						? vaunceMemberInfo.userId
						: vaunceMemberInfo.providerType,
			},
			{
				label: '등급',
				value: vaunceMemberInfo.vaunceMemberGrade,
			},
			{
				label: '전화번호',
				value: vaunceMemberInfo.telephone
					? getTelephoneNumberWithDash(vaunceMemberInfo.telephone)
					: '-',
			},
			{
				label: '보유 포인트',
				value: vaunceMemberInfo.remainingPoint,
			},
		];
	}, [vaunceMemberInfo]);

	if (visitCount) {
		vaunceMemberInfoData.push({ label: '방문 횟수', value: `${visitCount} 회` });
	}

	return (
		<WithDialog.XlDialog
			open={open}
			dialogTitle={'회원 정보'}
			closeDialogAction={'닫기'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 428 }}>
				<Stack spacing={1}>
					<InfoPrintStackTitle title={`회원 정보`} />
					{vaunceMemberInfoData.map((d, idx) => (
						<InfoPrintStackRow
							key={idx}
							label={d.label}
							value={d.value}
						/>
					))}
					<InfoPrintStackTitle title={`쿠폰 보유 목록`} />
					{vaunceMemberInfo.couponInfoList.map((couponInfo, idx) => (
						<InfoPrintStackRow
							key={idx}
							label={couponInfo.couponName}
							value={
								<Typography
									component='span'
									color={isCouponUsable[couponInfo.couponStatus] ? 'priamry' : 'error'}>
									{CouponStatusLabel[couponInfo.couponStatus]}
								</Typography>
							}
						/>
					))}
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
