import {
	CartLineKlPosDiscountInfoType,
	CartLineKlPosProductInfoType,
	CartLineOnlineTicketOnoffmixInfoType,
	CartLineTicketTypeArray,
	CartLineTicketUsageInfoType,
	CartLineType,
	CartLineTypeRules,
	OrderDetailType,
} from '@kinderlabs-pos/shared-data-type';
import { Divider, List, ListItem, Stack } from '@mui/material';
import { AppTicketsDisplay } from 'apps/pos-main/src/components/AppTicketsDisplay';
import { KLCouponDisplay } from 'apps/pos-main/src/components/KLCouponDisplay';
import { OnlineTicketsDisplay } from 'apps/pos-main/src/components/OnlineTIcketsDisplay';
import { Fragment } from 'react';

export const ExternalTicketsInOrder = ({ order }: { order: OrderDetailType }) => {
	return (
		<Stack
			spacing={1}
			sx={{ overflowY: 'auto' }}>
			<List>
				{order.cart.lines
					.filter((cl) => CartLineTypeRules.is외부티켓[cl.type])
					.map((cl, idx) => {
						return (
							<Fragment key={idx}>
								<ListItem
									key={idx}
									sx={{ px: 0 }}>
									{cl.type === 'ONLINE_TICKET_ONOFFMIX' && (
										<OnlineTicketsDisplay
											width='100%'
											overflow={'auto'}
											orderInfo={order}
											cartLine={cl as CartLineOnlineTicketOnoffmixInfoType}
										/>
									)}
									{(['KL_COUPON_DISCOUNT', 'KL_COUPON_PRODUCT'] as CartLineType[]).includes(
										cl.type
									) && (
										<KLCouponDisplay
											width='100%'
											overflow={'auto'}
											cartLine={cl as CartLineKlPosProductInfoType | CartLineKlPosDiscountInfoType}
										/>
									)}
									{CartLineTicketTypeArray.includes(cl.type as any) && (
										<AppTicketsDisplay
											width='100%'
											overflow={'auto'}
											cartLine={cl as CartLineTicketUsageInfoType}
										/>
									)}
								</ListItem>
								<Divider />
							</Fragment>
						);
					})}
			</List>
		</Stack>
	);
};
