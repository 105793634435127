import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { EmptyContent } from '../../components/EmptyContent';

export interface IPointRemovePanelProps {}
const PointRemovePanel: React.FC<IPointRemovePanelProps> = ({}) => {
	return (
		<Stack
			alignItems={'center'}
			spacing={2}>
			<Typography
				variant='h5'
				sx={{ color: `${vaunceColors.lightPink}` }}>
				당일 기준, 3개월 이내 소멸예정 포인트 안내
			</Typography>
			<EmptyContent title={'포인트 적립/사용 내역이 없습니다.'} />
		</Stack>
	);
};

export default PointRemovePanel;
