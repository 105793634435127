import {
	CartLineBellInfoType,
	CartLineInfoType,
	defaultProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';

export type CartLineBellActionType =
	| {
			type: 'ADD_BELL';
			cartLineId: string;
			bellNumber: string;
	  }
	| {
			type: 'REMOVE_BELL';
	  };

const cartLineBellReducer = (
	prev: CartLineInfoType[],
	action: CartLineBellActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD_BELL':
			return [
				...prev.filter((cl) => cl.type !== 'BELL'),
				createBellCartLine({
					cartLineId: action.cartLineId,
					bellNumber: action.bellNumber,
				}),
			];
		case 'REMOVE_BELL':
			return prev.filter((cl) => cl.type !== 'BELL');
	}
};

export const CartLineBellState = {
	reducer: cartLineBellReducer,
};

const createBellCartLine = ({
	cartLineId,
	bellNumber,
}: {
	cartLineId: string;
	bellNumber: string;
}): CartLineBellInfoType => {
	return {
		id: cartLineId,
		type: 'BELL',
		productInfoId: undefined,
		price: 0,
		name: `진동벨 ${bellNumber}`,
		productInfo: {
			category: '진동벨',
			isForKitchen: false,
			ddiziInfo: undefined,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		productDiscountInfo: undefined,
		bellNumber: `${bellNumber}`,
		targetCartLineId: undefined,
		ticketUsageId: undefined,
		quantity: 1,
	};
};
