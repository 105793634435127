import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const modifyPassword =
	(rootUrl: string) =>
	async ({ email, password, newPassword, newPasswordCheck }: ModifyPasswordRequestType) => {
		const result = await posAxiosClient.post<BaseInfoType & ManagerUserType>(
			`${rootUrl}/auth-service/auth/password/modify`,
			{
				email,
				password,
				newPassword,
				newPasswordCheck,
			}
		);

		return result.data;
	};

type ModifyPasswordRequestType = {
	email: string;
	password: string;
	newPassword: string;
	newPasswordCheck: string;
};
