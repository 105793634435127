import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import StopIcon from '@mui/icons-material/Stop';
import { IconButtonProps, Typography } from '@mui/material';

// Icon button 들 전부 리팩토링 해야함 일단 급하게 작업
export interface ITerminateButtonProps extends Pick<IconButtonProps, 'onClick' | 'disabled'> {
	variant?: 'white' | 'default';
	iconSize?: 'default' | 'large';
}
export const TerminateButton: React.FC<ITerminateButtonProps> = ({
	onClick,
	disabled,
	variant = 'white',
	iconSize = 'default',
}) => {
	return (
		<IconTextButton
			onClick={onClick}
			disabled={disabled}
			variant={variant}
			iconSize={iconSize}
			icon={<StopIcon fontSize='small' />}
			label={<Typography>{'응대 종료'}</Typography>}
		/>
	);
};
