import { InfoOutlined, PrinterOutlined } from '@ant-design/icons';
import {
	CartLineInfoType,
	OrderDetailType,
	PaymentInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4, numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import {
	OrderState,
	PaymentState,
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
	authState,
} from '@kinderlabs-pos/state';
import {
	CardStack,
	IconSquareButton,
	SelectableListItem,
	WithDialog,
	WithPopover,
	XlDialogProps,
} from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	List,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { ReactElement, useMemo, useState } from 'react';
import { PaymentDisplay } from '../../PaymentManagementPage/PaymentDisplay';
import { usePosRouter } from '../../routes';
import { OrderEditDialog } from '../OrderEditDialog';
import { OrderReceiptDialog } from '../OrderReceiptDialog';
import { PaymentDetailContentsInOrder } from './PaymentDetailContentsInOrder';

export const ActionsInOrder = ({
	order,
	isFetching,
}: {
	order: OrderDetailType;
	isFetching: boolean;
}) => {
	const theme = useTheme();
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo)!;

	const 날짜지난주문 = !dayjs(order.created).isToday();

	const canEditOrder = order.type !== 'KYULSAN' && order.status !== 'CANCELED' && !날짜지난주문;

	return (
		<Stack
			spacing={2}
			sx={{ overflowY: 'auto' }}>
			{(order.status === 'COMPLETED' || order.status === 'CANCELED') && (
				<>
					<WithDialog
						dialog={(open, closeDialog) => (
							<OrderReceiptDialog
								open={open}
								closeDialog={closeDialog}
								orderWithDetail={order}
							/>
						)}>
						{(openReceiptDialog) => (
							<Button
								fullWidth
								color={'primary'}
								variant='outlined'
								size={'small'}
								disabled={isFetching}
								onClick={openReceiptDialog}
								endIcon={<PrinterOutlined />}>
								<Typography variant='subtitle1'>{`영수증 재발급`}</Typography>
							</Button>
						)}
					</WithDialog>
					<Divider />
				</>
			)}
			{enterpriseInfo.usingOrderEdit && (
				<>
					<Stack
						direction={'row'}
						spacing={1}>
						<WithDialog
							dialog={(open, close) => (
								<OrderEditDialog
									open={open}
									closeDialog={close}
									order={order}
								/>
							)}>
							{(openDialog) => (
								<Button
									onClick={openDialog}
									variant='outlined'
									color={'warning'}
									fullWidth
									disabled={isFetching || !canEditOrder || order.aggregate.totalTaxFreePay > 0}
									size={'small'}>
									주문 수정
								</Button>
							)}
						</WithDialog>
						{order.aggregate.totalTaxFreePay > 0 && (
							<Typography color='warning'>
								면세상품이 포함된 주문은 주문수정이 불가합니다.
							</Typography>
						)}
						<WithPopover PopupComponent={주문수정Popup}>
							{(openPopover) => (
								<IconSquareButton
									color={theme.palette.warning.main}
									onClick={openPopover}>
									<InfoOutlined style={{ fontSize: 16 }} />
								</IconSquareButton>
							)}
						</WithPopover>
					</Stack>
					<Divider />
				</>
			)}
			<Stack
				direction={'row'}
				spacing={1}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<일부환불및재결제Dialog
							orderWithDetail={order}
							isFetching={isFetching}
							open={open}
							closeDialog={closeDialog}
						/>
					)}>
					{(dialogOpen) => (
						<Button
							fullWidth
							size={'small'}
							variant='outlined'
							color='warning'
							disabled={isFetching || order.status === 'CANCELED'}
							onClick={dialogOpen}>
							{order.status === 'CANCELED' ? `취소 완료` : `일부 환불 및 재결제`}
						</Button>
					)}
				</WithDialog>
				<WithPopover PopupComponent={주문환불및재결제Popup}>
					{(openPopover) => (
						<IconSquareButton
							color={theme.palette.warning.main}
							onClick={openPopover}>
							<InfoOutlined style={{ fontSize: 16 }} />
						</IconSquareButton>
					)}
				</WithPopover>
			</Stack>
			<Divider />
			<Stack spacing={1}>
				<Stack
					direction={'row'}
					spacing={1}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<전체환불및취소Dialog
								orderWithDetail={order}
								isFetching={isFetching}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								fullWidth
								size={'small'}
								variant='outlined'
								color='error'
								disabled={isFetching || order.status === 'CANCELED'}
								onClick={openDialog}>
								{order.status === 'CANCELED' ? `취소 완료` : `전체 환불 및 취소`}
							</Button>
						)}
					</WithDialog>
					<WithPopover PopupComponent={주문전체취소Popup}>
						{(openPopover) => (
							<IconSquareButton
								color={theme.palette.error.main}
								onClick={openPopover}>
								<InfoOutlined style={{ fontSize: 16 }} />
							</IconSquareButton>
						)}
					</WithPopover>
				</Stack>
				<Typography
					variant={'body2'}
					color={order.status === 'CANCELED' ? 'text.secondary' : 'error'}>
					∙ 발급된 티켓이 모두 발급 취소되며 주문 복구가 불가능합니다
				</Typography>
			</Stack>
			<Divider />
			<주문취소다시담기Button
				isFetching={isFetching}
				order={order}
			/>
			{/* <Button
					disabled={isFetching || !canCancelOrder}
					fullWidth
					size={'small'}
					color={'error'}
					variant='outlined'
					onClick={handleCancelOrder}>
					{order.status === 'CANCELED' ? `취소 완료` : `주문 취소`}
				</Button> */}
		</Stack>
	);
};

const 주문수정Popup: ReactElement = (
	<Stack p={2}>
		<Typography>
			다음의 경우 주문 수정이 불가능합니다.
			<br />
			<ol>
				<li>이미 주문일이 지난 완료된 주문</li>
				<li>이미 취소된 주문</li>
				<li>결산 주문</li>
			</ol>
		</Typography>
		<Typography>
			주문 수정시 주의 사항입니다.
			<br />
			<ol>
				<li>
					티켓이 추가되는 경우, 새로 발급되는 티켓은 주문 완료 시간을 기준으로 새로운 티켓이
					발급됩니다.
				</li>
				<li>
					기존에 발급한 티켓이 수정되는 경우, 이전의 티켓을 모두 취소하고 새로운 티켓을 발급합니다.
				</li>
				<li>주문 수정시 차액이 - (음수) 인경우 주문 수정이 불가능합니다.</li>
			</ol>
		</Typography>
	</Stack>
);

const 주문환불및재결제Popup: ReactElement = (
	<Stack p={2}>
		<Typography>
			분할 결제중 일부를 취소하고 재결제하는 경우에 사용합니다.
			<br />
			온라인권, 앱티켓(다회권, 정기권, 일회권, 쿠폰),
			<br />
			POS 쿠폰 (증정권, 할인쿠폰)
			<br />
			<br />
			온라인권 사용을 취소하지 않고 결제 중 일부만 취소하고 싶은 경우 사용합니다.
		</Typography>
	</Stack>
);

const 주문전체취소Popup: ReactElement = (
	<Stack p={2}>
		<Typography>
			주문 취소를 위해서는 먼저 결제를 모두 취소해주세요.
			<br />
			<br />
		</Typography>
		<Typography>
			온라인권, 앱티켓(다회권, 정기권, 일회권, 쿠폰),
			<br />
			POS 쿠폰 (증정권, 할인쿠폰)
			<br />
			<br />
			모두 사용처리가 취소됩니다.
		</Typography>
		<br />
		<Typography>
			주문 취소 후 필요시
			<br />
			온라인권, 앱티켓(다회권, 정기권, 일회권, 쿠폰),
			<br />
			POS 쿠폰 (증정권, 할인쿠폰)
			<br />
			<br />
			다시 사용처리를 해주세요
		</Typography>
	</Stack>
);

const 일부환불및재결제Dialog = ({
	open,
	closeDialog,
	orderWithDetail,
	isFetching,
}: XlDialogProps & {
	orderWithDetail: OrderDetailType;
	isFetching: boolean;
}) => {
	const { navigate결제 } = usePosRouter();
	const confirm = useConfirm();

	const paymentTotal = useMemo(
		() => PaymentState.utils.getTotal(orderWithDetail.payments),
		[orderWithDetail]
	);
	const remaining = orderWithDetail.aggregate.totalToPay - paymentTotal;

	const handleClickRemainingPay = () =>
		confirm(`${numberWithCommasAndWon(remaining)}을 이어서 결제하시겠습니까?`, () => {
			navigate결제({
				mode: 'order_continue',
				orderId: orderWithDetail.id,
				status: orderWithDetail.status,
			});
		});

	const customConfirm = useConfirm();
	const handleClickCancel = () => {
		if (orderWithDetail.status !== 'COMPLETED')
			customConfirm(
				<Stack>
					<Typography variant='subtitle1'>재결제를 마저 완료해주세요.</Typography>
					<Typography
						variant='subtitle1'
						color='error'>
						강제로 종료하시겠습니까?
					</Typography>
				</Stack>,
				() => {
					closeDialog && closeDialog();
				}
			);
		else {
			closeDialog && closeDialog();
		}
	};

	return (
		<WithDialog.XlDialog
			dialogTitle={'일부 환불 및 재결제'}
			open={open}
			closeDialog={closeDialog}>
			<OrderAndPaymentDialogContents
				orderWithDetail={orderWithDetail}
				paymentTotal={paymentTotal}
			/>
			<DialogActions>
				<Stack
					width='100%'
					direction={'row'}
					justifyContent={'flex-end'}
					spacing={1}>
					<Button
						disabled={isFetching || remaining <= 0}
						variant='outlined'
						onClick={handleClickRemainingPay}>
						재결제
					</Button>
					<Button
						variant='outlined'
						onClick={handleClickCancel}>
						닫기
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const 전체환불및취소Dialog = ({
	open,
	closeDialog,
	orderWithDetail,
	isFetching,
}: XlDialogProps & {
	orderWithDetail: OrderDetailType;
	isFetching: boolean;
}) => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const 주문취소시취소주방영수증출력 =
		PosElectronStoreState.usePosMainPageLocalSettings().data?.usingKitchenCancelBills ?? false;
	const mainPageQuery = PosElectronStoreState.usePosMainPageLocalSettings();
	const 주방주문증크게 = mainPageQuery.data?.usingLargeTextOnKitchenReceiptPrinter ?? false;

	const { printKitchen주문서Async } = PrintReceiptState.useKitchenPrinter({
		storeId,
		deviceType: 'POS',
	});
	const { printCafe주문서Async } = PrintReceiptState.useCafePrinter({
		storeId: storeId,
		deviceType: 'POS',
	});

	const customConfirm = useConfirm();
	const cancelOrder = OrderState.actions.useCancelOrder(async () => {
		closeDialog && closeDialog();

		if (주문취소시취소주방영수증출력) {
			try {
				await printCafe주문서Async({
					order: orderWithDetail,
					주방주문증크게,
					취소주문증인지: true,
				});
				await printKitchen주문서Async({
					order: orderWithDetail,
					주방주문증크게,
					취소주문증인지: true,
				});
			} catch {}
		}
	});

	const handleCancelOrder = () =>
		customConfirm('주문 취소를 완료하시겠습니까?', () => cancelOrder(orderWithDetail));

	const paymentTotal = useMemo(
		() => PaymentState.utils.getTotal(orderWithDetail.payments),
		[orderWithDetail]
	);

	const handleClickCancel = () => {
		customConfirm(
			<Stack>
				<Typography variant='subtitle1'>환불을 마저 완료하고 주문을 취소해주세요.</Typography>
				<Typography
					variant='subtitle1'
					color='error'>
					강제로 종료하시겠습니까?
				</Typography>
			</Stack>,
			() => {
				closeDialog && closeDialog();
			}
		);
	};

	return (
		<WithDialog.XlDialog
			dialogTitle={'전체 환불 및 취소'}
			open={open}
			closeDialog={closeDialog}>
			<OrderAndPaymentDialogContents
				orderWithDetail={orderWithDetail}
				paymentTotal={paymentTotal}
			/>
			<DialogActions>
				<Stack
					px={2}
					width='100%'
					direction={'row'}
					justifyContent={'flex-end'}
					spacing={1}>
					<Button
						disabled={isFetching || orderWithDetail.payments.some((p) => !p.refunded)}
						variant='outlined'
						onClick={handleCancelOrder}>
						주문 취소 완료
					</Button>
					<Button
						variant='outlined'
						onClick={handleClickCancel}>
						닫기
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const OrderAndPaymentDialogContents = ({
	orderWithDetail,
	paymentTotal,
}: {
	orderWithDetail: OrderDetailType;
	paymentTotal: number;
}) => {
	const [selectedPayment, setSelectedPayment] = useState<PaymentInfoType | undefined>(undefined);

	return (
		<DialogContent>
			<Stack
				direction={'row'}
				spacing={1}>
				<CardStack
					width={260}
					spacing={1}>
					<Stack>
						<Typography variant='h5'>{orderWithDetail.id}</Typography>
					</Stack>
					<Stack
						direction={'row'}
						justifyContent={'space-between'}>
						<Stack spacing={0.5}>
							<Typography>{`주문 총액`}</Typography>
							<Typography color='text.secondary'>{`결제 총액`}</Typography>
							<Typography>{`남은 금액`}</Typography>
						</Stack>
						<Stack spacing={0.5}>
							<Typography textAlign={'right'}>{`${numberWithCommasAndWon(
								orderWithDetail.aggregate.totalToPay
							)}`}</Typography>
							<Typography
								color='text.secondary'
								textAlign={'right'}>{`${numberWithCommasAndWon(paymentTotal)}`}</Typography>
							<Typography textAlign={'right'}>{`${numberWithCommasAndWon(
								orderWithDetail.aggregate.totalToPay - paymentTotal
							)}`}</Typography>
						</Stack>
					</Stack>
					<Divider />
					<List>
						{orderWithDetail.payments.map((payment, idx) => (
							<SelectableListItem
								selected={payment.id === selectedPayment?.id}
								onClick={() => setSelectedPayment(payment)}
								key={idx}>
								<PaymentDisplay
									payment={payment}
									variant={'withoutId'}
									selected={payment.id === selectedPayment?.id}
								/>
							</SelectableListItem>
						))}
						{orderWithDetail.payments.length === 0 && (
							<Stack
								spacing={1}
								width={'100%'}
								alignItems={'center'}>
								<Typography color={'text.secondary'}>환불할 결제가 없습니다.</Typography>
								<Typography color={'text.secondary'}>전체 취소 가능합니다.</Typography>
							</Stack>
						)}
					</List>
				</CardStack>
				<Divider />
				<CardStack
					width={390}
					height={590}
					overflow='auto'>
					{selectedPayment ? (
						<PaymentDetailContentsInOrder
							orderWithDetail={orderWithDetail}
							paymentId={selectedPayment.id}
							withRefunds
						/>
					) : (
						<Stack
							height='100%'
							alignItems={'center'}
							justifyContent={'center'}>
							<Typography>환불할 결제를 선택하세요.</Typography>
						</Stack>
					)}
				</CardStack>
			</Stack>
		</DialogContent>
	);
};

const 주문취소다시담기Button = ({
	order,
	isFetching,
}: {
	order: OrderDetailType;
	isFetching: boolean;
}) => {
	const customConfirm = useConfirm();
	const { navigatePosMain } = usePosRouter();
	const cartReducer = OrderState.cart.useDispatcher();

	const handleClick다시담기 = () =>
		customConfirm('취소된 주문을 다시 담으시겠습니까?', () => {
			const originalCartLines = order.cart.lines;
			const uuidConverterMap = originalCartLines.reduce((result, item) => {
				if (result[item.id] === undefined) result[item.id] = getUuidV4();
				return result;
			}, {} as Record<string, string>);

			const newCartLines: CartLineInfoType[] = originalCartLines.map((cl) => ({
				...cl,
				id: uuidConverterMap[cl.id],
				targetCartLineId: cl.targetCartLineId ? uuidConverterMap[cl.targetCartLineId] : undefined,
			}));

			cartReducer({ type: 'REPARE_CARTLINES', newCartLines });
			navigatePosMain();
		});

	return (
		<Button
			fullWidth
			size={'small'}
			variant='outlined'
			color='error'
			disabled={isFetching || order.status !== 'CANCELED'}
			onClick={handleClick다시담기}>
			{`취소 주문 다시 담기`}
		</Button>
	);
};
