import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductInfoType, StockInfoType } from '@kinderlabs-pos/shared-data-type';

export const getStockDetailInfoAsync = (rootUrl: string) => async (stockId: number) =>
	(
		await posAxiosClient.get<GetStockDetailInfoResponseType>(`${rootUrl}/stock`, {
			params: {
				stockId,
			},
		})
	).data;

type GetStockDetailInfoResponseType = StockInfoType &
	Pick<ProductInfoType, 'name' | 'barcode' | 'productStockData' | 'price'>;
