import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ProductDiscountMgInfoType } from '@kinderlabs-pos/shared-data-type';

export const getAllProductDiscountInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId?: number }) =>
		(
			await posAxiosClient.get<(ProductDiscountMgInfoType & BaseInfoType)[]>(
				`${rootUrl}/product-discount/all`,
				{
					params: { storeId },
				}
			)
		).data;
