import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Stack, Typography } from '@mui/material';
import CenterGuide01 from 'assets/vaunce/image/centerGuide01.png';
import CenterGuide02 from 'assets/vaunce/image/centerGuide02.png';
import CenterGuide03 from 'assets/vaunce/image/centerGuide03.png';
import CenterGuide04 from 'assets/vaunce/image/centerGuide04.png';

import CenterGuide01x from 'assets/vaunce/image/centerGuide01@2x.png';
import CenterGuide02x from 'assets/vaunce/image/centerGuide02@2x.png';
import CenterGuide03x from 'assets/vaunce/image/centerGuide03@2x.png';
import CenterGuide04x from 'assets/vaunce/image/centerGuide04@2x.png';

export const CenterGuideData = [
	{
		imageUrl: CenterGuide01,
		srcsetUrl: CenterGuide01x,
		title: '입장신청',
		description: (
			<Stack alignItems={'center'}>
				<Typography variant='h5'>이용자 개인정보 입력,</Typography>
				<Typography variant='h5'>이용약관 / 안전 수칙 동의</Typography>
			</Stack>
		),
	},
	{
		imageUrl: CenterGuide02,
		srcsetUrl: CenterGuide02x,
		title: '입장 번호 확인',
		description: (
			<Stack alignItems={'center'}>
				<Typography variant='h5'>카카오톡 또는 문자 수신,</Typography>
				<Typography variant='h5'>번호 확인 후 대기</Typography>
			</Stack>
		),
	},
	{
		imageUrl: CenterGuide03,
		srcsetUrl: CenterGuide03x,
		title: '결제 및 발권',
		description: (
			<Stack
				alignItems={'center'}
				spacing={1}>
				<Typography
					variant='h5'
					sx={{ textAlign: 'center' }}>
					카카오톡 또는 전광판 <br />
					호출시 프론트에서 <br />
					발권
				</Typography>
				<Typography
					textAlign={'center'}
					fontWeight={500}
					variant='h5'
					sx={{ color: vaunceColors.lightPink }}>
					미끄럼방지 양말 미소지 시 구매
				</Typography>
			</Stack>
		),
	},
	{
		imageUrl: CenterGuide04,
		srcsetUrl: CenterGuide04x,
		title: '입장',
		description: (
			<Stack alignItems={'center'}>
				<Typography
					variant='h5'
					sx={{ textAlign: 'center' }}>
					입장 팔찌 착용 후 입장 시설 이용
				</Typography>
			</Stack>
		),
	},
];
