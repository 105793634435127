import { PosBffApi } from '@kinderlabs-pos/apis/pos';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../QueryClient';

const posStatusKeys = createQueryKeys('posStatus', {
	list: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			if (!storeId) return null;
			return await PosBffApi.getPosStatusList({
				storeId,
			});
		},
	}),
	detail: (posId?: number) => ({
		queryKey: [posId],
		queryFn: async () => {
			if (!posId) {
				return;
			}
			return await PosBffApi.getPosStatus({
				posId,
			});
		},
	}),
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: posStatusKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: posStatusKeys._def });
};

export const PosStatusQueryState = {
	keys: posStatusKeys,
	invalidateQueries,
};
