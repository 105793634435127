import { CalendarOutlined } from '@ant-design/icons';
import { Box, SxProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, IDatePickerProps } from '../DatePicker';
// import { MonthPicker } from '../MonthPicker/index';

interface IAdminDatePickerProps<TInputDate extends Dayjs, TDate extends Dayjs>
	extends IDatePickerProps<TInputDate, TDate> {
	sx?: SxProps;
}
export const AdminDatePicker = <TInputDate extends Dayjs, TDate extends Dayjs>({
	sx,
	disabled,
	...props
}: IAdminDatePickerProps<TInputDate, TDate>) => {
	return (
		<Box sx={{ ...sx, position: 'relative' }}>
			<DatePicker
				{...props}
				minDate={dayjs('2017-01-01') as TDate}
				disabled={disabled}
			/>
			<Box
				sx={{
					position: 'absolute',
					right: 10,
					top: '20%',
					display: { xs: 'none', sm: 'block' },
				}}>
				<CalendarOutlined
					style={{
						fontSize: '2rem',
						...(disabled && {
							opacity: 0.5,
						}),
					}}
				/>
			</Box>
		</Box>
	);
};

export const AdminMonthPicker = <TInputDate extends Dayjs, TDate extends Dayjs>({
	sx,
	disabled,
	...props
}: IAdminDatePickerProps<TInputDate, TDate>) => {
	return (
		<Box sx={{ ...sx, position: 'relative' }}>
			<DatePicker
				{...props}
				minDate={dayjs('2017-01-01') as TDate}
				disabled={disabled}
			/>
			<Box
				sx={{
					position: 'absolute',
					right: 10,
					top: '20%',
					display: { xs: 'none', sm: 'block' },
				}}>
				<CalendarOutlined
					style={{
						fontSize: '2rem',
						...(disabled && {
							opacity: 0.5,
						}),
					}}
				/>
			</Box>
		</Box>
	);
};
