import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	TemplateStatisticsDataType,
	TemplateStatisticsTotalSumType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../../const';

const getArlimTalkUsageAsync =
	(rootUrl: string) =>
	async ({ from, to, ...others }: GetArlimTalkUsageReqType) =>
		(
			await posAxiosClient.get<{
				data: TemplateStatisticsDataType[];
				totalSum: TemplateStatisticsTotalSumType;
			}>(`${rootUrl}/auth-service/arlimtalk/usage`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					...others,
				},
			})
		).data;

type GetArlimTalkUsageReqType = {
	from: Dayjs;
	to: Dayjs;
	enterpriseId?: string;
	storeIdList?: number[];
	templateCode?: string;
};

export const ArlimTalkInfoApis = {
	getArlimTalkUsageInfo: getArlimTalkUsageAsync(rootUrl),
};
