import { AdminProductInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AdminCategoryInfoListFilterType,
	CategoryInfoTreeType,
	CategoryInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const categoryKeys = createQueryKeys('category', {
	all: () => ({
		queryKey: ['list'],
		queryFn: async () => {
			const res = await AdminProductInfoApis.getCategoryAllList();
			return res;
		},
	}),
	top: () => ({
		queryKey: ['list'],
		queryFn: async () => {
			const res = await AdminProductInfoApis.getTopCategoryList();
			return res;
		},
	}),
	detail: (categoryId: number) => ({
		queryKey: [categoryId],
		queryFn: async () => {
			const result = await AdminProductInfoApis.getCategoryInfoDetail(categoryId);
			return result;
		},
	}),
	canDelete: (categoryList: number[]) => ({
		queryKey: [categoryList],
		queryFn: async () => {
			return await AdminProductInfoApis.canDeleteCategory({ categoryList });
		},
	}),
});

const 대그룹그룹없음ID = -88;
const 중그룹그룹없음ID = -888;
const useCategoryAllTopListQuery = () => {
	const result = useQuery({
		...categoryKeys.top(),
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		// 더미 아이디 매핑. 이렇게까지 해야하나ㅋㅋ 코드보는 사람에게 미안합니다.
		result.data.forEach((tc) => {
			if (tc.isDummy) {
				tc.id = 대그룹그룹없음ID;

				tc.children.forEach((sc) => {
					sc.parentId = 대그룹그룹없음ID;

					if (sc.isDummy) {
						sc.id = 중그룹그룹없음ID;
						sc.children.forEach((bc) => {
							bc.parentId = 중그룹그룹없음ID;
						});
					}
				});
			}
		});
		return result.data;
	} else {
		throw Error;
	}
};

const useCreateCategoryInfo = () => {
	return QueryClient.useMutation(
		async (args: {
			categoryName: string;
			level: CategoryInfoType['level'];
			parentCategoryId?: number;
		}) => {
			return await AdminProductInfoApis.createCategoryInfo(
				args.categoryName,
				args.level,
				args.parentCategoryId
			);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateCategoryInfo = () => {
	return QueryClient.useMutation(
		async (input: { id: number; name: string }) => {
			await AdminProductInfoApis.updateCategoryInfo({ ...input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateMultiProductsCategory = () => {
	return QueryClient.useMutation(
		async (input: { productIdList: number[]; categoryId: number }) => {
			await AdminProductInfoApis.updateMulitProductCategoryInfo({ ...input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
				QueryClient.origin.invalidateQueries(['productInfo']);
			},
		}
	);
};

const useDeleteCategoryInfoList = () => {
	return QueryClient.useMutation(
		async (deleteList: number[]) => {
			await AdminProductInfoApis.deleteCategoryInfo({ deleteList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
				QueryClient.origin.invalidateQueries(['productInfo']);
			},
		}
	);
};

const useChangeParentCategory = () => {
	return QueryClient.useMutation(
		async (input: { categoryId: number; toCategoryId: number }) => {
			await AdminProductInfoApis.changeParentCategoryInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useCategoryTreeForSearch = () => {
	const usingSuperCategory = useAtomValue(authState.enterpriseInfo)?.usingSuperCategory;

	const { data } = useQuery({
		...categoryKeys.all(),
		refetchOnWindowFocus: true,
	});
	const categories = data ?? [];
	const allTopCategories = AdminCategoryInfoState.useCategoryAllTopListQuery();

	return useMemo(() => {
		const result: CategoryInfoTreeType = {
			BASE: {},
			SUPER: {},
			TOP: {},
			ONLYBASE: {},
		};

		if (usingSuperCategory) {
			allTopCategories.forEach((topCategoryInfo) => {
				if (!topCategoryInfo.isDummy) {
					result.TOP[topCategoryInfo.id] = {
						baseCategoryInfo: undefined,
						superCategoryInfo: undefined,
						topCategoryInfo,
					};
				}

				topCategoryInfo.children.forEach((superCategoryInfo) => {
					if (!superCategoryInfo.isDummy) {
						result.SUPER[superCategoryInfo.id] = {
							baseCategoryInfo: undefined,
							superCategoryInfo,
							topCategoryInfo,
						};
					}

					superCategoryInfo.children.forEach((baseCategoryInfo) => {
						result.BASE[baseCategoryInfo.id] = {
							baseCategoryInfo,
							superCategoryInfo,
							topCategoryInfo,
						};
					});
				});
			});
		} else {
			categories.forEach((baseCategoryInfo) => {
				result.ONLYBASE[baseCategoryInfo.id] = {
					baseCategoryInfo,
				};
			});
		}
		return result;
	}, [categories, allTopCategories]);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: categoryKeys._def });
};

export const AdminCategoryInfoState = {
	keys: categoryKeys,

	useCategoryAllTopListQuery: useCategoryAllTopListQuery,
	useCreate: useCreateCategoryInfo,
	useUpdate: useUpdateCategoryInfo,
	useChangeParent: useChangeParentCategory,
	useUpdateMulti: useUpdateMultiProductsCategory,
	useDelete: useDeleteCategoryInfoList,
	useCategoryTreeForSearch,
};
