import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AvailableType,
	CursorPageType,
	OnlineTicketInnerType,
	OnlineTicketProviderType,
	OnlineTicketType,
	OnlineTicketUsageType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../../const';

const getOnlineTicketInfoListAsync =
	(rootUrl: string) =>
	async ({ from, to, pageable, forUseDate, ...others }: GetOnlineTicketInfoListRequestType) =>
		(
			await posAxiosClient.get<PageType<OnlineTicketType>>(`${rootUrl}/online-ticket/search`, {
				params: {
					...others,
					...pageable.getPageableQueryParam(),
					from: from.format('YYYY-MM-DD HH:mm:ss'),
					to: to.format('YYYY-MM-DD HH:mm:ss'),
					forUseDate: forUseDate ?? false,
				},
			})
		).data;

const getOnlineTicketInfoListInfiniteAsync =
	(rootUrl: string) =>
	async ({
		from,
		to,
		pageable,
		cursorId,
		forUseDate,
		...others
	}: GetOnlineTicketInfoListRequestType & { cursorId?: number | string }) =>
		(
			await posAxiosClient.get<CursorPageType<OnlineTicketType>>(
				`${rootUrl}/online-ticket/search/cursor`,
				{
					params: {
						...others,
						...pageable.getPageableQueryParam(),
						from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
						to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
						forUseDate: forUseDate ?? false,
						cursorId,
					},
				}
			)
		).data;

type GetOnlineTicketInfoListRequestType = {
	from: Dayjs;
	to: Dayjs;
	pageable: Pageable;
	search?: string;
	storeIdList?: number[];
	usageStatus?: OnlineTicketUsageType;
	ticketProvider?: OnlineTicketProviderType;
	weekdayAvailable?: AvailableType;
	ticketType?: OnlineTicketInnerType;
	forUseDate?: boolean;
};

const getOnlineTicketDetailInfoAsync =
	(rootUrl: string) =>
	async ({ oltTicketId }: { oltTicketId: number }) =>
		(
			await posAxiosClient.get<OnlineTicketType>(`${rootUrl}/online-ticket`, {
				params: {
					oltTicketId: oltTicketId,
				},
			})
		).data;

const getOnlineTicketHistoryAsync =
	(rootUrl: string) =>
	async ({ from, to, pageable, storeIdList }: GetOnlineTicketHistoryRequestType) =>
		await posAxiosClient.get(`${rootUrl}/online-ticket/history`, {
			params: {
				storeIdList,
				...pageable.getPageableQueryParam(),
				from: from.format('YYYY-MM-DD HH:mm:ss'),
				to: to.format('YYYY-MM-DD HH:mm:ss'),
			},
		});

type GetOnlineTicketHistoryRequestType = {
	storeIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	pageable: Pageable;
};

export const AdminOnlineTicketApis = {
	getListPage: getOnlineTicketInfoListAsync(rootUrl),
	getListInfinite: getOnlineTicketInfoListInfiniteAsync(rootUrl),
	getDetailInfo: getOnlineTicketDetailInfoAsync(rootUrl),
	getHistoryInfo: getOnlineTicketHistoryAsync(rootUrl),
};
