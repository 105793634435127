import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { DdiziState, ExitQueryState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow } from '@kinderlabs-pos/ui-components';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ExitPage } from '.';

export interface IExitPaymentSummaryProps {}
export const ExitPaymentSummary: React.FC<IExitPaymentSummaryProps> = ({}) => {
	const selectedOrder = useAtomValue(ExitPage.selectedOrderAtom);

	const ddizis = useAtomValue(ExitQueryState.ddiziListInOrderAtom);

	const availableDdizis = useMemo(
		() => ddizis.filter((ddizi) => DdiziState.utils.isDdiziSelectableForJungsan(ddizi)),
		[ddizis]
	);

	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);

	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);
	// const { totalSum, totalDiscount, totalToPay } = useAtomValue(OrderState.aggregate.cart);

	// const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);

	if (!selectedOrder) return <></>;

	const paymentData = [
		{
			label: '선택된 티켓 개수',
			value: `${selectedCartLines.length} / ${availableDdizis.length}`,
		},
		{
			label: '총 추가 금액',
			value: `${numberWithCommasAndWon(totalToPay)}`,
		},
	];

	return (
		<Stack
			p={1}
			flex={1}
			spacing={1}>
			{paymentData.map((item, idx) => (
				<InfoPrintStackRow
					key={idx}
					value={item.value}
					label={item.label}
				/>
			))}
		</Stack>
	);
};
