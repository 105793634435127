import { Dayjs } from 'dayjs';
import { DdiziStatusTypeArray, Pageable } from '@kinderlabs-pos/shared-data-type';
import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const downloadDdiziExcel =
	(rootUrl: string) =>
	({
		dataOnPage,
		targetPosId,
		storeIdList,
		from,
		to,
		search,
		status,
		pageable,
	}: DdiziExcelRequestType) => {
		const url = new URL(`${rootUrl}/excel/ddizi/${dataOnPage ? 'page' : 'all'}`);

		if (targetPosId) url.searchParams.append('tartgetPosId', `${targetPosId}`);
		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (from) url.searchParams.append('from', from.startOf('d').format('YYYY-MM-DD HH:mm:ss'));
		if (to) url.searchParams.append('to', to.endOf('d').format('YYYY-MM-DD HH:mm:ss'));
		if (search) url.searchParams.append('search', search);
		if (status) url.searchParams.append('status', status);
		if (pageable.page) url.searchParams.append('page', `${pageable.page}`);
		if (pageable.size) url.searchParams.append('size', `${pageable.size}`);
		if (pageable.sort)
			url.searchParams.append('sort', `${pageable.sort.name},${pageable.sort.direction}`);

		return `${url}`;
	};
type DdiziExcelRequestType = {
	dataOnPage?: boolean;
	storeIdList?: number[];
	targetPosId?: number;
	from: Dayjs;
	to: Dayjs;
	search?: string;
	status?: (typeof DdiziStatusTypeArray)[number];
	pageable: Pageable;
};
