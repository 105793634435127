import {
	AdminProductInfoState,
	DdiziState,
	ExitQueryState,
	OrderState,
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { SelectableListItem, SimpleScrollbar, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Checkbox, List, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { DetailButton } from '../../components/PosHeader/buttons/DetailButton';
import { TicketCompleteButton } from '../../modules/tickets/components/buttons/TicketCompleteButton';
import { TicketPrintButton } from '../../modules/tickets/components/buttons/TicketPrintButton';
import { TickeTimeMinusButton } from '../../modules/tickets/components/buttons/TicketTimeMinusButton';
import { TickeTimePlusButton } from '../../modules/tickets/components/buttons/TicketTimePlusButton';
import { DdiziDetailDialog } from '../DdiziManagementPage/DdiziDetailDialog';
import { DdiziEnterExitButtons } from '../DdiziManagementPage/DdiziEnterExitButtons';
import { ExitPageDdiziDisplay } from './ExitPageDdiziDisplay';

export const ExitPageDdiziList = ({
	orderId,
	keyword,
	fixedDate,
}: {
	orderId: string;
	keyword: string | null;
	fixedDate: Date;
}) => {
	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);
	const jungsanInfo = StoreInfoState.useCurStoreJungsanInfo();
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList: curDeviceInfo.labelPrinterList,
	});

	// const ddizis = ExitQueryState.useDdiziListInOrder(orderId, keyword || '', now, false);
	const FixedDdizis = ExitQueryState.useDdiziListInOrder(orderId, keyword || '', fixedDate, false);

	const availableDdizis = useMemo(
		() => FixedDdizis.filter((ddizi) => DdiziState.utils.isDdiziSelectableForJungsan(ddizi)),
		[FixedDdizis]
	);
	const cartDispatch = OrderState.cart.useDispatcher();

	const adjustTime = DdiziState.actions.useAdjustTime();
	const handleAllToggle = () => {
		cartDispatch({
			type: 'EXCESS_CHARGE',
			subAction: {
				type: 'TOGGLE_ALL',
				ddizis: availableDdizis.filter((ddizi) =>
					DdiziState.utils.isDdiziSelectableForJungsan(ddizi)
				),
				jungsanInfo,
			},
		});
	};

	const handleAdjustTime = (time: number) => () => {
		adjustTime.mutate(
			selectedCartLines.map((cl) => ({
				ddiziId: cl.ddiziUsageId,
				time: time,
			}))
		);
	};

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId,
		deviceType: 'POS',
	});

	const handle재발권 = async () => {
		for (const cartline of selectedCartLines) {
			const ddizi = availableDdizis.find((ddizi) => ddizi.id === cartline.ddiziUsageId);
			if (ddizi) {
				const ddiziData = { ...DdiziState.utils.getDdizi인쇄Data(ddizi) };
				await 띠지프린터라벨출력({
					...ddiziData,
					deviceType: 'POS',
					newDdizi:
						ddiziData.deviceNum === 'LabelPrinter1'
							? localSettings?.useNewDesignOnLabelPrinter1 ?? false
							: localSettings?.useNewDesignOnLabelPrinter2 ?? false,
				});

				// 영수증 띠지 프린터를 쓴다면
				await print영수증띠지Async({
					ddizi,
				});
			}
		}
	};

	const noSelected = selectedCartLines.length === 0;

	return (
		<Stack
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<Stack
				direction={'row'}
				justifyContent={'flex-end'}
				spacing={2}
				pr={2}>
				<TicketCompleteButton
					label={'전체 선택'}
					onClick={handleAllToggle}
				/>
				<TicketPrintButton
					label={'재발권'}
					disabled={noSelected}
					onClick={handle재발권}
				/>
				<TickeTimePlusButton
					label={'10분 추가'}
					disabled={noSelected}
					onClick={handleAdjustTime(10)}
				/>
				<TickeTimeMinusButton
					label={'10분 감소'}
					disabled={noSelected}
					onClick={handleAdjustTime(-10)}
				/>
			</Stack>
			<Stack
				flex={1}
				sx={{ overflowY: 'auto' }}>
				<SimpleScrollbar>
					<List>
						{FixedDdizis.map((ddizi, idx) => {
							const selected = selectedCartLines.map((cl) => cl.ddiziUsageId).includes(ddizi.id);
							return (
								<SelectableListItem
									selected={selected}
									disabled={
										!availableDdizis.map((ddizi) => ddizi.id).includes(ddizi.id) ||
										!DdiziState.utils.isDdiziSelectableForJungsan(ddizi)
									}
									onClick={(e) => {
										cartDispatch({
											type: 'EXCESS_CHARGE',
											subAction: { type: 'TOGGLE', ddizi: ddizi, jungsanInfo },
										});
									}}
									key={idx}>
									<ExitPageDdiziDisplay
										width={'100%'}
										ddizi={ddizi}
										itemButton={
											dayjs(ddizi.publishInfo.published).isToday() && (
												<Stack
													direction={'row'}
													alignItems={'center'}
													spacing={1}>
													<Stack
														direction={'row'}
														spacing={1}
														sx={{ pointerEvents: 'all' }}>
														<WithDialog
															dialog={(open, closeDialog) => (
																<DdiziDetailDialog
																	open={open}
																	closeDialog={closeDialog}
																	ddizi={ddizi}
																/>
															)}>
															{(openDialog) => <DetailButton onClick={openDialog} />}
														</WithDialog>
														<DdiziEnterExitButtons ddizi={ddizi} />
													</Stack>
													<Checkbox checked={selected} />
												</Stack>
											)
										}
									/>
								</SelectableListItem>
							);
						})}
					</List>
				</SimpleScrollbar>
			</Stack>
		</Stack>
	);
};
