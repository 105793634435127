export const isSame = (source: any, target: any) => {
	if (typeof source !== typeof target) {
		return false;
	}

	if (typeof source === 'object' && source !== null && target !== null) {
		const keys1 = Object.keys(source);
		const keys2 = Object.keys(target);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			if (!isSame(source[key], target[key])) {
				return false;
			}
		}

		return true;
	}

	return source === target;
};
