import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {
	CardPaymentInfoToRefundType,
	useHandle카드환불Formik,
} from './hooks/useHandleCardRefundFormik';

interface ActionType {
	order: OrderDetailType;
	payment: CardPaymentInfoToRefundType;
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
	closeDialog?: () => void;
}
export const PaymentDetail카드Actions: React.FC<ActionType> = ({
	order,
	payment,
	onCompleteRefund,
	closeDialog,
}) => {
	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	useEffect(() => {
		return () => {
			handle결제모듈강제취소();
		};
	}, []);

	const { handleSubmit: handle카드환불, errors: 카드환불Errors } = useHandle카드환불Formik({
		payment,
		order,
		onComplete: () => {
			onCompleteRefund && onCompleteRefund(payment);
		},
	});

	return (
		<Stack spacing={1}>
			<Stack
				height={40}
				direction={'row'}
				spacing={1}
				justifyContent='flex-end'>
				{!payment.refunded && (
					<Button
						onClick={() => handle카드환불()}
						variant={'outlined'}
						sx={{ pl: 1, pr: 1 }}>
						<Typography variant={'body1'}>환불</Typography>
					</Button>
				)}
				{closeDialog && (
					<Button
						variant='contained'
						onClick={closeDialog}>
						확인
					</Button>
				)}
			</Stack>
			{카드환불Errors.submit && (
				<Stack direction={'row'}>
					<Typography
						variant='subtitle1'
						color={'error'}>
						{카드환불Errors.submit}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};
