import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	EnterpriseInfoType,
	LoginResultType,
	ManagerUserType,
} from '@kinderlabs-pos/shared-data-type';

export const loginAsync =
	(rootUrl: string) =>
	async ({ password, email }: LoginRequestAsyncType) => {
		const result = await posAxiosClient.post<LoginResultType<ManagerUserType>>(
			`${rootUrl}/authenticate/auth/login`,
			{
				email,
				password,
			}
		);

		localStorage.setItem('accessToken', result.data.accessToken);
		localStorage.setItem('refreshToken', result.data.refreshToken);
		localStorage.setItem('enterpriseHash', result.data.enterpriseInfo.hash);

		return {
			userInfo: result.data.content,
			enterpriseInfo: result.data.enterpriseInfo,
		};
	};

type LoginRequestAsyncType = {
	email: string;
	password: string;
};
