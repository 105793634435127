import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsTicket = ({
	selected,
	setSelected,
	orderWithDetail,
}: OrderAndPaymentsType) => {
	const 총N명 = useMemo(
		() =>
			orderWithDetail.ddizis
				.filter((ddizi) => ddizi.status !== 'CANCELED')
				.reduce((count, ddizi) => count + ddizi.multiple, 0),
		[orderWithDetail.ddizis]
	);

	return (
		<>
			<Divider />
			<Typography
				variant='h4'
				textAlign={'center'}
				sx={{ py: 0.5 }}>
				{`티켓`}
			</Typography>
			<Divider />
			<SelectableListItem
				sx={{ py: 1, px: 0 }}
				selected={selected.type === 'ddizi'}
				onClick={() => {
					setSelected({ type: 'ddizi' });
				}}>
				<Stack
					direction={'row'}
					sx={{ flex: 1 }}
					alignItems={'center'}
					spacing={1}
					justifyContent={'space-between'}>
					<Button
						size={'small'}
						color={'primary'}
						variant={selected.type === 'ddizi' ? 'contained' : 'outlined'}>
						{'티켓'}
					</Button>
					<Stack>
						<Typography
							variant={'h5'}
							color={'primary'}
							sx={{ textAlign: 'right' }}>
							{`총 ${총N명} 명`}
						</Typography>
						<Typography
							variant={'body2'}
							color={'text.secondary'}
							sx={{ textAlign: 'right' }}>
							{(() => {
								const using = orderWithDetail.ddizis
									.filter((ddizi) => ddizi.status === 'USING')
									.reduce((count, ddizi) => count + ddizi.multiple, 0);
								const exited = orderWithDetail.ddizis
									.filter((ddizi) => ddizi.status === 'EXITED')
									.reduce((count, ddizi) => count + ddizi.multiple, 0);

								return exited === orderWithDetail.ddizis.length
									? '전원 퇴장 완료'
									: `이용 중 : ${using} 명`;
							})()}
						</Typography>
					</Stack>
				</Stack>
			</SelectableListItem>
		</>
	);
};
