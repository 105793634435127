import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { rootUrl } from '../../const';
import {
	PageType,
	Pageable,
	PosCouponExcelUploadType,
	PosCouponListFilterType,
	PosCouponTicketType,
} from '@kinderlabs-pos/shared-data-type';

const getPosCouponTicketListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: PosCouponListFilterType) =>
		(
			await posAxiosClient.get<PageType<PosCouponTicketType>>(`${rootUrl}/coupon/search`, {
				params: {
					...options,
					...Pageable.getPageable({
						page,
						size,
						sort,
					}).getPageableQueryParam(),
				},
			})
		).data;

const getPosCouponTicketAsync =
	(rootUrl: string) =>
	async ({ couponId }: { couponId: string }) =>
		(
			await posAxiosClient.get<PosCouponTicketType | null>(`${rootUrl}/coupon`, {
				params: {
					id: couponId,
				},
			})
		).data;

const createCouponUpload = (rootUrl: string) => async (itemList: PosCouponExcelUploadType[]) => {
	return (
		await posAxiosClient.post<PosCouponExcelUploadType[]>(`${rootUrl}/coupon/upload`, itemList)
	).data;
};

const getExcelDown = (rootUrl: string) => async (couponInfoId: number) => {
	const blob = await getExcelDownloadBlob({ rootUrl, couponInfoId });

	const blobURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement('a');

	tempLink.style.display = 'none';
	tempLink.href = blobURL;
	tempLink.setAttribute('download', `Coupon-Upload${couponInfoId}_No_Empty.xlsx`);

	document.body.appendChild(tempLink);

	tempLink.click();

	document.body.removeChild(tempLink);
	window.URL.revokeObjectURL(blobURL);
};

const getExcelDownloadBlob = async ({
	rootUrl,
	couponInfoId,
}: {
	rootUrl: string;
	couponInfoId?: number;
}) => {
	const response = await posAxiosClient.get(`${rootUrl}/excel/coupon/download`, {
		params: {
			couponInfoId,
		},
		responseType: 'blob',
	});

	const blob = new Blob([response.data], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	return blob;
};

export type DiscardPosCouponInfoListRequestType = { couponInfoId: number };

const updateDiscardAsync =
	(rootUrl: string) =>
	async ({ couponInfoId }: DiscardPosCouponInfoListRequestType) =>
		(
			await posAxiosClient.put<string[]>(
				`${rootUrl}/coupon/discard/all?couponInfoId=${couponInfoId}`
			)
		).data;

export type DeletePosCouponListRequestType = { idList: number[] };

const deletePosCouponTicketListAsync =
	(rootUrl: string) =>
	async ({ idList }: DeletePosCouponListRequestType) =>
		(
			await posAxiosClient.delete<string[]>(`${rootUrl}/coupon`, {
				params: {
					idList,
				},
			})
		).data;

const updateCouponForceUseAsync = (rootUrl: string) => async (couponId: string) =>
	(await posAxiosClient.post<string>(`${rootUrl}/coupon/force-use?id=${couponId}`)).data;

export const PosCouponTicketApis = {
	getListPage: getPosCouponTicketListAsync(rootUrl),
	getOne: getPosCouponTicketAsync(rootUrl),
	updateDiscard: updateDiscardAsync(rootUrl),
	createCouponExcel: createCouponUpload(rootUrl),
	getExcelDown: getExcelDown(rootUrl),
	deleteListAsync: deletePosCouponTicketListAsync(rootUrl),
	forceUse: updateCouponForceUseAsync(rootUrl),
};
