import { SquareButton } from '@kinderlabs-pos/ui-atoms';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Box, Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { TelephoneBoard } from './TelephoneBoard';

export interface INumberBoardProps {
	isInitial?: boolean;
	minValue?: number;
	maxValue?: number;
	value: number;
	// setValue: React.Dispatch<React.SetStateAction<number>>;
	setValue: (input: number) => void;
	disabled?: boolean;
}
export const NumberBoard = ({
	minValue,
	maxValue,
	value,
	setValue,
	isInitial = false,
	disabled = false,
}: INumberBoardProps) => {
	useEffect(() => {
		if (maxValue && value > maxValue) setValue(maxValue);
		else if (minValue && value < minValue) setValue(minValue);
	}, [value]);

	const handleAddNumber = (val: string) => () => {
		if (val === '0') {
			setValue(value * 10);
		} else if (val === '00') {
			setValue(value * 100);
		} else if (val === '000') {
			setValue(value * 1000);
		} else if (isInitial && Number.isInteger(+val))
			// 0, 00, 000 을 누르지 않은 이상 초기화 하고 싶을 듯
			setValue(+val);
		else {
			setValue(value === 0 ? +val : value * 10 + +val);
		}
	};
	const handleDeleteNumber = () => {
		if (value === null) return;

		const rem = value % 10;
		const mod = (value - rem) / 10;

		setValue(mod);
	};

	const handleClearNumber = () => {
		setValue(0);
	};

	return (
		<Box
			// width={480}
			display='grid'
			gridTemplateColumns={'repeat(3, 80px)'}
			gridTemplateRows={'repeat(5, 60px)'}>
			<StyledButton
				disabled={disabled}
				onClick={handleClearNumber}
				sx={{ gridColumn: '1 / 3' }}>
				<Typography sx={{ fontSize: 20 }}>{'Clear'}</Typography>
			</StyledButton>
			<StyledButton
				disabled={disabled}
				onClick={handleDeleteNumber}>
				<BackspaceIcon />
			</StyledButton>
			{['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0', '000'].map((val, idx) => (
				<StyledButton
					key={idx}
					disabled={disabled}
					sx={{ flex: 1, bgcolor: 'white' }}
					onClick={handleAddNumber(val)}>
					<Typography sx={{ fontSize: 20 }}>{val}</Typography>
				</StyledButton>
			))}
		</Box>
	);
};

const StyledButton = styled(SquareButton)(({ theme }) => ({
	height: 60,
	border: '1px solid lightgray',
	'& .MuiTypgraphy-root': {
		fontSize: theme.typography.subtitle1.fontSize,
	},
}));
