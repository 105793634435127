import { Box, Button, Card, CardActions, Collapse, IconButton, Paper, Typography } from "@mui/material";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from '@mui/icons-material/Close';
import { NotiStackCard } from ".";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { snackBarStyle } from ".";

interface TicketActionsProps extends Omit<CustomContentProps, "message"> {
  ticketId: string
}
// eslint-disable-next-line react/display-name
export const TicketAction = forwardRef<HTMLDivElement, TicketActionsProps>(({ id, ticketId, }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} style={snackBarStyle}>
      <NotiStackCard cardColor={"#fddc6c"}>
        <ConfirmationNumberIcon sx={{ pr: 1 }} />
        <Typography variant="subtitle2">
          {ticketId}
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <Button onClick={() => { }}>
            {"재발권"}
          </Button>
          <Button onClick={() => { }}>
            {"퇴실처리"}
          </Button>
          <IconButton
            aria-label="Show more"
            sx={{
              p: 1,
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all .2s"
            }}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton sx={{
            p: 1,
            transform: "rotate(0deg)",
            transition: "all .2s"
          }} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
      </NotiStackCard>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper sx={{ p: 1 }}>
          <Button onClick={() => { }}>
            {"재발권"}
          </Button>
          <Button onClick={() => { }}>
            {"퇴실처리"}
          </Button>
          <Button onClick={() => { }}>
            {"티켓확인"}
          </Button>
          <Button onClick={() => { }}>
            {"주문확인"}
          </Button>
        </Paper>
      </Collapse>
    </SnackbarContent >
  );
});