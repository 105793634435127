import { QrcodeOutlined } from '@ant-design/icons';
import { OutlinedInput, OutlinedInputProps } from '@mui/material';
export interface IQRInputProps extends OutlinedInputProps { }
export const QRInput: React.FC<IQRInputProps> = ({ autoFocus, startAdornment, ...props }) => {
  return (
    <OutlinedInput
      autoFocus
      sx={theme => ({
        "&.Mui-focused": {
          "& span.anticon-qrcode": {
            color: theme.palette.primary.dark
          }
        }
      })}
      startAdornment={
        <QrcodeOutlined style={{ fontSize: 25, marginRight: 8 }} />
      }
      {...props}
    />
  )
};