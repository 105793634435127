import { PaymentResponseType } from '../HardwareDataType/PaymentModuleType';

export const GiftCardProvideTypeArray = ['ZLGOON'] as const;
export type GiftCardProviderType = (typeof GiftCardProvideTypeArray)[number];
export const GiftCardProviderTypeLabel: Record<GiftCardProviderType, string> = {
	ZLGOON: '즐거운',
};

export type GiftCardType = {
	giftCardNumber: string;
	giftCardName: string;
	giftCardPrice: number;
	giftCardProvider: GiftCardProviderType;
	published: Date;
	expired: Date;
	used?: Date;
	refunded?: Date;
};
