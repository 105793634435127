import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PaymentInfoType, ReceiptInfoType } from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';

export const refundPaymentAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, paymentId, refundReceipt, needToMonitor }: PaymentRequestType) =>
		(
			await posAxiosClient.post<PaymentInfoType>(
				`${rootUrl}/payment/store/${storeId}/pos/${posId}/payment/refund`,
				refundReceipt
					? {
							...refundReceipt,
							approvalDate: dayjs(refundReceipt.approvalDate).format('YYYY-MM-DDTHH:mm:ss'),
					  }
					: undefined,
				{
					params: {
						id: paymentId,
						needToMonitor,
					},
				}
			)
		).data;

export type PaymentRequestType = {
	storeId: number;
	posId: number;
	refundReceipt?: ReceiptInfoType;
	paymentId: string;
	needToMonitor: boolean;
};
