import {
	DeviceType,
	KioskInfoType,
	kitchenOrderStatusLabel,
	KitchenOrderStatusType,
	kitchenOrderStatusTypeArray,
	PosInfoType,
	PosTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { AdminKitchenSystemInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { FilterWith전체Selector } from '../../composites/FilterWithAllSelector';

export type KitchenOrderFilterType = {
	status?: KitchenOrderStatusType[];
	deviceTypeList?: DeviceType[];
	deviceIdList?: number[];
	kitchenSystemId?: number;
};

export interface IKitchenOrderFilterDialogProps extends XlDialogProps {
	filter: KitchenOrderFilterType;
	setFilter: React.Dispatch<React.SetStateAction<KitchenOrderFilterType>>;
}
export const KitchenOrderFilterDialog: React.FC<IKitchenOrderFilterDialogProps> = ({
	open,
	closeDialog,
	filter,
	setFilter,
}) => {
	const [tempFilter, setTempFilter] = useState(filter);
	const { id, posInfoList, kioskInfoList } = useAtomValue(StoreInfoState.curStoreInfo);

	useEffect(() => {
		if (!tempFilter.deviceIdList || !tempFilter.deviceTypeList) return;
		setTempFilter((prev) => ({ ...prev, deviceIdList: undefined }));
	}, [tempFilter.deviceTypeList]);

	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>주방 주문 필터</DialogTitle>
			<DialogContent>
				<Stack
					direction={'row'}
					spacing={1}
					py={1}>
					<Box
						width={180}
						py={1}>
						<FilterWith전체Selector
							label={'주문 상태'}
							candidates={kitchenOrderStatusTypeArray.map((key) => ({
								label: kitchenOrderStatusLabel[key],
								value: key,
							}))}
							value={tempFilter.status ? tempFilter.status[0] : undefined}
							onChangeValue={(value) => {
								setTempFilter((prev) => ({
									...prev,
									status: value ? [value as KitchenOrderStatusType] : undefined,
								}));
							}}
						/>
					</Box>
					<Box
						width={180}
						py={1}>
						<FilterWith전체Selector
							label={'주문 기기'}
							candidates={getMappedPosAndKioskList({
								curDeviceTypeList: tempFilter.deviceTypeList,
								posInfoList,
								kioskInfoList,
							}).map((key) => ({
								label: key.label,
								value: `${key.value}`,
							}))}
							value={tempFilter.deviceIdList ? `${tempFilter.deviceIdList[0]}` : undefined}
							onChangeValue={(value) => {
								setTempFilter((prev) => ({
									...prev,
									deviceIdList: value ? [+value] : undefined,
								}));
							}}
						/>
					</Box>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					onClick={() => {
						setFilter(tempFilter);
						closeDialog && closeDialog();
					}}>
					확인
				</Button>
				<Button
					onClick={closeDialog}
					variant={'outlined'}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

type MappedDeviceListType = { label: string; value: number };
const getMappedPosAndKioskList = ({
	curDeviceTypeList,
	posInfoList,
	kioskInfoList,
}: {
	curDeviceTypeList?: DeviceType[];
	posInfoList: PosInfoType[];
	kioskInfoList: KioskInfoType[];
}): MappedDeviceListType[] => {
	const curPosInfo = useAtomValue(StoreInfoState.curPosInfo);
	const mappedPosList: MappedDeviceListType[] = posInfoList.map((pos) => ({
		label: `[${PosTypeLabel[pos.posType]}] ${pos.name}${
			pos.id === curPosInfo.id ? ' - 현재 POS' : ''
		}`,
		value: pos.id,
	}));

	const mappedKioskList: MappedDeviceListType[] = kioskInfoList.map((kiosk) => ({
		label: `${kiosk.name} (KIOSK)`,
		value: kiosk.id,
	}));
	return curDeviceTypeList
		? curDeviceTypeList.some((val) => val === 'POS')
			? [...mappedPosList]
			: [...mappedKioskList]
		: [...mappedPosList, ...mappedKioskList];
};
