import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Map, MapMarker, MapProps } from 'react-kakao-maps-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import GrayBox from '../../components/GrayBox';
import { PageHeader } from '../../components/PageHeader';
import { CenterDetailData } from '../../dummyData';
import useCopyClipBoard from '../../hooks/useCopyClipBoard';
import { useVaunceAppRouter } from '../routes';
import backIconImage from 'assets/vaunce/image/back.png';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';

interface SearchResult {
	address_name: string;
	category_group_code: string;
	category_group_name: string;
	category_name: string;
	distance: string;
	id: string;
	phone: string;
	place_name: string;
	place_url: string;
	road_address_name: string;
	x: number | string;
	y: number | string;
}

type SearchResultStatus = 'ERROR' | 'OK' | 'ZERO_RESULT';

export interface ICenterLocalizationPageProps {}
const CenterLocalizationPage: React.FC<ICenterLocalizationPageProps> = ({}) => {
	const { id } = useParams();
	const curCenterId = Number(id);
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	const centerData = centerCollectionData.find((data) => data.centerId === curCenterId);

	const { navigateCenterDetail } = useVaunceAppRouter();
	const navigate = useNavigate();
	// 주소 복사
	const [isCopy, onCopy] = useCopyClipBoard();

	const handleCopyClipBoard = async (text: string) => {
		onCopy(text, '주소복사');
	};

	useEffect(() => {
		searchPlace();
	}, [centerData]);

	// 지도 관련
	const mapRef = useRef<any>(null);

	const [state, setState] = useState<MapProps>({
		center: { lat: 37.49676871972202, lng: 127.02474726969814 },
		isPanto: true,
	});

	const searchPlace = () => {
		const ps = new kakao.maps.services.Places();

		const placeSearchKaKao = (data: SearchResult[], status: SearchResultStatus) => {
			if (status === kakao.maps.services.Status.OK) {
				const newSearch = data[0];
				setState({
					center: { lat: newSearch.y as number, lng: newSearch.x as number },
				});
			}
		};

		const searchCenterData = CenterDetailData.find((data) => data.centerId === curCenterId);

		//@ts-ignore
		if (searchCenterData) ps.keywordSearch(`${searchCenterData.검색주소}`, placeSearchKaKao);
	};

	const zoomIn = () => {
		const map = mapRef.current;
		map.setLevel(map.getLevel() - 1);
	};
	const zoomOut = () => {
		const map = mapRef.current;
		map.setLevel(map.getLevel() + 1);
	};

	return (
		<Stack
			direction={'column'}
			height={'100%'}
			spacing={1}>
			<Stack
				direction={'row'}
				alignItems={'center'}>
				<img
					src={backIconImage}
					height={24}
					width={24}
					onClick={() => {
						navigate(`/centerDetail/${curCenterId}`);
					}}
				/>

				<Box sx={{ flex: 1 }}>
					<Typography
						sx={{ fontSize: '2.5em', fontWeight: 700 }}
						textAlign={'center'}>
						센터위치보기
					</Typography>
				</Box>
			</Stack>

			<GrayBox marginBottom={'1rem'}>
				{centerData && (
					<>
						<Typography variant='h3'>{centerData.centerDisplay}</Typography>
						<Typography
							variant='h6'
							fontWeight={400}>
							{centerData.address}
						</Typography>
						<Stack direction={'row'}>
							<Typography
								variant='h6'
								fontWeight={400}
								color={'primary'}
								marginRight={'1rem'}>
								전화번호
							</Typography>
							<Typography
								variant='h6'
								fontWeight={400}>
								{centerData.centerTel}
							</Typography>
						</Stack>
					</>
				)}
			</GrayBox>

			<Map
				center={state.center}
				isPanto={state.isPanto}
				style={{
					width: '100%',
					height: '100%',
					borderRadius: '12px',
					position: 'relative',
				}}
				level={4}
				// @ts-ignore
				ref={mapRef}>
				<MapMarker
					position={state.center}
					image={{
						src: '//t1.daumcdn.net/localimg/localimages/07/2018/pc/map/marker_map04.png',
						size: {
							width: 45,
							height: 50,
						},
					}}>
					<Stack
						sx={{
							width: centerData ? `바운스 ${centerData.centerDisplay}`.length * 12 : 'auto',
							color: '#000',
							pt: 0.5,
							pl: 1.5,
						}}>
						<a
							href={centerData?.kakaoUrl}
							style={{
								color: '#000000',
								whiteSpace: 'nowrap',
								borderRadius: '3px',
								textDecoration: 'none',
							}}>
							<Typography
								variant='h6'
								fontWeight={500}
								width={'auto'}>
								{centerData && `바운스 ${centerData.centerDisplay}`}
							</Typography>
						</a>
					</Stack>
				</MapMarker>

				<ZoomBox>
					<AddIcon
						onClick={zoomIn}
						sx={{ backgroundColor: 'white' }}
					/>
					<RemoveIcon
						onClick={zoomOut}
						sx={{ backgroundColor: 'white' }}
					/>
					<RefreshIcon
						sx={{ backgroundColor: 'white', marginTop: '1rem' }}
						onClick={() => {
							setState({ center: { lat: 37.49676871972202, lng: 127.02474726969814 } });
							searchPlace();
							mapRef.current.setLevel(3);
						}}
					/>
				</ZoomBox>
			</Map>
			<Stack
				direction={'row'}
				spacing={2}>
				<RoundedButton
					sx={{ flex: 1 }}
					onClick={() => {
						if (centerData) handleCopyClipBoard(centerData?.address);
					}}>
					주소복사
				</RoundedButton>

				<RoundedButton
					sx={{ flex: 1, backgroundColor: '#FFF', color: `${vaunceAppColors.lightPink}` }}
					onClick={() => {
						location.href = `${centerData?.kakaoUrl}`;
					}}>
					길 찾기
				</RoundedButton>
			</Stack>
		</Stack>
	);
};

export default CenterLocalizationPage;

const ZoomBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	bottom: 0,
	right: '3rem',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-end',
	zIndex: '1000',
	width: '32px',
	cursor: 'pointer',
}));
