import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { DdiziType } from '@kinderlabs-pos/shared-data-type';

export const adjustTimeAsync =
	(rootUrl: string) =>
	async (
		{
			storeId,
			posId,
		}: {
			storeId: number;
			posId: number;
		},
		timeAdjustRequestDTOLists: {
			ddiziId: DdiziType['id'];
			time: number;
		}[]
	) =>
		(
			await posAxiosClient.put<DdiziType[]>(
				`${rootUrl}/ddizi/store/${storeId}/pos/${posId}/ddizi/adjust-time`,
				timeAdjustRequestDTOLists
			)
		).data;
