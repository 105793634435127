import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import { Suspense, useState } from 'react';
// import { CartTicketUsageBoard } from './CartTicketUsageBoard';
import { ExchangeBoard } from './ExchangeBoard';
import { MenuTabs } from './MenuTab';
import { PosBoardInMainPage } from './PosBoardInPos/PosBoardInMainPage';
import { AppTicketUsageBoard } from '@kinderlabs-pos/feature/pos/vaunce-integration';
import { OnlineTicketUsageBoard } from '@kinderlabs-pos/feature/pos/online-tickets';

export interface IProductBoardProps {}
export const ProductBoard: React.FC<IProductBoardProps> = ({}) => {
	const [selectedTab, selectTab] = useState<0 | 1 | 2 | 3>(0);

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}>
			<MenuTabs
				selectedTab={selectedTab}
				selectTab={selectTab}
			/>
			<Paper
				sx={(theme) => ({
					flex: 1,
					overflow: 'auto',
					backgroundColor: 'white',
					p: 1,
					border: `2px solid ${theme.palette.primary.main}`,
					borderTopWidth: 0,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					borderBottomLeftRadius: theme.spacing(1.5),
					borderBottomRightRadius: theme.spacing(1.5),
				})}>
				<Suspense
					fallback={
						<Stack
							width={'100%'}
							height={'100%'}
							alignItems={'center'}
							justifyContent={'center'}>
							<CircularProgress />
						</Stack>
					}>
					<Box sx={{ height: '100%', overflow: 'auto' }}>
						{selectedTab === 0 && <PosBoardInMainPage />}
						{/* {selectedTab === 1 && <CartTicketUsageBoard />} */}
						{selectedTab === 1 && <AppTicketUsageBoard />}
						{selectedTab === 2 && <OnlineTicketUsageBoard />}
						{selectedTab === 3 && <ExchangeBoard />}
					</Box>
				</Suspense>
			</Paper>
		</Stack>
	);
};
