import { whitespace } from '@kinderlabs-pos/shared-util';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { FormikProvider } from 'formik';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { VaunceOptionButton } from '../../components/VaunceOptionButton';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { LoginPageHeader } from './LoginPageHeader';

export interface IFindPwPageProps {}
export const FindPwPage: React.FC<IFindPwPageProps> = ({}) => {
	const { useFindPasswordFormik } = vaunceAppFormiks();
	const formik = useFindPasswordFormik();
	const {
		values,
		handleChange,
		isSubmitting,
		handleSubmit,
		errors,
		handleBlur,
		isValid,
		touched,
		setFieldValue,
	} = formik;

	return (
		<Stack direction={'column'}>
			<LoginPageHeader />
			<LoginHeaderTypograpy>비밀번호 찾기</LoginHeaderTypograpy>
			<FormikProvider value={formik}>
				<Stack spacing={3}>
					<TextField
						name='id'
						onChange={handleChange}
						onBlur={handleBlur}
						sx={{ fontSize: '16px' }}
						label={<Typography variant='h6'>아이디</Typography>}
						variant='standard'
						placeholder={'이메일 주소를 입력하세요.'}
						helperText={
							<Typography color={'primary'}>{(touched.id && errors.id) || whitespace}</Typography>
						}
					/>
					<TextField
						name='birth'
						onChange={handleChange}
						onBlur={handleBlur}
						sx={{ fontSize: '16px' }}
						label={<Typography variant='h6'>생년월일</Typography>}
						variant='standard'
						placeholder={'생년월일 8자리를 입력하세요.'}
						helperText={
							<Typography color={'primary'}>
								{(touched.birth && errors.birth) || whitespace}
							</Typography>
						}
					/>
					<TextField
						name='phoneNum'
						onChange={handleChange}
						onBlur={handleBlur}
						sx={{ fontSize: '16px' }}
						label={<Typography variant='h6'>휴대전화번호</Typography>}
						variant='standard'
						placeholder={'(-)없이 휴대전화 번호를 입력하세요.'}
						helperText={
							<Typography color={'primary'}>
								{(touched.phoneNum && errors.phoneNum) || whitespace}
							</Typography>
						}
					/>

					<Stack direction={'row'}>
						<VaunceOptionButton
							text='휴대전화 수신'
							options={values.reception !== 'phone'}
							onClick={() => {
								setFieldValue('reception', 'phone');
								loggingEvent('비밀번호 찾기 > 휴대전화 수신', 'login', 'findPWWithPhone');
							}}
						/>

						<VaunceOptionButton
							text='이메일 수신'
							options={values.reception !== 'email'}
							onClick={() => {
								setFieldValue('reception', 'email');
								loggingEvent('비밀번호 찾기 > 이메일 수신', 'login', 'findPWWithEmail');
							}}
						/>
					</Stack>

					<Stack direction={'row'}>
						<RoundedSubmitButton
							disabled={!isValid}
							isSubmitting={isSubmitting}
							onClick={() => {
								handleSubmit();
								loggingEvent('비밀번호 찾기', 'login', 'findPW');
							}}
							sx={{ height: 58, width: '100%' }}
							text={'비밀번호 찾기'}
						/>
					</Stack>
				</Stack>
			</FormikProvider>
		</Stack>
	);
};
