import { SelectOutlined } from '@ant-design/icons';
import { OrderType } from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { IconButton, Stack, Typography } from '@mui/material';
import { usePosRouter } from '../routes';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '@kinderlabs-pos/state';

export const ExitPageOrderInfo = ({ selectedOrder }: { selectedOrder: OrderType }) => {
	const curStoreInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { navigate주문조회 } = usePosRouter();

	return (
		<Stack
			flex={1}
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}>
			<Stack>
				<Typography variant='h4'>{`주문번호 ${selectedOrder.id}  `}</Typography>
				<Typography variant='h5'>
					{selectedOrder.guestMemberInfo ? getUserInfoLabel(selectedOrder.guestMemberInfo) : '-'}
				</Typography>
			</Stack>
			<IconButton
				size={'small'}
				disabled={curStoreInfo.id !== selectedOrder.storeId}
				onClick={() => {
					navigate주문조회({ keyword: `${selectedOrder.id}` });
				}}>
				<SelectOutlined style={{ fontSize: 22 }} />
			</IconButton>
		</Stack>
	);
};

const getUserInfoLabel = (guestUsersInfo: NonNullable<OrderType['guestMemberInfo']>) =>
	`${guestUsersInfo.name} (${getTelephoneNumberWithDash(guestUsersInfo.telephone)})`;
