import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { OrderState, PosElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { TelephoneBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, Stack, Typography, alpha } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

const defaultMessage = '단말기에 전화번호를 입력해주세요.';
interface TelephoneDialogProps extends XlDialogProps {
	onNavigatePayment: () => void;
}
export const TelephoneDialog = ({ onNavigatePayment, open, closeDialog }: TelephoneDialogProps) => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();
	const { guestMemberInfo } = useAtomValue(OrderState.value);

	const [value, setValue] = useState<string>('010');
	const [paymentModuleMessage, setPaymentModuleMessage] = useState(defaultMessage);

	const [temp알림톡이라도직접입력, setTemp알림톡이라도직접입력] = useState(false);
	const mainPageQuery = PosElectronStoreState.usePosMainPageLocalSettings();
	const 알림톡전화번호결제모듈로직접 = useMemo(
		() =>
			mainPageQuery.data?.using알림톡전화번호입력시결제모듈로전화번호입력설정
				? !temp알림톡이라도직접입력
				: false,
		[
			mainPageQuery.data?.using알림톡전화번호입력시결제모듈로전화번호입력설정,
			temp알림톡이라도직접입력,
		]
	);

	const cartReducer = useSetAtom(OrderState.value);

	useEffect(() => {
		if (알림톡전화번호결제모듈로직접)
			(async () => {
				setPaymentModuleMessage(defaultMessage);
				try {
					const { responseMessage: 입력받은숫자 } = await ReactPMIPC.invoke서명패드숫자입력요청({
						vanCompany,
					});

					setValue(입력받은숫자);
					setPaymentModuleMessage('');
				} catch (e) {
					//@ts-ignore
					setPaymentModuleMessage(e?.message);
				}
			})();

		return () => {
			if (알림톡전화번호결제모듈로직접) ReactPMIPC.invoke결제강제취소({ vanCompany });
		};
	}, [알림톡전화번호결제모듈로직접, vanCompany]);

	const handlePaymentWithoutConfirm = () => {
		cartReducer({
			type: 'ADD_TELEPHONE',
			telephone: undefined,
		});

		onNavigatePayment();
		closeDialog && closeDialog();
	};

	const handleConfirm = () => {
		if (value)
			cartReducer({
				type: 'ADD_TELEPHONE',
				telephone: value,
			});

		onNavigatePayment();
		closeDialog && closeDialog();
	};

	return (
		<WithDialog.XlDialog
			dialogTitle={'전화번호 입력'}
			open={open}
			closeDialog={closeDialog}>
			<DialogContent>
				<Stack
					spacing={3}
					pb={5}>
					<Stack
						direction={'row'}
						spacing={3}>
						<Stack
							minWidth={320}
							minHeight={300}
							flex={1}
							spacing={1}
							alignItems={'center'}
							justifyContent={'center'}
							sx={(theme) => ({ bgcolor: alpha(theme.palette.primary.main, 0.1) })}>
							<Stack
								flex={1}
								spacing={1}
								alignItems={'center'}
								justifyContent={'center'}>
								<Typography
									variant={'subtitle1'}
									color={'text.secondary'}>
									전화번호
								</Typography>
								<Typography
									variant={'h1'}
									sx={{ fontSize: 28 }}>
									{value ? getTelephoneNumberWithDash(value) : '-'}
								</Typography>
							</Stack>
						</Stack>
						<Stack
							minWidth={
								mainPageQuery.data?.using알림톡전화번호입력시결제모듈로전화번호입력설정
									? 320
									: undefined
							}>
							{!알림톡전화번호결제모듈로직접 && (
								<Stack alignItems={'flex-end'}>
									<TelephoneBoard
										value={value}
										setValue={setValue}
										disabled={알림톡전화번호결제모듈로직접}
										mode='telephone'
									/>
								</Stack>
							)}
							{알림톡전화번호결제모듈로직접 && (
								<Stack
									flex={1}
									justifyContent={'center'}
									alignItems={'center'}
									spacing={1}>
									<Typography
										variant='h5'
										color='primary'>
										{paymentModuleMessage}
									</Typography>
									<Button
										variant='outlined'
										onClick={() => setTemp알림톡이라도직접입력(true)}>
										화면에서 입력
									</Button>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					width={'100%'}
					justifyContent={'space-between'}>
					<Button
						onClick={handlePaymentWithoutConfirm}
						color={'secondary'}
						variant='contained'>
						전화번호 없이 결제
					</Button>
					<Stack
						direction={'row'}
						spacing={1}>
						{guestMemberInfo && (
							<Button
								onClick={() => setValue(guestMemberInfo.telephone)}
								variant='contained'>
								{'응대 고객 번호 입력'}
							</Button>
						)}
						<Button
							disabled={!(value.length === 11)}
							onClick={handleConfirm}
							variant='contained'>
							결제하기
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								setValue('010');
								closeDialog && closeDialog();
							}}>
							닫기
						</Button>
					</Stack>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
