import { Box, BoxProps, styled, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { ActionBoardItem, EmptySquareCard, HiddenSquareCard, NoneSquareCard } from './Item';

export interface IActionBoardProps extends BoxProps {
	numOfColumns: number;
	children: ReactNode[];
	ItemSx?: BoxProps['sx'];
}

export const ActionBoard: React.FC<IActionBoardProps> & {
	Item: typeof ActionBoardItem;
	HiddenItem: typeof HiddenSquareCard;
	EmptyItem: typeof HiddenSquareCard;
	NoneItem: typeof NoneSquareCard;
} = ({ children, numOfColumns, ItemSx, sx, ...others }) => {
	const numOfRows = Math.ceil(children.length / numOfColumns);
	// const numOfPadItems = Math.ceil(children.length / numOfColumns) * numOfColumns - children.length;
	const theme = useTheme();

	return (
		<StyledGrid
			sx={{
				'--gap': theme.spacing(0.5),
				'--cols': numOfColumns,
				'--rows': numOfRows,
				...sx,
			}}
			{...others}>
			{children.map((child, idx) => (
				<Box
					sx={{
						...ItemSx,
						'& > div': { height: '100%', overflow: 'hidden', width: '100%' },
					}}
					key={idx}>
					{child}
				</Box>
			))}
			{/* {[...Array(numOfPadItems).keys()].map((child, idx) => (
				<Box
					key={idx}
					sx={{ ...ItemSx, '&>div': { height: '100%' } }}
				/>
			))} */}
		</StyledGrid>
	);
};

const StyledGrid = styled(Box)(({ theme }) => ({
	display: 'grid',
	'--grid-column-width':
		'calc((100% / var(--cols)) - ((var(--gap) * (var(--cols) - 1) ) / var(--cols)) )',
	gridTemplateColumns: `repeat(var(--cols), var(--grid-column-width))`,
	gridTemplateRows: `repeat(var(--rows), 1fr)`,
	gridGap: 'var(--gap)',
}));

ActionBoard.Item = ActionBoardItem;
ActionBoard.EmptyItem = EmptySquareCard;
ActionBoard.HiddenItem = HiddenSquareCard;
ActionBoard.NoneItem = NoneSquareCard;
