import { numberWithCommas, getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { List, SquareTextField, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

export interface IWithCustomProductDialogProps {
	children: (onClick: () => void) => ReactNode;
	closeETCDialog: () => void;
}
export const WithCustomProductDialog: React.FC<IWithCustomProductDialogProps> = ({
	children,
	closeETCDialog,
}) => {
	const [value, setValue] = useState(0);
	const cartDispatcher = OrderState.cart.useDispatcher();

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<WithDialog.XlDialog
					maxWidth={'lg'}
					dialogTitle={'임의 상품'}
					open={open}
					closeDialog={closeDialog}>
					<DialogContent>
						<Stack
							direction={'row'}
							spacing={5}>
							<List>
								<List.ItemButton
									selected
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										gap: 1,
									}}>
									<Typography>{'임의 가격 :'}</Typography>
									{/* 맨 처음의 quantity 는 변경하기 위한 value 이므로 삭제가 되게 해주어야 한다. 그래서 value, defaultValue 구분이 있다. */}
									<SquareTextField
										focused
										sx={{ width: 180 }}
										value={numberWithCommas(value || undefined)}
										onChange={(e) => {
											setValue(+e.currentTarget.value);
										}}
									/>
								</List.ItemButton>
							</List>
							<NumberBoard
								value={value}
								setValue={setValue}
							/>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								cartDispatcher({
									type: 'CUSTOM',
									subAction: {
										type: 'ADD',
										cartLineId: getUuidV4(),
										productInfo: {
											name: '임의 상품',
											price: value,
										},
									},
								});
								closeDialog();
								closeETCDialog();
							}}>
							{'확인'}
						</Button>
						<Button
							onClick={() => {
								setValue(0);
								closeDialog();
							}}>
							{'취소'}
						</Button>
					</DialogActions>
				</WithDialog.XlDialog>
			)}>
			{children}
		</WithDialog>
	);
};
