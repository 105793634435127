import { DdiziApi } from '@kinderlabs-pos/apis/pos';
import {
	CursorPageType,
	DdiziType,
	DdiziTypeToJungsan,
	Pageable,
} from '@kinderlabs-pos/shared-data-type';
import { DdiziState, QueryClient, StoreInfoState, authState } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';

type DdiziListParamsType = {
	targetPosId?: number;
	storeIdList?: number[];
	page: number;
	size?: number;
	search?: string;
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
	status?: DdiziType['status'];
};

type DdiziListInfiniteParamsType = Pick<
	DdiziListParamsType,
	'storeIdList' | 'status' | 'search' | 'from' | 'to'
> & {
	posId?: number;
};

const ddiziKeys = createQueryKeys('ddizi', {
	list: ({ params }: { params: DdiziListParamsType }) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				const res = await DdiziApi.getList({
					...params,
					storeIdList: params.storeIdList || storeIdList,
					pageable: Pageable.getPageable(params),
					from: params.from.startOf('d'),
					to: params.to.endOf('d'),
					search: params.search,
				});

				return res;
			},
		};
	},
	infinite: ({ params }: { params: DdiziListInfiniteParamsType }) => {
		const curStoreInfo = useAtomValue(StoreInfoState.curStoreInfo);
		return {
			queryKey: [params],
			queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
				const res = await DdiziApi.getInfiniteList({
					search: params.search,
					status: params.status,
					cursorId: pageParam?.startCursorId,
					storeIdList: params.storeIdList,
					posId: params.posId,
					from: params.from,
					to: params.to,
					pageable: Pageable.getPageable({
						page: 0,
						size: 20,
						sort: { name: 'publishInfo.published', direction: 'desc' },
					}),
				});

				const now = new Date();

				const result: CursorPageType<DdiziTypeToJungsan> = {
					...res,
					content: res.content.map((ddizi) =>
						DdiziState.utils.getDdiziToJungsan({
							ddizi,
							timeStandard: now,
							overtimePolicy: curStoreInfo.overtime,
						})
					),
				};

				return {
					content: result.content,
					isLast: !result.hasMore,
					startCursorId: result.startCursorId,
				};
			},
		};
	},
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries(ddiziKeys._def);
	QueryClient.origin.invalidateQueries(ddiziKeys._def);
};

export const DdiziQueryState = {
	keys: ddiziKeys,
	invalidateQueries,
};
