import { LabelRequestType } from './LabelPrinterType';

export const LabelRequestDummy: LabelRequestType = {
	message: {
		labelType: 'NORMAL',
		labelId: '0731004717',
		labelName: '플레이존',
		timeEnter: new Date(),
		timeExit: new Date(),
		timeUse: '2시간권',
	},
	deviceNum: 'LabelPrinter1',
	deviceType: 'POS',
	newDdizi: false,
};
