import { NormalQuestionTypeArray, NormalQuestionTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import {
	FormControl,
	InputLabel,
	NativeSelect,
	Stack,
	TextField,
	Typography,
	SxProps,
} from '@mui/material';
import { FormikProvider } from 'formik';
import { PageHeader } from '../../components/PageHeader';
import { VaunceHelperText } from '../../components/VaunceHelperText';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';

export interface IQuestionWriteProps {}
export const NormalQuestionToWrite: React.FC<IQuestionWriteProps> = ({}) => {
	const { useNormalQuestionFormik } = vaunceAppFormiks();
	const formik = useNormalQuestionFormik();
	const {
		values,
		setValues,
		handleChange,
		isSubmitting,
		handleSubmit,
		errors,
		handleBlur,
		isValid,
		touched,
		setFieldValue,
	} = formik;

	const spacing = 3;
	return (
		<Stack>
			<PageHeader title={'1:1 문의'} />
			<FormikProvider value={formik}>
				<Stack
					flex={1}
					spacing={spacing}>
					<Stack>
						<FormControl
							variant='standard'
							fullWidth
							sx={{ fontSize: '16px', pt: 2 }}>
							<InputLabel>
								<Typography
									variant='h6'
									fontWeight={500}>
									문의유형
								</Typography>
							</InputLabel>
							<NativeSelect
								sx={{
									fontSize: '16px',
								}}
								variant='standard'
								value={values.category}
								name='category'
								onChange={(e) => {
									setFieldValue('category', e.target.value);
								}}
								onBlur={handleBlur}>
								{NormalQuestionTypeArray.map((item, idx) => (
									<option
										value={item}
										key={idx}>
										{NormalQuestionTypeLabel[item]}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Stack>
					<TextField
						sx={{ fontSize: '16px' }}
						fullWidth
						InputProps={{
							style: { fontSize: '16px', fontWeight: 400, opacity: 0.6 },
						}}
						label={
							<Typography
								variant='h6'
								fontWeight={400}>
								제목
							</Typography>
						}
						type={'text'}
						variant='standard'
						placeholder={'제목을 입력해주세요.'}
						value={values.title}
						name='title'
						onChange={handleChange}
						onBlur={handleBlur}
						helperText={
							<Typography
								variant='h6'
								fontWeight={500}
								component={'span'}
								color={'primary'}>
								{touched.title ? errors.title : ' '}
							</Typography>
						}
					/>
					<TextField
						InputProps={{
							style: { fontSize: '16px', fontWeight: 400, opacity: 0.6 },
						}}
						fullWidth
						type={'text'}
						variant='standard'
						multiline
						rows={5}
						placeholder={'내용을 간단히 작성해주세요.'}
						value={values.memo}
						name='memo'
						onChange={handleChange}
						onBlur={handleBlur}
						helperText={
							<Typography
								variant='h6'
								fontWeight={500}
								component={'span'}
								color={'primary'}>
								{touched.memo ? errors.memo : ' '}
							</Typography>
						}
					/>
				</Stack>
				<RoundedSubmitButton
					disabled={!isValid}
					type='submit'
					isSubmitting={isSubmitting}
					text={'문의 접수'}
					sx={{ width: '100%', mt: spacing }}
					onClick={() => {
						handleSubmit();
					}}
				/>
			</FormikProvider>
			<Stack
				spacing={1}
				my={1}>
				<Typography
					variant='h5'
					color={vaunceAppColors.lightPink}>
					[안내 사항]
				</Typography>
				<Typography variant='h5'>
					- 1:1 문의는 실시간이 아닌, 순차적으로 답변이 이루어집니다.
				</Typography>
				<Typography variant='h5'>
					- 1회권 환불은 'My페이지 → 고객센터 → 이용권 환불' 메뉴에서 직접 환불이 가능합니다.
				</Typography>
			</Stack>
		</Stack>
	);
};
