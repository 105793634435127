import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PosStatusResponseType } from '@kinderlabs-pos/shared-data-type';

export const getPosStatusAsync = (rootUrl: string) => {
	return async ({ posId }: { posId: number }) =>
		(
			await posAxiosClient.get<PosStatusResponseType>(`${rootUrl}/pos-bff/status`, {
				params: {
					posId,
				},
			})
		).data;
};

export const getPosStatusListAsync = (rootUrl: string) => {
	return async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<PosStatusResponseType[]>(`${rootUrl}/pos-bff/status/list`, {
				params: {
					storeId,
				},
			})
		).data;
};
