import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const downloadExcelForIndemnity =
	(rootUrl: string) =>
	({ from, to, storeIdList, status }: AdminGuestVisitRequestType) => {
		const url = new URL(`${rootUrl}/excel/indemnity/agreement`);

		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (status) url.searchParams.append('status', status);
		if (from) url.searchParams.append('from', from.format('YYYY-MM-DD'));
		if (to) url.searchParams.append('to', to.format('YYYY-MM-DD'));

		return `${url}`;
	};

export type AdminGuestVisitRequestType = {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
	status?: GuestVisitType['status'];
};
