import {
	PosBoardDeviceType,
	ProductDiscountInfoType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas, numberWithCommasAnd원, replaceAll } from '@kinderlabs-pos/shared-util';
import { Stack, Typography } from '@mui/material';

interface Props {
	discountInfo?: Omit<ProductDiscountInfoType, 'evaluatedValue'>;
	productInfo: ProductInfoType;
	label?: string;
	deviceType: PosBoardDeviceType;
}
export const PosBoardLabel = ({ discountInfo, productInfo, label, deviceType }: Props) => {
	const labelOnPos = label?.replace('\\n', '\n') || '';
	return (
		<Stack
			flexDirection={'column'}
			justifyContent='center'
			alignItems={'center'}
			gap={0.5}>
			<Typography
				variant={deviceType === 'POS' ? 'body2' : 'subtitle1'}
				sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
				{deviceType === 'POS' && labelOnPos.length > 20 ? (
					<Typography style={{ fontSize: `1rem` }}>{labelOnPos}</Typography>
				) : (
					labelOnPos
				)}
			</Typography>
			<Typography variant='body2'>{numberWithCommasAnd원(productInfo?.price || 0)}</Typography>
			{discountInfo && (
				<Typography variant='body2'>
					{`-${numberWithCommas(discountInfo.value || 0)} ${
						discountInfo.type === 'PERCENTAGE' ? '%' : '원'
					}`}
				</Typography>
			)}
		</Stack>
	);
};
