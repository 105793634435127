export const ipcApiName = 'electronAPI';

export const ipcCommonCommandApiName = 'electronCommonCommandApi';

export const ipcPMApiName = 'electronPMAPI';
export const ipcRPApiName = 'electronRPAPI';
export const ipcLPApiName = 'electronLPAPI';

export const ipcPosMainPageApiName = 'electronPosMainPageAPI';
export const ipcPosSubPageApiName = 'electronPosSubPageAPI';

export const ipcKioskApiName = 'electronKioskPageAPI';

export const ipcEventExternalWindowApiName = 'external-window';
