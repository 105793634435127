import { rootUrl } from '../const';
import { getCmtDetailAsync } from './cmt/getCmtDetailAsync';
import { getCmtListAsync } from './cmt/getCmtListAsync';
import { useCmtAsync } from './cmt/uesCmtAsync';
import { cancelUseCmtAsync } from './cmt/cancelUseCmtAsync';
import { getMutListAsync } from './mut/getMutListAsync';
import { getMutDetailAsync } from './mut/getMutDetailAsync';
import { cancelUseMutAsync } from './mut/cancelUseMutAsync';
import { useMutAsync } from './mut/useMutAsync';
import { searchCmtListAsync } from './cmt/searchCmtListAsync';
import { searchMutListAsync } from './mut/searchMutListAsync';

export const CmtAPI = {
  getListAsync: getCmtListAsync(rootUrl),
  searchListAsync: searchCmtListAsync(rootUrl),
  getDetailAsync: getCmtDetailAsync(rootUrl),
  useAsync: useCmtAsync(rootUrl),
  cancelUseAsync: cancelUseCmtAsync(rootUrl),
};

export const MutAPI = {
  getListAsync: getMutListAsync(rootUrl),
  searchListAsync: searchMutListAsync(rootUrl),
  getDetailAsync: getMutDetailAsync(rootUrl),
  useAsync: useMutAsync(rootUrl),
  cancelUseAsync: cancelUseMutAsync(rootUrl),
};
