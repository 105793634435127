import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { ChildListType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Checkbox,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { EmptyContent } from '../../components/EmptyContent';
import { FormattedDialog } from '../../components/FormattedDialog';

export interface ICallChildListDialogProps {
	open: boolean;
	closeDialog: () => void;
	onClick: (val: ChildListType[]) => void;
	checkIdxList: boolean[];
	setCheckIdxList: React.Dispatch<React.SetStateAction<boolean[]>>;
}
export const CallChildListDialog: React.FC<ICallChildListDialogProps> = ({
	open,
	closeDialog,
	onClick,
	checkIdxList,
	setCheckIdxList,
}) => {
	const navigate = useNavigate();
	const myUsage = VaunceQueryState.useUserUsageQuery();

	const isAllChecked =
		checkIdxList.filter((item) => item === true).length === myUsage.childList.length;
	const clickedChildRegister = useSetAtom(VaunceState.goChildRegisterPage);

	return (
		<FormattedDialog
			open={open}
			closeDialog={closeDialog}>
			<Stack
				flex={1}
				overflow={'auto'}
				spacing={2}
				sx={{ pb: 5 }}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}>
					<Typography variant='h1'>자녀 목록</Typography>
				</Stack>
				{myUsage.childList.length === 0 ? (
					<Box>
						<EmptyContent title={''} />
						<Stack alignItems={'center'}>
							<Typography
								variant='h3'
								data-cy='registerChildEmpty'>
								{'등록된 자녀가 없습니다.'}
							</Typography>
							<Typography variant='h3'>{'자녀등록을 해주시기 바랍니다.'}</Typography>
						</Stack>
					</Box>
				) : (
					<Stack
						flex={1}
						overflow={'inherit'}>
						<Table>
							<TableHead
								sx={{
									borderTop: `1px solid ${vaunceColors.lightGray}`,
									bgcolor: vaunceColors.lightGray,
								}}>
								<TableRow>
									<TableCell align='center'>
										<Checkbox
											checked={isAllChecked}
											onChange={() => {
												isAllChecked
													? setCheckIdxList(myUsage.childList.map((item) => false))
													: setCheckIdxList(myUsage.childList.map((item) => true));
											}}
										/>
									</TableCell>
									<TableCell
										data-cy='registerChildNameLabel'
										align='center'>
										{'이름'}
									</TableCell>
									<TableCell
										data-cy='registerChildGenderLabel'
										align='center'>
										{'성별'}
									</TableCell>
									<TableCell
										data-cy='registerChildRelationLabel'
										align='center'>
										{'관계'}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody data-cy='registerChildTableBody'>
								{myUsage.childList.map((child, idx) => (
									<TableRow
										key={idx}
										data-cy='registerChildTableRow'>
										<TableCell align='center'>
											<Checkbox
												checked={checkIdxList[idx]}
												onClick={() => {
													const newArray = [...checkIdxList];
													newArray[idx] = !newArray[idx];
													setCheckIdxList(newArray);
												}}
											/>
										</TableCell>
										<TableCell
											align='center'
											data-cy='registerChildNameValue_${idx}'>
											{child.name}
										</TableCell>
										<TableCell
											align='center'
											data-cy={`registerChildGenderValue_${idx}`}>
											{child.gender === 'F' ? '여' : '남'}
										</TableCell>
										<TableCell
											align='center'
											data-cy={`registerChildRelationValue_${idx}`}>
											{child.relation === 2 ? '딸' : '아들'}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Stack>
				)}
				<RoundedButton
					data-cy='registerChildBtn'
					onClick={
						myUsage.childList.length === 0
							? () => {
									clickedChildRegister(true);
									navigate('/info');
							  }
							: () => {
									const checkedIdxArray = checkIdxList
										.map((item, idx) => (item === true ? idx : undefined))
										.filter((item) => typeof item === 'number');

									const addedChildList = myUsage.childList.filter((_, idx) =>
										checkedIdxArray.includes(idx)
									);

									onClick(addedChildList);
									closeDialog();
							  }
					}>
					{myUsage.childList.length === 0 ? '자녀등록 하러가기' : '추가'}
				</RoundedButton>
			</Stack>
		</FormattedDialog>
	);
};
