import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

export interface ITicketExitButtonProps extends IconButtonProps {}
export const TicketExitButton: React.FC<ITicketExitButtonProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <IconTextButton
      onClick={onClick}
      disabled={disabled}
      icon={<LogoutIcon fontSize="small" />}
      label={<Typography>{'티켓퇴장'}</Typography>}
    />
  );
};
