import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export const getProductInfoDetailAsync =
	(rootUrl: string) =>
	async ({ productId }: { productId: number }) =>
		(
			await posAxiosClient.get<ProductInfoType & BaseInfoType>(`${rootUrl}/product`, {
				params: {
					id: productId,
				},
			})
		).data;
