import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { SettingMenuItem } from '../common';

import {
	AdminStoreInfoState,
	authState,
	PosElectronStoreState,
	PosState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

export const AutomationPage = () => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { data: storeInfoDetail } = useQuery({
		...AdminStoreInfoState.keys.detail(storeId),
		refetchOnWindowFocus: true,
		refetchInterval: 20 * 1000,
	});
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);

	const updateStoreInfo = AdminStoreInfoState.useUpdateStoreInfo();
	const [settingAtom, setSettingAtom] = useAtom(PosState.settings);
	const customAlert = useAlert();

	const { 주방주문증폰트크게, set주방주문증폰트크게 } = use주방주문증폰트크게();
	const { 내역기본포스설정, set내역기본포스설정 } = use내역기본포스설정();
	const { 결제완료후자동이전페이지, set결제완료후자동이전페이지 } = use결제완료후자동이전페이지();
	const { 주문취소시취소주방영수증출력, set주문취소시취소주방영수증출력 } =
		use주문취소시취소주방영수증출력설정();
	const {
		알림톡전화번호입력시결제모듈로전화번호입력설정,
		set알림톡전화번호입력시결제모듈로전화번호입력설정,
	} = use알림톡전화번호입력시결제모듈로전화번호입력설정();
	const { using응대고객없는경우정보입력활성화설정, set응대고객없는경우정보입력활성화 } =
		useFNBPOS응대고객없는경우결제전고객정보활성화설정();
	const { using사이드바기본설정, set사이드바기본설정 } = use사이드바기본설정();
	const { callWith응대, setCallWith응대 } = useCallWith응대설정();

	return (
		<Stack
			sx={{ overflow: 'auto' }}
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='영수증 자동 출력'
				innerElement={
					<FormControlLabel
						control={<Switch checked={settingAtom.autoReceiptPrintOn} />}
						onChange={() => {
							setSettingAtom((prev) => ({ ...prev, autoReceiptPrintOn: !prev.autoReceiptPrintOn }));

							customAlert('영수증 자동 출력 설정을 수정하였습니다.');
						}}
						label={null}
					/>
				}
			/>
			{storeInfoDetail && (
				<SettingMenuItem
					title='회원/대기 등록 잠금'
					innerElement={
						<FormControlLabel
							control={<Switch checked={storeInfoDetail.lockWaivers} />}
							onChange={() =>
								updateStoreInfo.mutateAsync(
									{
										...storeInfoDetail,
										lockWaivers: !storeInfoDetail.lockWaivers,
									},
									{
										onSuccess: () => {
											customAlert('최대 20초 안에 회원/대기 등록 잠금 설정이 반영됩니다.');
										},
									}
								)
							}
							label={null}
						/>
					}
				/>
			)}
			<SettingMenuItem
				title='주방 주문증 폰트 크게'
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={주방주문증폰트크게}
									onChange={(e) => {
										set주방주문증폰트크게(e.target.checked);
										customAlert('주방 주문증 폰트를 크게 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>주방 주문증 폰트 크게 설정합니다.</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='기본 포스 필터 설정'
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={내역기본포스설정}
									onChange={(e) => {
										set내역기본포스설정(e.target.checked);
										customAlert('검색 내역 기본 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>
							주문내역 검색시 현재 사용중인 포스의 검색을 기본으로 설정합니다.
						</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='결제 완료후 페이지 전환'
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={결제완료후자동이전페이지}
									onChange={(e) => {
										set결제완료후자동이전페이지(e.target.checked);
										customAlert('결제 완료후 페이지 전환 이동 기능 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>
							결제 완료 후 프린트가 모두 끝나면 이전 페이지로 자동으로 이동합니다.
						</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='주문 취소시 취소 주방영수증 출력 여부'
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={주문취소시취소주방영수증출력}
									onChange={(e) => {
										set주문취소시취소주방영수증출력(e.target.checked);
										customAlert('주문 취소시 취소 주방영수증 출력 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>
							주문내역 검색시 현재 사용중인 포스의 검색을 기본으로 설정합니다.
						</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='주방 주문 전화번호 입력 설정'
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={알림톡전화번호입력시결제모듈로전화번호입력설정}
									onChange={(e) => {
										set알림톡전화번호입력시결제모듈로전화번호입력설정(e.target.checked);
										customAlert('주방 주문시 전화번호 입력 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>
							주방 주문시 결제 단말기로 고객에게 직접 전화번호 입력을 받도록 설정합니다. (KICC 전용)
						</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title={'응대 고객 없을 시,\n응대 고객 정보 입력 활성화'}
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={using응대고객없는경우정보입력활성화설정}
									onChange={(e) => {
										set응대고객없는경우정보입력활성화(e.target.checked);
										customAlert('응대 고객 정보 입력 활성화 설정이 변경되었습니다.');
									}}
								/>
							}
							label={null}
						/>
						<Typography>
							주방 주문 결제 시, 응대 고객이 없는 경우 고객 정보 입력을 먼저 받도록 설정합니다.
						</Typography>
					</Stack>
				}
			/>
			<SettingMenuItem
				title={'사이드바 기본 화면 설정'}
				innerElement={
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={using사이드바기본설정 === 'VISIT'}
									onChange={(e) => {
										set사이드바기본설정(e.target.checked ? 'VISIT' : 'MEMBER');
										customAlert(
											`사이드바 기본 화면 설정이 ${
												e.target.checked ? '대기중 고객 방문검색' : 'POS 회원 검색'
											}으로 변경되었습니다.`
										);
									}}
								/>
							}
							label={null}
						/>
						<Typography>ON: '대기중' 고객 방문검색, OFF: POS 회원검색</Typography>
					</Stack>
				}
			/>
			{enterpriseInfo?.id === 'zoolung' && (
				<SettingMenuItem
					title={'호출과 함께 응대 설정'}
					innerElement={
						<Stack>
							<FormControlLabel
								control={
									<Switch
										checked={callWith응대}
										onChange={(e) => {
											setCallWith응대(e.target.checked ? true : false);
											customAlert(`호출과 응대가 동시에 진행됩니다.`);
										}}
									/>
								}
								label={null}
							/>
							<Typography>ON: 고객 '호출'시 자동으로 응대가 시작됩니다.</Typography>
						</Stack>
					}
				/>
			)}
		</Stack>
	);
};

const use주방주문증폰트크게 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		주방주문증폰트크게: localSettings.data?.usingLargeTextOnKitchenReceiptPrinter ?? false,
		set주방주문증폰트크게: (usingLargeTextOnKitchenReceiptPrinter: boolean) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					usingLargeTextOnKitchenReceiptPrinter,
				});
		},
	};
};

const use주문취소시취소주방영수증출력설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		주문취소시취소주방영수증출력: localSettings.data?.usingKitchenCancelBills ?? false,
		set주문취소시취소주방영수증출력: (usingKitchenCancelBills: boolean) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					usingKitchenCancelBills,
				});
		},
	};
};

const use내역기본포스설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		내역기본포스설정: localSettings.data?.usingDefaultPosFilter ?? false,
		set내역기본포스설정: (usingDefaultPosFilter: boolean) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					usingDefaultPosFilter,
				});
		},
	};
};

const use결제완료후자동이전페이지 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		결제완료후자동이전페이지: localSettings.data?.goAutoMainPageAfterCompleteOrder ?? false,
		set결제완료후자동이전페이지: (goAutoMainPageAfterCompleteOrder: boolean) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					goAutoMainPageAfterCompleteOrder,
				});
		},
	};
};

const use알림톡전화번호입력시결제모듈로전화번호입력설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		알림톡전화번호입력시결제모듈로전화번호입력설정:
			localSettings.data?.using알림톡전화번호입력시결제모듈로전화번호입력설정 ?? false,
		set알림톡전화번호입력시결제모듈로전화번호입력설정: (
			using알림톡전화번호입력시결제모듈로전화번호입력설정: boolean
		) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					using알림톡전화번호입력시결제모듈로전화번호입력설정,
				});
		},
	};
};

const use사이드바기본설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		using사이드바기본설정: localSettings.data?.defaultSidebar ?? 'VISIT',
		set사이드바기본설정: (defaultSidebar: 'VISIT' | 'MEMBER') => {
			if (localSettings.data) {
				saveLocalSettings.mutate({
					...localSettings.data,
					defaultSidebar,
				});
			}
		},
	};
};

const useFNBPOS응대고객없는경우결제전고객정보활성화설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		using응대고객없는경우정보입력활성화설정:
			localSettings.data?.using응대고객없는경우정보입력활성화설정 ?? false,
		set응대고객없는경우정보입력활성화: (using응대고객없는경우정보입력활성화설정: boolean) => {
			if (localSettings.data) {
				saveLocalSettings.mutate({
					...localSettings.data,
					using응대고객없는경우정보입력활성화설정,
				});
			}
		},
	};
};

const useCallWith응대설정 = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return {
		callWith응대: localSettings.data?.callWith응대 ?? false,
		setCallWith응대: (callWith응대: boolean) => {
			if (localSettings.data)
				saveLocalSettings.mutate({
					...localSettings.data,
					callWith응대,
				});
		},
	};
};
