import { NumberOutlined } from '@ant-design/icons';
import {
	VanCompanyTypeCashReceiptInputSeperated,
	현금영수증OptionType,
} from '@kinderlabs-pos/shared-data-type';
import { StoreInfoState } from '@kinderlabs-pos/state';
import {
	Box,
	FormControl,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';

export const 현금영수증발급ActionStack = ({
	options,
	setOptions,
}: {
	options: 현금영수증OptionType;
	setOptions: (input: 현금영수증OptionType) => void;
}) => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	return (
		<Stack
			direction='row'
			spacing={1}>
			<OutlinedInput
				fullWidth
				name={'customerId'}
				value={options.customerId}
				onChange={(e) => {
					setOptions({ ...options, customerId: e.target.value });
				}}
				disabled={options.isSignPadUsed}
				inputProps={{ type: 'number' }}
				placeholder={'전화번호 입력'}
				startAdornment={
					<Box mr={1}>
						<NumberOutlined />
					</Box>
				}
			/>
			<FormControl
				sx={{ minWidth: 120 }}
				disabled={!VanCompanyTypeCashReceiptInputSeperated[vanCompany]}>
				<Select
					// defaultValue={'false'}
					value={options.isSignPadUsed ? 'true' : 'false'}
					onChange={(e) => {
						setOptions({
							...options,
							isSignPadUsed: e.target.value === 'true',
						});
					}}>
					<MenuItem value={'true'}>고객입력</MenuItem>
					<MenuItem value={'false'}>POS 입력</MenuItem>
				</Select>
			</FormControl>
		</Stack>
	);
};

export type 현금결제OptionType =
	| {
			type: '현금영수증미발급';
			customerId: undefined;
			isSignPadUsed: boolean;
	  }
	| 현금영수증OptionType;

export const 현금영수증선택Stack = <T extends 현금영수증OptionType>({
	options,
	setOptions,
	includes현금영수증미발급 = true,
	refundMode = false,
}: {
	options: T;
	setOptions: (input: T) => void;
	includes현금영수증미발급?: boolean;
	refundMode?: boolean;
}) => {
	return (
		<ToggleButtonGroup
			size={'small'}
			color={'primary'}
			value={options.type}
			disabled={refundMode}
			onChange={(event, newValue) => {
				newValue &&
					setOptions({
						...options,
						type: newValue,
					});
			}}
			exclusive
			fullWidth>
			{includes현금영수증미발급 && (
				<ToggleButton value={'현금영수증미발급'}>
					<Typography variant='subtitle2'>
						{'현금영수증'}
						<br /> {'미발급'}
					</Typography>
				</ToggleButton>
			)}
			<ToggleButton value={'소득공제'}>
				<Typography variant='subtitle2'>
					{'소득공제'}
					<br /> {'(전화번호)'}
				</Typography>
			</ToggleButton>
			<ToggleButton value={'지출증빙'}>
				<Typography variant='subtitle2'>
					{'지출증빙'}
					<br />
					{'(사업자번호)'}
				</Typography>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};
