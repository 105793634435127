import { backendApiRootUrl } from '@kinderlabs-pos/shared-const';
import axios from 'axios';

const verifyIdentity = () => {
	return axios.get<{ TID: string }>(`${backendApiRootUrl}/danal/ready`);
};

const verifysnsIdentity = () => {
	return axios.get<{ TID: string }>(`${backendApiRootUrl}/danal/sns-ready`);
};

export const DanalApis = {
	verifyIdentity,
	verifysnsIdentity,
};
