import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitStatisticsType, GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getAllGuestVisitsAsyncWithStatus =
	(rootUrl: string) =>
	async ({ storeId, statusList }: { storeId: number; statusList?: GuestVisitType['status'][] }) =>
		(
			await posAxiosClient.get<GuestVisitType[]>(`${rootUrl}/guest-visit/store/${storeId}/today`, {
				params: {
					statusList,
				},
			})
		).data;

export const getAllCallsInThreeMinute =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<
				{
					id: number;
					sunbun: number;
					posId: number;
					calledDateTime: Date;
				}[]
			>(`${rootUrl}/guest-visit/today/recent-calls`, {
				params: {
					storeId,
				},
			})
		).data;

export const getAllGuestVisitsInAllStoreToday =
	(rootUrl: string) =>
	async ({
		storeIdList,
		statusList,
		date,
	}: {
		storeIdList?: number[];
		statusList?: GuestVisitType['status'][];
		date: Dayjs;
	}) =>
		(
			await posAxiosClient.get<{ [storeId: number]: GuestVisitStatisticsType }>(
				`${rootUrl}/guest-visit/today/all`,
				{
					params: {
						storeIdList,
						statusList,
						date: date.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

export const getAllGuestVisitRecentCalledInAllStoreToday =
	(rootUrl: string) =>
	async ({ storeId }: { storeId?: number }) =>
		(
			await posAxiosClient.get<{ [storeId: number]: GuestVisitType }>(
				`${rootUrl}/guest-visit/today/all/recent-called`,
				{
					params: {
						storeId,
					},
				}
			)
		).data;
