import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CommutationTicketType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';

export const getCmtListAsync =
	(rootUrl: string) =>
	async ({ memberId, pageable }: GetCmtListAsyncRequestType) =>
		(
			await posAxiosClient.get<PageType<CommutationTicketType>>(`${rootUrl}/ticket/cmt/list`, {
				params: {
					...pageable.getPageableQueryParam(),
					memberId,
				},
			})
		).data;

export type GetCmtListAsyncRequestType = {
	memberId: number;
	pageable: Pageable;
};
