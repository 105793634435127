import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { b2CMainThemeOption } from './b2cMainThemeOption';
import { vaunceTempPaletteOptions } from './vaunceTempPaletteOptions';
import { VaunceTypography } from './VaunceTypography';

export const vaunceAppTheme = createTheme(
	deepmerge(b2CMainThemeOption, {
		typography: { ...VaunceTypography, fontFamily: 'Noto Sans KR' },
		palette: vaunceTempPaletteOptions,
		components: {
			MuiCssBaseline: deepmerge(b2CMainThemeOption.components?.MuiCssBaseline, {
				styleOverrides: {
					// 매우 주의
					// iOS 에서 16px 이하의 input 에서 자동 zoom 되는 문제가 있어서 조치하였음.
					// 다른 모바일을 이용하는 모든 앱에서도 항상 같은 조치가 필요하다.
					// fontFamily: 'Noto Sans CJK KR',
					input: {
						fontSize: '16px !important',
					},
					body: {
						height: '100vh',
						width: '100vw',
						'#root': {
							height: '100%',
						},
					},
				},
			}),
		},
	})
);
