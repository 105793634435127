import { alpha, Stack, useTheme } from '@mui/material';
import { PosBoardBaseItem, PosBoardBasePropType } from './PosBoardBaseItem';

export const PosBoardPaginationItem = ({
	children,
	CardActionProps,
	CardProps,
}: PosBoardBasePropType) => {
	const theme = useTheme();

	return (
		<PosBoardBaseItem
			CardProps={{
				...CardProps,
			}}
			CardActionProps={{
				...CardActionProps,
				sx: {
					color: 'black',
					fontSize: theme.typography.h6.fontSize,
					backgroundColor: alpha(theme.palette.primary.main, 0.25),
					'&.Mui-disabled': {
						backgroundColor: alpha(theme.palette.divider, 0.3),
					},
				},
			}}>
			<Stack spacing={2}>{children}</Stack>
		</PosBoardBaseItem>
	);
};
