import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitTypeWithMemberInfo } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../../storeinfo/src/lib/const';
import { getPosStatusAsync, getPosStatusListAsync } from './getPosStatusAsync';
import { getSearchMemberAsync } from './getSearchMemberAsync';
import { getUserMemberAsync } from './getUserMemberAsync';
import {
	search앱티켓과쿠폰,
	getAppTicketForUserAsync,
	getAppTicketHistoryAsync,
} from './vaunceAppTicketApis';
import { getPosBoardListAsync } from './getPosBoardListAsync';
import { getStoreSalesDeviceInfoListAsync } from './getStorePosInfoListAsync';

const getGuestVisitDetail =
	(rootUrl: string) =>
	async ({ guestVisitId }: { guestVisitId: number }) =>
		(
			await posAxiosClient.get<GuestVisitTypeWithMemberInfo>(
				`${rootUrl}/pos-bff/guestvisit-detail`,
				{
					params: {
						guestVisitId,
					},
				}
			)
		).data;

const usePoint =
	(rootUrl: string) =>
	async ({ memberId, point, storeId, posId }: PosChangePointType) =>
		(
			await posAxiosClient.post<GuestVisitTypeWithMemberInfo>(`${rootUrl}/pos-bff/point/use`, {
				userId: memberId,
				usePoint: point,
				storeId,
				posId,
			})
		).data;

const refundPoint =
	(rootUrl: string) =>
	async ({ memberId, point, storeId, posId }: PosChangePointType) =>
		(
			await posAxiosClient.post<GuestVisitTypeWithMemberInfo>(`${rootUrl}/pos-bff/point/refund`, {
				userId: memberId,
				usePoint: point,
				storeId,
				posId,
			})
		).data;

const rewardPoint =
	(rootUrl: string) =>
	async ({ memberId, point, storeId, posId }: PosChangePointType) =>
		(
			await posAxiosClient.post<GuestVisitTypeWithMemberInfo>(`${rootUrl}/pos-bff/point/reward`, {
				userId: memberId,
				usePoint: point,
				storeId,
				posId,
			})
		).data;
const rewardCancelPoint =
	(rootUrl: string) =>
	async ({ memberId, point, storeId, posId }: PosChangePointType) =>
		(
			await posAxiosClient.post<GuestVisitTypeWithMemberInfo>(`${rootUrl}/pos-bff/point/reward`, {
				userId: memberId,
				usePoint: point,
				storeId,
				posId,
			})
		).data;

export const PosBffApi = {
	// 현재 시재 조회
	getGuestVisitDetail: getGuestVisitDetail(rootUrl),
	usePoint: usePoint(rootUrl),
	refundPoint: refundPoint(rootUrl),
	rewardPoint: rewardPoint(rootUrl),
	rewardCancelPoint: rewardCancelPoint(rootUrl),
	getPosStatus: getPosStatusAsync(rootUrl),
	getPosStatusList: getPosStatusListAsync(rootUrl),
	getSearchMember: getSearchMemberAsync(rootUrl),
	getUserMember: getUserMemberAsync(rootUrl),
	search앱티켓과쿠폰: search앱티켓과쿠폰(rootUrl),
	getAppTicketHistory: getAppTicketHistoryAsync(rootUrl),
	getAppTicketForUser: getAppTicketForUserAsync(rootUrl),
	getPosBoardList: getPosBoardListAsync(rootUrl),
	getStorePosInfoList: getStoreSalesDeviceInfoListAsync(rootUrl),
};

export type PosChangePointType = {
	memberId: number;
	point: number;
	storeId: number;
	posId: number;
};
