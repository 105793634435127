import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getGroupBy } from '@kinderlabs-pos/shared-util';
import { GuestVisitQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	CircularProgress,
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { GuestVisitPage } from '.';
import { GuestVisitDisplay } from './GuestVisitDisplay';
import { GuestVisitFilterType } from './GuestVisitFilterDialog';

export const GuestVisitListContent = ({
	filter,
	keyword,
	option,
}: {
	keyword?: string;
	filter: GuestVisitFilterType;
	option: DayCalendarFilterOptionType;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedGuestVisit, selectGuestVisit] = useAtom(GuestVisitPage.selectedGuestVisit);

	const {
		data: guestVisitDataPage,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...GuestVisitQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				search: keyword,
				status: filter.status,
				sort: { name: 'sunbun', direction: 'desc' },
				...option,
			},
		}),
		getNextPageParam: (lastPage) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		refetchOnWindowFocus: true,
	});

	const guestVisitData = useMemo(() => {
		if (!guestVisitDataPage) return [];
		else return guestVisitDataPage.pages.flatMap((data) => data.content);
	}, [guestVisitDataPage]);

	const guestVisitsGroupBy = getGroupBy(guestVisitData, (item) => getDateFormat(item.createdDate));

	useEffect(() => {
		if (selectedGuestVisit) {
			selectGuestVisit(
				guestVisitData.find((guestVisit) => guestVisit.id === selectedGuestVisit.id)
			);
		}
	}, [selectedGuestVisit, guestVisitData]);

	return (
		<List sx={{ p: 0 }}>
			{[...Object.keys(guestVisitsGroupBy)].map((key, idx) => (
				<Fragment key={idx}>
					<ListSubheader
						sx={(theme) => ({
							py: 1,
							borderBottom: `1px solid ${theme.palette.divider}`,
						})}>
						<Typography variant={'body1'}>{key}</Typography>
					</ListSubheader>
					{guestVisitsGroupBy[key].map((guestVisit, guestVisitIdx) => {
						return (
							<SelectableListItem
								key={guestVisitIdx}
								selected={guestVisit.id === selectedGuestVisit?.id}
								onClick={() => selectGuestVisit(guestVisit)}
								sx={(theme) => ({
									py: 1,
									borderBottom: `1px solid ${theme.palette.divider}`,
								})}>
								<GuestVisitDisplay guestVisit={guestVisit} />
							</SelectableListItem>
						);
					})}
				</Fragment>
			))}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
