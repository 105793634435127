import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	PageType,
	Pageable,
	WarehouseOrderResponseType,
	WarehouseOrderStatusType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getWarehouseOrderInfoListAsync =
	(rootUrl: string) =>
	async ({
		from,
		to,
		page,
		size,
		sort,
		warehouseIdList,
		search,
		status,
	}: GetWarehouseOrderRequestType) =>
		(
			await posAxiosClient.get<PageType<WarehouseOrderResponseType>>(
				`${rootUrl}/warehouse-order/search`,
				{
					params: {
						search,
						status,
						warehouseIdList,
						from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
						to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;

type GetWarehouseOrderRequestType = {
	status?: WarehouseOrderStatusType;
	warehouseIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	search?: string;
	page: number;
	size: number;
	sort?: Pageable['sort'];
};
