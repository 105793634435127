import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../../const';
import { 매출통계카드사별매출PaymentAndCardRevenueInfo } from '@kinderlabs-pos/shared-data-type';

const get카드사별일자별 =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
		search,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
		search?: string;
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 매출통계카드사별매출PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/card-company/date`, {
				params: {
					storeIdList,
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					search: search,
					posIdList: posIdList,
				},
			})
		).data;

const get카드사별월별 =
	(rootUrl: string) =>
	async ({
		fromMonth,
		toMonth,
		storeIdList,
		posIdList,
		search,
	}: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		posIdList?: number[];
		search?: string;
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 매출통계카드사별매출PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/card-company/month`, {
				params: {
					storeIdList,
					fromMonth: fromMonth,
					toMonth: toMonth,
					search: search,
					posIdList: posIdList,
				},
			})
		).data;

export const CardCompanyStatisticsApis = {
	get카드사별일자별: get카드사별일자별(rootUrl),
	get카드사별월별: get카드사별월별(rootUrl),
};
