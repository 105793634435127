import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteCmtInfoListAsync =
	(rootUrl: string) =>
	async ({ cmtList }: DeleteCmtInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/cmt/list`, {
				params: {
					idList: cmtList,
				},
			})
		).data;

export type DeleteCmtInfoListRequestType = { cmtList: number[] };
