import { axiosAppClient } from '@kinderlabs-pos/apis/axios';

export const getVaunceAppAuthorizationToken = (rootUrl: string) => async (code: string) => {
	axiosAppClient.get<any>(`${rootUrl}/api/v1/auth/token`, { params: { code } }).then((data) => {
		if (data) {
			localStorage.setItem('code', code);
			localStorage.setItem('accessToken', `Bearer ${data.data}`);
		}
	});
};
