import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	VaunceAppCMTTicketOnPosType,
	VaunceAppMUTTicketOnPosType,
	VaunceAppOTTTicketOnPosType,
	VaunceAppTicketOnPosHistoryType,
	VaunceAppTicketOnPosType,
	VaunceCouponSearchInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const search앱티켓과쿠폰 = (rootUrl: string) => {
	return async ({ search }: { search: string }) =>
		(
			await posAxiosClient.get<{
				idMatched: boolean;
				ottList: VaunceAppOTTTicketOnPosType[];
				mutList: VaunceAppMUTTicketOnPosType[];
				cmtList: VaunceAppCMTTicketOnPosType[];
				couponList: VaunceCouponSearchInfoType[];
			}>(`${rootUrl}/pos-bff/vaunce/app-ticket/search`, {
				params: {
					search,
				},
			})
		).data;
};
export const getAppTicketHistoryAsync = (rootUrl: string) => {
	return async ({ ticketId }: { ticketId: string }) =>
		(
			await posAxiosClient.get<VaunceAppTicketOnPosHistoryType[]>(
				`${rootUrl}/pos-bff/vaunce/app-ticket/history`,
				{
					params: {
						ticketId,
					},
				}
			)
		).data;
};

export const getAppTicketForUserAsync = (rootUrl: string) => {
	return async ({ uid }: { uid: number }) =>
		(
			await posAxiosClient.get<VaunceAppTicketOnPosType[]>(
				`${rootUrl}/pos-bff/vaunce/app-ticket/search/all`,
				{
					params: {
						uid,
					},
				}
			)
		).data;
};
