import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Divider, Stack, Typography } from '@mui/material';
import QuestionIcon from 'assets/vaunce/image/question.png';
import QuestionIcon2x from 'assets/vaunce/image/question@2x.png';

import { ReactNode } from 'react';

import { FormattedDialog } from '../../components/FormattedDialog';

export interface I자주묻는질문DialogProps extends Omit<XlDialogProps, 'children'> {
	title: string;
	open: boolean;
	closeDialog: () => void;
	contents: ReactNode;
}
export const FAQDialog: React.FC<I자주묻는질문DialogProps> = ({
	title,
	open,
	closeDialog,
	contents,
}) => {
	return (
		<FormattedDialog
			open={open}
			closeDialog={closeDialog}>
			<Stack
				spacing={2}
				sx={{ pb: 2 }}>
				<Stack
					direction={'row'}
					spacing={1}
					alignItems={'center'}>
					<img
						src={QuestionIcon}
						srcSet={QuestionIcon2x}
						style={{ width: 18, height: 18 }}
					/>
					<Typography variant='h4'>{title}</Typography>
				</Stack>
				<Divider />
				<Typography
					variant='h4'
					fontWeight={400}>
					{contents}
				</Typography>
			</Stack>
		</FormattedDialog>
	);
};
