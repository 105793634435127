import { Box, Checkbox, Collapse, Grid, GridProps, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, ReactNode } from 'react';

export interface IFormikFrameworksProps {
	formGrids: {
		FormComponent: ReactNode;
		GridProps: GridProps;
	}[];
	title?: ReactNode;
	hide?: boolean;
	toggleHide?: (e: ChangeEvent<any>) => void;
}

export const FormikFrameworks: React.FC<IFormikFrameworksProps> = ({
	title,
	formGrids,
	hide,
	toggleHide,
}) => {
	const isHidden = hide && !toggleHide;
	const sx = isHidden ? { opacity: 0.4, pointerEvents: 'none' } : undefined;

	return (
		<Stack
			spacing={1}
			sx={sx}>
			{title && (
				<Stack
					direction={'row'}
					alignItems={'center'}
					my={3}
					spacing={2}>
					{hide !== undefined && (
						<Checkbox
							color={'primary'}
							onChange={toggleHide}
							disabled={!toggleHide}
							checked={!hide}
						/>
					)}
					<Typography variant='subtitle1'>{title}</Typography>
					<Box sx={{ flex: 1, height: 1.5, bgcolor: 'divider' }} />
				</Stack>
			)}
			<Collapse in={!hide}>
				<Grid
					container
					spacing={1}>
					{formGrids.map((form, formIdx) => (
						<Grid
							key={formIdx}
							item
							{...form.GridProps}>
							{form.FormComponent}
						</Grid>
					))}
				</Grid>
			</Collapse>
		</Stack>
	);
};

export const FormGroupTitle = ({ hide, children }: { hide?: boolean; children: ReactNode }) => {
	return (
		<Grid
			item
			xs={12}
			sx={{ flex: 1 }}>
			{children}
		</Grid>
	);
};
