import { updateFileType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updateFileAsync = (rootUrl: string) => async (updatePosFileInfo: updateFileType) =>
	(
		await posAxiosClient.put<any>(`${rootUrl}/store/pos/file`, {
			...updatePosFileInfo,
		})
	).data;
