import { number } from 'yup';
import { BaseInfoType } from './BaseInfoType';
import { DeviceType } from '../ConnectInfoType';
import { PosInfoType, PosTypeLabel } from './PosInfoType';

export const DeviceStatusArray = ['BEFORE_OPEN', 'CLOSED', 'OPENED', 'PAUSED'] as const;
export type DeviceStatusType = (typeof DeviceStatusArray)[number];
export const DeviceStatusLabel: Record<DeviceStatusType, string> = {
	BEFORE_OPEN: '영업전',
	CLOSED: '영업마감',
	OPENED: '영업중',
	PAUSED: '영업일시중지',
};

export type SalesDeviceInfoType = {
	id: number;
	name: string;
	status: DeviceStatusType;
	deviceType: DeviceType;
	storeId: number;
};

export const getSalesDeviceInfoName = (salesDeviceInfo: SalesDeviceInfoType) => {
	return salesDeviceInfo.deviceType === 'POS'
		? `${PosTypeLabel[(salesDeviceInfo as PosInfoType).posType]} ${salesDeviceInfo.name}`
		: `[KIOSK] ${salesDeviceInfo.name}`;
};
