import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import * as Yup from 'yup';

export interface EasyPayPaymentInfoToRefundType extends PaymentInfoType {
	method: 'APPCARD' | 'KAKAOPAY' | 'SSGPAY' | 'ZEROPAY' | 'SEOULPAY';
}

export const useHandle간편결제환불Formik = ({
	payment,
	order,
	onComplete,
}: {
	payment: EasyPayPaymentInfoToRefundType;
	order: OrderDetailType;
	onComplete?: (paymentInfo: EasyPayPaymentInfoToRefundType) => void;
}) => {
	const confirm = useConfirm();
	const refund = PaymentState.actions.refund.useRefund();

	return useFormik<{ customerId: string; submit: string | null }>({
		initialValues: {
			customerId: '',
			submit: null,
		},
		validateOnMount: true,
		validationSchema: Yup.object().shape({
			customerId: Yup.string().required().min(1),
		}),
		onSubmit: async (values, { setErrors }) => {
			confirm(
				dayjs(payment.created).isToday()
					? '환불하시겠습니까?'
					: '오늘 결제한 건이 아니면 환불시 문제가 될수 있습니다. 환불하시겠습니까?',
				async () => {
					try {
						if (payment.receipt) {
							const result = await refund({
								payment: payment,
								receipt: payment.receipt,
								order: order,
								method: payment.method,
								customerId: values.customerId,
							});

							onComplete && onComplete(payment);
						}
					} catch (e) {
						//@ts-ignore
						setErrors({ submit: e.message });
					}
				}
			);
		},
	});
};
