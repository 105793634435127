import { Stack, styled, Tab, Tabs, Typography } from '@mui/material';

import { Suspense, useState } from 'react';

import { CardStack } from '@kinderlabs-pos/ui-atoms';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import PaidIcon from '@mui/icons-material/Paid';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import { VaultcashCheckTab } from './pages/VaultcashCheckTab';
import { VaultcashDepositAndWithdrawalTab } from './pages/VaultcashDepositAndWithdrawalTab';
import { VaultcashHistoryTab } from './pages/VaultcashHistoryTab';
import { VaultcashManageTab } from './pages/VaultcashManageTab';

const tabData = [
	{
		label: '시재 점검 내역',
		icon: <ListIcon />,
	},
	{
		label: '시재 중간 점검',
		icon: <PriceCheckIcon />,
	},
	{
		label: '시재 입출금',
		icon: <PaidIcon />,
	},
	{
		label: '시재 설정',
		icon: <SettingsIcon />,
	},
];

export interface IVaultcashCheckPageProps {}
export const VaultcashCheckPage: React.FC<IVaultcashCheckPageProps> = ({}) => {
	const [tabIndex, setTabIndex] = useState(0);
	const handleChange = (event: any, newTabIndex: number) => {
		setTabIndex(newTabIndex);
	};

	return (
		<Stack
			direction={'row'}
			height={'100%'}
			spacing={1}>
			<CardStack
				height={'100%'}
				flex={1}>
				<StyledTabs
					orientation='vertical'
					value={tabIndex}
					onChange={handleChange}>
					{tabData.map((tab, idx) => (
						<Tab
							sx={{ marginTop: 1 }}
							label={
								<Stack
									direction={'row'}
									width={'100%'}
									sx={{ color: '#5b5a5a' }}>
									{tab.icon}
									<Typography
										variant='h5'
										paddingLeft={1}>
										{tab.label}
									</Typography>
								</Stack>
							}
						/>
					))}
				</StyledTabs>
			</CardStack>
			<CardStack
				flex={4}
				sx={{ p: 2 }}>
				<Suspense>
					{tabIndex === 0 && <VaultcashHistoryTab />}
					{tabIndex === 1 && <VaultcashCheckTab />}
					{tabIndex === 2 && <VaultcashDepositAndWithdrawalTab />}
					{tabIndex === 3 && <VaultcashManageTab />}
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const StyledTabs = styled(Tabs)((theme) => ({
	borderRight: 1,
	'& .MuiTabs-indicator': {
		width: '100%',
		opacity: 0.15,
		backgroundColor: 'black',
	},
}));
