export const aggregateSumReduceUtils = <T extends Record<string, number>>(
	initialValue: T,
	values: T[]
): T => {
	return values.reduce((result, item) => {
		[...Object.keys(item)].forEach((key: keyof T) => {
			//@ts-ignore
			result[key] += item[key];
		});
		return result;
	}, initialValue);
};
