import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';

/**
 * 이거 완전히 뜯어고쳐야겠는데?
 */
// 백엔드나 파일에서 setting 을 덮어써서 atomWithDefault 로 만들 것
const posSettingAtom = atomWithDefault(async () => {
	try {
		return {
			autoReceiptPrintOn: false,
			vaultcashCheckOnOpening: true,
			vaultcashWithdrawalAlertAmount: 0,
			parkingLotUrl: '',
			posFileUrl: '',
		};
	} catch {
		return {
			autoReceiptPrintOn: false,
			vaultcashCheckOnOpening: true,
			vaultcashWithdrawalAlertAmount: 0,
			parkingLotUrl: '',
			posFileUrl: '',
		};
	}
});

const posStatusAtom = atom({
	sseConnected: false,
});

export const PosState = {
	settings: posSettingAtom,
	status: posStatusAtom,
};
