import { AdminVendorOrderRetrunApis } from '@kinderlabs-pos/apis/storeinfo';
import { Pageable, VendorOrderType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

type VendorOrderListParamsType = {
	vendorId?: number;
	warehouseId?: number;
	sort?: Pageable['sort'];
	search?: string;
	type: VendorOrderType;
	from: Dayjs;
	to: Dayjs;
};

type VendorOrderHistoryInfoParamType = Omit<
	VendorOrderListParamsType,
	'warehouseId' | 'sort' | 'type'
> & {
	type?: VendorOrderListParamsType['type'];
};
const vendorOrderKeys = createQueryKeys('vendor-order', {
	list: ({ params }: { params: VendorOrderListParamsType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminVendorOrderRetrunApis.getVendorOrderList({
				...params,
				pageable: Pageable.getPageable(params),
			});
			return res;
		},
	}),
	detail: (vendorOrderId: number) => ({
		queryKey: [vendorOrderId],
		queryFn: async () => {
			const res = await AdminVendorOrderRetrunApis.getVendorOrderDetailInfo(vendorOrderId);
			return res;
		},
	}),
	history: ({ params }: { params: VendorOrderHistoryInfoParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminVendorOrderRetrunApis.getVendorOrderHistory(params);
			return res;
		},
	}),
	common: ({ vendorId, warehouseId }: { vendorId?: number; warehouseId?: number }) => ({
		queryKey: ['productList', vendorId, warehouseId],
		queryFn: async () => {
			if (!vendorId || !warehouseId) return [];
			const res = await AdminVendorOrderRetrunApis.getCommonProductList({ warehouseId, vendorId });
			return res;
		},
	}),
});

const createVendorOrder = () => {
	return QueryClient.useMutation(
		async (params: Parameters<typeof AdminVendorOrderRetrunApis.createVendorOrder>[number]) => {
			await AdminVendorOrderRetrunApis.createVendorOrder(params);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const updateToCompleteVendorOrder = () => {
	return QueryClient.useMutation(
		async (id: number) => {
			await AdminVendorOrderRetrunApis.updateToComplteOrder(id);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const 입고완료취소VendorOrder = () => {
	return QueryClient.useMutation(
		async (id: number) => {
			await AdminVendorOrderRetrunApis.입고완료취소Order(id);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const updateToCancelVendorOrder = () => {
	return QueryClient.useMutation(
		async (id: number) => {
			await AdminVendorOrderRetrunApis.updateToCancelOrder(id);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const uploadVendorOrderFile = () => {
	return QueryClient.useMutation(
		async ({ id, fileData }: { id: number; fileData: FormData }) => {
			await AdminVendorOrderRetrunApis.uploadVendorOrderFiles({
				id: id,
				fileData: fileData,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const deleteVendorOrderFileInfo = () => {
	return QueryClient.useMutation(
		async ({ orderId, fileId }: { orderId: number; fileId: number }) => {
			await AdminVendorOrderRetrunApis.deleteVendorOrderInfo({
				orderId,
				fileId,
			});
		},
		{
			onSuccess: () => invalidateQueries(),
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: vendorOrderKeys._def });
};

export const AdminVendorOrderInfoState = {
	keys: vendorOrderKeys,

	createOrder: createVendorOrder,
	cancelOrder: updateToCancelVendorOrder,
	compeleteOrder: updateToCompleteVendorOrder,
	입고완료취소VendorOrder: 입고완료취소VendorOrder,
	uploadFile: uploadVendorOrderFile,
	deleteVendorOrderFileInfo: deleteVendorOrderFileInfo,
};
