import { Badge, Box, BoxProps } from '@mui/material';
import React from 'react';
import qrImage2x from 'assets/vaunce/image/iconqr@2x.png';

export interface IQrImageBadgeProps extends BoxProps {
	badgeContent?: JSX.Element;
	imageSize: 'sm' | 'lg';
	qrImage: string;
}

const QrImageBadge: React.FC<IQrImageBadgeProps> = ({
	badgeContent,
	imageSize,
	qrImage,
	...others
}) => {
	const size = imageSize === 'sm' ? 65 : 78;
	return (
		<Box
			{...others}
			sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Badge
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiBadge-badge': {
						bottom: 12,
						right: 12,
					},
				}}
				badgeContent={badgeContent}>
				<img
					src={qrImage}
					srcSet={qrImage2x}
					width={size}
					height={size}
				/>
			</Badge>
		</Box>
	);
};
export default QrImageBadge;
