import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getGroupBy } from '@kinderlabs-pos/shared-util';
import { PaymentQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { PaymentManagementPage } from '.';
import { PaymentDisplay } from './PaymentDisplay';
import { PaymentsFilterType } from './PaymentsFilterDialog';

export const PaymentListContent = ({
	filter,
	keyword,
	option,
	isForRefundSearch = false,
}: {
	keyword?: string;
	filter: PaymentsFilterType;
	option: DayCalendarFilterOptionType;
	isForRefundSearch?: boolean;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedPayment, selectPayment] = useAtom(PaymentManagementPage.selectedPayment);
	const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
		...PaymentQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				search: keyword,
				method: filter.method,
				posIdList: filter.targetPosId ? [filter.targetPosId] : undefined,
				...option,
				isForRefundSearch,
			},
		}),
		refetchOnWindowFocus: true,
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
	});

	const paymentData = useMemo(() => {
		if (!data) return [];
		else return data.pages.flatMap((data) => data.content);
	}, [data]);

	const paymentsGroupBy = getGroupBy(paymentData, (payment) => getDateFormat(payment.created));

	useEffect(() => {
		if (selectedPayment?.id) {
			selectPayment(paymentData.find((payment) => payment.id === selectedPayment.id));
		}
	}, [selectedPayment?.id, paymentData]);

	return (
		<List sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
			{[...Object.keys(paymentsGroupBy)].map((key, idx) => (
				<Fragment key={idx}>
					<ListSubheader
						sx={(theme) => ({
							py: 1,
							bpaymentBottom: `1px solid ${theme.palette.divider}`,
						})}>
						<Typography variant={'body1'}>{key}</Typography>
					</ListSubheader>
					{paymentsGroupBy[key].map((payment) => {
						const selected = payment.id === selectedPayment?.id;
						return (
							<Fragment key={payment.id}>
								<SelectableListItem
									selected={payment.id === selectedPayment?.id}
									onClick={() => {
										selectPayment(payment);
									}}>
									<PaymentDisplay
										payment={payment}
										selected={selected}
										variant={'withId'}
									/>
								</SelectableListItem>
								<Divider component={'li'} />
							</Fragment>
						);
					})}
				</Fragment>
			))}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
