import { CartLineInfoType, CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { SquareTextField, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

export interface PriceChangeInCartDialogProps extends XlDialogProps {
	selectedCartLine: CartLineInfoType;
}
export const PriceChangeInCartDialog = ({
	open,
	closeDialog,
	selectedCartLine,
}: PriceChangeInCartDialogProps) => {
	const [value, setValue] = useState(() => selectedCartLine.price);
	const cartDispatcher = OrderState.cart.useDispatcher();
	const handleChangePrice = () => {
		cartDispatcher({
			type: 'PRODUCTS',
			subAction: {
				type: 'CHANGE_PRICE',
				cartLineId: selectedCartLine?.id,
				targetPrice: value,
				onComplete: closeDialog,
			},
		});
	};

	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const { data: productListData } = useQuery({
		...AdminProductInfoState.keys.inStore(storeId),
		keepPreviousData: true,
		refetchOnMount: true,
		refetchOnWindowFocus: true,
	});
	const productInfoList = productListData ?? [];

	const canChangePrice = CartLineTypeRules.canChangePrice(selectedCartLine, productInfoList);

	return (
		<WithDialog.XlDialog
			open={open}
			scroll='body'
			closeDialog={closeDialog}
			dialogTitle={'가격 변경'}>
			<DialogContent>
				<Stack
					direction={'row'}
					spacing={5}
					pb={5}>
					<Stack>
						<Typography variant='subtitle1'>{selectedCartLine.name}</Typography>
						<SquareTextField
							focused
							disabled={!canChangePrice}
							sx={{ width: 180 }}
							value={numberWithCommas(value || undefined)}
							onChange={(e) => {
								setValue(+e.currentTarget.value);
							}}
						/>
						{!canChangePrice && <Typography color='error'>임의 가격 설정 불가 상품</Typography>}
					</Stack>
					<NumberBoard
						value={value}
						setValue={setValue}
						disabled={!canChangePrice}
					/>
				</Stack>
				<DialogActions>
					<Button
						disabled={!canChangePrice}
						variant='contained'
						onClick={handleChangePrice}>
						가격 변경
					</Button>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						취소
					</Button>
				</DialogActions>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
