import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceState } from '@kinderlabs-pos/state';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Stack, styled, Typography } from '@mui/material';
import enrollImage from 'assets/vaunce/image/icon_enrollment.png';
import qrcodeImage from 'assets/vaunce/image/icon_qrcode.png';
import recordImage from 'assets/vaunce/image/icon_record.png';
import submitWarningImage from 'assets/vaunce/image/submitwarning.png';

import enrollImage2x from 'assets/vaunce/image/icon_enrollment@2x.png';
import qrcodeImage2x from 'assets/vaunce/image/icon_qrcode@2x.png';
import recordImage2x from 'assets/vaunce/image/icon_record@2x.png';
import submitWarningImage2x from 'assets/vaunce/image/submitwarning@2x.png';

import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import { loggingEvent } from '../../utils/firebaseAnalytics';

interface FacilityContent {
	title: string;
	img: string;
	srcsetUrl: string;
	navigate: () => void;
}
export interface IFacilitySubmitPageProps {}
export const FacilityRegisterPage: React.FC<IFacilitySubmitPageProps> = ({}) => {
	const resetInfos = useResetAtom(VaunceState.facilityInfoAtom);
	const {
		navigateNewRegisterStepOne,
		navigateRegisterHistory,
		navigateRegisteredQR,
		navigateMypage,
	} = useVaunceAppRouter();
	const contentList: FacilityContent[] = [
		{
			title: '신규 등록하기',
			img: enrollImage,
			srcsetUrl: enrollImage2x,
			navigate: navigateNewRegisterStepOne,
		},
		{
			title: '등록 이력 불러오기',
			img: recordImage,
			srcsetUrl: recordImage2x,
			navigate: navigateRegisterHistory,
		},
		{
			title: '등록된 QR코드 보기',
			img: qrcodeImage,
			srcsetUrl: qrcodeImage2x,
			navigate: navigateRegisteredQR,
		},
	];

	useEffect(() => {
		resetInfos();
	}, []);

	return (
		<Stack
			flex={1}
			overflow={'auto'}
			direction={'column'}>
			<PageHeader
				title='시설 이용 등록'
				navigateAlterUrl={navigateMypage}
			/>
			<Stack
				spacing={3}
				sx={{ flex: 1, overflow: 'inherit' }}>
				<Box>
					{contentList.map((content) => (
						<FacilityBox
							key={content.img}
							onClick={() => {
								if (content) {
									loggingEvent(
										`시설 이용 등록 > ${content.title}`,
										'route',
										`${content.title}ClickInHome`
									);
									content.navigate();
								} else alert('등록된 QR코드가 없거나 이미 시설이용동의 처리되었습니다.');
							}}>
							<Stack
								direction={'row'}
								alignItems={'center'}
								flex={1}
								spacing={1}>
								<img
									src={content.img}
									srcSet={content.srcsetUrl}
									style={{ marginRight: '20px' }}
									width={43}
									height={43}
								/>
								<Typography
									variant='h4'
									fontWeight={700}
									sx={{ flex: 1 }}>
									{content.title}
								</Typography>
								<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}` }} />
							</Stack>
						</FacilityBox>
					))}
				</Box>
				<Stack
					direction={'row'}
					sx={{ bgcolor: '#F4F4F4', borderRadius: '12px', p: 2 }}
					spacing={1}>
					<Box>
						<img
							src={submitWarningImage}
							srcSet={submitWarningImage2x}
							width={32}
							height={32}
						/>
					</Box>
					<Stack>
						<Typography
							variant='h4'
							color={`${vaunceColors.lightPink}`}
							fontWeight={700}>
							꼭! 확인하세요.
						</Typography>
						<Typography variant='h5'>
							이용 등록 후 센터에서 입장 신청을 반드시 하셔야 합니다.
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
export default FacilityRegisterPage;

const FacilityBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	borderBottom: '1px solid #707070',
	padding: '16px 10px',
}));
