import { AvailableType } from '../admin/DdiziInfoType';
import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { Pageable } from '../PageType';
import { TicketType } from '../TicketType';

export type OnlineTicketChannelType =
	| 'NAVER' //네이버
	| 'ONOFFMIX' //온오프믹스
	| 'KIDS_NOTE' //키즈노트
	| 'STORE_FARM' //스토어팜
	| 'ST11' //11번가
	| 'SHINSEGAE' //산세계
	| 'AUCTION_GMARKET' //옥션,지마켓
	| 'WOONGJIN_MALL' //웅진몰
	| 'TMON' //티몬
	| 'YANOLJA' //야놀자
	| 'WAUG' //와그
	| 'COUPANG' //쿠팡
	| 'YELLOW_BALLOON' //노랑풍선
	| 'GOOD_CHOICE' // 여기어때 (회사이름이 굿초이스)
	| 'HMALL' //HMALL
	| 'LOTTE_ON' //롯데온
	| 'WEMAKEPRICE' //위메프
	| 'EASYWELL' //이지웰
	| 'KAKAO_STORE' //카카오스토어
	| 'INTERPARK' //인터파크
	| 'KLOOK'
	| 'IWATRIP'
	| 'GRIP'
	| 'EDUMOSTA'
	| 'NIMO' // ("니모투어"),
	| 'BABYGO' // ("애기야가자"),
	| 'WEEBUR' // ("위버"),
	| 'TIMETICKET' // ("타임티켓"),
	| 'LEISUREPLANNER' // ("레저플래너"),
	| 'NOBAL' // ("놀이의발견"),
	| 'MYREALTRIP' // ("마이리얼트립"),
	| 'TABLENJOY' // ("테이블엔조이"),
	| 'OTHER'; // ("기타")

// 5 글자 이상이 안되도록 조심
export const OnlineTicketChannelTypeLabel: Record<OnlineTicketChannelType, string> = {
	NAVER: '네이버',
	ONOFFMIX: '온오프믹스',
	KIDS_NOTE: '키즈노트',
	STORE_FARM: '스토어팜',
	ST11: '11번가',
	SHINSEGAE: '신세계',
	AUCTION_GMARKET: '옥션지마켓',
	WOONGJIN_MALL: '웅진몰',
	TMON: '티몬',
	YANOLJA: '야놀자',
	WAUG: '와그',
	COUPANG: '쿠팡',
	YELLOW_BALLOON: '노랑풍선',
	GOOD_CHOICE: ' 여기어때', //(회사이름이 굿초이스)
	HMALL: 'HMALL',
	LOTTE_ON: '롯데온',
	WEMAKEPRICE: '위메프',
	EASYWELL: '이지웰',
	KAKAO_STORE: '카카오스토어',
	INTERPARK: '인터파크',
	KLOOK: '클룩',
	IWATRIP: '아이와트립',
	GRIP: '그립',
	EDUMOSTA: '에듀모스타',
	NIMO: '니모투어',
	BABYGO: '애기야가자',
	WEEBUR: '위버',
	TIMETICKET: '타임티켓',
	LEISUREPLANNER: '레저플래너',
	NOBAL: '놀이의발견',
	MYREALTRIP: '마이리얼트립',
	TABLENJOY: '테이블엔조이',
	OTHER: '기타채널',
};

export const onlineTicketInnerTypeArray = ['NORMAL', 'KIDS', 'CHILD', 'PARENTS'] as const;
export type OnlineTicketInnerType = (typeof onlineTicketInnerTypeArray)[number];
export const ticketTypeLabel: Record<OnlineTicketInnerType, string> = {
	NORMAL: '일반 이용권',
	KIDS: '키즈',
	CHILD: '유아',
	PARENTS: '보호자 이용권',
};

export const OnlineTicketProviderTypeArray = ['ONOFFMIX', 'TBRIDGE', 'ZLGOON'] as const;
export const getOnlineTicketProviderTypeArrayForEnterprise = (
	enterpriseId: string
): OnlineTicketProviderType[] => {
	switch (enterpriseId) {
		case 'vaunce':
			return ['ONOFFMIX'];
		case 'zoolung':
			return ['TBRIDGE', 'ZLGOON'];
		default:
			return [];
	}
};
export type OnlineTicketProviderType = (typeof OnlineTicketProviderTypeArray)[number];
export const OnlineTicketProviderLabel: Record<OnlineTicketProviderType, string> = {
	ONOFFMIX: '온오프믹스',
	TBRIDGE: '티브리지',
	ZLGOON: '즐거운',
};

export const OnlineTicketUsageTypeArray = ['USED', 'UNUSED'] as const;
export type OnlineTicketUsageType = (typeof OnlineTicketUsageTypeArray)[number];
export const OnlineTicketUsageTypeLabel: Record<OnlineTicketUsageType, string> = {
	UNUSED: '미사용',
	USED: '사용',
};

type ticketUseInfo = {
	used: Date;
	storeId: number;
	posId: number;
	orderId: string;
};

// 새로 추가
export interface OnlineTicketType extends TicketType {
	ticketId: number;
	ticketPrice: number;
	ticketType: OnlineTicketInnerType;
	klStoreId: number;
	ticketChannel: OnlineTicketChannelType;
	ticketProvider: OnlineTicketProviderType;
	weekdayAvailable: AvailableType;
	ticketUseInfo: ticketUseInfo;
	externalTicketId: string;
	externalOrderId: string;
	externalTicketInfoId: string;
	ticketMinutes: number;

	rawPrice?: number;
	supplyPrice?: number;
}

export type AdminOnlineTicketInfoListFilterType = {
	storeIdList?: number[];
	weekdayAvailable?: AvailableType;
	ticketType?: OnlineTicketInnerType;
	ticketProvider?: OnlineTicketProviderType;
	search?: string;
	usageStatus?: OnlineTicketUsageType;
	page: number;
	size?: number;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
	forUseDate?: boolean;
};
