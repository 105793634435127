import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteVendorOrderInfoAsync =
	(rootUrl: string) =>
	async ({ orderId, fileId }: { orderId: number; fileId: number }) =>
		(
			await posAxiosClient.delete(`${rootUrl}/vendor-order/file`, {
				params: {
					orderId,
					fileId,
				},
			})
		).data;
