import { rootUrl } from "../const";
import { deviceConnectDeviceAsync } from "./disconnectDevice";
import { getConnectInfoList } from "./getConnectInfoList";
import { getConnectInfoListByType } from "./getConnectInfoListByType";
import { healthCheckDeviceAsync } from "./healthCheckDeviceAsync";

export const DeviceConnectApis = {
  /**
   * 고객 정보 수집
   */
  getConnectInfoList: getConnectInfoList(rootUrl),
  /**
   * admin 에서 사용하기 좋음
   */
  getConnectInfoByTypeList: getConnectInfoListByType(rootUrl),
  disconnectDevice: deviceConnectDeviceAsync(rootUrl),
  healthCheckDevice: healthCheckDeviceAsync(rootUrl)
}