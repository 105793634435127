import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
	CashUnitKeyType,
	CashUnitKeyTypeArray,
	CashUnitTypeIndex,
	CashUnitTypeValue,
} from '@kinderlabs-pos/shared-data-type';
import {
	Box,
	Button,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { useRef } from 'react';
import { VaultcashFunctionType } from './common';

export const BillInputTable: React.FC<
	Pick<
		VaultcashFunctionType,
		'total' | 'numBills' | 'selectedBill' | 'setNumBills' | 'setSelectedBill'
	>
> = ({ total, numBills, selectedBill, setNumBills, setSelectedBill }) => {
	const textRefs = Array.from({ length: CashUnitKeyTypeArray.length }, () =>
		useRef<HTMLInputElement>(null)
	);

	const handleIncrease = (key: CashUnitKeyType, value: number) => {
		setNumBills((prev) => ({ ...prev, [key]: value + 1 }));
		setSelectedBill(key);
	};
	const handleDecrease = (key: CashUnitKeyType, value: number) => {
		setNumBills((prev) => ({ ...prev, [key]: value > 0 ? value - 1 : value }));
		setSelectedBill(key);
	};
	const handleChange = (key: CashUnitKeyType, value: number) => {
		if (!value) setNumBills((prev) => ({ ...prev, [key]: 0 }));
		else if (value >= 0) setNumBills((prev) => ({ ...prev, [key]: value }));
	};
	const handleMoveFocus = (e: React.KeyboardEvent<HTMLDivElement>) => {
		let index = CashUnitTypeIndex[selectedBill];
		if (e.key === 'Enter' || e.key === 'ArrowDown') {
			if (index < CashUnitKeyTypeArray.length - 1) ++index;
		} else if (e.key === 'Tab') {
			if (index < CashUnitKeyTypeArray.length - 1) ++index;
			e.stopPropagation();
			e.preventDefault();
		}
		if (e.key === 'ArrowUp') {
			if (index > 0) --index;
		}
		setSelectedBill(CashUnitKeyTypeArray[index]);
		textRefs[index].current?.focus();
	};

	return (
		<TableContainer
			component={Box}
			sx={{ width: '60%' }}>
			<Table>
				<TableHead>
					<TableCell
						align='center'
						width={80}>
						<Typography variant='h4'>금액권</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>수량</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>수량 조절</Typography>
					</TableCell>
					<TableCell
						align='center'
						width={140}>
						<Typography variant='h4'>권종별 합계</Typography>
					</TableCell>
				</TableHead>
				<TableBody>
					{CashUnitKeyTypeArray.map((cashUnitKey, idx) => (
						<TableRow key={cashUnitKey}>
							<TableCell align='right'>
								<Typography variant='subtitle2'>
									{CashUnitTypeValue[cashUnitKey].toLocaleString()} 원
								</Typography>
							</TableCell>
							<TableCell align='center'>
								<StyledTextField
									focused={selectedBill === cashUnitKey}
									inputRef={textRefs[idx]}
									value={numBills[cashUnitKey]}
									variant='outlined'
									onClick={() => {
										setSelectedBill(cashUnitKey);
									}}
									onChange={(e) => {
										handleChange(cashUnitKey, parseInt(e.target.value));
									}}
									onKeyDown={(e) => {
										handleMoveFocus(e);
									}}></StyledTextField>
							</TableCell>
							<TableCell align='center'>
								<StyledPlusMinusButton
									variant='outlined'
									onClick={() => handleIncrease(cashUnitKey, numBills[cashUnitKey])}>
									<PlusOutlined />
								</StyledPlusMinusButton>
								<StyledPlusMinusButton
									variant='outlined'
									onClick={() => handleDecrease(cashUnitKey, numBills[cashUnitKey])}>
									<MinusOutlined />
								</StyledPlusMinusButton>
							</TableCell>
							<TableCell align='right'>
								<Typography variant='subtitle2'>
									{(CashUnitTypeValue[cashUnitKey] * numBills[cashUnitKey]).toLocaleString()} 원
								</Typography>
							</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell />
						<TableCell />
						<TableCell
							align='right'
							height={40}>
							<Typography variant='h2'>총액 :</Typography>
						</TableCell>
						<TableCell align='right'>
							<Typography variant='h2'>{total.toLocaleString()} 원</Typography>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputBase-root': {
		height: 35,
		width: 60,
	},
	'& .MuiInputBase-input': {
		textAlign: 'center',
	},
}));

const StyledPlusMinusButton = styled(Button)(({ theme }) => ({
	marginLeft: 3,
	marginRight: 3,
	' &.MuiButtonBase-root': {
		minWidth: 40,
		minHeight: 35,
		padding: 0,
	},
}));
