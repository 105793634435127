import { rootUrl } from '../../../storeinfo/src/lib/const';
import { getPosHistoryAsync } from './getPosHistoryAsync';
import { getPosStatusAsync } from './getPosStatusAsync';
import { getStoreStatusAsync } from './getStoreStatusAsync';
import { postChangePosStatusAsync } from './postChangePosStatusAsync';
import { getCashSalesAsync } from './getCashSalesAsync';
import { getDateTimeFormatWithHyphen } from '@kinderlabs-pos/shared-util';
import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

const postCancelPosCloseAsync =
	(rootUrl: string) =>
	async ({ posId }: { posId: number }) =>
		(
			await posAxiosClient.post(`${rootUrl}/vault-cash/close-cancel`, undefined, {
				params: { posId },
			})
		).data;

export const VaultCashAPI = {
	getPosStatus: getPosStatusAsync(rootUrl),
	getPosHistory: getPosHistoryAsync(rootUrl),
	getStoreStatus: getStoreStatusAsync(rootUrl),
	getCashSales: getCashSalesAsync(rootUrl),
	postChangePosStatus: postChangePosStatusAsync(rootUrl),
	postCancelPosClose: postCancelPosCloseAsync(rootUrl),
	closePosWithout시재점검: async ({ storeId, posId }: { storeId: number; posId: number }) => {
		await postChangePosStatusAsync(rootUrl)({
			checkType: 'CLOSE',
			payload: {
				storeId: storeId,
				posId: posId,
				cashUnits: {
					cash50000: 0,
					cash10000: 0,
					cash5000: 0,
					cash1000: 0,
					cash500: 0,
					cash100: 0,
					cash50: 0,
					cash10: 0,
				},
				countedAmount: 0,
				registerDate: getDateTimeFormatWithHyphen(new Date()),
			},
		});
	},
};
