//@ts-nocheck

import {
	ResolvedColumnFilter,
	Row,
	RowData,
	RowModel,
	Table,
	TableOptionsResolved,
	createRow,
} from '@tanstack/react-table';

// JIN : Mui 에 버그가 있어서 버그 픽스하였습니다.
// Tanstack Table 에 PR 날려두었고, 해결되면 코드 삭제하겠습니다.
// https://github.com/TanStack/table/pull/5555
function memo<TDeps extends readonly any[], TDepArgs, TResult>(
	getDeps: (depArgs?: TDepArgs) => [...TDeps],
	fn: (...args: NoInfer<[...TDeps]>) => TResult,
	opts: {
		key: any;
		debug?: () => any;
		onChange?: (result: TResult) => void;
	}
): (depArgs?: TDepArgs) => TResult {
	let deps: any[] = [];
	let result: TResult | undefined;

	return (depArgs) => {
		let depTime: number;
		if (opts.key && opts.debug) depTime = Date.now();

		const newDeps = getDeps(depArgs);

		const depsChanged =
			newDeps.length !== deps.length ||
			newDeps.some((dep: any, index: number) => deps[index] !== dep);

		if (!depsChanged) {
			return result!;
		}

		deps = newDeps;

		let resultTime: number;
		if (opts.key && opts.debug) resultTime = Date.now();

		result = fn(...newDeps);
		opts?.onChange?.(result);

		if (opts.key && opts.debug) {
			if (opts?.debug()) {
				const depEndTime = Math.round((Date.now() - depTime!) * 100) / 100;
				const resultEndTime = Math.round((Date.now() - resultTime!) * 100) / 100;
				const resultFpsPercentage = resultEndTime / 16;

				const pad = (str: number | string, num: number) => {
					str = String(str);
					while (str.length < num) {
						str = ' ' + str;
					}
					return str;
				};

				console.info(
					`%c⏱ ${pad(resultEndTime, 5)} /${pad(depEndTime, 5)} ms`,
					`
            font-size: .6rem;
            font-weight: bold;
            color: hsl(${Math.max(
							0,
							Math.min(120 - 120 * resultFpsPercentage, 120)
						)}deg 100% 31%);`,
					opts?.key
				);
			}
		}

		return result!;
	};
}

export function getTempFilteredRowModel<TData extends RowData>(): (
	table: Table<TData>
) => () => RowModel<TData> {
	return (table) =>
		memo(
			() => [
				table.getPreFilteredRowModel(),
				table.getState().columnFilters,
				table.getState().globalFilter,
			],
			(rowModel, columnFilters, globalFilter) => {
				if (!rowModel.rows.length || (!columnFilters?.length && !globalFilter)) {
					for (let i = 0; i < rowModel.flatRows.length; i++) {
						rowModel.flatRows[i]!.columnFilters = {};
						rowModel.flatRows[i]!.columnFiltersMeta = {};
					}
					return rowModel;
				}

				const resolvedColumnFilters: ResolvedColumnFilter<TData>[] = [];
				const resolvedGlobalFilters: ResolvedColumnFilter<TData>[] = [];

				(columnFilters ?? []).forEach((d) => {
					const column = table.getColumn(d.id);

					if (!column) {
						return;
					}

					const filterFn = column.getFilterFn();

					resolvedColumnFilters.push({
						id: d.id,
						filterFn,
						resolvedValue: filterFn.resolveFilterValue?.(d.value) ?? d.value,
					});
				});

				const filterableIds = (columnFilters ?? []).map((d) => d.id);

				const globalFilterFn = table.getGlobalFilterFn();

				const globallyFilterableColumns = table
					.getAllLeafColumns()
					.filter((column) => column.getCanGlobalFilter());

				if (globalFilter && globalFilterFn && globallyFilterableColumns.length) {
					filterableIds.push('__global__');

					globallyFilterableColumns.forEach((column) => {
						resolvedGlobalFilters.push({
							id: column.id,
							filterFn: globalFilterFn,
							resolvedValue: globalFilterFn.resolveFilterValue?.(globalFilter) ?? globalFilter,
						});
					});
				}

				let currentColumnFilter;
				let currentGlobalFilter;

				// Flag the prefiltered row model with each filter state
				for (let j = 0; j < rowModel.flatRows.length; j++) {
					const row = rowModel.flatRows[j]!;

					row.columnFilters = {};

					if (resolvedColumnFilters.length) {
						for (let i = 0; i < resolvedColumnFilters.length; i++) {
							currentColumnFilter = resolvedColumnFilters[i]!;
							const id = currentColumnFilter.id;

							// Tag the row with the column filter state
							row.columnFilters[id] = currentColumnFilter.filterFn(
								row,
								id,
								currentColumnFilter.resolvedValue,
								(filterMeta) => {
									row.columnFiltersMeta[id] = filterMeta;
								}
							);
						}
					}

					if (resolvedGlobalFilters.length) {
						for (let i = 0; i < resolvedGlobalFilters.length; i++) {
							currentGlobalFilter = resolvedGlobalFilters[i]!;
							const id = currentGlobalFilter.id;
							// Tag the row with the first truthy global filter state
							if (
								currentGlobalFilter.filterFn(
									row,
									id,
									currentGlobalFilter.resolvedValue,
									(filterMeta) => {
										row.columnFiltersMeta[id] = filterMeta;
									}
								)
							) {
								row.columnFilters.__global__ = true;
								break;
							}
						}

						if (row.columnFilters.__global__ !== true) {
							row.columnFilters.__global__ = false;
						}
					}
				}

				const filterRowsImpl = (row: Row<TData>) => {
					// Horizontally filter rows through each column
					for (let i = 0; i < filterableIds.length; i++) {
						if (row.columnFilters[filterableIds[i]!] === false) {
							return false;
						}
					}
					return true;
				};

				// Filter final rows using all of the active filters
				return filterRows(rowModel.rows, filterRowsImpl, table);
			},
			getMemoOptions(table.options, 'debugTable', 'getFilteredRowModel', () =>
				table._autoResetPageIndex()
			)
		);
}

function filterRows<TData extends RowData>(
	rows: Row<TData>[],
	filterRowImpl: (row: Row<TData>) => any,
	table: Table<TData>
) {
	if (table.options.filterFromLeafRows) {
		return filterRowModelFromLeafs(rows, filterRowImpl, table);
	}

	return filterRowModelFromRoot(rows, filterRowImpl, table);
}

function filterRowModelFromLeafs<TData extends RowData>(
	rowsToFilter: Row<TData>[],
	filterRow: (row: Row<TData>) => Row<TData>[],
	table: Table<TData>
): RowModel<TData> {
	const newFilteredFlatRows: Row<TData>[] = [];
	const newFilteredRowsById: Record<string, Row<TData>> = {};
	const maxDepth = table.options.maxLeafRowFilterDepth ?? 100;

	const recurseFilterRows = (rowsToFilter: Row<TData>[], depth = 0) => {
		const rows: Row<TData>[] = [];

		// Filter from children up first
		for (let i = 0; i < rowsToFilter.length; i++) {
			let row = rowsToFilter[i]!;

			const newRow = createRow(
				table,
				row.id,
				row.original,
				row.index,
				row.depth,
				undefined,
				row.parentId
			);
			newRow.columnFilters = row.columnFilters;

			if (row.subRows?.length && depth < maxDepth) {
				newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
				row = newRow;

				if (filterRow(row) && !newRow.subRows.length) {
					rows.push(row);
					newFilteredRowsById[row.id] = row;
					newFilteredFlatRows.push(row);
					continue;
				}

				if (filterRow(row) || newRow.subRows.length) {
					rows.push(row);
					newFilteredRowsById[row.id] = row;
					newFilteredFlatRows.push(row);
					continue;
				}
			} else {
				row = newRow;
				if (filterRow(row)) {
					rows.push(row);
					newFilteredRowsById[row.id] = row;
					newFilteredFlatRows.push(row);
				}
			}
		}

		return rows;
	};

	return {
		rows: recurseFilterRows(rowsToFilter),
		flatRows: newFilteredFlatRows,
		rowsById: newFilteredRowsById,
	};
}

function filterRowModelFromRoot<TData extends RowData>(
	rowsToFilter: Row<TData>[],
	filterRow: (row: Row<TData>) => any,
	table: Table<TData>
): RowModel<TData> {
	const newFilteredFlatRows: Row<TData>[] = [];
	const newFilteredRowsById: Record<string, Row<TData>> = {};
	const maxDepth = table.options.maxLeafRowFilterDepth ?? 100;

	// Filters top level and nested rows
	const recurseFilterRows = (rowsToFilter: Row<TData>[], depth = 0) => {
		// Filter from parents downward first

		const rows: Row<TData>[] = [];

		// Apply the filter to any subRows
		for (let i = 0; i < rowsToFilter.length; i++) {
			let row = rowsToFilter[i]!;

			const pass = filterRow(row);

			if (pass) {
				if (row.subRows?.length && depth < maxDepth) {
					const newRow = createRow(
						table,
						row.id,
						row.original,
						row.index,
						row.depth,
						undefined,
						row.parentId
					);
					newRow.subRows = recurseFilterRows(row.subRows, depth + 1);
					row = newRow;
				}

				rows.push(row);
				newFilteredFlatRows.push(row);
				newFilteredRowsById[row.id] = row;
			}
		}

		return rows;
	};

	return {
		rows: recurseFilterRows(rowsToFilter),
		flatRows: newFilteredFlatRows,
		rowsById: newFilteredRowsById,
	};
}

function getMemoOptions(
	tableOptions: Partial<TableOptionsResolved<any>>,
	debugLevel:
		| 'debugAll'
		| 'debugCells'
		| 'debugTable'
		| 'debugColumns'
		| 'debugRows'
		| 'debugHeaders',
	key: string,
	onChange?: (result: any) => void
) {
	return {
		debug: () => tableOptions?.debugAll ?? tableOptions[debugLevel],
		key: process.env.NODE_ENV === 'development' && key,
		onChange,
	};
}
