import { Dayjs } from 'dayjs';
import { rootUrl } from '../../const';
import { SalesStatistics } from './SalesStatistics';
import { getAllGuestVisitAsync } from './getAllGuestVisitAsync';
import { getDetailGuestVisitAsync } from './getDetailGuestVisitAsync';
import { getStockSubulStatisticsAsync } from './getStockSubulStatisticsAsync';
import { getVendorOrderStatisticsAsync } from './getVendorOrderStatisticsAsync';
import { PaymentMethodStatisticsApis } from './PaymentMethodStatisticsApis';
import { ProductStatisticsApis } from './ProductStatisticsApis';
import { CardCompanyStatisticsApis } from './CardCompanyStatisticsApis';
import { GuestVisitStatisticsApis } from './GuestVisitStatisticsApis';

const downloadSalesPerProductExcel =
	(rootUrl: string) =>
	({
		requestType: type,
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		requestType: 'all' | 'date';
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) => {
		const url = new URL(`${rootUrl}/excel/product/statistics/${type}`);

		if (storeIdList && storeIdList?.length > 0)
			url.searchParams.append('storeIdList', `${storeIdList}`);
		if (posIdList) url.searchParams.append('posIdList', `${posIdList}`);
		url.searchParams.append('from', from.format('YYYY-MM-DD'));
		url.searchParams.append('to', to.format('YYYY-MM-DD'));

		return `${url}`;
	};

const downloadPaymentMethodExcel =
	(rootUrl: string) =>
	({
		requestType: type,
		from,
		to,
		storeIdList,
	}: {
		requestType: 'date';
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
	}) => {
		const url = new URL(`${rootUrl}/excel/revenue/payment-method/${type}`);

		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);

		url.searchParams.append('from', from.format('YYYY-MM-DD'));
		url.searchParams.append('to', to.format('YYYY-MM-DD'));

		return `${url}`;
	};

const downloadOnlineTicketExcel =
	(rootUrl: string) =>
	({
		requestType: type,
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		requestType: 'date';
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) => {
		const url = new URL(`${rootUrl}/pos-bff/online-ticket/statistics/excel`);

		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (posIdList) url.searchParams.append('posIdList', `${posIdList}`);

		url.searchParams.append('from', from.format('YYYY-MM-DD'));
		url.searchParams.append('to', to.format('YYYY-MM-DD'));

		return `${url}`;
	};

const downloadDailyOnlineTicketExcel =
	(rootUrl: string) =>
	({
		requestType: type,
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		requestType: 'date';
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) => {
		const url = new URL(`${rootUrl}/pos-bff/online-ticket/statistics/daily/excel`);

		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (posIdList) url.searchParams.append('posIdList', `${posIdList}`);

		url.searchParams.append('from', from.format('YYYY-MM-DD'));
		url.searchParams.append('to', to.format('YYYY-MM-DD'));

		return `${url}`;
	};

export const StatisticsApis = {
	getAllGuestVisit: getAllGuestVisitAsync(rootUrl),
	getDetailGuestVisit: getDetailGuestVisitAsync(rootUrl),

	get거래처별통계현황: getVendorOrderStatisticsAsync(rootUrl),
	get재고수불현황: getStockSubulStatisticsAsync(rootUrl),
	download상품별Excel: downloadSalesPerProductExcel(rootUrl),

	get매장별외부매출: SalesStatistics.get매장별외부매출(rootUrl),
	Payment: PaymentMethodStatisticsApis,
	Product: ProductStatisticsApis,
	CardCompany: CardCompanyStatisticsApis,
	GuestVisit: GuestVisitStatisticsApis,
	get매장별결제내용참고: SalesStatistics.get매장별결재내용참고(rootUrl),
	get매장별이용객입퇴장매출Statistics: SalesStatistics.get매장별이용객입퇴장매출Statistics(rootUrl),
	getMainStatistics: SalesStatistics.getMainStatistics(rootUrl),
	getStatisicsForPaymentSum: SalesStatistics.getStatisicsForPaymentSum(rootUrl),
	downloadPaymentMethodExcel: downloadPaymentMethodExcel(rootUrl),
	getOnlineTicketStatistics: SalesStatistics.getOnlineTicketStatistics(rootUrl),
	get매장별OnlineTicketStatistics: SalesStatistics.get매장별OnlineTicketStatistics(rootUrl),
	get체널별OnlineTicketStatistics: SalesStatistics.get체널별OnlineTicketStatistics(rootUrl),
	downloadOnlineTicketExcel: downloadOnlineTicketExcel(rootUrl),
	downloadDailyOnlineTicketExcel: downloadDailyOnlineTicketExcel(rootUrl),
	salesStatisticsStartTime: 8,
	salesStatisticsEndTime: 23,
};
