import { Button, Stack, Typography } from '@mui/material';
import { IFrameDialogWithURI } from 'apps/pos-main/src/dialogs/IFrameDialogWithURI';
import packageJson from 'package.json';
import { ReactNode, useMemo, useState } from 'react';
import GitInfo from 'react-git-info/macro';
import { SettingMenuItem } from '../common';
import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';

export interface ISoftwareInfoPageProps {}
export const SoftwareInfoPage: React.FC<ISoftwareInfoPageProps> = ({}) => {
	const gitInfo = useMemo(() => GitInfo(), []);

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='제작 사'
				innerElement={<Typography variant='subtitle1'>{`${packageJson.author}`}</Typography>}
			/>
			<SettingMenuItem
				title='License'
				innerElement={<Typography variant='subtitle1'>{`${packageJson.license}`}</Typography>}
			/>
			<SettingMenuItem
				title='POS 버전'
				innerElement={
					<Typography variant='subtitle1'>{`v${packageJson.version} (${gitInfo.commit.shortHash})`}</Typography>
				}
			/>{' '}
			<SettingMenuItem
				title='이용 문의'
				innerElement={
					<Typography
						variant='subtitle1'
						sx={(theme) => ({
							textDecoration: 'underline',
							color: `${theme.palette.primary.main}`,
						})}>
						help@kinderlabs.co.kr
					</Typography>
				}
			/>
			<SettingMenuItemWithNewWindow
				title='홈페이지'
				buttonLabel='홈페이지 보기'
				url={`${packageJson.homepage}`}
			/>
			<SettingMenuItemWithNewWindow
				title='서비스 이용약관'
				buttonLabel='이용약관 보기'
				url={`https://kinderlabs-ko-manual.oopy.io/d16c3176-294c-4ee0-8079-18b1b13cf022`}
			/>
			<SettingMenuItemWithNewWindow
				title='개인정보 처리방침'
				buttonLabel='개인정보 처리방침 보기'
				url={`https://kinderlabs-ko-manual.oopy.io/f89b7f9c-a8dd-4714-bf8f-865ae11f4c5c`}
			/>
			<SettingMenuItemWithNewWindow
				title='KLPOS 매뉴얼'
				buttonLabel='매뉴얼 보기'
				url={`https://kinderlabs-ko-manual.oopy.io/`}
			/>
			<SettingMenuItemWithNewWindow
				title='업데이트 노트'
				buttonLabel='업데이트 노트 보기'
				url={`https://kinderlabs-ko-manual.oopy.io/7f194292-bd88-4233-ad9f-774aeb825359`}
			/>
		</Stack>
	);
};

const SettingMenuItemWithNewWindow = ({
	title,
	buttonLabel,
	url,
	isExternalBrowser = false,
}: {
	title: ReactNode;
	buttonLabel: ReactNode;
	url: string;
	isExternalBrowser?: boolean;
}) => {
	const [showDialog, setShowDialog] = useState(false);

	const onClickButton = () => {
		if (isExternalBrowser) ReactPosMainPageIPC.externalWindow.changeParkingLotWindow(url);
		else setShowDialog((prev) => !prev);
	};

	return (
		<>
			<SettingMenuItem
				title={title}
				innerElement={
					<Button
						variant='outlined'
						onClick={onClickButton}>
						{buttonLabel}
					</Button>
				}
			/>
			{!isExternalBrowser && (
				<IFrameDialogWithURI
					title={title}
					src={url}
					show={showDialog}
					setShow={setShowDialog}
				/>
			)}
		</>
	);
};
