import { VaunceMemberInfoType, isVaunceMemberInfoSNS } from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { SearchMemberState } from '@kinderlabs-pos/state';
import { SelectableListItem, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, SearchBox } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	List,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtom } from 'jotai';
import { Fragment, Suspense, useEffect, useState } from 'react';

interface MemberSearchDialog extends XlDialogProps {
	setMemberInfo: (args: VaunceMemberInfoType | undefined) => void;
	presetMemberUser?: VaunceMemberInfoType;
}
export const MemberSearchDialog = ({
	open,
	closeDialog,
	setMemberInfo,
	presetMemberUser,
}: MemberSearchDialog) => {
	const [search, setSearch] = useAtom(MemberSearchDialog.searchAtom);

	const [selectedMember, setSelectedMember] = useState<VaunceMemberInfoType | undefined>(
		presetMemberUser
	);

	const { data } = useQuery({
		...SearchMemberState.keys.search(search),
	});

	const memberList = data ?? [];

	useEffect(() => {
		presetMemberUser && setSearch(presetMemberUser.userId);
	}, []);

	return (
		<WithDialog.XlDialog
			dialogTitle='회원 검색'
			open={open}>
			<DialogContent sx={{ width: 650, height: 300, mb: 1 }}>
				<Stack
					direction={'column'}
					spacing={1}
					height={1}>
					<MemberSearchBox setSearchValue={setSearch} />
					<Stack
						flex={1}
						direction='column'
						sx={{
							overflowY: 'auto',
							border: `1px solid ${grey[400]}`,
							borderRadius: 1,
						}}>
						<Suspense fallback={<FullSizeCircularProgress size={60} />}>
							<MemberListContent
								memberList={memberList}
								selectedMember={selectedMember}
								setSelectedMember={setSelectedMember}
							/>
						</Suspense>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					spacing={1}
					alignItems={'center'}>
					<Button
						onClick={() => {
							setMemberInfo(selectedMember);
							selectedMember && setSearch(selectedMember.userId);
							closeDialog && closeDialog();
						}}
						variant={'contained'}>
						확인
					</Button>
					<Button
						onClick={() => {
							setSearch('');
							closeDialog && closeDialog();
						}}
						variant={'outlined'}>
						취소
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
MemberSearchDialog.searchAtom = atom('');

export const MemberSearchBox = ({
	setSearchValue,
}: {
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState('');

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearchValue(tempSearchValue);
			}}
			placeholder={'회원이름 / 연락처 뒤 8자리 / 이메일 / 회원번호 로 검색가능'}
		/>
	);
};

const MemberListContent = ({
	memberList,
	selectedMember,
	setSelectedMember,
}: {
	memberList: VaunceMemberInfoType[];
	selectedMember: VaunceMemberInfoType | undefined;
	setSelectedMember: React.Dispatch<React.SetStateAction<VaunceMemberInfoType | undefined>>;
}) => {
	return (
		<List sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
			<ListSubheader
				component='div'
				sx={{ display: 'flex', alignItems: 'center', px: 1.5 }}>
				<Stack
					direction='row'
					flex={1}
					justifyContent={'space-around'}
					spacing={1}>
					<Typography variant={'subtitle1'}>회원이름</Typography>
					<Typography
						variant={'subtitle1'}
						sx={{ minWidth: 200 }}>
						이메일
					</Typography>
					<Typography variant={'subtitle1'}>회원번호</Typography>
					<Typography variant={'subtitle1'}>회원등급</Typography>
					<Typography variant={'subtitle1'}>연락처</Typography>
				</Stack>
			</ListSubheader>
			<Divider />
			{(!memberList || !memberList.length) && (
				<Stack
					direction={'column'}
					justifyContent={'center'}
					alignItems={'center'}
					height='200px'>
					<Typography variant='h4'>검색 결과가 없습니다</Typography>
				</Stack>
			)}
			{memberList.map((member, index) => (
				<Fragment key={index}>
					<SelectableListItem
						selected={member.id === selectedMember?.id}
						onClick={() => {
							setSelectedMember(member.id === selectedMember?.id ? undefined : member);
						}}>
						<Stack
							direction={'row'}
							justifyContent='space-around'
							flex={1}>
							<Typography variant='subtitle1'>{member.username}</Typography>

							<Typography
								variant='subtitle1'
								sx={{ minWidth: 200 }}>
								{`${isVaunceMemberInfoSNS[member.providerType!]} 연동계정`
									? member.providerType
									: member.userId}
							</Typography>
							<Typography variant='subtitle1'>{member.id}</Typography>
							<Typography variant='subtitle1'>{member.vaunceMemberGrade}</Typography>
							<Typography variant='subtitle1'>
								{member.telephone && getTelephoneNumberWithDash(member.telephone)}
							</Typography>
						</Stack>
					</SelectableListItem>
					<Divider component={'li'} />
				</Fragment>
			))}
		</List>
	);
};
