import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export type alertStateType = { alertOpen: boolean; alertMessage: string };

export const FinalFailureDialog = ({
	alertState,
	setAlertState,
}: {
	alertState: alertStateType;
	setAlertState: React.Dispatch<React.SetStateAction<alertStateType>>;
}) => {
	return (
		<Dialog open={alertState.alertOpen}>
			<DialogTitle>점검 실패</DialogTitle>
			<DialogContent>{alertState.alertMessage}</DialogContent>
			<DialogActions>
				<Button onClick={() => setAlertState((prev) => ({ ...prev, alertOpen: false }))}>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	);
};
