import { createTheme, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { deepmerge } from '@mui/utils';
import { baseThemeWithFontFamily } from '../base/realBaseTheme';
import { adminComponentOverrides } from './adminComponentOverrides';
import { adminTypographyWithFontFamily } from './adminTypographyWithFontFamily';
import '@fontsource/nanum-gothic';
import { stagingColor } from '../stagingColor';

const isStagingMode = process.env['NX_STAGING_MODE'] ? true : false;

const fontFamily = `"Nanum Gothic", sans-serif`;
export const adminTheme: Theme = createTheme(
	deepmerge(baseThemeWithFontFamily(fontFamily), {
		palette: {
			primary: {
				main: isStagingMode ? stagingColor : '#1890ff',
			},
			secondary: {
				main: '#595959',
			},
			warning: {
				main: '#dda116',
			},
			error: {
				main: '#dd3f27',
			},
			success: {
				main: '#4fba28',
			},
			info: {
				main: '#4c88dd',
			},
			background: {
				paper: 'white',
				default: grey[100],
			},
		},
		typography: adminTypographyWithFontFamily(fontFamily),
		components: adminComponentOverrides,
	})
);
