import { PosBffApi } from '@kinderlabs-pos/apis/pos';
import { QueryClient } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const VaunceAppTicketKeys = createQueryKeys('AppTickets', {
	search: ({ search }: { search: string }) => ({
		queryKey: [search],
		queryFn: async () => {
			if (!search) return null;
			return PosBffApi.search앱티켓과쿠폰({ search });
		},
	}),
	history: (ticketId: string) => ({
		queryKey: [ticketId],
		queryFn: async () => {
			return (await PosBffApi.getAppTicketHistory({ ticketId })).reverse();
		},
	}),
	forUser: (uid: number) => ({
		queryKey: [uid],
		queryFn: async () => {
			return await PosBffApi.getAppTicketForUser({ uid });
		},
	}),
});

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: VaunceAppTicketKeys._def });
};

export const VaunceAppTicketQueryState = {
	keys: VaunceAppTicketKeys,
	invalidateQueries,
};
