import { PremiumOneType } from '@kinderlabs-pos/shared-data-type';
import { CloseIconBox } from '@kinderlabs-pos/ui-atoms';
import { Dialog, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';

import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { useVaunceAppRouter } from '../routes';
export interface IOneTicketRefundDialogProps extends DialogProps {
	ticket: PremiumOneType;
	onClose: () => void;
}
export const OneTicketRefundDialog: React.FC<IOneTicketRefundDialogProps> = ({
	open,
	onClose,
	ticket,
}) => {
	const { navigateOneTicketWithRefund } = useVaunceAppRouter();
	const refundInfo = VaunceQueryState.usePremiumOneRefundInfoQuery(ticket.orderNum);
	const handleRefund = async () => {
		const cancelAmt =
			ticket.status === 'EXPIRED' ? parseInt(refundInfo.pgAmt) * 0.9 : parseInt(refundInfo.pgAmt);
		onClose();
		const data = await VaunceAppApis.postPremiumOneNicePayCancelAsync(
			refundInfo.orderNum,
			cancelAmt
		);
		if (data) {
			ticket.isRefundInProgress === 'REFUND_IN_PROGRESS'
				? (ticket.isRefundInProgress = null)
				: (ticket.isRefundInProgress = 'REFUND_IN_PROGRESS');
			navigateOneTicketWithRefund();
		}
	};

	return (
		<Dialog
			maxWidth='md'
			open={open}
			onClose={onClose}
			fullWidth>
			<DialogContent>
				<Stack
					direction={'row'}
					justifyContent={'flex-end'}>
					<IconButton onClick={onClose}>
						<CloseIconBox />
					</IconButton>
				</Stack>

				<Stack>
					<Stack
						margin={'30px 0 15px'}
						alignItems={'center'}
						spacing={1}>
						<Typography variant='h4'>
							{ticket.title && ticket.title.split('_').length > 1
								? '[' + ticket.title?.split('_')[1] + '] ' + ticket.title?.split('_')[3]
								: ticket.title}
						</Typography>
						<Typography variant='h5'>유효기간 : {ticket.expiredAt} 까지</Typography>
					</Stack>

					<Stack
						textAlign={'left'}
						borderTop={'1px solid rgba(0, 0, 0, 0.60)'}
						width={'100%'}>
						<Typography
							variant='h4'
							sx={{ my: '1rem', color: '#001243' }}>
							결제 금액
						</Typography>
					</Stack>
					<Stack alignItems={'center'}>
						<Typography
							variant='h2'
							sx={{ my: '1rem', color: '#001243' }}>
							{parseInt(refundInfo.pgAmt).toLocaleString()} 원
						</Typography>
					</Stack>

					<Stack
						textAlign={'left'}
						width={'100%'}>
						<Typography
							variant='h4'
							sx={{ my: '1rem', color: '#001243' }}>
							{refundInfo.refundInProgress === 'REFUND_IN_PROGRESS'
								? '환불 신청 금액'
								: '환불 금액'}
						</Typography>
					</Stack>
					<Stack alignItems={'center'}>
						<Typography
							variant='h2'
							sx={{ my: '1rem', color: '#001243' }}>
							{refundInfo.refundInProgress === 'REFUND_IN_PROGRESS'
								? parseInt(refundInfo.refundInProgressAmt).toLocaleString()
								: ticket.status === 'EXPIRED'
								? (parseInt(refundInfo.pgAmt) * 0.9).toLocaleString()
								: parseInt(refundInfo.pgAmt).toLocaleString()}{' '}
							원
						</Typography>
					</Stack>

					<Stack textAlign={'left'}>
						<Typography
							variant='h4'
							sx={{ my: '2rem', color: '#001243' }}>
							환불 안내
						</Typography>
						<Typography variant='h5'>① 카드사에 따라 약 5~7일 정도 소요됩니다.</Typography>
						<Typography variant='h5'>
							② 유효기간이 지난 경우, 구매 금액의 90%가 환불됩니다.
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						spacing={2}
						marginTop={'25px'}>
						<RoundedButton
							sx={{ width: '40%', height: 60 }}
							onClick={onClose}>
							닫기
						</RoundedButton>
						<RoundedButton
							sx={{ width: '60%', height: 60 }}
							onClick={handleRefund}>
							{refundInfo.refundInProgress === 'REFUND_IN_PROGRESS' ? '환불취소' : '환불신청'}
						</RoundedButton>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
