import { GuestMemberInfoSearchType } from '@kinderlabs-pos/shared-data-type';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Stack } from '@mui/material';
import { useGetGuestMemberSearchingActions } from 'apps/pos-main/src/modules/member-search/Formiks/useGetMemberSearchingActions';
import { UserInfoResultSection } from 'apps/pos-main/src/modules/member-search/UserInfoResultSection';
import { UserInfoSearchSection } from 'apps/pos-main/src/modules/member-search/UserInfoSearchSection';
import { Suspense } from 'react';

export const GuestMemberSearchPage = ({ onClose }: { onClose?: () => void }) => {
	const {
		isSearching,
		handleSearchByKeyword,
		setResult,
		multipleUserInfoList,
		result,
		MultipleDialogComponent,
	} = useGetGuestMemberSearchingActions();

	return (
		<Stack
			spacing={2}
			flex={1}
			sx={{ overflow: 'auto', maxWidth: 530 }}>
			<Suspense fallback={<FullSizeCircularProgress />}>
				<UserInfoSearchSection
					isLoading={isSearching}
					handleSearchByKeyword={handleSearchByKeyword}
					guestMemberInfoActions={{
						setGuestMemberInfo: (info: GuestMemberInfoSearchType) => setResult(info),
						multipleUserInfoList,
					}}
				/>
			</Suspense>
			<Suspense fallback={<FullSizeCircularProgress />}>
				<UserInfoResultSection
					searchStatus={result.status}
					guestMemberInfoActions={{
						guestMemberInfo: result.info,
						onClose,
						resetMemberInfo: () => setResult({ info: undefined, status: '검색전' }),
					}}
					appMemberInfo={result.info && { guestMemberId: result.info.memberId }}
					visitListInfo={result.info && { guestMemberId: result.info.memberId }}
					showAppMember={false}
				/>
			</Suspense>
			<MultipleDialogComponent />
		</Stack>
	);
};
