import { Stack, Typography } from '@mui/material';
import warningImage from 'assets/vaunce/image/pinkwarning.png';
import { ReactNode } from 'react';

export interface IEmptyContentProps {
	title: ReactNode;
}
export const EmptyContent: React.FC<IEmptyContentProps> = ({ title }) => {
	return (
		<Stack
			spacing={3}
			alignItems={'center'}
			justifyContent={'center'}
			my={'auto'}>
			<img
				src={warningImage}
				width={'74px'}
			/>
			<Typography variant='h5'>{title}</Typography>
		</Stack>
	);
};
