import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, MutInfoType } from '@kinderlabs-pos/shared-data-type';

export const createMutInfoAsync =
	(rootUrl: string) => async (createMutInfoRequest: CreateMutInfoRequestType) =>
		(
			await posAxiosClient.post<MutInfoType & BaseInfoType>(`${rootUrl}/mut`, {
				...createMutInfoRequest,
			})
		).data;

export type CreateMutInfoRequestType = Omit<MutInfoType, 'id'>;
