import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';

export const getGuestVisitDetailAsync =
	(rootUrl: string) =>
	async ({ guestVisitId }: { guestVisitId: number }) =>
		(
			await posAxiosClient.get<GuestVisitType>(`${rootUrl}/guest-visit/detail`, {
				params: {
					id: guestVisitId,
				},
			})
		).data;
