import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Checkbox, Divider, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import AgreePersonInfomationDialog from './AgreePersonInfomationDialog';
import AgreeProvisionThirdPersonDialog from './AgreeProvisionThirdPersonDialog';
import CommonNoticeForFacilityDialog from './CommonNoticeForFacilityDialog';
import NoticeBeforeUsingFacilityDialog from './NoticeBeforeUsingFacilityDialog';
import { loggingEvent } from '../../utils/firebaseAnalytics';

export interface IAgreeFacilityPageProps {}
const NewRegisterStepTwoPage: React.FC<IAgreeFacilityPageProps> = ({}) => {
	const { navigateNewRegisterStepThree } = useVaunceAppRouter();

	const [infos, setInfos] = useAtom(VaunceState.facilityInfoAtom);

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInfos({
			...infos,
			termsArray: [
				event.target.checked,
				event.target.checked,
				event.target.checked,
				event.target.checked,
			],
		});
	};

	const isAllChecked =
		infos.termsArray.filter((item) => item === true).length === infos.termsArray.length;

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='시설 이용 등록' />
			<CheckLabelBox
				label={
					<Typography
						variant='h5'
						data-cy='allAgreeTextInRegisterStepTwo'>
						{'전체동의'}
					</Typography>
				}
				control={
					<Checkbox
						data-cy='allCheckBoxInRegisterStepTwo'
						checked={isAllChecked}
						onChange={handleChangeAll}
					/>
				}
			/>

			<Divider sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }} />
			<SubCheckBox />
			<RoundedButton
				data-cy='agreeBtnInRegisterStepTwo'
				disabled={!isAllChecked}
				onClick={() => {
					loggingEvent(
						`시설 이용 등록 및 이전 등록 이력 STEP 4`,
						'route',
						'시설 이용 등록 및 이전 등록 이력 STEP 4'
					);
					navigateNewRegisterStepThree();
				}}>
				동의하고 다음
			</RoundedButton>
		</Stack>
	);
};

export default NewRegisterStepTwoPage;

const SubCheckBox = () => {
	const [infos, setInfos] = useAtom(VaunceState.facilityInfoAtom);
	const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInfos({
			...infos,
			termsArray: [
				event.target.checked,
				infos.termsArray[1],
				infos.termsArray[2],
				infos.termsArray[3],
			],
		});
	};

	const handleChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInfos({
			...infos,
			termsArray: [
				infos.termsArray[0],
				event.target.checked,
				infos.termsArray[2],
				infos.termsArray[3],
			],
		});
	};

	const handleChangeThird = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInfos({
			...infos,
			termsArray: [
				infos.termsArray[0],
				infos.termsArray[1],
				event.target.checked,
				infos.termsArray[3],
			],
		});
	};

	const handleChangeFourth = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInfos({
			...infos,
			termsArray: [
				infos.termsArray[0],
				infos.termsArray[1],
				infos.termsArray[2],
				event.target.checked,
			],
		});
	};

	const checkBoxInfo = [
		{
			title: '시설 이용 전 안내사항',
			textDataCy: 'agreeFirstTextInRegisterStepTwo',
			checkBoxDataCy: 'firstCheckBoxInRegisterStepTwo',
			handleChange: handleChangeFirst,
			dialog: <NoticeUsingFacilityBtn />,
		},
		{
			title: '시설 이용 간 공통 안내사항',
			textDataCy: 'agreeSecondTextInRegisterStepTwo',
			checkBoxDataCy: 'secondCheckBoxInRegisterStepTwo',
			handleChange: handleChangeSecond,
			dialog: <CommonNoticeFacilityBtn />,
		},
		{
			title: '개인정보 수집 및 이용 동의',
			textDataCy: 'agreeThirdTextInRegisterStepTwo',
			checkBoxDataCy: 'thirdCheckBoxInRegisterStepTwo',
			handleChange: handleChangeThird,
			dialog: <AgreePersonInfomationBtn />,
		},
		{
			title: '개인정보 제3자 정보 제공 동의',
			textDataCy: 'agreeFourthTextInRegisterStepTwo',
			checkBoxDataCy: 'fourthCheckBoxInRegisterStepTwo',
			handleChange: handleChangeFourth,
			dialog: <AgreeProvisionThirdPersonBtn />,
		},
	];

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			{checkBoxInfo.map((info, idx) => (
				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}>
					<CheckLabelBox
						label={
							<Typography
								variant='h5'
								data-cy={info.textDataCy}>
								{info.title}
							</Typography>
						}
						control={
							<Checkbox
								data-cy={info.checkBoxDataCy}
								checked={infos.termsArray[idx]}
								onChange={info.handleChange}
							/>
						}
					/>
					{info.dialog}
				</Stack>
			))}
		</Box>
	);
};

export const NoticeUsingFacilityBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<NoticeBeforeUsingFacilityDialog
					open={open}
					onClose={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox onClick={openDialog}>
					<Typography
						variant='h6'
						color='primary'
						fontWeight={400}>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}` }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

export const CommonNoticeFacilityBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<CommonNoticeForFacilityDialog
					open={open}
					onClose={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox onClick={openDialog}>
					<Typography
						variant='h6'
						color='primary'
						fontWeight={400}>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}` }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

export const AgreePersonInfomationBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<AgreePersonInfomationDialog
					open={open}
					onClose={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox onClick={openDialog}>
					<Typography
						variant='h6'
						color='primary'
						fontWeight={400}>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}` }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

export const AgreeProvisionThirdPersonBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<AgreeProvisionThirdPersonDialog
					open={open}
					onClose={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox onClick={openDialog}>
					<Typography
						variant='h6'
						color='primary'
						fontWeight={400}>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}` }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

const AllAgreeBox = styled(FormControlLabel)(({ theme }) => ({
	borderBottom: '1px solid #707070',
}));

const CheckLabelBox = styled(FormControlLabel)(({ theme }) => ({
	marginRight: 'auto',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: `${vaunceColors.lightPink}`,
	fontSize: '0.75em',
}));

const DetailBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
}));
