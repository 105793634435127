import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductHistoryIntoType,
	BaseInfoType,
	PageType,
	RevisionType,
} from '@kinderlabs-pos/shared-data-type';

export const getProductInfoHistoryAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<PageType<RevisionType<AdminProductHistoryIntoType & BaseInfoType>>>(
			`${rootUrl}/product/history`,
			{
				params: { id, revisionType: 'ALL' },
			}
		)
	).data;
