import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteProductDiscountInfoAsync =
	(rootUrl: string) =>
	async ({ idList }: DeleteProductDiscountInfoRequestType) =>
		(
			await posAxiosClient.delete(`${rootUrl}/product-discount`, {
				params: {
					ids: idList,
				},
			})
		).data;

type DeleteProductDiscountInfoRequestType = {
	idList: number[];
};
