import {
	CameraFilled,
	ExclamationCircleOutlined,
	PhoneFilled,
	PushpinFilled,
} from '@ant-design/icons';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Dialog, DialogContent, Divider, Stack, Typography } from '@mui/material';
import logo from 'assets/vaunce/image/mologo.png';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useCookies } from 'react-cookie';

interface InnerContents {
	icon: ReactNode;
	title: string;
	subtitle: string;
}

const commonStyle = { fontSize: '25px', color: '#FF5C89' };

const authorityContents: InnerContents[] = [
	{
		icon: <PhoneFilled style={commonStyle} />,
		title: '기기 및 앱기록 (필수)',
		subtitle: '앱 오류 확인 및 사용성 개선 용도',
	},
	{
		icon: <PushpinFilled style={commonStyle} />,
		title: '위치 (필수)',
		subtitle: '사용자 위치 기반 상품 추천 시',
	},
	{
		icon: <CameraFilled style={commonStyle} />,
		title: '저장공간 • 카메라 (선택)',
		subtitle: '구매후기 작성 및 상담톡 이용 시',
	},
];

export interface IAuthorityCheckDialogProps {
	open: boolean;
	handleClose: () => void;
}
export const AuthorityCheckDialog: React.FC<IAuthorityCheckDialogProps> = ({
	open,
	handleClose,
}) => {
	const [showDig, setShowDig] = useCookies(['show_dig']);

	const handleClick = () => {
		setShowDig('show_dig', false, { path: '/', expires: dayjs().add(3, 'M').toDate() });
		handleClose();
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth>
			<DialogContent>
				<Stack spacing={3}>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}>
						<img src={logo} />
					</Stack>
					<Typography
						variant='h5'
						fontWeight={500}
						sx={{ opacity: 0.6 }}>
						{'바운스 모바일을 이용하기 위해 아래의 앱 권한을 허용해 주세요'}
					</Typography>
					<Stack spacing={2}>
						{authorityContents.map((item, idx) => (
							<Stack
								direction={'row'}
								key={idx}
								alignItems={'center'}
								spacing={2}>
								<Box
									sx={{
										border: `2px solid #FF5C89`,
										borderRadius: '50%',
										padding: '7px',
									}}>
									{item.icon}
								</Box>
								<Stack>
									<Typography
										variant='h4'
										fontWeight={700}>
										{item.title}
									</Typography>
									<Typography
										variant='h5'
										sx={{ opacity: 0.6 }}
										fontWeight={500}>
										{item.subtitle}
									</Typography>
								</Stack>
							</Stack>
						))}
					</Stack>
					<Divider />
					<Stack
						direction={'row'}
						spacing={2}>
						<Box>
							<ExclamationCircleOutlined
								style={{ fontSize: '20px', color: 'rgba(0, 0, 0, 0.46)' }}
							/>
						</Box>
						<Typography
							variant='h6'
							sx={{ opacity: 0.46 }}>
							{
								'선택 권한은 서비스 사용 중 필요한 시점에 동의를 받고 있습니다. 허용하지 않으셔도 서비스 이용 가능합니다.'
							}
						</Typography>
					</Stack>
					<RoundedButton
						onClick={handleClick}
						data-cy='authorityBtn'>
						{'확인'}
					</RoundedButton>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
