import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, Tab, Typography } from '@mui/material';
import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { TabPanel } from '../VclubPage';
import AvailableCouponPanel from './AvailableCouponPanel';
import UnavailableCouponPanel from './UnavailableCouponPanel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { useVaunceAppRouter } from '../routes';

export interface ICouponPageProps {}
export const CouponPage: React.FC<ICouponPageProps> = ({}) => {
	const [value, setValue] = React.useState<number>(0);

	const couponList = VaunceQueryState.useCouponListQuery();
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const { navigateregisterCoupon } = useVaunceAppRouter();

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader
				title='내 쿠폰'
				style={{ marginBottom: 1 }}
			/>
			<Stack
				direction={'row'}
				justifyContent={'flex-end'}
				sx={{ cursor: 'pointer', mb: 1 }}
				onClick={() => {
					navigateregisterCoupon();
				}}>
				<Typography
					color='primary'
					variant='h5'>
					쿠폰 등록
				</Typography>
				<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}`, height: 15 }} />
			</Stack>

			<Stack
				height={'100%'}
				overflow={'auto'}>
				<VauncePinkTabs
					value={value}
					onChange={handleChange}
					variant='fullWidth'>
					<Tab label='사용 가능한 쿠폰' />
					<Tab label='사용 만료된 쿠폰' />
				</VauncePinkTabs>

				{/*  Available, Unavailable 공통화 작업 */}
				<TabPanel
					value={value}
					index={0}>
					<AvailableCouponPanel couponList={couponList.availableList} />
				</TabPanel>
				<TabPanel
					value={value}
					index={1}>
					<UnavailableCouponPanel couponList={couponList.unavailableList} />
				</TabPanel>
			</Stack>
		</Stack>
	);
};
