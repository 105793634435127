import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ConnectInfoType } from '@kinderlabs-pos/shared-data-type';

export const getConnectInfoList =
	(rootUrl: string) =>
	async ({ storeId }: RequestType) =>
		(await posAxiosClient.get<ConnectInfoType[]>(`${rootUrl}/connect/store/${storeId}`, {})).data;

type RequestType = {
	storeId: number;
};
