import { PremiumMutType, PremiumOneType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Badge, Stack, Typography } from '@mui/material';
import qrImage from 'assets/vaunce/image/iconqr.png';
import plusImage from 'assets/vaunce/image/magnifyplus.png';
import React from 'react';
import {
	CouponContentBox,
	CouponDescBox,
	ImageBox,
	TicketRefundBox,
	StyledRefundBox,
} from '../../components/CommonStyled';
import { EmptyContent } from '../../components/EmptyContent';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import { PlusImageBox } from '../VipclubPage';
import { OneTicketQrDialog } from './OneTicketQrDialog';
import { useAtom, useSetAtom } from 'jotai';
import { TicketWrapper } from '../VipclubPage/PremiumTab';
import QrImageBadge from '../../components/QrImageBadge';
import plusImage2x from 'assets/vaunce/image/magnifyplus@2x.png';
import { useLocation } from 'react-router-dom';
import { OneTicketRefundDialog } from './OneTicketRefundDialog';

export interface IOneTicketPageProps {}
export const OneTicketPage: React.FC<IOneTicketPageProps> = ({}) => {
	const { navigateticketsale } = useVaunceAppRouter();
	const isWithRefund = useLocation().pathname === '/oneTicketWithRefund' ? true : false;
	const premiumOne = VaunceQueryState.usePremiumOneQuery();

	const [oneTicketCount, setOneTicketCount] = useAtom(VaunceQueryState.OneTicketCountAtom);

	setOneTicketCount(premiumOne.one.length);

	const isExpired = (ticket: PremiumOneType) => {
		const today = new Date();
		const expirationDate = new Date(ticket.expiredAt);
		expirationDate.setDate(expirationDate.getDate() + 1); // 00:00시로 나와있을 경우를 대비하여 1일 추가
		return expirationDate < today;
	};

	return (
		<Stack
			height={'100%'}
			direction={'column'}>
			<PageHeader title='이용권' />
			<Stack
				height={'100%'}
				overflow={'auto'}
				direction={'column'}
				textAlign={'center'}
				alignItems={'center'}>
				{premiumOne.one.length === 0 ? (
					<>
						<EmptyContent title='구매된 티켓이 없습니다' />
						<Stack
							spacing={3}
							width={'100%'}
							mt={'auto'}>
							{!isWithRefund && (
								<RoundedButton
									disabled={false} // 1회권 오픈
									fullWidth
									onClick={navigateticketsale}>
									구매하기
								</RoundedButton>
							)}
						</Stack>
					</>
				) : (
					<Stack
						spacing={3}
						width={'100%'}>
						{premiumOne.one.map((ticket, idx) => (
							<TicketWrapper
								options={
									ticket.status === 'AVAILABLE' && !isExpired(ticket) && !ticket.isRefundInProgress
								}>
								<WithDialog
									dialog={(open, closeDialog) => (
										<OneTicketQrDialog
											open={open}
											onClose={closeDialog}
											ticket={ticket}
										/>
									)}>
									{(openDialog) => (
										<QrImageBadge
											qrImage={qrImage}
											imageSize={'sm'}
											sx={{ cursor: 'pointer' }}
											badgeContent={
												ticket.status === 'AVAILABLE' && !ticket.isRefundInProgress ? (
													<PlusImageBox>
														<img
															src={plusImage}
															srcSet={plusImage2x}
															width={14}
															height={14}
														/>
													</PlusImageBox>
												) : (
													<Stack></Stack>
												)
											}
											onClick={() => {
												ticket.status === 'AVAILABLE' && !ticket.isRefundInProgress && openDialog();
											}}
										/>
									)}
								</WithDialog>

								<CouponDescBox sx={{ mr: 'auto', width: '100%', ml: 2 }}>
									<Typography
										textAlign={'left'}
										variant='h5'
										fontWeight={500}
										color='primary'>
										{ticket.title && ticket.title.split('_').length > 1
											? '[' + ticket.title?.split('_')[1] + '] ' + ticket.title?.split('_')[3]
											: ticket.title}
									</Typography>
									{ticket.status === 'EXPIRED' &&
										!ticket.pg_cancel_amount &&
										!ticket.isRefundInProgress && (
											<Typography
												textAlign={'right'}
												color={'primary'}
												variant='h4'>
												{'기간만료'}
											</Typography>
										)}
									{ticket.usedTicketCount === 1 && !ticket.pg_cancel_amount && (
										<Typography
											textAlign={'right'}
											color={'primary'}
											variant='h4'>
											{'사용완료'}
										</Typography>
									)}
									{ticket.pg_cancel_amount && !ticket.isRefundInProgress && (
										<Typography
											textAlign={'right'}
											color={'primary'}
											variant='h4'>
											{'환불완료'}
										</Typography>
									)}
									{ticket.isRefundInProgress &&
										!ticket.pg_cancel_amount &&
										ticket.usedTicketCount === 0 && (
											<Typography
												textAlign={'right'}
												color={'primary'}
												variant='h4'>
												{'환불신청완료'}
											</Typography>
										)}
									{isWithRefund &&
									((ticket.status === 'AVAILABLE' && !ticket.pg_cancel_amount) ||
										(ticket.status === 'EXPIRED' &&
											ticket.remainderTicketCount === 1 &&
											!ticket.pg_cancel_amount)) ? (
										<StyledRefundBox>
											<WithDialog
												dialog={(open, closeDialog) => (
													<OneTicketRefundDialog
														open={open}
														onClose={closeDialog}
														ticket={ticket}
													/>
												)}>
												{(openDialog) => (
													<TicketRefundBox
														onClick={() => {
															openDialog();
														}}>
														<Typography
															color={'primary'}
															variant='h5'
															fontWeight={400}>
															{ticket.isRefundInProgress ? '환불취소' : '환불신청'}
														</Typography>
													</TicketRefundBox>
												)}
											</WithDialog>

											<Typography
												variant='h5'
												textAlign={'right'}
												fontWeight={500}>
												유효 기간: {ticket.expiredAt}
											</Typography>
										</StyledRefundBox>
									) : (
										<Typography
											variant='h5'
											textAlign={'right'}
											fontWeight={500}>
											유효 기간: {ticket.expiredAt}
										</Typography>
									)}
								</CouponDescBox>
							</TicketWrapper>
						))}
						{!isWithRefund && (
							<RoundedButton
								disabled={false} // 1회권 오픈
								fullWidth
								onClick={navigateticketsale}>
								구매하기
							</RoundedButton>
						)}
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

interface PlusImageBtnProps {
	ticket: PremiumOneType;
}

const PlusImageBtn = ({ ticket }: PlusImageBtnProps) => {
	return (
		<>
			<WithDialog
				dialog={(open, closeDialog) => (
					<OneTicketQrDialog
						open={open}
						onClose={closeDialog}
						ticket={ticket}
					/>
				)}>
				{(openDialog) => (
					<PlusImageBox>
						<img
							src={plusImage}
							onClick={openDialog}
						/>
					</PlusImageBox>
				)}
			</WithDialog>
		</>
	);
};
