import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteUnusedProductVariantsAsync =
	(rootUrl: string) =>
	async ({ storeId, productIdList }: { storeId: number; productIdList: number[] }) =>
		(
			await posAxiosClient.delete(`${rootUrl}/product/store/variant`, {
				params: {
					storeId,
					productIdList,
				},
			})
		).data;
