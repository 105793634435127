import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	DialogContent,
	DialogProps,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import React from 'react';
import { PersonAgreeData } from './PersonInfoAgreeData';

export interface IPersonInfoAgreeDialogProps extends DialogProps {
	closeDialog: () => void;
}
export const PersonInfoAgreeDialog: React.FC<IPersonInfoAgreeDialogProps> = ({
	open,
	closeDialog,
}) => {
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			fullWidth
			maxWidth={'lg'}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}>
			<DialogContent>
				<Stack spacing={2}>
					<Typography
						color={vaunceColors.lightPink}
						variant='h4'>
						개인정보 수집 및 이용 안내
					</Typography>
					<Divider />
					<Stack
						mt={2}
						spacing={1}>
						<Typography
							variant='h4'
							fontWeight={700}>
							제1장 총칙
						</Typography>
						<Typography variant='h4'>
							당사는 회원 서비스 제공을 위해 아래와 같이 최소한의 개인정보만을 수집합니다.
						</Typography>
						{PersonAgreeData.map((data, idx) => (
							<Stack
								key={idx}
								spacing={1}>
								<Typography
									variant='h4'
									sx={{ mt: 2 }}>
									{data.title}
								</Typography>
								{data.contents.map((item, idx) => (
									<Stack
										spacing={1}
										key={idx}>
										<Typography variant='h4'>{item.subtitle}</Typography>
										{item.type === 'text' ? (
											<Typography variant='h4'>{item.actualContent[0].detail}</Typography>
										) : (
											<ol style={{ padding: '1rem' }}>
												{item.actualContent.map((item, idx) => (
													<>
														<Typography variant='h4'>
															<li key={idx}>{item.detail}</li>
														</Typography>
														{item.subDetail && (
															<ol style={{ padding: '1rem' }}>
																{item.subDetail.map((item, idx) => (
																	<Typography variant='h4'>
																		<li key={idx}>{item}</li>
																	</Typography>
																))}
															</ol>
														)}
													</>
												))}
											</ol>
										)}
									</Stack>
								))}
							</Stack>
						))}
					</Stack>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
