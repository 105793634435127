import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import qs from 'qs';

export const deleteStoreInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: DeleteStoreInfoListRequestType) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/store/list`, {
				params: {
					storeId: storeId,
				},
			})
		).data;

export type DeleteStoreInfoListRequestType = { storeId: number };
