const lightGray = 'rgba(0, 0, 0, 0.5)';
const lightPink = '#FF5C89';
const skeletonGray = '#F2F2F2';
export const vaunceAppColors = {
	// yellow: yellow,
	// pink: pink,
	lightPink: lightPink,
	// deepMint: deepMint,
	// mint: mint,
	// paleGray: paleGray,
	lightGray: lightGray,
	skeletonGray: skeletonGray,
	// darkGray: darkGray,
	// gray: gray,
	// navy: navy,
	// registerBackground: registerBackground,
};
