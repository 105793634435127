import { useState } from 'react';

type onCopyFunction = (text: string, type: string) => Promise<boolean>;

function useCopyClipBoard(): [boolean, onCopyFunction] {
	const [isCopy, setIsCopy] = useState<boolean>(false);

	//android에선 navigator.clipboard 가 정상적으로 동작 안함
	const onCopy: onCopyFunction = async (text: string, type: string) => {
		try {
			const element = document.createElement('textarea');
			element.value = text;
			element.setAttribute('readonly', '');
			element.style.position = 'fixed';
			element.style.opacity = '0';
			document.body.appendChild(element);
			element.select();
			const copyValue = document.execCommand('copy');
			document.body.removeChild(element);
			alert(`${type}가 완료되었습니다.`);
			if (!copyValue) {
				throw new Error('주소복사가 실패하였습니다.');
			}
			setIsCopy(true);
			return true;
		} catch (error) {
			console.error(error);
			setIsCopy(false);
			return false;
		}
	};

	return [isCopy, onCopy];
}

export default useCopyClipBoard;
