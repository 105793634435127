import { FlexBox } from '@kinderlabs-pos/ui-atoms';
import { BoxProps, styled, Tab, Tabs, Typography } from '@mui/material';

const Menu = [
	{
		label: 'POS',
	},
	{
		label: '회원권',
	},
	{
		label: '온라인권',
	},
	// {
	//   label: '클래스',
	// },
];

export interface IMenuTabsProps extends BoxProps {
	selectedTab: 0 | 1 | 2 | 3;
	selectTab: (tab: 0 | 1 | 2 | 3) => void;
}
export const MenuTabs: React.FC<IMenuTabsProps> = ({ selectedTab, selectTab, ...others }) => {
	return (
		<FlexBox
			gap={1}
			{...others}>
			<StyledTabs
				sx={{ width: '100%' }}
				variant='fullWidth'
				value={selectedTab}
				centered
				onChange={(e, newValue) => {
					selectTab(newValue);
				}}>
				{Menu.map((category, idx) => (
					<Tab
						key={idx}
						value={idx}
						label={<Typography variant='subtitle1'>{category.label}</Typography>}
					/>
				))}
			</StyledTabs>
		</FlexBox>
	);
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
	'& .MuiTabs-indicator': {
		height: 0,
	},
	'& .MuiTab-root': {
		color: 'gray',
		borderRadius: '8px 8px 0px 0px',
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		'&.Mui-selected': {
			color: theme.palette.primary.main,
			border: `2px solid ${theme.palette.primary.main}`,
			borderBottomWidth: 0,
			backgroundColor: 'white',
		},
	},
}));
