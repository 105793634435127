import { PosBoard } from '@kinderlabs-pos/feature/pos-board';
import {
	boardColorSelectionsMap,
	PosBoardItemType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';

export interface IProductBoardActionBoardItemProps {
	item: PosBoardItemType;
	productInfo: ProductInfoType;
	onClick: () => void;
	disabled?: boolean;
}
export const ProductBoardActionBoardItem: React.FC<IProductBoardActionBoardItemProps> = ({
	productInfo,
	item,
	onClick,
	disabled = false,
}) => {
	return (
		<PosBoard.Item.Base
			CardActionProps={{
				disabled: disabled,
				onClick: onClick,
				sx: {
					bgcolor: boardColorSelectionsMap[item.color],
				},
			}}>
			<PosBoard.Label
				deviceType={'POS'}
				label={item.label}
				productInfo={productInfo}
			/>
		</PosBoard.Item.Base>
	);
};
