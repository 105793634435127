import { CircularProgress, Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';

import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useVaunceAppRouter } from '../pages/routes';
import { VaunceErrorComponent } from '../pages/VaunceErrorPage';
import { HomeFooter } from './HomeFooter';
import { MainHeader } from './MainHeader';
import { SidebarDrawer } from './SidebarDrawer';
import { pageLouteLog } from '../utils/TechLabsLogUtils';

export interface IMainContentProps {}
export const MainContent: React.FC<IMainContentProps> = ({}) => {
	const [searchParams] = useSearchParams();

	const { navigateLogin, navigateHomepage } = useVaunceAppRouter();
	const [drawerOpened, setDrawerOpened] = React.useState(false);
	const location = useLocation();
	console.log('location.pathname', location.pathname);
	pageLouteLog(location.pathname);

	useEffect(() => {
		const snsAccessToken = searchParams.get('accessToken');
		if (snsAccessToken) {
			localStorage.setItem('accessToken', snsAccessToken);
		}
		navigateHomepage();
	}, []);

	const toggleDrawer = () => {
		setDrawerOpened((prev) => !prev);
	};

	return (
		<ErrorBoundary fallback={<VaunceErrorComponent />}>
			<Stack
				sx={{
					height: '100%',
					backgroundImage: 'linear-gradient(115deg, #F7A500, #B000D9 60%)',
					position: 'relative',
					paddingTop: [
						'calc(env(safe-area-inset-top) + 5px)',
						'calc(constant(safe-area-inset-top) + 5px)',
					],
					// paddingBottom: [
					// 	'calc(env(safe-area-inset-bottom))',
					// 	'calc(constant(safe-area-inset-bottom))',
					// ],
				}}>
				<MainHeader />
				<SidebarDrawer
					toggleDrawer={toggleDrawer}
					opened={drawerOpened}
				/>
				<Stack
					flex={1}
					position='relative'
					sx={{
						overflowY: 'auto',
					}}>
					<Stack
						sx={{
							overflowY: 'auto',
							overflowX: 'hidden',
							flex: 1,
							backgroundColor: '#FFFFFF',
							borderTopLeftRadius: '25px',
							borderTopRightRadius: '25px',
							padding: location.pathname !== '/event' ? '16px 17px 10px' : '',
							margin: '0 8px',
						}}
						className='safeArea'>
						<Stack
							flex={1}
							height={'100%'}>
							<Suspense
								fallback={
									<Stack
										sx={{ overflowY: 'auto' }}
										flex={1}
										justifyContent={'center'}
										alignItems={'center'}>
										<CircularProgress />
									</Stack>
								}>
								<Outlet />
							</Suspense>
						</Stack>
					</Stack>
				</Stack>
				<HomeFooter toggleDrawer={toggleDrawer} />
			</Stack>
		</ErrorBoundary>
	);
};
