import {
	CartLineInfoType,
	CartLineTypeRules,
	ProductDiscountInfoType,
	ProductDiscountMgInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, authState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, NumberBoard, useAlert } from '@kinderlabs-pos/ui-components';
import {
	Button,
	Checkbox,
	DialogActions,
	Divider,
	List,
	ListItemButton,
	OutlinedInput,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import produce from 'immer';
import { useAtomValue } from 'jotai';
import { Fragment, Suspense, useEffect, useState } from 'react';
import { ProductQuantityAdjustItemStack } from './ProductQuantityAdjustItemStack';
import { CustomDiscountSelect } from './CustomDiscountSelect';
import { ProuductDiscountSelect } from './ProductDiscountSelect';

export const CartDiscountContent = ({
	closeDialog,
	remainingValue,
}: {
	remainingValue: number;
	closeDialog?: () => void;
}) => {
	const usingDuplicateDiscount = useAtomValue(authState.enterpriseInfo)?.usingDuplicateDiscount;
	const dispatch = OrderState.cart.useDispatcher();

	const allCartLines = useAtomValue(OrderState.cart.selector).lines;
	const inputCartLines = allCartLines.filter((cl) => CartLineTypeRules.canApplyDiscount[cl.type]);

	const { handleSubmit, handleChange, values, setValues, errors } = useFormik<{
		//상품타입
		cartLines: (CartLineInfoType & { selected: boolean })[];
		customAmount: number;
		selectedProductDiscount?: ProductDiscountMgInfoType;
		customDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
		lowestPrice: number;
		discountMode: 'custom' | 'product';
	}>({
		initialValues: {
			cartLines: inputCartLines.map((cl) => ({ ...cl, selected: false })),
			customAmount: 0,
			customDiscountInfo: {
				value: 0,
				type: 'PERCENTAGE',
			},
			lowestPrice: 0,
			discountMode: 'product',
		},
		onSubmit: (values, { setErrors, resetForm }) => {
			if (values.discountMode === 'custom') {
				values.cartLines
					.filter((cl) => cl.selected)
					.forEach((cartLine) => {
						dispatch({
							type: 'CUSTOM',
							subAction: {
								type: 'ADD_CUSTOM_DISCOUNT',
								cartLineId: getUuidV4(),
								productDiscountInfo: {
									type: values.customDiscountInfo.type,
									value: values.customDiscountInfo.value,
								},
								quantity: cartLine.quantity,
								targetPrice: cartLine.price,
								targetCartLineId: cartLine.id,
								taxFreeTarget: cartLine.productAdditionalInfo.taxFree,
							},
						});
					});
			} else if (values.discountMode === 'product') {
				values.cartLines
					.filter((cl) => cl.selected)
					.forEach((cartLine) => {
						if (!values.selectedProductDiscount) throw Error;

						dispatch({
							type: 'DISCOUNTS',
							subAction: {
								type: 'ADD',
								cartLineId: getUuidV4(),
								discountName: values.selectedProductDiscount.name,
								discountInfoId: values.selectedProductDiscount.id,
								productDiscountInfo: {
									type: values.selectedProductDiscount.type,
									value: values.selectedProductDiscount.value,
								},
								quantity: cartLine.quantity,
								targetPrice: cartLine.price,
								targetCartLineId: cartLine.id,
								taxFreeTarget: cartLine.productAdditionalInfo.taxFree,
							},
						});
					});
			}
		},
	});

	const customAlert = useAlert();

	useEffect(() => {
		const selectedCL = values.cartLines.filter((cl) => cl.selected);
		if (!selectedCL.length) return;
		const lowestPrice = Math.min(...selectedCL.map((cl) => cl.price));
		const totalQuantity = selectedCL.reduce((arr, val) => arr + val.quantity, 0);

		if (values.customAmount * totalQuantity > remainingValue) {
			customAlert('남은 금액이 0원 이하가 될 수 없습니다.');
			setValues({ ...values, customAmount: 0 });
			// setCustomAmount(0);
		} else {
			setValues(
				produce(values, (draft) => {
					draft.lowestPrice = lowestPrice;
					return draft;
				})
			);
		}
	}, [values]);

	useEffect(() => {
		setValues(
			produce(values, (draft) => {
				draft.customDiscountInfo.value = values.customAmount;
				return draft;
			})
		);
	}, [values.customAmount, remainingValue]);

	const handleClickSelect = (targetIdx: number) => {
		setValues(
			produce(values, (draft) => {
				draft.cartLines[targetIdx].selected = !draft.cartLines[targetIdx].selected;
				return draft;
			})
		);
	};

	const handleChangeQuantity = (targetIdx: number, newQuantity: number) => {
		if (newQuantity > inputCartLines[targetIdx].quantity) return;
		setValues(
			produce(values, (draft) => {
				draft.cartLines[targetIdx].quantity = newQuantity;
				return draft;
			})
		);
	};

	useEffect(() => {
		setValues({ ...values, cartLines: values.cartLines.map((cl) => ({ ...cl, selected: false })) });
	}, [values.selectedProductDiscount, values.discountMode]);

	return (
		<Stack
			direction='column'
			spacing={4}
			overflow={'auto'}
			width={750}
			height={522}>
			<Stack
				direction='row'
				spacing={3}
				flex={1}
				overflow={'auto'}
				justifyContent='space-between'>
				<Stack
					direction='column'
					spacing={1}
					width={300}>
					<ToggleButtonGroup
						fullWidth
						onChange={(e, newDiscountMode) => {
							if (!newDiscountMode) return;
							setValues({ ...values, discountMode: newDiscountMode });
						}}
						exclusive
						value={values.discountMode}>
						<ToggleButton value={'product'}>{'상품할인'}</ToggleButton>
						<ToggleButton value={'custom'}>{'임의할인'}</ToggleButton>
					</ToggleButtonGroup>

					{values.discountMode === 'product' && (
						<Suspense fallback={<FullSizeCircularProgress />}>
							<ProuductDiscountSelect
								selectedProductList={values.cartLines}
								selectedProductDiscount={values.selectedProductDiscount}
								setSelectedProductDiscount={(input) =>
									setValues({ ...values, selectedProductDiscount: input })
								}
							/>
						</Suspense>
					)}
					{values.discountMode === 'custom' && (
						<CustomDiscountSelect
							amount={values.customAmount}
							setAmount={(newAmount) => setValues({ ...values, customAmount: newAmount })}
							discountType={values.customDiscountInfo.type}
							setDiscountType={(newValue) => {
								setValues(
									produce(values, (draft) => {
										draft.customDiscountInfo.type = newValue;
									})
								);
							}}
							lowestPrice={values.lowestPrice}
						/>
					)}
				</Stack>
				<Stack
					direction='column'
					overflow={'auto'}
					flex={1}
					spacing={1}>
					<Typography variant='h5'>적용할 상품</Typography>
					<List
						disablePadding
						sx={{ width: '100%', overflow: 'auto' }}>
						{values.cartLines.map((cartLine, idx) => {
							const cannotApplyDiscount =
								CartLineTypeRules.cannotSelectInCart[cartLine.type] ||
								(!usingDuplicateDiscount &&
									CartLineTypeRules.hasAlreadyDiscounts(allCartLines, cartLine.id));

							const isProductApplicableForSelectedDiscount =
								values.selectedProductDiscount &&
								(values.selectedProductDiscount.discountProductWhitelist?.length === 0 ||
									values.selectedProductDiscount?.discountProductWhitelist?.includes(
										cartLine.productInfoId || 0
									));

							return (
								<Fragment key={idx}>
									<SelectableListItem
										sx={{ display: 'flex', flexDirection: 'row', gap: 1, height: 48 }}
										onClick={() => handleClickSelect(idx)}
										disabled={
											values.discountMode === 'product'
												? !isProductApplicableForSelectedDiscount || cannotApplyDiscount
												: cannotApplyDiscount
										}
										selected={cartLine.selected}>
										<Checkbox
											sx={{ width: 18, height: 18 }}
											checked={cartLine.selected}
										/>
										<ProductQuantityAdjustItemStack
											cartLine={cartLine}
											changeQuantity={(quantity) => handleChangeQuantity(idx, quantity)}
											inputDisabled={true}
										/>
									</SelectableListItem>
									<Divider component={'li'} />
								</Fragment>
							);
						})}
					</List>
				</Stack>
			</Stack>
			<DialogActions>
				<Stack
					direction='row'
					justifyContent='flex-end'
					spacing={1}>
					<Button
						variant='contained'
						disabled={values.cartLines.filter((cl) => cl.selected).length === 0}
						onClick={() => {
							if (values.discountMode === 'custom' && values.customDiscountInfo.value) {
								handleSubmit();
							} else if (values.discountMode === 'product' && !!values.selectedProductDiscount) {
								handleSubmit();
							}
							closeDialog && closeDialog();
						}}>
						적용
					</Button>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						취소
					</Button>
				</Stack>
			</DialogActions>
		</Stack>
	);
};
