import { PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { ReactNode } from 'react';
import { RetryPaymentDialogContents } from './RetryPaymentDialogContents';

interface IPaymentDialogProps extends XlDialogProps {
	failedPaymentRequest?: PaymentRequestInfoType;
	children: ReactNode;
	handleSubmitPayment: (args: PaymentRequestInfoType) => Promise<void>;
	onClose?: () => void;
}
export const PaymentDialog = ({
	failedPaymentRequest,
	children,
	handleSubmitPayment,
	onClose,
	...others
}: IPaymentDialogProps) => {
	return (
		<WithDialog.XlDialog {...others}>
			{failedPaymentRequest && (
				<RetryPaymentDialogContents
					onClose={onClose}
					paymentRequestInfo={failedPaymentRequest}
					handleSubmitPayment={handleSubmitPayment}
				/>
			)}
			{!failedPaymentRequest && children}
		</WithDialog.XlDialog>
	);
};
