import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, BoxProps, Divider, Stack, styled, Tab, Typography } from '@mui/material';
import clubMintImage from 'assets/vaunce/image/clubMint.png';
import clubMintImage2x from 'assets/vaunce/image/clubMint.png';

import clubPinkImage from 'assets/vaunce/image/clubPink.png';
import clubPinkImage2x from 'assets/vaunce/image/clubPink@2x.png';

import clubGoldImage from 'assets/vaunce/image/clubGold.png';
import clubGoldImage2x from 'assets/vaunce/image/clubGold@2x.png';

import couponImage from 'assets/vaunce/image/coupons.png';
import couponImage2x from 'assets/vaunce/image/coupons@2x.png';

import React from 'react';
import { GradeTextBox } from '../../components/GradeTextBox';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { useVaunceAppRouter } from '../routes';
import PointRemovePanel from './PointRemovePanel';
import PointSvePanel from './PointSavePanel';
import { getGradeMemberShip } from '../../dialog/MemberQrDialog';
import { MyInfoBodyType } from '@kinderlabs-pos/shared-data-type';

//TODO API 받아와서 채워줘야함
export interface IVclubPageProps {}
export const VclubPage: React.FC<IVclubPageProps> = ({}) => {
	const { navigateCouponList } = useVaunceAppRouter();

	const myInfo = VaunceQueryState.useMyInfoQuery();

	const [tab, setTab] = React.useState<number>(0);

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	if (!myInfo) return <></>;

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='바운스클럽' />
			<Stack
				flex={1}
				overflow={'inherit'}
				spacing={2}>
				<UserGradeInfo myInfo={myInfo} />
				<Divider />
				<Stack
					direction={'row'}
					justifyContent={'space-around'}
					alignItems={'center'}>
					<Stack
						textAlign={'center'}
						spacing={1}>
						<Typography variant='h2'>{myInfo.countUpgrade}</Typography>
						<Box>
							<Typography
								variant='h6'
								fontWeight={400}>
								승급까지
							</Typography>
							<Typography
								variant='h6'
								fontWeight={400}>
								남은 횟수
							</Typography>
						</Box>
					</Stack>
					<Stack
						textAlign={'center'}
						spacing={1}>
						<Typography variant='h2'>{myInfo?.point} P</Typography>
						<Box>
							<Typography
								variant='h6'
								fontWeight={400}>
								현재 보유
							</Typography>
							<Typography
								variant='h6'
								fontWeight={400}>
								포인트
							</Typography>
						</Box>
					</Stack>
					<Stack
						textAlign={'center'}
						spacing={2}
						onClick={navigateCouponList}>
						<Box>
							<img
								src={couponImage}
								srcSet={couponImage2x}
								width={34}
								height={32}
							/>
						</Box>
						<Box>
							<Typography variant='h6'>{''}</Typography>
							<Typography
								variant='h6'
								fontWeight={400}>
								보관쿠폰
							</Typography>
						</Box>
					</Stack>
				</Stack>
				<Divider />
				<VauncePinkTabs
					value={tab}
					onChange={handleChangeTab}
					variant='fullWidth'>
					<Tab label='포인트 적립/사용' />
					<Tab label='포인트 소멸예정' />
				</VauncePinkTabs>
				<Stack
					flex={1}
					overflow={'inherit'}
					className={'check2'}>
					<TabPanel
						value={tab}
						index={0}>
						<PointSvePanel />
					</TabPanel>
					<TabPanel
						value={tab}
						index={1}>
						<PointRemovePanel />
					</TabPanel>
				</Stack>
			</Stack>
		</Stack>
	);
};

interface IUserGradeInfo {
	myInfo: MyInfoBodyType;
}
const UserGradeInfo: React.FC<IUserGradeInfo> = ({ myInfo }) => {
	return (
		<Stack
			direction={'row'}
			spacing={4}
			ml={3}>
			<Box>
				{myInfo.membership === 'MINT' && (
					<img
						src={clubMintImage}
						srcSet={clubMintImage2x}
						width={55}
						height={55}
					/>
				)}
				{myInfo.membership === 'PINK' && (
					<img
						src={clubPinkImage}
						srcSet={clubPinkImage2x}
						width={55}
						height={55}
					/>
				)}
				{myInfo.membership === 'GOLD' && (
					<img
						src={clubGoldImage}
						srcSet={clubGoldImage2x}
						width={55}
						height={55}
					/>
				)}
			</Box>
			<Stack>
				<Stack
					direction={'row'}
					alignItems={'baseline'}
					mb={'5px'}>
					<Typography variant='h4'>{myInfo.userName}</Typography>
					<Typography variant='h5'>님의 회원등급</Typography>
				</Stack>
				<Stack direction={'row'}>
					<GradeTextBox
						grade={myInfo.membership}
						withBackgroundColor
						fontSize={'1.65em'}
						longVer
					/>
					<Box flex={1} />
				</Stack>
			</Stack>
		</Stack>
	);
};
interface TabPanelProps extends BoxProps {
	index: number;
	value: number;
}

export function TabPanel({ children, value, index, ...other }: TabPanelProps) {
	return <Box {...other}>{value === index && children}</Box>;
}

export const MintBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#3EB489',
	borderRadius: '15px',
	color: '#FFF',
	textTransform: 'uppercase',
	padding: '1px 12.5px',
	display: 'inline-block',
	width: 'max-content',
	fontSize: '1.6em',
	fontWeight: 700,
}));
