import { PaymentAPI } from '@kinderlabs-pos/apis/pos';
import {
	OrderDetailType,
	PaymentInfoType,
	PaymentRequestInfoType,
} from '@kinderlabs-pos/shared-data-type';
import {
	OrderQueryState,
	OrderState,
	PaymentQueryState,
	ReceiptQueryState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useHandleRefund, HandleRefundArgsType } from './handleRefundForRefundReceipt';
import dayjs from 'dayjs';
import { QueryClient } from '../../QueryClient';

// TODO : 현재 쓰이지 않는듯 하여 일단 주석 처리
// const cancel현금영수증Atom = atom(
// 	null,
// 	async (
// 		get,
// 		set,
// 		{
// 			payment,
// 			order,
// 			receipt,
// 			customerId,
// 		}: {
// 			payment: PaymentInfoType;
// 			order: OrderDetailType;
// 			receipt: ReceiptInfoType;
// 			customerId: string;
// 		}
// 	) => {
// 		const storeInfo = get(StoreInfoState.curStoreInfo);
// 		const posId = get(StoreInfoState.curPosInfo).id;

// 		const canceledReceipt: ReceiptInfoType = {
// 			...(await handle환불_결제모듈({
// 				method: 'CASH_현금영수증_발급',
// 				receipt: receipt,
// 				isSignPadUsed: false,
// 				customerId: customerId,
// 			})),
// 			receiptType: 'REFUND',
// 		};

// 		const result = await PaymentAPI.cancelReceipt({
// 			storeId: storeInfo.id,
// 			posId: posId,
// 			paymentId: payment.id,
// 			receipt: canceledReceipt,
// 		});

// 		set(OrderState.value, {
// 			type: 'PAYMENTS',
// 			subAction: {
// 				type: 'CANCEL_CASH_RECEIPT',
// 				paymentId: payment.id,
// 			},
// 		});

// 		OrderQueryState.invalidateQueries();
// 		PaymentQueryState.invalidateQueries();
// 		ReceiptQueryState.invalidateQueries();

// 		set(PrintReceiptState.영수증인쇄, {
// 			baseReceiptPayload: {
// 				type: 'CANCEL',
// 				storeInfo,
// 				orderId: order.id,
// 				orderDailySeq: order.dailySeq,
// 				paymentId: result.id,
// 				orderList: OrderState.cart.utils.getOrderListFromCart(order.cart.lines),
// 				aggregate: order.aggregate,
// 			},
// 			receiptOption: {
// 				subType: 'OFFICIAL',
// 				receipt: canceledReceipt,
// 			},
// 		});
// 	}
// );

type RefundPayloadType = {
	payment: PaymentInfoType;
	order: OrderDetailType;
} & HandleRefundArgsType;

const useRefundOfficial중복결제 = () => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();
	const handleRefund = useHandleRefund(vanCompany);
	return async ({
		paymentInfo,
		customerId,
	}: {
		paymentInfo: PaymentRequestInfoType;
		customerId?: string;
	}) => {
		const payload: HandleRefundArgsType = getRefundArgsFor중복결제({ paymentInfo, customerId });
		// console.log(payload);
		return await handleRefund(payload);
	};
};

const getRefundArgsFor중복결제 = ({
	paymentInfo,
	customerId,
}: {
	paymentInfo: PaymentRequestInfoType;
	customerId?: string;
}): HandleRefundArgsType => {
	if (!paymentInfo.receipt) throw Error();

	switch (paymentInfo.method) {
		case 'CARD':
		case 'CASH_IC':
		case 'CARD_MS':
			return {
				method: paymentInfo.method,
				receipt: paymentInfo.receipt,
			};
		case 'CASH':
			if (!customerId) throw Error();
			return {
				method: 'CASH_현금영수증_발급',
				receipt: paymentInfo.receipt,
				isSignPadUsed: false,
				customerId,
			};
		case 'TRANSFER':
			if (!customerId) throw Error();
			return {
				method: 'TRANSFER_현금영수증_발급',
				receipt: paymentInfo.receipt,
				isSignPadUsed: false,
				customerId,
			};
		case 'KAKAOPAY':
		case 'SSGPAY':
		case 'APPCARD':
		case 'ZEROPAY':
		case 'SEOULPAY':
			if (!customerId) throw Error();
			return {
				method: paymentInfo.method,
				receipt: paymentInfo.receipt,
				customerId,
			};
		default:
			throw new Error('중복결제 취소만 가능합니다.');
	}
};

const useRefund = () => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();
	const setOrder = useSetAtom(OrderState.value);
	const handleRefund = useHandleRefund(vanCompany);

	return async ({ order, payment, ...payload }: RefundPayloadType) => {
		const refundReceipt = await handleRefund(payload);
		const needToMonitor =
			dayjs(order.created).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD');

		const refundResult = await PaymentAPI.refundPayment({
			storeId,
			posId: deviceId,
			paymentId: payment.id,
			refundReceipt,
			needToMonitor,
		});

		setOrder({
			type: 'PAYMENTS',
			subAction: { type: 'REFUND', refundedPaymentInfo: refundResult },
		});

		OrderQueryState.invalidateQueries();
		PaymentQueryState.invalidateQueries();
		ReceiptQueryState.invalidateQueries();
	};
};

const useRefundByForce = () => {
	return QueryClient.useMutation(
		async (payment: PaymentInfoType) => {
			return await PaymentAPI.refundPayment({
				storeId: payment.storeId,
				posId: payment.posId,
				paymentId: payment.id,
				needToMonitor: true,
			});
		},
		{
			onSuccess: () => {
				OrderQueryState.invalidateQueries();
				PaymentQueryState.invalidateQueries();
				ReceiptQueryState.invalidateQueries();
			},
		}
	);
};

export const PaymentRefundActions = {
	useRefund,
	useRefundOfficial중복결제,
	useRefundByForce,
	// cancel현금영수증: cancel현금영수증Atom,
	// 취소영수증재인쇄: 취소영수증재인쇄Atom,
};
