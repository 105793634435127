import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	Pageable,
	PageType,
	VaultCashCheckType,
	VaultCashHistoryType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getPosHistoryAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		storeIdList,
		posId,
		from,
		to,
		pageable,
		vaultCashType,
	}: {
		storeId?: number;
		storeIdList?: number[];
		posId?: number;
		from?: Dayjs;
		to?: Dayjs;
		pageable: Pageable;
		vaultCashType?: VaultCashCheckType;
	}) =>
		(
			await posAxiosClient.get<PageType<VaultCashHistoryType>>(`${rootUrl}/vault-cash/history`, {
				params: {
					storeIdList,
					targetStoreId: storeId,
					posId: posId,
					from: from?.format('YYYY-MM-DD'),
					to: to?.add(1, 'days').format('YYYY-MM-DD'),
					vaultCashType,
					...pageable.getPageableQueryParam(),
				},
			})
		).data;
