import { useState, SyntheticEvent } from 'react';

export const useDialogActions = () => {
	const [open, setOpen] = useState(false);
	const openDialog = (e?: SyntheticEvent) => {
		e?.stopPropagation();
		setOpen(true);
	};
	const closeDialog = (e?: SyntheticEvent) => {
		e?.stopPropagation();
		setOpen(false);
	};

	return {
		open,
		openDialog,
		closeDialog,
	};
};
