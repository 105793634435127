import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, KDSInfoType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../const';

const domain_name = 'store/kds';

const getKDSInfoListAsync = (rootUrl: string) => async (storeId: number) =>
	(
		await posAxiosClient.get<(BaseInfoType & KDSInfoType)[]>(`${rootUrl}/${domain_name}/list`, {
			params: {
				storeId,
			},
		})
	).data;

const getKDSInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, KDSId }: { storeId: number; KDSId: number }) =>
		(
			await posAxiosClient.get<BaseInfoType & KDSInfoType>(`${rootUrl}/${domain_name}`, {
				params: {
					storeId,
					id: KDSId,
				},
			})
		).data;

const createKDSInfoAsync =
	(rootUrl: string) =>
	async ({ data }: { data: Omit<KDSInfoType, 'id'> }) =>
		(
			await posAxiosClient.post<BaseInfoType & KDSInfoType>(`${rootUrl}/${domain_name}`, {
				...data,
			})
		).data;

const updateKDSInfoAsync =
	(rootUrl: string) =>
	async ({ data }: { data: KDSInfoType }) =>
		(
			await posAxiosClient.put(
				`${rootUrl}/${domain_name}`,
				{ ...data },
				{
					params: {
						id: data.id,
					},
				}
			)
		).data;

const deleteKDSInfoAsync = (rootUrl: string) => async (idList: number[]) =>
	(
		await posAxiosClient.delete(`${rootUrl}/${domain_name}/list`, {
			params: {
				idList,
			},
		})
	).data;

export const KDSInfoApis = {
	getInfoList: getKDSInfoListAsync(rootUrl),
	getInfo: getKDSInfoAsync(rootUrl),
	create: createKDSInfoAsync(rootUrl),
	update: updateKDSInfoAsync(rootUrl),
	delete: deleteKDSInfoAsync(rootUrl),
};
