import { DesktopOutlined } from '@ant-design/icons';
import { isOnElectron } from '@kinderlabs-pos/ipc/react';
import {
	PosInfoType,
	PosStatusDetailedType,
	PosTypeLabel,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import {
	AdminStoreInfoState,
	PosStatusQueryState,
	VaultCashState,
	authState,
} from '@kinderlabs-pos/state';
import { List, SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { getPosSummaryText } from './StatusGuideText';

export const PosSelectList = ({
	selectedStore,
	selectedPos,
	selectPos,
	setPosState,
}: {
	selectedStore: StoreInfoType;
	selectedPos?: PosInfoType;
	selectPos: (selectedPos?: PosInfoType) => void;
	setPosState: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
}) => {
	const theme = useTheme();
	const user = useAtomValue(authState.user);
	const [posList, setPosList] = useState<PosInfoType[]>([]);
	const { data: posStatusListData } = useQuery({
		...PosStatusQueryState.keys.list(selectedStore.id),
	});
	const posStatusList = posStatusListData ?? [];

	const { data } = useQuery({
		...VaultCashState.keys.storeStatus(selectedStore.id),
	});

	const storeVaultcashStatus = data ?? [];

	const { data: detailStoreInfo } = useQuery({
		...AdminStoreInfoState.keys.detail(selectedStore.id),
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		VaultCashState.invalidateStatus();

		if (!selectedStore) return;

		const canShowWebPos = !isOnElectron || user?.authority === 'SUPERUSER';

		detailStoreInfo &&
			setPosList(
				canShowWebPos
					? detailStoreInfo.posInfoList
					: detailStoreInfo.posInfoList.filter((p) => p.posType !== 'WEBPOS')
			);
	}, [selectedStore, detailStoreInfo]);

	useEffect(() => {
		if (!selectedPos) setPosState(undefined);
	}, [selectedPos]);

	const handleClickPosInfo = (posInfo: PosInfoType) => {
		if (posStatusList.length > 0) {
			const state = posStatusList.find((value) => value.posId === posInfo.id)?.posStatus;
			setPosState(selectedPos?.id === posInfo.id ? undefined : state);
		}
		selectPos(selectedPos?.id === posInfo.id ? undefined : posInfo);
	};

	return (
		<List>
			{posList.map((posInfo, idx) => {
				const selected = selectedPos?.id === posInfo.id;
				const eachPosStatus = posStatusList?.find((value) => value.posId === posInfo.id)?.posStatus;
				const eachVaultcashStatus = storeVaultcashStatus.find(
					(value) => value.posId === posInfo.id
				);

				// const loginned = loginStatusMap[posInfo.id]
				// 	? loginStatusMap[posInfo.id].status === 'ONLINE'
				// 	: false;
				// const lastAccess = loginStatusMap[posInfo.id]
				// 	? loginStatusMap[posInfo.id].lastAccessTime
				// 	: undefined;
				// const logginedUserId = loginStatusMap[posInfo.id]
				// 	? loginStatusMap[posInfo.id].userId
				// 	: undefined;

				return (
					<SelectableListItem
						disabled={eachPosStatus === 'inUse'}
						onClick={() => handleClickPosInfo(posInfo)}
						selected={selected}
						key={idx}>
						<ListItemIcon>
							<DesktopOutlined
								style={{ fontSize: 36, color: selected ? theme.palette.primary.main : 'inherit' }}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{ color: 'primary', variant: 'h4' }}
							secondaryTypographyProps={{ color: 'text.secondary', variant: 'subtitle2' }}
							primary={`[${PosTypeLabel[posInfo.posType]}] ${posInfo.name}`}
							secondary={getPosSummaryText({
								posStatusDetailed: eachPosStatus,
								openDate: eachVaultcashStatus?.openInfo?.date,
								closeDate: eachVaultcashStatus?.closeInfo?.date,
							})}
						/>
					</SelectableListItem>
				);
			})}
		</List>
	);
};
