import { PosInfoType } from '@kinderlabs-pos/shared-data-type';
import { VaultCashState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, Stack } from '@mui/material';

export const SalesCloseCancelButton = ({
	posInfo,
	selectPosInfo,
}: {
	posInfo: PosInfoType;
	selectPosInfo: (posInfo?: PosInfoType) => void;
}) => {
	const customConfirm = useConfirm();
	const posCancelMutaion = VaultCashState.actions.useCancelPosClose();
	const handleCancelSalesClose = () =>
		customConfirm('영업 마감을 취소하시겠습니까?', async () => {
			posCancelMutaion.mutateAsync(
				{ posId: posInfo.id },
				{ onSuccess: () => selectPosInfo(undefined) }
			);
		});

	return (
		<Stack>
			<Button
				fullWidth
				variant='contained'
				onClick={handleCancelSalesClose}>
				{'영업 마감 취소'}
			</Button>
		</Stack>
	);
};
