import { TypographyOptions } from '@mui/material/styles/createTypography';

export const b2cTypographyWithFontFamily = (fontFamily: string): TypographyOptions => ({
	htmlFontSize: 10,
	fontFamily: fontFamily,
	h1: {
		fontWeight: 700,
		fontSize: '4rem',
		lineHeight: 1.5,
	},
	h2: {
		fontWeight: 700,
		fontSize: '2.8rem',
		lineHeight: 1.5,
	},
	h3: {
		fontSize: '2.6rem',
		lineHeight: 1.5,
	},
	h4: {
		fontSize: '2.2rem',
		lineHeight: 1.5,
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '2rem',
		lineHeight: 1.5,
	},
	subtitle2: {
		fontWeight: 400,
		fontSize: '1.8rem',
		lineHeight: 1.5,
	},
	body1: {
		fontWeight: 400,
		fontSize: '1.6rem',
		lineHeight: 1.5,
	},
});
