import { ProductInfoType } from './admin/ProductInfoType';

const ExchangeCouponStatusTypeArray = ['PUBLISHED', 'COMPLETED', 'UNUSABLE'] as const;
type ExchangeCouponStatusType = typeof ExchangeCouponStatusTypeArray[number];
export const ExchangeCouponStatusTypeLabel: Record<ExchangeCouponStatusType, string> = {
	PUBLISHED: '사용가능',
	COMPLETED: '사용완료',
	UNUSABLE: '사용불가',
};
export interface ExchangeCouponType {
	id: string;
	status: ExchangeCouponStatusType;
	name: string;
	publishInfo: {
		published: Date;
		guestVisitId?: number;
		storeId: number;
		posId: number;
		orderId: string;
		sourceType: ProductInfoType['type'];
		sourceId: number;
		userName?: string;
		userTelephone?: string;
		description: string[];
	};
	used?: Date;
	canceledInfo?: {
		canceled: Date;
		storeId: number;
		posId: number;
	};
}
