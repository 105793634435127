import { useControlledReactTable } from '@kinderlabs-pos/frameworks/table';
import { GuestMemberInfoType, OrderType } from '@kinderlabs-pos/shared-data-type';
import { GuestMemberInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

export const CurFiveGuestVisitHistoryDialog = ({
	open,
	closeDialog,
	setGuestMemberInfo,
}: {
	open: boolean;
	closeDialog: () => void;
	setGuestMemberInfo: (info: OrderType['guestMemberInfo']) => void;
}) => {
	const { id } = useAtomValue(StoreInfoState.curStoreInfo);
	const { data } = useQuery({
		...GuestMemberInfoState.keys.getRecetnFive(id),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const recentFiveInfoList = data ?? [];
	const columns = useColumns();
	const { table, TableComponent } = useControlledReactTable({
		reactTableOptions: {
			columns,
			data: recentFiveInfoList,
			enableRowSelection: true,
			enableMultiRowSelection: false,
		},
		hasCheckbox: true,
		withoutPagination: true,
	});

	const selected: OrderType['guestMemberInfo'][] = table.getSelectedRowModel().rows.map((row) => ({
		memberId: row.original.id,
		name: row.original.name,
		telephone: row.original.telephone,
	}));

	const handleMemberId = () => {
		selected.forEach((info) => {
			setGuestMemberInfo(info);
		});
		closeDialog();
	};

	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>{'최근 가입 정보 이력'}</DialogTitle>
			<DialogContent>
				<Stack sx={{ flex: 1, overflow: 'auto' }}>
					<TableComponent />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					disabled={selected.length === 0}
					onClick={handleMemberId}>
					{'확인'}
				</Button>
				<Button
					variant='outlined'
					onClick={closeDialog}>
					{'닫기'}
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const useColumns = () => {
	return useMemo<ColumnDef<GuestMemberInfoType>[]>(
		() => [
			{
				accessorKey: 'id',
				header: '순서',
				cell: ({ row }) => row.index + 1,
				size: 60,
				enableSorting: false,
			},
			{
				accessorKey: 'telephone',
				header: '휴대폰 번호',
				enableSorting: false,
			},
			{
				accessorKey: 'name',
				header: '이름',
				enableSorting: false,
			},
			{
				accessorKey: 'birthday',
				header: '생년월일',
				enableSorting: false,
			},
		],
		[]
	);
};
