import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import { ChildrenType } from '@kinderlabs-pos/shared-data-type';

//delete 도 getApi 날림
export const getDeleteChildrenAsync = (rootUrl: string) => async (childrenId: number) =>
	await (
		await axiosAppClient.get<ChildrenType>(`${rootUrl}/users/children/delete/${childrenId}`)
	).data;

export const postChildrenAsync = (rootUrl: string) => async (children: FormData) =>
	await (
		await axiosAppClient.post<ChildrenType>(`${rootUrl}/users/children`, children)
	).data;
