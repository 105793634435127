import {
	OrderDetailType,
	VaunceMemberInfoType,
	canEditPoint,
	getArrangedAppUserId,
	getPointStatus,
} from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';

import { AppUserMemberInfoSearchDialog } from '../../../member-search/Template/AppUserMemberInfoSearchDialog';

export const PostAcc = ({
	orderDetail,
	userMemberInfo,
	selectMemberInfo,
	pointEarned,
}: {
	orderDetail: OrderDetailType;
	userMemberInfo: VaunceMemberInfoType | null;
	selectMemberInfo: (memberInfo?: VaunceMemberInfoType) => void;
	pointEarned: boolean;
}) => {
	const pointStatus = getPointStatus(orderDetail);

	return (
		<Stack flex={1}>
			<Stack
				flex={1}
				spacing={1}>
				{PointStatusInfo(orderDetail).map((stack, idx) => (
					<Fragment key={idx}>{stack}</Fragment>
				))}
				{AppUserInfo(userMemberInfo).map((stack, idx) => (
					<Fragment key={idx}>{stack}</Fragment>
				))}
			</Stack>
			<Stack
				direction='row'
				justifyContent='flex-end'
				spacing={1}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<AppUserMemberInfoSearchDialog
							open={open}
							closeDialog={closeDialog}
							selectMemberInfo={selectMemberInfo}
						/>
					)}>
					{(openDialog) => (
						<Button
							startIcon={<SearchIcon />}
							variant={'outlined'}
							onClick={openDialog}
							disabled={!canEditPoint[pointStatus]}>
							<Typography variant='subtitle1'>{pointEarned ? '변경' : '검색'}</Typography>
						</Button>
					)}
				</WithDialog>
			</Stack>
		</Stack>
	);
};

const AppUserInfo = (userMemberInfo: VaunceMemberInfoType | null): JSX.Element[] =>
	userMemberInfo
		? [
				<InfoPrintStackTitle title={`회원 정보`} />,
				<InfoPrintStackRow
					value={userMemberInfo?.id}
					label={'회원 번호'}
				/>,
				<InfoPrintStackRow
					value={getArrangedAppUserId(userMemberInfo.providerType, userMemberInfo.userId)}
					label={'회원 ID'}
				/>,
				<InfoPrintStackRow
					value={userMemberInfo?.username}
					label={'회원 이름'}
				/>,
				<InfoPrintStackRow
					value={userMemberInfo?.telephone}
					label={'연락처'}
				/>,
				<InfoPrintStackRow
					value={userMemberInfo?.vaunceMemberGrade}
					label={'멤버 등급'}
				/>,
				<InfoPrintStackRow
					value={userMemberInfo?.remainingPoint}
					label={'현재 보유 포인트'}
				/>,
		  ]
		: [];

const PointStatusInfo = (orderDetail: OrderDetailType): JSX.Element[] => {
	const pointStatus = getPointStatus(orderDetail);

	return [
		<InfoPrintStackTitle title={`포인트 적립 정보`} />,
		<InfoPrintStackRow
			value={pointStatus === '적립 예정' ? '적립 예정 (주문 당일 자정)' : pointStatus}
			label={'적립 상태'}
		/>,
		<InfoPrintStackRow
			value={orderDetail?.points}
			label={'적립 가능 포인트'}
		/>,
	];
};
