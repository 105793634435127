import { styled, TextField } from "@mui/material";

export const SquareTextField = styled(TextField)(({ theme }) => ({
  padding: 0,
  "& .MuiInputBase-root": {
    borderRadius: 0,
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    borderRadius: 0,
    backgroundColor: "white",
    height: 24
  }
}))