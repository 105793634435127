import {
	Card,
	CardActionArea,
	CardActionAreaProps,
	CardProps,
	styled,
	alpha,
	Checkbox,
} from '@mui/material';
import { ReactNode } from 'react';

export type PosBoardBasePropType = {
	children?: ReactNode;
	CardProps?: CardProps;
	CardActionProps?: CardActionAreaProps;
};
export const PosBoardBaseItem = ({
	children,
	CardProps,
	CardActionProps,
}: PosBoardBasePropType) => {
	return (
		<StyledCard {...CardProps}>
			<StyledCardActionArea {...CardActionProps}>{children}</StyledCardActionArea>
		</StyledCard>
	);
};

const StyledCard = styled(Card)(({ theme }) => ({
	width: '100%',
	height: `100%`,
	color: 'white',
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme, disabled }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	'&.Mui-disabled': {
		backgroundColor: alpha(theme.palette.grey[500], 0.5),
	},
}));
