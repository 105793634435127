import { PrinterOutlined } from '@ant-design/icons';
import { ExchangeCouponPrintEventHandler, OrderState } from '@kinderlabs-pos/state';
import { List } from '@kinderlabs-pos/ui-atoms';
import { ExpandButton } from '@kinderlabs-pos/ui-components';
import { Button, Collapse, Divider, Stack, StackProps, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Fragment } from 'react';
import { PrintItemDisplay } from '../common/PrintItemDisplay';
import { StyledProgress } from '../common/StyledProgress';

export const ExchangeCouponPrintHandler = ({ StackProps }: { StackProps?: StackProps }) => {
	const couponWithStatus = useAtomValue(OrderState.printing.exchangeCoupons.printing);
	const { expanded, toggleExpandButton } = ExpandButton.useToggle(false);

	const completeLength = couponWithStatus.filter(
		(coupon) => coupon.printStatus === 'COMPLETE'
	).length;

	const isPaused = ExchangeCouponPrintEventHandler.useIsPaused();
	const pausePrinting = ExchangeCouponPrintEventHandler.usePause();
	const resumePrinting = ExchangeCouponPrintEventHandler.useResume();

	const isComplete = couponWithStatus.length > 0 && completeLength === couponWithStatus.length;
	return (
		<>
			{couponWithStatus.length > 0 && (
				<Stack {...StackProps}>
					<ExchangeCouponPrintEventHandler deviceType='POS' />
					<List
						sx={{ width: '100%' }}
						disablePadding>
						<List.ItemButton
							onClick={toggleExpandButton}
							sx={{ position: 'relative', width: '100%', justifyContent: 'space-between' }}>
							<StyledProgress
								isPaused={isPaused}
								variant='determinate'
								value={(100 * completeLength) / couponWithStatus.length}
							/>
							<Typography variant={'h4'}>
								{`교환권 출력 ${
									isComplete ? '완료' : isPaused ? '실패' : '중'
								} (${completeLength} / ${couponWithStatus.length})`}
							</Typography>
							<Stack
								spacing={1}
								direction={'row'}>
								{!isComplete &&
									(!isPaused ? (
										<Button
											color={'success'}
											variant={'outlined'}
											onClick={(e) => {
												e.stopPropagation();
												pausePrinting();
											}}>
											중지
										</Button>
									) : (
										<Button
											color={'error'}
											startIcon={<PrinterOutlined />}
											variant={'outlined'}
											onClick={(e) => {
												e.stopPropagation();
												resumePrinting();
											}}>
											재개
										</Button>
									))}
								{isComplete && (
									<Button
										color={'success'}
										sx={{ pointerEvents: 'none' }}
										variant={'outlined'}>
										출력 완료
									</Button>
								)}
								<ExpandButton
									expanded={expanded}
									onClick={(e) => {
										e.stopPropagation();
										toggleExpandButton();
									}}
								/>
							</Stack>
						</List.ItemButton>
					</List>
					<Stack sx={{ overflowY: 'auto', flex: 1, width: '100%' }}>
						<Collapse in={expanded}>
							<List
								sx={{ width: '100%', overflowY: 'auto' }}
								disablePadding>
								{couponWithStatus.map((coupon, idx) => (
									<Fragment key={idx}>
										<List.Item
											key={idx}
											sx={{ width: '100%', height: 40 }}>
											<PrintItemDisplay
												label={`${coupon.name} ( #${coupon.id} )`}
												status={coupon.printStatus}
												resumePrinting={resumePrinting}
											/>
										</List.Item>
										<Divider />
									</Fragment>
								))}
							</List>
						</Collapse>
					</Stack>
				</Stack>
			)}
		</>
	);
};
