import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { whitespace } from '@kinderlabs-pos/shared-util';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import {
	FormControlLabel,
	InputLabel,
	NativeSelect,
	Stack,
	TextField,
	Typography,
	formLabelClasses,
} from '@mui/material';
import { FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { LoginTitleBox } from '../../components/LoginTitleBox';
import VaunceButton from '../../components/VaunceButton';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { AddressMap } from '../../dummyData';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { LoginPageHeader } from '../Login/LoginPageHeader';
import { useSearchParams } from 'react-router-dom';

export interface ISignUpForthStepProps {}
export const SignUpForthStep: React.FC<ISignUpForthStepProps> = ({}) => {
	const [searchParams] = useSearchParams();

	const [snsMode, setSnsMode] = useState<boolean>(false);
	const { useSignupInfoFirstStepFormik } = vaunceAppFormiks();
	const formik = useSignupInfoFirstStepFormik();
	const {
		values,
		handleChange,
		handleSubmit,
		handleBlur,
		setFieldValue,
		errors,
		touched,
		isSubmitting,
		isValid,
	} = formik;

	const [check, setCheck] = useState<boolean>(false);
	const sns = searchParams.get('provider');

	useEffect(() => {
		const sns = searchParams.get('provider');
		if (sns) {
			localStorage.setItem('provider', 'sns');
			setSnsMode(true);
		}
		const snsToken = searchParams.get('accessToken');
		if (snsToken) {
			localStorage.setItem('accessToken', snsToken);
		}
	}, []);

	return (
		<Stack height={'100%'}>
			<LoginPageHeader disableBack={true} />
			<LoginTitleBox
				title='기본 정보'
				subtitle='필수 입력 사항'
				showStep
				stepNum={3}
			/>
			<Stack
				spacing={2}
				overflow={'auto'}>
				<FormikProvider value={formik}>
					<TextField
						InputProps={{
							style: { fontSize: '16px', fontWeight: 500 },
						}}
						value={values.name != 'signup' ? values.name : ''}
						name='name'
						onChange={handleChange}
						onBlur={handleBlur}
						// inputProps={{ readOnly: values.name != 'signup' ? true : false }}
						sx={{ fontSize: '16px' }}
						fullWidth
						label={
							<Typography
								variant='h6'
								sx={{ fontWeight: 500 }}>
								이름
							</Typography>
						}
						variant='standard'
						helperText={
							<Typography
								color={'primary'}
								sx={{ fontWeight: 500 }}>
								{(touched.name && errors.name) || whitespace}
							</Typography>
						}
					/>

					<TextField
						InputProps={{
							style: { fontSize: '16px', fontWeight: 500 },
						}}
						value={values.phone}
						name='phone'
						onChange={handleChange}
						inputProps={{ readOnly: true }}
						sx={{ fontSize: '16px', backgroundColor: '#F4F4F4' }}
						fullWidth
						label={
							<Typography
								variant='h6'
								sx={{ fontWeight: 500 }}>
								휴대폰번호
							</Typography>
						}
						variant='standard'
					/>
					<Stack
						direction={'row'}
						width={'100%'}
						spacing={2}
						alignItems={'center'}>
						<TextField
							InputProps={{
								style: { fontSize: '16px', fontWeight: 500 },
							}}
							name='emailFirst'
							onChange={handleChange}
							onBlur={handleBlur}
							sx={{ fontSize: '16px' }}
							fullWidth
							label={<Typography variant='h6'>이메일</Typography>}
							variant='standard'
							helperText={
								<Typography
									color={'primary'}
									sx={{ fontWeight: 500 }}>
									{(touched.emailFirst && errors.emailFirst) || whitespace}
								</Typography>
							}
						/>

						<Typography
							variant='h6'
							fontWeight={500}>
							@
						</Typography>
						{values.emailSecond !== 'direct' ? (
							<NativeSelect
								style={{ width: '100%', marginRight: '10px' }}
								sx={{ fontSize: '16px', fontWeight: 500 }}
								name='emailSecond'
								value={values.emailSecond}
								onChange={handleChange}>
								<option value='naver.com'>naver.com</option>
								<option value='hanmail.net'>hanmail.net</option>
								<option value='gmail.com'>gmail.com</option>
								<option value='nate.com'>nate.com</option>
								<option value='yahoo.co.kr'>yahoo.co.kr</option>
								<option value='korea.com'>korea.com</option>
								<option value='direct'>직접입력</option>
							</NativeSelect>
						) : (
							<TextField
								name='emailSecondText'
								onChange={handleChange}
								onBlur={handleBlur}
								sx={{ fontSize: '16px' }}
								fullWidth
								InputProps={{
									style: { fontSize: '16px', fontWeight: 500 },
								}}
								label={<Typography variant='h6'>이메일</Typography>}
								variant='standard'
								placeholder='naver.com'
								helperText={
									<Typography
										color={'primary'}
										sx={{ fontWeight: 500 }}>
										{(touched.emailSecond && errors.emailSecond) || whitespace}
									</Typography>
								}
							/>
						)}
					</Stack>

					{!snsMode && (
						<>
							<FormControlLabel
								sx={{ fontSize: '16px' }}
								label={<Typography variant='h5'>입력된 이메일주소를 아이디로 사용</Typography>}
								control={
									<VaunceCheckBox
										checked={check}
										onChange={() => {
											setCheck(!check);
											if (values.emailSecond !== 'direct')
												setFieldValue('id', `${values.emailFirst}@${values.emailSecond}`);
											else setFieldValue('id', `${values.emailFirst}@${values.emailSecondText}`);
										}}
										sx={{ ml: -1 }}
									/>
								}
							/>
							<Stack
								direction={'row'}
								alignItems={'flex-end'}>
								<TextField
									InputProps={{
										style: { fontSize: '16px', fontWeight: 500 },
									}}
									name='id'
									onBlur={handleBlur}
									onChange={(event) => {
										// 중복검사를 한 이후, 다시 아이디 변경 시 중복검사 다시 하도록
										if (values.isPassIdDuplicated) {
											setFieldValue('id', event?.target.value);
											setFieldValue('isPassIdDuplicated', false);
										}
									}}
									value={values.id}
									sx={{ fontSize: '16px', flex: 2 }}
									fullWidth
									label={<Typography variant='h6'>아이디</Typography>}
									variant='standard'
									placeholder='이메일 주소 입력'
									helperText={
										<Typography
											color={'primary'}
											sx={{ fontWeight: 500 }}>
											{(touched.id && errors.id) || whitespace}
										</Typography>
									}
								/>
								<Stack sx={{ flex: 1 }}>
									<VaunceButton
										name='isPassIdDuplicated'
										onClick={async () => {
											const data = await VaunceAppApis.getDuplicatedId(values.id);
											if (data) {
												setFieldValue('isPassIdDuplicated', true);
												if (data.isDuplicated) {
													alert('이미 가입되어 있는 사용자의 ID 입니다.');
													setFieldValue('id', '');
												} else {
													alert('사용 가능한 ID 입니다.');
												}
											}
										}}>
										중복 확인
									</VaunceButton>
									<Stack
										alignItems={'center'}
										mt={1}>
										<Typography
											color={'primary'}
											sx={{ fontWeight: 500 }}>
											{errors.isPassIdDuplicated || whitespace}
										</Typography>
									</Stack>
								</Stack>
							</Stack>
							<TextField
								InputProps={{
									style: { fontSize: '16px', fontWeight: 500 },
								}}
								name='password'
								onChange={handleChange}
								onBlur={handleBlur}
								sx={{ fontSize: '16px' }}
								fullWidth
								type={'password'}
								label={<Typography variant='h6'>비밀번호</Typography>}
								variant='standard'
								placeholder='비밀번호를 입력하세요.'
								helperText={
									<Typography
										color={'primary'}
										sx={{ fontWeight: 500 }}>
										{(touched.password && errors.password) || whitespace}
									</Typography>
								}
							/>
							<TextField
								InputProps={{
									style: { fontSize: '16px', fontWeight: 500 },
								}}
								name='confirmPassword'
								onChange={handleChange}
								onBlur={handleBlur}
								sx={{ fontSize: '1.5em' }}
								fullWidth
								type={'password'}
								label={<Typography variant='h6'>비밀번호 확인</Typography>}
								variant='standard'
								placeholder='비밀번호를 입력하세요.'
								helperText={
									<Typography
										color={'primary'}
										sx={{ fontWeight: 500 }}>
										{(touched.confirmPassword && errors.confirmPassword) || whitespace}
									</Typography>
								}
							/>
						</>
					)}
					<Stack>
						<InputLabel
							variant='standard'
							sx={{ fontWeight: 300 }}>
							{'주소'}
						</InputLabel>
						<Stack
							direction={'row'}
							width={'100%'}
							spacing={2}>
							<Stack flex={1}>
								<NativeSelect
									name='addressFirst'
									onChange={handleChange}
									onBlur={handleBlur}
									sx={{ fontSize: '16px', fontWeight: 500 }}
									fullWidth>
									<option value={'unSelected'}>{'선택'}</option>
									{Object.keys(AddressMap).map((address) => (
										<option
											key={address}
											value={address}>
											{address}
										</option>
									))}
								</NativeSelect>
								<Typography
									color={'primary'}
									sx={{ fontWeight: 500 }}>
									{(touched.addressFirst && errors.addressFirst) || whitespace}
								</Typography>
							</Stack>
							<Stack flex={1}>
								<InputLabel variant='standard' />
								<NativeSelect
									name='addressSecond'
									onChange={handleChange}
									sx={{ fontSize: '16px', fontWeight: 500 }}
									fullWidth>
									<option value={'unSelected'}>{'선택'}</option>
									{AddressMap[values.addressFirst] &&
										AddressMap[values.addressFirst].map((add) => (
											<option
												key={add}
												value={add}>
												{add}
											</option>
										))}
								</NativeSelect>
								<Typography
									color={'primary'}
									sx={{ fontWeight: 500 }}>
									{(touched.addressSecond && errors.addressSecond) || whitespace}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction={'row'}
						p={1}>
						<RoundedSubmitButton
							fullWidth
							sx={{ height: 58 }}
							onClick={() => {
								if (Object.keys(errors).length > 0) {
									alert(Object.values(errors).join('\n'));
								}
								handleSubmit();
							}}
							text='다음'
							// disabled={!isValid}
							isSubmitting={isSubmitting}
						/>
					</Stack>
				</FormikProvider>
			</Stack>
		</Stack>
	);
};
