import { ColumnDef } from '@tanstack/react-table';
import { BaseInfoType } from './BaseInfoType';
import { CategoryInfoTreeType, CategorySearchSpec } from './CategorySearchSpec';
import { ProductInfoType } from './ProductInfoType';

export type CategoryInfoType = {
	id: number;
	parentId?: number;
	name: string;
	// 1 은 BASE, 2는 중분류, 3는 대분류
	level: 1 | 2 | 3;
	children: (CategoryInfoType & BaseInfoType)[];
	isDummy?: boolean;
	usageCount?: number;
};

export const CategoryLevelTypeLabel: Record<1 | 2 | 3, string> = {
	3: '대분류',
	2: '중분류',
	1: '소분류',
};

export type CategoryGroupSetInfoType = {
	id: number;
	name: string;
	description: string;
	categoryGroups: CategoryGroupType[];
};

export type CategoryGroupType = {
	name: string;
	categoryList: string[];
};

export type CategoryDataForTable = {
	baseCategory: {
		id: number;
		name: string;
	};
	superCategory: {
		id: number;
		name: string;
	};
	topCategory: {
		id: number;
		name: string;
	};
	categoryGroupKey: string;
};

export type ProductInfoWithCategory = ProductInfoType &
	BaseInfoType & { categoryData: CategoryDataForTable };

export const getCategoryDataForTable = ({
	categoryTree,
	baseCategoryId,
	baseCategoryName,
}: {
	categoryTree: CategoryInfoTreeType;
	baseCategoryId?: number;
	baseCategoryName?: string;
}): CategoryDataForTable => {
	const baseCategory = baseCategoryId
		? categoryTree.BASE[baseCategoryId]?.baseCategoryInfo
		: undefined;
	const topCategory = baseCategoryId
		? categoryTree.BASE[baseCategoryId]?.topCategoryInfo
		: undefined;
	const superCategory = baseCategoryId
		? categoryTree.BASE[baseCategoryId]?.superCategoryInfo
		: undefined;

	return {
		baseCategory: {
			name: baseCategoryName ?? baseCategory?.name ?? '기타 소분류',
			id: baseCategoryId ?? Number.MAX_SAFE_INTEGER,
		},
		superCategory: {
			name: superCategory?.name ?? '기타 중분류',
			id: superCategory?.id ?? Number.MAX_SAFE_INTEGER,
		},
		topCategory: {
			name: topCategory?.name ?? '기타 대분류',
			id: topCategory?.id ?? Number.MAX_SAFE_INTEGER,
		},
		categoryGroupKey: [
			topCategory?.id ?? Number.MAX_SAFE_INTEGER,
			superCategory?.id ?? Number.MAX_SAFE_INTEGER,
			baseCategoryId ?? Number.MAX_SAFE_INTEGER,
		].join('::'),
	};
};

const getCategorySortKey = ({
	topCategoryId,
	superCategoryId,
	baseCategoryId,
}: {
	topCategoryId?: number;
	superCategoryId?: number;
	baseCategoryId?: number;
}) =>
	[
		topCategoryId ?? Number.MAX_SAFE_INTEGER,
		superCategoryId ?? Number.MAX_SAFE_INTEGER,
		baseCategoryId ?? Number.MAX_SAFE_INTEGER,
	].join('::');
/**
 * 일부 매출 통계 등에 활용
 */
export const getCategoryReactTableColumnDefs = <
	T extends { categoryData?: CategoryDataForTable }
>(): ColumnDef<T>[] => {
	return [
		{
			id: 'topCategory',
			accessorKey: 'categoryData.topCategory.name',
			cell: (info) => {
				return info.row.getCanExpand()
					? info.row.getLeafRows()[0].original.categoryData?.topCategory.name ?? ''
					: info.row.original.categoryData?.topCategory.name ?? '';
			},
			enableSorting: false,
			header: '대분류',
			size: 130,
		},
		{
			id: 'superCategory',
			accessorKey: 'categoryData.superCategory.name',
			cell: (info) => {
				return info.row.getCanExpand()
					? info.row.getLeafRows()[0].original.categoryData?.superCategory.name ?? ''
					: info.row.original.categoryData?.superCategory.name ?? '';
			},
			enableSorting: false,
			header: '중분류',
			size: 130,
		},
		{
			id: 'baseCategory',
			accessorKey: 'categoryData.baseCategory.name',
			cell: (info) => {
				return info.row.getCanExpand()
					? info.row.getLeafRows()[0].original.categoryData?.baseCategory.name ?? ''
					: info.row.original.categoryData?.baseCategory.name ?? '';
			},
			header: '소분류',
			size: 130,
			sortingFn: (rowA, rowB, columnId) => {
				const colACategory = rowA.getCanExpand()
					? rowA.getLeafRows()[0].original.categoryData?.categoryGroupKey
					: rowA.original.categoryData?.categoryGroupKey;
				const colBCategory = rowB.getCanExpand()
					? rowB.getLeafRows()[0].original.categoryData?.categoryGroupKey
					: rowB.original.categoryData?.categoryGroupKey;

				return (colACategory ?? '') > (colBCategory ?? '') ? 1 : -1;
			},
		},
	];
};

// 아래 함수랑 중복 코드인데 id 가 아니라 productId 컬럼 사용함
export const get대중소분류상품TreeColumnDefs = <
	T extends ({ productId: number } | { id: number }) & ({ name: string } | { productName: string })
>({
	productCategoryMap,
	productIdColumn,
	productNameColumn,
}: {
	productCategoryMap: Record<string, ProductInfoWithCategory>;
	productIdColumn: 'productId' | 'id';
	productNameColumn: 'productName' | 'name';
}): ColumnDef<T>[] => {
	//@ts-ignore
	const getProductId = (row: T): number => row[productIdColumn];
	//@ts-ignore
	const getProductName = (row: T): number => row[productNameColumn];

	return [
		{
			id: 'topCategory',
			accessorFn: (row) =>
				productCategoryMap[getProductId(row)].categoryData.topCategory.name ?? '그룹 없음',
			header: '대분류',
			enableSorting: false,
			size: 120,
		},
		{
			id: 'superCategory',
			accessorFn: (row) =>
				productCategoryMap[getProductId(row)].categoryData.superCategory.name ?? '그룹 없음',
			header: '중분류',
			enableSorting: false,
			size: 120,
		},
		{
			id: 'baseCategory',
			accessorFn: (row) =>
				productCategoryMap[getProductId(row)].categoryData.baseCategory.name ?? '그룹 없음',
			header: '소분류',
			size: 120,
			sortingFn: (rowA, rowB) => {
				// 대중소 카테고리로 분류
				const colASortKey = getProductId(rowA.original)
					? productCategoryMap[getProductId(rowA.original)].categoryData.categoryGroupKey
					: undefined;
				const colBSortKey = getProductId(rowB.original)
					? productCategoryMap[getProductId(rowB.original)].categoryData.categoryGroupKey
					: undefined;

				if (colASortKey && colBSortKey) return colASortKey > colBSortKey ? 1 : -1;
				return 0;
			},
		},
		{
			id: productNameColumn,
			accessorKey: productNameColumn,
			header: '품목명',
			size: 180,
			sortingFn: (rowA, rowB) => {
				// 대중소 카테고리로 분류
				const colASortKey = getProductId(rowA.original)
					? productCategoryMap[getProductId(rowA.original)].categoryData.categoryGroupKey
					: undefined;
				const colBSortKey = getProductId(rowB.original)
					? productCategoryMap[getProductId(rowB.original)].categoryData.categoryGroupKey
					: undefined;

				return `${colASortKey}${getProductName(rowA.original)}` >
					`${colBSortKey}${getProductName(rowB.original)}`
					? 1
					: -1;
			},
		},
		{
			id: productIdColumn,
			accessorKey: productIdColumn,
			header: '상품코드',
			size: 120,
			accessorFn: (row) => `${getProductId(row)}`,
		},
	];
};
