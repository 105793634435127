import { isSnsMode } from '@kinderlabs-pos/shared-util';
import { VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useEffect, useRef } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import { AddressPart } from './AddressPart';
import { ChildEnrollPart } from './ChildEnrollPart';
import { ExtraInfoPart } from './ExtraInfoPart';
import { PassWordPart } from './PasswordPart';
import { ProfileImgPart } from './ProfileImgPart';

export interface IInfoPageProps {}
const InfoPage: React.FC<IInfoPageProps> = ({}) => {
	const { navigateLeave } = useVaunceAppRouter();

	const childRenRef = useRef<HTMLDivElement>(null);
	const clickedChildRegister = useAtomValue(VaunceState.goChildRegisterPage);

	const scroll = () => {
		childRenRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		if (clickedChildRegister) scroll();
	}, []);

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='회원정보' />
			<Box
				overflow={'scroll'}
				height={'100%'}>
				<Stack
					sx={{ textAlign: 'center' }}
					spacing={4}>
					{/* 프로필 사진 & 이메일 & 이름 */}
					<ProfileImgPart />

					{/* 비밀번호 */}
					{!isSnsMode() && <PassWordPart />}

					{/* 주소 */}
					<AddressPart />

					{/* 자녀등록 */}
					<Typography
						variant='h1'
						ref={childRenRef}>
						자녀등록
					</Typography>
					<ChildEnrollPart />

					{/* 추가정보 */}
					<Typography variant='h1'>추가정보</Typography>
					<ExtraInfoPart />

					{/* 회원탈퇴 */}
				</Stack>
				<RoundedButton
					sx={{
						mt: 10,
						mb: 2,
						width: '100%',
						height: 50,
						backgroundColor: '#FFF',
						borderColor: '#C8D1D3',
						color: '#334856',
					}}
					onClick={() => {
						navigateLeave();
					}}>
					회원 탈퇴
				</RoundedButton>
			</Box>
		</Stack>
	);
};

export default InfoPage;
