import { grey } from '@mui/material/colors';
import { ProductItemPropsType } from '.';

export const ProductOptionItemProps: ProductItemPropsType = {
	CartListItemProp: {
		size: 'small',
		color: grey[100],
	},
	nameTypographyProps: {
		variant: 'body1',
	},
	subNameTypographyProps: {
		variant: 'body2',
		color: 'primary',
	},
	quantityTypographyProps: {
		variant: 'subtitle2',
		display: 'none',
	},
	quantityChangeTypographyProps: {
		variant: 'body2',
		display: 'none',
	},
	priceTypographyProps: {
		variant: 'subtitle2',
	},
	unitPriceTypographyProps: {
		variant: 'body2',
		color: 'primary',
	},
};
