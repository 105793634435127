import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, Typography } from '@mui/material';

import lockImage from 'assets/vaunce/image/shield_lock.png';
import { LoginPageHeader } from '../Login/LoginPageHeader';

import { DanalApis } from '@kinderlabs-pos/apis/vaunce';
import { useEffect, useRef, useState } from 'react';
import { LoginTitleBox } from '../../components/LoginTitleBox';
import { isSnsMode } from '@kinderlabs-pos/shared-util';

export interface ISignUpThirdStepProps {}
export const SignUpThirdStep: React.FC<ISignUpThirdStepProps> = ({}) => {
	const [transactionId, setTransactionId] = useState<string | null>(null);

	const ref = useRef<any>();

	const handle인증요청하기 = () => {
		if (isSnsMode()) {
			DanalApis.verifysnsIdentity().then((data) => {
				setTransactionId(data.data.TID);
			});
		} else {
			DanalApis.verifyIdentity().then((data) => {
				setTransactionId(data.data.TID);
			});
		}
	};

	useEffect(() => {
		if (transactionId && ref.current) ref.current.submit();
	}, [ref, transactionId]);

	return (
		<Stack height={'100%'}>
			<LoginPageHeader />
			<LoginTitleBox
				title='본인 인증'
				showStep
				stepNum={2}
			/>
			<Stack
				textAlign={'center'}
				height={'100%'}
				justifyContent={'center'}>
				<Box>
					<img
						src={lockImage}
						width={74}
					/>
				</Box>

				<Typography variant='h5'>
					본인명의 휴대폰 본인인증 시 제공되는 정보는 해당 인증기관에서 직접 수집하며, 인증 이외의
					용도로 이용, 저장하지 않습니다.
				</Typography>
			</Stack>

			<form
				ref={ref}
				name='Ready'
				action='https://wauth.teledit.com/Danal/WebAuth/Mobile/Start.php'
				method='post'>
				<input
					type='hidden'
					name='TID'
					value={`${transactionId}`}
				/>
				<input
					type='hidden'
					name='IsCharSet'
					value='UTF-8'
				/>
				<input
					type='hidden'
					name='ByBuffer'
					value='This value bypass to CPCGI Page'
				/>
				<input
					type='hidden'
					name='ByAnyName'
					value='AnyValue'
				/>
				<input
					type='hidden'
					name='BgColor'
					value='00'
				/>
			</form>
			<RoundedButton
				onClick={handle인증요청하기}
				sx={{ height: 58, mb: 8 }}>
				<Typography variant='h4'> 인증 요청하기</Typography>
			</RoundedButton>
		</Stack>
	);
};
