import { PaymentInfoType, ReceiptInfoType } from '@kinderlabs-pos/shared-data-type';

export type PaymentReducerActionType =
	| { type: 'SET'; paymentInfos: PaymentInfoType[] }
	| { type: 'SUBMIT'; paymentInfo: PaymentInfoType }
	| { type: 'REFUND'; refundedPaymentInfo: PaymentInfoType }
	// 현금 영수증 추가
	| { type: 'ADD_CASH_RECEIPT'; paymentId: string; receipt: ReceiptInfoType }
	// 현금 영수증 취소
	| { type: 'CANCEL_CASH_RECEIPT'; paymentId: string };

export const paymentReducer = (
	prev: PaymentInfoType[],
	action: PaymentReducerActionType
): PaymentInfoType[] => {
	switch (action.type) {
		case 'SET':
			return action.paymentInfos;
		case 'SUBMIT':
			return [...prev, action.paymentInfo];
		case 'REFUND':
			return prev.map((payment) =>
				payment.id === action.refundedPaymentInfo.id ? action.refundedPaymentInfo : payment
			);
		case 'ADD_CASH_RECEIPT':
			return prev.map((payment) =>
				payment.id === action.paymentId ? { ...payment, receipt: action.receipt } : payment
			);
		case 'CANCEL_CASH_RECEIPT':
			return prev.map((payment) => {
				if (payment.id === action.paymentId) {
					const { receipt, ...otherPayment } = payment;
					return otherPayment;
				} else {
					return payment;
				}
			});
	}
};
