import { DayCalendarFilterOptionType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { OrderQueryState } from '@kinderlabs-pos/state';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

type 결제민감정보요약Type = { label: string; value: number };
export const PaymentSummary = {
	usePosColumns: () =>
		useMemo<ColumnDef<결제민감정보요약Type>[]>(
			() => [
				{
					accessorKey: 'label',
					header: '종류',
					size: 120,
					aggregatedFn: null,
				},
				{
					accessorKey: 'value',
					cell: ({ getValue }) => `${getValue() as number} 건`,
					header: '건 수',
					size: 240,
					aggregatedFn: null,
				},
			],
			[]
		),

	// JIN: POS 에서만 사용한다.
	use결제민감정보들: ({
		storeIdList,
		posIdList,
		calendarOption,
	}: {
		storeIdList?: number[];
		posIdList?: number[];
		calendarOption: DayCalendarFilterOptionType;
	}): 결제민감정보요약Type[] => {
		const { from, to } = calendarOption;

		const { data } = useInfiniteQuery({
			...OrderQueryState.keys.infinite({
				params: {
					storeIdList,
					posIdList,
					from,
					to,
					status: 'CANCELED',
				},
				pageable: Pageable.getPageable({ page: 0, size: 2000 }),
			}),
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
			},
			refetchOnWindowFocus: true,
		});

		return [
			{
				label: '주문 취소 건수',
				value: data?.pages[0].content.length ?? 0,
			},
		];
	},
};
