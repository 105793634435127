import { Box, FormControl, styled, TextField } from '@mui/material';

export const CouponContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(3),
	boxShadow: '0 0 12px rgb(0 0 0 / 16%)',
	borderRadius: theme.spacing(1.5),
	marginBottom: theme.spacing(1),
}));

export const ImageBox = styled(Box)(({ theme }) => ({
	marginRight: theme.spacing(3),
	// position: 'relative',
}));

export const CouponDescBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
}));

export const CouponSubDescBox = styled(Box)(({ theme }) => ({
	display: 'flex',
}));

export const MemberInfoBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	marginBottom: '1rem',
}));

export const ProfileImageBox = styled(Box)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundPosition: '50% 50%',
	borderRadius: '50%',
	width: theme.spacing(9.25),
	height: theme.spacing(9.25),
	marginBottom: '0.5rem',
}));

export const StyleTextField = styled(TextField)(({ theme }) => ({
	// marginBottom: '3rem',
	fontSize: '1.3em',
}));

export const StyleFormControl = styled(FormControl)(({ theme }) => ({
	// margin: '0 1rem 3rem 0',
	fontSize: '1.3em',
}));

export const StyledRefundBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}));

export const TicketRefundBox = styled(Box)(({ theme }) => ({}));
