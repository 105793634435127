const sendPushTokenInApp = (access_token: string) => {
	// ios push token
	try {
		// @ts-ignore
		if (webkit?.messageHandlers?.newLoginAccessToken) {
			console.log('ios앱 토큰 갱신 시도');
			// @ts-ignore
			webkit.messageHandlers.newLoginAccessToken.postMessage(access_token);

			console.log('ios앱 토큰 갱신 성공');
			return;
		}
	} catch (e) {
		// console.log('앱에서 실행이 아니면 에러가 나는게 맞습니다.');
	}

	// android push token
	try {
		// @ts-ignore
		if (window.vaunce) {
			console.log('android 앱 토큰 갱신 시도');
			// @ts-ignore
			window.vaunce.newLoginAccessToken(access_token);
			console.log('android 앱 토큰 갱신 성공');
			return;
		}
	} catch (e) {
		// console.log('앱에서 실행이 아니면 에러가 나는게 맞습니다.');
	}

	console.log('앱이 아니라서 토큰 갱신 실패');
};

export const VaunceWebAppApis = {
	sendPushTokenInApp,
};
