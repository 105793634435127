import {
	BarChartOutlined,
	CreditCardOutlined,
	GiftOutlined,
	PrinterOutlined,
} from '@ant-design/icons';
import { CardStack, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Box, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { Suspense, useMemo, useState } from 'react';
import { SalesAndGuestVisitsInPos } from './SalesAndGuestVisitsInPos';
import { CalendarSelect, FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { PosAndKioskSelector } from '@kinderlabs-pos/frameworks/table';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { DayCalendarFilterOptionType, SalesDeviceInfoType } from '@kinderlabs-pos/shared-data-type';
import { ProductStatisticsInPos } from './ProductStatisticsInPos';
import { PrintStatisticsButtonLayer } from './PrintStatisticsButtonLayer';
import { PaymentMethodStatisticsInPos } from './PaymentMethodStatisticsInPos';

const tabData = [
	{
		label: '개요/카드사별 매출',
		icon: <BarChartOutlined />,
	},
	{
		label: '결제수단별 매출',
		icon: <CreditCardOutlined />,
	},
	{
		label: '상품별 매출',
		icon: <GiftOutlined />,
	},
	{
		label: '통계 출력',
		icon: <PrinterOutlined />,
	},
];

export const StatisticsInPosPage = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const handleChange = (event: any, newTabIndex: number) => {
		setTabIndex(newTabIndex);
	};

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const today = useMemo(() => dayjs(), []);

	const [selectedPosIdList, setSelectedPosIdList] = useState<number[] | undefined>(undefined);

	const { posInfoList, kioskInfoList } = useAtomValue(StoreInfoState.curStoreInfo);
	const deviceList = useMemo<SalesDeviceInfoType[]>(
		() => [...posInfoList, ...kioskInfoList],
		[posInfoList, kioskInfoList]
	);

	const selectedPosInfoList = selectedPosIdList
		? deviceList.filter((device) => selectedPosIdList.includes(device.id))
		: undefined;

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.hour(0).minute(0),
		to: today.endOf('day'),
	});

	return (
		<Stack
			direction={'row'}
			height={'100%'}
			spacing={1}>
			<CardStack
				height={'100%'}
				flex={1}>
				<StyledTabs
					orientation='vertical'
					value={tabIndex}
					onChange={handleChange}>
					{tabData.map((tab, idx) => (
						<Tab
							sx={{ marginTop: 1 }}
							label={
								<Stack
									direction={'row'}
									width={'100%'}
									sx={{ color: '#5b5a5a' }}>
									{tab.icon}
									<Typography
										variant='h5'
										paddingLeft={1}>
										{tab.label}
									</Typography>
								</Stack>
							}
						/>
					))}
				</StyledTabs>
			</CardStack>
			<Stack
				flex={4}
				spacing={1}
				height={'100%'}
				overflow={'auto'}>
				<CardStack
					spacing={1}
					py={2}
					height={60}
					direction={'row'}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<Box
						minWidth={180}
						flex={1}>
						<Suspense fallback={<FullSizeCircularProgress />}>
							<PosAndKioskSelector
								setPosAndKioskIdList={(value) => setSelectedPosIdList(value)}
								posAndKioskIdList={selectedPosIdList}
								storeId={storeId}
							/>
						</Suspense>
					</Box>
				</CardStack>
				<Suspense>
					{tabIndex === 0 && (
						<SalesAndGuestVisitsInPos
							selectedDeviceInfoList={selectedPosInfoList}
							option={option}
						/>
					)}
					{tabIndex === 1 && (
						<PaymentMethodStatisticsInPos
							selectedDeviceInfoList={selectedPosInfoList}
							option={option}
						/>
					)}
					{tabIndex === 2 && (
						<ProductStatisticsInPos
							selectedDeviceInfoList={selectedPosInfoList}
							option={option}
						/>
					)}
					{tabIndex === 3 && (
						<PrintStatisticsButtonLayer
							selectedDeviceInfoList={selectedPosInfoList}
							option={option}
						/>
					)}
				</Suspense>
			</Stack>
		</Stack>
	);
};

const StyledTabs = styled(Tabs)((theme) => ({
	borderRight: 1,
	'& .MuiTabs-indicator': {
		width: '100%',
		opacity: 0.15,
		backgroundColor: 'black',
	},
}));
