import * as Yup from 'yup';

export enum ValidErrorsMsg {
	// 공통
	REQUIRED = '필수입니다',

	//이름
	NAMEREQUIRED = '이름은 필수입니다',

	// 생일
	BIRTHREQUIRED = '생년월일을 입력해주세요',
	BIRTHMAX = '생년월일은 8자를 넘을 수 없습니다.',
	BIRTHMATCH = '생년월일 형식이 맞지 않습니다',

	// 센터
	CENTERREQUIRED = '센터를 선택해주세요',

	// 예약 > 상품
	GOODSREQUIRED = '상품의 종류를 선택해주세요',

	// 전화번호
	PHONEREQUIRED = '전화번호는 필수입니다',
	PHONEMAX = '11자리를 넘을 수 없습니다',
	PHONEMATCH = '앞자리를 다시한번 확인해 주세요',

	// 예약
	NUMBEROFUSERREQUIRED = '사용 인원을 입력해 주세요',
	STARTDATE = '오늘보다 이전 날짜는 선택할 수 없습니다',
	ENDDATE = '마지막 날짜는 시작날짜 보다 뒤로 선택해주세요',

	COUPONREQUIRED = '쿠폰번호를 입력해주세요',
}

export const requiredStringYup = (message: string) =>
	Yup.string().min(1).max(255).required(message);

export const nameYup = Yup.string().required(ValidErrorsMsg.NAMEREQUIRED);

export const birthdayYup = Yup.string()
	.max(8, ValidErrorsMsg.BIRTHMAX)
	.required(ValidErrorsMsg.BIRTHREQUIRED)
	.matches(
		/^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
		ValidErrorsMsg.BIRTHMATCH
	);

export const genderYup = Yup.string().oneOf(['M', 'F']).required('성별을 선택해주세요');

export const relationYup = Yup.string().oneOf(['1', '2']).required('관계를 선택해주세요');

export const telephoneYup = Yup.string()
	.max(11, ValidErrorsMsg.PHONEMAX)
	.matches(/^[0-1][0-9]+$/, ValidErrorsMsg.PHONEMATCH)
	.required(ValidErrorsMsg.PHONEREQUIRED);

export const centerYup = Yup.number().required(ValidErrorsMsg.CENTERREQUIRED);

export const couponYup = Yup.string().required(ValidErrorsMsg.COUPONREQUIRED);
