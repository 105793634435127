import { Paper, Stack } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { pageLouteLog } from '../utils/TechLabsLogUtils';
export interface ISignUpContentProps {}
export const SignUpContent: React.FC<ISignUpContentProps> = ({}) => {
	const location = useLocation();
	pageLouteLog(location.pathname);

	return (
		<Paper
			sx={{
				bgcolor: '#fff',
				height: '100%',
				paddingTop: ['calc(env(safe-area-inset-top))', 'calc(constant(safe-area-inset-top))'],
				paddingBottom: [
					'calc(env(safe-area-inset-bottom))',
					'calc(constant(safe-area-inset-bottom))',
				],
			}}>
			<Stack
				height={'100%'}
				sx={{ p: 3 }}>
				<Outlet />
			</Stack>
		</Paper>
	);
};
