import { DdiziType } from "@kinderlabs-pos/shared-data-type";
import { DdiziState } from "@kinderlabs-pos/state";
import { useConfirm } from "@kinderlabs-pos/ui-components";
import { TicketEnterButton } from '../../modules/tickets/components/buttons/TicketEnterButton';
import { TicketEnterCancelButton } from '../../modules/tickets/components/buttons/TicketEnterCancelButton';
import { TicketExitButton } from '../../modules/tickets/components/buttons/TicketExitButton';
import { TicketExitCancelButton } from '../../modules/tickets/components/buttons/TicketExitCancelButton';

export interface IDdiziEnterExitButtonsProps {
  ddizi: DdiziType,
}
export const DdiziEnterExitButtons: React.FC<IDdiziEnterExitButtonsProps> = ({
  ddizi,
}) => {
  const confirm = useConfirm();
  const enterExit = DdiziState.actions.useEnterExit();

  const handleEnter: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    confirm("지금 시점부터 입장 처리 하시겠습니까?", () => enterExit.mutate({ ddiziId: ddizi.id, type: "ENTER" }))
  }

  const handleExit: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    confirm("추가 요금 없이 퇴장 처리 하시겠습니까?", () => enterExit.mutate({ ddiziId: ddizi.id, type: "EXIT" }))
  }

  const handleCancelEnter: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    confirm("입장 처리를 취소하시겠습니까?", () => enterExit.mutate({ ddiziId: ddizi.id, type: "CANCEL_ENTER" }))
  }

  const handleCancelExit: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    confirm("퇴장 처리를 취소하시겠습니까?", () => enterExit.mutate({ ddiziId: ddizi.id, type: "CANCEL_EXIT" }))
  }

  return (
    <>
      {ddizi.status === "PUBLISHED"
        ? <TicketEnterButton onClick={handleEnter} />
        : <TicketEnterCancelButton onClick={handleCancelEnter} disabled={ddizi.status == "EXITED"} />
      }
      {ddizi.status === "EXITED"
        ? <TicketExitCancelButton onClick={handleCancelExit} />
        : <TicketExitButton onClick={handleExit} disabled={ddizi.status == "PUBLISHED"} />
      }
    </>
  )
};