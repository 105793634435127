import {
	CartLineInfoType,
	CartLineOnlineTicketOnoffmixInfoType,
	defaultProductAdditionalInfo,
	OnlineTicketType,
} from '@kinderlabs-pos/shared-data-type';

export type CartLineOnlineTicketOnoffmixActionType =
	| {
			type: 'ADD';
			cartLineId: string;
			onlineTicketInfo: OnlineTicketType;
			productInfoId?: number;
	  }
	| {
			type: 'REMOVE';
			onoffmixTicketId: string;
	  };

const cartLineOnlineTicketReducer = (
	prev: CartLineInfoType[],
	action: CartLineOnlineTicketOnoffmixActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD':
			// 말도 안 되는 경우에 대한 방어처리 추가
			const hasOnlineTicketAlready = prev.some(
				(cl) =>
					cl.type === 'ONLINE_TICKET_ONOFFMIX' &&
					(cl as CartLineOnlineTicketOnoffmixInfoType).ticketUsageInfo.ticketUsageId ===
						action.onlineTicketInfo.externalTicketId
			);

			if (hasOnlineTicketAlready) return prev;
			else
				return [
					...prev,
					createOnlineTicketCartLine({
						cartLineId: action.cartLineId,
						onlineTicketInfo: action.onlineTicketInfo,
						productInfoId: action.productInfoId,
					}),
				];
		case 'REMOVE':
			return prev.filter(
				(cl) =>
					cl.type !== 'ONLINE_TICKET_ONOFFMIX' ||
					(cl as CartLineOnlineTicketOnoffmixInfoType).ticketUsageInfo.ticketUsageId !==
						action.onoffmixTicketId
			);
	}
};

export const CartLineOnlineTicketState = {
	reducer: cartLineOnlineTicketReducer,
};

const createOnlineTicketCartLine = ({
	cartLineId,
	onlineTicketInfo,
	productInfoId,
}: {
	cartLineId: string;
	onlineTicketInfo: OnlineTicketType;
	productInfoId?: number;
}): CartLineOnlineTicketOnoffmixInfoType => {
	return {
		id: cartLineId,
		type: 'ONLINE_TICKET_ONOFFMIX',
		price: 0,
		name: onlineTicketInfo.ticketName,
		productInfoId,
		productInfo: {
			category: '온라인티켓',
			isForKitchen: false,
			ddiziInfo: onlineTicketInfo.ddiziInfo,
		},
		productDiscountInfo: undefined,
		productAdditionalInfo: defaultProductAdditionalInfo,
		targetCartLineId: undefined,
		ticketUsageInfo: {
			ticketUsageId: onlineTicketInfo.externalTicketId,
			name: onlineTicketInfo.ticketName,
			userName: onlineTicketInfo.user?.name ?? '',
			userTelephone: onlineTicketInfo.user?.telephone ?? '',
		},
		quantity: 1,
	};
};
