import { PosState } from "@kinderlabs-pos/state";
import { IconTextButton } from "@kinderlabs-pos/ui-atoms";
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import { Typography } from "@mui/material";
import { useAtom } from "jotai";

export interface IReceiptToggleButtonProps { }
export const ReceiptToggleButton: React.FC<IReceiptToggleButtonProps> = ({ }) => {
  const [posSettings, setPosSettings] = useAtom(PosState.settings)

  return (
    <IconTextButton
      variant='white'
      onClick={() => setPosSettings(state => ({ ...state, autoReceiptPrintOn: !state.autoReceiptPrintOn }))}
      icon={
        posSettings.autoReceiptPrintOn
          ? <PrintIcon fontSize="small" />
          : <PrintDisabledIcon fontSize="small"
            sx={theme => ({
              fill: `${theme.palette.grey[400]} !important`
            })} />}
      label={<Typography>
        {"자동 출력"}
      </Typography>}
    />
  )
};