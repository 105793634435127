import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { alpha, Components, Theme } from '@mui/material';
export const waiverComponentsOverride: Components<Theme> = {
	MuiOutlinedInput: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				'& fieldset': {
					borderWidth: 3,
					borderRadius: 10,
				},
				'&.Mui-focused': {
					'& > fieldset.MuiOutlinedInput-notchedOutline': {
						borderWidth: 3.5,
						borderRadius: 10,
						borderColor: theme.palette.primary.main,
					},
					color: theme.palette.primary.main,
				},
				'&:hover': {
					'& > fieldset.MuiOutlinedInput-notchedOutline': {
						borderWidth: 3.5,
						borderRadius: 10,
						borderColor: theme.palette.primary.main,
					},
					color: theme.palette.primary.main,
				},
				'&.Mui-disabled': {
					backgroundColor: vaunceColors.paleGray,
				},
			}),
		},
	},
};
