import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, CmtInfoType } from '@kinderlabs-pos/shared-data-type';

export const getCmtInfoDeatilAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<CmtInfoType & BaseInfoType>(`${rootUrl}/cmt`, {
			params: {
				id: id,
			},
		})
	).data;
