import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ConnectInfoType, DeviceType } from '@kinderlabs-pos/shared-data-type';

export const getConnectInfoListByType =
	(rootUrl: string) =>
	async ({ storeId, deviceType }: RequestType) =>
		(
			await posAxiosClient.get<ConnectInfoType[]>(
				`${rootUrl}/connect/store/${storeId}/type/${deviceType}`,
				{}
			)
		).data;

type RequestType = {
	storeId: number;
	deviceType: DeviceType;
};
