import axios from 'axios';
import { setAxiosRequestTokenInterceptor } from '../common/setAxiosRequestTokenInterceptor';

type AppResponseEntityType<T> = {
	header: {
		code: number;
		message: string;
	};
	body: T;
};

// app Client 는 response 가 AppResponseEntityType 를 활용한다.
export const axiosAppClient = axios.create();

setAxiosRequestTokenInterceptor(axiosAppClient);

axiosAppClient.interceptors.response.use(
	(response) => {
		if ((response.data as AppResponseEntityType<any>).header.code === 200) {
			response.data = response.data.body;
		}
		return response;
	},
	(error) => {
		console.log(error, error.response.data);
		return Promise.reject(error.response.data);
	}
);
