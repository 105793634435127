import { OrderTypeWithDdizisAndExchanges } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getGroupBy, getTimeFormat } from '@kinderlabs-pos/shared-util';
import { ExitQueryState, OrderState } from '@kinderlabs-pos/state';
import { List, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { ExitPage } from '.';

export const ExitPageOrderList = ({ keyword }: { keyword: string | null }) => {
	const [selectedOrder, setSelectedOrder] = useAtom(ExitPage.selectedOrderAtom);
	const resetSelection = OrderState.cart.useDispatcher();
	useEffect(() => {
		resetSelection({ type: 'CLEAR' });
	}, [selectedOrder?.id]);

	const orderList = ExitQueryState.useOrderList(keyword, (order) => setSelectedOrder(order));

	const ordersGroupBy = useMemo(
		() =>
			getGroupBy(orderList, (order: OrderTypeWithDdizisAndExchanges) =>
				getDateFormat(order.created)
			),
		[orderList]
	);

	return (
		<List sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
			{[...Object.keys(ordersGroupBy)].map((date, idx) => {
				return (
					<Fragment key={idx}>
						<ListSubheader
							sx={(theme) => ({
								py: 1,
								borderBottom: `1px solid ${theme.palette.divider}`,
							})}>
							<Typography variant={'body1'}>{getDateFormat(date)}</Typography>
						</ListSubheader>
						{ordersGroupBy[date].map((order, orderIdx) => (
							<ListItemButton
								key={orderIdx}
								onClick={() => {
									setSelectedOrder(order);
								}}
								selected={selectedOrder?.id === order.id}
								sx={{
									borderBottom: `1px solid ${grey[300]}`,
								}}>
								<ListItemText
									primary={`주문번호 : ${order.id}`}
									secondary={
										order.guestMemberInfo
											? `${order.guestMemberInfo.name} (${order.guestMemberInfo.telephone})`
											: ' '
									}
								/>
								<Typography variant='body2'>{getTimeFormat(order.created)}</Typography>
							</ListItemButton>
						))}
					</Fragment>
				);
			})}
		</List>
	);
};
