import { Box, Stack, styled } from '@mui/material';
import CheckGrayIcon from 'assets/icon-check-light-gray.png';
import CheckWhiteIcon from 'assets/icon-check-white.png';

const lightGray = '#D3D3D3';
const navy = '#282B5F';

interface IUncheckedBoxProps {}
const UncheckedBox: React.FC<IUncheckedBoxProps> = ({}) => {
	return (
		<Stack
			sx={(theme) => ({
				width: 40,
				height: 40,
				[theme.breakpoints.down('lg')]: {
					width: 30,
					height: 30,
				},
				[theme.breakpoints.down('sm')]: {
					width: 25,
					height: 25,
				},
				border: `2px solid ${lightGray}`,
				borderRadius: 1,
				backgroundColor: 'white',
				alignItems: 'center',
				justifyContent: 'center',
			})}>
			<CheckedLightGray />
		</Stack>
	);
};

interface ICheckedBOxProps {}
const CheckedBox: React.FC<ICheckedBOxProps> = ({}) => {
	return (
		<Stack
			sx={(theme) => ({
				width: 40,
				height: 40,
				[theme.breakpoints.down('lg')]: {
					width: 30,
					height: 30,
				},
				[theme.breakpoints.down('sm')]: {
					width: 25,
					height: 25,
				},
				border: `2px solid ${navy}`,
				borderRadius: 1,
				backgroundColor: `${navy}`,
				alignItems: 'center',
				justifyContent: 'center',
			})}>
			<CheckedWhite />
		</Stack>
	);
};

const CheckedLightGray = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${CheckGrayIcon})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 20,
	height: 15,
	[theme.breakpoints.down('sm')]: {
		width: 16,
		height: 12,
	},
}));

const CheckedWhite = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${CheckWhiteIcon})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 20,
	height: 15,
	[theme.breakpoints.down('sm')]: {
		width: 16,
		height: 12,
	},
}));

export { UncheckedBox, CheckedBox };
