import { ParkingButton } from '../buttons/ParkingButton';
import { useAtomValue } from 'jotai';
import { PosState } from '@kinderlabs-pos/state';
import { useEffect } from 'react';
import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';

export const ParkingDialogButton = () => {
	const parkingLotUrl = useAtomValue(PosState.settings).parkingLotUrl;

	// 최초에 1번 업데이트
	useEffect(() => {
		ReactPosMainPageIPC.externalWindow.changeParkingLotWindow(parkingLotUrl);
	}, [parkingLotUrl]);

	return (
		<ParkingButton
			onClick={() => {
				ReactPosMainPageIPC.externalWindow.showParkingLotWindow();
			}}
		/>
	);
};
