import { OnlineTicketStatisticsDetail } from '@kinderlabs-pos/apis/storeinfo';
import { MuiReactTableUtils } from '@kinderlabs-pos/frameworks/table';
import { OnlineTicketStatisticsFilterType } from '@kinderlabs-pos/shared-data-type';
import { AdminStoreInfoState, StatisticsState, authState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';

export const NewOnlineTicketStatisticsData = {
	useDataByStore: ({ filter }: { filter: OnlineTicketStatisticsFilterType }) => {
		const { from, to } = filter.calendarOption;
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);

		const { data } = useQuery({
			...StatisticsState.keys.salesByOLTForStore({
				params: {
					storeIdList: filter.storeIdList || storeIdListForManager,
					posIdList: filter.posIdList,
					from,
					to,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		const onlineTicketdata = data?.onlineTicketStatisticsByStore ?? [];

		return useMemo(
			() => onlineTicketdata.flatMap((o) => o.onlineTicketStatistics || []),
			[onlineTicketdata]
		);
	},
	useDataByChannel: ({ filter }: { filter: OnlineTicketStatisticsFilterType }) => {
		const { from, to } = filter.calendarOption;
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);

		const { data } = useQuery({
			...StatisticsState.keys.salesByOLTChannel({
				params: {
					storeIdList: filter.storeIdList || storeIdListForManager,
					posIdList: filter.posIdList,
					from,
					to,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		const onlineTicketdata = data?.onlineTicketStatisticsByStore ?? [];

		return useMemo(() => onlineTicketdata.flatMap((o) => o.onlineTicketStatistics || []), [data]);
	},
	use매장별ColmnInfo: () => {
		const { data } = useQuery({
			...AdminStoreInfoState.keys.all(),
			keepPreviousData: true,
			refetchOnWindowFocus: true,
		});
		const storeInfoList = data ?? [];
		const is주렁주렁 = useAtomValue(authState.enterpriseInfo)?.id === 'zoolung';

		return useMemo<ColumnDef<OnlineTicketStatisticsDetail>[]>(
			() => [
				{
					id: 'storeName',
					accessorFn: (row) => storeInfoList.find((s) => s.id === row.storeId)?.name || '',
					cell: (info) => (
						<MuiReactTableUtils.ColumnWithExpandButton
							label={info.getValue() as string}
							info={info}
						/>
					),
					header: '매장명',
					size: 150,
					footer: '총 합',
				},
				{
					accessorKey: 'channelName',
					header: '채널명',
					size: 150,
				},
				commonColumnDef['ticketName'],
				commonColumnDef['ticketPrice'],
				...(is주렁주렁
					? [commonColumnDef['rawPrice'], commonColumnDef['productId']]
					: ([] as ColumnDef<OnlineTicketStatisticsDetail>[])),
				commonColumnDef['quantity'],
				commonColumnDef['amount'],
				...(is주렁주렁
					? [commonColumnDef['순매출']]
					: ([] as ColumnDef<OnlineTicketStatisticsDetail>[])),
			],
			[storeInfoList, is주렁주렁]
		);
	},
	use채널별ColmnInfo: () => {
		const is주렁주렁 = useAtomValue(authState.enterpriseInfo)?.id === 'zoolung';
		return useMemo<ColumnDef<OnlineTicketStatisticsDetail>[]>(
			() => [
				{
					accessorKey: 'channelName',
					header: '채널명',
					cell: (info) => (
						<MuiReactTableUtils.ColumnWithExpandButton
							label={info.getValue() as string}
							info={info}
						/>
					),
					size: 150,
					footer: '총 합',
				},
				commonColumnDef['ticketName'],
				commonColumnDef['ticketPrice'],
				...(is주렁주렁
					? [commonColumnDef['rawPrice'], commonColumnDef['productId']]
					: ([] as ColumnDef<OnlineTicketStatisticsDetail>[])),
				commonColumnDef['quantity'],
				commonColumnDef['amount'],
				...(is주렁주렁
					? [commonColumnDef['순매출']]
					: ([] as ColumnDef<OnlineTicketStatisticsDetail>[])),
			],
			[is주렁주렁]
		);
	},
};

const commonColumnDef: Record<string, ColumnDef<OnlineTicketStatisticsDetail>> = {
	ticketName: {
		accessorKey: 'ticketName',
		header: '티켓이름',
		size: 360,
	},
	ticketPrice: {
		accessorKey: 'ticketPrice',
		header: '가격',
		size: 100,
		...ColumnDefsHelper.get가격ColumnDef(),
	},
	productId: {
		accessorKey: 'productId',
		header: '상품코드',
		size: 120,
		//@ts-ignore
		aggregationFn: null,
	},
	rawPrice: {
		accessorKey: 'rawPrice',
		header: '수수료 제외',
		size: 120,
		...ColumnDefsHelper.get가격ColumnDef(),
	},
	quantity: {
		accessorKey: 'quantity',
		header: '수량',
		size: 90,
		...ColumnDefsHelper.get건수ColumnDef('quantity'),
	},
	amount: {
		accessorKey: 'amount',
		header: '판매액',
		size: 150,
		...ColumnDefsHelper.get금액ColumnDef('amount'),
	},
	// JIN: 주렁주렁의 특별주문. 이해하지말고 그대로 받아들일 것.
	순매출: {
		accessorKey: 'realRevenue',
		accessorFn: (row) => (row.rawPrice ?? 0) * row.quantity,
		header: '순매출 (주렁주렁)',
		size: 180,
		...ColumnDefsHelper.get금액ColumnDef('realRevenue'),
	},
};
