import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { Badge, IconButton, IconButtonProps, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconTextButton } from '@kinderlabs-pos/ui-atoms';

export interface ICallButtonProps extends IconButtonProps {
	callCount: number;
	disabled?: boolean;
}
export const CallButton: React.FC<ICallButtonProps> = ({ callCount, onClick, disabled }) => {
	return (
		<IconTextButton
			iconSize='large'
			onClick={onClick}
			disabled={disabled}
			icon={
				<Badge
					badgeContent={callCount}
					color='primary'
					sx={{
						'& > .MuiBadge-badge': {
							top: 6,
						},
					}}>
					<NotificationsIcon fontSize='small' />
				</Badge>
			}
			label={<Typography>{'호출'}</Typography>}
		/>
	);
};
