import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Box, DialogContent, IconButton } from '@mui/material';
import { PropsWithChildren } from 'react';

export interface IFormattedDialogProps extends PropsWithChildren {
	open: boolean;
	closeDialog: () => void;
}
export const FormattedDialog: React.FC<IFormattedDialogProps> = ({
	open,
	closeDialog,
	children,
}) => {
	return (
		<WithDialog.XlDialog
			titleSxProps={{ padding: 0, pr: 1, pt: 1 }}
			fullWidth
			maxWidth={'lg'}
			closeDialog={closeDialog}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}
					sx={{ mb: 1 }}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}
			open={open}>
			<DialogContent>{children}</DialogContent>
		</WithDialog.XlDialog>
	);
};
