import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';

export interface IQrCodeProps {
	qrData: string;
	style?: React.CSSProperties;
}
const QrCode: React.FC<IQrCodeProps> = ({ qrData, style }) => {
	return (
		<QRCodeCanvas
			style={style}
			value={qrData}
			size={128}
			bgColor={'#ffffff'}
			fgColor={'#000000'}
			level={'M'}
			includeMargin={false}
		/>
	);
};

export default QrCode;
