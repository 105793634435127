import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	CursorPageType,
	Pageable,
	ProductInfoType,
	ProductType,
} from '@kinderlabs-pos/shared-data-type';

export const getListInfiniteAsync =
	(rootUrl: string) =>
	async ({ storeId, type, search, cetegoryId, cursorPkId, pageable }: GetListInfiniteReqType) =>
		(
			await posAxiosClient.get<CursorPageType<ProductInfoType & BaseInfoType>>(
				`${rootUrl}/product/list/cursor`,
				{
					params: {
						...pageable.getPageableQueryParam(),
						storeId,
						type,
						search,
						cursorPkId,
						cetegoryId,
					},
				}
			)
		).data;

type GetListInfiniteReqType = {
	storeId?: number;
	type?: ProductType;
	search?: string;
	cetegoryId?: number;
	cursorPkId?: string | number;
	pageable: Pageable;
};
