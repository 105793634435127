import { debounce } from '@mui/material';
import { useEffect, useState } from 'react';

export const useScrollControl = ({ scrollSize }: { scrollSize: number }) => {
	const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
	const [scrollBtnInfo, setScrollBtnInfo] = useState<{
		isBtnRequired: boolean;
		isTop: boolean;
		isBottom: boolean;
	}>({
		isBtnRequired: false,
		isTop: true,
		isBottom: false,
	});

	useEffect(() => {
		if (!scrollRef) return;

		setScrollBtnInfo({
			isBtnRequired: scrollRef.scrollHeight > scrollRef.clientHeight,
			isTop: true,
			isBottom: false,
		});
	}, [scrollRef]);

	const resetScrollBtnInfo = debounce(() => {
		const element = scrollRef;
		if (!element) return;

		if (element.scrollTop === 0) {
			setScrollBtnInfo((prev) => ({ ...prev, isTop: true, isBottom: false }));
		} else if (element.scrollHeight - (element.scrollTop + element.clientHeight) < 1) {
			setScrollBtnInfo((prev) => ({ ...prev, isTop: false, isBottom: true }));
		} else {
			setScrollBtnInfo((prev) => ({ ...prev, isTop: false, isBottom: false }));
		}
	}, 200);

	return {
		isScrollButtonRequired: scrollBtnInfo.isBtnRequired,
		isTop: scrollBtnInfo.isTop,
		isBottom: scrollBtnInfo.isBottom,
		scrollBtnInfo,
		onScroll: () => resetScrollBtnInfo(),
		scrollUp: () => {
			scrollRef?.scrollBy({ left: 0, top: -scrollSize, behavior: 'smooth' });
		},
		scrollDown: () => {
			scrollRef?.scrollBy({ left: 0, top: scrollSize, behavior: 'smooth' });
		},
		setScrollRef,
	};
};
