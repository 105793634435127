import { UserOutlined } from '@ant-design/icons';
import { OrderType } from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { OrderState, SearchMemberState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Avatar, Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { TerminateButton } from '../PosHeader/WaitingIndicator/buttons/TerminateButton';
import { MemberInfoDialog } from './MemberInfoDialog';

export interface IUserInfoBoxProps {
	userInfo?: OrderType['guestMemberInfo'];
	userLength: number;
}
export const UserInfoBox: React.FC<IUserInfoBoxProps> = ({ userInfo, userLength }) => {
	const dispatchOrder = useSetAtom(OrderState.value);
	const handleClickTerminateButton = () => {
		dispatchOrder({ type: 'SET_GUEST_USERS_INFO', guestMemberInfo: undefined });
	};

	return (
		<Stack
			direction='row'
			width={'100%'}
			alignItems={'center'}
			spacing={2}>
			<Typography variant='h4'>{userInfo?.sunbun ? `순번 ${userInfo.sunbun}` : ''}</Typography>
			<Stack
				direction={'column'}
				flex={1}>
				<Typography variant='h4'>
					{userInfo ? (
						<>
							{userInfo.name}
							{userLength > 0 && (
								<Typography
									variant='subtitle1'
									component={'span'}>
									{` 포함 ${userLength}명`}
								</Typography>
							)}
						</>
					) : (
						'-'
					)}
				</Typography>
				<Typography variant='subtitle1'>
					{userInfo?.telephone ? getTelephoneNumberWithDash(userInfo.telephone) : '-'}
				</Typography>
			</Stack>
			{userInfo && <VaunceAppMemberInfo vaunceMemberId={userInfo.appUserId} />}
			{userInfo && (
				<TerminateButton
					variant='default'
					onClick={handleClickTerminateButton}
				/>
			)}
		</Stack>
	);
};

export const VaunceAppMemberInfo = ({ vaunceMemberId }: { vaunceMemberId?: string }) => {
	const { data } = useQuery({
		...SearchMemberState.keys.getByUserId(vaunceMemberId),
		refetchOnWindowFocus: true,
	});
	const memberInfo = data ?? null;

	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			spacing={1}>
			<WithDialog
				dialog={(open, closeDialog) => (
					<Suspense>
						{memberInfo && (
							<MemberInfoDialog
								open={open}
								closeDialog={closeDialog}
								vaunceMemberInfo={memberInfo}
							/>
						)}
					</Suspense>
				)}>
				{(openDialog) => (
					<Button
						color='primary'
						variant='outlined'
						onClick={openDialog}
						disabled={!memberInfo}
						startIcon={<UserOutlined />}>
						<Typography variant={'subtitle1'}>{'앱회원 정보'}</Typography>
					</Button>
				)}
			</WithDialog>
		</Stack>
	);
};
