import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CursorPageType,
	DeviceType,
	KitchenOrderInfoType,
	KitchenOrderMethodType,
	KitchenOrderStatusType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../const';

const domain_name = 'kitchen-order';

const getKitchenOrderInfoAsync = (rootUrl: string) => async (kitchenOrderId: string) =>
	(
		await posAxiosClient.get<KitchenOrderInfoType>(`${rootUrl}/${domain_name}`, {
			params: {
				id: kitchenOrderId,
			},
		})
	).data;

interface SearchKitchenOrderListType {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
	deviceTypeList?: DeviceType[];
	deviceIdList?: number[];
	statusList?: KitchenOrderStatusType[];
	kitchenSystemId?: number;
	search?: string;
	cursorId?: string | number;
	pageable: Pageable;
}

const getKitchenOrderInfoSearchByCursorAsync =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		deviceIdList,
		deviceTypeList,
		statusList,
		kitchenSystemId,
		search,
		cursorId,
		pageable,
	}: SearchKitchenOrderListType) =>
		(
			await posAxiosClient.get<CursorPageType<KitchenOrderInfoType>>(
				`${rootUrl}/${domain_name}/search/cursor`,
				{
					params: {
						storeIdList,
						...pageable.getPageableQueryParam(),
						cursorId,
						from: from.format('YYYY-MM-DD HH:mm:ss'),
						to: to.format('YYYY-MM-DD HH:mm:ss'),
						statusList,
						search,
						deviceIdList,
						deviceTypeList,
						kitchenSystemId,
					},
				}
			)
		).data;

const getKitchenOrderInfoSearchByOffSetAsync =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		deviceIdList,
		deviceTypeList,
		status,
		kitchenSystemId,
		search,
		pageable,
	}: Omit<SearchKitchenOrderListType, 'cursorId' | 'statusList'> & {
		status?: KitchenOrderStatusType;
	}) =>
		(
			await posAxiosClient.get<PageType<KitchenOrderInfoType>>(`${rootUrl}/${domain_name}/search`, {
				params: {
					storeIdList,
					...pageable.getPageableQueryParam(),
					from: from.format('YYYY-MM-DD HH:mm:ss'),
					to: to.format('YYYY-MM-DD HH:mm:ss'),
					status,
					search,
					deviceIdList,
					deviceTypeList,
					kitchenSystemId,
				},
			})
		).data;

export interface KitchenOrderStatusSwitchType {
	id: string[];
	orderLineIds?: string[];
	targetStatus: KitchenOrderMethodType;
}
const updateKitchenOrderStatusAsync =
	(rootUrl: string) =>
	async ({ id, orderLineIds, targetStatus }: KitchenOrderStatusSwitchType) =>
		(
			await posAxiosClient.post<KitchenOrderInfoType>(
				`${rootUrl}/${domain_name}/${targetStatus}`,
				null,
				{
					params: {
						id,
						orderLineIds,
					},
				}
			)
		).data;

const callKitchenOrderFromDeviceAsync = (rooUrl: string) => async (kitchenOrderId: string) =>
	(
		await posAxiosClient.post<KitchenOrderInfoType>(`${rooUrl}/${domain_name}/call`, null, {
			params: {
				id: kitchenOrderId,
			},
		})
	).data;

const getRecentCallsFromStoreAsync =
	(rootUrl: string) => async (kitchenSystemId: number, date: string) =>
		(
			await posAxiosClient.get<KitchenOrderInfoType[]>(
				`${rootUrl}/${domain_name}/list/recent/calls`,
				{
					params: {
						kitchenSystemId,
						date,
					},
				}
			)
		).data;

const getKitchenOrderListToPrint =
	(rootUrl: string) =>
	async ({ kitchenSystemId, date }: { kitchenSystemId: number; date: string }) =>
		(
			await posAxiosClient.get<KitchenOrderInfoType[]>(`${rootUrl}/${domain_name}/print/list`, {
				params: {
					kitchenSystemId,
					date,
				},
			})
		).data;

const printKitchenOrder =
	(rootUrl: string) =>
	async ({
		kitchenSystemId,
		kitchenOrderId,
	}: {
		kitchenSystemId: number;
		kitchenOrderId: string;
	}) =>
		(
			await posAxiosClient.post<KitchenOrderInfoType>(
				`${rootUrl}/${domain_name}/print`,
				{},
				{
					params: {
						kitchenSystemId,
						id: kitchenOrderId,
					},
				}
			)
		).data;

export const KitchenOrderAPI = {
	getKitchenOrderInfo: getKitchenOrderInfoAsync(rootUrl),
	getKitchenOrderInfoListByCursor: getKitchenOrderInfoSearchByCursorAsync(rootUrl),
	getkitchenOrderInfoListByOffSet: getKitchenOrderInfoSearchByOffSetAsync(rootUrl),
	updateKitchenOrderStatus: updateKitchenOrderStatusAsync(rootUrl),
	callKitchenOrder: callKitchenOrderFromDeviceAsync(rootUrl),
	getRecentCalls: getRecentCallsFromStoreAsync(rootUrl),
	printKitchenOrder: printKitchenOrder(rootUrl),
	getKitchenOrderListToPrint: getKitchenOrderListToPrint(rootUrl),
};
