import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PaymentInfoType } from '@kinderlabs-pos/shared-data-type';

export const getPaymentsInOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, orderId }: { storeId: number; posId: number; orderId: string }) =>
		(
			await posAxiosClient.get<PaymentInfoType>(
				`${rootUrl}/payment/store/${storeId}/pos/${posId}/payments/order`,
				{
					params: {
						id: orderId,
					},
				}
			)
		).data;
