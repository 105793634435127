import { Components } from '@mui/material';

export const posComponentOverrides: Components = {
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				fontSize: '1.6rem',
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				fontSize: '1.6rem',
			},
		},
	},
	MuiDialogContentText: {
		styleOverrides: {
			root: {
				fontSize: '1.6rem',
			},
		},
	},
	MuiToolbar: {
		styleOverrides: {
			regular: {
				'@media (min-width: 600px)': {
					height: 48,
					minHeight: 48,
				},
				height: 48,
				minHeight: 48,
			},
			dense: {
				'&.MuiToolbar-dense': {
					backgroundColor: 'white',
					height: 24,
					minHeight: 24,
				},
			},
		},
	},
	MuiTableHead: {
		styleOverrides: {
			root: {
				'& MuiTableRow-head': {
					'& .MuiTableCell-root': {
						paddingLeft: 6,
						paddingRight: 6,
					},
				},
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				padding: 8,
				fontSize: 14,
			},
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: {
				borderRadius: '10%',
			},
			sizeSmall: {
				width: 26,
				height: 26,
				padding: 0,
				paddingLeft: 2,
				paddingRight: 2,
				'& .MuiSvgIcon-root': {
					width: 24,
					height: 24,
				},
			},
		},
	},
};
