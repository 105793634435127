import { GiftCardProviderType } from '@kinderlabs-pos/shared-data-type';
import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { CardPaymentType } from '../HardwareDataType/PaymentModuleType';
import { OrderType } from '../Order/OrderType';
import { Pageable } from '../PageType';
import { ReceiptInfoType } from './ReceiptInfoType';

export type PaymentStatusType = 'COMPLETE' | 'REFUND';
export type AdminPaymentFilterType = {
	storeIdList?: number[];
	posIdList?: number[];
	page: number;
	size?: number;
	method?: PaymentMethodType;
	status?: PaymentStatusType;
	cardPaymentType?: CardPaymentType;
	// ddiziInfoId?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
};

export const PaymentMethodTypeArray = [
	'CARD',
	'CASH',
	'VIRTUAL_CARD',
	'TRANSFER',
	'KAKAOPAY',
	'SSGPAY',
	'ZEROPAY',
	'ZEROPAY_MPM',
	'SEOULPAY',
	'SEOULPAY_MPM',
	'CASH_IC',
	'CARD_MS',
	'CREDIT',
	'APPCARD',
	'POINT',
	'GIFTCARD',
] as const;
export type PaymentMethodType = (typeof PaymentMethodTypeArray)[number];
export const PaymentMethodTypeLabel: Record<PaymentMethodType, string> = {
	CARD: 'IC카드',
	CASH: '현금',
	VIRTUAL_CARD: '가상카드',
	TRANSFER: '계좌이체',
	KAKAOPAY: '카카오페이',
	SSGPAY: 'SSG페이',
	ZEROPAY: '제로페이',
	ZEROPAY_MPM: '제로페이(MPM)',
	SEOULPAY: '서울페이',
	SEOULPAY_MPM: '서울페이(MPM)',
	CASH_IC: '현금IC카드', // 할부없음
	CARD_MS: 'MS카드', // 할부있음
	CREDIT: '외상',
	APPCARD: '앱카드',
	POINT: '포인트',
	GIFTCARD: '상품권',
};

export const is할부가능: Record<PaymentMethodType, boolean> = {
	CARD: true,
	CASH: false,
	VIRTUAL_CARD: true,
	TRANSFER: false,
	KAKAOPAY: true,
	SSGPAY: true,
	ZEROPAY: false,
	ZEROPAY_MPM: false,
	SEOULPAY: false,
	SEOULPAY_MPM: false,
	CASH_IC: false,
	CARD_MS: true,
	CREDIT: false,
	APPCARD: true,
	POINT: false,
	GIFTCARD: false,
};

export const need메모옵션: Record<PaymentMethodType, '없음' | '가능' | '필수'> = {
	CARD: '없음',
	CASH: '없음',
	VIRTUAL_CARD: '가능',
	TRANSFER: '필수',
	KAKAOPAY: '없음',
	SSGPAY: '없음',
	ZEROPAY: '없음',
	ZEROPAY_MPM: '없음',
	SEOULPAY: '없음',
	SEOULPAY_MPM: '없음',
	CASH_IC: '없음',
	CARD_MS: '없음',
	CREDIT: '필수',
	APPCARD: '없음',
	POINT: '없음',
	GIFTCARD: '없음',
};

// 이게 ON 이면 공식 발급된 영수증이 없는 결제 형태일수도 있다는 말이다.
export const has비공식영수증: Record<PaymentMethodType, boolean> = {
	CARD: false,
	CASH: true,
	VIRTUAL_CARD: true, // 2023.11.03 가상카드에서 영수증 제거.
	TRANSFER: true,
	KAKAOPAY: false,
	SSGPAY: false,
	ZEROPAY: false,
	ZEROPAY_MPM: true,
	SEOULPAY: false,
	SEOULPAY_MPM: true,
	CASH_IC: false,
	CARD_MS: false,
	CREDIT: true,
	APPCARD: false,
	POINT: true,
	GIFTCARD: true,
};

/* 애매해서 주석처리함 has공식영수증은 아래 
  export interface CardMSPaymentInfoType extends PaymentRequestInfoType {
    method: 'CARD_MS';
    receipt: ReceiptInfoType;
  }
  부분에서 receipt 가 필수나 있냐 없냐로 표현됨.
  예를 들어 SEOULPAY_MPM 는 receipt 가 존재하지 않음.
*/
// export const has공식영수증: Record<PaymentMethodType, boolean> = {
// 	CARD: true,
// 	CASH: true,
// 	VIRTUAL_CARD: true,
// 	TRANSFER: true,
// 	KAKAOPAY: true,
// 	SSGPAY: true,
// 	ZEROPAY: true,
// 	ZEROPAY_MPM: false,
// 	SEOULPAY: true,
// 	SEOULPAY_MPM: false,
// 	CASH_IC: true,
// 	CARD_MS: true,
// 	CREDIT: false,
// 	APPCARD: true,
// 	POINT: false,
// };

export const has공식현금영수증: Record<PaymentMethodType, boolean> = {
	CARD: false,
	CASH: true,
	VIRTUAL_CARD: false,
	TRANSFER: true,
	KAKAOPAY: false,
	SSGPAY: false,
	ZEROPAY: false,
	ZEROPAY_MPM: false,
	SEOULPAY: false,
	SEOULPAY_MPM: false,
	CASH_IC: false,
	CARD_MS: false,
	CREDIT: false,
	APPCARD: false,
	POINT: false,
	GIFTCARD: true,
};

export interface PaymentInfoType {
	id: string;
	created: Date;
	amount: number;
	taxFreeAmount: number;
	tax: number;
	storeId: number;
	posId: number;
	orderId: OrderType['id'];
	// jungSanId?: number;
	method: PaymentMethodType;
	receipt?: ReceiptInfoType;
	refundReceipt?: ReceiptInfoType;
	refunded?: Date;
	memberId?: number;
	memo?: string;
}

export type PaymentTableDataType = Omit<
	PaymentInfoType,
	'storeId' | 'status' | 'amount' | 'created'
> & {
	storeId: string;
	status: string;
	amount: string;
	created: string;
};

export type PaymentRequestInfoType = Omit<PaymentInfoType, 'id' | 'orderId'> & {
	id?: string;
	orderId?: string;
};

/**
 * refundId 는 현금 취소는 undefined 가 되고 카드 취소 등은 bin 이 된다.
 * refunded 는 환불 시각을 의미한다.
 */
export interface RefundInfoType {
	refundId: string;
	refunded: Date;
	receiptId?: string;
}

export interface CardPaymentInfoType extends PaymentRequestInfoType {
	method: 'CARD';
	receipt: ReceiptInfoType;
}

export interface CardMSPaymentInfoType extends PaymentRequestInfoType {
	method: 'CARD_MS';
	receipt: ReceiptInfoType;
}

export interface CashICPaymentInfoType extends PaymentRequestInfoType {
	method: 'CASH_IC';
	receipt: ReceiptInfoType;
}

// 가상카드 결제
export interface VirtualCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'VIRTUAL_CARD';
	memo?: string;
	receipt: undefined;
}

export interface KakaoPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'KAKAOPAY';
	receipt: ReceiptInfoType;
}

export interface SSGPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'SSGPAY';
	receipt: ReceiptInfoType;
}

export interface APPCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'APPCARD';
	receipt: ReceiptInfoType;
}

export interface ZeroPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'ZEROPAY';
	receipt: ReceiptInfoType;
}

export interface SeouPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'SEOULPAY';
	receipt: ReceiptInfoType;
}

export interface CashPaymentInfoType extends PaymentRequestInfoType {
	method: 'CASH';
	receivedMoney?: number;
	// 현금 영수증을 함께하는 경우
	receipt?: ReceiptInfoType;
}

// 계좌이체
export interface TransferPaymentInfoType extends PaymentRequestInfoType {
	method: 'TRANSFER';
	memo: string;
	receipt?: ReceiptInfoType;
}

// 외상결제
export interface CreditPaymentInfoType extends PaymentRequestInfoType {
	method: 'CREDIT';
	memo: string;
	receipt: undefined;
}

export interface ZeroPayMPMPaymentInfoType extends PaymentRequestInfoType {
	method: 'ZEROPAY_MPM';
	receipt: undefined;
}

export interface SeoulPayMPMPaymentInfoType extends PaymentRequestInfoType {
	method: 'SEOULPAY_MPM';
	receipt: undefined;
}

// 포인트사용
export interface PointUseInfoType extends PaymentRequestInfoType {
	method: 'POINT';
	memberId: number;
	receipt: undefined;
}

export interface GiftCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'GIFTCARD';
	giftCardNumber: string;
	giftCardName: string;
	giftCardProvider: GiftCardProviderType;
}

export type 현금영수증OptionType =
	| { type: '소득공제'; customerId: string; isSignPadUsed: boolean }
	| { type: '지출증빙'; customerId: string; isSignPadUsed: boolean };
