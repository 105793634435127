import { AdminPosInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';
import {
	KioskInfoType,
	PosInfoType,
	PosTypeLabel,
	updateFileType,
} from '@kinderlabs-pos/shared-data-type';
import { AdminKioskInfoState, PosState, QueryClient } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useMemo } from 'react';

const posInfoKeys = createQueryKeys('posInfo', {
	list: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await AdminPosInfoApis.getPosInfoList({ storeId: storeId });
			return res;
		},
	}),
	detail: ({ storeId, posId }: { storeId?: number; posId?: number }) => ({
		queryKey: [storeId, posId],
		queryFn: async () => {
			if (!posId) {
				return null;
			}
			const res = await AdminPosInfoApis.getPosInfo({ storeId: storeId, posId: posId });
			return res;
		},
	}),
	print: () => ({
		queryKey: ['model', 'list'],
		queryFn: async () => {
			const res = await AdminPosInfoApis.getPosPrinterModelList();
			return res;
		},
	}),
});

const useCreatePosInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<PosInfoType, 'id' | 'status' | 'deviceType'>) => {
			return await AdminPosInfoApis.createPosInfo(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useUpdatePosInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<PosInfoType, 'status' | 'deviceType'>) => {
			return await AdminPosInfoApis.updatePosInfo(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateAutomationOption = () => {
	return QueryClient.useMutation(
		async (input: Pick<PosInfoType, 'id' | 'storeId' | 'receiptAutoPrint'>) => {
			return await AdminPosInfoApis.updateAutomationOption(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateParkingUrl = () => {
	const [settingAtom, setSettingAtom] = useAtom(PosState.settings);

	return QueryClient.useMutation(
		async (input: Pick<PosInfoType, 'id' | 'storeId' | 'parkingUrl'>) => {
			return await AdminPosInfoApis.updateParkingUrl(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();

				if (data.parkingUrl) {
					ReactPosMainPageIPC.externalWindow.changeParkingLotWindow(data.parkingUrl);
					setSettingAtom({ ...settingAtom, parkingLotUrl: data.parkingUrl });
				}
			},
		}
	);
};

const useDeletePosInfo = () => {
	return QueryClient.useMutation(
		async (posId: number) => {
			await AdminPosInfoApis.deletePosInfo({ idList: [posId] });
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useUpdatePosFileUrl = () => {
	return QueryClient.useMutation(
		async (input: updateFileType) => {
			return await AdminPosInfoApis.updateFile(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const usePosAndKioskSelectorList = ({ storeId }: { storeId?: number }) => {
	const { data: posInfoListData } = useQuery({
		...posInfoKeys.list(storeId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const posInfo = posInfoListData ?? [];

	const { data: kioskInfoListData } = useQuery({
		...AdminKioskInfoState.keys.list(storeId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const kioskInfo = kioskInfoListData ?? [];

	return useMemo(() => {
		if (!storeId) return [];

		const posInfoList = posInfo.map((pos) => ({
			value: pos.id,
			device: pos,
			type: 'POS',
			label: `[POS] ${PosTypeLabel[pos.posType]} ${pos.name}`,
		}));

		const kioskInfoList = kioskInfo.map((kiosk) => ({
			value: kiosk.id,
			device: kiosk,
			type: 'KIOSK',
			label: `[KIOSK] ${kiosk.name}`,
		}));

		return [...posInfoList, ...kioskInfoList] as {
			value: number;
			device: PosInfoType | KioskInfoType;
			type: string;
			label: string;
		}[];
	}, [posInfo, kioskInfo, storeId]);
};

const useDeviceInfo = ({ storeId, deviceId }: { storeId?: number; deviceId?: number }) => {
	const deviceList = usePosAndKioskSelectorList({ storeId });

	return deviceList.find((deviceInfo) => deviceInfo.device.id === deviceId);
};

const invalidateQueries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: posInfoKeys._def });

export const AdminPosInfoState = {
	keys: posInfoKeys,
	usePosAndKioskSelectorList,
	useDeviceInfo,

	useCreatePosInfo,
	useUpdatePosInfo,
	useDeletePosInfo,
	useUpdateAutomationOption,
	useUpdateParkingUrl,
	useUpdatePosFileUrl,
};
