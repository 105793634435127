import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Box, DialogProps, Divider, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { FormattedDialog } from '../../components/FormattedDialog';

export interface IAgreePersonInfomationDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
}

const AgreePersonInfomationDialog: React.FC<IAgreePersonInfomationDialogProps> = ({
	open,
	onClose,
}) => {
	const noticeContent: string[] = [
		"㈜ 바운스는 회원의 개인정보를 '개인정보 수집 및 이용 동의'에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 다만, 아래와 같이 서비스 제공을 위해 개인정보를 제공 받는 자, 제공목적, 제공 항목, 이용 및 보유기간을 회원에게 고지하여 동의를 구한 후 제3자에게 제공합니다. 귀하께서는 아래 내용을 자세히 읽어 보시고, 모든 내용을 이해하신 후에 동의 여부를 결정하여 주시기 바랍니다.",
		'',
		'1. 제공받는 자 : ㈜ 킨더랩스',
		'',
		'2. 제공목적',
		'- 매장 대기 서비스 제공, 이용자 및 이용정보 확인, 소비자 분쟁 해결, 회원서비스 제공, 신규 서비스 및 서비스 개선',
		'',
		'3. 제공항목',
		'- 앱 회원 정보, 이용자 및 이용정보, 이름, 핸드폰번호',
		'',
		'4 이용 및 보유기간',
		'- 동의 철회 또는 탈퇴 시 지체 없이 파기. 단, 회원가입 남용, 서비스 부정사용 방지를 위해 CI(고유식별번호) 정보는 회원탈퇴 후 90일까지',
		'',
		'5. 전항에도 불구하고 다음의 경우에는 예외로 합니다.',
		'- 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우',
		'- 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우',
		'- 정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우',
		'- 수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안정성 확보 조치를 적용한 경우',
	];

	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack
				spacing={2}
				sx={{ pb: 5 }}>
				<Typography
					variant='h3'
					color={vaunceColors.lightPink}
					fontWeight={500}>
					{'[필수] 개인정보 제3자 정보 제공 동의'}
				</Typography>
				<Divider />
				<Box>
					<NoticeCotnentBox>
						{noticeContent.map((content, idx) => (
							<Typography
								variant='h5'
								key={idx}
								sx={{ fontWeight: 500 }}>
								{content === '' && <br />}
								{content}
							</Typography>
						))}
					</NoticeCotnentBox>
				</Box>
			</Stack>
		</FormattedDialog>
	);
};

export default AgreePersonInfomationDialog;

const NoticeCotnentBox = styled(Box)(({ theme }) => ({
	marginTop: '20px',
}));
