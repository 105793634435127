import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { AuthorityType } from '@kinderlabs-pos/shared-data-type';

export const deleteManagerInfoAsync =
	(rootUrl: string) =>
	async ({ authority, idList }: DeleteManagerType) =>
		await posAxiosClient.delete<string[]>(
			`${rootUrl}/auth-service/manager/member/${authority}/delete`,
			{
				params: {
					idList: idList,
				},
			}
		);

type DeleteManagerType = {
	authority: AuthorityType;
	idList: string[];
};
