import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { DdiziType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getDdiziListAsync =
	(rootUrl: string) =>
	async ({
		targetStoreId,
		targetPosId,
		storeIdList,
		from,
		to,
		pageable,
		status,
		search,
	}: GetDdiziListRequestType) =>
		(
			await posAxiosClient.get<PageType<DdiziType>>(`${rootUrl}/ddizi/search`, {
				params: {
					...pageable.getPageableQueryParam(),
					storeIdList,
					targetStoreId,
					targetPosId,
					from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
					to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
					status,
					search,
				},
			})
		).data;

export type GetDdiziListRequestType = {
	storeIdList?: number[];
	targetStoreId?: number;
	targetPosId?: number;
	from: Dayjs;
	to: Dayjs;
	status?: DdiziType['status'];
	search?: string;
	pageable: Pageable;
};
