import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Stack,
	Typography,
} from '@mui/material';
import multiLargeImage from 'assets/vaunce/image/multi_large.png';
import onestopImage from 'assets/vaunce/image/onestop.png';
import cmtTicketImage from 'assets/vaunce/image/seasonticket.png';

import multiLargeImage2x from 'assets/vaunce/image/multi_large@2x.png';
import onestopImage2x from 'assets/vaunce/image/onestop@2x.png';
import cmtTicketImage2x from 'assets/vaunce/image/seasonticket@2x.png';

import { ReactNode } from 'react';
import GrayBox from '../../components/GrayBox';
import { useVaunceAppRouter } from '../routes';

export interface IVauncePremiumClubTabProps {}
export const VauncePremiumClubTab: React.FC<IVauncePremiumClubTabProps> = ({}) => {
	const { navigateMultiUseTickets, navigateCommutationTickets, navigateLogin } =
		useVaunceAppRouter();
	const isLoginedUser = localStorage.getItem('accessToken') ? true : false;
	const ticketsBox: {
		imgUrl: string;
		srcsetUrl: string;
		title: '다회권' | '정기 회원권';
		subtitle: string;
		desTitle: string;
		description: ReactNode[];
	}[] = [
		{
			imgUrl: `${multiLargeImage}`,
			srcsetUrl: multiLargeImage2x,
			title: '다회권',
			subtitle: '5회권 구매 시, 1회 추가 증정!',
			desTitle: '5회권 (1회 추가, 총 6회권)',
			description: [
				<>
					1회 2시간 이용 기준
					<br />
					(영통센터는 2시간 30분 이용)
				</>,
				'이용자의 나이 또는 키에 따라 금액 다름',
				'지정 센터 별 금액 다름',
				<>
					구매 시, 지정된 센터에서만 사용 가능 <br /> (교차 사용 불가)
				</>,
				'유효 기간 6개월',
			],
		},
		{
			imgUrl: `${cmtTicketImage}`,
			srcsetUrl: cmtTicketImage2x,
			title: '정기 회원권',
			subtitle: '기간 내 무제한으로 사용하세요!',
			desTitle: '3개월권',
			description: [
				<>
					1회 2시간 이용 기준
					<br />
					(영통센터는 2시간 30분)
				</>,
				<>매일 1회만 무료 이용 가능</>,
				<>시간 초과 시 추가 결제 필요</>,
				<>
					구매 시, 지정된 센터에서만 사용 가능
					<br />
					(교차 사용 불가)
				</>,
				<>
					사용 전 정기권 이용자 등록 필수
					<br />
					(이름/생년월일/사진)
				</>,
				<>사용자: 실제 이용하는 자녀 또는 본인</>,
				<>유효기간: 사용자 등록일부터 3개월</>,
			],
		},
	];
	return (
		<Stack spacing={5}>
			<GrayBox sx={{ pr: 1 }}>
				<Stack
					direction={'row'}
					spacing={1}
					alignItems={'center'}>
					<img
						src={onestopImage}
						srcSet={onestopImage2x}
						width={64}
						height={64}
						style={{ marginRight: '15px' }}
					/>
					<Stack>
						<Typography variant='h3'>{'비용은 줄이고, 혜택은 늘리고!'}</Typography>
						<Typography
							variant='h6'
							fontWeight={500}>
							{'바운스 매니아라면 놓쳐서는 안될 세가지 맞춤형 멤버십 프로그램!'}
						</Typography>
					</Stack>
				</Stack>
			</GrayBox>

			<Box>
				{ticketsBox.map((item, idx) => (
					<Accordion
						key={idx}
						sx={{ pb: 2 }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ color: `${vaunceColors.lightPink}` }} />}>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}>
								<img
									src={item.imgUrl}
									width={34}
									height={34}
								/>
								<Stack>
									<Typography
										color={vaunceColors.lightPink}
										variant='h3'>
										{item.title}
									</Typography>
									<Typography
										variant='h5'
										fontWeight={500}>
										{item.subtitle}
									</Typography>
								</Stack>
							</Stack>
						</AccordionSummary>
						<AccordionDetails>
							<Stack spacing={3}>
								<Box sx={{ pl: 7 }}>
									<Typography
										variant='h4'
										sx={{ mb: 1 }}>
										{item.desTitle}
									</Typography>
									<ul>
										{item.description.map((item, idx) => (
											<li key={idx}>
												<Typography
													key={idx}
													variant='h5'>
													{item}
												</Typography>
											</li>
										))}
									</ul>
								</Box>
								<RoundedButton
									fullWidth
									onClick={() => {
										if (!isLoginedUser) {
											alert('로그인이 필요한 메뉴입니다.');
											navigateLogin();
										} else {
											item.title === '다회권'
												? navigateMultiUseTickets()
												: navigateCommutationTickets();
										}
									}}>
									{'구매 페이지로 이동'}
								</RoundedButton>
							</Stack>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Stack>
	);
};
