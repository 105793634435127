import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import {
	AdminStoreInfoState,
	BaseReceiptPayloadType,
	OrderState,
	PrintReceiptState,
} from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogContent, Stack, debounce } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

type OrderReceiptDialogProps = XlDialogProps & {
	orderWithDetail: OrderDetailType;
};
export const OrderReceiptDialog = ({
	open,
	closeDialog,
	orderWithDetail,
}: OrderReceiptDialogProps) => {
	const { handleClick주문영수증인쇄, handleClick환불영수증인쇄, handleClick간이영수증인쇄 } =
		use주문영수증수동인쇄({
			orderWithDetail,
		});

	return (
		<WithDialog.XlDialog
			dialogTitle={'주문 영수증 인쇄'}
			open={open}
			closeDialogAction='닫기'
			closeDialog={closeDialog}>
			<DialogContent>
				<Stack
					direction={'row'}
					spacing={1}>
					<Button
						variant='outlined'
						disabled={!orderWithDetail.completed}
						onClick={() => {
							handleClick주문영수증인쇄();
							closeDialog && closeDialog();
						}}>
						주문 영수증 재인쇄
					</Button>
					<Button
						variant='outlined'
						disabled={!orderWithDetail.canceled}
						onClick={() => {
							handleClick환불영수증인쇄();
							closeDialog && closeDialog();
						}}>
						환불 영수증 재인쇄
					</Button>
					<Button
						variant='outlined'
						disabled={!orderWithDetail.completed}
						onClick={() => {
							handleClick간이영수증인쇄();
							closeDialog && closeDialog();
						}}>
						간이 영수증 인쇄
					</Button>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};

const use주문영수증수동인쇄 = ({ orderWithDetail }: { orderWithDetail: OrderDetailType }) => {
	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
	const storeInfoList = data ?? [];
	const storeInfo = storeInfoList.find((storeInfo) => storeInfo.id === orderWithDetail.storeId);

	if (!storeInfo) throw Error('개발 에러 - storeInfo 검토');

	const 주문영수증재출력 = PrintReceiptState.use영수증수동출력({ receiptType: 'ORDER' });
	const 환불영수증재출력 = PrintReceiptState.use영수증수동출력({ receiptType: 'CANCEL' });
	const 간이영수증출력 = PrintReceiptState.use간이영수증출력();

	const handleClick주문영수증인쇄 = debounce(async () => {
		await 주문영수증재출력({
			order: orderWithDetail,
			payments: orderWithDetail.payments,
		});
	}, 100);

	const handleClick간이영수증인쇄 = debounce(async () => {
		await 간이영수증출력({
			order: orderWithDetail,
			payments: orderWithDetail.payments,
		});
	}, 100);

	const handleClick환불영수증인쇄 = debounce(async () => {
		await 환불영수증재출력({
			order: orderWithDetail,
			payments: orderWithDetail.payments,
		});
	}, 100);

	return {
		// 취소도 포함
		handleClick주문영수증인쇄,
		handleClick환불영수증인쇄,
		handleClick간이영수증인쇄,
	};
};
