import { ipcCommonCommandApiName } from '@kinderlabs-pos/ipc/common';
import { useEffect } from 'react';

let keyboardEvent: any;
const ElectronDebugToggle = () => {
	useEffect(() => {
		keyboardEvent = (event: KeyboardEvent) => {
			if (event.key === 'F12') {
				//F12
				//메인프로세스로 toggle-debug 메시지 전송 (디버그 툴 토글시켜라)
				window &&
					window[ipcCommonCommandApiName] &&
					window[ipcCommonCommandApiName].sendCommandMessage({ key: 'toggle-debug' });
			}
		};

		window.addEventListener('keyup', keyboardEvent);

		return () => {
			keyboardEvent && window.removeEventListener('keyup', keyboardEvent);
		};
	}, []);

	return null;
};

const registerUpdateAvailableListener = (callback: (updateAvailable: boolean) => void) => {
	if (window && window[ipcCommonCommandApiName])
		window[ipcCommonCommandApiName].regsterUpdateAvailableListener((updateAvailable) => {
			callback(updateAvailable);
		});
};

const checkForUpdate = () =>
	window && window[ipcCommonCommandApiName] && window[ipcCommonCommandApiName].checkForUpdate();

const unregisterUpdateAvailableListener = () =>
	window &&
	window[ipcCommonCommandApiName] &&
	window[ipcCommonCommandApiName].unregsterUpdateAvailableListener();
const closeApp = () => {
	window &&
		window[ipcCommonCommandApiName] &&
		window[ipcCommonCommandApiName].sendCommandMessage({ key: 'close-app' });
};

const minimizeApp = () => {
	window &&
		window[ipcCommonCommandApiName] &&
		window[ipcCommonCommandApiName].sendCommandMessage({ key: 'minimize-window' });
};
export const ReactCommonCommandIPC = {
	closeApp,
	minimizeApp,
	checkForUpdate,
	registerUpdateAvailableListener,
	unregisterUpdateAvailableListener,
	ElectronDebugToggle,
};
