import { MemoAPI } from '@kinderlabs-pos/apis/pos';
import { MemoStatusType, MemoTargetType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { QueryClient } from '../QueryClient';
import { MemoAction } from './MemoAction';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { createQueryKeys } from '@lukemorales/query-key-factory';

type MemoListParamsType = {
	// from: Dayjs;
	// to: Dayjs;
	status?: MemoStatusType;
	targetType?: MemoTargetType;
	storeIdList?: number[];
	search?: string;
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
};

const memoKeys = createQueryKeys('memo', {
	page: ({ params }: { params: MemoListParamsType }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				return await MemoAPI.getMemoList({
					storeIdList: params.storeIdList || storeIdListForManager,
					search: params.search,
					status: params.status,
					targetType: params.targetType,
					from: params.from,
					to: params.to,
					pageable: Pageable.getPageable(params),
				});
			},
		};
	},
	monitor: ({ params }: { params: Pick<MemoListParamsType, 'storeIdList' | 'from' | 'to'> }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				return await MemoAPI.getMemoListForMonitoring({
					storeIdList: params.storeIdList || storeIdListForManager,
					date: params.from,
					// params.to 가 씹히고 있음
				});
			},
		};
	},
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: memoKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: memoKeys._def });
};

export const MemoState = {
	keys: memoKeys,
	action: MemoAction,
	invalidateQueries: invalidateQueries,
};
