import { RoundedButton, RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Stack } from '@mui/material';
import { PageHeader } from 'apps/vaunce-app/src/components/PageHeader';
import { vaunceAppFormiks } from 'apps/vaunce-app/src/utils/useFormik';
import { FieldArray, FormikProvider } from 'formik';
import React from 'react';
import { useVaunceAppRouter } from '../../routes';
import { NewRegisterApplicantInfo } from './NewRegisterApplicantInfo';
import { NewRegisterCenterSelect } from './NewRegisterCenterSelect';
import { NewRegisterFacilityUsers } from './NewRegisterFacilityUsers';
import { NewRegisterRadioAvaliableType } from './NewRegisterRadioAvailableType';
import { loggingEvent } from 'apps/vaunce-app/src/utils/firebaseAnalytics';

//TODO 본인 확인
export interface FacilityUserType {
	id?: number;
	birth: string;
	gender: string;
	name: string;
	relation: number;
}

export interface ICreateFacilityPageProps {}
const NewRegisterStepOnePage: React.FC<ICreateFacilityPageProps> = ({}) => {
	const { navigateFacilityRegister } = useVaunceAppRouter();
	const { useFacilityRegisterFormik } = vaunceAppFormiks();
	const formik = useFacilityRegisterFormik();
	const { isSubmitting, handleSubmit, isValid, errors } = formik;

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<form onSubmit={handleSubmit}>
				<PageHeader title='시설 이용 등록' />
				<Stack
					flex={1}
					overflow={'auto'}
					spacing={4}>
					{/* 센터 선택 */}
					<NewRegisterCenterSelect formik={formik} />

					{/* 신청자 */}
					<NewRegisterApplicantInfo />

					{/* // 이용 여부 선택 */}
					<NewRegisterRadioAvaliableType formik={formik} />

					{/* 시설 이용자 */}
					<Stack spacing={2}>
						<FormikProvider value={formik}>
							<FieldArray
								name='userArray'
								render={(arrayHelpers) => {
									return (
										<NewRegisterFacilityUsers
											formik={formik}
											arrayHelpers={arrayHelpers}
										/>
									);
								}}
							/>
						</FormikProvider>
					</Stack>

					<Stack
						direction={'row'}
						spacing={2}
						sx={{ pb: 1 }}>
						<RoundedButton
							sx={{ flex: 1 }}
							onClick={() => {
								loggingEvent(
									`시설 이용 등록 STEP 1 > 취소`,
									'route',
									'시설 이용 등록 STEP 1 > 취소'
								);
								navigateFacilityRegister();
							}}>
							취소
						</RoundedButton>
						<RoundedSubmitButton
							data-cy='RegisterSubmitBtn'
							// disabled={!isValid}
							type={'submit'}
							isSubmitting={isSubmitting}
							onClick={() => {
								if (errors.userArray) {
									let msg: any = '';
									for (const user of errors.userArray) {
										if (user) {
											msg = msg + Object.values(user).join('\n') + '\n';
										}
									}
									alert(msg);
								}
								loggingEvent(
									`시설 이용 등록 STEP 1 > 다음`,
									'route',
									'시설 이용 등록 STEP 1 > 다음'
								);
							}}
							text={'다음'}
						/>
					</Stack>
				</Stack>
			</form>
		</Stack>
	);
};

export default NewRegisterStepOnePage;
