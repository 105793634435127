import { WarningOutlined } from '@ant-design/icons';
import { GuestVisitState, OrderState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, ButtonProps, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

export const CartActionResetButton = (props: Omit<ButtonProps, 'onClick'>) => {
	const [order, dispatchOrder] = useAtom(OrderState.value);
	const customConfirm = useConfirm();
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const cancelGV = GuestVisitState.actions.useCancelMutation();
	const dispatchCart = OrderState.cart.useDispatcher();

	const handle초기화 = () => {
		if (order.id) {
			customConfirm(
				<Typography
					color={'error'}
					variant={'h4'}>
					<WarningOutlined />
					{' 주문목록, 결제목록을 초기화하고 현재 고객 응대를 종료하시겠습니까?'}
				</Typography>,
				() => {
					if (guestMemberInfo && guestMemberInfo.guestVisitId)
						cancelGV.mutate(
							{ guestVisitId: guestMemberInfo.guestVisitId },
							{ onSuccess: () => dispatchOrder({ type: 'CLEAR' }) }
						);
					else dispatchOrder({ type: 'CLEAR' });
				}
			);
		} else {
			customConfirm('주문 목록을 초기화하시겠습니까?', () => {
				dispatchCart({ type: 'CLEAR' });
			});
		}
	};

	return (
		<Button
			{...props}
			onClick={handle초기화}
		/>
	);
};
