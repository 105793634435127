import { ListProps, List as MuiList, ListItem, ListItemButton } from '@mui/material';

type OverridedTableType = React.FC<ListProps> & {
  Item: typeof ListItem
  ItemButton: typeof ListItemButton
};

export const List: OverridedTableType = (props) => <MuiList {...props} />
List.Item = ListItem
List.ItemButton = ListItemButton
