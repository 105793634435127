import { posImageAxiosClient } from '@kinderlabs-pos/apis/axios';

export const uploadVendorOrderFilesAsync =
	(rootUrl: string) =>
	async ({ id, fileData }: { id: number; fileData: FormData }) => {
		return (
			await posImageAxiosClient.put(`${rootUrl}/vendor-order/file`, fileData, {
				params: {
					id: id,
				},
			})
		).data;
	};
