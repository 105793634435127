import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	DialogContent,
	DialogProps,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import React from 'react';
import { TermsOfUseData } from '../../TermsOfUsePage/termsOfUseData';

export interface IServiceAgreeDialogProps extends DialogProps {
	closeDialog: () => void;
}
export const ServiceAgreeDialog: React.FC<IServiceAgreeDialogProps> = ({ open, closeDialog }) => {
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			fullWidth
			maxWidth={'lg'}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}>
			<DialogContent>
				<Stack>
					<Stack spacing={2}>
						<Typography
							color={vaunceColors.lightPink}
							variant='h4'>
							이용약관
						</Typography>

						<Divider />
						<Typography variant='h4'>
							㈜바운스의 모바일 서비스 및 멤버십 프로그램 이용과 관련하여 필요한 사항을 규정합니다.
						</Typography>
						<Stack mt={2}>
							{TermsOfUseData.map((item, idx) => (
								<Stack
									key={idx}
									spacing={2}>
									<Typography
										variant='h4'
										fontWeight={700}>
										{item.title}
									</Typography>
									{item.contents.map((item, idx) => (
										<Stack
											spacing={1}
											key={idx}>
											<Typography variant='h4'>{item.subtitle}</Typography>
											{item.type === 'text' ? (
												<Typography variant='h4'>{item.actualContent[0].detail}</Typography>
											) : (
												<ol style={{ padding: '1rem' }}>
													{item.actualContent.map((item, idx) => (
														<>
															<Typography variant='h4'>
																<li key={idx}>{item.detail}</li>
															</Typography>
															{item.subDetail && (
																<ol style={{ padding: '1rem' }}>
																	{item.subDetail.map((item) => (
																		<Typography variant='h4'>
																			<li>{item}</li>
																		</Typography>
																	))}
																</ol>
															)}
														</>
													))}
												</ol>
											)}
										</Stack>
									))}
								</Stack>
							))}
							<Typography variant='h4'>[부칙] 본 약관은 2021년 12월 1일부터 시행합니다.</Typography>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
