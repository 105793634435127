import { AdminCmtInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { CmtInfoListFilterType, CmtInfoType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { QueryClient } from '../QueryClient';

const cmtInfo = 'cmtInfo';
const searchOptionAtom = atom<CmtInfoListFilterType>({
	page: 0,
	size: 10,
	search: undefined,
	sort: undefined,
	days: undefined,
	ddiziInfoId: undefined,
});
const selectedIdiceAtom = atomWithReset<number[]>([]);

const useCmtListQuery = (param: CmtInfoListFilterType) => {
	const resetSelection = useResetAtom(selectedIdiceAtom);

	const result = useQuery({
		queryKey: [cmtInfo, 'list', param],
		queryFn: async () => {
			const res = await AdminCmtInfoApis.getList(param);
			return res;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		resetSelection();
	}, [result.data]);

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCmtInfoListInStoreQuery = () => {
	const resetSelection = useResetAtom(selectedIdiceAtom);

	const result = useQuery({
		queryKey: [cmtInfo, 'list', 'instore'],
		queryFn: async () => {
			const res = await AdminCmtInfoApis.getListInStore();
			return res;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		resetSelection();
	}, [result.data]);

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCmtInfoDetailQuery = (id?: number) => {
	const result = useQuery({
		queryKey: [cmtInfo, 'detail', id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminCmtInfoApis.getListDetail(id);
			return res;
		},
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCmtInfoDetailHistoryQuery = (id?: number) => {
	const result = useQuery({
		queryKey: [cmtInfo, 'detail', 'history', id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminCmtInfoApis.getListHistory(id);
			return res;
		},
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCreateCmtInfo = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: Omit<CmtInfoType, 'id'>) => {
			await AdminCmtInfoApis.createInfo(input);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([cmtInfo]);
			},
		}
	);
};

const useUpdateCmtInfo = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: CmtInfoType) => {
			await AdminCmtInfoApis.updateInfo(input);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([cmtInfo, 'detail']);
			},
		}
	);
};

const useDeleteCmtInfoList = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: number[]) => {
			await AdminCmtInfoApis.deleteInfoList({ cmtList: input });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([cmtInfo]);
			},
		}
	);
};

export const AdminCmtInfoState = {
	searchOption: searchOptionAtom,
	selectedIdice: selectedIdiceAtom,
	useGetListQuery: useCmtListQuery,
	useGetListInStoreQuery: useCmtInfoListInStoreQuery,
	useDetailQuery: useCmtInfoDetailQuery,
	useDetailHistoryQuery: useCmtInfoDetailHistoryQuery,
	useCreate: useCreateCmtInfo,
	useUpdate: useUpdateCmtInfo,
	useDelete: useDeleteCmtInfoList,
};
