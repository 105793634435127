import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { PosTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { PrintReceiptState, StoreInfoState } from '@kinderlabs-pos/state';
import { FlexBox } from '@kinderlabs-pos/ui-atoms';
import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import { usePosRouter } from '../../pages/routes';
import { MainMenuList } from '../MainMenuList';
import { ParkingDialogButton } from './ParkingDialog';
import { PosSubToolbar } from './PosSubToolbar';
import { WaitingIndicator } from './WaitingIndicator';
import { BarcodeScannerButton } from './buttons/BarcodeScannerButton';
import { CashBoxButton } from './buttons/CashBoxButton';
import { DesktopButton } from './buttons/DesktopButton';
import { NetworkButton } from './buttons/NetworkButton';
import { ReceiptPrintButton } from './buttons/ReceiptPrintButton';
import { ReceiptToggleButton } from './buttons/ReceiptToggleButton';

export interface IPosHeaderProps {
	disabled?: boolean;
	canLeave: () => Promise<boolean>;
}
export const PosHeader = ({ canLeave, disabled = false }: IPosHeaderProps) => {
	const { receiptPrinterList, printMode, posType, name } = useAtomValue(StoreInfoState.curPosInfo);

	const { navigatePosMain } = usePosRouter();
	const handleClickHomeButton = async () => {
		if (await canLeave()) navigatePosMain();
	};
	const handleClick금전함열기 = PrintReceiptState.useSendOpenBox({
		receiptPrinterList,
		printMode,
	});

	return (
		<>
			<AppBar position='absolute'>
				<Toolbar style={{ paddingLeft: 8, paddingRight: 8 }}>
					<Box
						pl={1}
						pr={3}
						onClick={handleClickHomeButton}
						sx={{ cursor: 'pointer' }}>
						<Typography
							color={'white'}
							variant={'subtitle1'}>
							{`${PosTypeLabel[posType]} ${name}`}
						</Typography>
					</Box>
					<FlexBox
						flex={1}
						alignItems={'center'}
						sx={{ ...(disabled && { pointerEvents: 'none' }) }}>
						<WaitingIndicator disabled={disabled} />
					</FlexBox>
					<FlexBox
						gap={2}
						mx={1}>
						<BarcodeScannerButton />
						<DesktopButton
							onClick={() => {
								ReactCommonCommandIPC.minimizeApp();
							}}
						/>
						<ReceiptToggleButton />
						<ReceiptPrintButton />
						<ParkingDialogButton />
						<CashBoxButton
							onClick={() => {
								handleClick금전함열기();
							}}
						/>
						<NetworkButton />
					</FlexBox>
					<MainMenuList
						cannotNavigate={disabled}
						sx={{ fill: 'white' }}
					/>
				</Toolbar>
				<PosSubToolbar />
			</AppBar>
			<DummyToolbarForPadding />
		</>
	);
};

const DummyToolbarForPadding = styled(Box)({
	height: 72,
});
