import { Button, Stack, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { mainPath } from '../pages/routes';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

interface PosMainFallbackComponentProps extends FallbackProps {}
export const PosMainFallbackComponent = ({
	error,
	resetErrorBoundary,
}: PosMainFallbackComponentProps) => {
	const { pathname } = useLocation();

	useEffect(() => {
		try {
			Sentry.withScope((scope) => {
				// scope.setTransactionName('POS 에러화면');
				scope.setTags({
					Type: '에러화면',
					Where: 'POS',
				});
				Sentry.captureMessage('POS 에러화면 진입');
				Sentry.captureException(error);
			});
		} finally {
		}
	}, []);

	return (
		<Stack
			height={'100vh'}
			width={'100vw'}
			direction={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			spacing={1}>
			<Typography
				color={'text.primary'}
				variant='h1'>
				{'네트워크 에러입니다.'}
			</Typography>
			<Stack
				direction={'row'}
				spacing={1}>
				<Button
					variant={'contained'}
					color={'error'}
					onClick={() => window.location.reload()}>
					로그인 화면으로
				</Button>
				{pathname.includes(mainPath) && (
					<Button
						variant={'contained'}
						color={'primary'}
						onClick={resetErrorBoundary}>
						메인 화면으로
					</Button>
				)}
			</Stack>
		</Stack>
	);
};
