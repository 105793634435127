import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorCommandType, VendorDetailInfoType } from '@kinderlabs-pos/shared-data-type';

export const createVendorInfoAsync =
	(rootUrl: string) => async (data: Omit<VendorCommandType, 'id'>) =>
		(
			await posAxiosClient.post<VendorCommandType>(`${rootUrl}/vendor`, {
				...data,
			})
		).data;
