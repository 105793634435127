import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, SearchBox } from '@kinderlabs-pos/ui-components';
import { DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { ReactNode, Suspense, useState } from 'react';
import { PosCouponDialogContents } from './PosCouponDialogContents';

export interface IWithPosCouponDialogProps {
	children: (onClick: () => void) => ReactNode;
	closeETCDialog: () => void;
}
export const WithPosCouponDialog: React.FC<IWithPosCouponDialogProps> = ({
	children,
	closeETCDialog,
}) => {
	const [keyword, setKeyword] = useState<string | undefined>(undefined);

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<WithDialog.XlDialog
					dialogTitle='POS 쿠폰 사용'
					open={open}>
					<DialogContent sx={{ width: 720, height: 520 }}>
						<Stack
							direction='column'
							spacing={1}
							height='100%'
							flex={1}>
							<Stack
								direction='column'
								spacing={1}>
								<PosCouponSearchBox
									keyword={keyword || null}
									setSearch={setKeyword}
								/>
								<Typography
									color={'text.secondary'}
									variant={'body2'}
									textAlign={'center'}>
									{'POS 쿠폰 번호 혹은 QR 코드를 입력하세요.'}
								</Typography>
							</Stack>
							<Suspense fallback={<FullSizeCircularProgress />}>
								<PosCouponDialogContents
									keyword={keyword}
									onClose={() => {
										closeDialog();
										closeETCDialog();
									}}
								/>
							</Suspense>
						</Stack>
					</DialogContent>
					<DialogActions />
				</WithDialog.XlDialog>
			)}>
			{children}
		</WithDialog>
	);
};

const PosCouponSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			width={220}
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};
