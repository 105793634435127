import { rootUrl } from '../../const';
import { createWarehouseOrderAsync } from './createWarehouseOrderAsync';
import { deleteWarehouseOrderFileInfoAsync } from './deleteWarehouseOrderFileInfoAsync';
import { getDetailWarehouseOrderInfoAsync } from './getDetailWarehouseOrderInfoAsync';
import { getWarehouseOrderDownloadUrl } from './getWarehouseOrderDownloadUrl';
import { getWarehouseOrderInfoListAsync } from './getWarehouseOrderInfoListAsync';
import { updateWarehouseOrderModifyStatusAsync } from './updateWarehouseOrderModifyStatusAsync';
import { updateWarehouseOrderStatusAsync } from './updateWarehouseOrderStatusAsync';
import { uploadWarehouseOrderFilesAsync } from './uploadWarehouseOrderFilesAsync';

export const AdminWarehouseOrderApis = {
	getWarehouseOrderList: getWarehouseOrderInfoListAsync(rootUrl),
	getDetailWarehouseOrderInfo: getDetailWarehouseOrderInfoAsync(rootUrl),
	createWarehouseOrder: createWarehouseOrderAsync(rootUrl),
	uploadWarehouseOrderFiles: uploadWarehouseOrderFilesAsync(rootUrl),
	deleteWarehouseOrderFiles: deleteWarehouseOrderFileInfoAsync(rootUrl),
	getDownloadUrl: getWarehouseOrderDownloadUrl(rootUrl),
	updateWarehouseOrderStatus: updateWarehouseOrderStatusAsync(rootUrl),
	updateWarehouseOrderModifyStatus: updateWarehouseOrderModifyStatusAsync(rootUrl),
	// 통합본
};
