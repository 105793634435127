import { AdminPosBoardInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	PosBoardEmptyItemType,
	PosBoardInfoType,
	PosBoardItemType,
} from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { atom } from 'jotai';
import { QueryClient } from '../../QueryClient';
import { PosBffApi } from '@kinderlabs-pos/apis/pos';

const productPrice = atom<number>(0);

const posBoardQueryKey = ['posBoardInfo'];
const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries(posBoardQueryKey);
};

const usePosBoardDetailInfoQuery = (posBoardId?: number) => {
	const result = useQuery({
		queryKey: [...posBoardQueryKey, 'detail', posBoardId],
		queryFn: async () => {
			if (!posBoardId) throw Error;

			const res = await AdminPosBoardInfoApis.getDetail({ id: posBoardId });
			return res;
		},
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const usePosBoardInfoCreate = () => {
	return QueryClient.useMutation(
		async (input: Omit<PosBoardInfoType, 'id' | 'items'> & { storeId: number; index?: number }) => {
			await AdminPosBoardInfoApis.create(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const usePosBoardInfoUpdate = () => {
	return QueryClient.useMutation(
		async (input: Omit<PosBoardInfoType, 'items'>) => {
			await AdminPosBoardInfoApis.update(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const usePosBoardItemInfoUpdate = () => {
	return QueryClient.useMutation(
		async (input: {
			item: Omit<PosBoardItemType, 'productInfo'> | Omit<PosBoardEmptyItemType, 'productInfo'>;
			boardId: number;
			index: number;
		}) => {
			await AdminPosBoardInfoApis.updateItem(input);
		},
		{
			onSuccess: () => {
				QueryClient.origin.invalidateQueries(['productInfo']);
				invalidateQueries();
			},
		}
	);
};

const usePosBoardItemBulkInfoUpdate = () => {
	return QueryClient.useMutation(
		async (input: {
			itemList: (
				| Omit<PosBoardItemType, 'productInfo'>
				| Omit<PosBoardEmptyItemType, 'productInfo'>
			)[];
			boardId: number;
		}) => {
			await AdminPosBoardInfoApis.updateItemBulk(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const usePosBoardItemOrderUpdate = (storeId: number) => {
	const queryClient = QueryClient.useQueryClient();

	const mutate = QueryClient.useMutation(
		async ({
			posBoardList,
			triggerPromise,
		}: {
			storeId: number;
			posBoardList: PosBoardInfoType[];
			triggerPromise: Promise<boolean>;
		}) => {
			const result = await triggerPromise;

			if (result)
				return await AdminPosBoardInfoApis.updateItemOrder({
					storeId,
					posBoardIdsList: posBoardList.map((board) => board.id),
				});
			else {
				// 에러보다 좋은 방법이 있다면 써봅시다 ~
				throw new Error('순서바꾸기 취소. 문제 없는 에러.');
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(posBoardQueryKey);
			},
			/**
			 *  참고
			 *  https://tanstack.com/query/v4/docs/guides/optimistic-updates
			 *
			 * */
			onMutate: async (newData) => {
				await queryClient.cancelQueries({ queryKey: [...posBoardQueryKey, 'in-store', storeId] });

				const previousData = queryClient.getQueryData<PosBoardInfoType[]>([
					...posBoardQueryKey,
					'in-store',
					storeId,
				]);
				queryClient.setQueryData([...posBoardQueryKey, 'in-store', storeId], newData.posBoardList);

				return { previousData };
			},
			onError: (err, data, context) => {
				//@ts-ignore
				if (context?.previousData)
					queryClient.setQueryData(
						[...posBoardQueryKey, 'in-store', storeId],
						//@ts-ignore
						context.previousData
					);
			},
		}
	);

	return mutate;
};

const usePosBoardItemInfoDelete = () => {
	return QueryClient.useMutation(
		async (input: { boardId: number; index: number }) => {
			await AdminPosBoardInfoApis.updateItem({
				...input,
				item: {
					label: null,
					productInfoId: null,
					color: null,
				},
			});
		},
		{
			onSuccess: () => {
				QueryClient.origin.invalidateQueries(['productInfo']);
				invalidateQueries();
			},
		}
	);
};

const usePosBoardInfoDelete = () => {
	return QueryClient.useMutation(
		async (id: number) => {
			await AdminPosBoardInfoApis.delete({ posBoardId: id });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const usePosBoardUpdateWithOtherStore = () => {
	return QueryClient.useMutation(
		async (input: {
			targetStoreId: number;
			targetBoardId: number;
			sourceBoardId: number;
			productIdList: number[];
		}) => {
			await AdminPosBoardInfoApis.updatePosBoardWithOtherStore(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
				QueryClient.origin.invalidateQueries(['productInfo']);
			},
		}
	);
};

/**
 * PosBffApi 전용
 *
 * @param storeId
 * @returns
 */
const usePosBoardInPos = ({ storeId, refetch }: { storeId: number; refetch?: boolean }) => {
	const result = useQuery({
		queryKey: [...posBoardQueryKey, 'store', storeId],
		queryFn: async () => {
			if (!storeId) throw Error;

			const res = await AdminPosBoardInfoApis.getListInStore({ storeId });
			return res;
		},
		refetchOnWindowFocus: refetch,
		refetchOnMount: refetch,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const usePosBoardListCompareTwoStore = ({
	targetStoreId,
	sourceStoreId,
	refetch,
}: {
	targetStoreId: number;
	sourceStoreId: number;
	refetch?: boolean;
}) => {
	const result = useQuery({
		queryKey: [...posBoardQueryKey, 'store', targetStoreId, sourceStoreId],
		queryFn: async () => {
			if (!targetStoreId || !sourceStoreId) throw Error;

			const res = await AdminPosBoardInfoApis.getListCompareTwoStore({
				targetStoreId,
				sourceStoreId,
			});
			return res;
		},
		refetchOnWindowFocus: refetch,
		refetchOnMount: refetch,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

export const AdminPosBoardInfoState = {
	priceForDiscount: productPrice,
	usePosBoardInPos,
	usePosBoardCompare: usePosBoardListCompareTwoStore,
	useDetailQuery: usePosBoardDetailInfoQuery,
	useCreate: usePosBoardInfoCreate,
	useUpdate: usePosBoardInfoUpdate,
	useDelete: usePosBoardInfoDelete,
	useUpdateItem: usePosBoardItemInfoUpdate,
	useUpdateItemBulk: usePosBoardItemBulkInfoUpdate,
	useUpdateItemOrder: usePosBoardItemOrderUpdate,
	useUpdatePosBoardWithOterStore: usePosBoardUpdateWithOtherStore,
	useDeleteItem: usePosBoardItemInfoDelete,
};
