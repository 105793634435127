import { CartLineInfoType, CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { CartListItem, CartListItemButton } from '@kinderlabs-pos/ui-atoms';
import { ListItem, Stack, Typography, TypographyProps } from '@mui/material';
import { ProductBellItemProps } from './ProductBellItemProps';
import { ProductDefaultItemProps } from './ProductDefaultItemProps';
import { ProductExcessChargeItemProps } from './ProductExcessChargeItemProps';
import { ProductOptionItemProps } from './ProductOptionItemProps';
import { ProductTicketItemProps } from './ProductTicketItemProps';
import { useMemo } from 'react';

export interface IProductItemProps {
	cartLine: CartLineInfoType;
	selectOption?: {
		selected: boolean;
		select: () => void;
		disabled?: boolean;
	};
	highlightOption?: 'quantity-increase' | 'quantity-decrease' | 'canceled';
	quantityChange?: number;
}

export const ProductItem = (props: IProductItemProps) => {
	const display = CartLineTypeRules.displayInCartTable[props.cartLine.type];

	switch (display) {
		case 'options':
			return (
				<ProductItem.Default
					{...props}
					productItemProps={ProductOptionItemProps}
				/>
			);
		case 'ticket':
			return (
				<ProductItem.Default
					{...props}
					productItemProps={ProductTicketItemProps}
				/>
			);
		case 'excess-charge':
			return (
				<ProductItem.Default
					{...props}
					productItemProps={ProductExcessChargeItemProps}
				/>
			);
		case 'bell':
			return (
				<ProductItem.Default
					{...props}
					productItemProps={ProductBellItemProps}
				/>
			);
		default:
			return (
				<ProductItem.Default
					{...props}
					productItemProps={ProductDefaultItemProps}
				/>
			);
	}
};

export type ProductItemPropsType = {
	CartListItemProp: {
		size?: 'small';
		color?: string;
	};
	nameTypographyProps: TypographyProps;
	subNameTypographyProps: TypographyProps;
	quantityTypographyProps: TypographyProps;
	quantityChangeTypographyProps: TypographyProps;
	priceTypographyProps: TypographyProps;
	unitPriceTypographyProps: TypographyProps;
};

ProductItem.Default = ({
	cartLine,
	selectOption,
	highlightOption,
	quantityChange,
	productItemProps,
}: IProductItemProps & { productItemProps: ProductItemPropsType }) => {
	const cannotSelectInCart = CartLineTypeRules.cannotSelectInCart[cartLine.type];
	const { quantity } = cartLine;

	const ItemRender = useMemo(
		() => (!!selectOption && !cannotSelectInCart ? CartListItemButton : CartListItem),
		[cannotSelectInCart]
	);

	return (
		<ItemRender
			{...productItemProps.CartListItemProp}
			highlight={highlightOption}
			// selectable={!!selectOption && !cannotSelectInCart}
			disabled={selectOption?.disabled}
			clicked={selectOption?.selected}
			onClick={selectOption?.select}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				width={'100%'}
				spacing={1}>
				<Stack
					flex={1}
					sx={{ overflow: 'hidden' }}>
					<Typography
						{...productItemProps.nameTypographyProps}
						sx={{
							...productItemProps.nameTypographyProps.sx,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
						className={'name'}>
						{CartLineTypeRules.renderCartLineNameInTable(cartLine)}
					</Typography>
					<Typography
						{...productItemProps.subNameTypographyProps}
						className={'subname'}>
						{CartLineTypeRules.renderCartLineSubNameInTable(cartLine)}
					</Typography>
				</Stack>
				<Stack alignItems={'center'}>
					<Typography
						{...productItemProps.quantityTypographyProps}
						className={'quantity'}>{`x ${quantity}`}</Typography>
					{quantityChange && (
						<Typography
							{...productItemProps.quantityChangeTypographyProps}
							className={'quantitychange'}>
							{(quantityChange > 0 ? '+' : '') + quantityChange}
						</Typography>
					)}
				</Stack>
				<Stack>
					<Typography
						{...productItemProps.priceTypographyProps}
						textAlign={'right'}
						sx={{ minWidth: 100 }}
						className={'price'}>
						{`${numberWithCommasAndWon(quantity * cartLine.price)}`}
					</Typography>
					<Typography
						{...productItemProps.unitPriceTypographyProps}
						textAlign={'right'}
						className={'unitprice'}>
						{`개당 ${numberWithCommasAndWon(cartLine.price)}`}
					</Typography>
				</Stack>
			</Stack>
		</ItemRender>
	);
};
