import { AdminSignupDeviceInfoApis, AdminWaiverInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { DeviceType, WaiverInfoType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { DeviceTokenQueryState } from '../DeviceTokenQueryState';
import { QueryClient } from '../QueryClient';

const waiverInfoKeys = createQueryKeys('waiverInfo', {
	// signup device 에도 공통되는 것이지만
	waiverOrSignup: (storeId: number, deviceId: number, deviceType: DeviceType) => ({
		queryKey: [storeId, deviceId, deviceType],
		queryFn: async () => {
			if (deviceType === 'WAIVER') {
				const result = await AdminWaiverInfoApis.getWaiverInfoList({ storeId });

				// 특이케이스 처리 ; OTP 로그인한 경우
				if (deviceId === 0) {
					const otpResult = result.find((r) => r.waitingType === 'PHONE');
					if (otpResult) return otpResult;
					else throw Error('최소 하나의 핸드폰 번호 입력 WAITING 기기를 등록하세요');
				}
				return result.find((w) => w.id === deviceId);
			} else if (deviceType === 'SIGNUP')
				return await AdminSignupDeviceInfoApis.getSignupDeviceInfo({ storeId, deviceId });
			else return undefined;
		},
	}),
	getOne: (storeId?: number, deviceId?: number) => ({
		queryKey: [storeId, deviceId],
		queryFn: async () => {
			if (!storeId) return undefined;
			const result = await AdminWaiverInfoApis.getWaiverInfoList({ storeId });

			// 특이케이스 처리 ; OTP 로그인한 경우
			if (deviceId === 0) {
				const otpResult = result.find((r) => r.waitingType === 'PHONE');
				if (otpResult) return otpResult;
				else throw Error('최소 하나의 핸드폰 번호 입력 WAITING 기기를 등록하세요');
			}
			return result.find((w) => w.id === deviceId);
		},
	}),
	list: (storeId: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await AdminWaiverInfoApis.getWaiverInfoList({ storeId: storeId });
			return res;
		},
	}),
});

const useCreateWaiverInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<WaiverInfoType, 'id'>) => {
			await AdminWaiverInfoApis.createWaiverInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useUpdateWaiverInfo = () => {
	return QueryClient.useMutation(
		async (input: WaiverInfoType) => {
			return await AdminWaiverInfoApis.updateWaiverInfo(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useDeleteWaiverInfo = () => {
	return QueryClient.useMutation(
		async (waiverId: number) => {
			await AdminWaiverInfoApis.deleteWaiverInfoList({ idList: [waiverId] });
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteWaiverListInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await AdminWaiverInfoApis.deleteWaiverInfoList({ idList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: waiverInfoKeys._def });
};

export const AdminWaiverInfoState = {
	keys: waiverInfoKeys,

	useCreateWaiverInfo: useCreateWaiverInfo,
	useUpdateWaiverInfo: useUpdateWaiverInfo,
	useDeleteWaiverInfo: useDeleteWaiverInfo,
	useDeleteWaiverListInfo: useDeleteWaiverListInfo,
	invalidateQueries,
};
