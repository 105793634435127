import { Stack, Typography } from '@mui/material';
import { VaultcashHistory } from 'apps/pos-main/src/components/VaultcashHistory';

export const VaultcashHistoryTab = () => {
	return (
		<Stack height={'100%'}>
			<Stack flex={1}>
				<VaultcashHistory />
			</Stack>
			<Stack alignItems={'flex-end'}>
				<Typography
					variant='body2'
					color='error'>
					※ 직전일대비 과부족은 현재 영업일 과부족에 영향을 미치지 않습니다.
				</Typography>
			</Stack>
		</Stack>
	);
};
