import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CardPaymentType, Pageable, PaymentMethodType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const downloadAdminExcel =
	(rootUrl: string) =>
	async ({
		method,
		cardPaymentType,
		amount,
		bin,
		status,
		storeId,
		posId,
		from,
		to,
		search,
		pageable,
		filename,
	}: AdminExcelRequestType) => {
		const url = new URL(`${rootUrl}/excel/payment/all`);

		if (method) url.searchParams.append('method', method);
		if (cardPaymentType) url.searchParams.append('cardPayment', cardPaymentType);
		if (amount) url.searchParams.append('amount', `${amount}`);
		if (bin) url.searchParams.append('bin', bin);
		if (status) url.searchParams.append('status', status);
		if (storeId) url.searchParams.append('targetStoreId', `${storeId}`);
		if (posId) url.searchParams.append('targetPosId', `${posId}`);
		if (from) url.searchParams.append('from', from.format('YYYY-MM-DD HH:mm:ss'));
		if (to) url.searchParams.append('to', to.format('YYYY-MM-DD HH:mm:ss'));
		if (search) url.searchParams.append('search', search);

		window.open(url.href);
	};

export type AdminExcelRequestType = {
	filename: string;
	method?: PaymentMethodType;
	cardPaymentType?: CardPaymentType;
	amount?: number;
	bin?: string;
	status?: 'REFUND' | 'COMPLETE';
	storeId?: number;
	posId?: number;
	from: Dayjs;
	to: Dayjs;
	search?: string;
	pageable: Pageable;
	size?: number;
	sort?: Pageable['sort'];
};
