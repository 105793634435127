import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButtonProps, Typography } from '@mui/material';

// Icon button 들 전부 리팩토링 해야함 일단 급하게 작업
export interface IMembershipButtonProps extends Pick<IconButtonProps, 'onClick' | 'disabled'> {
	variant?: 'white' | 'default';
	iconSize?: 'default' | 'large';
}
export const MembershipButton: React.FC<IMembershipButtonProps> = ({
	onClick,
	disabled,
	variant = 'white',
	iconSize = 'default',
}) => {
	return (
		<IconTextButton
			onClick={onClick}
			disabled={disabled}
			variant={variant}
			iconSize={iconSize}
			icon={<AccountCircleIcon fontSize='small' />}
			label={<Typography>{disabled ? '비회원' : '회원'}</Typography>}
		/>
	);
};
