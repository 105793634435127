export type PageType<T> = {
	content: T[]; // 총 개수
	totalPages: number; // 총 페이지 개수
	totalElements: number; // 총 개수
	size: number; // pageSize
	number: number; // pageNumber
};

export const initialPageResponse = {
	content: [],
	totalPages: 0,
	totalElements: 0,
	size: 0,
	number: 0,
};

export type CursorPageType<T> = {
	content: T[];
	hasMore: boolean;
	startCursorId: string | number | null;
	empty: boolean;
};

export type SortType = {
	name: string;
	direction: 'asc' | 'desc';
};
export class Pageable<T extends string = string> {
	page: number = 0;
	size?: number;
	sort?: SortType = undefined;
	// sort: {
	//   name: string,
	//   direction: "asc" | "desc"
	// }[] = []
	static getPageable = <T extends string>({
		page,
		size,
		sort,
	}: {
		page?: number;
		size?: number;
		sort?: SortType;
	}) => {
		const pageable = new Pageable();

		pageable.page = page || 0;
		pageable.size = size;
		pageable.sort = sort;

		return pageable;
	};

	getSortQueryParam() {
		return { sort: this.sort ? `${this.sort.name},${this.sort.direction}` : undefined };
	}
	getPageableQueryParam() {
		return {
			size: this.size,
			page: this.page,
			...this.getSortQueryParam(),
			// sort: this.sort ? `${this.sort.name},${this.sort.direction}` : undefined
			// sort : this.sort.map(sort => `${sort.name},${sort.direction}`)
		};
	}
}

export const getPagedData = <T,>(data: T[], { page, size = 20 }: { page: number; size?: number }) =>
	data.filter((_, idx) => page * size <= idx && idx < (page + 1) * size);
