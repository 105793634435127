import dayjs from 'dayjs';
import { DdiziType } from '../DdiziType';
import { Pageable } from '../PageType';
import { BaseInfoType, BaseSearchInfoType } from './BaseInfoType';
import { AvailableType, DdiziInfoType } from './DdiziInfoType';
import { ExchangeInfoType } from './ExchangeInfoType';
import { ProductDiscountInfoType, getDiscountLabel } from './ProductDiscountInfoType';
import { ProductType } from './ProductInfoType';

export const posCouponTypeArray = ['DISCOUNT', 'PRODUCT'] as const;

export type PosCouponType = (typeof posCouponTypeArray)[number];

export const PosCouponTypeLabel: Record<PosCouponType, string> = {
	DISCOUNT: '할인',
	PRODUCT: '티켓 증정',
};

export const posCouponTargetTypeArray = ['ALLCENTER', 'CENTER'] as const;

export type PosCouponTargetType = (typeof posCouponTargetTypeArray)[number];

export const PosCouponTargetLabel: Record<PosCouponTargetType, string> = {
	ALLCENTER: '전매장',
	CENTER: '특정매장',
};

export type PosCouponExcelUploadType = {
	couponId: string | null;
	startDate: string | null;
	expiration: string | null;
};

export type CouponCodeInfo = {
	prefix: string;
	codeLength: number;
};

export type PosCouponInfoType = {
	id: number;
	name: string;
	stock: number;
	couponType: PosCouponType;
	discountCouponInfo?: ProductDiscountInfoType;
	couponStatusStatistics: CouponStatusStatistics;
	couponCodeInfo: CouponCodeInfo;
	usableForAllCenter: boolean;
	storeWhiteList: number[];
	isExpired: boolean;
	startDate: string;
	expiration: string;
	uploadStatus: 'COMPLETED' | 'PARTIALLY_COMPLETED' | 'NOT_STARTED';
} & (
	| {
			couponType: 'DISCOUNT';
			discountCouponInfo: ProductDiscountInfoType;
	  }
	| {
			couponType: 'PRODUCT';
			discountCouponInfo: undefined;
	  }
);

export type PosCouponProductInfoDTOType = {
	id: number;
	name: string;
	price: number;
	productType: ProductType;
	ddiziInfoDTO?: DdiziInfoType;
	exchangeInfoDTO?: ExchangeInfoType;
};

export type PosCouponSelectType = 'DISCOUNT' | 'PRODUCT';
export type PosCouponUploadStatusType = 'COMPLETED' | 'PARTIALLY_COMPLETED' | 'NOT_STARTED';
export type PosCouponInfoListFilterType = {
	page: number;
	size?: number;
	search?: string;
	sort?: Pageable['sort'];
	couponType?: PosCouponSelectType;
	uploadStatus?: PosCouponUploadStatusType;
	targetStoreId?: number;
};

export type PosCouponInfoSearchParamsType = BaseSearchInfoType & {
	couponType?: PosCouponSelectType;
	uploadStatus?: PosCouponUploadStatusType;
	targetStoreId?: string;
};

export type PosCouponDetailSearchParamsType = BaseSearchInfoType & {
	couponInfoId?: string;
	couponStatus?: PosCouponStatusType;
	isUsed?: string;
	startDate?: string;
	expiration?: string;
};

export type CouponStatusStatistics = {
	couponInfoId: number;
	notUsedQuantity: number;
	totalStock: number;
	usedQuantity: number;
};

export type PosCouponTicketType = {
	id: string;
	couponInfoId: number;
	status: PosCouponStatusType;
	useDTO: null | PosCouponUseDTO;
	registered?: Date;
	startDate?: Date;
	expiration?: Date;
	isExpired: boolean;
	guestUserForTicketDTO: null | PosCouponGestUserForTicketDTO;
};

export type PosCouponListFilterType = {
	page: number;
	size?: number;
	search?: string;
	sort?: Pageable['sort'];
	couponInfoId?: number;
	couponStatus?: PosCouponStatusType;
	isUsed?: boolean;
	startDate?: string;
	expiration?: string;
};

export type PosCouponSearchParamType = {
	page?: string;
	size?: string;
	search?: string;
	sortName?: string;
	direction?: string;
	couponInfoId?: string;
	couponStatus?: string;
	isUsed?: string;
	startDate?: string;
	expiration?: string;
};
type PosCouponUseDTO = {
	used: string;
	storeId: number;
	deviceId: number;
	orderId: string;
};
type PosCouponGestUserForTicketDTO = {
	name: string;
	telephone: string;
	memberId: number;
};

export type PosCouponStatusType = 'USABLE' | 'UNUSABLE' | 'COMPLETED' | 'DISCARDED';

export const isPosCouponAvailable: Record<PosCouponStatusType, boolean> = {
	USABLE: true,
	UNUSABLE: false,
	COMPLETED: false,
	DISCARDED: false,
};

export const PosCouponStatusLabel: Record<PosCouponStatusType, string> = {
	USABLE: '사용 가능',
	UNUSABLE: '사용 불가',
	COMPLETED: '사용 완료',
	DISCARDED: '폐기',
};

export const getExpiryDate = (row: PosCouponTicketType): string => {
	return row.startDate && row.expiration
		? `${dayjs(row.startDate).format('YYYY-MM-DD')} ~ ${dayjs(row.expiration).format('YYYY-MM-DD')}`
		: '미등록';
};

export const getUsedDate = (row: PosCouponTicketType): string =>
	row.useDTO ? dayjs(row.useDTO.used).format('YYYY-MM-DD HH:mm:ss') : '';

export function getCouponTypeLabel(row: PosCouponInfoType & BaseInfoType): string {
	if (row.couponType === 'DISCOUNT') {
		return getDiscountLabel({
			value: row.discountCouponInfo.value,
			type: row.discountCouponInfo.type,
		});
	} else return '티켓증정';
}
