import { Stack, styled, Typography } from '@mui/material';
import { PageHeader } from '../../components/PageHeader';
import { PrivacyTermData } from './PrivacyTermData';

const Typography장 = styled(Typography)(({ theme }) => ({
	fontWeight: 700,
	fontSize: 20,
}));

const BoldSpan = styled('span')(({ theme }) => ({
	fontWeight: 'bold',
}));

export const PrivacyTermPage = ({}) => {
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='개인정보처리방침' />
			<Stack
				spacing={2}
				sx={{ flex: 1, overflow: 'inherit' }}>
				<Typography variant='h4'>
					{
						'㈜바운스는(이하 ‘당사’) 회원의 개인정보를 매우 중요시 하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법을 준수하고 있습니다. 당사는 개인정보처리방침을 통하여 회원이 제공한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.'
					}
				</Typography>
				{PrivacyTermData.map((item, idx) => (
					<Stack
						key={idx}
						spacing={2}>
						<Typography
							variant='h4'
							fontWeight={700}>
							{item.title}
						</Typography>
						{item.contents.map((item, idx) => (
							<Stack
								spacing={1}
								key={idx}>
								<Typography variant='h4'>{item.subtitle}</Typography>
								{item.type === 'text' ? (
									<>
										{item.actualContent.map((item, idx) => (
											<Typography
												variant='h4'
												key={idx}>
												{item.detail}
											</Typography>
										))}
									</>
								) : (
									<ol>
										{item.actualContent.map((item, idx) => (
											<>
												<Typography
													variant='h4'
													key={idx}>
													<li>{item.detail}</li>
												</Typography>
												{item.subDetail ? (
													<ol>
														{item.subDetail.map((item, idx) => (
															<Typography
																key={idx}
																variant='h4'>
																<li>{item}</li>
															</Typography>
														))}
													</ol>
												) : (
													<></>
												)}
											</>
										))}
									</ol>
								)}
							</Stack>
						))}
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};
