import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminManagerInfoListFilterType,
	ManagerUserType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';

export const getManagerInfoListAsync =
	(rootUrl: string) =>
	async ({
		page,
		size,
		sort,
		authority,
		...options
	}: Omit<AdminManagerInfoListFilterType, 'storeId'> & { storeIdList?: number[] }) =>
		(
			await posAxiosClient.get<PageType<ManagerUserType>>(
				`${rootUrl}/auth-service/manager/member/${authority}/list`,
				{
					params: {
						...options,
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;
