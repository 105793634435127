import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

export interface ITicketExitCancelButtonProps extends IconButtonProps {}
export const TicketExitCancelButton: React.FC<ITicketExitCancelButtonProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <IconTextButton
      disabled={disabled}
      onClick={onClick}
      icon={<CancelPresentationIcon fontSize="small" />}
      label={<Typography>{'퇴장취소'}</Typography>}
    />
  );
};
