import {
	KitchenOrderStatusType,
	OrderStatusType,
	OrderType,
} from '@kinderlabs-pos/shared-data-type';
import {
	createSearchParams,
	Navigate,
	NonIndexRouteObject,
	useNavigate,
	Outlet,
} from 'react-router-dom';
import { DdiziManagementPage } from './DdiziManagementPage';
import { ExitPage } from './ExitPage';
import { GuestVisitPage } from './GuestVisitPage';
import { MainPageTemplate } from './MainPageTemplate';
import { MainPosPage } from './MainPosPage';
import { OrderPage } from './OrderPage';
import { PaymentManagementPage } from './PaymentManagementPage';
import { OrderPaymentPage } from './payments/OrderPaymentPage';
import { VaultcashCheckPage } from './VaultcashCheckPage';

import { PosLoginPage } from './PosLoginPage';
import { PosSettingsPage } from './PosSettingPage';
import { ReserveTicketsPage } from './ReserveTicketsPage';
import { SelectStorePage } from './SelectStorePage';
import { StoreClosePage } from './StoreClosePage';
import { StatisticsInPosPage } from './StatisticsInPosPage';
import { OrderEditResultPage } from './payments/OrderPaymentPage/OrderEditResultPage';
import { KitchenOrderPage } from './KitchenOrderPage';

type CustomRouteObject = Omit<NonIndexRouteObject, 'children' | 'path'> & {
	title?: string;
	children?: CustomRouteObject[];
	path: string;
	noHeader?: boolean;
	// useCheckBeforeLeave?: () => void
};

export const mainPath = 'main';
const introPath = 'intro';

export const mainRouteObject: CustomRouteObject = {
	path: '',
	element: <MainPosPage />,
};

export const settingRouteObject: CustomRouteObject = {
	path: 'settings',
	element: <PosSettingsPage />,
	title: 'POS 설정',
};

export const guestVisitsRouteObject: CustomRouteObject = {
	path: 'guestvisits',
	element: <GuestVisitPage />,
	title: '고객 목록',
};

export const reserveTicketsRouteObject: CustomRouteObject = {
	path: 'reserve-tickets',
	element: <ReserveTicketsPage />,
	title: '예약 관리',
};

export const ordersRouteObject: CustomRouteObject = {
	path: 'orders',
	element: <OrderPage />,
	title: '주문 내역',
};

export const kitchenOrderRouteObject: CustomRouteObject = {
	path: 'kitchen-orders',
	element: <KitchenOrderPage />,
	title: '주방 주문 목록',
};

export const paymentManagmentsRouteObject: CustomRouteObject = {
	path: 'payments-management',
	element: <PaymentManagementPage />,
	title: '결제 내역',
};

export const ddizisRouteObject: CustomRouteObject = {
	path: 'ddizis',
	element: <DdiziManagementPage />,
	title: '티켓 목록',
};

export const paymentsRouteObject: CustomRouteObject = {
	path: 'payments-order',
	element: <OrderPaymentPage />,
	title: '결제',
	noHeader: true,
};

export const paymentsOrderEditRouteObject: CustomRouteObject = {
	path: 'payments-order-edit',
	element: <OrderEditResultPage />,
	title: '결제',
	noHeader: true,
};

export const exitsRouteObject: CustomRouteObject = {
	path: 'exits',
	title: '퇴장 관리',
	element: <ExitPage />,
};

export const vaultcashCheckObject: CustomRouteObject = {
	path: 'vaultCashCheck',
	element: <VaultcashCheckPage />,
	title: '시재 관리',
};

export const storeCloseObject: CustomRouteObject = {
	path: 'storeClose',
	element: <StoreClosePage />,
	title: '영업 마감',
};

export const statisticsRouteObject: CustomRouteObject = {
	path: 'statistics',
	element: <StatisticsInPosPage />,
	title: '매출 통계',
};

export const mainRoutes = [
	mainRouteObject,
	settingRouteObject,
	guestVisitsRouteObject,
	reserveTicketsRouteObject,
	ordersRouteObject,
	paymentManagmentsRouteObject,
	ddizisRouteObject,
	paymentsRouteObject,
	paymentsOrderEditRouteObject,
	exitsRouteObject,
	vaultcashCheckObject,
	storeCloseObject,
	statisticsRouteObject,
	kitchenOrderRouteObject,
];

export const allRoutes: CustomRouteObject[] = [
	{
		path: '/',
		children: [
			{
				path: mainPath,
				element: <MainPageTemplate />,
				children: mainRoutes,
			},
			{
				path: introPath,
				children: [
					{
						path: 'login',
						element: <PosLoginPage />,
					},
					{
						path: 'select-store',
						element: <SelectStorePage />,
					},
				],
			},
			{
				path: '',
				element: (
					<Navigate
						to={`/${introPath}/login`}
						replace={true}
					/>
				),
			},
		],
	},
];

export const usePosRouter = () => {
	const navigate = useNavigate();

	return {
		navigatePosMain: () => {
			navigate(`/${mainPath}`);
		},
		navigate시재관리: () => {
			navigate(`/${mainPath}/${vaultcashCheckObject.path}`);
		},
		navigate세팅관리: () => {
			navigate(`/${mainPath}/${settingRouteObject.path}`);
		},
		navigate예약관리: () => {
			navigate(`/${mainPath}/${reserveTicketsRouteObject.path}`);
		},

		navigate대기관리: () => {
			navigate(`/${mainPath}/${guestVisitsRouteObject.path}`);
		},
		navigate티켓관리: () => {
			navigate(`/${mainPath}/${ddizisRouteObject.path}`);
		},
		navigate영업마감: () => {
			navigate(`/${mainPath}/${storeCloseObject.path}`);
		},
		navigate통계: () => {
			navigate(`/${mainPath}/${statisticsRouteObject.path}`);
		},
		navigate주문조회: (args?: { keyword?: string; status?: OrderType['status'] }) => {
			if (args) {
				const params = {
					keyword: args.keyword || '',
					...(args.status && { status: args.status }),
				};
				navigate({
					pathname: `/${mainPath}/${ordersRouteObject.path}`,
					search: `?${createSearchParams(params)}`,
				});
			} else {
				navigate(`/${mainPath}/${ordersRouteObject.path}`);
			}
		},
		navigate주방주문조회: (args?: { keyword?: string; status?: KitchenOrderStatusType }) => {
			if (args) {
				const params = { keyword: args.keyword || '', ...(args.status && { status: args.status }) };
				navigate({
					pathname: `/${mainPath}/${kitchenOrderRouteObject.path}`,
					search: `?${createSearchParams(params)}`,
				});
			} else {
				navigate(`/${mainPath}/${kitchenOrderRouteObject.path}`);
			}
		},
		navigate결제조회: (args?: { keyword: string }) => {
			if (args) {
				const params = { keyword: args.keyword || '' };
				navigate({
					pathname: `/${mainPath}/${paymentManagmentsRouteObject.path}`,
					search: `?${createSearchParams(params)}`,
				});
			} else {
				navigate(`/${mainPath}/${paymentManagmentsRouteObject.path}`);
			}
		},
		navigate퇴장관리: (keyword?: string) => {
			const params = { keyword: keyword || '' };
			navigate({
				pathname: `/${mainPath}/${exitsRouteObject.path}`,
				search: `?${createSearchParams(params)}`,
			});
		},
		navigate결제: (options: {
			mode: 'order' | 'jungsan' | 'order_continue' | 'order_edit';
			orderId?: string;
			status?: OrderStatusType;
		}) => {
			navigate({
				pathname: `/${mainPath}/${paymentsRouteObject.path}`,
				search: `?${createSearchParams(options)}`,
			});
		},
		navigate주문수정완료: () => {
			navigate({
				pathname: `/${mainPath}/${paymentsOrderEditRouteObject.path}`,
			});
		},
		navigateToLogin: () => {
			navigate(`/${introPath}/login`);
		},
		navigateToSelectStore: () => {
			navigate(`/${introPath}/select-store`);
		},
	};
};
