import axios from 'axios';
import qs from 'qs';
import { setAxiosResponseInterceptor } from './setAxiosResponseInterceptor';

export const posImageAxiosClient = axios.create();

posImageAxiosClient.interceptors.request.use((config) => {
	if (!config.headers) return config;

	config.headers['Content-Type'] = 'multipart/form-data';

	const accessToken = localStorage.getItem('accessToken');
	const refreshToken = localStorage.getItem('refreshToken');
	const enterpriseHash = localStorage.getItem('enterpriseHash');

	if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
	if (refreshToken) config.headers['refreshToken'] = `Bearer ${refreshToken}`;
	if (enterpriseHash) config.headers['Enterprise-Hash'] = enterpriseHash;

	config.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

	return config;
});
setAxiosResponseInterceptor(posImageAxiosClient);
