import { IconTextButton } from "@kinderlabs-pos/ui-atoms";
import { Box, CircularProgress, IconButtonProps, Typography } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { ReactNode, useState } from "react";
import { s } from "msw/lib/glossary-58eca5a8";

export interface ITicketPrintButtonProps extends IconButtonProps {
  label?: ReactNode
}
export const TicketPrintButton: React.FC<ITicketPrintButtonProps> = ({
  label,
  onClick,
  disabled
}) => {
  const [isPrinting, setIsPrinting] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <IconTextButton
        onClick={(e) => {
          setIsPrinting(true);
          onClick && onClick(e)
          setIsPrinting(false)
        }}
        disabled={disabled || isPrinting}
        icon={
          <PrintIcon fontSize="small" />}
        label={label || "재발권"}
      />
      {isPrinting && <CircularProgress
        size={18}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-9px",
          marginLeft: "-9px"
        }} />}
    </Box>
  )
};