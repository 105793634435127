import { alpha, Components, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createMuiCssBaseline } from '../base/createMuiCssBaseline';

export const kioskComponentOverrides: Components<Theme> = {
	MuiOutlinedInput: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				'&:hover > .MuiOutlinedInput-notchedOutline': {
					borderColor: alpha(theme.palette[ownerState.color || 'primary'].main, 0.6),
					borderWidth: 2,
				},
			}),
		},
	},
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: 16,
				boxShadow: 'none',
				border: `1px solid ${grey[600]}`,
			},
		},
	},
	MuiDivider: {
		styleOverrides: {
			root: {
				borderWidth: 1,
			},
		},
	},
	MuiToolbar: {
		styleOverrides: {
			regular: {
				'@media (min-width: 600px)': {
					height: 48,
					minHeight: 48,
				},
				height: 48,
				minHeight: 48,
			},
			dense: {
				'&.MuiToolbar-dense': {
					backgroundColor: 'white',
					height: 24,
					minHeight: 24,
				},
			},
		},
	},
	MuiTableHead: {
		styleOverrides: {
			root: {
				'& MuiTableRow-head': {
					'& .MuiTableCell-root': {
						paddingLeft: 6,
						paddingRight: 6,
					},
				},
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				padding: 8,
				fontSize: 14,
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: '40px 32px',
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				padding: '40px 32px',
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				padding: '40px 32px',
			},
		},
	},
	MuiIconButton: {
		styleOverrides: {
			sizeSmall: {
				width: 26,
				height: 26,
				'& .MuiSvgIcon-root': {
					width: 24,
					height: 24,
				},
			},
			sizeMedium: {
				width: 30,
				height: 30,

				'& .MuiSvgIcon-root': {
					width: 30,
					height: 30,
				},
				'& > .anticon > svg': {
					fontSize: 30,
				},
			},
			sizeLarge: {
				width: 40,
				height: 40,

				'& .MuiSvgIcon-root': {
					width: 40,
					height: 40,
				},
				'& > .anticon > svg': {
					fontSize: 40,
				},
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: ({ ownerState }) => ({
				padding: '16px 26px',
				...(ownerState.variant === 'outlined' && {
					borderWidth: 2,
					'&:hover': {
						borderWidth: 2,
					},
					'.Mui-disabled': {
						borderWidth: 2,
					},
				}),
			}),
		},
	},
};
