import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';

import { Box, Stack, styled, Typography } from '@mui/material';

import couponImage from 'assets/vaunce/image/icon_Coupon.png';
import eventImage from 'assets/vaunce/image/icon_Event.png';
import informationImage from 'assets/vaunce/image/icon_information.png';
import locationImage from 'assets/vaunce/image/icon_Location.png';
import membershipImage from 'assets/vaunce/image/icon_membership.png';
import partyImage from 'assets/vaunce/image/icon_party.png';
import registrationImage from 'assets/vaunce/image/icon_QR_icon.png';
import vipImage from 'assets/vaunce/image/icon_VIP.png';

import couponImage2X from 'assets/vaunce/image/icon_Coupon@2x.png';
import eventImage2X from 'assets/vaunce/image/icon_Event@2x.png';
import informationImage2X from 'assets/vaunce/image/icon_information@2x.png';
import locationImage2X from 'assets/vaunce/image/icon_Location@2x.png';
import membershipImage2X from 'assets/vaunce/image/icon_membership@2x.png';
import partyImage2X from 'assets/vaunce/image/icon_party@2x.png';
import registrationImage2X from 'assets/vaunce/image/icon_QR_icon@2x.png';
import vipImage2X from 'assets/vaunce/image/icon_VIP@2x.png';

import dayjs from 'dayjs';
import { getAnalytics } from 'firebase/analytics';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { EventCarousel } from '../../components/EventCarousel';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';
import { AuthorityCheckDialog } from './AuthorityCheckDialog';
import { PopUpPushDialog } from './PopUpPushDialog';
import { AdminPopupDialog } from './AdminPopupDialog';
import { useVaunceAppNavigationWithAuthGuard } from '../../utils/useVaunceAppNavigationWithAuthGuard';
import { MenuType } from '../../components/SidebarDrawer/DrawerMenuItem';

const mainMenu = [
	{
		label: '이용권',
		subTitle: 'VAUNCE Ticket',
		iconUrl: membershipImage,
		srcsetUrl: membershipImage2X,
		url: '/oneTicket',
		needLoginAssertion: true,
	},
	// {
	// 	label: '멤버십',
	// 	subTitle: 'VAUNCE Club',
	// 	iconUrl: membershipImage,
	// 	srcsetUrl: membershipImage2X,
	// 	url: '/vclub',
	// 	needLoginAssertion: true,
	// },
	{
		label: '쿠폰',
		subTitle: 'VAUNCE Coupon',
		iconUrl: couponImage,
		srcsetUrl: couponImage2X,
		url: '/couponList',
		needLoginAssertion: true,
	},
	{
		label: '다회권/정기권',
		// sidetitle: '다회권/정기권',
		subTitle: 'VAUNCE Premium Club',
		iconUrl: vipImage,
		srcsetUrl: vipImage2X,
		url: '/vipclub',
		needLoginAssertion: true,
	},
	{
		label: '간편 입장 QR',
		subTitle: 'Register to use',
		iconUrl: registrationImage,
		srcsetUrl: registrationImage2X,
		url: '/facilityRegister',
		needLoginAssertion: true,
	},
];

const bottomMenuHeight = 90;
const carouselHeight = 104;
export interface IHomePageProps {}
export const HomePage: React.FC<IHomePageProps> = ({}) => {
	const navigate = useNavigate();
	const { navigateLogin, navigateCenterDescription, navigateEvent } = useVaunceAppRouter();
	const adminPopup = VaunceQueryState.usePopupQuery();

	const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
	const [authOpen, setAuthOpen] = useState<boolean>(false);
	const [adminPopupOpen, setAdminPopupOpen] = useState<boolean>(false);
	// const [marketOpen, setMarketOpen] = useState<boolean>(false);

	const [popUpCookie, setPopUpCookie] = useCookies(['popup-push']);
	const [saveCookie, setSaveCookie] = useCookies(['save_push_agree']);
	const [adminPopupCookie, setAdminPopupCookie] = useCookies(['ad-modal-today-close']);

	const { navigateWithLoginGuard, isLoginned } = useVaunceAppNavigationWithAuthGuard();

	const analytics = getAnalytics();

	const popUpClose = () => {
		setPopUpOpen(false);
	};

	const authClose = () => {
		setAuthOpen(false);
	};

	const adminPopipClose = () => {
		setAdminPopupOpen(false);
	};

	// const marketClose = () => {
	// 	setMarketOpen(false);
	// };

	const isValidLogin = async (path: string) => {
		if (!localStorage.getItem('accessToken')) {
			alert('로그인이 필요한 메뉴입니다.');
			navigateLogin();
		} else {
			const res = await VaunceAppApis.getAgreePush(popUpCookie['popup-push']);
			res &&
				setSaveCookie('save_push_agree', true, {
					path: '/',
					expires: dayjs().add(3, 'M').toDate(),
				});
			navigate(path);
		}
	};

	useEffect(() => {
		if (!popUpCookie['popup-push']) {
			setSaveCookie('save_push_agree', false, { path: '/', expires: dayjs().add(3, 'M').toDate() });
			setPopUpOpen(true);
			setAuthOpen(true);
			// setMarketOpen(true);
		}
		if (adminPopup && adminPopupCookie['ad-modal-today-close'] !== adminPopup.id.toString()) {
			setAdminPopupOpen(true);
		}
	}, []);

	return (
		<>
			<Stack
				direction='column'
				flex={1}>
				{mainMenu.map((content, idx) => (
					<MainBox
						sx={{
							borderBottom: idx != mainMenu.length - 1 ? '1px solid #D3D3D3' : '',
						}}
						key={content.label}
						onClick={() => {
							navigateWithLoginGuard(content);
							loggingEvent(`홈 > ${content.label}`, 'route', `${content.subTitle}ClickInHome`);
						}}>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={2}>
							<img
								src={content.iconUrl}
								srcSet={content.srcsetUrl}
								width={42}
								height={43}
							/>
							<Stack>
								<Stack
									direction={'row'}
									spacing={1}
									alignItems={'baseline'}>
									<Typography
										variant='h3'
										fontWeight={500}>
										{content.label}
									</Typography>
									{/* {content.sidetitle && (
										<Typography
											variant='body1'
											fontWeight={400}
											fontFamily={'Lato'}
											sx={{ opacity: 0.6 }}>
											{content.sidetitle}
										</Typography>
									)} */}
								</Stack>
								<Typography
									variant='body1'
									fontWeight={400}
									fontFamily={'Lato'}
									sx={{ opacity: 0.6 }}>
									{content.subTitle}
								</Typography>
							</Stack>
						</Stack>
					</MainBox>
				))}
			</Stack>
			<Box
				height={carouselHeight}
				className={'dummy'}
			/>
			<Stack
				position={'absolute'}
				left={0}
				bottom={bottomMenuHeight}
				width={'100vw'}
				height={carouselHeight}
				sx={{ backgroundColor: 'white' }}>
				<EventCarousel />
			</Stack>
			<HomeInfoComponent />
			{/* <MarketingConsentDialog
				open={marketOpen}
				handleClose={marketClose}
			/> */}
			<AuthorityCheckDialog
				open={authOpen}
				handleClose={authClose}
			/>
			<PopUpPushDialog
				open={popUpOpen}
				handleClose={popUpClose}
			/>
			{adminPopup && (
				<AdminPopupDialog
					open={adminPopupOpen}
					handleClose={adminPopipClose}
					popup={adminPopup}
				/>
			)}
		</>
	);
};

const MainBox = styled(Box)(({ theme }) => ({
	padding: '0px 10px',
	borderTop: 0,
	borderLeft: 0,
	borderRight: 0,
	backgroundColor: 'transparent',
	textAlign: 'left',
	display: 'flex',
	alignItems: 'center',
	alignSelf: 'stretch',
	height: '100%',
	cursor: 'pointer',
}));

export const MainBottomMenuItems: MenuType[] = [
	{
		label: '센터안내',
		url: '/centerDescription',
		iconUrl: locationImage,
		srcsetUrl: locationImage2X,
		needLoginAssertion: false,
	},
	{
		label: '파티/단체',
		url: '/reservation',
		iconUrl: partyImage,
		srcsetUrl: partyImage2X,
		needLoginAssertion: true,
	},
	{
		label: '이벤트',
		url: '/event',
		iconUrl: eventImage,
		srcsetUrl: eventImage2X,
		needLoginAssertion: false,
	},
	{
		label: '자녀등록',
		url: '/info',
		iconUrl: informationImage,
		srcsetUrl: informationImage2X,
		needLoginAssertion: true,
	},
];

const HomeInfoComponent = () => {
	const { navigateWithLoginGuard, isLoginned } = useVaunceAppNavigationWithAuthGuard();
	const clickedChildRegister = useSetAtom(VaunceState.goChildRegisterPage);

	return (
		<Stack
			px={1}
			height={bottomMenuHeight}
			justifyContent={'flex-end'}>
			<Stack
				direction='row'
				spacing={3}
				justifyContent={'center'}
				py={1}
				sx={{ cursor: 'pointer', backgroundColor: 'white' }}>
				<FooterBox
					onClick={() => {
						navigateWithLoginGuard(MainBottomMenuItems[0]);
						loggingEvent('홈 > 센터안내', 'route', 'centerClickInHome');
					}}>
					<img
						src={locationImage}
						srcSet={locationImage2X}
						width={24}
						height={24}
					/>
					<Typography
						mt={'4px'}
						variant='h6'
						fontWeight={400}>
						센터안내
					</Typography>
				</FooterBox>
				<FooterBox
					onClick={() => {
						navigateWithLoginGuard(MainBottomMenuItems[1]);
						loggingEvent('홈 > 파티/단체', 'route', 'reservationClickInHome');
					}}>
					<img
						src={partyImage}
						srcSet={partyImage2X}
						width={28}
						height={28}
					/>
					<Typography
						variant='h6'
						fontWeight={400}>
						파티/단체
					</Typography>
				</FooterBox>
				<FooterBox
					onClick={() => {
						navigateWithLoginGuard(MainBottomMenuItems[2]);
						loggingEvent('홈 > 이벤트', 'route', 'partyClickInHome');
					}}>
					<img
						src={eventImage}
						srcSet={eventImage2X}
						width={24}
						height={24}
					/>
					<Typography
						mt={'4px'}
						variant='h6'
						fontWeight={400}>
						이벤트
					</Typography>
				</FooterBox>
				<FooterBox
					onClick={() => {
						navigateWithLoginGuard(MainBottomMenuItems[3]);
						clickedChildRegister(true);
						loggingEvent('홈 > 자녀등록', 'route', 'childRegisterClickInHome');
					}}>
					<img
						src={informationImage}
						srcSet={informationImage2X}
						width={24}
						height={24}
					/>
					<Typography
						variant='h6'
						mt={'4px'}
						fontWeight={400}>
						자녀등록
					</Typography>
				</FooterBox>
			</Stack>
		</Stack>
	);
};

const FooterBox = styled(Box)(({ theme }) => ({
	width: '25%',
	textAlign: 'center',
	cursor: 'pointer',
}));
