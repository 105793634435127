import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteStockInfoAsync = (rootUrl: string) => async (stockIdList: number[]) =>
	(
		await posAxiosClient.delete(`${rootUrl}/stock`, {
			params: {
				stockIdList,
			},
		})
	).data;
