import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import saleDoneImage from 'assets/vaunce/image/saleDone.png';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';

export interface INicePaySuccessPageProps {}
export const NicePaySuccessPage: React.FC<INicePaySuccessPageProps> = ({}) => {
	const { navigateVauncePremiumClub } = useVaunceAppRouter();

	return (
		<Stack height={'100%'}>
			<PageHeader title='구매완료' />
			<Stack height={'100%'}>
				<Stack
					alignItems={'center'}
					justifyContent={'center'}
					height={'100%'}
					spacing={2}
					textAlign={'center'}>
					<img
						src={saleDoneImage}
						width={74}
					/>
					<Stack>
						<Typography variant='h5'>구매가 성공적으로</Typography>
						<Typography variant='h5'>완료되었습니다.</Typography>
					</Stack>

					<Stack>
						<Typography variant='h5'>구매하신 내역은</Typography>
						<Typography variant='h5'>MY바운스 {'>'} 이용권 또는</Typography>
						<Typography variant='h5'>MY바운스 {'>'} 바운스프리미엄클럽에서</Typography>
						<Typography variant='h5'>확인하실 수 있습니다.</Typography>
					</Stack>
				</Stack>
				<RoundedButton
					onClick={() => {
						navigateVauncePremiumClub();
					}}>
					구매내역 확인하기
				</RoundedButton>
			</Stack>
		</Stack>
	);
};
