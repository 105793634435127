import { PosElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';
import { GuestVisitSubscriber } from './GuestVisitSubscriber';
import { KitchenOrderSubscriber } from './KitchenOrderSubscriber';

export const PosSSEEventProcessorProvider: React.FC<React.PropsWithChildren<{}>> = ({
	children,
}) => {
	const { storeId, deviceId: posId, enterpriseId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const mainPageQuery = PosElectronStoreState.usePosMainPageLocalSettings();
	const 주방주문증크게 = mainPageQuery.data?.usingLargeTextOnKitchenReceiptPrinter ?? false;

	const curPosInfo = useAtomValue(StoreInfoState.curPosInfo);
	const isKitchenPos = curPosInfo.posType === 'KITCHEN';

	return isKitchenPos ? (
		<KitchenOrderSubscriber
			storeId={storeId}
			주방주문증크게={주방주문증크게}
			enterpriseId={enterpriseId}
			posId={posId}>
			{children}
		</KitchenOrderSubscriber>
	) : (
		<GuestVisitSubscriber
			storeId={storeId}
			enterpriseId={enterpriseId}
			posId={posId}>
			{children}
		</GuestVisitSubscriber>
	);
};
