import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import {
	NoticeDetailBodyType,
	NoticeReqeustType,
	NoticeResponseType,
	SettingResponseType,
} from '@kinderlabs-pos/shared-data-type';

export const getAllNoticeListPageableAsync = (rootUrl: string) => async (data: NoticeReqeustType) =>
	(
		await axiosAppPostClient.get<NoticeResponseType>(`${rootUrl}/admin/get_notice_all_pageable`, {
			params: { ...data },
		})
	).data;

export const getNoticeDetail = (rootUrl: string) => async (noticeId: number) =>
	(await axiosAppClient.get<NoticeDetailBodyType>(`${rootUrl}/notice/get_one/${noticeId}`)).data;

export const getSetting = (rootUrl: string) => async () =>
	await (
		await axiosAppPostClient.get<SettingResponseType>(`${rootUrl}/users/getSetting`)
	).data;
