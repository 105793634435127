import { OrderState, PrintReceiptState } from '@kinderlabs-pos/state';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

let timeout1: NodeJS.Timeout | null = null;
const curPrintingIdxAtom = atomWithReset(0);
type controlType = 'PAUSE' | 'RESUME' | 'READY';
const controlAtom = atom<controlType>('READY');

export const ExchangeCouponPrintEventHandler = ({
	deviceType,
}: {
	deviceType: 'POS' | 'KIOSK';
}) => {
	const exchangeCoupons = useAtomValue(OrderState.printing.exchangeCoupons.printing);
	const [control, setControl] = useAtom(controlAtom);

	// 이따 리팩토링할 것
	const [curIdx, setCurIdx] = useAtom(curPrintingIdxAtom);
	const resetAtom = useResetAtom(curPrintingIdxAtom);
	useEffect(() => {
		return () => {
			resetAtom();
			setControl('READY');
		};
	}, []);

	const changeStatus = useSetAtom(OrderState.printing.exchangeCoupons.changeStatus);
	const { print쿠폰Async } = PrintReceiptState.useExchangePrinter({ deviceType });

	useEffect(() => {
		if (control === 'PAUSE' || control === 'READY') return;

		if (exchangeCoupons.length === 0 || curIdx >= exchangeCoupons.length) return;
		// IPC Send 는 여기서
		// sendTicketPrinterMessage({ ticket 정보 })

		const exchangeCouponsToPrint = exchangeCoupons[curIdx];

		// 2번씩 rerendering 되는 문제가 발견되어서 조치
		if (['ON'].includes(exchangeCouponsToPrint.printStatus)) return;
		timeout1 = setTimeout(async () => {
			changeStatus({
				id: exchangeCouponsToPrint.id,
				printingStatus: 'ON',
			});

			try {
				await print쿠폰Async({ coupon: exchangeCouponsToPrint });

				changeStatus({
					id: exchangeCouponsToPrint.id,
					printingStatus: 'COMPLETE',
				});
				setCurIdx((state) => state + 1);
			} catch {
				changeStatus({
					id: exchangeCouponsToPrint.id,
					printingStatus: 'FAILED',
				});
				setControl('PAUSE');
			}
		}, 0); // 기존 300ms 였던것 0으로 줄임.

		return () => {
			timeout1 && clearTimeout(timeout1);
		};
	}, [exchangeCoupons, curIdx, control]);

	// 이건 clear 하는건데, 시점을 언제로 잡아야 좋을지ㅋㅋ

	return null;
};

ExchangeCouponPrintEventHandler.useIsPaused = () => {
	return useAtomValue(controlAtom) === 'PAUSE';
};
ExchangeCouponPrintEventHandler.usePause = () => {
	const setControl = useSetAtom(controlAtom);
	return () => setControl('PAUSE' as controlType);
};
ExchangeCouponPrintEventHandler.useResume = () => {
	const setControl = useSetAtom(controlAtom);
	return () => setControl('RESUME' as controlType);
};
ExchangeCouponPrintEventHandler.useReady = () => {
	const setControl = useSetAtom(controlAtom);
	return () => setControl('READY' as controlType);
};
