import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PosInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateParkingUrlAsync = (rootUrl: string) => async (info: updateParkingUrlType) =>
	(
		await posAxiosClient.put<updateParkingUrlType>(
			`${rootUrl}/store/pos/parking-url`,
			{
				...info,
			},
			{
				params: {},
			}
		)
	).data;

export type updateParkingUrlType = Pick<PosInfoType, 'id' | 'storeId' | 'parkingUrl'>;
