import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Divider, Stack, Typography } from '@mui/material';
import infoImage from 'assets/vaunce/image/infofolder.png';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { useVaunceAppRouter } from '../routes';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
export interface INotFoundIdPageProps {}
export const NotFoundIdPage: React.FC<INotFoundIdPageProps> = ({}) => {
	const { navigateFindIdStepOne, navigateSignUpStepOne } = useVaunceAppRouter();

	return (
		<Stack>
			<LoginHeaderTypograpy>아이디 찾기</LoginHeaderTypograpy>
			<Stack
				textAlign={'center'}
				alignItems={'center'}
				spacing={3}>
				<img
					src={infoImage}
					height={68}
					width={68}
				/>

				<Box>
					<Typography variant='h5'>입력하신 정보와 일치하는</Typography>
					<Typography variant='h5'>회원 정보가 없습니다.</Typography>
				</Box>

				<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />
				<Box>
					<Typography
						variant='h5'
						fontWeight={500}>
						입력하신 정보로 확인되지 않을 경우,
					</Typography>
					<Typography
						variant='h5'
						fontWeight={500}>
						다음 방법으로 확인하세요.
					</Typography>
				</Box>

				<Box>
					<Stack
						direction={'row'}
						spacing={1}
						justifyContent='center'>
						<Typography variant='h5'>바운스 고객센터 </Typography>
						<Typography
							variant='h5'
							color='primary'>
							1599-9583
						</Typography>
					</Stack>

					<Typography
						sx={{
							opacity: 0.6,
						}}
						variant='body1'>
						평일 09:00 ~ 18:00 문의 가능합니다.
					</Typography>
				</Box>

				<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />

				<Stack
					direction={'row'}
					spacing={1}
					width='100%'>
					<RoundedButton
						data-cy='retryfindIdBtn'
						sx={{ height: 58, flex: 1 }}
						onClick={() => {
							navigateFindIdStepOne();
						}}>
						다시 시도
					</RoundedButton>
					<RoundedButton
						data-cy='signupBtnForNotFoundId'
						sx={{
							height: 58,
							flex: 1,
							backgroundColor: '#FFFFFF',
							color: vaunceColors.lightPink,
						}}
						onClick={() => {
							navigateSignUpStepOne();
						}}>
						회원 가입
					</RoundedButton>
				</Stack>
			</Stack>
		</Stack>
	);
};
