import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { QRInput } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {
	EasyPayPaymentInfoToRefundType,
	useHandle간편결제환불Formik,
} from './hooks/useHandleSimplePaymentRefundFormik';

import { PaymentState } from '@kinderlabs-pos/state';

interface ActionType {
	order: OrderDetailType;
	payment: EasyPayPaymentInfoToRefundType;
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
	closeDialog?: () => void;
}
export const PaymentDetailEasyPayActions: React.FC<ActionType> = ({
	order,
	payment,
	onCompleteRefund,
	closeDialog,
}) => {
	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	useEffect(() => {
		return () => {
			handle결제모듈강제취소();
		};
	}, []);
	const [간편결제환불QrMode, set간편결제환불QrMode] = React.useState(false);

	const 간편결제환불QrMode시작 = () => set간편결제환불QrMode(true);
	const 간편결제환불QrMode종료 = () => set간편결제환불QrMode(false);

	return (
		<Stack spacing={1}>
			<Stack
				height={40}
				direction={'row'}
				spacing={1}
				justifyContent='flex-end'>
				{!payment.refunded && (
					<Button
						onClick={간편결제환불QrMode시작}
						variant={'outlined'}
						sx={{ pl: 1, pr: 1 }}>
						<Typography variant={'body1'}>환불</Typography>
					</Button>
				)}
				{closeDialog && (
					<Button
						variant='contained'
						onClick={closeDialog}>
						확인
					</Button>
				)}
			</Stack>
			{간편결제환불QrMode && (
				<간편결제환불Stack
					order={order}
					payment={payment}
					QR입력Mode종료={간편결제환불QrMode종료}
					onCompleteRefund={onCompleteRefund}
				/>
			)}
		</Stack>
	);
};

const 간편결제환불Stack = ({
	payment,
	order,
	QR입력Mode종료,
	onCompleteRefund,
}: {
	order: OrderDetailType;
	payment: EasyPayPaymentInfoToRefundType;
	QR입력Mode종료: () => void;
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
}) => {
	const { values, setValues, handleChange, isValid, isSubmitting, handleSubmit, errors } =
		useHandle간편결제환불Formik({
			payment,
			order,
			onComplete: () => {
				QR입력Mode종료();
				onCompleteRefund && onCompleteRefund(payment);
			},
		});

	return (
		<Stack>
			<form onSubmit={handleSubmit}>
				<Stack
					direction={'row'}
					spacing={1}>
					<QRInput
						name={'customerId'}
						value={values.customerId}
						onChange={handleChange('customerId')}
					/>
					<Button
						variant={'contained'}
						type={'submit'}
						disabled={!isValid || isSubmitting}>
						확인
					</Button>
					<Button
						variant={'outlined'}
						disabled={isSubmitting}
						onClick={QR입력Mode종료}>
						취소
					</Button>
				</Stack>
			</form>
			{errors.submit && (
				<Typography
					variant={'subtitle1'}
					color={'error'}>
					{errors.submit}
				</Typography>
			)}
		</Stack>
	);
};
