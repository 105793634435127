import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockHistoryInfoType } from '@kinderlabs-pos/shared-data-type';

export const getStockHistoryDetailInfoAsync = (rooturl: string) => async (historyId: number) =>
	(
		await posAxiosClient.get<StockHistoryInfoType>(`${rooturl}/stock/history/detail`, {
			params: {
				historyId,
			},
		})
	).data;
