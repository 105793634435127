import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { ChildListType, FacilityUserType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	Button,
	Divider,
	FormControl,
	InputLabel,
	NativeSelect,
	Stack,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import { VaunceOptionButton } from 'apps/vaunce-app/src/components/VaunceOptionButton';
import deleteImage from 'assets/vaunce/image/allimdelete.png';
import { FieldArrayRenderProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import VaunceButton from '../../../components/VaunceButton';
import { VaunceHelperText } from '../../../components/VaunceHelperText';
import { CallChildListDialog } from '../CallChildListDialog';

export interface INewRegisterFacilityUsersProps {
	formik: FormikValues;
	arrayHelpers: FieldArrayRenderProps;
}
export const NewRegisterFacilityUsers: React.FC<INewRegisterFacilityUsersProps> = ({
	formik,
	arrayHelpers,
}) => {
	const { values, handleChange, setValues, handleBlur, setFieldValue } = formik;

	const newDummy = { name: '', birth: '', relation: 1, gender: 'M' };

	return (
		<>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}>
				<Typography variant='h2'>시설 이용자</Typography>
				<LoadChildrenButton formik={formik} />
			</Stack>
			<RoundBox>
				<Typography
					variant={'h5'}
					color={vaunceColors.lightPink}>
					모든 이용자의 정보를 입력해 주세요.
				</Typography>
			</RoundBox>
			{values.availableType === 'USING' && <ApplicationInfo />}

			{values.userArray.map((user: FacilityUserType, idx: number) => (
				<Stack
					spacing={1}
					key={idx}
					sx={{ borderBottom: `1px dashed ${vaunceColors.lightPink}` }}>
					<Stack
						direction={'row'}
						spacing={2}>
						<TextField
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									이름
								</Typography>
							}
							data-cy={`registerName_${idx}`}
							name={`userArray.${idx}.name`}
							value={user.name}
							onChange={handleChange}
							sx={{ flex: 1, fontSize: '1.5em' }}
							placeholder='예) 홍길동'
							variant='standard'
							onBlur={handleBlur}
							InputProps={{
								style: { fontSize: '16px', fontWeight: 500 },
							}}
							helperText={
								<VaunceHelperText
									dataCy={'registerNameHelpText'}
									name={`userArray.${idx}.name`}
								/>
							}
						/>
						<TextField
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									생년월일(8자리)
								</Typography>
							}
							InputProps={{
								style: { fontSize: '16px', fontWeight: 500 },
							}}
							data-cy={`registerBirth_${idx}`}
							name={`userArray.${idx}.birth`}
							value={user.birth}
							onChange={handleChange}
							sx={{ flex: 1, fontSize: '1.5em' }}
							placeholder='예) 20220101'
							variant='standard'
							onBlur={handleBlur}
							helperText={
								<VaunceHelperText
									dataCy={'registerBirthHelpText'}
									name={`userArray.${idx}.birth`}
								/>
							}
						/>
					</Stack>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}>
						<Stack
							sx={{ flex: 1 }}
							spacing={1}>
							<Typography
								variant='h6'
								fontWeight={400}>
								{'성별'}
							</Typography>
							<Stack direction={'row'}>
								<VaunceOptionButton
									options={user.gender !== 'M'}
									name={`userArray.${idx}.gender`}
									value={'M'}
									onClick={handleChange}
									text={'남성'}
									data-cy={`registerGender_M_${idx}`}
								/>
								<VaunceOptionButton
									options={user.gender !== 'F'}
									name={`userArray.${idx}.gender`}
									value={'F'}
									onClick={handleChange}
									text={'여성'}
									data-cy={`registerGender_F_${idx}`}
								/>
							</Stack>
						</Stack>
						<FormControl sx={{ flex: 1 }}>
							<InputLabel
								variant='standard'
								htmlFor='uncontrolled-native'>
								<Typography
									variant='h6'
									fontWeight={400}>
									{'관계'}
								</Typography>
							</InputLabel>
							<NativeSelect
								sx={{ fontSize: '16px', fontWeight: 500, pt: 1, flex: 1 }}
								data-cy={`registerrelation_${idx}`}
								name={`userArray.${idx}.relation`}
								value={user.relation}
								onChange={handleChange}>
								{values.availableType === 'USING' && <option value={0}>본인</option>}
								<option value={1}> 자녀 </option>
								<option value={2}> 배우자 </option>
								<option value={3}> 기타 </option>
							</NativeSelect>
						</FormControl>
					</Stack>
					<Box flex={'0 0 auto'}>
						<DeleteButton
							data-cy={`registerFacilityUserDeleteBtn_${idx}`}
							style={{
								float: 'right',
								width: 'auto',
								padding: '10px 14px',
								marginBottom: '2rem',
								marginRight: '1rem',
							}}
							onClick={() => {
								arrayHelpers.remove(idx);
							}}>
							<Stack
								direction={'row'}
								alignItems={'center'}>
								<Typography
									variant='h6'
									fontWeight={400}>
									{'삭제'}
								</Typography>
								<img src={deleteImage} />
							</Stack>
						</DeleteButton>
					</Box>
				</Stack>
			))}
			<VaunceButton
				data-cy='registerFacilityUserAddBtn'
				style={{ width: '100%' }}
				onClick={() => arrayHelpers.push(newDummy)}>
				+ 추가
			</VaunceButton>
		</>
	);
};

interface ILoadChildrenButton {
	formik: FormikValues;
}

const LoadChildrenButton: React.FC<ILoadChildrenButton> = ({ formik }) => {
	const { values, handleChange, setValues, handleBlur, setFieldValue } = formik;

	const myUsage = VaunceQueryState.useUserUsageQuery();

	const [checkIdxList, setCheckIdxList] = useState<boolean[]>(
		myUsage.childList.map((item) => false)
	);

	const changeChildRelationData = (childrenData: ChildListType[]) => {
		childrenData.map((child) => {
			child.relation = 1;
		});
		return childrenData;
	};

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<CallChildListDialog
					open={open}
					closeDialog={closeDialog}
					checkIdxList={checkIdxList}
					setCheckIdxList={setCheckIdxList}
					onClick={(childrenData) => {
						const userArray = values.userArray.filter((item: FacilityUserType) => item.name !== '');

						// 받아온 자녀쪽 relation 1: 아들, 2: 딸 이지만,
						// 관계 relation은 	자녀:1 이기 때문에 다 1로 바꾸어줘야함
						const userSet = [...new Set([...userArray, ...changeChildRelationData(childrenData)])];
						setFieldValue('userArray', userSet);
					}}
				/>
			)}>
			{(openDialog) => (
				<Button
					data-cy='loadChildInRegisterStepOne'
					onClick={openDialog}
					variant='outlined'>
					{'자녀 불러오기'}
				</Button>
			)}
		</WithDialog>
	);
};
const ApplicationInfo = () => {
	const myUsage = VaunceQueryState.useUserUsageQuery();
	return (
		<Stack spacing={2}>
			<Stack
				direction={'row'}
				spacing={2}>
				<TextField
					data-cy='applicantNameInRegister'
					label={
						<Typography
							variant='h6'
							fontWeight={400}>
							이름
						</Typography>
					}
					value={myUsage.applicant}
					variant='filled'
					sx={{ flex: 1, fontSize: '1.5em' }}
					InputProps={{
						readOnly: true,
					}}
				/>
				<TextField
					data-cy='applicantBirthInRegister'
					label={
						<Typography
							variant='h6'
							fontWeight={400}>
							생년월일(8자리)
						</Typography>
					}
					value={myUsage.applicantBirth}
					variant='filled'
					sx={{ flex: 1, fontSize: '1.5em' }}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Stack>
			<Stack
				direction={'row'}
				spacing={2}
				alignItems={'center'}>
				<Stack
					sx={{ flex: 1 }}
					spacing={1}>
					<Typography>{'성별'}</Typography>
					<Stack direction={'row'}>
						<VaunceOptionButton
							text='남성'
							options={myUsage.applicantGender !== 'M'}
						/>
						<VaunceOptionButton
							text='여성'
							options={myUsage.applicantGender !== 'F'}
						/>
					</Stack>
				</Stack>

				<FormControl sx={{ flex: 1 }}>
					<TextField
						data-cy='applicantRelationInRegister'
						label='관계'
						defaultValue={'본인'}
						variant='filled'
						sx={{ flex: 1, fontSize: '1.5em', pt: 1 }}
						InputProps={{
							readOnly: true,
						}}
					/>
				</FormControl>
			</Stack>
			<Divider sx={{ borderBottom: `1px dashed ${vaunceColors.lightPink}` }} />
		</Stack>
	);
};

const RoundBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#EDEDED',
	color: `${vaunceColors.lightPink}`,
	width: '100%',
	borderRadius: theme.spacing(2.25),
	padding: '7.5px 10px 5.5px',
}));

const DeleteButton = styled(Button)(({ theme }) => ({
	color: `${vaunceColors.lightPink}`,
	boxShadow: '0 0 12px rgb(0 0 0 / 16%)',
	textAlign: 'center',
	border: 'none',
	fontSize: '0.75em',
	borderRadius: theme.spacing(1.5),
}));
