import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { WarehouseInfoType } from '@kinderlabs-pos/shared-data-type';

export const creaateWarehouseInfoAsync =
	(rootUrl: string) =>
	async (createInfo: Omit<WarehouseInfoType, 'id' | 'storeId'> & { storeId: number | null }) =>
		(
			await posAxiosClient.post<WarehouseInfoType>(`${rootUrl}/warehouse`, {
				...createInfo,
			})
		).data;
