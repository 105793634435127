import { BaseInfoType, StoreInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getStoreInfoSimpleAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<StoreInfoType & BaseInfoType>(`${rootUrl}/store`, {
				params: { id: storeId },
			})
		).data;
