import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { DialogProps, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedDialog } from '../../components/FormattedDialog';

export interface ICommonNoticeForFacilityDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
}
const CommonNoticeForFacilityDialog: React.FC<ICommonNoticeForFacilityDialogProps> = ({
	open,
	onClose,
}) => {
	const noticeContent: string[] = [
		'다이빙, 덤블링 등 무리한 동작을 하지않고 본인 역량에 맞는 동작을 시도합니다. 시설물을 이용함에 있어 안전사고로 인한 심각한 상해가 발생할 수 있습니다.',
		'바운스 내에서 절대 뛰거나 술래잡기 등 장난치지 않습니다. 본인은 물론 타인까지 안전사고로 인한 심각한 상해가 발생할 수 있습니다.',
		'각 시설별 안전수칙 및 이용방법을 시설 이용 전 숙지하고 안전하게 이용 바랍니다.',
		'바운스 내에서는 절대 미끄럼방지 양말을 벗지 않습니다.',
		'어드벤처 시설 이용 시 반드시 안전 장비를 착용하고 직원의 통제를 따라 주시기 바랍니다.',
		'시설 이용은 정해진 이용 시간에만 이용이 가능 하며, 직원이 없는 경우 출입이나 이용을 금지합니다. 시설 이용을 원할 시 주변에 있는 직원에게 요청해주세요.',
		'고객의 고의·과실로 타인에게 피해를 입힌 경우 고객은 그 책임을 부담합니다.',
	];

	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack
				spacing={2}
				sx={{ pb: 5 }}>
				<Typography
					variant='h3'
					color={vaunceColors.lightPink}
					fontWeight={700}>
					시설 이용 간 공통 안내 사항
				</Typography>
				<Divider />
				<ol>
					{noticeContent.map((item, idx) => (
						<Typography
							variant='h5'
							key={idx}>
							<li>{item}</li>
						</Typography>
					))}
				</ol>
			</Stack>
		</FormattedDialog>
	);
};

export default CommonNoticeForFacilityDialog;
