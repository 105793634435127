import { GuestMemberInfoSearchType } from '@kinderlabs-pos/shared-data-type';
import { GuestMemberInfoState, OrderState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useGetGuestMemberSearchingActions } from '../../member-search/Formiks/useGetMemberSearchingActions';
import { UserInfoResultSection } from '../../member-search/UserInfoResultSection';
import { UserInfoSearchSection } from '../../member-search/UserInfoSearchSection';

export const MemberDialog = ({
	open,
	closeDialog,
	handleNextStep,
}: {
	open: boolean;
	closeDialog: () => void;
	handleNextStep: () => void;
}) => {
	const [appUserId, setAppUserId] = useState<string | undefined>(undefined);
	const dispatchOrder = useSetAtom(OrderState.value);
	const {
		isSearching,
		isRendered,
		setIsRender,
		result,
		setResult,
		multipleUserInfoList,
		handleSearchByKeyword,
		resetAllState,
		MultipleDialogComponent,
	} = useGetGuestMemberSearchingActions();

	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			fullWidth
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>{'회원 연결'}</DialogTitle>
			<DialogContent>
				<Stack spacing={3}>
					<UserInfoSearchSection
						handleSearchByKeyword={handleSearchByKeyword}
						withoutCurFive
						isLoading={isSearching}
						guestMemberInfoActions={{
							setGuestMemberInfo: (info: GuestMemberInfoSearchType) => setResult(info),
							multipleUserInfoList,
						}}
					/>
					<UserInfoResultSection
						searchStatus={result.status}
						guestMemberInfoActions={{
							setIsRender: () => setIsRender(true),
							guestMemberInfo: result.info,
							withoutUpdateAction: true,
							withoutDeleteAction: true,
							withoutRunAction: true,
							resetMemberInfo: () =>
								setResult({
									info: undefined,
									status: '검색전',
								}),
						}}
						appMemberInfo={{
							guestMemberId: result.info?.memberId,
							setAppUserId: (val?: string) => setAppUserId(val),
						}}
					/>
					<MultipleDialogComponent />
					<Stack
						direction={'row'}
						sx={{ alignItems: 'center' }}
						spacing={2}>
						<Button
							onClick={handleNextStep}
							sx={{ flex: 1 }}
							variant='contained'>
							{'고객 정보 없이 결제'}
						</Button>
						<Button
							disabled={!result.info || result.status === '결과없음' || !isRendered}
							onClick={() => {
								if (result.info) {
									dispatchOrder({
										type: 'SET_GUEST_USERS_INFO',
										guestMemberInfo: {
											...result.info,
											appUserId,
										},
									});
									handleNextStep();
								}
							}}
							sx={{ flex: 1 }}
							variant='contained'>
							{'고객 정보 연결 후 결제'}
						</Button>
						<Button
							onClick={() => {
								closeDialog();
								resetAllState();
							}}
							sx={{ flex: 1 }}
							variant='outlined'>
							{'닫기'}
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions></DialogActions>
		</WithDialog.XlDialog>
	);
};
