import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('ko');
dayjs.extend(relativeTime);

export const getDateTimeFormat = (date: Date | string) => {
	return dayjs(date).format('YYYY.MM.DD HH:mm:ss');
};

export const getShortDateFormatWithSlash = (date: Date | string) => {
	return dayjs(date).format('YY/MM/DD');
};

export const getDateFormat = (date: Date | string) => {
	return dayjs(date).format('YYYY.MM.DD');
};

export const getDateFormatWithHyphen = (date: Date | string) => {
	return dayjs(date).format('YYYY-MM-DD');
};

export const getDateTimeFormatWithHyphen = (date: Date | string) => {
	return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};

export const getTimeFormat = (date: Date | string, withSeconds: boolean = false) => {
	return dayjs(date).format(withSeconds ? 'HH:mm:ss' : 'HH:mm');
};

export const getTimeFormat오전오후 = (date: Date | string) => {
	const now = dayjs(date);
	return now.format(`${now.get('hour') > 12 ? '오후' : '오전'} h:mm`);
};
export const getTimeFormatWithoutSecond = (date: Date | string) => {
	return dayjs(date).format(`HH:mm`);
};

export const getRelativeTimeFormat = (now: Dayjs, date: Date) => {
	return now.to(dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
};

const DateOfWeekValue = {
	0: '일',
	1: '월',
	2: '화',
	3: '수',
	4: '목',
	5: '금',
	6: '토',
};

export const get요일 = (day: Dayjs) => {
	//@ts-ignore
	return DateOfWeekValue[day.get('day')];
};

export const checkToday = (date?: Date | string) => {
	return !!date && getDateFormat(date) === getDateFormat(new Date());
};

export const getToday = () => {
	return dayjs();
};
