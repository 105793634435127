import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, ListItem, Stack, Typography } from '@mui/material';
import { usePosRouter } from '../../routes';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsSubActions = ({
	selected,
	setSelected,
	orderWithDetail,
	paymentTotal,
	isFetching,
}: OrderAndPaymentsType) => {
	const { navigate결제 } = usePosRouter();

	const confirm = useConfirm();
	const handleClickRemainingPay = () =>
		confirm(`${numberWithCommasAndWon(remaining)}을 이어서 결제하시겠습니까?`, () => {
			navigate결제({
				mode: 'order_continue',
				orderId: orderWithDetail.id,
				status: orderWithDetail.status,
			});
		});

	const remaining = orderWithDetail.aggregate.totalToPay - paymentTotal;

	return (
		<Stack justifyContent={'flex-end'}>
			{orderWithDetail.status === 'NOT_COMPLETED' && (
				<ListItem sx={{ p: 0 }}>
					<Button
						fullWidth
						color={'error'}
						variant='outlined'
						size={'small'}
						disabled={isFetching || orderWithDetail.type === 'KYULSAN'}
						onClick={handleClickRemainingPay}>
						<Stack>
							<Typography variant='subtitle1'>
								{`남은금액 : ${numberWithCommasAndWon(remaining)}`}
							</Typography>
							{orderWithDetail.type === 'KYULSAN' ? (
								<Typography variant='body2'>{`결산주문 재결제 불가`}</Typography>
							) : (
								<Typography variant='body2'>{`결제하기`}</Typography>
							)}
						</Stack>
					</Button>
				</ListItem>
			)}
			{orderWithDetail.status === 'PARTIALLY_CANCELED' && (
				<ListItem sx={{ p: 0 }}>
					<Button
						fullWidth
						color={'error'}
						variant='outlined'
						size={'small'}
						disabled={isFetching || orderWithDetail.type === 'KYULSAN'}
						onClick={handleClickRemainingPay}>
						<Stack>
							<Typography variant='subtitle1'>
								{`취소된 금액 : ${numberWithCommasAndWon(remaining)}`}
							</Typography>
							{orderWithDetail.type === 'KYULSAN' ? (
								<Typography variant='body2'>{`결산주문 재결제 불가`}</Typography>
							) : (
								<Typography variant='body2'>{`결제하기`}</Typography>
							)}
						</Stack>
					</Button>
				</ListItem>
			)}
		</Stack>
	);
};
