import { EnterpriseInfoType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';
import { atom } from 'jotai';

export const userAtom = atom<ManagerUserType | null>(null);
export const enterpriseInfoAtom = atom<EnterpriseInfoType | null>(null);
export const storeIdListForManagerAtom = atom<number[] | undefined>((get) => {
	const user = get(userAtom);

	if (!user) return [];
	return ['STOREMANAGER', 'SUPERVISOR'].includes(user.authority) ? user.storeIdList : undefined;
});
