import { VaultCashCheckTypeValue, VaultCashHistoryType } from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat } from '@kinderlabs-pos/shared-util';
import { StoreInfoState, VaultCashState } from '@kinderlabs-pos/state';
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { HistoryDetailDialog } from './HistoryDetailDialog';
import { useQuery } from '@tanstack/react-query';

const today = dayjs();
export const HistoryTable = () => {
	const [content, setContent] = useState<VaultCashHistoryType>();
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { data: histories } = useQuery({
		...VaultCashState.keys.posHistory({
			params: {
				storeIdList: [storeId],
				posId: deviceId,
				from: today,
				to: today,
				sort: { name: 'createdDate', direction: 'asc' },
			},
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	return (
		<TableContainer
			component={Box}
			sx={{ width: '100%' }}>
			<Table>
				<TableHead>
					<TableCell align='center'>
						<Typography variant='h4'>일시</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>항목</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>입력금액</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>기대금액</Typography>
					</TableCell>
					<TableCell align='center'>
						<Typography variant='h4'>과부족</Typography>
					</TableCell>
				</TableHead>
				{histories && histories.totalElements > 0 ? (
					<TableBody>
						{histories.content.map((historyContent: VaultCashHistoryType) => (
							<TableRow
								onClick={() => {
									setContent(historyContent);
									setOpenDialog(true);
								}}
								key={historyContent.id}>
								<TableCell align='center'>
									<Typography variant='subtitle2'>
										{getDateTimeFormat(historyContent.createdDate)}
									</Typography>
								</TableCell>
								<TableCell align='center'>
									<Typography variant='subtitle2'>
										{VaultCashCheckTypeValue[historyContent.type]}
									</Typography>
								</TableCell>
								<TableCell align='right'>
									<Typography variant='subtitle2'>
										{VaultCashState.utils.getCountedAmount(historyContent)}
									</Typography>
								</TableCell>
								<TableCell align='right'>
									<Typography variant='subtitle2'>
										<Typography
											color={'text.secondary'}
											variant='body1'
											component={'span'}>
											{historyContent.type === 'OPEN' ? '(직전일대비) ' : ''}
										</Typography>
										{VaultCashState.utils.getExpectedAmount(historyContent)}
									</Typography>
								</TableCell>
								<TableCell align='right'>
									<Typography
										variant='subtitle2'
										color={'error'}>
										<Typography
											variant='body1'
											component={'span'}>
											{historyContent.type === 'OPEN' ? '(직전일대비) ' : ''}
										</Typography>
										{VaultCashState.utils.getOverAndShort(historyContent)}
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				) : (
					<Stack
						alignItems='center'
						justifyContent='center'>
						<Typography variant='h1'>시재 점검 내역이 없습니다</Typography>
					</Stack>
				)}
			</Table>
			<HistoryDetailDialog
				open={openDialog}
				closeDialog={() => setOpenDialog(false)}
				historyContent={content}
			/>
		</TableContainer>
	);
};
