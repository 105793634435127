import { OrderDetailContents } from '@kinderlabs-pos/feature/pos/orders';
import { OrderStatusTypeColor } from '@kinderlabs-pos/shared-data-type';
import { OrderQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { CardStack, SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, List, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Fragment, Suspense, useMemo, useState } from 'react';
import { OrderDisplay } from '../../OrderPage/OrderDisplay';
import { usePosRouter } from '../../routes';

const todayStart = dayjs().startOf('day');
const todayEnd = dayjs().endOf('day');

export const StoreCloseOrderCancelCheck = ({
	handleActivateNextStep,
}: {
	handleActivateNextStep: () => void;
}) => {
	const { navigate주문조회 } = usePosRouter();
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedOrderId, selectOrderId] = useState<string | null>(null);
	const { data: partiallyCanceledOrdersData } = useQuery({
		...OrderQueryState.keys.list({
			params: {
				storeIdList: [storeId],
				status: 'PARTIALLY_CANCELED',
				from: todayStart,
				to: todayEnd,
				page: 0,
				size: 1000,
			},
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	const { data: notCompletedOrdersData } = useQuery({
		...OrderQueryState.keys.list({
			params: {
				storeIdList: [storeId],
				status: 'NOT_COMPLETED',
				from: todayStart,
				to: todayEnd,
				page: 0,
				size: 1000,
			},
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

	const partiallyCanceledOrders = partiallyCanceledOrdersData?.content ?? [];
	const notCompletedOrders = notCompletedOrdersData?.content ?? [];

	const ordersToCheckBefore영업마감 = useMemo(
		() => [...notCompletedOrders, ...partiallyCanceledOrders],
		[partiallyCanceledOrders, notCompletedOrders]
	);

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}
			direction={'column'}
			flex={1}
			justifyContent={'space-between'}
			spacing={2}>
			<Stack
				flex={1}
				spacing={1}
				overflow={'auto'}>
				<Stack
					flex={1}
					overflow={'auto'}
					direction={'row'}
					spacing={2}>
					<Stack
						flex={1}
						overflow={'auto'}
						sx={{
							border: `1px solid ${grey[400]}`,
						}}>
						<List sx={{ flex: 1, py: 0 }}>
							{ordersToCheckBefore영업마감.map((o, idx) => (
								<Fragment key={idx}>
									<SelectableListItem
										selected={o.id === selectedOrderId}
										onClick={() => selectOrderId(o.id)}>
										<OrderDisplay
											order={o}
											key={idx}
										/>
									</SelectableListItem>
									<Divider />
								</Fragment>
							))}
						</List>
					</Stack>
					<Stack
						height={'100%'}
						overflow={'auto'}
						flex={1}>
						{selectedOrderId && (
							<Suspense>
								<OrderDetailContentsWithOrderId orderId={selectedOrderId} />
							</Suspense>
						)}
						{!selectedOrderId && (
							<CardStack
								width={'100%'}
								height={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								sx={{ bgcolor: 'white', overflowY: 'auto' }}>
								<Typography
									sx={{ textAlign: 'center' }}
									variant={'subtitle1'}
									color={'text.secondary'}>
									주문을 선택하고
									<br />
									상세 정보를 확인하세요.
								</Typography>
							</CardStack>
						)}
					</Stack>
				</Stack>
				<Stack alignItems={'flex-end'}>
					<Typography variant={'h4'}>
						{`확인이 필요한 주문 총 ${ordersToCheckBefore영업마감.length} 개`}
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction={'row'}
				spacing={1}
				justifyContent={'flex-end'}>
				<Button
					disabled={notCompletedOrders.length === 0}
					variant={'outlined'}
					color={OrderStatusTypeColor['NOT_COMPLETED']}
					onClick={() => {
						navigate주문조회({ status: 'NOT_COMPLETED' });
					}}>
					결제미완 확인
				</Button>
				<Button
					disabled={partiallyCanceledOrders.length === 0}
					variant={'outlined'}
					color={OrderStatusTypeColor['PARTIALLY_CANCELED']}
					onClick={() => {
						navigate주문조회({ status: 'PARTIALLY_CANCELED' });
					}}>
					재결제필요 확인
				</Button>
				<Button
					variant={'contained'}
					onClick={handleActivateNextStep}>
					다음
				</Button>
			</Stack>
		</Stack>
	);
};

const OrderDetailContentsWithOrderId = ({ orderId }: { orderId: string }) => {
	const { data: order, isSuccess } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});

	if (!isSuccess || !order) return null;
	return <OrderDetailContents order={order} />;
};
