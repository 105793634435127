import { deviceAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ConnectInfoType, DeviceType } from '@kinderlabs-pos/shared-data-type';
import { getSessionName } from './healthCheckDeviceAsync';

export const deviceConnectDeviceAsync =
	(rootUrl: string) =>
	async ({ storeId, deviceId, deviceType }: RequestType) => {
		const result = await deviceAxiosClient.post<ConnectInfoType[]>(
			`${rootUrl}/disconnect/store/${storeId}/type/${deviceType}`,
			{},
			{
				params: {
					deviceId,
				},
			}
		);

		localStorage.removeItem(getSessionName(deviceType));

		return result.data;
	};

type RequestType = {
	storeId: number;
	deviceId: number;
	deviceType: DeviceType;
};
