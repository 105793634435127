import { BaseInfoType, StoreInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const createStoreInfoAsync =
	(rootUrl: string) => async (createStoreInfoRequestInfo: CreateStoreInfoRequestType) =>
		(
			await posAxiosClient.post<StoreInfoType & BaseInfoType>(`${rootUrl}/store/`, {
				...createStoreInfoRequestInfo,
			})
		).data;

export type CreateStoreInfoRequestType = Omit<StoreInfoType, 'id'>;
