import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Box, DialogProps, Divider, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { FormattedDialog } from '../../components/FormattedDialog';

export interface IAgreePersonInfomationDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
}

const AgreePersonInfomationDialog: React.FC<IAgreePersonInfomationDialogProps> = ({
	open,
	onClose,
}) => {
	const noticeContent: string[] = [
		'㈜ 바운스에서 『개인정보보호법』 제15조에 의거하여 아래와 같은 내용으로 개인정보를 수집하고 있습니다. 귀하께서는 아래 내용을 자세히 읽어 보시고, 모든 내용을 이해하신 후에 동의 여부를 결정하여 주시기 바랍니다.',
		'',
		'Ⅰ. 개인정보의 수집 및 이용 동의서',
		'귀하께서 제공한 모든 정보는 다음의 목적을 위해 활용하며, 아래 목적 이외의 용도로는 사용되지 않습니다.',
		'',
		'① 개인정보 수입항목 및 수집·이용 목적',
		'가. 수집 항목',
		'- 성명, 연락처, 생년월일, 성별, 보호자와의 관계, 입장 인원',
		'나. 수집 및 이용 목적',
		'- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산, 비상시 연락',
		'- 컨텐츠 제공, 맞춤 서비스 제공, 본인 인증, 물품 구매 및 요금 결제',
		'- 사고 발생 시 이용 확인 및 보험 보상 업무',
		'',
		'② 개인정보 이용 및 보유기간',
		'가. 이용 및 보유기간: 회원 탈퇴 시 까지',
		'단, 다음의 정보에 대해서는 아래의 사유로 명시한 기간 동안 보존합니다.',
		'',
		'나. 관련 법령에 의한 정보 보유 사유',
		'상법, 전자상거래 등에서 소비자 보호에 관한 법률 등 관계 법력의 규정에 의하여 보존의 필요가 있는 경우 법령에서 정한 일정 기간 동안 회원 정보를 보관합니다.',
		'- 대금 결제 및 재화 등의 공급에 관한 기록',
		'· 보존 이유 : 전자 상거래 등에서의 소비자보호에 관한 법률',
		'· 보존 기간 : 5년',
		'- 소비자의 불만 또는 분쟁에 관한 기록',
		'· 보존 이유 : 전자 상거래 등에서의 소비자보호에 관한 법률',
		'· 보존 기간 : 3년',
	];

	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack
				spacing={2}
				sx={{ pb: 5 }}>
				<Typography
					variant='h3'
					color={vaunceColors.lightPink}
					fontWeight={500}>
					{'[필수] 개인 정보 수집 및 이용 동의'}
				</Typography>
				<Divider />
				<Box>
					<NoticeCotnentBox>
						{noticeContent.map((content, idx) => (
							<Typography
								variant='h5'
								key={idx}
								sx={{ fontWeight: 500 }}>
								{content === '' && <br />}
								{content}
							</Typography>
						))}
					</NoticeCotnentBox>
				</Box>
			</Stack>
		</FormattedDialog>
	);
};

export default AgreePersonInfomationDialog;

const NoticeCotnentBox = styled(Box)(({ theme }) => ({
	marginTop: '20px',
}));
