import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, Tab, Typography } from '@mui/material';
import QuestionIcon from 'assets/vaunce/image/question.png';
import QuestionIcon2x from 'assets/vaunce/image/question@2x.png';

import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { useVaunceAppRouter } from '../routes';
import { TabPanel } from '../VclubPage';
import { NormalQuestDialog } from './NormalQeustDialog';
import { PartyQuestDialog } from './PartyQeustionDialog';

export interface I일대일문의PageProps {}
export const OneToOnePage: React.FC<I일대일문의PageProps> = ({}) => {
	const { navigateNormalQeustion, navigatePartyQuestionStepOne, navigateMypage } =
		useVaunceAppRouter();
	const [value, setValue] = React.useState<number>(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	const resetPartyQuestion = useResetAtom(VaunceState.partyQuestionRequestInfo);

	useEffect(() => {
		resetPartyQuestion();
	}, []);

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}
			flex={1}>
			<PageHeader
				title='1:1 문의'
				navigateAlterUrl={navigateMypage}
			/>
			<VauncePinkTabs
				value={value}
				onChange={handleChange}
				variant='fullWidth'>
				<Tab
					label={
						<Typography
							variant='h5'
							fontWeight={500}>
							일반문의
						</Typography>
					}
				/>
				<Tab
					label={
						<Typography
							variant='h5'
							fontWeight={500}>
							파티/단체 문의
						</Typography>
					}
				/>
			</VauncePinkTabs>

			<Stack
				flex={1}
				overflow={'inherit'}>
				<TabPanel
					value={value}
					index={0}>
					<일반문의Tab />
				</TabPanel>
				<TabPanel
					value={value}
					index={1}>
					<파티문의Tab />
				</TabPanel>
			</Stack>
			<Stack sx={{ pt: 1 }}>
				<RoundedButton
					onClick={() => {
						value === 0 ? navigateNormalQeustion() : navigatePartyQuestionStepOne();
					}}>
					{'신규 작성'}
				</RoundedButton>
			</Stack>
		</Stack>
	);
};

export const 일반문의Tab = () => {
	const normalQuestionList = VaunceQueryState.useNormalQuestionQuery();

	return (
		<Stack
			flex={1}
			overflow={'inherit'}>
			<Stack
				flex={1}
				overflow={'inherit'}>
				{normalQuestionList.Question.map(
					(question, idx) =>
						question.status !== 'DELETED' && (
							<Stack
								key={idx}
								direction={'row'}
								spacing={2}
								sx={{ borderBottom: `1px solid ${vaunceColors.lightGray}`, padding: 2 }}>
								<WithDialog
									dialog={(open, closeDialog) => (
										<NormalQuestDialog
											open={open}
											closeDialog={closeDialog}
											data={question}
										/>
									)}>
									{(openDialog) => (
										<>
											<img
												src={QuestionIcon}
												srcSet={QuestionIcon2x}
												style={{ width: 18, height: 18 }}
											/>
											<Box onClick={openDialog}>
												<Typography variant='h4'>{question.title}</Typography>
												<Typography
													variant='h6'
													fontWeight={400}>
													{question.create_date}
												</Typography>
											</Box>
										</>
									)}
								</WithDialog>
							</Stack>
						)
				)}
			</Stack>
		</Stack>
	);
};

export const 파티문의Tab = () => {
	const partyQuestionList = VaunceQueryState.usePartyQuestionQuery();

	return (
		<Stack sx={{ position: 'relative' }}>
			{partyQuestionList.Question.map((question, idx) => (
				<Stack
					key={idx}
					direction={'row'}
					alignItems={'center'}
					spacing={2}
					sx={{ borderBottom: `1px solid ${vaunceColors.lightGray}`, padding: 2 }}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<PartyQuestDialog
								open={open}
								closeDialog={closeDialog}
								data={question}
							/>
						)}>
						{(openDialog) => (
							<>
								<img
									src={QuestionIcon}
									style={{ width: 18, height: 18 }}
								/>
								<Box onClick={openDialog}>
									<Typography variant='h4'>{question.title}</Typography>
									<Typography
										variant='h6'
										fontWeight={400}>
										{question.create_date}
									</Typography>
								</Box>
							</>
						)}
					</WithDialog>
				</Stack>
			))}
		</Stack>
	);
};
