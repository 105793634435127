import {
  Card, CardActionArea, CardProps, Unstable_Grid2 as Grid, Typography, Box
} from '@mui/material';
import { ReactNode } from 'react';

// 좀 더 잘 만들 수 있을 것 같은데... numOfColumns 가 ActionBoard 에 의해 정해진다.
interface IActionBoardItemProps<T extends ReactNode> extends Omit<CardProps, "onClick"> {
  label?: ReactNode
  value: T
  onClick: (value?: T) => void,
  size?: number,
  numOfColumns?: number
}

export const ActionBoardItem:
  <T extends ReactNode>(props: IActionBoardItemProps<T>) => React.ReactElement
  = ({
    sx,
    label,
    value,
    onClick,
    size = 1,
  }) => {
    return (
      <Card
        sx={{
          bgcolor: "darkgray",
          color: "white",
          ...sx,
        }}
      >
        <CardActionArea
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => { onClick(value) }}
        >
          {label ?? <Typography variant="h6">
            {value}
          </Typography>}
        </CardActionArea>
      </Card>
    );
  };
export const EmptySquareCard = ({ numOfColumns = 1 }: { numOfColumns?: number }) =>
  <Card
    sx={{
      bgcolor: "darkgray",
      color: "white",
    }}
  />
export const HiddenSquareCard = ({ numOfColumns = 1 }: { numOfColumns?: number }) => <Box sx={{ visibility: "hidden" }} />
export const NoneSquareCard = ({ numOfColumns = 1 }: { numOfColumns?: number }) => <Box sx={{ display: "none" }} />