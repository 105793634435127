import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import '@noonnu/spoqahansansneo-regular';
import { baseThemeWithFontFamily } from '../base/realBaseTheme';
import { kioskComponentOverrides } from './kioskComponentOverrides';
import { kioskPaletteOptions } from './kioskPaletteOptions';
import { kioskTypographyWithFontFamily } from './kioskTypographyWithFontFamily';

const fontFamily = 'SpoqaHanSansNeo-Regular';
export const kioskMainThemeOption: ThemeOptions = deepmerge(
	baseThemeWithFontFamily(fontFamily, 15),
	{
		palette: kioskPaletteOptions,
		typography: kioskTypographyWithFontFamily(fontFamily),
		components: kioskComponentOverrides,
		transition: {
			easing: {
				// This is the most common easing curve.
				easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
				// Objects enter the screen at full velocity from off-screen and
				// slowly decelerate to a resting point.
				easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
				// Objects leave the screen at full velocity. They do not decelerate when off-screen.
				easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
				// The sharp curve is used by objects that may return to the screen at any time.
				sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
			},
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 428.5, // iphone 12 pro max
				md: 540.5, // ipad mini : 768 but galaxy tab A8 800px 임을 반영
				lg: 720.5, // ipad pro : 1024
				xl: 1080.5, // maximum container
			},
		},
	}
);
