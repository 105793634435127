import { PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { DatePicker, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { CardPayDialog } from '../../modules/payments/CardPayDialog';
import { OtherPayDialog } from '../../modules/payments/OtherPayDialog';
import { CashPayDialog } from '../../modules/payments/CashPayDialog';

const today = dayjs();
export interface OrderEditDialogProps extends XlDialogProps {}
export const KyulsanOrderDialog = ({ open, closeDialog }: OrderEditDialogProps) => {
	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [targetDate, setTargetDate] = useState(today);
	const [isRefund, setIsRefund] = useState(false);

	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);

	const customConfirm = useConfirm();

	const handleKyulsan = OrderState.actions.useKyulsanOrderPayment();
	const onClickPayment = async (args: PaymentRequestInfoType) => {
		await handleKyulsan.mutateAsync(
			{
				paymentRequestData: args,
				posId: posId,
				targetDate: targetDate,
				isRefund,
			},
			{
				onSuccess: () =>
					customConfirm(`${isRefund ? '마이너스 ' : ''} 결제가 완료되었습니다.`, () => {
						closeDialog && closeDialog();
					}),
			}
		);
	};

	return (
		<WithDialog.XlDialog
			open={open}
			scroll='body'
			closeDialog={closeDialog}
			dialogTitle={'결산 주문'}>
			<DialogContent>
				<Stack
					width={400}
					spacing={3}
					py={3}>
					<Stack
						direction={'row'}
						spacing={1}
						alignItems={'center'}
						justifyContent={'flex-end'}>
						<Switch
							value={isRefund}
							onChange={() => setIsRefund((prev) => !prev)}
						/>
						<Typography variant='body1'>반품 (마이너스 결제)</Typography>
					</Stack>
					<Stack>
						<TextField
							size={'small'}
							InputProps={{
								readOnly: true,
							}}
							label={<Typography variant={'h4'}>{'결제 총액'}</Typography>}
							value={numberWithCommasAnd원(isRefund ? -totalToPay : totalToPay)}
						/>
					</Stack>
					<Stack width={400}>
						<DatePicker
							label={<Typography variant={'h4'}>{'날짜 선택'}</Typography>}
							value={targetDate}
							minDate={today.add(-1, 'year')}
							maxDate={today}
							onChange={(value) => {
								if (value) setTargetDate(value);
							}}
							usageType={'POS'}
						/>
					</Stack>
					<Typography
						variant='body2'
						color='text.secondary'>
						결산주문을 이용하여 오늘 이전의 날짜를 정하여 결제 혹은 환불 결제가 가능합니다.
					</Typography>
				</Stack>
				<DialogActions>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CardPayDialog
								is결산모드
								결제할금액={isRefund ? -totalToPay : totalToPay}
								open={open}
								closeDialog={closeDialog}
								handleSubmitPayment={onClickPayment}
								storeId={storeId}
								posId={posId}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={isRefund || totalToPay <= 0}
								variant='contained'
								onClick={openDialog}>
								카드
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CardPayDialog
								is결산모드
								결제할금액={isRefund ? -totalToPay : totalToPay}
								open={open}
								closeDialog={closeDialog}
								handleSubmitPayment={onClickPayment}
								storeId={storeId}
								posId={posId}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant='contained'
								onClick={openDialog}>
								가상카드
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CashPayDialog
								is결산모드
								결제할금액={isRefund ? -totalToPay : totalToPay}
								open={open}
								closeDialog={closeDialog}
								handleSubmitPayment={onClickPayment}
								storeId={storeId}
								posId={posId}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant='contained'
								onClick={openDialog}>
								현금
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<OtherPayDialog
								is결산모드
								결제할금액={isRefund ? -totalToPay : totalToPay}
								open={open}
								closeDialog={closeDialog}
								handleSubmitPayment={onClickPayment}
								storeId={storeId}
								posId={posId}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant='contained'
								onClick={openDialog}>
								계좌이체
							</Button>
						)}
					</WithDialog>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						취소
					</Button>
				</DialogActions>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
