import { PosElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Drawer, IconButton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { DrawerHeader } from './DrawerHeader';
import { KitchenPosDrawerContents } from './KitchenPosDrawerContents';
import { MainPosDrawerContents } from './MainPosDrawerContents';

export type GuestVisitTabValueType = 'WAITING' | 'COMPLETED' | 'OTHERS' | 'POS-BOARD';
export type KitchenOrderTabValueType = 'ALL' | 'ORDER-COOK' | 'COMPLETE-CANCEL';
export type PosSideBarTabValueType = GuestVisitTabValueType | KitchenOrderTabValueType;

interface IPosSideDrawerProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}
export const PosSideDrawer: React.FC<IPosSideDrawerProps> = ({ open, setOpen }) => {
	const posInfo = useAtomValue(StoreInfoState.curPosInfo);
	const isKitchenPos = posInfo.posType === 'KITCHEN' && !!posInfo.kitchenSystemId;
	const defaultSidebar =
		PosElectronStoreState.usePosMainPageLocalSettings().data?.defaultSidebar ?? 'VISIT';

	return (
		<Drawer
			anchor='left'
			open={open}
			onClose={() => {
				setOpen(false);
			}}>
			<Suspense fallback={<FullSizeCircularProgress />}>
				<Stack
					sx={{ bgcolor: grey[200] }}
					height={'100%'}
					overflow={'auto'}
					minWidth={582}>
					{/* <DrawerHeader handleClose={() => setOpen(false)} /> */}
					<Stack
						flex={1}
						p={1}
						spacing={1}
						overflow={'auto'}
						minWidth={562}>
						{isKitchenPos && (
							<KitchenPosDrawerContents
								open={open}
								setOpen={setOpen}
							/>
						)}
						{!isKitchenPos && (
							<MainPosDrawerContents
								open={open}
								setOpen={setOpen}
								defaultSidebar={defaultSidebar}
							/>
						)}
					</Stack>
				</Stack>
			</Suspense>
		</Drawer>
	);
};
