import { AdminSignupDeviceInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { SignupDeviceInfo } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { DeviceTokenQueryState } from '../DeviceTokenQueryState';
import { QueryClient } from '../QueryClient';

const signupDeviceInfoKeys = createQueryKeys('signupDeviceInfo', {
	list: (storeId: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await AdminSignupDeviceInfoApis.getSignupDeviceInfoList({ storeId: storeId });
			return res;
		},
	}),
	getOne: (storeId: number, deviceId: number) => ({
		queryKey: [storeId, deviceId],
		queryFn: async () => {
			return await AdminSignupDeviceInfoApis.getSignupDeviceInfo({ storeId, deviceId });
		},
	}),
});

const useCreateSignupDeviceInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<SignupDeviceInfo, 'id'>) => {
			await AdminSignupDeviceInfoApis.createSignupDeviceInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useUpdateSignupDeviceInfo = () => {
	return QueryClient.useMutation(
		async (input: SignupDeviceInfo) => {
			return await AdminSignupDeviceInfoApis.updateSignupDeviceInfo(input);
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useDeleteSignupDeviceInfo = () => {
	return QueryClient.useMutation(
		async (waiverId: number) => {
			await AdminSignupDeviceInfoApis.deleteSignupDeviceInfoList({ idList: [waiverId] });
		},
		{
			onSuccess: (data) => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteSignupDeviceInfoList = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await AdminSignupDeviceInfoApis.deleteSignupDeviceInfoList({ idList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: signupDeviceInfoKeys._def });
};

export const AdminSignupDeviceInfoState = {
	keys: signupDeviceInfoKeys,
	useCreateSignupDeviceInfo: useCreateSignupDeviceInfo,
	useUpdateSignupDeviceInfo: useUpdateSignupDeviceInfo,
	useDeleteSignupDeviceInfo: useDeleteSignupDeviceInfo,
	useDeleteSignupDeviceInfoList: useDeleteSignupDeviceInfoList,
};
