import { CheckOutlined } from '@ant-design/icons';
import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';

export interface ICompleteButtonProps {
	onClick: () => void;
	disabled?: boolean;
	label?: string;
}
export const CompleteButton: React.FC<ICompleteButtonProps> = ({ onClick, disabled, label }) => {
	return (
		<IconTextButton
			disabled={disabled}
			iconSize={'large'}
			onClick={onClick}
			icon={<CheckIcon fontSize='small' />}
			label={<Typography>{label || '조리 완료'}</Typography>}
		/>
	);
};
