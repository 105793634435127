import { AuthApis } from '@kinderlabs-pos/apis/storeinfo';
import { authState } from '@kinderlabs-pos/state';
import { AxiosInstance } from 'axios';
import qs from 'qs';

export const setAxiosRequestTokenInterceptor = (axiosInstance: AxiosInstance) => {
	axiosInstance.interceptors.request.use((config) => {
		if (!config.headers) return config;

		let accessToken = localStorage.getItem('accessToken');
		const refreshToken = localStorage.getItem('refreshToken');
		const enterpriseHash = localStorage.getItem('enterpriseHash');

		if (!config.url?.includes('/auth') && accessToken) {
			//
			const isTokenExpiringSoon = authState.isTokenExpiringSoon(accessToken);
			if (isTokenExpiringSoon) {
				AuthApis.reissueTokenAsync();
			}
		}

		accessToken = localStorage.getItem('accessToken');

		if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
		if (refreshToken) config.headers['refreshToken'] = `Bearer ${refreshToken}`;
		config.headers['Enterprise-Hash'] = enterpriseHash || '';
		// ngrok 테스트 시, BE와 원활한 통신을 위함.
		config.headers['ngrok-skip-browser-warning'] = true;

		config.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

		return config;
	});
};
