import { Stack } from '@mui/material';
import backIconImage from 'assets/vaunce/image/back.png';
import closeIconImage from 'assets/vaunce/image/close.png';
import { useNavigate } from 'react-router-dom';

export interface ILoginPageHeaderProps {
	disableBack?: boolean;
}
export const LoginPageHeader: React.FC<ILoginPageHeaderProps> = ({ disableBack = false }) => {
	const navigate = useNavigate();
	return (
		<Stack
			direction={'row'}
			justifyContent='flex-end'>
			{!disableBack && (
				<img
					style={{ marginRight: 'auto' }}
					src={backIconImage}
					height={24}
					width={24}
					onClick={() => {
						navigate(-1);
					}}
				/>
			)}

			<img
				src={closeIconImage}
				height={24}
				width={24}
				onClick={() => {
					navigate('/');
				}}
			/>
		</Stack>
	);
};
