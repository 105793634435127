import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteWarehouseInfoAsync = (rootUrl: string) => async (warehouseId: number) =>
	(
		await posAxiosClient.delete(`${rootUrl}/warehouse`, {
			params: {
				warehouseId,
			},
		})
	).data;
