import { PremiumMutType } from '@kinderlabs-pos/shared-data-type';
import { CloseIconBox } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	Dialog,
	DialogContent,
	DialogProps,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import QrCode from '../../../components/QrCode';
export interface IMultiTicketQrDialogProps extends DialogProps {
	ticket: PremiumMutType;
	onClose: () => void;
}
export const MultiTicketQrDialog: React.FC<IMultiTicketQrDialogProps> = ({
	open,
	onClose,
	ticket,
}) => {
	return (
		<Dialog
			maxWidth='md'
			open={open}
			onClose={onClose}
			fullWidth>
			<DialogContent>
				<Stack
					direction={'row'}
					justifyContent={'flex-end'}>
					<IconButton onClick={onClose}>
						<CloseIconBox />
					</IconButton>
				</Stack>

				<Stack>
					<Box
						textAlign={'center'}
						margin={'40px 0 40px'}>
						<Typography variant='h5'>{ticket.centerName}</Typography>
						<Typography variant='h5'>유효기간 ({ticket.expiredAt} 까지)</Typography>
						<QrCode
							qrData={ticket.orderNum}
							style={{ marginTop: '40px' }}
						/>
						<Typography variant='h5'>다회권 QR 코드</Typography>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
