import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { ManagerTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { QueryClient, StoreInfoState, authState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import MenuIcon from '@mui/icons-material/Menu';
import {
	Divider,
	IconButton,
	IconButtonProps,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import {
	ddizisRouteObject,
	exitsRouteObject,
	guestVisitsRouteObject,
	kitchenOrderRouteObject,
	ordersRouteObject,
	paymentManagmentsRouteObject,
	settingRouteObject,
	statisticsRouteObject,
	storeCloseObject,
	usePosRouter,
	vaultcashCheckObject,
} from '../../pages/routes';
import { VaultcashChecker } from '../VaultcashChecker';
import { useResetAtom } from 'jotai/utils';

export interface IMainMenuProps extends Omit<IconButtonProps, 'onClick'> {
	cannotNavigate: boolean;
}
export const MainMenuList: React.FC<IMainMenuProps> = ({ sx, cannotNavigate, ...others }) => {
	const user = useAtomValue(authState.user);
	const posInfo = useAtomValue(StoreInfoState.curPosInfo);
	const { open, anchorEl, handleClickMenu, handleCloseMenu } = useMenuCommand();

	const confirm = useConfirm();
	const {
		navigate세팅관리,
		navigate시재관리,
		navigateToLogin,
		navigateToSelectStore,
		navigate영업마감,
		navigate대기관리,
		navigate결제조회,
		navigate주문조회,
		navigate티켓관리,
		navigate퇴장관리,
		navigate통계,
		navigate주방주문조회,
	} = usePosRouter();

	const reset시재점검 = useResetAtom(VaultcashChecker.checkAtom);
	const logout = useSetAtom(authState.logout);
	const handleLogout = () => {
		confirm('로그아웃 하시겠습니까?', async () => {
			await logout();

			reset시재점검();
			navigateToLogin();
			QueryClient.origin.clear();
		});
	};

	const handleToSelectStore = () => {
		confirm('매장을 다시 선택하시겠습니까?', async () => {
			reset시재점검();
			navigateToSelectStore();
			QueryClient.origin.clear();
		});
	};

	// navigate 시 간헐적으로 메뉴가 다시뜨는 문제 해결
	const safeNavigate = (navigate: () => void) => setTimeout(() => navigate(), 100);

	return (
		<>
			<IconButton
				{...others}
				onClick={handleClickMenu}>
				<MenuIcon sx={sx} />
			</IconButton>
			<Menu
				id='main-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}>
				<MenuItem>
					<Stack>
						<Typography variant='subtitle1'>{`${user?.name}(${
							user?.authority ? ManagerTypeLabel[user.authority] : ''
						})`}</Typography>
						<Typography
							variant='body1'
							color={'text.secondary'}>{`${user?.email}`}</Typography>
					</Stack>
				</MenuItem>
				<Divider />
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						safeNavigate(() => navigate세팅관리());
					}}>
					{settingRouteObject.title}
				</MenuItem>
				<Divider />
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() =>
							posInfo.posType === 'KITCHEN' ? navigate주방주문조회() : navigate대기관리()
						);
					}}>
					{posInfo.posType === 'KITCHEN'
						? kitchenOrderRouteObject.title
						: guestVisitsRouteObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() => navigate주문조회());
					}}>
					{ordersRouteObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() => navigate퇴장관리());
					}}>
					{exitsRouteObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() => navigate결제조회());
					}}>
					{paymentManagmentsRouteObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() => navigate티켓관리());
					}}>
					{ddizisRouteObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						safeNavigate(() => navigate시재관리());
					}}>
					{vaultcashCheckObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						safeNavigate(() => navigate영업마감());
					}}>
					{storeCloseObject.title}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						// autofocus 문제로 navitage를 큐 맨뒤로 보냄
						safeNavigate(() => navigate통계());
					}}>
					{statisticsRouteObject.title}
				</MenuItem>
				{/* 
          // 영업 마감 기능이 현재 별로 없으므로 우선 시재 점검쪽으로 기능을 옮김. 
          // 영업 마감 기능이 확장되면 다시 살리자.
        <MenuItem
					disabled={cannotNavigate}
					onClick={() => {
						handleCloseMenu();
						navigate영업마감();
					}}>
					{storeCloseObject.title}
				</MenuItem> */}
				<Divider />
				<MenuItem
					disabled={cannotNavigate}
					onClick={handleToSelectStore}>
					{'매장 재선택'}
				</MenuItem>
				<MenuItem
					disabled={cannotNavigate}
					onClick={handleLogout}>
					{'로그아웃'}
				</MenuItem>
				<MenuItem
					onClick={() => {
						confirm('종료하시겠습니까?', () => {
							ReactCommonCommandIPC.closeApp();
						});
					}}>
					{'종료'}
				</MenuItem>
			</Menu>
		</>
	);
};

const useMenuCommand = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return {
		open,
		anchorEl,
		handleClickMenu,
		handleCloseMenu,
	};
};
