import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, MutInfoType } from '@kinderlabs-pos/shared-data-type';

export const getMutInfoDeatilAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<MutInfoType & BaseInfoType>(`${rootUrl}/mut`, {
			params: {
				id: id,
			},
		})
	).data;
