import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderTypeWithDdizisAndExchanges } from '@kinderlabs-pos/shared-data-type';

export const searchForDdiziAsync =
	(rootUrl: string) =>
	async ({
		// 주체
		storeId,
		posId,
		search,
		// 조회 대상
		storeIdList,
	}: {
		storeId: number;
		posId: number;
		search: string;
		storeIdList: number[];
	}) =>
		(
			await posAxiosClient.get<OrderTypeWithDdizisAndExchanges[]>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/order/search-ddizi`,
				{
					params: {
						search,
						storeIdList,
					},
				}
			)
		).data;
