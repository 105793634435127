import {
	ExpandedState,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getGroupedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	RowData,
	TableOptions,
	useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useEffect } from 'react';
import { CustomTableOptions } from '../types';
import { NewMuiTables } from './NewMuiTables';

export const useExpandableReactTable = <TData extends RowData>({
	reactTableOptions,
}: {
	reactTableOptions: Omit<
		CustomTableOptions<TData>,
		'getCoreRowModel' | 'getExpandedRowModel' | 'getGroupedRowModel' | 'onExpandedChange'
	>;
}) => {
	const { state, ...otherOptions } = reactTableOptions;
	const [expanded, setExpanded] = React.useState<ExpandedState>({});

	const table = useReactTable({
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getGroupedRowModel: getGroupedRowModel(),
		onExpandedChange: setExpanded,
		enableRowSelection: false,
		state: {
			expanded,
			...state,
		},
		...otherOptions,
	});

	const TableComponent = useCallback(
		() => (
			<NewMuiTables
				table={table}
				setSorting={table.options.onSortingChange}
			/>
		),
		[table]
	);

	return { table, TableComponent };
};

export const useSimpleReactTable = <TData extends RowData>({
	reactTableOptions,
	hasCheckbox,
}: {
	reactTableOptions: Omit<
		CustomTableOptions<TData>,
		'getCoreRowModel' | 'getExpandedRowModel' | 'getGroupedRowModel' | 'onExpandedChange'
	> & { enableRowSelection?: TableOptions<TData>['enableRowSelection'] };
	hasCheckbox?: boolean;
}) => {
	const { state, enableRowSelection = false, ...otherOptions } = reactTableOptions;

	const table = useReactTable({
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		enableRowSelection,
		state,
		...otherOptions,
	});

	const TableComponent = useCallback(
		() => (
			<NewMuiTables
				table={table}
				setSorting={table.options.onSortingChange}
				hasCheckbox={hasCheckbox}
			/>
		),
		[table]
	);

	return {
		table,
		TableComponent,
	};
};

/**
 * enableRowSelection 설정이 필수이다.
 */
export const useControlledReactTable = <TData extends RowData>({
	reactTableOptions,
	hasCheckbox,
	useAutoSorting = false,
	useAutoPagination = false,
	withoutPagination = false,
	onlyPaginationNumbers = false,
}: {
	reactTableOptions: Omit<
		CustomTableOptions<TData>,
		'getCoreRowModel' | 'getExpandedRowModel' | 'getGroupedRowModel' | 'onExpandedChange'
	> & { enableRowSelection: NonNullable<TableOptions<TData>['enableRowSelection']> };
	hasCheckbox?: boolean;
	useAutoSorting?: boolean;
	useAutoPagination?: boolean;
	withoutPagination?: boolean;
	onlyPaginationNumbers?: boolean;
}) => {
	const { state, enableRowSelection, ...options } = reactTableOptions;

	const table = useReactTable({
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualSorting: !useAutoSorting,
		manualPagination: !useAutoPagination,
		enableRowSelection,
		state,
		...options,
	});

	useEffect(() => {
		table.resetRowSelection();
	}, [table.getState().sorting, table.getState().pagination]);

	const TableComponent = useCallback(
		() => (
			<NewMuiTables
				table={table}
				setSorting={table.options.onSortingChange}
				hasCheckbox={hasCheckbox}
				withoutPagination={withoutPagination}
				onlyPaginationNumbers={onlyPaginationNumbers}
			/>
		),
		[table]
	);

	return {
		table,
		TableComponent,
	};
};
