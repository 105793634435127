import { DdiziType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { SearchBox } from '@kinderlabs-pos/ui-components';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { atomWithReset, useAtomCallback, useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { FilterWith전체Selector } from '../../composites/FilterWithAllSelector';

const filterAtom = atomWithReset<{
	type?: 'ONLINE' | 'ONETIME' | 'MULTIUSE' | 'COMMUTATION';
	search: string;
	pageable: Pageable;
}>({
	pageable: Pageable.getPageable({ page: 0, size: 10 }),
	type: undefined,
	search: '',
});

const ddiziPageAtom = atom<PageType<DdiziType> | undefined>(undefined);

const useSearch = () => {
	// rerendering 이 얼마나 될런지를 모르겠다.
	const [filter, setFilter] = useAtom(filterAtom);
	const [ddiziPage, setDdiziPage] = useAtom(ddiziPageAtom);
	const resetFilter = useResetAtom(filterAtom);

	const handleSearch = useAtomCallback<void, number | undefined>(async (get, set, page) => {
		setFilter((state) => {
			state.pageable.page = page || 0;
			return { ...state, pageable: state.pageable };
		});

		// 추후에 StoreInfoState.curPosInfo 의 리팩토링을 기다려보자.
		if (!get(StoreInfoState.curPosInfo) || !get(StoreInfoState.curStoreInfo)) return;
	});

	return { ddiziPage, filter, setFilter, resetFilter, handleSearch };
};

// 여기에선 추후 더 많은 정보를 보여주도록 하자. 여기에선 환불 신청도 가능하다.

export interface IReserveTicketsPageProps {}
export const ReserveTicketsPage: React.FC<IReserveTicketsPageProps> = ({}) => {
	const { ddiziPage, filter, setFilter, resetFilter, handleSearch } = useSearch();

	useEffect(() => {
		resetFilter();
		handleSearch();
	}, []);

	// const users = [
	//   test핫현이팀[0],
	//   test핫현이팀[1]
	// ]

	return (
		<Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 1 }}>
			<Stack
				direction={'row'}
				justifyContent={'center'}
				spacing={1}
				pt={2}>
				<Box width={120}>
					<FilterWith전체Selector
						label={'타입'}
						candidates={[
							{ label: '온라인', value: 'ONLINE' },
							{ label: '일회권', value: 'ONETIME' },
							{ label: '다회권', value: 'MULTIUSE' },
							{ label: '정기권', value: 'COMMUTATION' },
						]}
						value={filter.type}
						onChangeValue={(val) => {
							setFilter((state) => ({
								...state,
								type: val as 'ONLINE' | 'ONETIME' | 'MULTIUSE' | 'COMMUTATION' | undefined,
							}));
							// handleSearch();
						}}
					/>
				</Box>
				<SearchBox
					onChange={(val) => {
						// setSearchValue(val)
					}}
					hasDeleteKey
					hasSearchButton
					onSearch={handleSearch}
				/>
			</Stack>
			<Stack
				direction={'row'}
				spacing={1}
				justifyContent={'center'}>
				<Typography variant={'body1'}>
					{'예약번호, 대표자 이름, 대표자 전화번호 뒷자리로 검색됩니다.'}
				</Typography>
			</Stack>
			{/* <Table.Container sx={{ flex: 1, overflowY: "auto" }}>
        <UserInfoBox userInfo={users[0]} />
        <Divider />
        <OnlineTicketTable dummyData={dummyOnlineTickets.filter(data =>
          data.user.telephone === users[0].telephone)} />
        <Divider />
      </Table.Container> */}
		</Paper>
	);
};
