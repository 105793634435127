import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import {
	Button,
	Container,
	ContainerProps,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { usePosLoginFormik } from './usePosLoginFormik';

export interface IPosLoginFormProps {
	onLogin: (args?: { email: string; password: string }) => void;
	onClose: () => void;
	recentId?: string;
	disableLogin?: boolean;
	loginButtonLabel?: ReactNode;
	exitButtonLabel?: ReactNode;
	ContainerProps?: ContainerProps;
}
export const PosLoginForm: React.FC<IPosLoginFormProps> = ({
	onLogin,
	onClose,
	recentId,
	disableLogin = false,
	loginButtonLabel = 'LOGIN',
	exitButtonLabel = 'EXIT',
	ContainerProps,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.SyntheticEvent) => {
		event.preventDefault();
	};

	const [capsWarning, setCapsWarning] = useState(false);
	const onKeyDown = (keyEvent: any) => {
		if (keyEvent.getModifierState('CapsLock')) {
			setCapsWarning(true);
		} else {
			setCapsWarning(false);
		}
	};

	const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } =
		usePosLoginFormik({ onLogin, recentId });

	return (
		<Container
			sx={{ width: 320 }}
			{...ContainerProps}>
			<form
				noValidate
				onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<Stack spacing={1}>
						<InputLabel htmlFor='email-login'>Email Address</InputLabel>
						<OutlinedInput
							id='email-login'
							type='email'
							value={values.email}
							name='email'
							onBlur={handleBlur}
							onChange={handleChange}
							placeholder='Enter email address'
							fullWidth
							error={Boolean(touched.email && errors.email)}
						/>
						{touched.email && errors.email && (
							<FormHelperText
								error
								id='standard-weight-helper-text-email-login'>
								{errors.email as any}
							</FormHelperText>
						)}
					</Stack>
					<Stack spacing={1}>
						<InputLabel htmlFor='password-login'>Password</InputLabel>
						<OutlinedInput
							fullWidth
							color={capsWarning ? 'warning' : 'primary'}
							error={Boolean(touched.password && errors.password)}
							id='-password-login'
							type={showPassword ? 'text' : 'password'}
							value={values.password}
							name='password'
							onBlur={(event: React.FocusEvent<any, Element>) => {
								setCapsWarning(false);
								handleBlur(event);
							}}
							onKeyDown={onKeyDown}
							onChange={handleChange}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
									</IconButton>
								</InputAdornment>
							}
							placeholder='Enter password'
						/>
						{capsWarning && (
							<Typography
								variant='caption'
								sx={{ color: 'warning.main' }}
								id='warning-helper-text-password-login'>
								Caps lock on!
							</Typography>
						)}
						{touched.password && errors.password && (
							<FormHelperText
								error
								id='standard-weight-helper-text-password-login'>
								{errors.password}
							</FormHelperText>
						)}
					</Stack>
					{errors.submit && (
						<Stack>
							<Typography
								variant={'body2'}
								color={'error'}>
								{errors.submit}
							</Typography>
						</Stack>
					)}
					<Stack
						direction='row'
						spacing={2}>
						<Button
							sx={{ flex: 2 }}
							onClick={onClose}
							variant='outlined'>
							{exitButtonLabel}
						</Button>
						<Button
							sx={{ flex: 5 }}
							disableElevation
							disabled={isSubmitting || disableLogin}
							fullWidth
							type='submit'
							variant='contained'
							color='primary'>
							{loginButtonLabel}
						</Button>
					</Stack>
				</Stack>
			</form>
		</Container>
	);
};
