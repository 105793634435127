import { ErrorMessageType } from '@kinderlabs-pos/shared-data-type';
import { PosElectronStoreState, authState } from '@kinderlabs-pos/state';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import * as Yup from 'yup';

export const usePosLoginFormik = ({
	onLogin,
	recentId,
}: {
	onLogin: (args?: { email: string; password: string }) => void;
	recentId?: string;
}) => {
	const login = useSetAtom(authState.login);

	return useFormik({
		initialValues: {
			email: recentId || '',
			password: '',
			terms: true,
			submit: null,
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('유효한 Email 주소가 아닙니다.')
				.max(255)
				.required('Email 주소가 필요합니다.'),
			password: Yup.string().max(255).required('비밀번호를 입력해주세요.'),
		}),
		enableReinitialize: true,
		onSubmit: async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
			setSubmitting(true);
			try {
				await login(values);

				onLogin({ email: values.email, password: values.password });
			} catch (error) {
				setErrors({ submit: (error as ErrorMessageType).message });
			} finally {
				setSubmitting(false);
			}
		},
	});
};
