import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import { OptionGroupListType, ProductInfoType } from '@kinderlabs-pos/shared-data-type';
import { useScrollControl } from '@kinderlabs-pos/shared-react-util';
import {
	getUuidV4,
	numberWithCommas,
	numberWithCommasAnd원PlusMinus,
} from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { useAtom } from 'jotai';

export const ProductSetSelectDialog = ({
	productInfo,
	open,
	closeDialog,
	closeSearchDialog,
	...otherProps
}: XlDialogProps & {
	productInfo: ProductInfoType;
	closeSearchDialog?: () => void;
}) => {
	const theme = useTheme();
	const cartReducer = OrderState.cart.useDispatcher();
	const [selectedCartLine, selectCartLine] = useAtom(CartActionBoard.selectedCartLineAtom);
	const { isValid, selectedOptionMap, handleSelectedOptionIdList } =
		OrderState.cart.utils.useSetProductOptionSelectMap({ productInfo, initialSelect: true });

	const handleClick장바구니추가 = () => {
		const selectedOptions = (productInfo.optionGroupList || []).flatMap((optionGroup) => {
			return optionGroup.optionList
				.filter((option) =>
					// selected 된 것만 가져와서
					selectedOptionMap[optionGroup.id].includes(option.id)
				)
				.map((option) => ({
					// isForKitchen 을 맞춰넣은 후
					...option,
					isForKitchen: optionGroup.isForKitchen,
				}));
		});

		cartReducer({
			type: 'PRODUCTS',
			subAction: {
				type: 'ADD_SET',
				cartLineId: getUuidV4(),
				productInfo: productInfo,
				selectedOptions,
				onComplete: (cartLine) => {
					selectCartLine(cartLine);
					closeDialog && closeDialog();
				},
			},
		});
	};

	const { isScrollButtonRequired, isTop, isBottom, scrollUp, scrollDown, setScrollRef, onScroll } =
		useScrollControl({ scrollSize: 300 });

	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={'세트상품 옵션 선택'}
			{...otherProps}>
			<DialogContent>
				<Stack
					position={'relative'}
					flex={1}
					overflow={'hidden'}
					width={584}
					height={468}
					spacing={1}>
					<Stack>
						<Typography variant={'h4'}>{productInfo.name}</Typography>
						<Typography
							variant={'body2'}
							color={'text.secondary'}>
							{numberWithCommas(productInfo.price)}
						</Typography>
					</Stack>
					<Stack>
						<Typography variant={'body2'}>{productInfo.description}</Typography>
					</Stack>
					<Stack
						flex={1}
						overflow={'auto'}>
						<Stack
							pr={'80px'}
							ref={setScrollRef}
							onScroll={onScroll}
							flex={1}
							overflow={'auto'}>
							{(productInfo.optionGroupList || []).map((optionGroup, idx) => {
								return (
									<Stack key={idx}>
										<Stack>
											<Stack
												direction={'row'}
												spacing={1}>
												<Stack
													direction={'row'}
													alignItems={'center'}
													spacing={1}
													flex={1}>
													<Typography variant='h5'>{`[${
														optionGroup.minSelect === 0 ? '선택' : '필수'
													} 옵션] ${
														optionGroup.isMultipleCheckable ? '(복수 선택)' : ''
													}`}</Typography>
													<Typography variant='subtitle1'>{optionGroup.name}</Typography>
													<Typography variant='subtitle1'>{`(${optionGroup.minSelect} ~ ${optionGroup.maxSelect} 개 선택)`}</Typography>
												</Stack>
												<Typography variant='subtitle1'>
													{optionGroup.isForKitchen ? '주방 영수증 포함' : ''}
												</Typography>
											</Stack>
											<Typography
												variant='body2'
												color={'text.secondary'}>
												{optionGroup.description}
											</Typography>
										</Stack>
										<Stack
											width={'100%'}
											direction={'row'}
											spacing={1}>
											<OptionGroupFormGroup
												optionGroup={optionGroup}
												selectedOptionIdList={selectedOptionMap[optionGroup.id]}
												setSelectedOptionIdList={handleSelectedOptionIdList(optionGroup.id)}
											/>
										</Stack>
										<Divider flexItem />
									</Stack>
								);
							})}
							<Stack
								key={isScrollButtonRequired ? 'show' : 'hidden'}
								display={!isScrollButtonRequired ? 'none' : undefined}
								position={'absolute'}
								sx={{ bottom: 5, right: 15 }}>
								<IconButton
									disabled={isTop}
									size='large'
									onClick={scrollUp}>
									<UpCircleFilled
										style={{
											color: !isTop ? theme.palette.primary.main : undefined,
										}}
									/>
								</IconButton>
								<IconButton
									disabled={isBottom}
									size='large'
									onClick={scrollDown}>
									<DownCircleFilled
										style={{
											color: !isBottom ? theme.palette.primary.main : undefined,
										}}
									/>
								</IconButton>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					spacing={2}>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						닫기
					</Button>
					<Button
						variant='contained'
						disabled={!isValid}
						onClick={() => {
							handleClick장바구니추가();
							closeSearchDialog && closeSearchDialog();
						}}>
						장바구니 추가
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const OptionGroupFormGroup = ({
	selectedOptionIdList,
	setSelectedOptionIdList,
	optionGroup,
}: {
	selectedOptionIdList: number[];
	setSelectedOptionIdList: (optionList: number[]) => void;
	optionGroup: OptionGroupListType;
}) => {
	const getExclusiveIdListWithLIFO = OrderState.cart.utils.useLIFOIdList({
		maxSelect: optionGroup.maxSelect,
		selectedIdList: selectedOptionIdList,
	});

	const handleClick = (optionId: number) => {
		if (selectedOptionIdList.includes(optionId)) {
			setSelectedOptionIdList(selectedOptionIdList.filter((id) => id !== optionId));
		} else {
			setSelectedOptionIdList(getExclusiveIdListWithLIFO(optionId));
		}
	};

	return (
		<FormGroup>
			{optionGroup.optionList.map((option, idx) => (
				<FormControlLabel
					key={idx}
					control={
						<Checkbox
							checked={selectedOptionIdList.includes(option.id)}
							onChange={(e) => handleClick(+e.currentTarget.value)}
						/>
					}
					label={`${option.name} (${numberWithCommasAnd원PlusMinus(option.price)})`}
					value={option.id}
				/>
			))}
		</FormGroup>
	);
};
