import { NumberOutlined, WarningOutlined } from '@ant-design/icons';
import { PaymentMethodTypeLabel, PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { PaymentState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	OutlinedInput,
	Stack,
	Typography,
	debounce,
} from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';

export const RetryPaymentDialogContents = ({
	onClose,
	paymentRequestInfo,
	handleSubmitPayment,
	errorMessage,
}: {
	onClose?: () => void;
	errorMessage?: string;
	paymentRequestInfo: PaymentRequestInfoType;
	handleSubmitPayment: (payment: PaymentRequestInfoType) => Promise<void>;
}) => {
	const customConfirm = useConfirm();
	const handleClick닫기 = () =>
		customConfirm(
			'강제 결제 취소 시, 중복 결제건 발생 여부를 확인해주세요.',
			() => onClose && onClose()
		);

	const { errors, handleSubmit, isSubmitting } = useFormik<{
		paymentRequestInfo: PaymentRequestInfoType;
		submit?: string;
	}>({
		initialValues: {
			paymentRequestInfo,
			submit: undefined,
		},
		validateOnMount: true,
		onSubmit: async (values, { setErrors, resetForm, setValues }) => {
			try {
				await handleSubmitPayment(values.paymentRequestInfo);

				onClose && onClose();
				resetForm();
			} catch (e) {
				// @ts-ignore
				setErrors({ submit: e.message });
				throw e;
			}
		},
	});

	const handleRefund중복결제 = PaymentState.actions.refund.useRefundOfficial중복결제();
	const {
		handleSubmit: handleClickRefund,
		errors: refundErrors,
		setValues: setRefundValues,
		values: refundValues,
	} = useFormik<{
		paymentRequestInfo: PaymentRequestInfoType;
		customerId?: string;
		submit: string | null;
	}>({
		initialValues: {
			paymentRequestInfo,
			customerId: undefined,
			submit: null,
		},
		validateOnMount: true,
		onSubmit: async (values, { setErrors }) => {
			try {
				await handleRefund중복결제({
					paymentInfo: values.paymentRequestInfo,
					customerId: values.customerId,
				});

				onClose && onClose();
			} catch (e) {
				//@ts-ignore
				setErrors({ submit: e?.message });
			}
		},
	});

	const needCustomerId = [
		'CASH',
		'TRANSFER',
		'KAKAOPAY',
		'SSGPAY',
		'APPCARD',
		'ZEROPAY',
		'SEOULPAY',
	].includes(paymentRequestInfo.method);
	const cannotRefund = needCustomerId ? !refundValues.customerId : false;

	const handleSubmitDebounce = useCallback(debounce(handleSubmit, 100), [handleSubmit]);

	return (
		<>
			<DialogContent sx={{ minWidth: 768 }}>
				{isSubmitting && (
					<Stack
						spacing={1}
						alignItems={'center'}>
						<CircularProgress
							color='secondary'
							sx={{ mb: 3 }}
						/>
						<Typography
							variant={'h4'}
							color={'secondary'}>
							데이터 전송중입니다.
						</Typography>
						<Typography
							variant={'h6'}
							color={'secondary'}>
							잠시만 기다려주세요. (최대 30초)
						</Typography>
					</Stack>
				)}
				{!isSubmitting && (
					<Stack
						spacing={1}
						alignItems={'center'}>
						<Stack
							direction={'row'}
							spacing={1}>
							<Typography
								variant={'h2'}
								color={'error'}>
								<WarningOutlined />
							</Typography>
							<Typography
								variant={'h2'}
								color={'error'}>
								서버 에러가 발생했습니다.
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							pt={3}
							spacing={1}>
							<Typography variant={'h3'}>
								{PaymentMethodTypeLabel[paymentRequestInfo.method]}
							</Typography>
							<Typography variant={'h3'}>
								{numberWithCommasAnd원(paymentRequestInfo.amount)}
							</Typography>
						</Stack>
						<Stack
							direction={'row'}
							pb={3}
							spacing={1}>
							<Typography variant={'subtitle2'}>
								{needCustomerId ? '번호 :' : '카드 번호 : '}
							</Typography>
							<Typography variant={'subtitle2'}>{paymentRequestInfo.receipt?.bin}</Typography>
						</Stack>
						{(errorMessage || errors.submit || refundErrors.submit) && (
							<Typography
								variant={'subtitle2'}
								color={'error'}>
								{errorMessage || errors.submit || refundErrors.submit}
							</Typography>
						)}
						<Stack
							spacing={1}
							alignItems={'center'}>
							<Typography variant={'body1'}>
								1. 다회권, 정기권, 온라인티켓, 앱쿠폰 이용시 해당 티켓들이 사용 가능한지
								확인해주세요.
							</Typography>
							<Typography variant={'body1'}>2. 네트워크가 정상인지 확인해주세요.</Typography>
						</Stack>
					</Stack>
				)}
			</DialogContent>

			<DialogActions>
				<Button
					disabled={isSubmitting}
					size={'large'}
					variant='contained'
					onClick={() => handleSubmitDebounce()}
					color={'primary'}>
					전송 재시도
				</Button>
				<Stack
					px={1}
					direction={'row'}
					spacing={1}>
					{needCustomerId && (
						<OutlinedInput
							sx={{ width: 240, height: 42 }}
							value={refundValues.customerId}
							onChange={(e) => {
								setRefundValues({ ...refundValues, customerId: e.target.value });
							}}
							disabled={isSubmitting}
							placeholder={
								['CASH', 'TRANSFER'].includes(paymentRequestInfo.method)
									? '전화번호/사업자번호 입력'
									: '바코드 입력'
							}
							startAdornment={
								<Box mr={1}>
									<NumberOutlined />
								</Box>
							}
						/>
					)}
					<Button
						disabled={isSubmitting || cannotRefund}
						onClick={() => handleClickRefund()}
						size={'large'}
						variant='contained'
						color={'error'}>
						결제 취소
					</Button>
				</Stack>
				<Button
					disabled={isSubmitting}
					size={'large'}
					onClick={handleClick닫기}
					variant='outlined'
					color={'primary'}>
					강제 닫기
				</Button>
			</DialogActions>
		</>
	);
};
