import { Stack, Typography } from '@mui/material';
import packageJson from 'package.json';
import { useMemo } from 'react';
import GitInfo from 'react-git-info/macro';

export const VersionDisplay = () => {
	const gitInfo = useMemo(() => GitInfo(), []);

	return (
		<Stack
			flex={1}
			position={'absolute'}
			sx={{
				bottom: 0,
				right: 0,
			}}
			direction={'row'}
			alignItems={'flex-end'}
			justifyContent={'flex-end'}
			p={2}>
			<Typography
				variant='subtitle1'
				color={
					'text.secondary'
				}>{`v${packageJson.version} (${gitInfo.commit.shortHash})`}</Typography>
		</Stack>
	);
};
