import {
	CartLineInfoType,
	CartLineTypeRules,
	CouponStatusLabel,
	CouponTypeLabel,
	VaunceCouponSearchInfoType,
	getDiscountLabel,
	isCouponUsable,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getDateTimeFormat, getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { VaunceAppTicketUserMemberInfoBox } from './common/VaunceAppTicketUserMemberInfoBox';
import { getSortedUserIdList } from './common/getSortedUserIdList';
import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { useCallback } from 'react';

export const VaunceAppCouponListContent = ({
	couponInfoMap,
}: {
	couponInfoMap: Record<
		string,
		(VaunceCouponSearchInfoType & {
			canUseTicket: boolean;
		})[]
	>;
}) => {
	const userIdList = getSortedUserIdList({ ticketOrCouponInfoMap: couponInfoMap });

	return (
		<Stack>
			{userIdList.map((memberId, idx) => {
				const { uid, userName, userId, userBirth, userTelephone } = couponInfoMap[memberId][0];

				return (
					<Stack
						key={idx}
						spacing={1}>
						<VaunceAppTicketUserMemberInfoBox
							uid={uid}
							userName={userName}
							userId={userId}
							userBirth={userBirth}
							userTelephone={userTelephone}
						/>
						<List>
							{couponInfoMap[memberId].map((coupon, couponIdx) => {
								return (
									<AppCouponUsageItem
										key={couponIdx}
										couponInfo={coupon}
									/>
								);
							})}
						</List>
					</Stack>
				);
			})}
		</Stack>
	);
};

const AppCouponUsageItem = ({ couponInfo }: { couponInfo: VaunceCouponSearchInfoType }) => {
	const couponUsage = useAtomValue(OrderState.cart.lines.ticketUsageSelector).filter(
		(usage) => usage.type === 'VAUNCE_APP_DISCOUNT' || usage.type === 'VAUNCE_APP_FREE_COUPON'
	);
	const nowUsing = couponUsage
		.map((u) => u.ticketUsageInfo.ticketUsageId)
		.includes(`${couponInfo.couponId}`);

	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);
	const canApplyDiscount = selectedCartLine
		? CartLineTypeRules.canApplyDiscount[selectedCartLine.type]
		: false;

	const handleClickUse = useHandleClickUse({ couponInfo, targetCartLine: selectedCartLine });
	const handleClickCancelUse = useHandleClickCancelUse({ couponInfo });

	return (
		<>
			<ListItem>
				<Stack
					direction={'row'}
					width={'100%'}
					spacing={1}>
					<Stack
						flex={1}
						spacing={1}>
						<Typography variant='h5'>{couponInfo.couponName}</Typography>
						<Typography
							variant='body2'
							color='text.secondary'>{`#${couponInfo.couponId}`}</Typography>
					</Stack>
					<Stack>
						<Typography variant='subtitle1'>
							{couponInfo.couponType === 'FREE_TICKET'
								? '티켓 증정'
								: getDiscountLabel({
										value: couponInfo.discount,
										type: couponInfo.couponType === 'DISCOUNT_PERCENT' ? 'PERCENTAGE' : 'VALUE',
								  })}
						</Typography>
						<Typography
							variant='body2'
							color='text.secondary'>
							{`${getDateFormat(couponInfo.expired)} 만료`}
						</Typography>
					</Stack>
					<Stack>
						{nowUsing ? (
							<Button
								variant={'outlined'}
								size={'small'}
								onClick={handleClickCancelUse}
								color={'warning'}>
								{'적용 취소'}
							</Button>
						) : (
							<Button
								variant={'outlined'}
								size={'small'}
								onClick={handleClickUse}
								disabled={couponInfo.couponType !== 'FREE_TICKET' && !canApplyDiscount}
								color={isCouponUsable[couponInfo.couponStatus] ? 'primary' : 'error'}>
								{CouponStatusLabel[couponInfo.couponStatus]}
							</Button>
						)}
					</Stack>
				</Stack>
			</ListItem>
			<Divider />
		</>
	);
};
// const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
// 	const couponUsage = useAtomValue(OrderState.cart.lines.ticketUsageSelector).filter(
// 		(usage) => usage.type === 'VAUNCE_APP_DISCOUNT'
// 	);
// 	const cartDispatch = OrderState.cart.useDispatcher();
// 	const nowUsing = couponUsage.map((u) => u.ticketUsageInfo.ticketUsageId).includes();

const useHandleClickUse = ({
	couponInfo,
	targetCartLine,
}: {
	couponInfo: VaunceCouponSearchInfoType;
	targetCartLine?: CartLineInfoType;
}) => {
	const dispatch = OrderState.cart.useDispatcher();
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);

	const minutes = storeInfo.policy.defaultMinutesInCenter;

	return useCallback(() => {
		if (couponInfo.couponType === 'FREE_TICKET') {
			dispatch({
				type: 'USAGES',
				subAction: {
					type: 'ADD_FREE_COUPON',
					cartLineId: getUuidV4(),
					ticketUsage: {
						type: 'FREECOUPON',
						id: couponInfo.couponId,
						name: `${couponInfo.couponName}`,
						useId: `${couponInfo.couponId}`,
						ticketId: couponInfo.couponId,
						ticketInfoId: couponInfo.couponId,
						memberId: couponInfo.uid,
						userName: couponInfo.userName,
						userTelephone: couponInfo.userTelephone,
						ddiziInfo: {
							name: `${couponInfo.couponName}-${minutes} 분 이용권`,
							ddiziType: 'NORMAL',
							minutes: minutes,
							hasNoAdditionalCharge: false,
							isNotForStatistics: false,
							available: 'ALL',
						},
					},
				},
			});

			return;
		} else {
			if (!targetCartLine) return;

			dispatch({
				type: 'USAGES',
				subAction: {
					type: 'ADD_DISCOUNT_TICKET',
					cartLineId: getUuidV4(),
					targetCartLineId: targetCartLine.id,
					targetPrice: targetCartLine.price,
					productDiscountInfo: {
						type: couponInfo.couponType === 'DISCOUNT_PERCENT' ? 'PERCENTAGE' : 'VALUE',
						value: couponInfo.discount,
						// evaluatedValue: remainingValue < discountValue ? remainingValue : discountValue,
					},
					ticketUsage: {
						type: 'DISCOUNT',
						id: couponInfo.couponId,
						name: `${couponInfo.couponName}`,
						useId: `${couponInfo.couponId}`,
						ticketId: couponInfo.couponId,
						ticketInfoId: couponInfo.couponId,
						memberId: couponInfo.uid,
						userName: couponInfo.userName,
						userTelephone: couponInfo.userTelephone,
					},
					taxFreeTarget: targetCartLine.productAdditionalInfo.taxFree,
				},
			});
		}
	}, [couponInfo, targetCartLine]);
};

const useHandleClickCancelUse = ({ couponInfo }: { couponInfo: VaunceCouponSearchInfoType }) => {
	const customConfirm = useConfirm();
	const cartDispatcher = OrderState.cart.useDispatcher();

	return useCallback(() => {
		customConfirm('앱쿠폰 사용을 취소하시겠습니까?', () => {
			cartDispatcher({
				type: 'USAGES',
				subAction: { type: 'REMOVE_DISCOUNT_TICKET', ticketId: couponInfo.couponId },
			});
		});
	}, [couponInfo.couponId]);
};
