import jukjeon_center1 from 'assets/vaunce/image/jukjeon_center1.jpg';
import jukjeon_center10 from 'assets/vaunce/image/jukjeon_center10.jpg';
import jukjeon_center11 from 'assets/vaunce/image/jukjeon_center11.jpg';
import jukjeon_center12 from 'assets/vaunce/image/jukjeon_center12.jpg';
import jukjeon_center13 from 'assets/vaunce/image/jukjeon_center13.jpg';
import jukjeon_center2 from 'assets/vaunce/image/jukjeon_center2.jpg';
import jukjeon_center3 from 'assets/vaunce/image/jukjeon_center3.jpg';
import jukjeon_center4 from 'assets/vaunce/image/jukjeon_center4.jpg';
import jukjeon_center5 from 'assets/vaunce/image/jukjeon_center5.jpg';
import jukjeon_center6 from 'assets/vaunce/image/jukjeon_center6.jpg';
import jukjeon_center7 from 'assets/vaunce/image/jukjeon_center7.jpg';
import jukjeon_center8 from 'assets/vaunce/image/jukjeon_center8.jpg';
import jukjeon_center9 from 'assets/vaunce/image/jukjeon_center9.jpg';

import samsung_center1 from 'assets/vaunce/image/samsung_center1.jpg';
import samsung_center2 from 'assets/vaunce/image/samsung_center2.jpg';
import samsung_center3 from 'assets/vaunce/image/samsung_center3.jpg';
import samsung_center4 from 'assets/vaunce/image/samsung_center4.jpg';
import samsung_center5 from 'assets/vaunce/image/samsung_center5.jpg';
import samsung_center6 from 'assets/vaunce/image/samsung_center6.jpg';
import samsung_center7 from 'assets/vaunce/image/samsung_center7.jpg';

import banpo_center1 from 'assets/vaunce/image/banpo_center1.jpg';
import banpo_center2 from 'assets/vaunce/image/banpo_center2.jpg';
import banpo_center3 from 'assets/vaunce/image/banpo_center3.jpg';
import banpo_center4 from 'assets/vaunce/image/banpo_center4.jpg';
import banpo_center5 from 'assets/vaunce/image/banpo_center5.jpg';
import banpo_center6 from 'assets/vaunce/image/banpo_center6.jpg';

import dongdaegu_center1 from 'assets/vaunce/image/dongdaegu_center1.jpg';
import dongdaegu_center2 from 'assets/vaunce/image/dongdaegu_center2.jpg';
import dongdaegu_center3 from 'assets/vaunce/image/dongdaegu_center3.jpg';
import dongdaegu_center4 from 'assets/vaunce/image/dongdaegu_center4.jpg';
import dongdaegu_center5 from 'assets/vaunce/image/dongdaegu_center5.jpg';
import dongdaegu_center6 from 'assets/vaunce/image/dongdaegu_center6.jpg';

import geoje_center1 from 'assets/vaunce/image/geoje_center1.jpg';
import geoje_center2 from 'assets/vaunce/image/geoje_center2.jpg';
import geoje_center3 from 'assets/vaunce/image/geoje_center3.jpg';
import geoje_center4 from 'assets/vaunce/image/geoje_center4.jpg';
import geoje_center5 from 'assets/vaunce/image/geoje_center5.jpg';
import geoje_center6 from 'assets/vaunce/image/geoje_center6.jpg';
import geoje_center7 from 'assets/vaunce/image/geoje_center7.jpg';
import geoje_center8 from 'assets/vaunce/image/geoje_center8.jpg';

import busan_center1 from 'assets/vaunce/image/busan_center1.jpg';
import busan_center2 from 'assets/vaunce/image/busan_center2.jpg';
import busan_center3 from 'assets/vaunce/image/busan_center3.jpg';
import busan_center4 from 'assets/vaunce/image/busan_center4.jpg';
import busan_center5 from 'assets/vaunce/image/busan_center5.jpg';
import busan_center6 from 'assets/vaunce/image/busan_center6.jpg';
import busan_center7 from 'assets/vaunce/image/busan_center7.jpg';
import busan_center8 from 'assets/vaunce/image/busan_center8.jpg';

import gumi_center1 from 'assets/vaunce/image/gumi_center1.jpg';
import gumi_center2 from 'assets/vaunce/image/gumi_center2.jpg';
import gumi_center3 from 'assets/vaunce/image/gumi_center3.jpg';
import gumi_center4 from 'assets/vaunce/image/gumi_center4.jpg';
import gumi_center5 from 'assets/vaunce/image/gumi_center5.jpg';
import gumi_center6 from 'assets/vaunce/image/gumi_center6.jpg';

import wolgye_center1 from 'assets/vaunce/image/wolgye_center1.jpg';
import wolgye_center2 from 'assets/vaunce/image/wolgye_center2.jpg';
import wolgye_center3 from 'assets/vaunce/image/wolgye_center3.jpg';
import wolgye_center4 from 'assets/vaunce/image/wolgye_center4.jpg';
import wolgye_center5 from 'assets/vaunce/image/wolgye_center5.jpg';
import wolgye_center6 from 'assets/vaunce/image/wolgye_center6.jpg';
import wolgye_center7 from 'assets/vaunce/image/wolgye_center7.jpg';
import wolgye_center8 from 'assets/vaunce/image/wolgye_center8.jpg';

import gwangju_center1 from 'assets/vaunce/image/gwangju_center1.jpg';
import gwangju_center2 from 'assets/vaunce/image/gwangju_center2.jpg';
import gwangju_center3 from 'assets/vaunce/image/gwangju_center3.jpg';
import gwangju_center4 from 'assets/vaunce/image/gwangju_center4.jpg';
import gwangju_center5 from 'assets/vaunce/image/gwangju_center5.jpg';
import gwangju_center6 from 'assets/vaunce/image/gwangju_center6.jpg';

import yeongtong_center1 from 'assets/vaunce/image/yeongtong_center1.jpg';
import yeongtong_center2 from 'assets/vaunce/image/yeongtong_center2.jpg';
import yeongtong_center3 from 'assets/vaunce/image/yeongtong_center3.jpg';
import yeongtong_center4 from 'assets/vaunce/image/yeongtong_center4.jpg';
import yeongtong_center5 from 'assets/vaunce/image/yeongtong_center5.jpg';
import yeongtong_center6 from 'assets/vaunce/image/yeongtong_center6.jpg';

import namyangju_center1 from 'assets/vaunce/image/namyangju_center1.jpg';
import namyangju_center2 from 'assets/vaunce/image/namyangju_center2.jpg';
import namyangju_center3 from 'assets/vaunce/image/namyangju_center3.jpg';
import namyangju_center4 from 'assets/vaunce/image/namyangju_center4.jpg';
import namyangju_center5 from 'assets/vaunce/image/namyangju_center5.jpg';
import namyangju_center6 from 'assets/vaunce/image/namyangju_center6.jpg';
import namyangju_center7 from 'assets/vaunce/image/namyangju_center7.jpg';
import namyangju_center8 from 'assets/vaunce/image/namyangju_center8.jpg';

import wonju_center1 from 'assets/vaunce/image/wonju_center1.jpg';
import wonju_center2 from 'assets/vaunce/image/wonju_center2.jpg';
import wonju_center3 from 'assets/vaunce/image/wonju_center3.jpg';
import wonju_center4 from 'assets/vaunce/image/wonju_center4.jpg';
import wonju_center5 from 'assets/vaunce/image/wonju_center5.jpg';
import wonju_center6 from 'assets/vaunce/image/wonju_center6.jpg';
import wonju_center7 from 'assets/vaunce/image/wonju_center7.jpg';
import wonju_center8 from 'assets/vaunce/image/wonju_center8.jpg';
import wonju_center9 from 'assets/vaunce/image/wonju_center9.jpg';

import pangyo_center1 from 'assets/vaunce/image/pangyo_center1.jpg';
import pangyo_center2 from 'assets/vaunce/image/pangyo_center2.jpg';
import pangyo_center3 from 'assets/vaunce/image/pangyo_center3.jpg';
import pangyo_center4 from 'assets/vaunce/image/pangyo_center4.jpg';
import pangyo_center5 from 'assets/vaunce/image/pangyo_center5.jpg';

import jeju_center1 from 'assets/vaunce/image/jeju_center1.jpg';
import jeju_center10 from 'assets/vaunce/image/jeju_center10.jpg';
import jeju_center11 from 'assets/vaunce/image/jeju_center11.jpg';
import jeju_center2 from 'assets/vaunce/image/jeju_center2.jpg';
import jeju_center3 from 'assets/vaunce/image/jeju_center3.jpg';
import jeju_center4 from 'assets/vaunce/image/jeju_center4.jpg';
import jeju_center5 from 'assets/vaunce/image/jeju_center5.jpg';
import jeju_center6 from 'assets/vaunce/image/jeju_center6.jpg';
import jeju_center7 from 'assets/vaunce/image/jeju_center7.jpg';
import jeju_center8 from 'assets/vaunce/image/jeju_center8.jpg';
import jeju_center9 from 'assets/vaunce/image/jeju_center9.jpg';

import uiwang_cetner1 from 'assets/vaunce/image/uiwang_cetner1.jpg';
import uiwang_cetner2 from 'assets/vaunce/image/uiwang_cetner2.jpg';
import uiwang_cetner3 from 'assets/vaunce/image/uiwang_cetner3.jpg';
import uiwang_cetner4 from 'assets/vaunce/image/uiwang_cetner4.jpg';
import uiwang_cetner5 from 'assets/vaunce/image/uiwang_cetner5.jpg';
import uiwang_cetner6 from 'assets/vaunce/image/uiwang_cetner6.jpg';
import uiwang_cetner7 from 'assets/vaunce/image/uiwang_cetner7.jpg';

import hanam_center1 from 'assets/vaunce/image/hanam_center1.jpg';
import hanam_center10 from 'assets/vaunce/image/hanam_center10.jpg';
import hanam_center11 from 'assets/vaunce/image/hanam_center11.jpg';
import hanam_center2 from 'assets/vaunce/image/hanam_center2.jpg';
import hanam_center3 from 'assets/vaunce/image/hanam_center3.jpg';
import hanam_center4 from 'assets/vaunce/image/hanam_center4.jpg';
import hanam_center5 from 'assets/vaunce/image/hanam_center5.jpg';
import hanam_center6 from 'assets/vaunce/image/hanam_center6.jpg';
import hanam_center7 from 'assets/vaunce/image/hanam_center7.jpg';
import hanam_center8 from 'assets/vaunce/image/hanam_center8.jpg';
import hanam_center9 from 'assets/vaunce/image/hanam_center9.jpg';

import yeongjongdo_center1 from 'assets/vaunce/image/yeongjongdo_center1.jpg';
import yeongjongdo_center2 from 'assets/vaunce/image/yeongjongdo_center2.jpg';
import yeongjongdo_center3 from 'assets/vaunce/image/yeongjongdo_center3.jpg';
import yeongjongdo_center4 from 'assets/vaunce/image/yeongjongdo_center4.jpg';
import yeongjongdo_center5 from 'assets/vaunce/image/yeongjongdo_center5.jpg';
import yeongjongdo_center6 from 'assets/vaunce/image/yeongjongdo_center6.jpg';
import yeongjongdo_center7 from 'assets/vaunce/image/yeongjongdo_center7.jpg';
import yeongjongdo_center8 from 'assets/vaunce/image/yeongjongdo_center8.jpg';

import anyang_center1 from 'assets/vaunce/image/anyang_center1.jpg';
import anyang_center2 from 'assets/vaunce/image/anyang_center2.jpg';
import anyang_center3 from 'assets/vaunce/image/anyang_center3.jpg';
import anyang_center4 from 'assets/vaunce/image/anyang_center4.jpg';
import anyang_center5 from 'assets/vaunce/image/anyang_center5.jpg';
import anyang_center6 from 'assets/vaunce/image/anyang_center6.jpg';
import anyang_center7 from 'assets/vaunce/image/anyang_center7.jpg';
import anyang_center8 from 'assets/vaunce/image/anyang_center8.jpg';
import anyang_center9 from 'assets/vaunce/image/anyang_center9.jpg';

import yeonsu_center1 from 'assets/vaunce/image/yeonsu_center1.jpg';
import yeonsu_center10 from 'assets/vaunce/image/yeonsu_center10.jpg';
import yeonsu_center11 from 'assets/vaunce/image/yeonsu_center11.jpg';
import yeonsu_center12 from 'assets/vaunce/image/yeonsu_center12.jpg';
import yeonsu_center2 from 'assets/vaunce/image/yeonsu_center2.jpg';
import yeonsu_center3 from 'assets/vaunce/image/yeonsu_center3.jpg';
import yeonsu_center4 from 'assets/vaunce/image/yeonsu_center4.jpg';
import yeonsu_center5 from 'assets/vaunce/image/yeonsu_center5.jpg';
import yeonsu_center6 from 'assets/vaunce/image/yeonsu_center6.jpg';
import yeonsu_center7 from 'assets/vaunce/image/yeonsu_center7.jpg';
import yeonsu_center8 from 'assets/vaunce/image/yeonsu_center8.jpg';
import yeonsu_center9 from 'assets/vaunce/image/yeonsu_center9.jpg';

import cheonan_center1 from 'assets/vaunce/image/cheonan_center1.jpg';
import cheonan_center2 from 'assets/vaunce/image/cheonan_center2.jpg';
import cheonan_center3 from 'assets/vaunce/image/cheonan_center3.jpg';
import cheonan_center4 from 'assets/vaunce/image/cheonan_center4.jpg';
import cheonan_center5 from 'assets/vaunce/image/cheonan_center5.jpg';
import cheonan_center6 from 'assets/vaunce/image/cheonan_center6.jpg';
import cheonan_center7 from 'assets/vaunce/image/cheonan_center7.jpg';
import cheonan_center8 from 'assets/vaunce/image/cheonan_center8.jpg';
import cheonan_center9 from 'assets/vaunce/image/cheonan_center9.jpg';

import yongin_center1 from 'assets/vaunce/image/yongin_center1.jpg';
import yongin_center2 from 'assets/vaunce/image/yongin_center2.jpg';
import yongin_center3 from 'assets/vaunce/image/yongin_center3.jpg';
import yongin_center4 from 'assets/vaunce/image/yongin_center4.jpg';
import yongin_center5 from 'assets/vaunce/image/yongin_center5.jpg';
import yongin_center6 from 'assets/vaunce/image/yongin_center6.jpg';
import yongin_center7 from 'assets/vaunce/image/yongin_center7.jpg';
import yongin_center8 from 'assets/vaunce/image/yongin_center8.jpg';
import yongin_center9 from 'assets/vaunce/image/yongin_center9.jpg';
import yongin_center10 from 'assets/vaunce/image/yongin_center10.jpg';

import suwon_lotte1 from 'assets/vaunce/image/suwon_lotte1.jpg';
import suwon_lotte2 from 'assets/vaunce/image/suwon_lotte2.jpg';
import suwon_lotte3 from 'assets/vaunce/image/suwon_lotte3.jpg';
import suwon_lotte4 from 'assets/vaunce/image/suwon_lotte4.jpg';
import suwon_lotte5 from 'assets/vaunce/image/suwon_lotte5.jpg';
import suwon_lotte6 from 'assets/vaunce/image/suwon_lotte6.jpg';
import suwon_lotte7 from 'assets/vaunce/image/suwon_lotte7.jpg';
import suwon_lotte8 from 'assets/vaunce/image/suwon_lotte8.jpg';

import inspire_01 from 'assets/vaunce/image/inspire_01.jpg';
import inspire_02 from 'assets/vaunce/image/inspire_02.jpg';
import inspire_03 from 'assets/vaunce/image/inspire_03.jpg';
import inspire_04 from 'assets/vaunce/image/inspire_04.jpg';
import inspire_05 from 'assets/vaunce/image/inspire_05.jpg';
import inspire_06 from 'assets/vaunce/image/inspire_06.jpg';
import inspire_07 from 'assets/vaunce/image/inspire_07.jpg';
import inspire_08 from 'assets/vaunce/image/inspire_08.jpg';
import inspire_09 from 'assets/vaunce/image/inspire_09.jpg';

interface CenterDetailDataType {
	centerId: number;
	검색주소: string;
	imageList: string[];
}
//이건 dummy 아님 계속 관리되어야함
export const CenterDetailData: CenterDetailDataType[] = [
	{
		centerId: 50,
		검색주소: '바운스트램폴린파크 용인 죽전',
		imageList: [
			jukjeon_center1,
			jukjeon_center2,
			jukjeon_center3,
			jukjeon_center4,
			jukjeon_center5,
			jukjeon_center6,
			jukjeon_center7,
			jukjeon_center8,
			jukjeon_center9,
			jukjeon_center10,
			jukjeon_center11,
			jukjeon_center12,
			jukjeon_center13,
		],
	},
	{
		centerId: 49,
		검색주소: '바운스트램폴린파크 삼성 센터',
		imageList: [
			samsung_center1,
			samsung_center2,
			samsung_center3,
			samsung_center4,
			samsung_center5,
			samsung_center6,
			samsung_center7,
		],
	},
	{
		centerId: 51,
		검색주소: '바운스트램폴린파크 반포 센터',
		imageList: [
			banpo_center1,
			banpo_center2,
			banpo_center3,
			banpo_center4,
			banpo_center5,
			banpo_center6,
		],
	},
	{
		centerId: 52,
		검색주소: '바운스트램폴린파크 동대구',
		imageList: [
			dongdaegu_center1,
			dongdaegu_center2,
			dongdaegu_center3,
			dongdaegu_center4,
			dongdaegu_center5,
			dongdaegu_center6,
		],
	},
	{
		centerId: 54,
		검색주소: '바운스트램폴린파크 거제 한화벨버디어 센터',
		imageList: [
			geoje_center1,
			geoje_center2,
			geoje_center3,
			geoje_center4,
			geoje_center5,
			geoje_center6,
			geoje_center7,
			geoje_center8,
		],
	},
	{
		centerId: 55,
		검색주소: '바운스트램폴린파크 부산 용호W 센터',
		imageList: [
			busan_center1,
			busan_center2,
			busan_center3,
			busan_center4,
			busan_center5,
			busan_center6,
			busan_center7,
			busan_center8,
		],
	},
	{
		centerId: 56,
		검색주소: '바운스트램폴린파크 구미 센터',
		imageList: [gumi_center1, gumi_center2, gumi_center3, gumi_center4, gumi_center5, gumi_center6],
	},
	{
		centerId: 57,
		검색주소: '바운스트램폴린파크 월계 센터',
		imageList: [
			wolgye_center1,
			wolgye_center2,
			wolgye_center3,
			wolgye_center4,
			wolgye_center5,
			wolgye_center6,
			wolgye_center7,
			wolgye_center8,
		],
	},
	{
		centerId: 58,
		검색주소: '바운스트램폴린파크 광주 센터',
		imageList: [
			gwangju_center1,
			gwangju_center2,
			gwangju_center3,
			gwangju_center4,
			gwangju_center5,
			gwangju_center6,
		],
	},
	{
		centerId: 59,
		검색주소: '바운스트램폴린파크 수원 영통',
		imageList: [
			yeongtong_center1,
			yeongtong_center2,
			yeongtong_center3,
			yeongtong_center4,
			yeongtong_center5,
			yeongtong_center6,
		],
	},
	{
		centerId: 60,
		검색주소: '바운스트램폴린파크 남양주 센터',
		imageList: [
			namyangju_center1,
			namyangju_center2,
			namyangju_center3,
			namyangju_center4,
			namyangju_center5,
			namyangju_center6,
			namyangju_center7,
			namyangju_center8,
		],
	},
	{
		centerId: 47,
		검색주소: '바운스슈퍼파크 원주오크밸리센터',
		imageList: [
			wonju_center1,
			wonju_center2,
			wonju_center3,
			wonju_center4,
			wonju_center5,
			wonju_center6,
			wonju_center7,
			wonju_center8,
			wonju_center9,
		],
	},
	{
		centerId: 64,
		검색주소: '째깍섬 바운스 파미어스몰점',
		imageList: [pangyo_center1, pangyo_center2, pangyo_center3, pangyo_center4, pangyo_center5],
	},
	{
		centerId: 65,
		검색주소: '바운스슈퍼파크 제주센터',
		imageList: [
			jeju_center1,
			jeju_center2,
			jeju_center3,
			jeju_center4,
			jeju_center5,
			jeju_center6,
			jeju_center7,
			jeju_center8,
			jeju_center9,
			jeju_center10,
			jeju_center11,
		],
	},
	{
		centerId: 69,
		검색주소: '바운스트램폴린파크 의왕센터',
		imageList: [
			uiwang_cetner1,
			uiwang_cetner2,
			uiwang_cetner3,
			uiwang_cetner4,
			uiwang_cetner5,
			uiwang_cetner6,
			uiwang_cetner7,
		],
	},
	{
		centerId: 99,
		검색주소: '바운스슈퍼파크 하남센터',
		imageList: [
			hanam_center1,
			hanam_center2,
			hanam_center3,
			hanam_center4,
			hanam_center5,
			hanam_center6,
			hanam_center7,
			hanam_center8,
			hanam_center9,
			hanam_center10,
			hanam_center11,
		],
	},
	{
		centerId: 104,
		검색주소: '스포키 바이 바운스 영종도',
		imageList: [
			yeongjongdo_center1,
			yeongjongdo_center2,
			yeongjongdo_center3,
			yeongjongdo_center4,
			yeongjongdo_center5,
			yeongjongdo_center6,
			yeongjongdo_center7,
			yeongjongdo_center8,
		],
	},
	{
		centerId: 110,
		검색주소: '바운스 째깍섬 안양센터',
		imageList: [
			anyang_center1,
			anyang_center2,
			anyang_center3,
			anyang_center4,
			anyang_center5,
			anyang_center6,
			anyang_center7,
			anyang_center8,
			anyang_center9,
		],
	},
	{
		centerId: 130,
		검색주소: '바운스 인천 연수',
		imageList: [
			yeonsu_center1,
			yeonsu_center2,
			yeonsu_center3,
			yeonsu_center4,
			yeonsu_center5,
			yeonsu_center6,
			yeonsu_center7,
			yeonsu_center8,
			yeonsu_center9,
			yeonsu_center10,
			yeonsu_center11,
			yeonsu_center12,
		],
	},
	{
		centerId: 160,
		검색주소: '바운스슈퍼파크 천안',
		imageList: [
			cheonan_center1,
			cheonan_center2,
			cheonan_center3,
			cheonan_center4,
			cheonan_center5,
			cheonan_center6,
			cheonan_center7,
			cheonan_center8,
			cheonan_center9,
		],
	},
	{
		centerId: 162,
		검색주소: '바운스 용인센터',
		imageList: [
			yongin_center1,
			yongin_center2,
			yongin_center3,
			yongin_center4,
			yongin_center5,
			yongin_center6,
			yongin_center7,
			yongin_center8,
			yongin_center9,
			yongin_center10,
		],
	},
	{
		centerId: 166,
		검색주소: '바운스 수원롯데백화점 센터',
		imageList: [
			suwon_lotte1,
			suwon_lotte2,
			suwon_lotte3,
			suwon_lotte4,
			suwon_lotte5,
			suwon_lotte6,
			suwon_lotte7,
			suwon_lotte8,
		],
	},
	{
		centerId: 171,
		검색주소: '바운스 인스파이어리조트 센터',
		imageList: [
			inspire_01,
			inspire_02,
			inspire_03,
			inspire_04,
			inspire_05,
			inspire_06,
			inspire_07,
			inspire_08,
			inspire_09,
		],
	},
];

export const AddressMap: Record<string, string[]> = {
	서울특별시: [
		'강남구',
		'강동구',
		'강북구',
		'강서구',
		'관악구',
		'광진구',
		'구로구',
		'금천구',
		'노원구',
		'도봉구',
		'동대문구',
		'동작구',
		'마포구',
		'서대문구',
		'서초구',
		'성동구',
		'성북구',
		'송파구',
		'양천구',
		'영등포구',
		'용산구',
		'은평구',
		'종로구',
		'중구',
		'중랑구',
	],
	인천광역시: [
		'계양구',
		'남구',
		'남동구',
		'동구',
		'부평구',
		'서구',
		'연수구',
		'중구',
		'강화군',
		'옹진군',
	],
	대전광역시: ['대덕구', '동구', '서구', '유성구', '중구'],
	광주광역시: ['광산구', '남구', '동구', '북구', '서구'],
	대구광역시: ['남구', '달서구', '동구', '북구', '서구', '수성구', '중구', '달성군'],
	울산광역시: ['남구', '동구', '북구', '중구', '울주군'],
	부산광역시: [
		'강서구',
		'금정구',
		'남구',
		'동구',
		'동래구',
		'부산진구',
		'북구',
		'사상구',
		'사하구',
		'서구',
		'수영구',
		'연제구',
		'영도구',
		'중구',
		'해운대구',
		'기장군',
	],
	세종특별자치시: ['세종특별자치시'],
	경기도: [
		'고양시',
		'과천시',
		'광명시',
		'광주시',
		'구리시',
		'군포시',
		'김포시',
		'남양주시',
		'동두천시',
		'부천시',
		'성남시',
		'수원시',
		'시흥시',
		'안산시',
		'안성시',
		'안양시',
		'양주시',
		'오산시',
		'용인시',
		'의왕시',
		'의정부시',
		'이천시',
		'파주시',
		'평택시',
		'포천시',
		'하남시',
		'화성시',
		'가평군',
		'양평군',
		'여주군',
		'연천군',
	],
	강원도: [
		'강릉시',
		'동해시',
		'삼척시',
		'속초시',
		'원주시',
		'춘천시',
		'태백시',
		'고성군',
		'양구군',
		'양양군',
		'영월군',
		'인제군',
		'정선군',
		'철원군',
		'평창군',
		'홍천군',
		'화천군',
		'횡성군',
	],
	충청북도: [
		'제천시',
		'청주시',
		'충주시',
		'괴산군',
		'단양군',
		'보은군',
		'영동군',
		'옥천군',
		'음성군',
		'증평군',
		'진천군',
		'청원군',
	],
	충청남도: [
		'계룡시',
		'공주시',
		'논산시',
		'보령시',
		'서산시',
		'아산시',
		'천안시',
		'금산군',
		'당진군',
		'부여군',
		'서천군',
		'연기군',
		'예산군',
		'청양군',
		'태안군',
		'홍성군',
	],
	전라북도: [
		'군산시',
		'김제시',
		'남원시',
		'익산시',
		'전주시',
		'정읍시',
		'고창군',
		'무주군',
		'부안군',
		'순창군',
		'완주군',
		'임실군',
		'장수군',
		'진안군',
	],
	전라남도: [
		'광양시',
		'나주시',
		'목포시',
		'순천시',
		'여수시',
		'강진군',
		'고흥군',
		'곡성군',
		'구례군',
		'담양군',
		'무안군',
		'보성군',
		'신안군',
		'영광군',
		'영암군',
		'완도군',
		'장성군',
		'장흥군',
		'진도군',
		'함평군',
		'해남군',
		'화순군',
	],
	경상북도: [
		'경산시',
		'경주시',
		'구미시',
		'김천시',
		'문경시',
		'상주시',
		'안동시',
		'영주시',
		'영천시',
		'포항시',
		'고령군',
		'군위군',
		'봉화군',
		'성주군',
		'영덕군',
		'영양군',
		'예천군',
		'울릉군',
		'울진군',
		'의성군',
		'청도군',
		'청송군',
		'칠곡군',
	],
	경상남도: [
		'거제시',
		'김해시',
		'마산시',
		'밀양시',
		'사천시',
		'양산시',
		'진주시',
		'진해시',
		'창원시',
		'통영시',
		'거창군',
		'고성군',
		'남해군',
		'산청군',
		'의령군',
		'창녕군',
		'하동군',
		'함안군',
		'함양군',
		'합천군',
	],
	제주도: ['서귀포시', '제주시', '남제주군', '북제주군'],
};
