import { CloseCircleOutlined } from '@ant-design/icons';
import { CartTable } from '@kinderlabs-pos/feature/pos/cart-table';
import {
	OrderDetailType,
	OrderStatusTypeColor,
	OrderStatusTypeLabel,
	OrderTypeLabel,
	PaymentMethodTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import {
	canceledStyleUtils,
	getDateTimeFormat,
	getTelephoneNumberWithDash,
	numberWithCommasAndWon,
} from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import {
	Button,
	Divider,
	IconButton,
	Link,
	List,
	ListItem,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export const OrderDetailContents = ({
	order,
	NavigateActions,
	navigate결제내역,
	guestMemberAction,
}: {
	order: OrderDetailType;
	NavigateActions?: ReactNode;
	navigate결제내역?: (val: string) => void;
	guestMemberAction?: {
		openConnectDialog: () => void;
		deleteMember: () => void;
	};
}) => {
	const theme = useTheme();
	const deviceInfo = AdminPosInfoState.useDeviceInfo({
		storeId: order.storeId,
		deviceId: order.posId,
	});

	const orderInfoData = [
		{
			label: '주문 번호',
			value: `${order.id}`,
		},
		{
			label: '주문 종류',
			value: `${OrderTypeLabel[order.type]}`,
		},
		{
			label: '주문 상태',
			value: (
				<Typography
					component={'span'}
					color={OrderStatusTypeColor[order.status]}>
					{`${OrderStatusTypeLabel[order.status]}`}
				</Typography>
			),
		},
		{
			label: '주문 기기',
			value: (
				<Typography
					component={'span'}
					color={OrderStatusTypeColor[order.status]}>
					{`${deviceInfo?.label || '-'}`}
				</Typography>
			),
		},
		{
			label: '주문 일자',
			value: getDateTimeFormat(order.created),
		},
		{
			label: '주문 완료 일자',
			value: order.completed ? getDateTimeFormat(order.completed) : '',
		},
	];

	if (order.canceled)
		orderInfoData.push({
			label: '주문 취소 일자',
			value: getDateTimeFormat(order.canceled),
		});

	return (
		<Stack
			flex={1}
			spacing={1}
			flexGrow={1}
			sx={{ overflowY: 'auto' }}>
			<InfoPrintStackTitle
				title={`주문 정보`}
				rightButtons={NavigateActions}
			/>
			{orderInfoData.map((orderData, idx) => (
				<InfoPrintStackRow
					key={idx}
					label={orderData.label}
					value={orderData.value}
				/>
			))}
			{(guestMemberAction || order.guestMemberInfo) && <InfoPrintStackTitle title={'고객 정보'} />}
			{order.guestMemberInfo && (
				<Stack spacing={1}>
					{[
						{
							label: '고객 성명 (전화번호) ',
							value: `${order.guestMemberInfo.name ?? '이름없음'} (${getTelephoneNumberWithDash(
								order.guestMemberInfo.telephone
							)})`,
						},
						{
							label: `동행 고객`,
							value: (order.guestMemberInfo.users ?? []).filter((_, idx) => idx !== 0).join(', '),
						},
					].map(({ label, value }, idx) => (
						<Stack
							sx={{ alignItems: 'center' }}
							direction={'row'}
							key={idx}>
							<Stack
								flex={1}
								justifyContent={'flex-start'}>
								{label}
							</Stack>
							<Stack
								flex={1}
								justifyContent={'flex-start'}>
								{value}
							</Stack>
							{guestMemberAction?.deleteMember && idx === 0 && (
								<Stack justifyContent={'flex-start'}>
									<IconButton onClick={guestMemberAction.deleteMember}>
										<CloseCircleOutlined
											style={{ color: theme.palette.error.main, fontSize: 20 }}
										/>
									</IconButton>
								</Stack>
							)}
						</Stack>
					))}
				</Stack>
			)}
			{!order.guestMemberInfo && guestMemberAction && (
				<Button
					sx={{ display: guestMemberAction ? 'unset' : 'hidden' }}
					variant='text'
					onClick={guestMemberAction.openConnectDialog}>
					{'+'}
				</Button>
			)}
			<Stack sx={{ flexGrow: 1 }}>
				<InfoPrintStackTitle title={`결제 품목`} />
				<CartTable
					selectable={false}
					hasTitle={false}
					cartLines={order.cart.lines}
				/>
				<Divider />
				{navigate결제내역 && (
					<>
						<InfoPrintStackTitle title={`결제 목록`} />
						<List>
							{order.payments.map((payment, idx) => (
								<ListItem key={idx}>
									<Stack
										direction={'row'}
										width={'100%'}
										justifyContent={'space-between'}>
										<Link
											onClick={() => navigate결제내역(payment.id)}
											sx={{ ...canceledStyleUtils(!!payment.refunded) }}>
											{`${payment.id} ( ${PaymentMethodTypeLabel[payment.method]} ${
												payment.receipt ? `승인번호 ${payment.receipt.approvalId})` : ' )'
											}`}
										</Link>
										<Typography
											variant='subtitle1'
											sx={{ ...canceledStyleUtils(!!payment.refunded) }}>
											{numberWithCommasAndWon(payment.amount)}
										</Typography>
									</Stack>
								</ListItem>
							))}
						</List>
						<Divider />
					</>
				)}
				<List>
					{[
						{
							label: '총 결제액',
							value: numberWithCommasAndWon(order.aggregate.totalSum),
						},
						{
							label: '총 할인액',
							value: numberWithCommasAndWon(order.aggregate.totalDiscount),
						},
						{
							label: '실 결제액',
							value: numberWithCommasAndWon(order.aggregate.totalToPay),
						},
					].map(({ label, value }, idx) => (
						<PaymentSummaryStack
							key={idx}
							label={label}
							value={value}
						/>
					))}
				</List>
			</Stack>
		</Stack>
	);
};

const PaymentSummaryStack = ({
	label,
	value,
}: // detail = false,
// paymentInfo,
// navigate결제내역,
{
	label: ReactNode;
	value: ReactNode;
	// detail?: boolean;
	// paymentInfo: PaymentInfoType[];
	// navigate결제내역(val: string): void;
}) => {
	return (
		<ListItem
			dense
			sx={{ display: 'flex', flexDirection: 'row' }}>
			<Stack
				flex={1}
				alignItems={'flex-start'}>
				<Typography variant={'subtitle1'}>{label}</Typography>
			</Stack>
			<Stack
				flex={1}
				alignItems={'flex-end'}>
				<Typography variant={'subtitle1'}>{value}</Typography>
			</Stack>
		</ListItem>
	);
};
