import { ActionBoard } from '@kinderlabs-pos/ui-components';
import { Grid2Props } from '@mui/material';
import { ReactNode, useMemo, useRef } from 'react';
import { PosBoardEmptyItem } from '../PosBoardItem/PosBoardEmptyItem';
import { PosCategoryItem } from './PosCategoryItem';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PosBoardPaginationItem } from '../PosBoardItem/PosBoardPaginationItem';
import { PrimitiveAtom, WritableAtom, atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { atomWithReset } from 'jotai/utils';

const numOfColumns = 5;
export interface ICategoryBoardProps extends Grid2Props {
	children: ReactNode[];
	onChangePagination?: (newIdx: number) => void;
	disablePagination?: boolean;
	// jin : 주열님 POS Category Board 의 Atom 은 POS Category 안에서만 소화되기에 굳이 atom 을 주입받지 않아도 될것 같아요. 라고는 말했지만... sub board 도 좀 이상하죠ㅋㅋ 이렇게 하지만입받아도 괜찮을거 같아요ㅋㅋㅋ 잠이 모자라서 그런지 오늘따라 머리가 참 안 도네요ㅋㅋ
	isSubBoard?: boolean;
}

export const PosCategoryBoard = ({
	children,
	onChangePagination,
	disablePagination,
	isSubBoard = false,
	...others
}: ICategoryBoardProps) => {
	const prevPage = useRef(0);
	const [page, setPage] = useAtom(
		isSubBoard ? PosCategoryBoard.paginationAtomForDialog : PosCategoryBoard.paginationAtom
	);
	useEffect(() => {
		if (prevPage.current !== page) {
			prevPage.current = page;
			onChangePagination && onChangePagination(page * PosCategoryBoard.numOfPads);
		}
	}, [onChangePagination, page]);

	const pagedChildren = disablePagination
		? children
		: children.filter(
				(item, idx) =>
					idx >= page * PosCategoryBoard.numOfPads && idx < (page + 1) * PosCategoryBoard.numOfPads
		  );
	const isNowPosCategoryItemEmpty = useMemo(() => pagedChildren.length === 0, [pagedChildren]);

	useEffect(() => {
		if (page !== 0 && isNowPosCategoryItemEmpty) {
			setPage((prev) => prev - 1);
			return;
		}
	}, [page, isNowPosCategoryItemEmpty]);

	const isStartPage = disablePagination ? true : page === 0;
	const isEndPage = disablePagination
		? true
		: isNowPosCategoryItemEmpty
		? true
		: page === Math.ceil(children.length / PosCategoryBoard.numOfPads) - 1;

	const newChildren = [
		...pagedChildren,
		...[...new Array(PosCategoryBoard.numOfPads - pagedChildren.length).keys()].map((item, idx) => (
			<PosBoardEmptyItem key={idx} />
		)),
	];

	return (
		<ActionBoard
			numOfColumns={numOfColumns}
			spacing={1}
			ItemSx={{
				height: 45,
			}}
			{...others}>
			{[
				<PosBoardPaginationItem
					key={'prior'}
					CardActionProps={{ disabled: isStartPage, onClick: () => setPage((prev) => prev - 1) }}>
					<LeftOutlined />
				</PosBoardPaginationItem>,
				newChildren[0],
				newChildren[1],
				newChildren[2],
				<PosBoardPaginationItem
					key={'next'}
					CardActionProps={{ disabled: isEndPage, onClick: () => setPage((prev) => prev + 1) }}>
					<RightOutlined />
				</PosBoardPaginationItem>,
				newChildren[3],
				newChildren[4],
				newChildren[5],
				newChildren[6],
				newChildren[7],
			]}
		</ActionBoard>
	);
};

PosCategoryBoard.Item = PosCategoryItem;
PosCategoryBoard.numOfPads = 8;

PosCategoryBoard.paginationAtom = atomWithReset<number>(0);
PosCategoryBoard.paginationAtomForDialog = atomWithReset<number>(0);
