import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';

export const getAgreePushAsync = (rootUrl: string) => async (type: pushRequestType) =>
	(
		await axiosAppClient.get(`${rootUrl}/users/agree_push`, {
			params: {
				agree_push: type,
			},
		})
	).data;

// 이건 또 res 형태가 다르네 참나
export const getAdAgreePushAsync = (rootUrl: string) => async (type: AdRequestType) =>
	(
		await axiosAppPostClient.get(`${rootUrl}/users/updateAdAgree`, {
			params: {
				adAgree: type,
			},
		})
	).data;

type pushRequestType = boolean;
type AdRequestType = 'AGREE' | 'DISAGREE';
