import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorOrderReturnInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateToCompleteVendorOrderAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.put<VendorOrderReturnInfoType>(`${rootUrl}/vendor-order/complete`, null, {
			params: {
				id,
			},
		})
	).data;

export const 입고완료취소VendorOrderAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.put<VendorOrderReturnInfoType>(
			`${rootUrl}/vendor-order/cancel-complete`,
			null,
			{
				params: {
					id,
				},
			}
		)
	).data;
