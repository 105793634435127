import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import {
	FindUserInfo,
	FindUserType,
	JoinFormType,
	LoginTyoe,
	PostFindUserType,
	SignUpMoreInfoType,
	SNSAuthResponseModel,
	SNSProviderType,
	WithdrawalType,
} from '@kinderlabs-pos/shared-data-type';
import { SentryApiLogging } from './SentryApiLogging';
import { backendApiRootUrl } from '@kinderlabs-pos/shared-const';

// TODO 로그인 실패시 어캐해줄건지 필요
type LoginRequestType = {
	id: string;
	password: string;
	auto: boolean; // 자동 로그인 체크 여부
};

type SNSLoginRequestType = {
	code: string; // id 대신 code 를 넣을 것
	// password 대신 additionalState 를 넣을 것
	additionalInfo: string;
	auto?: boolean;
	provider: SNSProviderType;
};

export const postSnsLogInForSignupAsync =
	(rootUrl: string) =>
	async ({
		code,
		additionalInfo,
		auto,
		provider,
		onSuccess,
		onAlreadyMember,
	}: SNSLoginRequestType & { onSuccess: () => void; onAlreadyMember: () => void }) => {
		SentryApiLogging.setRequestContext({ code, additionalInfo, auto, provider });

		const result = await axiosAppClient.post<{ vo: SNSAuthResponseModel }>(
			`${rootUrl}/auth/sns-login`,
			{
				code,
				additionalInfo,
				auto,
				provider: provider.toUpperCase(),
			},
			{
				params: { signup: true },
			}
		);

		// 회원가입 시도의 경우
		if (result.data.vo.authModel.isJoined) {
			// 이미 회원가입이 완료된 경우, 그냥 로그인시켜줌.
			localStorage.setItem('accessToken', result.data.vo.authModel.token);

			onAlreadyMember();
		} else {
			// 아직 회원가입이 안 된 경우, 로그인 진행을 위해 SNS 토큰을 사용
			localStorage.setItem('snsToken', result.data.vo.snsUserInfo.accessToken);
			localStorage.setItem('provider', provider);

			onSuccess();
		}

		return result.data;
	};

export const postSnsJoinAsync = (rootUrl: string) => async (userFormData: FormData) => {
	const result = await axiosAppClient.post(`${rootUrl}/auth/snsjoin`, userFormData);
	if (result) {
		return result.data;
	}
};

export const postSnsLogInAsync =
	(rootUrl: string) =>
	async ({
		code,
		additionalInfo,
		auto,
		provider,
		onSuccess,
		onNotMember,
	}: SNSLoginRequestType & {
		onSuccess: () => void;
		onNotMember: (args: { onConfirm: () => void }) => void;
	}) => {
		SentryApiLogging.setRequestContext({ code, additionalInfo, auto, provider });

		const result = await axiosAppClient.post<{ vo: SNSAuthResponseModel }>(
			`${rootUrl}/auth/sns-login`,
			{
				code,
				additionalInfo,
				auto,
				provider: provider.toUpperCase(),
			},
			{
				params: { signup: false },
			}
		);

		// 로그인 시도의 경우
		if (result.data.vo.authModel.isJoined) {
			// 로그인 성공
			localStorage.setItem('accessToken', result.data.vo.authModel.token);
			localStorage.setItem('refreshToken', result.data.vo.authModel.refreshToken);
			localStorage.setItem('Provider', provider);
			onSuccess();
		} else {
			// 아직 회원가입이 안 된 경우, 로그인 진행을 위해 SNS 토큰을 사용

			onNotMember({
				onConfirm: () => {
					localStorage.setItem('snsToken', result.data.vo.snsUserInfo.accessToken);
					localStorage.setItem('provider', provider);
				},
			});
		}

		return result.data;
	};

export const postAppleLoginAsync =
	(rootUrl: string) => async (code: string, onSuccess: () => void) => {
		SentryApiLogging.setRequestContext({ code });

		const result = await axiosAppClient.post<{ vo: SNSAuthResponseModel }>(
			`${rootUrl}/auth/sns-login`,
			{
				code: code,
				provider: 'APPLE',
			},
			{
				params: { signup: false },
			}
		);
		if (result) {
			localStorage.setItem('accessToken', result.data.vo.authModel.token);
			localStorage.setItem('refreshToken', result.data.vo.authModel.refreshToken);
			onSuccess();
		}
		return result.data;
	};

// TODO 로그인 실패시 어캐해줄건지 필요
export const postLogInAsync = (rootUrl: string) => async (logInFormData: LoginRequestType) => {
	SentryApiLogging.setRequestContext(logInFormData);

	const result = await axiosAppClient.post<LoginTyoe>(`${rootUrl}/auth/app-login`, logInFormData);

	if (result.data.vo) {
		localStorage.setItem('accessToken', result.data.vo['token']);
		localStorage.setItem('refreshToken', result.data.vo['refreshToken']);
		return result.data;
	}
	return null;
};

export const getDuplicatedId = (rootUrl: string) => async (id: any) => {
	SentryApiLogging.setRequestContext(id);
	return await (
		await axiosAppClient.get<any>(`${rootUrl}/auth/id/duplicated`, { params: { userId: id } })
	).data;
};

export const postSignUpMoreInfoAsync =
	(rootUrl: string) => async (userId: string, signupFormData: FormData) => {
		SentryApiLogging.setRequestContext({ userId, signupFormData });

		return await (
			await axiosAppClient.post<SignUpMoreInfoType>(
				`${rootUrl}/auth/join_more_info/${userId}`,
				signupFormData
			)
		).data;
	};
export const postSignUpAsync = (rootUrl: string) => async (signupFormData: JoinFormType) => {
	SentryApiLogging.setRequestContext(signupFormData);

	const result = await (
		await axiosAppPostClient.post<any>(`${rootUrl}/auth/app-join`, signupFormData)
	).data;

	return result;
};

//id 찾기와 password 찾기 두개 api 형식이 다름.
export const postFindIdsync = (rootUrl: string) => async (userFormData: PostFindUserType) => {
	SentryApiLogging.setRequestContext(userFormData);
	return await (
		await axiosAppPostClient.post<FindUserType>(`${backendApiRootUrl}/find_id_rest`, userFormData)
	).data;
};

export const postFindPwAsync = (rootUrl: string) => async (userFormData: FormData) => {
	SentryApiLogging.setRequestContext(userFormData);
	return await (
		await axiosAppClient.post<FindUserInfo>(`${rootUrl}/auth/find_pw`, userFormData)
	).data;
};

// 탈퇴하기
export const getWithdrawal = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<WithdrawalType>(`${rootUrl}/users/withdrawal`)
	).data;
