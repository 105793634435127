import { createSearchParams, URLSearchParamsInit, useSearchParams } from 'react-router-dom';
/**
 * @author jin
 * @returns searchParams, setSearchParams, changeSearchParams
 *
 * 해당 함수는 ReactRouterv6 의 searchParams 를 이용할 때 더욱 편리하게 활용하기 위한 유틸리티 커스텀 훅입니다.
 * 참고 : @url : https://github.com/KinderLabs/kinderlabs-pos/issues/127
 *
 * searchParams, setSearchParams 는 useSearchParams의 기본적인 기능입니다.
 * 페이지 외부에서 라우팅하며 검색어를 자동 입력할 때에는 setSearchParamsKeyword 를 이용하면 됩니다.
 * 페이지 내부에서 라우팅하며 검색어를 자동 입력할 때에는 changeSearchParams 를 이용하면 검색어가 여러번 바뀌어도 뒤로가기 클릭시 검색어만 바뀌는 문제가 발생하지 않습니다.
 */
export const useSearchParamsWrapper = <T extends Record<string, string>>() => {
	return useSearchParamsWrapperInternal<T>(true);
};

export const useAdminSearchParamsWrapper = <
	T extends Record<string, string | number | boolean | undefined>
>() => {
	const result = useSearchParamsWrapperInternal<T>(true);

	return result;
};

const useSearchParamsWrapperInternal = <
	T extends Record<string, string | number | boolean | undefined>
>(
	replace: boolean
) => {
	const [searchParams, _setSearchParams] = useSearchParams();

	const setSearchParams = (param: T) => _setSearchParams(createSearchParams(removeEmpty(param)));
	const changeSearchParams = (param: T) =>
		_setSearchParams((prev) => createSearchParams(removeEmpty(param)), {
			replace,
		});

	return {
		searchParams,
		setSearchParams,
		changeSearchParams,
	};
};

const removeEmpty = (obj: Record<string, string | number | boolean | undefined>) => {
	let newObj: Record<string, string> = {};
	Object.keys(obj).forEach((key) => {
		if (obj[key] === undefined) delete newObj[key];
		// @ts-ignore
		else newObj[key] = obj[key];
	});
	return newObj;
};
