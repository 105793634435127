import { TypographyOptions } from '@mui/material/styles/createTypography';

export const kioskTypographyWithFontFamily = (fontFamily: string): TypographyOptions => ({
	htmlFontSize: 18,
	fontFamily: fontFamily,
	h1: {
		fontWeight: 500,
		fontSize: '5rem',
		lineHeight: 1.5,
	},
	h2: {
		fontWeight: 500,
		fontSize: '4rem',
		lineHeight: 1.5,
	},
	h3: {
		fontWeight: 700,
		fontSize: '3.5rem',
		lineHeight: 1.5,
	},
	h4: {
		fontWeight: 700,
		fontSize: '3rem',
		lineHeight: 1.5,
	},
	h5: {
		fontWeight: 700,
		fontSize: '2.5rem',
		lineHeight: 1.5,
	},
	h6: {
		fontWeight: 700,
		fontSize: '2rem',
		lineHeight: 1.5,
	},
	body1: {
		fontWeight: 400,
		fontSize: '1.9rem',
		lineHeight: 1.5,
	},
	body2: {
		fontWeight: 400,
		fontSize: '1.3rem',
		lineHeight: 1.3,
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '2rem',
		lineHeight: 1.4,
	},
	subtitle2: {
		fontWeight: 400,
		fontSize: '1.7rem',
		lineHeight: 1.3,
	},
	button: {
		fontWeight: 400,
		fontSize: '2.2rem',
		lineHeight: 1.5,
	},
	caption: {
		fontWeight: 400,
		fontSize: '1rem',
		lineHeight: 1.5,
	},
});
