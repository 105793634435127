import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { DialogProps, Stack, Typography } from '@mui/material';
import { FormattedDialog } from '../../components/FormattedDialog';
export interface ITelePhoneDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
	centerData: any;
}
export const TelePhoneDialog: React.FC<ITelePhoneDialogProps> = ({ open, onClose, centerData }) => {
	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack spacing={3}>
				<Stack alignItems={'center'}>
					<PhoneInTalkIcon sx={{ color: vaunceColors.lightPink, width: '74px', height: '74px' }} />
					<Typography variant='h4'>선택하신 센터로 전화를 거시겠습니까?</Typography>
				</Stack>

				<Stack
					direction={'row'}
					spacing={2}
					pb={3}>
					<RoundedButton
						sx={{ flex: 1, color: vaunceColors.lightPink, bgcolor: 'white' }}
						onClick={onClose}>
						취소
					</RoundedButton>
					<RoundedButton
						sx={{ flex: 1 }}
						onClick={() => {
							location.href = `tel:${centerData.centerTel}`;
						}}>
						확인
					</RoundedButton>
				</Stack>
			</Stack>
		</FormattedDialog>
	);
};
