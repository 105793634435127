import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Pageable, VisitStatisticsWithStoreType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getDetailGuestVisitAsync =
	(rootUrl: string) =>
	async ({ storeIdList, from, to, sort }: RequestType) =>
		(
			await posAxiosClient.get<VisitStatisticsWithStoreType[]>(
				`${rootUrl}/statistics/store/detail`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						storeIdList,
						...Pageable.getPageable({
							sort,
						}).getSortQueryParam(),
					},
				}
			)
		).data;

type RequestType = {
	storeIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
};
