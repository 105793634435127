import { EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import {
	VaunceAppCMTTicketOnPosType,
	VaunceAppTicketOnPosType,
} from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat, getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { AccordionDetails, Button, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { VaunceAppTicketUserMemberInfoBox } from './common/VaunceAppTicketUserMemberInfoBox';
import { VaunceTicketItemAccordion } from './common/VaunceTicketItemAccordion';
import { getSortedUserIdList } from './common/getSortedUserIdList';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { VaunceAppTicketHistoryDialog } from './VaunceAppTicketHistoryDialog';
import { VaunceCmtTicketUserDialog } from './VaunceCmtTicketUserDialog';

export const VaunceAppTicketCmtListContent = ({
	appTicketInfoMap,
}: {
	appTicketInfoMap: {
		[userId: string]: (VaunceAppCMTTicketOnPosType & { canUseTicket: boolean })[];
	};
}) => {
	const userIdList = getSortedUserIdList({ ticketOrCouponInfoMap: appTicketInfoMap });

	return (
		<Stack>
			{userIdList.map((memberId, idx) => {
				const { uid, userName, userId, userBirth, userTelephone } = appTicketInfoMap[memberId][0];

				return (
					<Stack
						key={idx}
						spacing={1}>
						<VaunceAppTicketUserMemberInfoBox
							uid={uid}
							userName={userName}
							userId={userId}
							userBirth={userBirth}
							userTelephone={userTelephone}
						/>
						{appTicketInfoMap[memberId].map((cmt, cmtIdx) => {
							const 사용등록필요 = !cmt.ticketOrderNum;
							return (
								<VaunceTicketItemAccordion
									key={cmtIdx}
									ticket={cmt}
									canUseTicket={cmt.canUseTicket && cmt.expiredTicket === 'N'}
									rightPanel={
										<Button
											color={cmt.canUseTicket && !사용등록필요 ? 'success' : 'error'}
											variant={'outlined'}
											sx={{ pointerEvents: 'none' }}>
											{사용등록필요 ? (
												<Typography variant={'body1'}>{'사용 등록 필요'}</Typography>
											) : (
												<Typography variant={'body1'}>
													{cmt.canUseTicket
														? `${dayjs(cmt.ticketEndDate).diff(dayjs(), 'days') + 1} 일`
														: cmt.ticketStatus === 'PREMIUM_SEASON_TODAY_USED'
														? `오늘 이미 사용 (${getDateTimeFormat(cmt.detailTicket[0].endDate)})`
														: '사용 가능'}
												</Typography>
											)}
										</Button>
									}>
									<AccordionDetails>
										<Suspense>
											<Stack>
												<Stack
													direction={'row'}
													justifyContent={'flex-end'}
													spacing={1}>
													<Stack flex={1}>
														{사용등록필요 ? (
															<Stack alignItems={'center'}>
																<Typography variant={'h5'}>{'사용 등록 필요'}</Typography>
																<Typography
																	variant={'body1'}
																	color={'text.secondary'}>
																	{'앱에서 사용자 등록이 필요합니다.'}
																</Typography>
															</Stack>
														) : (
															<VaunceAppCMTUsePanel cmt={cmt} />
														)}
													</Stack>
													<WithDialog
														dialog={(open, closeDialog) => (
															<VaunceCmtTicketUserDialog
																cmtTicket={cmt}
																open={open}
																closeDialog={closeDialog}
															/>
														)}>
														{(openDialog) => (
															<IconButton onClick={openDialog}>
																<EyeOutlined />
															</IconButton>
														)}
													</WithDialog>
													<WithDialog
														dialog={(open, closeDialog) => (
															<VaunceAppTicketHistoryDialog
																ticketId={cmt.ticketId}
																open={open}
																closeDialog={closeDialog}
															/>
														)}>
														{(openDialog) => (
															<IconButton onClick={openDialog}>
																<HistoryOutlined />
															</IconButton>
														)}
													</WithDialog>
												</Stack>
											</Stack>
										</Suspense>
									</AccordionDetails>
								</VaunceTicketItemAccordion>
							);
						})}
					</Stack>
				);
			})}
		</Stack>
	);
};

const VaunceAppCMTUsePanel = ({ cmt }: { cmt: VaunceAppTicketOnPosType }) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const cmtUsage = useAtomValue(OrderState.cart.lines.ticketUsageSelector).filter(
		(usage) => usage.type === 'VAUNCE_APP_CMT'
	);
	const cartDispatch = OrderState.cart.useDispatcher();

	const useInfo = cmt.detailTicket[0];

	const nowUsing = cmtUsage.map((u) => u.ticketUsageInfo.ticketUsageId).includes(useInfo.ticketNum);

	const handleCancelUse = () =>
		cartDispatch({
			type: 'USAGES',
			subAction: { type: 'DELETE', useId: `${useInfo.ticketNum}`, ticketType: 'COMMUTATION' },
		});
	const handleUse = () =>
		cartDispatch({
			type: 'USAGES',
			subAction: {
				type: 'ADD_TICKET',
				cartLineId: getUuidV4(),
				ticketUsage: {
					type: 'COMMUTATION',
					useId: useInfo.ticketNum,
					name: cmt.ticketTitle,
					ticketId: cmt.ticketId,
					ticketInfoId: 0,
					userName: cmt.ticketUserName,
					userTelephone: cmt.userTelephone,
					memberId: cmt.uid,
					ddiziInfo: {
						name: cmt.ticketTitle,
						ddiziType: 'NORMAL',
						minutes: storeInfo.policy.defaultMinutesInCenter,
						hasNoAdditionalCharge: false,
						isNotForStatistics: false,
						available: 'ALL',
					},
				},
			},
		});

	const alreadyUsed = cmt.ticketStatus === 'PREMIUM_SEASON_TODAY_USED';
	return (
		<Stack
			direction={'row'}
			spacing={1}
			justifyContent={'center'}>
			<Button
				variant={'outlined'}
				color={alreadyUsed ? 'inherit' : nowUsing ? 'error' : 'success'}
				fullWidth
				onClick={nowUsing ? handleCancelUse : handleUse}
				disabled={!!alreadyUsed}>
				{alreadyUsed && (
					<Stack spacing={1}>
						<Typography variant='h4'>{' 이미사용'}</Typography>
					</Stack>
				)}
				{!alreadyUsed && (
					<Stack spacing={1}>
						<Typography variant='body1'>{`티켓번호`}</Typography>
						<Typography variant='h4'>{`#${cmt.ticketId} 사용 ${
							nowUsing ? '취소' : ''
						}`}</Typography>
					</Stack>
				)}
			</Button>
		</Stack>
	);
};
