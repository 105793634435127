import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { rootUrl } from '../../const';
import { canDeleteStoreInfoAsync } from './canDeleteStoreInfoAsync';
import { createStoreInfoAsync } from './createStoreInfoAsync';
import { deleteStoreInfoListAsync } from './deleteStoreInfoListAsync';
import { getStoreInfoDetailAsync } from './getStoreInfoDetailAsync';
import { getStoreInfoListAsync } from './getStoreInfoListAsync';
import { getStoreInfoListPageAsync } from './getStoreInfoListPageAsync';
import { getStoreInfoSimpleAsync } from './getStoreInfoSimpleAsync';
import { updateStoreInfoAsync } from './updateStoreInfoAsync';

const getAddressInfo = (rootUrl: string) => async () =>
	(
		await posAxiosClient.get<{ sigunguMap: Record<string, string[]> }>(
			`${rootUrl}/address-controller`
		)
	).data;

export const AdminStoreInfoApis = {
	getStoreInfoList: getStoreInfoListAsync(rootUrl),
	getStoreInfoListPage: getStoreInfoListPageAsync(rootUrl),
	getStoreInfoDetail: getStoreInfoDetailAsync(rootUrl),
	getStoreInfoSimple: getStoreInfoSimpleAsync(rootUrl),
	createStoreInfo: createStoreInfoAsync(rootUrl),
	deleteStoreInfoList: deleteStoreInfoListAsync(rootUrl),
	updateStoreInfo: updateStoreInfoAsync(rootUrl),
	cnaDelete: canDeleteStoreInfoAsync(rootUrl),
	// Address 만을 위해서 따로 빼는건 오바라서 여기다가 넣었습니다.
	getAddressInfo: getAddressInfo(rootUrl),
};
