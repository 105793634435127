import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import wariningImage from 'assets/vaunce/image/pinkwarning.png';
import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';

export interface INicePayResultPageProps {}
export const NicePayResultPage: React.FC<INicePayResultPageProps> = ({}) => {
	const { navigateVauncePremiumClub } = useVaunceAppRouter();
	const searchParams = useLocation().search;
	const query = qs.parse(searchParams, { ignoreQueryPrefix: true });

	const [failType, setFailType] = useState<string>(
		getPayFailType(query['resultType'] as string) ?? ''
	);

	function getPayFailType(text: string) {
		switch (text) {
			case '1':
				return '구매 실패';
			case '2':
				return '발권 실패';
			default:
				return '구매 실패';
		}
	}

	return (
		<Stack>
			<PageHeader title={failType} />
			<Stack
				alignItems={'center'}
				spacing={2}>
				<img
					src={wariningImage}
					width={74}
				/>

				<Typography variant='h5'>구매가 정상적으로 완료되지 않았습니다.</Typography>
				<Typography variant='h5'> 결제를 완료하지 못하였습니다.</Typography>

				{failType === '구매 실패' ? (
					<RoundedButton
						fullWidth
						onClick={() => {
							navigateVauncePremiumClub();
						}}>
						다시 시도
					</RoundedButton>
				) : (
					<RoundedButton
						fullWidth
						onClick={() => {
							location.href = 'tel:1599-9583';
						}}>
						고객 센터
					</RoundedButton>
				)}
			</Stack>
		</Stack>
	);
};
