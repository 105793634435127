import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const searchGuestVisitsAsync =
	(rootUrl: string) =>
	async ({ storeIdList, from, to, statusList, search, pageable }: SearchGuestVisitsType) =>
		(
			await posAxiosClient.get<PageType<GuestVisitType>>(`${rootUrl}/guest-visit/search`, {
				params: {
					storeIdList,
					...pageable.getPageableQueryParam(),
					from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
					to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
					statusList,
					search,
				},
			})
		).data;

interface SearchGuestVisitsType {
	storeIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	statusList?: GuestVisitType['status'][];
	search?: string;
	pageable: Pageable;
}
