import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminStockInfoType,
	AdminStockInnerInfoType,
	StockInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const createStockInfoAsync =
	(rootUrl: string) =>
	async (createStockInfo: {
		warehouseId: number;
		stockBulkList: Omit<AdminStockInnerInfoType, 'id'>[];
	}) =>
		(await posAxiosClient.post<StockInfoType>(`${rootUrl}/stock/bulk`, { ...createStockInfo }))
			.data;
