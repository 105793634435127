import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export const getDetailProductVariantsInfoAsync =
	(rootUrl: string) =>
	async ({ targetStoreId, posBoardId }: { targetStoreId: number; posBoardId: number }) =>
		(
			await posAxiosClient.get<ProductInfoType[]>(`${rootUrl}/product/variant/compare`, {
				params: {
					storeId: targetStoreId,
					posBoardId,
				},
			})
		).data;
