import { PrinterOutlined } from '@ant-design/icons';
import {
	CardCompanyStatisticsData,
	NewPaymentMethodStatisticsData,
	NewProductStatisticsData,
	PaymentSummary,
	상품매출커스터마이즈종류Type,
} from '@kinderlabs-pos/feature/pos/statistics';
import {
	DayCalendarFilterOptionType,
	CustomReceiptRequestType,
	CustomReceiptSentenseType,
	SalesDeviceInfoType,
	getSalesDeviceInfoName,
	initialCategorySpec,
	상품KeyUtil,
	포스통계출력선택TypeArray,
	포스통계출력선택TypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { aggregateSumReduceUtils, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import {
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
	authState,
} from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Suspense, useCallback, useMemo } from 'react';

import { 포스통계출력선택Type } from '@kinderlabs-pos/shared-data-type';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { Stack } from '@mui/material';

export const PrintStatisticsButtonLayer = ({
	selectedDeviceInfoList,
	option,
}: {
	selectedDeviceInfoList?: SalesDeviceInfoType[];
	option: DayCalendarFilterOptionType;
}) => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const printStacks = useMemo<Record<포스통계출력선택Type, boolean>>(
		() => ({
			SUMMARY: localSettings?.printStacks.includes('SUMMARY') ?? false,
			CARD: localSettings?.printStacks.includes('CARD') ?? false,
			PAYMENT: localSettings?.printStacks.includes('PAYMENT') ?? false,
			PRODUCT: localSettings?.printStacks.includes('PRODUCT') ?? false,
		}),
		[localSettings]
	);

	const saveLocalSettingsMutation = PosElectronStoreState.useSavePosLocalSettings();
	const changePrintStack = (출력타입: 포스통계출력선택Type, include: boolean) => {
		const newPrintStacks = {
			...printStacks,
			[`${출력타입}`]: include,
		};

		const newArray: 포스통계출력선택Type[] = [];
		Object.keys(newPrintStacks).forEach((출력타입) => {
			if (newPrintStacks[출력타입 as 포스통계출력선택Type])
				newArray.push(출력타입 as 포스통계출력선택Type);
		});

		if (localSettings)
			saveLocalSettingsMutation.mutate({ ...localSettings, printStacks: newArray });
	};

	return (
		<CardStack
			flex={1}
			direction={'row'}>
			<Stack
				flex={1}
				justifyContent={'center'}
				alignItems={'center'}>
				<Stack
					width={180}
					height={60}>
					{/* <Button
					onClick={() =>
						handleClickButton({
							calendarOption: option,
							storeId,
							selectedPosInfoList,
						})
					}>
					asdfa
				</Button> */}
					<Suspense fallback={<FullSizeCircularProgress />}>
						<PrintStatisticsButton
							calendarOption={option}
							selectesdDeviceInfoList={selectedDeviceInfoList}
							printStacks={printStacks}
						/>
					</Suspense>
				</Stack>
			</Stack>
			<Stack alignItems={'flex-end'}>
				<FormGroup>
					{포스통계출력선택TypeArray.map((출력타입) => {
						return (
							<FormControlLabel
								key={출력타입}
								control={
									<Checkbox
										checked={printStacks[출력타입]}
										onChange={(e) => {
											changePrintStack(출력타입, e.target.checked);
										}}
									/>
								}
								label={`${포스통계출력선택TypeLabel[출력타입]} 포함`}
							/>
						);
					})}
				</FormGroup>
			</Stack>
		</CardStack>
	);
};

const newLine: CustomReceiptSentenseType[] = [
	{
		type: 'lineFeed',
		number: 1,
	},
	{
		type: 'divider',
	},
	{
		type: 'lineFeed',
		number: 1,
	},
];

// 이렇게 안하면 무한렌더링 발생
const statisticsType: 상품매출커스터마이즈종류Type = {
	pageType: '매출',
	상세정보보기: true,
	옵션합쳐보기: true,
};
export const PrintStatisticsButton = ({
	calendarOption,
	selectesdDeviceInfoList,
	printStacks,
}: {
	calendarOption: DayCalendarFilterOptionType;
	selectesdDeviceInfoList?: SalesDeviceInfoType[];
	printStacks: Record<포스통계출력선택Type, boolean>;
}) => {
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
	const { from, to } = calendarOption;
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const posIdList = useMemo(
		() => selectesdDeviceInfoList?.map((p) => p.id),
		[selectesdDeviceInfoList]
	);

	const { data: 결제수단별판매, isFetching: isFetching상품별판매결제통계 } =
		NewPaymentMethodStatisticsData.usePOSData({
			filter: {
				storeIdList: [storeInfo.id],
				posIdList,
				dateType: 'DAY',
				calendarOption,
			},
		});

	const { data: 상품별매출Data, isFetching: isFetching상품별판매 } =
		NewProductStatisticsData.useData({
			filter: {
				dateType: 'DAY',
				calendarOption,
				storeIdList: [storeInfo.id],
				posIdList,
				categorySpec: initialCategorySpec,
			},
			statisticsType,
		});

	const { data: 카드사별판매, isFetching: isFetching카드사별판매 } =
		CardCompanyStatisticsData.useData({
			filter: {
				dateType: 'DAY',
				calendarOption,
				storeIdList: [storeInfo.id],
				posIdList,
			},
		});

	const data = PaymentSummary.use결제민감정보들({
		calendarOption,
		storeIdList: [storeInfo.id],
		posIdList,
	});

	const isFetching = isFetching상품별판매결제통계 || isFetching상품별판매 || isFetching카드사별판매;
	const { printCustomReceiptAsync } = PrintReceiptState.useCustomReceiptPrinter({
		deviceType: 'POS',
	});

	const handleClickDonwloadButton = useCallback(async () => {
		const printRequestTitle: CustomReceiptSentenseType[] = [
			{
				type: 'keyValue',
				key: '업체명',
				value: enterpriseInfo?.name ?? '',
			},
			{
				type: 'keyValue',
				key: '매장명',
				value: storeInfo.name,
			},
			{
				type: 'keyValue',
				key: '조회날짜',
				value: `${from.format('YYYY-MM-DD')} ~ ${to.format('YYYY-MM-DD')}`,
			},
			{
				type: 'keyValue',
				key: '발권시각',
				value: `${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
			},
		];

		const total결제수단별판매 = aggregateSumReduceUtils(
			{
				amount: 0,
				count: 0,
			},
			결제수단별판매.map(({ amount, count }) => ({ amount, count }))
		);

		const 결제요약Data: CustomReceiptSentenseType[] = [
			{
				type: 'lineFeed',
				number: 1,
			},
			...data.map(
				(r) =>
					({
						type: 'keyValue',
						key: r.label,
						value: `${r.value} 건`,
					} as CustomReceiptSentenseType)
			),
		];

		const printRequestMethodStatistics: CustomReceiptSentenseType[] = [
			{
				type: 'lineFeed',
				number: 1,
			},
			...결제수단별판매.map(
				(r) =>
					({
						type: 'keyValue',
						key: r.verticalAxis,
						value: numberWithCommasAnd원(r.amount),
					} as CustomReceiptSentenseType)
			),
			{
				type: 'keyValue',
				key: '총 합',
				value: numberWithCommasAnd원(total결제수단별판매.amount),
			},
		];

		const printRequestPerProductStatistics: CustomReceiptSentenseType[] = 상품별매출Data.map(
			(data) =>
				({
					type: 'keyValue',
					key:
						`${상품KeyUtil.getName(data.productKey)}` +
						(data.targetKey ? ` (${상품KeyUtil.getName(data.targetKey)})` : ''),
					value: `${numberWithCommasAnd원(data.revenueSum)} (수량: ${data.quantitySum})`,
				} as CustomReceiptSentenseType)
		);

		const total카드사별판매 = aggregateSumReduceUtils(
			{
				revenueSum: 0,
				paymentCountSum: 0,
			},
			카드사별판매.map(({ revenueSum, paymentCountSum }) => ({ revenueSum, paymentCountSum }))
		);
		const printRequestPerCardStatistics: CustomReceiptSentenseType[] = [
			...(카드사별판매 || []).map(
				(data) =>
					({
						type: 'keyValue',
						key: data.verticalAxis,
						value: `${data.paymentCountSum} 건 / ${numberWithCommasAnd원(data.revenueSum)}`,
					} as CustomReceiptSentenseType)
			),
			{ type: 'lineFeed', number: 1 },
			{
				type: 'keyValue',
				key: '총 합',
				value: `${total카드사별판매.paymentCountSum} 건 / ${numberWithCommasAnd원(
					total카드사별판매.revenueSum
				)}`,
			},
		];
		const printRequest: CustomReceiptRequestType = {
			sentences: (
				[
					{
						type: 'title',
						title: `[${storeInfo.name} ${
							selectesdDeviceInfoList
								? selectesdDeviceInfoList.map((d) => getSalesDeviceInfoName(d)).join(', ')
								: ''
						} 매출 통계]`,
					},
					{ type: 'lineFeed', number: 1 },
				] as CustomReceiptSentenseType[]
			)
				.concat(printRequestTitle)
				.concat(newLine)
				.concat(
					printStacks.SUMMARY
						? [
								{ type: 'title', title: `[결제 요약]` },
								{ type: 'lineFeed', number: 1 },
								...결제요약Data,
								...newLine,
						  ]
						: []
				)
				.concat(
					printStacks.PAYMENT
						? [
								{ type: 'title', title: `[결제 수단별 매출 통계]` },
								{ type: 'lineFeed', number: 1 },
								...printRequestMethodStatistics,
								...newLine,
						  ]
						: []
				)
				.concat(
					printStacks.PRODUCT
						? [
								{ type: 'title', title: `[상품별 매출 통계]` },
								{ type: 'lineFeed', number: 1 },
								...printRequestPerProductStatistics,
								...newLine,
						  ]
						: []
				)
				.concat(
					printStacks.CARD
						? [
								{ type: 'title', title: `[카드사별 매출 통계]` },
								{ type: 'lineFeed', number: 1 },
								...printRequestPerCardStatistics,
						  ]
						: []
				)
				.concat({ type: 'lineFeed', number: 1 }),
		};

		printCustomReceiptAsync({ receiptRequestData: printRequest });
	}, [printCustomReceiptAsync, isFetching, enterpriseInfo]);

	return (
		<Button
			sx={{ width: 160, height: '100%' }}
			onClick={handleClickDonwloadButton}
			color={'success'}
			disabled={isFetching}
			variant='outlined'
			startIcon={<PrinterOutlined />}>
			{isFetching ? <CircularProgress color={'success'} /> : '매출통계 출력'}
		</Button>
	);
};
