import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CommutationTicketType } from '@kinderlabs-pos/shared-data-type';

export const cancelUseCmtAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, memberId, orderId, ticketUsageId }: UseCmtAsyncRequestType) =>
		(
			await posAxiosClient.post<CommutationTicketType>(
				`${rootUrl}/ticket/cmt/store/${storeId}/pos/${posId}/use/cancel`,
				{
					memberId,
					orderId,
				},
				{
					params: {
						ticketUsageId,
					},
				}
			)
		).data;

export type UseCmtAsyncRequestType = {
	ticketUsageId: number;
	orderId: string;
	memberId: number;
	storeId: number;
	posId: number;
};
