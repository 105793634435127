import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateProductInfoAsync =
	(rootUrl: string) => async (updateProductRequestInfo: UpdaateProductInfoRequestType) =>
		(
			await posAxiosClient.put<ProductInfoType>(`${rootUrl}/product/`, updateProductRequestInfo, {
				params: {
					id: updateProductRequestInfo.id,
				},
			})
		).data;

export const updateProductInfoBulkAsync =
	(rootUrl: string) => async (updateProductRequestInfo: UpdaateProductInfoRequestType[]) =>
		(
			await posAxiosClient.put<ProductInfoType>(
				`${rootUrl}/product/bulk`,
				updateProductRequestInfo,
				{}
			)
		).data;

export type UpdaateProductInfoRequestType = ProductInfoType;
