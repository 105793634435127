import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CartType,
	OrderType,
	OrderTypeWithDdizisAndExchanges,
	PaymentInfoType,
	ReceiptInfoType,
} from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';

export const editOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, orderId, payment, receipt, newPoints, cart }: OrderEditRequestType) => {
		return (
			await posAxiosClient.put<{
				order: OrderTypeWithDdizisAndExchanges;
				payment: PaymentInfoType;
			}>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/order`,
				{
					cart,
					newPoints,
					createPaymentRequest: payment
						? {
								payment,
								receipt: receipt
									? {
											...receipt,
											approvalDate: dayjs(receipt.approvalDate).format(`YYYY-MM-DDTHH:mm:ss`),
									  }
									: undefined,
						  }
						: undefined,
				},
				{
					params: {
						id: orderId,
					},
				}
			)
		).data;
	};

export type OrderEditRequestType = {
	storeId: number;
	posId: number;
	orderId: string;
	payment?: Omit<PaymentInfoType, 'id' | 'orderId'>;
	receipt?: ReceiptInfoType;
	newPoints: number;
	cart: CartType;
};

// export type PaymentOrderCreateRequestType = {
// 	storeId: number;
// 	posId: number;
// 	type: OrderType['type'];
// 	guestVisitId?: number;
// 	cart: CartType;
// 	guestUsersInfo: OrderType['guestUsersInfo'];
// 	isDdiziStartAtPublish: boolean;
// 	points: OrderType['points'];
// 	pointMemberUserId?: string;
// };
