import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import { ReceiptInfoType, VanCompanyType } from '@kinderlabs-pos/shared-data-type';

export type HandleRefundByPM = { receipt: ReceiptInfoType } & (
	| { method: 'CASH_IC' }
	| { method: 'CARD' | 'CARD_MS' }
	| {
			method: 'CASH_현금영수증_발급' | 'TRANSFER_현금영수증_발급';
			customerId: string;
			isSignPadUsed: boolean;
	  }
	| ({ method: 'ZEROPAY' | 'SEOULPAY' } & { customerId: string })
	| ({ method: 'KAKAOPAY' | 'SSGPAY' | 'APPCARD' } & { customerId: string })
);

// 함수 이름을 이렇게 한게 매우 아쉽지만... 이게 최고 가독성 좋음
export const useHandle환불_결제모듈 = () => {
	return async ({ receipt, ...payload }: HandleRefundByPM, vanCompany: VanCompanyType) => {
		switch (payload.method) {
			case 'CARD':
				return await ReactPMIPC.invoke카드IC취소({
					...receipt,
					vanCompany: vanCompany,
				});
			case 'CARD_MS':
				return await ReactPMIPC.invoke카드MS취소({
					...receipt,
					vanCompany: vanCompany,
				});
			case 'CASH_IC':
				return await ReactPMIPC.invoke현금IC취소({
					...receipt,
					vanCompany: vanCompany,
				});
			case 'CASH_현금영수증_발급':
			case 'TRANSFER_현금영수증_발급':
				if (typeof receipt.isEnterprise !== 'boolean') throw Error('소비자/사업자여부 없음');
				// 현금 영수증 발급 취소하며 결제 취소
				return await ReactPMIPC.invoke현금영수증취소({
					...receipt,
					customerId: payload.customerId,
					isEnterprise: receipt.isEnterprise,
					isSignPadUsed: payload.isSignPadUsed,
					vanCompany: vanCompany,
				});
			case 'KAKAOPAY':
			case 'SSGPAY':
			case 'APPCARD':
				return await ReactPMIPC.invoke간편결제취소({
					...receipt,
					customerId: payload.customerId,
					vanCompany: vanCompany,
				});
			case 'ZEROPAY':
			case 'SEOULPAY':
				return await ReactPMIPC.invoke간편결제취소({
					...receipt,
					customerId: payload.customerId,
					vanCompany: vanCompany,
				});
			default:
				throw Error('[TypeError] 환불 결제 모듈 타입이 없습니다.');
		}
	};
};
