import { CartLineType } from '../Order/Cart/CartLineInfoType';
import { CategoryDataForTable } from '../admin/CategoryInfoType';
import { DdiziInfoType } from '../admin/DdiziInfoType';

export type 일자별시간대별DataType = {
	paymentAndCardRevenueList: 일자별시간대별PaymentAndCardRevenueInfo[];
	// total: 일자별시간대별PaymentAndCardRevenueInfo;
};

export type PaymentAndCardRevenueInfoBase = {
	verticalAxis: string;
	vat: number;
	paymentCountSum: number;
	revenueSumWithoutVAT: number;
	revenueSum: number;

	tax: number;
	taxFreeRevenue: number;
};

export type PaymentAndCardRevenueInfoInReceipt = {
	receiptAmountSum: number | null;
	receiptCountSum: number | null;
};

export type PaymentAndCardRevenueInfoInRefund = {
	refundAmountSum: number;
	refundCountSum: number;
};

export type 일자별시간대별PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase & {
	dayOfWeek: string;
	paymentAndCardRevenueDetailInfo: 일자별시간대별paymentAndCardRevenueDetail[];
};

export type 매출통계카드사별매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type 매장별결제수단매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		storeId: number;
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type 결제수단별매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		dayOfWeek: string;
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type 일자별시간대별paymentAndCardRevenueDetail = {
	horizontalAxis: string;
	paymentCount: number;
	revenue: number;
	revenueWithoutVAT: number;
	vat: number;

	tax: number;
	taxFreeRevenue: number;
};

export type 결제수단별매출paymentAndCardRevenueDetailInfo = {
	horizontalAxis: string;
	paymentCount: number;
	refundAmount: number;
	refundCount: number;
	revenue: number;
	revenueWithoutVAT: number;

	tax: number;
	taxFreeRevenue: number;
};

export type 매장별CategoryData = {
	categoryByStoreInfo: 매장별CategoryByStoreInfo[];
	total: 매장별CategoryByStoreInfo;
};

export type 매장별CategoryByStoreInfo = {
	storeId: number;
	storeName: string;
	categoryRevenueInfo: CategoryRevenueInfo[];
	subtotal: CategoryRevenueInfo;
};

export type 일자별CategoryData = {
	categoryByDateInfo: 일자별CategoryByDateInfo[];
	total: 일자별CategoryByDateInfo;
};

export type 일자별CategoryByDateInfo = {
	date: string;
	dayOfWeek: string;
	categoryRevenueInfo: CategoryRevenueInfo[];
	subtotal: CategoryRevenueInfo;
};

export type CategoryRevenueInfo = {
	categoryId: number;
	categoryName: string;
	revenueSum: number;
	realRevenueSum: number;
	realRevenueVAT: number;
	revenueVAT: number;
};

export type 상품별CategoryData = {
	revenueInfos: 상품별RevenueInfo[];
	total: 상품별TotalType;
};

export type 상품별RevenueInfo = {
	category: string;
	categoryId: number;
	productRevenueInfo: 상품별productRevenueInfo[];
	subtotal: 상품별TotalType;
};

export type Exapandable통계DataType<T> = T & {
	children?: Exapandable통계DataType<T>[];
};

export type WithX2<X1, X2> = X1 & {
	x2Data: X2[];
};

export type 상품별TotalType = {
	discount: number;
	name: string;
	netIncome: number;
	optionPrice: number;
	price: number | null;
	productId: string | null;
	quantitySum: number;
	realRevenueSum: number;
	revenueSum: number;
	realRevenueVAT: number;
	revenueVAT: number;
	timeInfos: 상품별TimeInfo[];
};

export type 상품KeyType = string;
export const 상품KeyUtil = {
	getType: (key: 상품KeyType) => key.split('::')[0] as CartLineType,
	getProductId: (key: 상품KeyType) => key.split('::')[1],
	getName: (key: 상품KeyType) => key.split('::')[2],
	getPrice: (key: 상품KeyType) => +key.split('::')[3],
	getRawPrice: (key: 상품KeyType) => +key.split('::')[4],
	getSupplyPrice: (key: 상품KeyType) => +key.split('::')[5],
	getTaxFree: (key: 상품KeyType) => key.split('::')[6] === 'true',
	get상품명코드기준통계Key: (key: 상품KeyType) => {
		const props = key.split('::');
		const type = props[0] as CartLineType;

		return type === 'SET_OPTION' ? [props[2]].join('::') : [props[1], props[2]].join('::');
	},
};
export type 상품별productRevenueInfo = {
	name: string;
	targetName?: string;
	category?: string;
	categoryId?: number;
	ratio?: number; // 전체중 비중
	discount: number;
	netIncome: number;
	optionPrice: number;
	price: number;
	productId: string;
	quantitySum: number;
	realRevenueSum: number;
	revenueSum: number;
	cartLineType?: CartLineType;

	productKey: 상품KeyType;
	targetKey?: 상품KeyType;

	// 0228 추가함. 아직 VAT 계산에 써먹지는 않음.
	rawPrice: number;
	supplyPrice: number;
	taxFree: boolean;
	// vat: number;

	realRevenueVAT: number;
	revenueVAT: number;
	timeInfos: 상품별TimeInfo[];

	categoryData?: CategoryDataForTable;
};

export const get카테고리그루핑키 = ({
	topCategory,
	superCategory,
	category,
}: {
	topCategory: string;
	superCategory: string;
	category: string;
}) => [topCategory, superCategory, category].join('::');

export const get카테고리그루핑해제 = (categoryKey: string) => {
	const categories = categoryKey.split('::');
	if (categories.length < 3) throw Error('카테고리 이름에 에러가 있습니다. 개발진에 문의해주세요.');

	return {
		topCategory: categories[0],
		superCategory: categories[1],
		category: categories[2],
	};
};

export const get순매출 = ({
	revenue,
	discount,
	optionPrice,
}: {
	revenue: number;
	discount: number;
	optionPrice: number;
}) => revenue + discount + optionPrice;

export type 상품별TimeInfo = {
	quantity: number;
	revenue: number;
	discount: number;
	optionPrice: number;
	dateValue: string;
};

export type GuestVisitDateType = 'YEAR' | 'MONTH' | 'DAY' | 'TIME';

export type GuestVisitByStatisticsType = {
	storeId: number;
	storeName: string;
	subTotalIn: number;
	subTotalOut: number;
	dateType: GuestVisitDateType;
	ddiziType: DdiziInfoType['ddiziType'] | null;
	visitorInOutInfos: VisitorInOutInfosType[];
};

export type VisitorInOutInfosType = {
	date: string;
	time: number;
	in: number;
	out: number;
};

export type 전년도대비매출증감RevenueType = {
	categoryId: string;
	categoryName: string;
	ex_quantity: number;
	now_quantity: number;
	revenueSum: number;
	ex_realRevenueSum: number;
	now_realRevenueSum: number;
	ratio?: number;
};

// 이건 쓰는 타입
export type 전년도대비매출증감Type = {
	storeId: number;
	storeName: string;
	date: string;
	subtotal: 전년도대비매출증감RevenueType;
	salesGrowthInfos: 전년도대비매출증감RevenueType[];
};

export type VisitorStayTimeByDateResultDTO = {
	date: string;
	day_요일: string;
	stayTime: number;
	visitorCount: number;
};

export type CategoryRevenueNewInfo = {
	categoryId: number;
	categoryName: string;
	revenueSum: number;
	realRevenueSum: number;
	realRevenueVAT: number;
	revenueVAT: number;

	superCategory?: string;
	topCategory?: string;
};
