import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { isIOS } from '@kinderlabs-pos/shared-util';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import {
	Box,
	FormControlLabel,
	InputAdornment,
	Stack,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import circleImage from 'assets/vaunce/image/circle_pink.png';
import lockImage from 'assets/vaunce/image/lock_pink.png';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';
import { LoginPageHeader } from './LoginPageHeader';
import { SNSLoginButton } from './SNSLoginRedirectPage/SNSLoginButton';

export interface ILoginPageProps {}
export const LoginPage: React.FC<ILoginPageProps> = ({}) => {
	const { navigateHomepage, navigateFindIdStepOne, navigateFindPwdStepOne, navigateSignUpStepOne } =
		useVaunceAppRouter();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const signupState = searchParams.get('already');
		if (signupState) {
			if (signupState === '7') {
				alert(`탈퇴 후 7일간은 재가입이 불가능합니다.`);
			}
			if (signupState === 'Y') {
				alert(`이미 가입된 사용자입니다. 로그인을 이용해 주세요.`);
			}
		}

		const userIdForApple = searchParams.get('userIdForApple');
		if (userIdForApple) {
			console.log('userIdForApple');
			// appleLogin();
		}
	}, []);

	async function appleLogin() {
		const result = await VaunceAppApis.postAppleLoginAsync(
			searchParams.get('userIdForApple') as string,
			navigateHomepage
		);
	}

	const [checked, setChecked] = React.useState([false, false]);

	const [logId, setLogId] = useState<string>('');
	const [logPwd, setLogPwd] = useState<string>('');

	const handleIsSaveLoginId = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, checked[1]]);
	};

	const handleIsAutoLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], event.target.checked]);
	};

	const Login = async () => {
		let loginFormData = {
			id: logId,
			password: logPwd,
			auto: checked[1],
		};

		const loginResponse = await VaunceAppApis.postLogInAsync(loginFormData);
		if (loginResponse) {
			if (checked[0]) {
				localStorage.setItem('isSaveLoginId', logId);
			}
			navigateHomepage();
		} else {
			alert('아이디와 비밀번호를 확인해주세요.');
		}
		localStorage.removeItem('auth');
		loggingEvent('로그인', 'login', 'login');
	};

	useEffect(() => {
		const getId = localStorage.getItem('isSaveLoginId');
		if (getId !== null) {
			setChecked([true, checked[1]]);
			setLogId(getId);
		}
	}, []);

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<LoginPageHeader />
			<LoginHeaderTypograpy>LOGIN</LoginHeaderTypograpy>

			<TextField
				data-cy='loginIdTextField'
				sx={{ mb: '3rem', fontSize: '16px' }}
				value={logId}
				onChange={(event) => {
					setLogId(event?.target.value);
				}}
				variant='standard'
				placeholder={'아이디 (이메일 계정)'}
				InputProps={{
					style: { fontSize: '16px', fontWeight: 500 },
					startAdornment: (
						<InputAdornment position='start'>
							<img src={circleImage} />
						</InputAdornment>
					),
				}}
			/>
			<TextField
				data-cy='loginPWTextField'
				sx={{ mb: '3rem', fontSize: '16px' }}
				value={logPwd}
				onChange={(event) => {
					setLogPwd(event?.target.value);
				}}
				variant='standard'
				type={'password'}
				placeholder={'비밀번호'}
				InputProps={{
					style: { fontSize: '16px', fontWeight: 500 },
					startAdornment: (
						<InputAdornment position='start'>
							<img src={lockImage} />
						</InputAdornment>
					),
				}}
			/>
			<Stack
				direction={'row'}
				justifyContent={'center'}
				mb={'40px'}>
				<FormControlLabel
					label={<Typography variant='h5'>아이디 저장</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[0]}
							onChange={handleIsSaveLoginId}
						/>
					}
				/>
				<FormControlLabel
					label={<Typography variant='h5'>자동 로그인</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[1]}
							onChange={handleIsAutoLogin}
						/>
					}
				/>
			</Stack>
			<RoundedButton
				data-cy='vaunceAppLoginBtn'
				sx={{ height: 58 }}
				onClick={Login}>
				<Typography
					variant='h4'
					sx={{ fontFamily: 'Lato', textTransform: 'none' }}>
					Log in
				</Typography>
			</RoundedButton>

			<Stack
				direction={'row'}
				justifyContent={'center'}
				my={4}
				spacing={2}>
				<Typography
					data-cy='findIdRouteBtn'
					onClick={() => {
						navigateFindIdStepOne();
					}}
					sx={{ cursor: 'pointer' }}
					variant='h6'
					fontWeight={400}>
					아이디 찾기
				</Typography>
				<Typography>|</Typography>
				<Typography
					onClick={() => {
						navigateFindPwdStepOne();
					}}
					sx={{ cursor: 'pointer' }}
					variant='h6'
					fontWeight={400}>
					비밀번호 찾기
				</Typography>
				<Typography>|</Typography>

				<Typography
					onClick={() => {
						navigateSignUpStepOne();
						localStorage.removeItem('accessToken');
						localStorage.removeItem('refreshToken');
						localStorage.removeItem('auth');
						localStorage.removeItem('provider');
					}}
					sx={{ cursor: 'pointer' }}
					variant='h6'
					fontWeight={400}>
					회원가입
				</Typography>
			</Stack>

			<SNSLoginBox>
				<Typography
					variant='h4'
					sx={{ opacity: 0.7 }}
					mb={'2rem'}>
					SNS로 로그인하기
				</Typography>
				<SNSLoginButton provider='kakao' />
				<SNSLoginButton provider='naver' />
				{/* {isIOS() && <SNSLoginButton provider='apple' />} */}
			</SNSLoginBox>
		</Stack>
	);
};
export default LoginPage;

const SNSLoginBox = styled(Box)(({ theme }) => ({
	marginTop: '3rem',
	textAlign: 'center',
}));
