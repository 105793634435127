import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { AuthorityType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const updateManagerInfoAsync =
	(rootUrl: string) =>
	async ({ data, myAuthority }: UpdateManagerRequestInfo) =>
		(
			await posAxiosClient.put<ManagerUserType>(
				`${rootUrl}/auth-service/manager/member/${myAuthority}/modify`,
				{
					...data,
				}
			)
		).data;

export type UpdateManagerRequestInfo = {
	data?: Omit<ManagerUserType, 'authority' | 'authorityLevel' | 'enterpriseInfo'>;
	myAuthority: AuthorityType;
};
