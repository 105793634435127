import { CashUnitKeyTypeArray, CashUnitTypeIndex } from '@kinderlabs-pos/shared-data-type';
import BackspaceIcon from '@mui/icons-material/Backspace';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Box, Button, styled, Typography } from '@mui/material';
import { VaultcashFunctionType } from './common';

export const VaultcashNumberBoard: React.FC<
	Pick<VaultcashFunctionType, 'numBills' | 'setNumBills' | 'selectedBill' | 'setSelectedBill'>
> = ({ numBills, setNumBills, selectedBill, setSelectedBill }) => {
	const handleAddNumber = (val: string) => () => {
		let amount = numBills[selectedBill];
		if (val === '0') {
			setNumBills({ ...numBills, [selectedBill]: amount * 10 });
		} else if (val === '00') {
			setNumBills({ ...numBills, [selectedBill]: amount * 100 });
		} else if (val === '000') {
			setNumBills({ ...numBills, [selectedBill]: amount * 1000 });
		} else {
			setNumBills({ ...numBills, [selectedBill]: !amount ? +val : amount * 10 + +val });
		}
	};
	const handleDeleteNumber = () => {
		let value = numBills[selectedBill];
		if (value === null) return;
		const rem = value % 10;
		const mod = (value - rem) / 10;
		setNumBills({ ...numBills, [selectedBill]: mod });
	};

	const handleClearNumber = () => {
		setNumBills({ ...numBills, [selectedBill]: 0 });
	};

	const handleMoveFocus = () => {
		let index = CashUnitTypeIndex[selectedBill];
		if (index < CashUnitKeyTypeArray.length - 1) {
			++index;
		}
		setSelectedBill(CashUnitKeyTypeArray[index]);
	};

	return (
		<Box
			display="grid"
			gridTemplateColumns="repeat(3, 75px)"
			gridTemplateRows="repeat(6, 60px)"
			justifyContent="center">
			<StyledButton
				onClick={handleClearNumber}
				sx={{ gridRow: '1', gridColumn: 'span 2' }}>
				<Typography sx={{ fontSize: 20 }}>{'Clear'}</Typography>
			</StyledButton>
			<StyledButton
				onClick={handleDeleteNumber}
				sx={{ gridRow: '1', gridColumn: '3' }}>
				<BackspaceIcon />
			</StyledButton>
			{['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0', '000'].map((val, idx) => (
				<StyledButton
					key={idx}
					sx={{ flex: 1, bgcolor: 'white' }}
					onClick={handleAddNumber(val)}
					disabled={val === '00' || val === '000'}>
					<Typography sx={{ fontSize: 20 }}>{val}</Typography>
				</StyledButton>
			))}
			<StyledButton
				onClick={handleMoveFocus}
				sx={{ gridRow: '6', gridColumn: 'span 3', marginTop: 1.5, height: '60px' }}>
				<Typography sx={{ fontSize: 20 }}>ENTER</Typography>
				<KeyboardReturnIcon />
			</StyledButton>
		</Box>
	);
};

const StyledButton = styled(Button)(({ theme }) => ({
	border: '1px solid lightgray',
	padding: 0,
	borderRadius: 0,
	'& .MuiTypgraphy-root': {
		fontSize: theme.typography.subtitle1.fontSize,
	},
}));
