import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { Pageable } from '../PageType';
import { BaseSearchInfoType } from './BaseInfoType';
import { ProductType } from './ProductInfoType';

export const DiscountTypeArray = ['VALUE', 'PERCENTAGE'] as const;
export type DiscountType = (typeof DiscountTypeArray)[number];
export const DiscountTypeLabel: Record<DiscountType, string> = {
	VALUE: '금액 할인',
	PERCENTAGE: '요율 할인',
};

export const DiscountTypeUnitLabel: Record<DiscountType, string> = {
	VALUE: '원',
	PERCENTAGE: '%',
};

export type ProductDiscountInfoType = {
	name: string;
	value: number;
	type: DiscountType;
	evaluatedValue: number;
};

export type ProductDiscountMgInfoType = Omit<ProductDiscountInfoType, 'evaluatedValue'> & {
	id: number;
	isForAllCenter: boolean;
	isForAllProduct: boolean;
	isDisabled: boolean;
	discountStoreWhitelist: number[] | null;
	discountProductWhitelist: number[] | null;
};

export type AdminProductDiscountInfoListFilterType = {
	page: number;
	search?: string;
	size?: number;
	sort?: Pageable['sort'];
	type?: DiscountType;
	storeId?: number;
};

export interface AdminProductDiscountHistroyInfoType extends ProductDiscountMgInfoType {
	deleted: boolean;
}

/**
 * 얘는 살려놓는게 편하겠다.
 * 여러 종류의 discount Label 에서 활용
 *
 * @param discountInfo
 * @returns
 */
export const getDiscountLabel = (discountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>) =>
	`${
		discountInfo.type === 'PERCENTAGE'
			? discountInfo.value + ' %'
			: numberWithCommasAndWon(discountInfo.value)
	} 할인`;
