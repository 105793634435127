import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AddressType,
	AgeType,
	DdiziInfoType,
	MainStatisticsType,
	OnlineTicketStatisticsType,
	TierType,
	VisitUserGendersType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export type PaymentAndCardRevenueInfoBase = {
	verticalAxis: string;
	vat: number;
	paymentCountSum: number;
	revenueSumWithoutVAT: number;
	revenueSum: number;

	tax: number;
	taxFreeRevenue: number;
};

export type PaymentAndCardRevenueInfoInReceipt = {
	receiptAmountSum: number | null;
	receiptCountSum: number | null;
};

export type PaymentAndCardRevenueInfoInRefund = {
	refundAmountSum: number;
	refundCountSum: number;
};

export type 매장별결제수단매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		storeId: number;
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type New결제수단매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		// storeId: number;
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type 결제수단별매출PaymentAndCardRevenueInfo = PaymentAndCardRevenueInfoBase &
	PaymentAndCardRevenueInfoInReceipt &
	PaymentAndCardRevenueInfoInRefund & {
		dayOfWeek: string;
		paymentAndCardRevenueDetailInfo: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	};

export type 일자별시간대별paymentAndCardRevenueDetail = {
	horizontalAxis: string;
	paymentCount: number;
	revenue: number;
	revenueWithoutVAT: number;
	vat: number;

	tax: number;
	taxFreeRevenue: number;
};

export type 캘린더DataType = {
	calendarRevenueByDates: CalendarRevenueByDatesType[];
	total: CalendarRevenueByDatesType;
};

export type CalendarRevenueByDatesType = {
	date: string;
	month: string;
	dayOfMonth: string;
	validRevenueAmountSum: number;
	validRevenueCountSum: number;
	nextDayRefundAmountSum: number;
	nextDayRefundCountSum: number;
};

export type 결제수단별매출paymentAndCardRevenueDetailInfo = {
	horizontalAxis: string;
	paymentCount: number;
	refundAmount: number;
	refundCount: number;
	revenue: number;
	revenueWithoutVAT: number;

	tax: number;
	taxFreeRevenue: number;
};

export type 매장별외부매출Data = {
	storeId: number;
	onlineTicketRevenue: number;
	onlineTicketCount: number;
	multiUseTicketRevenue: number;
	multiUseTicketCount: number;
	commutationTicketRevenue: number;
	commutationTicketCount: number;
	couponRevenue: number;
	couponCount: number;
	klcouponRevenue: number;
	klcouponCount: number;
};

export type 매장별결제내용참고Data = {
	validRevenueAmountSum: number;
	validRevenueCountSum: number;
	nextDayRefundAmountSum: number;
	nextDayRefundCountSum: number;
	revenueWithReceipt: number;
	countWithReceipt: number;
	cardReceiptRevenue: number;
	cardReceiptCount: number;
	cashReceiptCount: number;
	cashReceiptRevenue: number;
};

export const get매장별SalesExternalStatistics =
	(rootUrl: string) =>
	async ({ storeIdList, date }: { storeIdList?: number[]; date: Dayjs }) =>
		(
			await posAxiosClient.get<매장별외부매출Data>(
				`${rootUrl}/report-bff/summary/external-revenue`,
				{
					params: {
						storeIdList: storeIdList,
						date: date.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

export const get매장별결제내용참고Statistics =
	(rootUrl: string) =>
	async ({
		date,
		fromTime,
		toTime,
		storeIdList,
	}: {
		date: Dayjs;
		fromTime: number;
		toTime: number;
		storeIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<매장별결제내용참고Data>(
				`${rootUrl}/report-bff/summary/valid-payment`,
				{
					params: {
						storeIdList: storeIdList,
						date: date.format('YYYY-MM-DD'),
						fromTime: fromTime,
						toTime: toTime,
					},
				}
			)
		).data;

const getMainStatistics =
	(rootUrl: string) =>
	async ({ storeIdList, from, to }: { storeIdList?: number[]; from: Dayjs; to: Dayjs }) =>
		(
			await posAxiosClient.get<{
				date: MainStatisticsType;
			}>(`${rootUrl}/statistics/main/visitor/revenue`, {
				params: {
					storeIdList,
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
				},
			})
		).data;

const getStatisicsForPaymentSum =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		posId,
		from,
		to,
	}: {
		storeIdList?: number[];
		posIdList?: number[];
		posId?: number;
		from: Dayjs;
		to: Dayjs;
	}) =>
		(
			await posAxiosClient.get<{ revenueSumByStores: StatisicsForPaymentSumType[] }>(
				`${rootUrl}/statistics/main/payment/sum`,
				{
					params: {
						storeIdList,
						posIdList,
						posId,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

type StatisicsForPaymentSumType = {
	매장별: string;
	판매금액: number;
	카드: number;
	'현금(발급)': number;
	'현금(미발급)': number;
	외상: number;
	포인트: number;
	온라인: number;
	간편결제: number;
	'제로페이(MPM)': number;
	'서울페이(MPM)': number;
	가상카드: number;
	'계좌이체(발급)': number;
	'계좌이체(미발급)': number;
};

const get매장별이용객입퇴장매출Statistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		date,
		fromTime,
		toTime,
	}: {
		storeIdList?: number[];
		date: Dayjs;
		fromTime: number;
		toTime: number;
	}) =>
		(
			await posAxiosClient.get<매장별GuestVisitSalesType>(
				`${rootUrl}/report-bff/summary/visitor-revenue/time`,
				{
					params: {
						storeIdList: storeIdList,
						date: date.format('YYYY-MM-DD'),
						fromTime: fromTime,
						toTime: toTime,
					},
				}
			)
		).data;

const getOnlineTicketStatistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		from,
		to,
	}: {
		storeIdList?: number[];
		posIdList?: number[];
		from: Dayjs;
		to: Dayjs;
	}) =>
		(
			await posAxiosClient.get<OnlineTicketStatisticsType[]>(
				`${rootUrl}/pos-bff/online-ticket/statistics`,
				{
					params: {
						storeIdList,
						posIdList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get매장별OnlineTicketStatistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		from,
		to,
	}: {
		storeIdList?: number[];
		posIdList?: number[];
		from: Dayjs;
		to: Dayjs;
	}) =>
		(
			await posAxiosClient.get<매장별OnlineTicketStatisticsType>(
				`${rootUrl}/pos-bff/online-ticket/statistics/store`,
				{
					params: {
						storeIdList,
						posIdList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

const get체널별OnlineTicketStatistics =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		channelList,
		from,
		to,
	}: {
		storeIdList?: number[];
		posIdList?: number[];
		channelList?: string[];
		from: Dayjs;
		to: Dayjs;
	}) =>
		(
			await posAxiosClient.get<체널별OnlineTicketStatisticsType>(
				`${rootUrl}/pos-bff/online-ticket/statistics/channel`,
				{
					params: {
						storeIdList,
						posIdList,
						channelList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
					},
				}
			)
		).data;

export type 매장별OnlineTicketStatisticsType = {
	onlineTicketStatisticsByStore: OnlineTicketStatisticsByStore[];
	total: OnlineTicketStatisticsByStore;
};

export type OnlineTicketStatisticsByStore = {
	storeId: number | null;
	storeName: string;
	onlineTicketStatistics: null | OnlineTicketStatisticsDetail[];
	subTotal: OnlineTicketStatisticsDetail;
};

export type OnlineTicketStatisticsDetail = {
	storeId: number;
	channelName: null | string;
	ticketName: null | string;
	ticketPrice: null | number;
	productId?: number;
	rawPrice?: number;
	supplyPrice?: number;
	quantity: number;
	amount: number;
};

export type 체널별OnlineTicketStatisticsType = {
	onlineTicketStatisticsByStore: 체널별OnlineTicketStatisticsByStore[];
	total: 체널별OnlineTicketStatisticsByStore;
};

export type 체널별OnlineTicketStatisticsByStore = {
	channelName: string;
	onlineTicketStatistics: OnlineTicketStatisticsDetail[];
	subTotal: OnlineTicketStatisticsDetail;
};

export type GuestVisitDateType = 'YEAR' | 'MONTH' | 'DAY' | 'TIME';

export type 매장별GuestVisitSalesType = {
	byTimeInfos: [
		{
			storeId: number;
			storeName: string;
			totalVisitors: number;
			normalUses: number;
			parentUsers: number;
			totalRevenue: number;
			totalRevenueExceptVAT: number;
			time: string;
		}
	];
	total: {
		storeId: number;
		storeName: string;
		totalVisitors: number;
		normalUses: number;
		parentUsers: number;
		totalRevenue: number;
		totalRevenueExceptVAT: number;
	};
};

export type StoreOperationStatus = 'BEFORE_OPEN' | 'OPEN' | 'CLOSED';
export const getStoreOperationStatus = (status: StoreOperationStatus) => {
	switch (status) {
		case 'BEFORE_OPEN':
			return '영업 전';
		case 'OPEN':
			return '영업 중';
		case 'CLOSED':
			return '영업 종료';
		default:
			return '영업 종료';
	}
};

export const getStoreOperationStatusColor = (status: StoreOperationStatus) => {
	switch (status) {
		case 'BEFORE_OPEN':
			return 'warning';
		case 'OPEN':
			return 'success';
		case 'CLOSED':
			return 'error';
		default:
			return '';
	}
};

export type 고객대기StatisticsType = {
	storeId: number;
	storeName: string;

	waiting: number;
	processing: number;
	completed: number;
	lastCompletedSunbun: number;
};

export type 고객현황StatisticsType = {
	storeId: number;
	storeName: string;

	nowUsing: number;
	nowUsingNormalVisitors: number;
	nowUsingParentVisitors: number;
};

export type 고객총방문StatisticsType = {
	storeId: number;
	storeName: string;

	totalVisitors: number;
	totalNormalVisitors: number;
	totalParentVisitors: number;
};

export type 매출요약StatisticsType = {
	storeId: number;
	storeName: string;

	offlineRevenue: number;
	onlineRevenue: number;
	totalRevenue: number;
	totalRevenueWithoutVAT: number;
};

export type 회원StatisticsType = {
	storeId: number;
	storeName: string;

	totalMember: number;
	newMember: number;
	reVisitMember: number;
};

export type 매장고객대기현황Type = 고객대기StatisticsType &
	고객현황StatisticsType &
	고객총방문StatisticsType &
	매출요약StatisticsType & {
		storeOperationStatus: StoreOperationStatus;
	};

export type 매장별고객방문매출요약Type = 고객총방문StatisticsType &
	매출요약StatisticsType &
	회원StatisticsType;

export type GuestVisitByStatisticsType = {
	storeId: number;
	storeName: string;
	subTotalIn: number;
	subTotalOut: number;
	dateType: GuestVisitDateType;
	ddiziType: DdiziInfoType['ddiziType'] | null;
	visitorInOutInfos: VisitorInOutInfosType[];
};

export type GuestmemberStatisticsResponseType = {
	statsByStore: {
		storeId: number;
		value: number;
	}[];
	statsByGender: {
		gender: VisitUserGendersType;
		value: number;
	}[];
	statsByAgeGroup: {
		ageGroup: AgeType;
		value: number;
	}[];
	statsByTier: {
		tier: TierType;
		value: number;
	}[];
	statsByAddress: {
		region: string;
		statsByDistrict: {
			address: AddressType;
			value: number;
		}[];
	}[];
};

export type VisitorInOutInfosType = {
	date: string;
	time: number;
	in: number;
	out: number;
};

export const SalesStatistics = {
	get매장별결재내용참고: get매장별결제내용참고Statistics,

	get매장별외부매출: get매장별SalesExternalStatistics,
	get매장별이용객입퇴장매출Statistics: get매장별이용객입퇴장매출Statistics,

	getOnlineTicketStatistics,
	get매장별OnlineTicketStatistics,
	get체널별OnlineTicketStatistics,
	getMainStatistics,
	getStatisicsForPaymentSum,
};
