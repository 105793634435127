import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, DialogProps, Stack, Typography } from '@mui/material';
import { FormattedDialog } from '../../components/FormattedDialog';
import GrayBox from '../../components/GrayBox';

export interface INoticeInfoDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
	noticeId: number;
}
export const NoticeInfoDialog: React.FC<INoticeInfoDialogProps> = ({ open, onClose, noticeId }) => {
	const noticeDetail = VaunceQueryState.useNoticeDetailQuery(noticeId).notice;

	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack
				spacing={2}
				sx={{ pb: 2 }}>
				<GrayBox>
					<Typography variant='h4'>{noticeDetail.title}</Typography>
					<Stack
						direction={'row'}
						spacing={1}>
						<Typography
							variant='h6'
							fontWeight={400}>
							{noticeDetail.centerName}
						</Typography>
						<Typography
							variant='h6'
							fontWeight={400}>
							|
						</Typography>
						<Typography
							variant='h6'
							fontWeight={400}>
							{noticeDetail.createDate}
						</Typography>
					</Stack>
				</GrayBox>

				<Box
					sx={{ fontSize: '16px', fontWeight: 400 }}
					dangerouslySetInnerHTML={{ __html: noticeDetail.noticeContents }}
				/>
			</Stack>
		</FormattedDialog>
	);
};
