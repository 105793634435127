import { GuestVisitDetailContent } from '@kinderlabs-pos/feature/waiver/guestvisits';
import { GuestVisitQueryState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { GuestVisitActionButtons } from '../../components/GuestVisitActionButtons';
import { usePosRouter } from '../routes';

interface IGuestVisitDetailContentWrapperProps {
	guestVisitId: number;
	withActions?: boolean;
}
export const GuestVisitDetailContentWrapper: React.FC<IGuestVisitDetailContentWrapperProps> = ({
	guestVisitId,
	withActions = false,
}) => {
	const { data } = useQuery({
		...GuestVisitQueryState.keys.detail(guestVisitId),
		refetchOnWindowFocus: true,
	});

	const { navigate주문조회 } = usePosRouter();

	return (
		<GuestVisitDetailContent
			navigate주문내역={(orderId: string) => navigate주문조회({ keyword: orderId })}
			guestVisitId={guestVisitId}
			GuestVisitActions={withActions && data && <GuestVisitActionButtons guestVisit={data} />}
		/>
	);
};
