import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const rollbackStockHistoryAsync = (rootUrl: string) => async (historyId: number) =>
	(
		await posAxiosClient.put(`${rootUrl}/stock/history/restore`, null, {
			params: {
				historyId,
			},
		})
	).data;
