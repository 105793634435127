import { Card, CardActions, CardProps } from "@mui/material";
import { SnackbarKey } from "notistack";
import { CSSProperties, ReactNode } from 'react';

export interface INotistackCardProps extends CardProps {
  cardColor: string
  children: ReactNode
}
export const NotiStackCard: React.FC<INotistackCardProps> = ({ cardColor, children }, ref) => {
  return (
    <Card sx={{
      bgcolor: cardColor,
      width: "100%"
    }}>
      <CardActions sx={{
        p: 1,
        pl: 2,
        justifyContent: "space-between"
      }}>
        {children}
      </CardActions>
    </Card>
  )
};

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    warning: false;
    // adds `myCustomVariant` variant  
    // adds `reportComplete` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    ticket: {
      ticketId: string
    }
    order: {
      orderId: string
    }
  }
}

export const snackBarStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  // minWidth: 500,
  // maxWidth: 500,
  width: 500
}