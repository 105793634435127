import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useVaunceAppRouter } from '../../pages/routes';
import { vaunceColors } from '@kinderlabs-pos/shared-const';

export interface IEventCarouselProps {}
export const EventCarousel: React.FC<IEventCarouselProps> = ({}) => {
	const eventList = VaunceQueryState.useEventListQuery();

	const { navigateEventDetail } = useVaunceAppRouter();

	const [activeIndex, setActiveIndex] = useState<number>(0);

	return (
		<Stack>
			<Box
				display='flex'
				width='100%'
				height='4px'>
				{eventList.map((event, index) => (
					<Box
						key={index}
						flex='1'
						height='100%'
						bgcolor={index === activeIndex ? vaunceColors.pink : '#707070'}
					/>
				))}
			</Box>
			<Carousel
				autoPlay={true}
				interval={3500}
				indicators={false}
				onChange={(now?: number) => {
					// 0인 경우 작동해야함
					if (now != undefined) setActiveIndex(now);
				}}>
				{eventList.map((event, index) => (
					<ContentBox
						className='slide'
						key={event.imgUrl}
						style={{
							backgroundImage: `url(${event.imgUrl})`,
							backgroundSize: '100% 111px',
							color: 'white',
							textAlign: 'center',
						}}
						onClick={() => {
							navigateEventDetail(event.id);
						}}>
						<Typography
							color={'#FFF'}
							variant='h3'>
							{event.topText}
						</Typography>
						<Typography
							color={'#FFF'}
							variant='h3'
							fontWeight={900}>
							{event.bottomText}
						</Typography>
					</ContentBox>
				))}
			</Carousel>
		</Stack>
	);
};

export const ContentBox = styled(Box)(({ theme }) => ({
	flexDirection: 'column',
	justifyContent: 'center',
	display: 'flex',
	minHeight: '111px',
	height: '100%',
	width: '100%',
}));
