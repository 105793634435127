import { rootUrl } from '../../const';
import { deleteManagerInfoAsync } from './deleteManagerInfoAsync';
import { getManagerInfoDetailAsync } from './getManagerInfoDetailAsync';
import { getManagerInfoListAsync } from './getManagerInfoListAsync';
import { updateAuthorityAsync } from './updateAuthorityAsync';
import { updateManagerInfoAsync } from './updateManagerInfoAsync';
import { getManagerHistoryListAsync } from './getManagerHistoryListAsync';

export const AdminManagerInfoApis = {
  updateManagerInfo : updateManagerInfoAsync(rootUrl),
  updateAuthority : updateAuthorityAsync(rootUrl),
  getManagerInfoList : getManagerInfoListAsync(rootUrl),
  getManagerDetailInfo : getManagerInfoDetailAsync(rootUrl),
  deleteManagerInfo : deleteManagerInfoAsync(rootUrl),
  getManagerInfoHistory : getManagerHistoryListAsync(rootUrl)
}