import { CartSummary, CartTableWithItems } from '@kinderlabs-pos/feature/pos/cart-table';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Suspense } from 'react';
import { PaymentResult } from '../../../modules/payments/PaymentResult';

export const OrderEditResultPage = () => {
	return (
		<Stack
			flex={1}
			direction={'row'}
			spacing={1}
			height={'100%'}
			sx={{ overflow: 'auto' }}>
			<CardStack
				flex={2}
				sx={{ overflow: 'auto' }}>
				<Box
					flex={1}
					sx={{ overflow: 'auto' }}>
					<CartTableWithItems />
				</Box>
				<CartSummary />
			</CardStack>
			<CardStack
				variant={'nocolors'}
				flex={3}>
				<Suspense fallback={<CircularProgress />}>
					<PaymentResult
						mode={'order_print'}
						// 	mode === 'jungsan'
						// 		? 'jungsan'
						// 		: originOrderStatus === 'PARTIALLY_CANCELED'
						// 		? 'order_not_print'
						// 		: 'order_print'
						// }
					/>
				</Suspense>
			</CardStack>
		</Stack>
	);
};
