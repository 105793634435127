import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

export interface IReplayButtonProps {
	onClick: IconButtonProps['onClick'];
	disabled?: boolean;
	label?: string;
}
export const ReplayButton: React.FC<IReplayButtonProps> = ({ onClick, disabled, label }) => {
	return (
		<IconTextButton
			iconSize='large'
			onClick={onClick}
			disabled={disabled}
			icon={<ReplayIcon fontSize='small' />}
			label={<Typography>{label || '대기 재등록'}</Typography>}
		/>
	);
};
