import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	DialogContent,
	DialogProps,
	Divider,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';

const noticeContent = [
	{
		title: '이용 항목',
		content: '성명, 성별, 생년월일, 휴대전화번호, 이메일 주소',
	},
	{
		title: '이용 목적',
		content:
			'신규 서비스 개발, 멤버십 혜택 및 각종 이벤트 안내, 맞춤 서비스 제공, 서비스 이용 통계 및 설문',
	},
	{ title: '이용 기간', content: '회원 탈퇴 시 까지' },
];

export interface IMarketingAgreeDialogProps extends DialogProps {
	closeDialog: () => void;
}
export const MarketingAgreeDialog: React.FC<IMarketingAgreeDialogProps> = ({
	open,
	closeDialog,
}) => {
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			fullWidth
			maxWidth={'lg'}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}>
			<DialogContent>
				<Stack spacing={2}>
					<Typography
						color={vaunceColors.lightPink}
						variant='h4'>
						마케팅 활용 동의
					</Typography>
					<Divider />

					{noticeContent.map((notice) => (
						<Stack>
							<Typography
								variant='h4'
								fontWeight={700}>
								{notice.title}
							</Typography>
							<Typography variant='h4'>{notice.content}</Typography>
						</Stack>
					))}
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
