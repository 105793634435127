import { ReactNode } from 'react';

export type ConsentType = {
	title: string;
	contents: ContentType[];
}[];

export type ContentType = {
	type: 'text' | 'list';
	subtitle: string;
	actualContent: ActualContentType[];
};

export type ActualContentType = {
	detail: ReactNode;
	subDetail?: ReactNode[];
};

export const TermsOfUseData: ConsentType = [
	{
		title: '제 1장 총칙',
		contents: [
			{
				type: 'text',
				subtitle: '제1조 (목적)',
				actualContent: [
					{
						detail:
							" 본 약관은 ㈜바운스(이하 “회사”라 합니다.)가 인터넷상에서 제공하는 모든 서비스(이하 '서비스'라 하며, 접속가능한 유무선 단말기의 종류와는 상관없이 이용 가능한 “회사”가 제공하는 모든 “서비스”를 의미합니다.)를 이용함에 있어 회원과 회사간의 권리, 의무 및 책임사항 및 절차를 규정함을 목적으로 합니다.",
					},
				],
			},
			{
				type: 'list',
				subtitle: '제2조 (용어의 정의)',
				actualContent: [
					{
						detail:
							'회원 : 고객 중 회사의 모바일 웹페이지(이하 “웹”이라 합니다.) 또는 모바일 어플리케이션(이하 “앱”이라합니다.)을 통해 이용 약관과 개인정보의 수집, 제공 및 활용에 관한 동의서에 동의하고, 바운스 멤버십에 가입한자를 말합니다.',
					},
					{
						detail:
							'아이디 : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 영문자와 숫자의 조합을 말합니다.',
					},
					{
						detail:
							'비밀번호 : 회원의 정보 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합을 말합니다.',
					},
					{
						detail:
							'포인트 : 바운스 멤버십 회원이 바운스 매장 또는 앱을 통해 회사의 제품 및 상품을 구매할 경우, 정해진 조건에따라 적립되고 사용할 수 있는 사이버 화폐를 의미합니다.',
					},
					{
						detail: '매장 : 회사가 운영하는 전국 직영점을 의미합니다.',
					},
					{
						detail:
							'유료멤버십 : 일정한 금액을 월 또는 연간으로 지불하고 회사가 정한 혜택을 제공하는 멤버십 서비스를 말합니다.',
					},
					{
						detail:
							'멀티패스 : 회원이 앱을 통해 회사가 정한 할인율로 다회 이용권을 구매하고 매장에서 사용하는 서비스를말합니다.',
					},
					{
						detail:
							'정기회원권 : 회원이 정해진 기간의 요금을 지불하고 기간 내에 자유롭게 매장을 이용할 수 있는 서비스를말합니다.',
					},
					{
						detail:
							'이용등록 : 회원이 매장을 방문하여 이용신청을 할 때 앱에 미리 등록된 이용자 정보를 사용하여 매장에서 쉽게신청을 할 수 있는 서비스를 말합니다.',
					},
					{
						detail:
							'본 약관에서 사용하는 용어의 정의는 본 조에서 정한 것을 제외하고는 거래 관행 및 관계 법령을 따릅니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제3조 (약관의 효력 및 변경)',
				actualContent: [
					{
						detail:
							'본 약관은 서비스에 가입된 고객을 포함하여 서비스를 이용하고자 본 약관에 동의한 모든 회원에 대하여 그효력을 발생합니다.',
					},
					{
						detail:
							'본 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지하고, 이에 동의한 회원이 서비스에가입함으로써 효력이 발생합니다.',
					},
					{
						detail:
							'회사는 본 약관의 내용을 이용자가 알 수 있도록 웹과 앱 초기 서비스 화면에 게시합니다.',
					},
					{
						detail:
							'본 약관은 회사가 필요하다고 인정되는 경우 변경할 수 있으며, 약관이 변경된 경우 지체 없이 제2항과 같은 방법으로 공지합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 규정의 변경은 최소 7일전에 공지하고, 이용자에게 불리한 변경은 최소 30일전에 공지합니다.',
					},
					{
						detail:
							'회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있고, 변경된 약관의효력 발생일로부터 30일 이내 해지요청을 하지 않은 회원은 변경된 약관에 동의한 것으로 간주합니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제4조 (약관 외 준칙)',
				actualContent: [
					{
						detail:
							'본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 공정거래법 등 관계법령 및 회사가 제공하는 서비스에 관한 별도의 약관, 이용규정 또는 세부 이용 지침 등의 규정에 따릅니다.',
					},
					{
						detail:
							'회사와 회원간의 상업적인 계약과 관련하여 본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.',
					},
				],
			},
		],
	},
	{
		title: '제2장 회원 관리 및 멤버십 서비스',
		contents: [
			{
				type: 'list',
				subtitle: '제5조 (멤버쉽 회원 가입)',
				actualContent: [
					{
						detail:
							'바운스 멤버십 회원이 되고자 하는 고객은 본 약관에 동의한 후에 바운스 멤버십 프로그램에 참여할 수 있습니다.',
					},
					{
						detail:
							'만 14세 미만 아동은 개인정보의 수집 및 활용 목적에 대하여 충분히 숙지하고 법정대리인의 동의를 받아야합니다.',
					},
					{
						detail:
							'이용계약은 서비스 이용자가 본 약관에 동의 후 이용 신청에 대하여 회사가 승낙함으로써 성립합니다.',
					},
					{
						detail:
							'회원은 회원 자격을 타인에게 양도하거나 대여하거나 담보의 목적물로 이용할 수 없습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제6조 (멤버십 회원의 종류)',
				actualContent: [
					{
						detail: '회원은 가입 형태에 따라 다음과 같이 구분됩니다.',
						subDetail: [
							'정회원 : 앱을 통해 가입한 회원으로 회사가 제공하는 모든 바운스 멤버십 서비스를 제공받을 수 있습니다.',
							'유료회원: 앱을 통해 가입한 회원이 유료멤버십 상품을 가입하는 경우 기본 멤버십 서비스 외에 추가 서비스를제공받을 수 있습니다.',
						],
					},
				],
			},
			{
				type: 'list',
				subtitle: '제7조 (회원 탈퇴 및 자격 상실)',
				actualContent: [
					{
						detail:
							'회원은 언제든지 웹과 앱을 통해 회원 탈퇴를 요청할 수 있으며, 회사는 회원의 요청에 따라 조속히 회원 탈퇴에 필요한 제반 절차를 수행합니다. 단, 회원이 탈퇴를 요청할 때에는 회원이 보유하고 있는 모든 멤버십 혜택(멤버십 등급, 적립 포인트, 쿠폰 등)이 소멸되는 것을 전제로 합니다.',
					},
					{
						detail: '회원 탈퇴 시, 다음 각 호와 같은 조건에 따라 탈퇴가 제한될 수 있습니다.',
						subDetail: [
							'멀티패스, 정기회원권, 유료멤버십 등에 가입 및 구매한 회원의 이용권 또는 기간이 남아 있는 경우, 사용 혹은 환불을 진행한 후 탈퇴할 수 있습니다.',
							'사용 가능한 모바일 이용권을 보유하고 있는 경우, 사용 혹은 환불을 진행한 후 탈퇴할 수 있습니다.',
						],
					},
					{
						detail:
							'회원이 다음 각 호의 사유에 해당하는 경우, 회사는 당해 회원에 대한 통보로써 회원의 자격을 제한 또는정지시킬 수 있고, 14일의 기간을 정하여 시정하거나 소명할 기회를 부여한 뒤, 회원의 소명이 없거나 그 소명이정당하지 아니할 경우 회원의 자격을 상실시킬 수 있습니다. 단, 회원이 사망한 경우에는 별도의 통보 없이 자격이 상실됩니다.',
						subDetail: [
							'회원 가입시 허위의 내용을 등록한 경우',
							'바운스 리워드 또는 쿠폰을 부정 적립 또는 부정 사용하는 등 서비스를 부정한 방법 또는 목적으로 이용한 경우',
							'다른 회원의 서비스 이용을 방해하거나 그 정보를 도용하는 경우',
							'바운스 멤버십 이용과 관련하여 법령, 본 약관 또는 공서양속에 반하는 행위를 하는 경우',
							'회사 또는 다른 회원의 명예를 훼손하거나 모욕하는 경우',
							'상품을 정당한 이유 없이 상습적으로 취소 또는 환불하는 등의 방법으로 회사의 업무를 현저히 방해하는 경우',
							'재판매 목적으로 재화 등을 대량으로 중복 구매하여 공정한 거래질서를 현저히 방해하는 경우',
							'제품과 상품 구매 및 서비스 이용 시 정상적인 거래 범위를 현저히 이탈하여 오남용 하는 경우',
							'기타 이에 준하는 경우로써 회원으로서의 자격을 지속시키는 것이 객관적으로 부적절하다고 판단되는 경우',
						],
					},
				],
			},
			{
				type: 'list',
				subtitle: '제8조(포인트 적립 및 등급별 혜택)',
				actualContent: [
					{
						detail: '포인트 적립 및 사용',
						subDetail: [
							'바운스 매장 또는 앱에서 상품을 구매한 회원은 회사가 고지한 바에 따라 가입시 등록한 휴대전화번호 입력또는 웹과 앱의 회원 바코드 제시를 통해 포인트를 적립 받을 수 있습니다. (세부내용은 회사의 앱 안내에따릅니다.)',
							'포인트는 바운스 매장에서 100포인트부터 100포인트 단위로 사용이 가능합니다.',
						],
					},
					{
						detail: '회원 등급별 혜택 및 쿠폰의 유효기간',
						subDetail: [
							'이용실적에 따라 회원의 등급이 부여되며, 등급별로 다양한 혜택이 제공됩니다.단, 각 등급별 혜택은 회사의 관련 정책에 따라 변경될 수 있으며, 자세한 내용은 웹 또는 앱에서 확인이 가능합니다.',
							'바운스 멤버십으로 인해 발급된 쿠폰은 발급 시 안내한 유효기간 내에서만 사용 가능합니다.',
							'현장에서 멤버십으로 인해 발급된 쿠폰을 사용할 경우에는 반드시 쿠폰 바코드 또는 QR코드가 있는 화면 또는 이미지를 제시하는 것을 원칙으로 합니다.',
						],
					},
				],
			},
			{
				type: 'list',
				subtitle: '제9조(포인트 유효기간 및 소멸)',
				actualContent: [
					{
						detail: '포인트의 유효기간은 적립일을 포함하여 365일입니다.',
					},
					{
						detail:
							'유효기간 365일이 경과된 포인트는 익일 자동 소멸 처리되며, 유효기간 만료 이전에 웹과 앱을 통해 조회하실 수 있습니다.',
					},
					{
						detail: '제품 및 상품 구매 결제로 적립된 포인트는 해당 결제 취소 시 적립이 취소됩니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제10조(회원의 의무)',
				actualContent: [
					{
						detail:
							'회원은 본 약관에서 규정하는 사항과 이용안내 또는 공지사항 등을 통하여 회사가 공지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.',
					},
					{
						detail:
							'회원의 아이디와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다.회원에게 부여된 아이디와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.',
					},
					{
						detail:
							'회원은 자신의 아이디나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야하며, 신고를 하지 않아 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.',
					},
					{
						detail:
							'회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과와 회원이 약관에 위반한 영업활동을 하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회원은 회사에 대하여 손해배상의무를 집니다.',
					},
					{
						detail:
							'회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제11조(휴면계정 관리)',
				actualContent: [
					{
						detail:
							'회사는 회원이 웹 또는 앱에 12개월 이상 로그인 하지 않거나 12개월 이상 거래내역이 없는 경우, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 아래와 같이 제한 / 상실시킬 수 있습니다.',
					},
					{
						detail:
							'12개월 이상 서비스에 로그인하지 않거나 거래 내역 또한 없을 경우에는 휴면계정으로 별도 관리되어 멤버십 서비스 이용이 중단되고, 이후 다시 이용하시려면 인증 절차를 통해 휴면 해제를 해주시기 바랍니다.또한 휴면계정으로 별도 관리되는 경우 포인트와 등급별 쿠폰 혜택이 제공되지 않으며, 등급별 유지 조건 미충족 시 회원 등급 레벨이 하향 조정 될 수 있습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제12조(회원 탈퇴와 포인트 및 쿠폰의 소멸 처리)',
				actualContent: [
					{
						detail:
							'본 약관 제 7조 제1항에 정해진 방법으로 회원 탈퇴 시 요청일 현재까지 적립된 포인트와 쿠폰은 자동으로 소멸되며, 재가입 시 소멸된 포인트 및 쿠폰은 복원되지 않습니다.',
					},
					{
						detail:
							'본 약관 제 7조 제2항에 따라 회원 자격이 상실된 경우, 자격 상실일을 기준으로 적립된 포인트와 쿠폰은 자동으로 소멸됩니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제13조(회원에 대한 통지)',
				actualContent: [
					{
						detail:
							'회원에 대한 통지를 하는 경우, 회사는 회원이 등록한 e - mail 주소 또는 SMS 등으로 할 수 있습니다.',
					},
					{
						detail:
							'회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.',
					},
					{
						detail: '제2항의 사유로 통지하는 경우, 부득이한 사정에 의해 통지가 생략될 수 있습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제14조(개인정보의 보호)',
				actualContent: [
					{
						detail: '개인정보취급과 관련된 사항은 회사의 개인정보처리방침을 따릅니다.',
					},
				],
			},
		],
	},
	{
		title: '제3장 유료멤버십 서비스',
		contents: [
			{
				type: 'list',
				subtitle: '제15조(유료멤버십 서비스 적용범위)',
				actualContent: [
					{
						detail: '회원은 앱을 통해 제공하는 유료멤버십 서비스를 이용할 수 있습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제16조(유료멤버십 서비스 이용 및 취소)',
				actualContent: [
					{
						detail:
							'회원은 월간 또는 연간 유료멤버십을 가입할 수 있고, 월간 유료멤버십의 경우 정기 결제를 설정할 수 있으며 연간 유료멤버십은 일시납으로 이용할 수 있습니다.',
					},
					{
						detail:
							'유료멤버십의 구체적인 운영 방침이나 활용 방법 및 혜택은 회사의 마케팅 정책에 따라 변경될 수 있습니다.',
					},
					{
						detail:
							'유료멤버십은 이용료 결제일 7일 이내에 어떠한 혜택도 사용하지 않은 경우에 한해 청약철회가 가능합니다.',
					},
					{
						detail:
							'청약철회 기간이 지난 경우에도 어떠한 혜택도 사용하지 않은 경우에는 이용 기간 내에 해지가 가능하며, 마지막 결제 금액에서 위약금 10 % 를 공제한 90 % 가 환불됩니다.',
					},
					{
						detail:
							'연간 유료멤버십은 1년의 가입을 유지하는 조건으로 할인 가격에 제공되며, 1년 경과전에 구독을 해지하면 본 조 제4항의 위약금 및 유료멤버십을 통해 혜택받은 금액을 공제하고 환불됩니다.',
					},
				],
			},
		],
	},
	{
		title: '제4장 멀티패스 서비스',
		contents: [
			{
				type: 'list',
				subtitle: '제17조(멀티패스 서비스 적용범위)',
				actualContent: [
					{
						detail: '회원은 앱을 통해 제공하는 멀티패스 서비스를 이용할 수 있습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제18조(멀티패스 서비스 이용 및 취소)',
				actualContent: [
					{
						detail: '회원은 다회 이용권을 회사가 정한 할인가에 구매 및 사용이 가능합니다.',
					},
					{
						detail:
							'멀티패스의 구체적인 운영 방침이나 활용 방법 및 혜택은 회사의 마케팅 정책에 따라 변경될 수 있습니다.',
					},
					{
						detail:
							'멀티패스 구매 시 회원이 선택한 매장에서만 사용이 가능하며 2시간 이용권으로만 사용 가능합니다.단, 영통센터는 2시간 30분 이용 가능합니다.',
					},
					{
						detail:
							'멀티패스는 결제일 7일 이내에 1회도 사용하지 않은 경우에 한해 전액 환불이 가능합니다.',
					},
					{
						detail:
							'환불 가능 기간이 지난 경우에도 유효기간 내에 환불이 가능하며, 결제 금액에서 위약금 10 % 와 사용된 횟수의 금액을(정상가 기준) 공제하고 환불됩니다.',
					},
					{
						detail: '유효기간이 만료된 후에는 환불이 불가합니다.',
					},
					{
						detail:
							'일부 매장은 멀티패스 사용 시, 이용자의 조건(키 또는 나이)과 구매한 멀티패스의 조건이 맞지 않는 경우 현장에서 차액에 대한 추가 결제가 있을 수 있습니다.',
					},
				],
			},
		],
	},
	{
		title: '제5장 정기회원권 서비스',
		contents: [
			{
				type: 'list',
				subtitle: '제19조(정기회원권 서비스 적용범위)',
				actualContent: [
					{
						detail: '회원은 앱을 통해 제공하는 정기회원권 서비스를 이용할 수 있습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제20조(정기회원권 서비스 이용 및 취소)',
				actualContent: [
					{
						detail:
							'회원은 정기회원권 구매 및 가입이 가능하며 기간과 매장을 선택하고 결제 시 선택한 매장에서 정해진 기간 내에 1일 1회 2시간 이용이 가능합니다.',
					},
					{
						detail:
							'정기회원권의 구체적인 운영 방침이나 활용 방법 및 혜택은 회사의 마케팅 정책에 따라 변경될 수 있습니다.',
					},
					{
						detail:
							'정기회원권 구매 시 회원이 선택한 매장에서만 사용이 가능하며 2시간 이용권으로만 사용 가능합니다.단,영통센터는 2시간 30분 이용 가능합니다.',
					},
					{
						detail:
							'정기회원권은 결제일 7일 이내에 1회도 사용하지 않은 경우에 한해 전액 환불이 가능합니다.',
					},
					{
						detail:
							'전액 환불 가능 기간이 지난 경우에도 유효기간 내에 환불이 가능하며, 결제 금액에서 위약금 10 % 와 사용된 횟수의 금액을(정상가 기준) 공제하고 잔여 금액을 남은 기간의 비율에 따라 차등 환불됩니다.',
					},
					{
						detail: '유효기간이 만료된 후에는 환불이 불가합니다.',
					},
					{
						detail:
							'일부 매장은 정기회원권 사용 시, 이용자의 조건(키 또는 나이)과 구매한 정기회원권의 조건이 맞지 않는 경우 현장에서 차액에 대한 추가 결제가 있을 수 있습니다.',
					},
				],
			},
		],
	},
	{
		title: '제6장 기타',
		contents: [
			{
				type: 'list',
				subtitle: '제21조(손해 배상)',
				actualContent: [
					{
						detail:
							'회사 또는 회원이 본 약관의 규정을 위반함으로 인하여 상대방에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회사 또는 회원은 상대방에게 발생하는 모든 손해를 배상하여야 합니다.',
					},
					{
						detail:
							'회사 또는 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 상대방이 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회사 또는 회원은 자신의 책임과 비용으로 상대방을 면책 시켜야 하며, 면책되지 못한 경우 그로 인하여 발생한 모든 손해를 배상하여야 합니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제22조(면책조항)',
				actualContent: [
					{
						detail:
							'회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.',
					},
					{
						detail:
							'회사는 회원의 귀책사유로 인한 서비스의 이용 장애에 대하여 책임을 지지 않습니다.',
					},
					{
						detail:
							'회사는 어떠한 경우에도 간접적∙결과적 손해나 통상의 손해를 제외한 특별한 손해에 대해서는 책임을 지지 않습니다.',
					},
					{
						detail:
							'회원이 서비스에 게재한 정보ㆍ자료ㆍ사실의 신뢰도 및 정확성 등 내용에 대하여는 회원이 책임을 부담하며, 회사는 동 내용의 부정확 또는 허위로 인해 회원 또는 제3자에게 발생한 손해에 대하여는 아무런 책임을 지지 않습니다.',
					},
					{
						detail:
							'회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.',
					},
					{
						detail:
							'회사는 회원 상호간 및 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.',
					},
					{
						detail:
							'회사는 무료로 제공하는 서비스 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.',
					},
				],
			},
			{
				type: 'list',
				subtitle: '제33조(관할법원)',
				actualContent: [
					{
						detail:
							'서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.',
					},
					{
						detail:
							'본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있으며, 소송이 제기될 경우 서울중앙지방법원을 전속 관할법원으로 합니다.',
					},
				],
			},
		],
	},
];
