import { SelectOutlined } from '@ant-design/icons';
import { OrderDetailContents } from '@kinderlabs-pos/feature/pos/orders';
import { GuestMemberInfoSearchType, OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
} from '@mui/material';
import {
	GuestMemberSearchingActionReturnType,
	useGetGuestMemberSearchingActions,
} from 'apps/pos-main/src/modules/member-search/Formiks/useGetMemberSearchingActions';
import { UserInfoResultSection } from 'apps/pos-main/src/modules/member-search/UserInfoResultSection';
import { UserInfoSearchSection } from 'apps/pos-main/src/modules/member-search/UserInfoSearchSection';
import { OrderActions } from 'libs/state/src/lib/state/PosOrder/OrderState/OrderActions';
import { useDialogActions } from 'libs/ui/atoms/src/lib/WithDialog/useDialogActions';
import { usePosRouter } from '../../routes';

export const OrderDetailContentsWrapper = ({
	order,
	hasNavigate = false,
}: {
	order: OrderDetailType;
	hasNavigate?: boolean;
}) => {
	const { open, closeDialog, openDialog } = useDialogActions();
	const { navigate주문조회 } = usePosRouter();
	const confirm = useConfirm();
	const deleteOrderMember = OrderActions.useDeleteMember();
	const guestmemberSearchActions = useGetGuestMemberSearchingActions();
	const handleGoto주문조회 = () => {
		confirm(`주문 번호 ${order.id} 로 주문 페이지에서 검색하시겠습니까?`, () => {
			navigate주문조회({
				keyword: `${order.id}`,
			});
		});
	};

	const handleDeleteMember = () => {
		confirm('고객 정보를 삭제하시겠습니까?', async () => {
			await deleteOrderMember.mutateAsync(order.id);
			guestmemberSearchActions.resetAllState();
		});
	};

	const NavigateActions = (
		<IconButton onClick={handleGoto주문조회}>
			<SelectOutlined />
		</IconButton>
	);

	return (
		<>
			<OrderDetailContents
				order={order}
				NavigateActions={hasNavigate ? NavigateActions : undefined}
				guestMemberAction={{
					openConnectDialog: openDialog,
					deleteMember: handleDeleteMember,
				}}
			/>
			<GuestMemberConnectDialog
				open={open}
				orderId={order.id}
				closeDialog={closeDialog}
				guestMemberSearchingActions={guestmemberSearchActions}
			/>
		</>
	);
};

const GuestMemberConnectDialog = ({
	open,
	orderId,
	closeDialog,
	guestMemberSearchingActions,
}: {
	open: boolean;
	orderId: string;
	closeDialog: () => void;
	guestMemberSearchingActions: GuestMemberSearchingActionReturnType;
}) => {
	const confirm = useConfirm();
	const {
		isSearching,
		isRendered,
		setIsRender,
		resetAllState,
		result,
		setResult,
		handleSearchByKeyword,
		multipleUserInfoList,
		MultipleDialogComponent,
	} = guestMemberSearchingActions;

	const updateOrderMember = OrderActions.useUpdateMember();

	const closeDialogWithReset = () => {
		resetAllState();
		closeDialog();
	};

	const handleOrderMember = () => {
		if (!result.info) return;
		confirm('고객 정보를 추가하시겠습니까?', async () => {
			await updateOrderMember.mutateAsync({ orderId, memberInfo: result.info });
			closeDialog();
		});
	};

	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			fullWidth
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>고객 정보 추가</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<UserInfoSearchSection
						isLoading={isSearching}
						handleSearchByKeyword={handleSearchByKeyword}
						guestMemberInfoActions={{
							setGuestMemberInfo: (info: GuestMemberInfoSearchType) => setResult(info),
							multipleUserInfoList,
						}}
					/>
					<UserInfoResultSection
						searchStatus={result.status}
						guestMemberInfoActions={{
							guestMemberInfo: result.info,
							setIsRender: () => setIsRender(true),
							withoutDeleteAction: true,
							withoutRunAction: true,
							withoutUpdateAction: true,
						}}
					/>
					<MultipleDialogComponent />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!result.info || !isRendered}
					variant='contained'
					onClick={handleOrderMember}>
					{'확인'}
				</Button>
				<Button
					variant='outlined'
					onClick={closeDialogWithReset}>
					{'닫기'}
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
