import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CartType,
	DeviceType,
	OrderType,
	OrderTypeWithDdizisAndExchanges,
	PaymentInfoType,
	ReceiptInfoType,
} from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';

export const postOrderAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		deviceType,
		deviceId,
		orderId,
		payment,
		receipt,
		isLastPayment,
		orderCreateRequest,
	}: OrderRequestType) => {
		return (
			await posAxiosClient.post<{
				order: OrderTypeWithDdizisAndExchanges;
				payment: PaymentInfoType;
			}>(`${rootUrl}/order/store/${storeId}/device/${deviceType}/${deviceId}/order`, {
				orderId,
				isLastPayment,
				orderCreateRequest,
				createPaymentRequest: payment
					? {
							payment,
							receipt: receipt
								? {
										...receipt,
										approvalDate: dayjs(receipt.approvalDate).format(`YYYY-MM-DDTHH:mm:ss`),
								  }
								: undefined,
					  }
					: undefined,
			})
		).data;
	};

export type OrderRequestType = {
	storeId: number;
	deviceType: DeviceType;
	deviceId: number;
	orderId?: string;
	isLastPayment: boolean;
	payment?: Omit<PaymentInfoType, 'id' | 'orderId'>;
	receipt?: ReceiptInfoType;
	orderCreateRequest?: {
		storeId: number;
		posId: number;
		type: OrderType['type'];
		cart: CartType;
		guestMemberInfo: OrderType['guestMemberInfo'];
		isDdiziStartAtPublish: boolean;
		points: OrderType['points'];
		pointMemberUserId?: string;
		kioskUserTelephone?: string;
	};
};
