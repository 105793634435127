import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { PageHeader } from '../../components/PageHeader';
import { AppGuideData } from './AppGuideData';

export const AppGuidePage = ({}) => {
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='앱 이용안내' />
			<List sx={{ flex: 1, overflow: 'inherit' }}>
				{AppGuideData.map((item, idx) => (
					<ListItem
						key={idx}
						sx={{ borderBottom: `1px solid ${vaunceColors.lightGray}`, padding: 3 }}>
						<Stack
							direction={'row'}
							spacing={2}>
							<Stack
								alignItems={'center'}
								sx={{ paddingRight: 1.5 }}>
								<img
									src={item.imageUrl}
									srcSet={item.srcsetUrl}
									width={34}
									height={34}
								/>
							</Stack>
							<Stack spacing={1}>
								<Typography
									variant='h3'
									fontWeight={700}
									sx={{ color: `${vaunceColors.lightPink}` }}>
									{item.title}
								</Typography>
								<Typography variant='h5'>{item.contents}</Typography>
							</Stack>
						</Stack>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};
