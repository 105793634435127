import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	WarehouseOrderRequestType,
	WarehouseOrderResponseType,
} from '@kinderlabs-pos/shared-data-type';

export const createWarehouseOrderAsync =
	(rootUrl: string) => async (params: WarehouseOrderRequestType) =>
		(
			await posAxiosClient.post<WarehouseOrderResponseType[]>(
				`${rootUrl}/warehouse-order/request`,
				params
			)
		).data;
