import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CommutationTicketDetailType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';

export const getCmtDetailAsync =
	(rootUrl: string) =>
	async ({ ticketId, memberId, pageable }: GetCmtDetailAsyncRequestType) =>
		(
			await posAxiosClient.get<PageType<CommutationTicketDetailType>>(
				`${rootUrl}/ticket/cmt/detail`,
				{
					params: {
						...pageable.getPageableQueryParam(),
						ticketId,
						memberId,
					},
				}
			)
		).data;

export type GetCmtDetailAsyncRequestType = {
	ticketId: number;
	memberId: number;
	pageable: Pageable;
};
