import { useEffect } from "react";
import { debounceTime, filter, fromEvent, map, scan, timeInterval, timeout, withLatestFrom } from "rxjs";
import inko from 'inko'

const 바코드최소길이 = 2; // 티켓id 가 1이면 안 찍힐텐데 걱정된다.
const 키보드UpDownThreshold = 10; // 키보드 up, down 시간 간격
const 연속입력시간Threshold = 20; // ms
const 바코드찍는최소시간 = 500 // ms - 500ms 에 한번씩만 바코드가 인식됨.

// 테스트용
// const 키보드UpDownThreshold = 100; // 키보드 up, down 시간 간격
// const 연속입력시간Threshold = 30000; // ms

const inkoInstance = new inko();
export const useBarcodeScanner = (callback: (number: string) => void) => {
  useEffect(() => {
    const down = fromEvent<KeyboardEvent>(document, "keydown");
    const up = fromEvent<KeyboardEvent>(document, "keyup");

    // keyup 과 keydown 이 timestamp 10 차이 이하
    const scannerKeys = up.pipe(
      filter(e => e.code !== "ShiftLeft"),
      withLatestFrom(down, (up, down) => ({
        key: `${down.key}`,
        pressTime: up.timeStamp - down.timeStamp,
        e: up,
      })),
      filter((x) => x.pressTime < 키보드UpDownThreshold) // 설정값 매우 중요
    );

    const scanned = scannerKeys.pipe(
      timeInterval(),
      scan((acc, value) =>
        value.value.key === "Enter" ? ""
          : value.interval > 연속입력시간Threshold
            ? value.value.key : acc + value.value.key, ""),
      filter((x) => x.length >= 바코드최소길이), // 길이 2 이상인것만 통과
      debounceTime(바코드찍는최소시간),
      map(x => inkoInstance.ko2en(x))
    )

    const sub = scanned.subscribe((e) => {
      callback(e)
    })

    return () => sub.unsubscribe()
  }, [callback])
}