import { Typography } from '@mui/material';
import { ErrorMessage } from 'formik';

export interface IVaunceHelperTextProps {
	name: string;
	dataCy?: string;
}
export const VaunceHelperText: React.FC<IVaunceHelperTextProps> = ({ name, dataCy }) => {
	return (
		<ErrorMessage
			name={name}
			render={(msg) => (
				<Typography
					variant='h6'
					fontWeight={500}
					data-cy={dataCy}
					component={'span'}
					color={'primary'}>
					{msg}
				</Typography>
			)}
		/>
	);
};
