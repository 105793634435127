import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { PrinterInfoType } from '@kinderlabs-pos/shared-data-type';

export const useSendOpenBox = ({
	receiptPrinterList: RPList,
	printMode,
}: {
	receiptPrinterList: PrinterInfoType[];
	printMode: 'BIXOLONSDK' | 'ESCPOS';
}) => {
	return async () => {
		const CandidatePrinterInfos = RPList.filter(
			(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
		);
		for (const printerInfo of CandidatePrinterInfos) {
			await ReactRPIPC.sendOpenCashBox({
				printerInfo: printerInfo,
				printMode,
			});
		}
	};
};

// JM : BXLN 레거시 코드 백업
// if (RPList.length === 0) {
//   await ReactPosIPC.hardware.sendOpenCashBox({
//     deviceInfo: undefined,
//     printMode: 'BIXOLONSDK',
//   });
// }
