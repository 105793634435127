import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosBoardWithOtherStoreAsync =
	(rootUrl: string) =>
	async ({
		targetBoardId,
		targetStoreId,
		sourceBoardId,
		productIdList,
	}: {
		targetStoreId: number;
		targetBoardId: number;
		sourceBoardId: number;
		productIdList: number[];
	}) =>
		(
			await posAxiosClient.put(`${rootUrl}/pos-board/${targetBoardId}/item/cover`, null, {
				params: {
					storeId: targetStoreId,
					boardId: targetBoardId,
					sourceBoardId,
					productIdList,
				},
			})
		).data;
