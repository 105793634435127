import { OrderState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { Suspense } from 'react';
import { UserInfoBox } from '../../../UserInfoBox';
import { useAtomValue } from 'jotai';

export interface IProcessingPanelProps {}
export const ProcessingPanel: React.FC<IProcessingPanelProps> = ({}) => {
	const { guestMemberInfo } = useAtomValue(OrderState.value);

	return (
		<Stack
			direction={'row'}
			alignItems={'center'}>
			<Suspense>
				<UserInfoBox
					userInfo={guestMemberInfo}
					userLength={guestMemberInfo?.users?.length || 0}
				/>
			</Suspense>
		</Stack>
	);
};
