import { PremiumOneType } from '@kinderlabs-pos/shared-data-type';
import { CloseIconBox } from '@kinderlabs-pos/ui-atoms';
import { Dialog, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import QrCode from '../../components/QrCode';
export interface IOneTicketQrDialogProps extends DialogProps {
	ticket: PremiumOneType;
	onClose: () => void;
}
export const OneTicketQrDialog: React.FC<IOneTicketQrDialogProps> = ({ open, onClose, ticket }) => {
	return (
		<Dialog
			maxWidth='md'
			open={open}
			onClose={onClose}
			fullWidth>
			<DialogContent>
				<Stack
					direction={'row'}
					justifyContent={'flex-end'}>
					<IconButton onClick={onClose}>
						<CloseIconBox />
					</IconButton>
				</Stack>

				<Stack>
					<Stack
						textAlign={'center'}
						margin={'30px 0 40px'}
						alignItems={'center'}
						spacing={1}>
						{/* <Typography variant="h1" color="primary">
							사용자 정보
						</Typography>
						<Box
							display={'flex'}
							borderRadius={'50%'}
							bgcolor={'rgba(0, 0, 0, 0.30)'}
							width={'140px'}
							height={'140px'}
							alignItems={'center'}
						>
							<img src={ticket.imageUrl ?? 'http://101.101.217.174/image/logo@2x.png'} />
						</Box> */}

						<Typography variant='h4'>
							{ticket.title && ticket.title.split('_').length > 1
								? '[' + ticket.title?.split('_')[1] + '] ' + ticket.title?.split('_')[3]
								: ticket.title}
						</Typography>
						<Stack>
							<Typography variant='h5'>유효기간 ({ticket.expiredAt} 까지)</Typography>
						</Stack>

						<QrCode
							qrData={ticket.orderNum}
							style={{ marginTop: '40px' }}
						/>
						<Typography variant='h4'>이용권 QR 코드</Typography>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
