import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { SNSProviderType, SNSRedirectType } from '@kinderlabs-pos/shared-data-type';
import { useAlert, useConfirm } from '@kinderlabs-pos/ui-components';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	loginPageRouteObject,
	signupSecondRouteObject,
	useSnsRedirectRouteParams,
	useVaunceAppRouter,
} from '../../routes';

export const SnsRedirectPage = ({}) => {
	let { provider, type } = useSnsRedirectRouteParams();
	if (!provider || !type) throw new Error('해당 페이지는 접근이 불가능합니다.');

	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	// naver 의 경우는 state, kakao 의 경우는 redirectURI
	const additionalInfo = getAdditionalInfo(searchParams, provider, type);

	const signUp = useHandleSignup();
	const login = useHandleLogin();

	useEffect(() => {
		if (!code || !additionalInfo || !provider) return;

		if (type === 'signup') {
			signUp({ code, additionalInfo, provider });
		} else {
			login({ code, additionalInfo, provider });
		}
	}, [code, additionalInfo]);

	return <CircularProgress />;
};

const getAdditionalInfo = (
	searchParams: URLSearchParams,
	provider: SNSProviderType,
	type: SNSRedirectType
) => {
	switch (provider) {
		case 'naver':
			return searchParams.get('state');
		case 'kakao':
			return VaunceAppApis.getSNSLoginRedirectURL(provider, type);
		case 'apple':
			return VaunceAppApis.getSNSLoginRedirectURL(provider, type);
	}
};

const useHandleLogin = () => {
	const navigate = useNavigate();
	const customConfirm = useConfirm();
	const customAlert = useAlert();

	const { navigateSignUpStepTwo } = useVaunceAppRouter();
	return async ({
		code,
		additionalInfo,
		provider,
	}: {
		code: string;
		additionalInfo: string;
		provider: SNSProviderType;
	}) => {
		try {
			const result = await VaunceAppApis.postSnsLogInAsync({
				code,
				additionalInfo,
				provider,
				onSuccess: () => {
					navigate('/', { replace: true });
				},
				onNotMember: ({ onConfirm }) => {
					customConfirm(
						'바운스 회원이 아닙니다. 회원가입을 하시겠습니까?',
						() => {
							onConfirm();
							navigate(`/members/${signupSecondRouteObject.path}`, { replace: true });
						},
						() => {
							navigate(`/members/${loginPageRouteObject.path}`, { replace: true });
						}
					);
				},
			});
		} catch (e) {
			console.error(e);

			// 추후 Formik 으로 바뀌어도 됨
			customAlert('로그인에 실패하였습니다.', () =>
				location.replace(`/members/${loginPageRouteObject.path}`)
			);
		}
	};
};

const useHandleSignup = () => {
	const navigate = useNavigate();
	const customAlert = useAlert();

	return async ({
		code,
		additionalInfo,
		provider,
	}: {
		code: string;
		additionalInfo: string;
		provider: SNSProviderType;
	}) => {
		try {
			const result = await VaunceAppApis.postSnsLogInForSignupAsync({
				code,
				additionalInfo,
				provider,
				onSuccess: () => {
					navigate(`/members/${signupSecondRouteObject.path}`, { replace: true });
				},
				onAlreadyMember: () => {
					customAlert('이미 가입된 유저입니다. 로그인하였습니다.', () => navigate('/'));
				},
			});
		} catch (e) {
			console.error(e);

			// 추후 Formik 으로 바뀌어도 됨
			customAlert('SNS 회원가입에 실패하였습니다.', () => location.replace('/members/signup'));
		}
	};
};
