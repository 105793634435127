import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PosBoardInPosBffType } from '@kinderlabs-pos/shared-data-type';

export const getPosBoardListAsync = (rootUrl: string) => {
	return async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<PosBoardInPosBffType[]>(`${rootUrl}/pos-bff/board/list`, {
				params: {
					storeId,
				},
			})
		).data;
};
