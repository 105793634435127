import { MobileCheckerApis } from '@kinderlabs-pos/apis/storeinfo';
import { atom, Provider } from 'jotai';
import { ReactNode } from 'react';

const _GuestVisitTokenAtom = atom<{ token: string; enterpriseId: string } | undefined>(undefined);
const mobileCheckerInfoAtom = atom(async (get) => {
	const tokenAndEnterpriseId = get(_GuestVisitTokenAtom);

	if (!tokenAndEnterpriseId) throw new Error('Provider 를 이용하여 GuestVisitToken');
	const result = await MobileCheckerApis.getGuestVisitinfo({
		guestVisitToken: tokenAndEnterpriseId.token,
		enterpriseId: tokenAndEnterpriseId.enterpriseId,
	});

	return { ...result, enterpriseId: tokenAndEnterpriseId.enterpriseId };
});

const GuestVisitTokenProvider = ({
	children,
	enterpriseId,
	guestVisitToken,
}: {
	children: ReactNode;
	enterpriseId: string;
	guestVisitToken: string;
}) => {
	return (
		<Provider initialValues={[[_GuestVisitTokenAtom, { token: guestVisitToken, enterpriseId }]]}>
			{children}
		</Provider>
	);
};

export const MobileCheckerInfoState = {
	TokenProvider: GuestVisitTokenProvider,
	value: mobileCheckerInfoAtom,
};
