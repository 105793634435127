import {
	canceledStyleUtils,
	getDateFormat,
	numberWithCommasAndWon,
} from '@kinderlabs-pos/shared-util';
import { Button, Stack, Typography, alpha } from '@mui/material';
import { ItemSelectType, OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsOrder = ({
	selected,
	setSelected,
	orderWithDetail,
}: OrderAndPaymentsType) => {
	return (
		<Button
			variant='contained'
			sx={(theme) => ({
				width: '100%',
				backgroundColor: alpha(theme.palette.primary.main, selected.type === 'order' ? 1.0 : 0.5),
			})}
			onClick={() => {
				setSelected({ type: 'order' });
			}}>
			<Stack
				direction={'row'}
				sx={{ flex: 1 }}
				alignItems={'center'}
				spacing={1}>
				<Stack>
					<Typography
						variant='h4'
						sx={{
							textAlign: 'left',
							flex: 1,
							...canceledStyleUtils(orderWithDetail.status === 'CANCELED'),
						}}>
						{`주문 정보`}
					</Typography>
					<Typography
						variant='body1'
						sx={{ textAlign: 'left', flex: 1 }}>
						{`${orderWithDetail.guestMemberInfo?.name || '-'}`}
					</Typography>
				</Stack>
				<Stack
					flex={1}
					alignItems={'flex-end'}>
					<Typography
						variant='h4'
						sx={{
							textAlign: 'right',
							flex: 1,
							...canceledStyleUtils(orderWithDetail.status === 'CANCELED'),
						}}>
						{`${numberWithCommasAndWon(orderWithDetail.aggregate.totalToPay)}`}
					</Typography>
					<Typography
						variant='body1'
						sx={{ textAlign: 'right', flex: 1 }}>
						{`${getDateFormat(orderWithDetail.created)}`}
					</Typography>
				</Stack>
			</Stack>
		</Button>
	);
};
