import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { DdiziState, OrderState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Card, CardActionArea, Stack, Typography, alpha } from '@mui/material';
import { useAtomValue } from 'jotai';
import { usePosRouter } from '../routes';

const get남은금액인지추가금액인지Label = (totalpaid: number) =>
	totalpaid > 0 ? '남은 금액' : '추가 금액';

export interface IExitPaymentCardProps {
	orderId: string;
	onComplete: () => void;
}
export const ExitPaymentCard: React.FC<IExitPaymentCardProps> = ({ orderId, onComplete }) => {
	const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);

	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);
	const enterExitMutation = DdiziState.actions.useEnterExit();

	const { navigate결제 } = usePosRouter();

	const noTickets = selectedCartLines.length === 0;
	const confirm = useConfirm();

	const handleClickCard = () => {
		if (remainingPay > 0) {
			navigate결제({ mode: 'jungsan', orderId: orderId });
		} else {
			confirm('모두 퇴장 완료 처리하시겠습니까?', () => {
				selectedCartLines.forEach((cl) =>
					enterExitMutation.mutate({ ddiziId: cl.ddiziUsageId, type: 'EXIT' })
				);

				onComplete();
			});
		}
	};

	return (
		<Card sx={{ aspectRatio: '1.6/1', height: 100 }}>
			<CardActionArea
				disabled={noTickets}
				sx={(theme) => ({
					height: '100%',
					bgcolor: noTickets ? theme.palette.divider : alpha(theme.palette.primary.main, 0.5),
				})}
				onClick={handleClickCard}>
				{noTickets && (
					<Stack alignItems={'center'}>
						<Typography variant='subtitle1'>선택된 티켓 없음</Typography>
					</Stack>
				)}
				{!noTickets &&
					(remainingPay > 0 ? (
						<Stack alignItems={'center'}>
							<Typography variant='subtitle2'>
								{get남은금액인지추가금액인지Label(totalPaid)}
							</Typography>
							<Typography variant='h2'>{`${numberWithCommasAnd원(remainingPay)}`}</Typography>
							<Typography
								variant='h2'
								mt={1}>
								결제
							</Typography>
						</Stack>
					) : (
						<Stack alignItems={'center'}>
							<Typography variant='subtitle2'>{`${get남은금액인지추가금액인지Label(
								totalPaid
							)} 없음`}</Typography>
							<Typography variant='h2'>{'퇴장 완료'}</Typography>
						</Stack>
					))}
			</CardActionArea>
		</Card>
	);
};
