import { Pageable } from '../PageType';

export const AvailableTypeArray = ['ALL', 'WEEKDAY', 'WEEKEND'] as const;
export type AvailableType = (typeof AvailableTypeArray)[number];
export const AvailableTypeLabel: Record<AvailableType, string> = {
	ALL: '주중/주말',
	WEEKDAY: '주중',
	WEEKEND: '주말',
};

export const DdiziTypeArray = ['NORMAL', 'PARENTS', 'GROUP_TICKET'] as const;
type DdiziType = (typeof DdiziTypeArray)[number];
export const DdiziTypeLabel: Record<DdiziType, string> = {
	NORMAL: '일반권',
	PARENTS: '보호자이용권',
	GROUP_TICKET: '단체 이용권',
};

export interface DdiziInfoType {
	name: string;
	ddiziType: DdiziType;
	minutes: number;
	hasNoAdditionalCharge: boolean;
	available: AvailableType;
	isNotForStatistics: boolean;
}

export type DdiziInfoListFilterType = {
	page: number; // page Number
	size?: number; // page Size
	search?: string;
	sort?: Pageable['sort'];
	available?: AvailableType;
};
