import { PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { PaymentDisplay } from '../../PaymentManagementPage/PaymentDisplay';
import { ItemSelectType, OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsPayment = ({
	selected,
	setSelected,
	orderWithDetail,
	paymentTotal,
}: OrderAndPaymentsType) => {
	const selectedPayment =
		selected.type === 'payment'
			? orderWithDetail.payments.find((p) => p.id === selected.id)
			: undefined;

	return (
		<>
			<Divider sx={{ mt: 2 }} />
			<Stack
				component={'li'}
				pl={2}
				my={0.5}>
				<Typography
					variant='h4'
					textAlign={'center'}>
					{`결제`}
				</Typography>
				<Typography
					variant={'body2'}
					color={'text.secondary'}
					textAlign={'center'}>
					{`총 ${numberWithCommasAndWon(paymentTotal)}`}
				</Typography>
			</Stack>
			<Divider />
			{orderWithDetail.payments.map((payment) => {
				return (
					<React.Fragment key={payment.id}>
						<PaymentListItem
							payment={payment}
							selectedPayment={selectedPayment}
							setSelected={setSelected}
						/>
						{/* <Divider component='li' /> */}
					</React.Fragment>
				);
			})}
		</>
	);
};

const PaymentListItem = ({
	payment,
	setSelected,
	selectedPayment,
}: {
	payment: PaymentInfoType;
	setSelected: React.Dispatch<React.SetStateAction<ItemSelectType>>;
	selectedPayment?: PaymentInfoType;
}) => (
	<SelectableListItem
		selected={payment.id === selectedPayment?.id}
		onClick={() => {
			setSelected({ type: 'payment', id: payment.id });
		}}
		sx={{ py: 1, px: 0 }}>
		<PaymentDisplay
			payment={payment}
			selected={selectedPayment?.id === payment.id}
			variant={'withoutId'}
		/>
	</SelectableListItem>
);
