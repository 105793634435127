import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	MemoStatusType,
	MemoTargetType,
	MemoType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getMemoListAsync =
	(rootUrl: string) =>
	async ({ status, targetType, storeIdList, search, from, to, pageable }: GetMemoListRequestType) =>
		(
			await posAxiosClient.get<PageType<MemoType>>(`${rootUrl}/memo/search`, {
				params: {
					...pageable.getPageableQueryParam(),
					status,
					storeIdList,
					targetType,
					search,
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
				},
			})
		).data;
export const getMemoListForMonitoringAsync =
	(rootUrl: string) =>
	async ({ date, storeIdList }: { storeIdList?: number[]; date: Dayjs }) =>
		(
			await posAxiosClient.get<MemoType[]>(`${rootUrl}/memo/search/monitoring`, {
				params: {
					storeIdList,
					date: date.format('YYYY-MM-DD'),
					// to: to.format('YYYY-MM-DD'),
				},
			})
		).data;

type GetMemoListRequestType = {
	status?: MemoStatusType;
	targetType?: MemoTargetType;
	search?: string;
	storeIdList?: number[];
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
};
