import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderType } from '@kinderlabs-pos/shared-data-type';

export const changePointUserMemberIdAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, orderId, memberUserId }: RequestType) => {
		return (
			await posAxiosClient.post<OrderType>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/order/points-member`,
				{
					memberUserId,
				},
				{
					params: {
						id: orderId,
					},
				}
			)
		).data;
	};

export type RequestType = {
	storeId: number;
	posId: number;
	orderId: string;
	memberUserId: string;
};
