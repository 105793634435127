import { CartLineExcessChargeInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { List } from '@kinderlabs-pos/ui-atoms';
import { Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export interface IJungSanDdiziResultDisplayProps {}
export const JungSanDdiziResultDisplay: React.FC<IJungSanDdiziResultDisplayProps> = ({}) => {
	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);

	return (
		<List sx={{ width: '100%' }}>
			{selectedCartLines.length > 0 && (
				<List.Item>
					<Typography variant={'subtitle1'}>{'정산 완료'}</Typography>
				</List.Item>
			)}
			{selectedCartLines.map((cartLine, idx) => (
				<DdiziInfo
					key={idx}
					cartLine={cartLine}
				/>
			))}
		</List>
	);
};

interface IDdiziInfoProps {
	cartLine: CartLineExcessChargeInfoType;
}
const DdiziInfo: React.FC<IDdiziInfoProps> = ({ cartLine }) => {
	return (
		<List.Item
			divider
			sx={{ gap: 3 }}>
			<Typography variant='subtitle1'>{cartLine.ddiziUsageId}</Typography>
			<Typography variant='subtitle1'>{cartLine.name}</Typography>
			<Typography variant='subtitle1'>{`초과금액 : ${numberWithCommasAndWon(
				cartLine.price
			)}`}</Typography>
		</List.Item>
	);
};
