import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	OrderType,
	OrderTypeWithPayments,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getListAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		from,
		to,
		pageable,
		status,
		type,
		keyword,
	}: GetOrderListRequestType) =>
		(
			await posAxiosClient.get<PageType<OrderTypeWithPayments>>(`${rootUrl}/order/search`, {
				params: {
					...pageable.getPageableQueryParam(),
					storeIdList,
					posIdList,
					from: from.format('YYYY-MM-DD HH:mm:ss'),
					to: to.format('YYYY-MM-DD HH:mm:ss'),
					status,
					type,
					search: keyword,
				},
			})
		).data;

export type GetOrderListRequestType = {
	storeIdList?: number[];
	posIdList?: number[];
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
	status?: OrderType['status'];
	type?: OrderType['type'];
	keyword?: string;
};
