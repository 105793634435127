import { PosStatusQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import {
	CircularProgress,
	Stack,
	Step,
	StepConnector,
	StepIcon,
	StepLabel,
	Stepper,
	styled,
	Typography,
} from '@mui/material';
import { atom, useAtom, useAtomValue } from 'jotai';
import React, { Suspense, useState } from 'react';
import { StoreCloseCheck } from './pages/StoreCloseCheck';
import { StoreCloseOrderCancelCheck } from './pages/StoreCloseOrderCancelCheck';
import { StoreCloseResultPage } from './pages/StoreCloseResultPage';
import { useQuery } from '@tanstack/react-query';

const steps = [
	{
		label: '주문 취소 확인',
	},
	{
		label: '시재 마감',
	},
	{
		label: '결과 확인',
	},
];

export interface IStoreClosePageProps {}
export const StoreClosePage: React.FC<IStoreClosePageProps> = ({}) => {
	const isAlreadyClosed = useIsAlreadyClosed();
	const [activeStep, setActiveStep] = useState(isAlreadyClosed ? 2 : 0);

	const handleActivateNextStep = () => setActiveStep((prev) => prev + 1);
	const handleSuccess영업마감 = () => {
		setActiveStep((prev) => prev + 1);
	};

	return (
		<Stack
			direction={'row'}
			height={'100%'}
			spacing={1}>
			<CardStack
				sx={{ px: 2 }}
				flex={1}>
				<Stepper
					activeStep={activeStep}
					connector={<StepConnector sx={{ ml: '15px' }} />}
					orientation='vertical'>
					{steps.map((step, idx) => (
						<Step key={step.label}>
							<StepLabel StepIconComponent={StyledStepIcon}>
								<Typography variant={'h4'}>{step.label}</Typography>
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</CardStack>
			<CardStack
				flex={4}
				sx={{ p: 2 }}>
				<Suspense fallback={<CircularProgress />}>
					{activeStep === 0 && (
						<StoreCloseOrderCancelCheck handleActivateNextStep={handleActivateNextStep} />
					)}
					{activeStep === 1 && <StoreCloseCheck onComplete={handleSuccess영업마감} />}
					{activeStep === 2 && <StoreCloseResultPage />}
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const StyledStepIcon = styled(StepIcon)(({ theme }) => ({
	marginRight: theme.spacing(2),
	fontSize: '3.0rem',
	'& > .MuiStepIcon-text': {
		fontSize: '1.5rem',
	},
}));

const useIsAlreadyClosed = () => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { data } = useQuery({
		...PosStatusQueryState.keys.list(storeId),
	});
	const posStatusList = data ?? [];

	return !!posStatusList?.find((posStatus) => posStatus.posId === deviceId)?.vaultCashCloseInfo;
};
