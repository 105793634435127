import { vaunceAppTheme } from '@kinderlabs-pos/ui/themes';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from '@kinderlabs-pos/ui-components';
import App from './app/app';
import { QueryClient } from '@kinderlabs-pos/state';
import {
	BrowserClient,
	Breadcrumbs,
	Dedupe,
	defaultStackParser,
	getCurrentHub,
	GlobalHandlers,
	makeFetchTransport,
	LinkedErrors,
	BrowserTracing,
} from '@sentry/browser';

import { initializeApp } from 'firebase/app';
import { CookiesProvider } from 'react-cookie';
import packageJson from 'package.json';
import {
	SENTRY_ENVIRONMENT,
	SENTRY_USE,
	VAUNCE_APP_SENTRY_DSN,
} from '@kinderlabs-pos/shared-const';

const firebaseConfig = {
	apiKey: 'AIzaSyASRxmFq7quDfjKJVhIsAsBDX07zfwMqGs',
	authDomain: 'vaunce-app.firebaseapp.com',
	projectId: 'vaunce-app',
	storageBucket: 'vaunce-app.appspot.com',
	messagingSenderId: '144057075503',
	appId: '1:144057075503:web:2d8798e2a637672b6d43da',
	measurementId: 'G-DSKRQC9ZXJ',
};

initializeApp(firebaseConfig);

const SentryClient = new BrowserClient({
	dsn: SENTRY_USE ? VAUNCE_APP_SENTRY_DSN : '',
	integrations: [
		new Breadcrumbs({ console: true }),
		new BrowserTracing(),
		new GlobalHandlers(),
		new LinkedErrors(),
		new Dedupe(),
	],
	environment: SENTRY_ENVIRONMENT,
	tracesSampleRate: 1.0,
	release: `${packageJson.version}`,
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
});

getCurrentHub().bindClient(SentryClient);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// <StrictMode>
	<BrowserRouter>
		<CookiesProvider>
			<QueryClient.Provider>
				<ThemeProvider theme={vaunceAppTheme}>
					<ConfirmProvider>
						<Suspense>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CssBaseline />
								<App />
							</LocalizationProvider>
						</Suspense>
					</ConfirmProvider>
				</ThemeProvider>
			</QueryClient.Provider>
		</CookiesProvider>
	</BrowserRouter>
	// </StrictMode>
);
