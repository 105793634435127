import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import { VanCompanyType } from '@kinderlabs-pos/shared-data-type';

type HandlePaymentByPM = { amount: number; tax: number } & (
	| { method: 'CASH_IC' }
	| ({ method: 'CARD' | 'CARD_MS' } & { installment: number })
	| ({
			method: 'CASH_현금영수증_발급' | 'TRANSFER_현금영수증_발급' | 'GIFTCARD_현금영수증_발급';
	  } & {
			customerId: string;
			isEnterprise: boolean;
			isSignPadUsed: boolean;
	  })
	| ({ method: 'ZEROPAY' | 'SEOULPAY' } & { customerId: string })
	| ({ method: 'KAKAOPAY' | 'SSGPAY' | 'APPCARD' } & { customerId: string; installment: number })
);

export const useHandle결제모듈 = (vanCompany: VanCompanyType) => {
	return async (payload: HandlePaymentByPM) => {
		switch (payload.method) {
			case 'CARD':
				return await ReactPMIPC.invoke카드IC승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CARD_MS':
				return await ReactPMIPC.invoke카드MS승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CASH_IC':
				return await ReactPMIPC.invoke현금IC승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CASH_현금영수증_발급':
			case 'GIFTCARD_현금영수증_발급':
			case 'TRANSFER_현금영수증_발급':
				// 현금 영수증 발급하며 결제
				return await ReactPMIPC.invoke현금영수증승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'KAKAOPAY':
			case 'SSGPAY':
			case 'APPCARD':
				return await ReactPMIPC.invoke간편결제승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'ZEROPAY':
			case 'SEOULPAY':
				return await ReactPMIPC.invoke간편결제승인({
					...payload,
					installment: 0,
					vanCompany: vanCompany,
				});
			default:
				throw Error('[TypeError] (PM) 결제 모듈 타입이 없습니다.');
		}
	};
};
