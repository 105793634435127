import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminWarehouseInfoListFilterType,
	PageType,
	Pageable,
	WarehouseInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const getWarehouseInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: AdminWarehouseInfoListFilterType) => {
		return (
			await posAxiosClient.get<PageType<WarehouseInfoType>>(`${rootUrl}/warehouse/search`, {
				params: {
					...options,
					...Pageable.getPageable({
						page,
						size,
						sort,
					}).getPageableQueryParam(),
				},
			})
		).data;
	};
