import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	PageType,
	Pageable,
	PosCouponInfoListFilterType,
	PosCouponInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../const';

const getPosCouponInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: PosCouponInfoListFilterType) =>
		(
			await posAxiosClient.get<PageType<PosCouponInfoType & BaseInfoType>>(
				`${rootUrl}/coupon-info/search`,
				{
					params: {
						...options,
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;

const getPosCouponInfoAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<PosCouponInfoType & BaseInfoType>(`${rootUrl}/coupon-info`, {
			params: {
				id: id,
			},
		})
	).data;

const getPosCouponInfoAllListAsync = (rootUrl: string) => async () =>
	(await posAxiosClient.get<(PosCouponInfoType & BaseInfoType)[]>(`${rootUrl}/coupon-info/all/`))
		.data;

export const createCouponInfoAsync =
	(rootUrl: string) => async (createCouponInfoRequest: CreateCouponInfoRequestType) =>
		(
			await posAxiosClient.post<PosCouponInfoType & BaseInfoType>(`${rootUrl}/coupon-info`, {
				...createCouponInfoRequest,
			})
		).data;

export type CreateCouponInfoRequestType = Omit<PosCouponInfoType, 'id' | 'couponStatusStatistics'>;

const updatePosCouponInfoAsync =
	(rootUrl: string) => async (updateRequestedCouponInfo: UpdatePosCouponInfoRequestType) =>
		(
			await posAxiosClient.put<PosCouponInfoType & BaseInfoType>(
				`${rootUrl}/coupon-info`,
				{
					...updateRequestedCouponInfo,
				},
				{
					params: {
						id: updateRequestedCouponInfo.id,
					},
				}
			)
		).data;

export type UpdatePosCouponInfoRequestType = PosCouponInfoType;

const deletePosCouponInfoListAsync =
	(rootUrl: string) =>
	async ({ idList }: DeletePosCouponInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/coupon-info`, {
				params: {
					idList,
				},
			})
		).data;

export type DeletePosCouponInfoListRequestType = { idList: number[] };

export const PosCouponInfoApis = {
	getPage: getPosCouponInfoListAsync(rootUrl),
	getOne: getPosCouponInfoAsync(rootUrl),
	getAllList: getPosCouponInfoAllListAsync(rootUrl),
	createInfo: createCouponInfoAsync(rootUrl),
	updateInfo: updatePosCouponInfoAsync(rootUrl),
	deleteInfoList: deletePosCouponInfoListAsync(rootUrl),
};
