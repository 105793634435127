import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import {
	DeviceType,
	ExchangeCouponStatusTypeLabel,
	ExchangeCouponType,
} from '@kinderlabs-pos/shared-data-type';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '../StoreInfoState';
import { KioskElectronStoreState, PosElectronStoreState } from '../ElectronStoreState';

export const useExchangePrinter = ({ deviceType }: { deviceType: DeviceType }) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	return {
		print쿠폰Async: async ({ coupon }: { coupon: ExchangeCouponType }) => {
			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
			);
			for (const printerInfo of CandidatePrinterInfos) {
				await ReactRPIPC.invoke교환권출력({
					hardwareInfo: {
						deviceType: deviceType,
						printerInfo: printerInfo,
						printMode,
					},
					couponId: coupon.id,
					couponName: coupon.name,
					couponState: ExchangeCouponStatusTypeLabel[coupon.status],
					orderId: `${coupon.publishInfo.orderId}`,
					publishedDate: coupon.publishInfo.published,
					usedDate: coupon.used,
					others: {
						greetings: coupon.publishInfo.description,
					},
					barcodeType: localSettings ? localSettings.usingBarcodeModel : 'QRCODE',
				});
			}
		},
	};
};

// JM : BXLN 레거시 코드 백업
// if (RPList.length === 0) {
//   await ReactPosIPC.hardware.invoke교환권출력({
//     deviceType,
//     deviceNum: 'ReceiptPrinter1',
//     printMode: 'BIXOLONSDK',
//     couponId: coupon.id,
//     couponName: coupon.name,
//     couponState: ExchangeCouponStatusTypeLabel[coupon.status],
//     orderId: `${coupon.publishInfo.orderId}`,
//     publishedDate: coupon.publishInfo.published,
//     usedDate: coupon.used,
//     others: {
//       greetings: coupon.publishInfo.description,
//     },
//     deviceInfo: undefined,
//   });
// }
