import { IconButton, IconButtonProps, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface IIconTextButtonProps extends IconButtonProps {
  variant?: 'white' | 'default';
  iconSize?: 'large' | 'default';
  label: ReactNode;
  icon: ReactNode;
}

export const IconTextButton: React.FC<IIconTextButtonProps> = ({
  variant = 'default',
  iconSize = 'default',
  label,
  icon,
  disabled,
  onClick,
}) => {
  return (
    <StyledIconButton
      disabled={disabled}
      style={
        iconSize === 'large'
          ? {
              transform: 'scale(1.2)',
            }
          : {}
      }
      variant={variant}
      onClick={onClick}
    >
      {icon}
      {typeof label === 'string' ? (
        <Typography variant={'caption'}>{label}</Typography>
      ) : (
        label
      )}
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)<{ variant: 'default' | 'white' }>(
  ({ variant, theme }) => ({
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fill: variant === 'white' && 'white',
      height: 30,
    },
    '& > .MuiTypography-root': {
      fontSize: theme.typography.caption.fontSize,
      color: variant === 'white' && 'white',
    },
    '&.Mui-disabled > .MuiSvgIcon-root': {
      fill: 'lightgray',
    },
    '&.Mui-disabled > .MuiTypography-root': {
      color: 'lightgray',
    },
  })
);
