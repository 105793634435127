import { BellOutlined } from '@ant-design/icons';
import { GuestCallType, GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { getRelativeTimeFormat } from '@kinderlabs-pos/shared-util';
import {
	GuestVisitState,
	OrderState,
	PosElectronStoreState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { Button, Stack, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { getUsersByGVInfo } from '../../../GuestVisitActionButtons';
import { WaitingIndicator } from '../../WaitingIndicator';

export const GuestVisitCallDisplay = ({
	now,
	guestVisit,
	guestVisitCall,
	closePannel,
}: {
	now: Dayjs;
	guestVisit: GuestVisitType;
	guestVisitCall?: GuestCallType;
	closePannel?: () => void;
}) => {
	const { posInfoList } = useAtomValue(StoreInfoState.curStoreInfo);
	const curPosId = useAtomValue(StoreInfoState.curStoreAndDevice).deviceId;
	const callWith응대 = PosElectronStoreState.usePosMainPageLocalSettings().data?.callWith응대;
	const { guestMemberInfo } = useAtomValue(OrderState.value);

	const callMutation = GuestVisitState.actions.useCallMutation({
		onError: (e) => {
			//@ts-ignore
			customAlert(e?.message ?? '호출실패');
		},
	});
	const rerunMutation = GuestVisitState.actions.useRerunMutation();
	const dispatchOrder = useSetAtom(OrderState.value);
	const setCalled지류티켓 = useSetAtom(WaitingIndicator.called지류티켓Atom);
	const handleClickCall = async () => {
		const result = await callMutation.mutateAsync({ guestVisitId: guestVisit.id });

		if (result && callWith응대 && !guestMemberInfo) {
			await rerunMutation.mutateAsync({ guestVisitId: result.id });

			if (guestVisit.memberInfo) {
				dispatchOrder({
					type: 'SET_GUEST_USERS_INFO',
					guestMemberInfo: {
						...guestVisit.memberInfo,
						sunbun: guestVisit.sunbun,
						users: getUsersByGVInfo({ users: guestVisit.users, visitCount: guestVisit.visitCount }),
						guestVisitId: guestVisit.id,
						appUserId: undefined,
					},
				});
				setCalled지류티켓(undefined);
			} else {
				setCalled지류티켓(guestVisit);
			}

			closePannel && closePannel();
		}
	};

	const customAlert = useAlert();

	const isCalledbyThisPos = curPosId === guestVisitCall?.posId;
	const thisPos = useMemo(
		() => posInfoList.find((pos) => pos.id === guestVisitCall?.posId),
		[posInfoList, guestVisitCall]
	);

	const 최근호출이후180초가안지났는지 = now.diff(guestVisitCall?.calledDateTime, 'seconds') < 180;

	return guestVisitCall ? (
		<Stack
			direction={'row'}
			spacing={1}>
			<Button
				disabled={!isCalledbyThisPos}
				color={isCalledbyThisPos ? 'success' : 'error'}
				variant='outlined'
				sx={{ px: 0.5, width: 120, pointerEvents: 'none' }}>
				<Stack>
					<Typography variant={'body2'}>{`POS ${thisPos?.name}`}</Typography>
					<Typography variant={'body1'}>
						{`${getRelativeTimeFormat(now, guestVisitCall.calledDateTime)} 호출`}
					</Typography>
				</Stack>
			</Button>
			<Button
				variant='outlined'
				color={'error'}
				disabled={(!isCalledbyThisPos && 최근호출이후180초가안지났는지) || callMutation.isLoading}
				onClick={handleClickCall}
				startIcon={<BellOutlined />}
				sx={{ width: 96 }}>
				<Typography variant={'subtitle2'}>재호출</Typography>
			</Button>
		</Stack>
	) : (
		<Button
			onClick={handleClickCall}
			variant='outlined'
			disabled={callMutation.isLoading}
			sx={{ width: 96 }}
			startIcon={<BellOutlined />}>
			<Typography variant={'subtitle1'}>{'호출'}</Typography>
		</Button>
	);
};
