import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductDiscountInfoListFilterType,
	BaseInfoType,
	Pageable,
	PageType,
	ProductDiscountMgInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const getProductDiscountInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: AdminProductDiscountInfoListFilterType) =>
		(
			await posAxiosClient.get<PageType<ProductDiscountMgInfoType & BaseInfoType>>(
				`${rootUrl}/product-discount/search`,
				{
					params: {
						...options,
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;
