import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PaymentInfoType, ReceiptInfoType } from '@kinderlabs-pos/shared-data-type';

export const publishCashReceiptAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, paymentId, receipt }: PublishReceiptRequestType) =>
		(
			await posAxiosClient.post<PaymentInfoType>(
				`${rootUrl}/payment/store/${storeId}/pos/${posId}/payment/receipt/publish`,
				receipt,
				{
					params: {
						paymentId,
					},
				}
			)
		).data;

export type PublishReceiptRequestType = {
	storeId: number;
	posId: number;
	paymentId: string;
	receipt: ReceiptInfoType;
};
