import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, styled, Typography } from '@mui/material';
import warningImage from 'assets/vaunce/image/pinkwarning.png';
import wishmapImage from 'assets/vaunce/image/wishmap.png';
import wishphonImage from 'assets/vaunce/image/wishphone.png';

import warningImage2x from 'assets/vaunce/image/pinkwarning@2x.png';
import wishmapImage2x from 'assets/vaunce/image/wishmap@2x.png';
import wishphonImage2x from 'assets/vaunce/image/wishphone@2x.png';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';

export interface IWishListPageProps {}
const WishListPage: React.FC<IWishListPageProps> = ({}) => {
	const navigate = useNavigate();
	const { navigateCenterDescription, navigateCenterDetail } = useVaunceAppRouter();

	const favoritCenterList = VaunceQueryState.useWishListQuery();
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='찜 리스트' />
			{favoritCenterList.length === 0 ? (
				<>
					<Stack
						flex={1}
						textAlign={'center'}
						justifyContent={'center'}
						spacing={3}>
						<Box>
							<img
								src={warningImage}
								srcSet={warningImage2x}
								height={74}
							/>
						</Box>
						<Typography variant='h5'>찜 한 센터 정보가 없습니다.</Typography>
					</Stack>
					<RoundedButton
						sx={{ height: 58 }}
						onClick={navigateCenterDescription}>
						센터 찜하러 가기
					</RoundedButton>
				</>
			) : (
				favoritCenterList.map((center) => (
					<WishBox
						key={center.centerId}
						onClick={() => {
							navigate(`/centerDetail/${center.centerId}`);
						}}>
						<WishImageBox>
							<img
								src={center.centerImageUrl ?? ''}
								width={'102'}
								height={'104'}
							/>
						</WishImageBox>
						<Stack spacing={2}>
							<Typography variant='h4'>{center.centerDisplay}</Typography>

							<Stack spacing={1}>
								<Stack
									direction={'row'}
									alignItems={'center'}
									spacing={1}>
									<img
										src={wishmapImage}
										srcSet={wishmapImage2x}
										width={24}
										height={24}
									/>
									<Typography
										variant='h6'
										fontWeight={400}>
										{center.address}
									</Typography>
								</Stack>

								<Stack
									direction={'row'}
									alignItems={'center'}
									spacing={1}>
									<img
										src={wishphonImage}
										srcSet={wishphonImage2x}
										width={24}
										height={24}
									/>
									<Typography
										variant='h6'
										fontWeight={400}
										textAlign={'center'}>
										{center.centerTel}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</WishBox>
				))
			)}
		</Stack>
	);
};

export default WishListPage;

const WishBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderBottom: '1px solid rgba(0, 0, 0, 0.17)',
	padding: '16px',
	width: '100%',
	cursor: 'pointer',
}));

const WishImageBox = styled(Box)(({ theme }) => ({
	marginRight: '10px',
	width: '33.3%',
}));

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'column',
}));
