import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CommutationTicketType } from '@kinderlabs-pos/shared-data-type';

export const searchCmtListAsync =
	(rootUrl: string) =>
	async ({ search }: SearchCmtListAsyncRequestType) =>
		(
			await posAxiosClient.get<CommutationTicketType[]>(`${rootUrl}/ticket/cmt/search`, {
				params: {
					keyword: search,
				},
			})
		).data;

export type SearchCmtListAsyncRequestType = {
	search: string;
};
