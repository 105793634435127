import { DayCalendarFilterOptionType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';
import { getToday, numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { OrderQueryState, PaymentQueryState, PaymentState } from '@kinderlabs-pos/state';
import { CardStack, IconSquareButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	CalendarSelect,
	RotatingRefreshButton,
	SearchBox,
	useConfirm,
} from '@kinderlabs-pos/ui-components';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { Suspense, useMemo, useState } from 'react';
import { PaymentDetailContentsWrapper } from '../../dialogs/PaymentDetailDialog/PaymentDetailContentsWrapper';
import { OrderDetailContentsWrapper } from '../OrderPage/SelectedDetailContents/OrderDetailContentsWrapper';
import { usePosRouter } from '../routes';
import { PaymentListContent } from './PaymentListConents';
import { PaymentsFilterDialog, PaymentsFilterType } from './PaymentsFilterDialog';

const today = getToday();
export const PaymentManagementPage = ({}) => {
	const { searchParams, changeSearchParams } = useSearchParamsWrapper<{
		keyword?: string;
	}>();

	const keyword = searchParams.get('keyword');

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.hour(0).minute(0),
		to: today.endOf('day'),
	});
	const [filter, setFilter] = useState<PaymentsFilterType>({ method: undefined });
	const selectedPayment = useAtomValue(PaymentManagementPage.selectedPayment);

	const handleClickReload = () => PaymentQueryState.invalidateQueries();

	const hasFilter = !!filter.method || !!filter.targetPosId;
	const theme = useTheme();

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				flex={1}
				spacing={1}
				p={1}>
				<Stack
					height={43}
					direction={'row'}
					spacing={1}
					color={grey[400]}
					sx={{
						overflowY: 'auto',
					}}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<PaymentsFilterDialog
								open={open}
								closeDialog={closeDialog}
								filter={filter}
								setFilter={setFilter}
							/>
						)}>
						{(openDialog) => (
							<IconSquareButton
								color={!!hasFilter ? theme.palette.primary.main : undefined}
								onClick={openDialog}>
								<TuneIcon />
							</IconSquareButton>
						)}
					</WithDialog>
					<RotatingRefreshButton handleClick={handleClickReload} />
				</Stack>
				<Stack>
					<PaymentSearchBox
						keyword={keyword}
						setSearch={(val) => {
							changeSearchParams({ keyword: val });
						}}
					/>
				</Stack>
				<Stack>
					<Typography
						variant={'body2'}
						sx={{ textAlign: 'center' }}>
						{'결제번호, 결제일련번호, 금액'}
						<br />
						{'카드 및 현금영수증 전화번호 뒷 4자리로 검색됩니다.'}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 1, border: `1px solid ${grey[400]}`, pb: 1, overflowY: 'auto' }}>
					<Suspense>
						<PaymentListContent
							keyword={keyword || undefined}
							option={option}
							filter={filter}
						/>
					</Suspense>
				</Stack>
			</CardStack>
			<Stack
				direction={'row'}
				flex={2}
				spacing={1}
				height={'100%'}
				sx={{ overflowY: 'auto' }}>
				{selectedPayment && (
					<Suspense>
						<CardStack
							spacing={1}
							flex={1}
							sx={{ overflowY: 'auto' }}>
							<PaymentDetailContentsWithOrder payment={selectedPayment} />
						</CardStack>
						<Stack
							flex={1}
							spacing={1}>
							<CardStack
								flex={1}
								sx={{ overflowY: 'auto' }}>
								<OrderDetailContentsFromPayment payment={selectedPayment} />
							</CardStack>
							<결제마저하기버튼Stack payment={selectedPayment} />
						</Stack>
					</Suspense>
				)}
				{!selectedPayment && (
					<CardStack
						width={'100%'}
						alignItems={'center'}
						justifyContent={'center'}
						sx={{ bgcolor: 'white', overflowY: 'auto' }}>
						<Typography
							sx={{ textAlign: 'center' }}
							variant={'subtitle1'}
							color={'text.secondary'}>
							결제를 선택하고
							<br />
							상세 정보를 확인하세요.
						</Typography>
					</CardStack>
				)}
			</Stack>
		</Stack>
	);
};
PaymentManagementPage.selectedPayment = atom<PaymentInfoType | undefined>(undefined);

const PaymentSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};

const OrderDetailContentsFromPayment = ({ payment }: { payment: PaymentInfoType }) => {
	const { data: order } = useQuery({
		...OrderQueryState.keys.detailByOrderId(payment.orderId),
		refetchOnWindowFocus: true,
	});

	if (!order) return null;

	return (
		<OrderDetailContentsWrapper
			order={order}
			hasNavigate={true}
		/>
	);
};

const PaymentDetailContentsWithOrder = ({ payment }: { payment: PaymentInfoType }) => {
	const { data: order } = useQuery({
		...OrderQueryState.keys.detailByOrderId(payment.orderId),
		refetchOnWindowFocus: true,
	});

	if (!order) return null;

	return (
		<PaymentDetailContentsWrapper
			order={order}
			payment={payment}
		/>
	);
};

const 결제마저하기버튼Stack = ({ payment }: { payment: PaymentInfoType }) => {
	const { navigate결제 } = usePosRouter();
	const confirm = useConfirm();

	const { data: order } = useQuery({
		...OrderQueryState.keys.detailByOrderId(payment.orderId),
		refetchOnWindowFocus: true,
	});
	if (!order) return null;

	const paymentTotal = useMemo(() => PaymentState.utils.getTotal(order.payments), [order]);
	const remaining = order.aggregate.totalToPay - paymentTotal;

	const handleClickRemainingPay = () =>
		confirm(`${numberWithCommasAndWon(remaining)}을 이어서 결제하시겠습니까?`, () => {
			navigate결제({
				mode: 'order_continue',
				orderId: order.id,
				status: order.status,
			});
		});

	if (!['NOT_COMPLETED', 'PARTIALLY_CANCELED'].includes(order.status)) return null;

	return (
		<Stack width={'100%'}>
			<Button
				fullWidth
				color={'error'}
				variant='outlined'
				size={'small'}
				onClick={handleClickRemainingPay}
				sx={{ bgcolor: 'white' }}>
				<Stack>
					<Typography variant='subtitle1'>
						{order.status !== 'COMPLETED'
							? `남은금액 : ${numberWithCommasAndWon(remaining)}`
							: `취소된금액 : ${numberWithCommasAndWon(remaining)}`}
					</Typography>
					<Typography variant='body2'>{`결제 이어하기`}</Typography>
				</Stack>
			</Button>
		</Stack>
	);
};
