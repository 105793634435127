import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getGroupBy } from '@kinderlabs-pos/shared-util';
import { KitchenOrderQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	CircularProgress,
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { KitchenOrderPage } from '.';
import { KitchenOrderDisplay } from './KitchenOrderDisplay';
import { KitchenOrderFilterType } from './KitchenOrderFilterDialog';

export const KitchenOrderListContent = ({
	keyword,
	filter,
	option,
}: {
	keyword: string | null;
	filter: KitchenOrderFilterType;
	option: DayCalendarFilterOptionType;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedKitchenOrder, selectKitchenOrder] = useAtom(KitchenOrderPage.selectedKitchenOrder);
	const {
		data: kitchenOrderPage,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...KitchenOrderQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				search: keyword ? keyword : undefined,
				statusList: filter.status ? [filter.status[0]] : [],
				deviceIdList: filter.deviceIdList,
				deviceTypeList: filter.deviceTypeList,
				kitchenSystemId: filter.kitchenSystemId,
				...option,
			},
		}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const kitchenOrderData = useMemo(() => {
		if (!kitchenOrderPage) return [];
		else return kitchenOrderPage.pages.flatMap((data) => data.content);
	}, [kitchenOrderPage]);

	const kitchenOrderGroupBy = getGroupBy(kitchenOrderData, (item) =>
		getDateFormat(item.createdDate)
	);

	useEffect(() => {
		if (selectedKitchenOrder) {
			selectKitchenOrder(
				kitchenOrderData.find((kitchenOrder) => kitchenOrder.id === selectedKitchenOrder.id)
			);
		}
	}, [selectedKitchenOrder, kitchenOrderData]);

	return (
		<List sx={{ p: 0 }}>
			{[...Object.keys(kitchenOrderGroupBy)].map((key, idx) => (
				<Fragment key={idx}>
					<ListSubheader
						sx={(theme) => ({
							py: 1,
							borderBottom: `1px solid ${theme.palette.divider}`,
						})}>
						<Typography variant={'body1'}>{key}</Typography>
					</ListSubheader>
					{kitchenOrderGroupBy[key].map((kitchenOrder, kitchenOrderIdx) => {
						return (
							<SelectableListItem
								key={kitchenOrderIdx}
								selected={kitchenOrder.id === selectedKitchenOrder?.id}
								onClick={() => selectKitchenOrder(kitchenOrder)}
								sx={(theme) => ({
									py: 1,
									borderBottom: `1px solid ${theme.palette.divider}`,
								})}>
								<KitchenOrderDisplay kitchenOrder={kitchenOrder} />
							</SelectableListItem>
						);
					})}
				</Fragment>
			))}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
