import { DdiziApi } from '@kinderlabs-pos/apis/pos';
import { DdiziType } from '@kinderlabs-pos/shared-data-type';
import {
	DdiziQueryState,
	ExitQueryState,
	OrderQueryState,
	OrderState,
	QueryClient,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';

const _changeItem = () => {
	ExitQueryState.invalidateDdiziQueries();
	DdiziQueryState.invalidateQueries();
	OrderQueryState.invalidateQueries();
};

const useAdjustTime = () => {
	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	return QueryClient.useMutation(
		async (input: { ddiziId: DdiziType['id']; time: number }[]) => {
			return await DdiziApi.adjustTime(
				{
					storeId,
					posId,
				},
				input
			);
		},
		{
			onSuccess: (data) => {
				_changeItem();
			},
		}
	);
};

const useEnterExit = () => {
	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const cartDispatcher = OrderState.cart.useDispatcher();

	return QueryClient.useMutation(
		async ({
			ddiziId,
			type,
		}: {
			ddiziId: DdiziType['id'];
			type: 'EXIT' | 'ENTER' | 'CANCEL_EXIT' | 'CANCEL_ENTER';
		}) => {
			switch (type) {
				case 'ENTER':
					return await DdiziApi.enter({
						storeId,
						posId,
						ddiziId,
					});
				case 'EXIT':
					return await DdiziApi.exit({
						storeId,
						posId,
						ddiziId,
					});
				case 'CANCEL_ENTER':
					return await DdiziApi.cancelEnter({
						storeId,
						posId,
						ddiziId,
					});
				case 'CANCEL_EXIT':
					return await DdiziApi.cancelExit({
						storeId,
						posId,
						ddiziId,
					});
			}
		},
		{
			onSuccess: (data) => {
				cartDispatcher({
					type: 'EXCESS_CHARGE',
					subAction: {
						type: 'UNSELECT',
						ddiziId: data.id,
					},
				});
				_changeItem();
			},
		}
	);
};

export const DdiziActions = {
	useAdjustTime: useAdjustTime,
	useEnterExit: useEnterExit,
};
