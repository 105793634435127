import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { MemoType } from '@kinderlabs-pos/shared-data-type';

export const getMemoDetailAsync =
	(rootUrl: string) =>
	async ({ memoId }: { memoId: number }) =>
		(
			await posAxiosClient.get<MemoType>(`${rootUrl}/memo/detail`, {
				params: {
					memoId,
				},
			})
		).data;
