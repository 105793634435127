import { SNSProviderType, SNSRedirectType } from '@kinderlabs-pos/shared-data-type';
import { createSearchParams, RouteObject, useNavigate, useParams } from 'react-router-dom';
import { MainContent } from '../components/MainContent';
import { SignUpContent } from '../components/SignUpContent';
import { AppGuidePage } from './AppGuidePage';
import { CenterGuidePage } from './CenterGuidePage';
import { CenterPage } from './CenterPage';
import { CenterDetailPage } from './CenterPage/CenterDetailPage';
import CenterLocalizationPage from './CenterPage/CenterLocalizationPage';
import { CmtTicketPage } from './CmtTicketPage';
import { CouponPage } from './CouponPage';
import { RegisterCoupon } from './CouponPage/RegisterCoupon';
import { EventPage } from './EventPage';
import { EventDetailPage } from './EventPage/EventDetail';
import FacilityRegisterPage from './FacilitysubmitPage';
import FacilityRegisterHistoryPage from './FacilitysubmitPage/FacilityRegisterHistoryPage';
import NewRegisterStepCompletePage from './FacilitysubmitPage/NewRegisterStepCompletePage';
import NewRegisterStepOnePage from './FacilitysubmitPage/NewRegisterStepOne';

import { NewRegisterStepThreePage } from './FacilitysubmitPage/NewRegisterStepThreePage';
import NewRegisterStepTwoPage from './FacilitysubmitPage/NewRegisterStepTwoPage';
import { ShowRegisteredQR } from './FacilitysubmitPage/ShowRegisteredQR';
import { FAQPage } from './FAQPage';
import HistoryPage from './HistoryPage';
import { HomePage } from './HomePage';
import InfoPage from './InfoPage';
import { LeavePage } from './LeavePage';
import LoginPage from './Login';
import { FindIdPage } from './Login/FindIdPage';
import { FindPwPage } from './Login/FindPwPage';
import { FounddIdPage } from './Login/FoundIdPage';
import { FoundPwPage } from './Login/FoundPwPage';
import { NotFoundIdPage } from './Login/NotFoundIdPage';
import { SignUpFirstPage } from './Login/SignUpFirstPage';
import { SnsRedirectPage } from './Login/SNSLoginRedirectPage';
import { MemberShipPage } from './MemberShipPage';
import { MultiTicketPage } from './MultiTicketPage';
import { MyPagePage } from './MyPagePage';
import { NicePayResultPage } from './NicePayPage/NicePayResultPage';
import { NicePaySuccessPage } from './NicePayPage/NicePaySuccessPage';
import NoticePage from './noticePage';
import { OneStopPage } from './OneStopPage';
import { OneTicketPage } from './OneTicketPage';
import { NicePayOneSuccessPage } from './OneTicketPage/NicePayOneSuccessPage';
import { TicketsalePage } from './OneTicketPage/TicketsalePage';
import { OneToOnePage } from './OnetoOnePage';
import { NormalQuestionToWrite } from './OnetoOnePage/NormalQuestionWrite';
import {
	PartyQeustionStepOnePage,
	PartyQeustionStepTwoPage,
	PartyQuestionStepThreePage,
} from './OnetoOnePage/PartyQuestionSteps';
import { WriteDonePage } from './OnetoOnePage/WriteDone';
import PayHistoryPage from './PayHistoryPage';
import { PrivacyTermPage } from './PrivacyTermPage';
import { ReservationPage } from './ReservationPage';
import { SignUpFifthStep } from './SignPage/SignUpFifthStep';
import { SignUpForthStep } from './SignPage/SignUpForthStep';
import { SignUpSecondStep } from './SignPage/SignUpSecondStep';
import { SignUpSixthStep } from './SignPage/SignUpSixthStep';
import { SignUpThirdStep } from './SignPage/SignUpThirdStep';
import { TermsOfUsePage } from './TermsOfUsePage';
import { VclubPage } from './VclubPage';
import { VipclubPage } from './VipclubPage';
import VipClubRegisterPage from './VipclubPage/VipClubRegisterPage';
import WishListPage from './WishListPage';
import { SettingPage } from './SettingPage';

type CustomRouteObject = Omit<RouteObject, 'children' | 'path'> & {
	title?: string;
	children?: CustomRouteObject[];
	path: string;
	prevPath?: string;
};

export const homepageRouteObject: CustomRouteObject = {
	path: '/',
	element: <HomePage />,
};

export const myPageRouteObject: CustomRouteObject = {
	path: 'my',
	element: <MyPagePage />,
};

// 내 정보관리
export const infoPageRouteObject: CustomRouteObject = {
	path: 'info',
	element: <InfoPage />,
};

export const myMembershipPageRouteObject: CustomRouteObject = {
	path: 'vclub',
	element: <VclubPage />,
};

export const nicePaySuccessRouteObject: CustomRouteObject = {
	path: 'nicepaySuccess',
	element: <NicePaySuccessPage />,
};

export const nicePayOneSuccessRouteObject: CustomRouteObject = {
	path: 'nicepayOneSuccess',
	element: <NicePayOneSuccessPage />,
};

export const nicePayResultRouteObject: CustomRouteObject = {
	path: 'nicepayresult',
	element: <NicePayResultPage />,
};

export const vauncePremiumRouteObject: CustomRouteObject = {
	path: 'vipclub',
	element: <VipclubPage />,
};

export const vauncePremiumVipClubRegisterRouteObject: CustomRouteObject = {
	path: 'vipclubregister',
	element: <VipClubRegisterPage />,
};

export const oneStopPageRouteObject: CustomRouteObject = {
	path: 'oneStop',
	element: <OneStopPage />,
};

export const multiTicketRouteObject: CustomRouteObject = {
	path: 'multiTicket',
	element: <MultiTicketPage />,
};

export const cmtTicketRouteObject: CustomRouteObject = {
	path: 'cmtTicket',
	element: <CmtTicketPage />,
};

export const oneTicketRouteObject: CustomRouteObject = {
	path: 'oneTicket',
	element: <OneTicketPage />,
};

export const ticketSaleRouteObject: CustomRouteObject = {
	path: 'ticketsale',
	element: <TicketsalePage />,
};

export const couponRouteObject: CustomRouteObject = {
	path: 'couponList',
	element: <CouponPage />,
};

export const registerCouponRouteObject: CustomRouteObject = {
	path: 'registerCoupon',
	element: <RegisterCoupon />,
};

export const wishListRouteObject: CustomRouteObject = {
	path: 'wishlist',
	element: <WishListPage />,
};

export const centerHistoryRouteObject: CustomRouteObject = {
	path: 'centerHistory',
	element: <HistoryPage />,
};

export const payhistoryRouteObject: CustomRouteObject = {
	path: 'payhistory',
	element: <PayHistoryPage />,
};

export const snsRedirectRouteObject: CustomRouteObject = {
	// type 은 login 이냐? signup 이냐? 를 결정한다.
	path: 'sns/redirect/:provider/type/:type',
	element: <SnsRedirectPage />,
};

export const useSnsRedirectRouteParams = () =>
	useParams<{ provider: SNSProviderType; type: SNSRedirectType }>();

// 바운스 이용하기
export const centerDescriptionRouteObject: CustomRouteObject = {
	path: 'centerDescription',
	element: <CenterPage />,
};

export const centerDetailRouteObject: CustomRouteObject = {
	path: 'centerDetail/:id',
	element: <CenterDetailPage />,
};

export const centerLocationRouteObject: CustomRouteObject = {
	path: 'centerLocation/:id',
	element: <CenterLocalizationPage />,
};

export const membershipRouteObject: CustomRouteObject = {
	path: 'membershipinfo',
	element: <MemberShipPage />,
};

export const facilityRegisterRouteObject: CustomRouteObject = {
	path: 'facilityRegister',
	element: <FacilityRegisterPage />,
};

export const facilityNewRegisterStepOne: CustomRouteObject = {
	path: 'newRegister_stepOne',
	element: <NewRegisterStepOnePage />,
};

export const facilityNewRegisterStepTwo: CustomRouteObject = {
	path: 'newRegister_stepTwo',
	element: <NewRegisterStepTwoPage />,
};

export const facilityNewRegisterStepThree: CustomRouteObject = {
	path: 'newRegister_stepThree',
	element: <NewRegisterStepThreePage />,
};

export const facilityNewRegisterComplete: CustomRouteObject = {
	path: 'newRegister_complete',
	element: <NewRegisterStepCompletePage />,
};

export const facilityRegisterHistory: CustomRouteObject = {
	path: 'registerHistory',
	element: <FacilityRegisterHistoryPage />,
};

export const facilityRegisteredQR: CustomRouteObject = {
	path: 'registeredQR',
	element: <ShowRegisteredQR />,
};

export const reservationRouteObject: CustomRouteObject = {
	path: 'reservation',
	element: <ReservationPage />,
};

export const eventRouteObject: CustomRouteObject = {
	path: 'event',
	element: <EventPage />,
};

export const eventDetailRouteObject: CustomRouteObject = {
	path: 'eventDetail/:id',
	element: <EventDetailPage />,
};

export const noticeRouteObject: CustomRouteObject = {
	path: 'notice',
	element: <NoticePage />,
};

// 고객센터
export const centerGuideRouteObject: CustomRouteObject = {
	path: 'centerguide',
	element: <CenterGuidePage />,
};

export const oneTicketWithRefundObject: CustomRouteObject = {
	path: 'oneTicketWithRefund',
	element: <OneTicketPage />,
};

export const faqRouteObject: CustomRouteObject = {
	path: 'faq',
	element: <FAQPage />,
};

export const oneToOneRouteObject: CustomRouteObject = {
	path: 'questions',
	element: <OneToOnePage />,
};

export const normalQeustionRouteObject: CustomRouteObject = {
	path: 'normalQuestion',
	element: <NormalQuestionToWrite />,
};

export const partyQeustionStepOne: CustomRouteObject = {
	path: 'PartyQuestion/stepOne',
	element: <PartyQeustionStepOnePage />,
};

export const partyQuestionStepTwo: CustomRouteObject = {
	path: 'PartyQeustion/stepTwo',
	element: <PartyQeustionStepTwoPage />,
};

export const partyQuestionStepThree: CustomRouteObject = {
	path: 'PartyQuestion/stepThree',
	element: <PartyQuestionStepThreePage />,
};

export const questionDoneRouteObject: CustomRouteObject = {
	path: 'question/complete/:prev',
	element: <WriteDonePage />,
};

export const appguideRouteObject: CustomRouteObject = {
	path: 'appguide',
	element: <AppGuidePage />,
};

export const termsofuseRouteObject: CustomRouteObject = {
	path: 'termsofuse',
	element: <TermsOfUsePage />,
};

export const privacyRouteObject: CustomRouteObject = {
	path: 'privacy',
	element: <PrivacyTermPage />,
};

export const settingRouteObject: CustomRouteObject = {
	path: 'setting',
	element: <SettingPage />,
};
export const leaveRouteObject: CustomRouteObject = {
	path: 'leave',
	element: <LeavePage />,
};

const mainRoutesGroup = [
	// 내 정보관리
	homepageRouteObject,
	myPageRouteObject,
	infoPageRouteObject,
	myMembershipPageRouteObject,
	vauncePremiumRouteObject,
	vauncePremiumVipClubRegisterRouteObject,
	nicePaySuccessRouteObject,
	nicePayOneSuccessRouteObject,
	nicePayResultRouteObject,
	oneStopPageRouteObject,
	multiTicketRouteObject,
	cmtTicketRouteObject,
	oneTicketRouteObject,
	ticketSaleRouteObject,
	couponRouteObject,
	registerCouponRouteObject,
	wishListRouteObject,
	centerHistoryRouteObject,
	payhistoryRouteObject,
	// 바운스 이용하기
	centerDescriptionRouteObject,
	centerDetailRouteObject,
	centerLocationRouteObject,
	membershipRouteObject,
	facilityRegisterRouteObject,
	facilityNewRegisterStepOne,
	facilityNewRegisterStepTwo,
	facilityNewRegisterStepThree,
	facilityNewRegisterComplete,
	facilityRegisterHistory,
	facilityRegisteredQR,
	reservationRouteObject,
	eventRouteObject,
	eventDetailRouteObject,
	noticeRouteObject,
	// 고객센터
	oneTicketWithRefundObject,
	centerGuideRouteObject,
	faqRouteObject,
	oneToOneRouteObject,
	normalQeustionRouteObject,
	partyQeustionStepOne,
	partyQuestionStepTwo,
	partyQuestionStepThree,
	questionDoneRouteObject,
	appguideRouteObject,
	termsofuseRouteObject,
	privacyRouteObject,
	settingRouteObject,
	// 회원탈퇴
	leaveRouteObject,
];

export const loginPageRouteObject: CustomRouteObject = {
	path: 'login',
	element: <LoginPage />,
};

export const signupFirstRouteObject: CustomRouteObject = {
	path: 'signup01',
	element: <SignUpFirstPage />,
};

export const signupSecondRouteObject: CustomRouteObject = {
	path: 'signup02',
	element: <SignUpSecondStep />,
};

export const signupThirdRouteObject: CustomRouteObject = {
	path: 'signup03',
	element: <SignUpThirdStep />,
};

export const signupForthRouteObject: CustomRouteObject = {
	path: 'signup04',
	element: <SignUpForthStep />,
};

export const signupFifthRouteObject: CustomRouteObject = {
	path: 'signup05',
	element: <SignUpFifthStep />,
};

export const signupSixRouthObject: CustomRouteObject = {
	path: 'signup06',
	element: <SignUpSixthStep />,
};

export const findIdFirstRouteObject: CustomRouteObject = {
	path: 'find_id01',
	element: <FindIdPage />,
};

export const findIdSecondRouteObject: CustomRouteObject = {
	path: 'find_id02',
	element: <FounddIdPage />,
};

export const findIdFailRouteObject: CustomRouteObject = {
	path: 'find_id_fail',
	element: <NotFoundIdPage />,
};

export const findPwdFirstRouteObject: CustomRouteObject = {
	path: 'find_pw01',
	element: <FindPwPage />,
};

export const findPwdSecondRouteObject: CustomRouteObject = {
	path: 'find_pw02',
	element: <FoundPwPage />,
};

const subRouteObject = [
	loginPageRouteObject,
	signupFirstRouteObject,
	signupSecondRouteObject,
	signupThirdRouteObject,
	signupForthRouteObject,
	signupSixRouthObject,
	signupFifthRouteObject,
	findIdFirstRouteObject,
	findIdSecondRouteObject,
	findIdFailRouteObject,
	findPwdFirstRouteObject,
	findPwdSecondRouteObject,
	snsRedirectRouteObject,
];

export const allRoutes: CustomRouteObject[] = [
	{
		path: '',
		children: [
			{
				path: '/',
				element: <MainContent />,
				children: mainRoutesGroup,
			},
		],
	},
	{
		path: '/members',
		children: [
			{
				path: '',
				element: <SignUpContent />,
				children: subRouteObject,
			},
		],
	},
];

export const useVaunceAppRouter = () => {
	const navigate = useNavigate();

	return {
		// 내 정보관리
		navigateHomepage: () => {
			navigate(homepageRouteObject.path);
		},
		navigateMypage: () => {
			navigate(`/${myPageRouteObject.path}`);
		},
		navigateMyInfo: () => {
			navigate(`/${infoPageRouteObject.path}`);
		},
		navigateMyMembership: () => {
			navigate(`/${myMembershipPageRouteObject.path}`);
		},
		navigateVauncePremiumClub: () => {
			navigate(`/${vauncePremiumRouteObject.path}`);
		},
		navigateVauncePremiumVipClubRegister: () => {
			navigate(`/${vauncePremiumVipClubRegisterRouteObject.path}`);
		},
		navigateOneStop: () => {
			navigate(`/${oneStopPageRouteObject.path}`);
		},
		navigateMultiUseTickets: () => {
			navigate(`/${multiTicketRouteObject.path}`);
		},
		navigateCommutationTickets: () => {
			navigate(`/${cmtTicketRouteObject.path}`);
		},
		navigateOneTickets: () => {
			navigate(`/${oneTicketRouteObject.path}`);
		},

		navigateticketsale: () => {
			navigate(`/${ticketSaleRouteObject.path}`);
		},
		navigateCouponList: () => {
			navigate(`/${couponRouteObject.path}`);
		},
		navigateregisterCoupon: () => {
			navigate(`/${registerCouponRouteObject.path}`);
		},

		navigateWishList: () => {
			navigate(`/${wishListRouteObject.path}`);
		},
		navigateCenterHistory: () => {
			navigate(`/${centerHistoryRouteObject.path}`);
		},
		navigatePayHistory: () => {
			navigate(`/${payhistoryRouteObject.path}`);
		},
		// 바운스 이용하기
		navigateCenterDescription: () => {
			navigate(`/${centerDescriptionRouteObject.path}`);
		},
		navigateCenterDetail: (centerId: number) => {
			navigate(`/centerDetail/${centerId}`);
		},
		navigateCenterLocation: (centerId: number) => {
			navigate(`/centerLocation/${centerId}`);
		},
		navigateMemberShip: () => {
			navigate(`/${membershipRouteObject.path}`);
		},
		navigateFacilityRegister: () => {
			navigate(`/${facilityRegisterRouteObject.path}`);
		},
		navigateNewRegisterStepOne: () => {
			navigate(`/${facilityNewRegisterStepOne.path}`);
		},
		navigateNewRegisterStepTwo: () => {
			navigate(`/${facilityNewRegisterStepTwo.path}`);
		},
		navigateNewRegisterStepThree: () => {
			navigate(`/${facilityNewRegisterStepThree.path}`);
		},
		navigateNewRegisterComplete: () => {
			navigate(`/${facilityNewRegisterComplete.path}`);
		},
		navigateRegisterHistory: () => {
			navigate(`/${facilityRegisterHistory.path}`);
		},
		navigateRegisteredQR: () => {
			navigate(`/${facilityRegisteredQR.path}`);
		},
		navigateReservation: () => {
			navigate(`/${reservationRouteObject.path}`);
		},
		navigateEvent: () => {
			navigate(`/${eventRouteObject.path}`);
		},
		navigateEventDetail: (id: number) => {
			navigate(`/eventDetail/${id}`);
		},
		navigateNoticeAndAlarm: () => {
			navigate(`/${noticeRouteObject.path}`);
		},
		// 고객센터
		navigateCenterGuide: () => {
			navigate(`/${centerGuideRouteObject.path}`);
		},
		navigateOneTicketWithRefund: () => {
			navigate(`/${oneTicketWithRefundObject.path}`);
		},
		navigateFAQ: () => {
			navigate(`/${faqRouteObject.path}`);
		},
		navigateOneToOne: () => {
			navigate(`/${oneToOneRouteObject.path}`);
		},
		navigateNormalQeustion: () => {
			navigate(`/${normalQeustionRouteObject.path}`);
		},
		navigatePartyQuestionStepOne: () => {
			navigate(`/${partyQeustionStepOne.path}`);
		},
		navigatePartyQuestionStepTwo: () => {
			navigate(`/${partyQuestionStepTwo.path}`);
		},
		navigatePartyQuestionStepThree: () => {
			navigate(`/${partyQuestionStepThree.path}`);
		},
		navigateQuestionDone: () => {
			navigate(`/${questionDoneRouteObject.path}`);
		},
		navigateAppGuide: () => {
			navigate(`/${appguideRouteObject.path}`);
		},
		navigateTermsOfUse: () => {
			navigate(`/${termsofuseRouteObject.path}`);
		},
		navigatePrivacy: () => {
			navigate(`/${privacyRouteObject.path}`);
		},
		navigateSetting: () => {
			navigate(`/${privacyRouteObject.path}`);
		},
		navigateLeave: () => {
			navigate(`/${leaveRouteObject.path}`);
		},
		// 로그인
		navigateLogin: () => {
			navigate(`/members/${loginPageRouteObject.path}`);
		},
		navigateSignUpStepOne: () => {
			navigate(`/members/${signupFirstRouteObject.path}`);
		},
		navigateSignUpStepTwo: () => {
			navigate(`/members/${signupSecondRouteObject.path}`);
		},
		navigateSignUpStepThree: () => {
			navigate(`/members/${signupThirdRouteObject.path}`);
		},
		navigateSignUpStepForth: () => {
			navigate(`/members/${signupForthRouteObject.path}`);
		},
		navigateSignUpStepFive: () => {
			navigate(`/members/${signupFifthRouteObject.path}`);
		},
		navigateSignUpStepSix: (name: string) => {
			navigate({
				pathname: `/members/${signupSixRouthObject.path}`,
				search: `?${createSearchParams({ name: name })}`,
			});
		},
		navigateFindIdStepOne: () => {
			navigate(`/members/${findIdFirstRouteObject.path}`);
		},
		navigateFindIdStepTwo: () => {
			navigate(`/members/${findIdSecondRouteObject.path}`);
		},
		navigateNotFoundId: () => {
			navigate(`/members/${findIdFailRouteObject.path}`);
		},
		navigateFindPwdStepOne: () => {
			navigate(`/members/${findPwdFirstRouteObject.path}`);
		},
		navigateFindPwdStepTwo: () => {
			navigate(`/members/${findPwdSecondRouteObject.path}`);
		},
	};
};
