import {
	KitchenOrderInfoType,
	KitchenOrderLineTypeRule,
	kitchenOrderStatusLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat, getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState, AdminStoreInfoState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import { Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';

export interface IKitchenOrderDetailContentsProps {
	kitchenOrderInfo: KitchenOrderInfoType;
	// kitchenOrderInfo: KitchenOrderInfoType;
	Actions?: ReactNode;
	navigate주문목록?: (val: string) => void;
}
export const KitchenOrderDetailContents: React.FC<IKitchenOrderDetailContentsProps> = ({
	kitchenOrderInfo,
	Actions,
	navigate주문목록,
}) => {
	const {
		created,
		status,
		completed,
		canceled,
		kitchenOrderLines,
		id,
		orderId,
		deviceType,
		deviceId,
		storeId,
	} = kitchenOrderInfo;

	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const storeInfoList = data ?? [];
	const deviceInfo = AdminPosInfoState.useDeviceInfo({
		storeId,
		deviceId,
	});

	const kitchenOrderInfoList: { label: ReactNode; value: ReactNode }[] = [
		{ label: '주방 주문 번호', value: `${id}` },
		{ label: '주방 주문 상태', value: `${kitchenOrderStatusLabel[status]}` },
		{ label: '주방 주문 일자', value: `${getDateTimeFormat(created)}` },
		{
			label: '주문 기기',
			value: `${deviceInfo?.label || ' - '}`,
		},
		{
			label: status === 'CANCELED' ? '주방 주문 취소 일자' : '주방 주문 완료 일자',
			value: canceled
				? `${getDateTimeFormat(canceled)}`
				: completed
				? `${getDateTimeFormat(completed)}`
				: ' - ',
		},
		{
			label: '주문 호출 번호',
			value: `${
				kitchenOrderInfo.telephone ? getTelephoneNumberWithDash(kitchenOrderInfo.telephone) : ' - '
			}`,
		},
	];

	const originOrderInfo: { label: ReactNode; value: ReactNode }[] = [
		{
			label: '주문 아이디',
			value: (
				<Link
					sx={{ cursor: 'pointer' }}
					onClick={() => navigate주문목록 && navigate주문목록(orderId)}>
					{orderId}
				</Link>
			),
		},
		{ label: '매장 정보', value: storeInfoList.find((store) => store.id === storeId)?.name || '-' },
		{
			label: '주문 기기',
			value: `${deviceInfo?.label || ' - '}`,
		},
	];
	return (
		<Stack spacing={2}>
			<Stack spacing={1}>
				<InfoPrintStackTitle title={'주방 주문 정보'} />
				{kitchenOrderInfoList.map((info, idx) => (
					<InfoPrintStackRow
						key={idx}
						label={info.label}
						value={info.value}
					/>
				))}
			</Stack>
			<Stack spacing={1}>
				<InfoPrintStackTitle title={'주문 메뉴 정보'} />
				{KitchenOrderLineTypeRule.getGroupedKitchenOrderLines(kitchenOrderLines).map((line) => {
					const { targetOrder, optionList } = line;
					return (
						<Stack spacing={1}>
							<InfoPrintGridRow
								label={targetOrder.name}
								qty={targetOrder.quantity}
								status={kitchenOrderStatusLabel[targetOrder.orderLineStatus]}
							/>
							<Stack>
								{optionList.map((option) => (
									<Typography>{` └ ${option.name}`}</Typography>
								))}
							</Stack>
						</Stack>
					);
				})}
			</Stack>
			<Stack>{Actions}</Stack>
			{navigate주문목록 && (
				<Stack>
					<InfoPrintStackTitle title={'원주문 정보'} />
					<List>
						{originOrderInfo.map((info, idx) => (
							<ListItem key={idx}>
								<Stack
									direction={'row'}
									alignItems={'center'}
									width={'100%'}>
									<Stack
										flex={1}
										justifyContent={'flex-start'}>
										<Typography>{info.label}</Typography>
									</Stack>
									<Stack
										flex={1}
										justifyContent={'flex-start'}>
										<Typography>{info.value}</Typography>
									</Stack>
								</Stack>
							</ListItem>
						))}
					</List>
				</Stack>
			)}
		</Stack>
	);
};

const InfoPrintGridRow = ({
	label,
	qty,
	status,
}: {
	label: ReactNode;
	qty: ReactNode;
	status: ReactNode;
}) => {
	return (
		<Grid
			container
			rowSpacing={1}>
			<Grid
				item
				xs={7}>
				<Typography variant={'body1'}>{label}</Typography>
			</Grid>
			<Grid
				item
				xs={2}>
				<Typography variant={'body1'}>{qty}</Typography>
			</Grid>
			<Grid
				item
				xs={3}>
				<Typography
					textAlign={'end'}
					variant={'body1'}>
					{status}
				</Typography>
			</Grid>
		</Grid>
	);
};
