import { DdiziTypeToJungsan } from '@kinderlabs-pos/shared-data-type';
import { DdiziState } from '@kinderlabs-pos/state';
import { TickeTimeMinusButton } from '../../modules/tickets/components/buttons/TicketTimeMinusButton';
import { TickeTimePlusButton } from '../../modules/tickets/components/buttons/TicketTimePlusButton';

export interface IDdiziTimeAdjustButtonsProps {
  ddizi: DdiziTypeToJungsan;
}
export const DdiziTimeAdjustButtons: React.FC<IDdiziTimeAdjustButtonsProps> = ({
  ddizi,
}) => {
  const adjustTime = DdiziState.actions.useAdjustTime();
  const handleAdjustTime: (time: number) => React.MouseEventHandler =
    (time: number) => (e) => {
      e.stopPropagation();
      adjustTime.mutate([{ ddiziId: ddizi.id, time: time }]);
    };
  const { status } = ddizi;

  return (
    <>
      <TickeTimePlusButton
        onClick={handleAdjustTime(10)}
        disabled={status !== 'USING'}
      />
      <TickeTimeMinusButton
        onClick={handleAdjustTime(-10)}
        disabled={status !== 'USING'}
      />
    </>
  );
};
