import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import {
	CartLineInfoType,
	CartLineTypeRules,
	PosBoardItemType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';
import { useHandleClickPosBoardItem } from './handleClickPosBoardItem';
import { PosBoardBase } from './PosBoardBase';
import { ProductBoardActionBoardItem } from './ProductBoardActionBoardItem';
import { useState } from 'react';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { ProductSetSelectDialog } from './ProductSetSelectDialog';

export const PosBoardInOrderEdit = ({
	originCartLines,
}: {
	originCartLines: CartLineInfoType[];
}) => {
	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);
	const [selectedSetProductInfo, setSelectedSetProductInfo] = useState<ProductInfoType | undefined>(
		undefined
	);

	return (
		<WithDialog
			dialog={(open, closeDialog) =>
				selectedSetProductInfo ? (
					<ProductSetSelectDialog
						productInfo={selectedSetProductInfo}
						open={open}
						closeDialog={closeDialog}
					/>
				) : null
			}>
			{(openDialog) => (
				<PosBoardBase
					renderItem={({ item, productInfo, idx }) => (
						<ProductBoardActionBoardItemInOrderEditPage
							key={idx}
							productInfo={productInfo}
							originCartLines={originCartLines}
							selectedCartLine={selectedCartLine}
							openSetProductDialog={(productInfo) => {
								setSelectedSetProductInfo(productInfo);
								openDialog();
							}}
							item={item}
						/>
					)}
				/>
			)}
		</WithDialog>
	);
};

const ProductBoardActionBoardItemInOrderEditPage = ({
	productInfo,
	item,
	selectedCartLine,
	originCartLines,
	openSetProductDialog,
}: {
	productInfo: Omit<ProductInfoType, 'usedInPosBoard'>;
	item: PosBoardItemType;
	originCartLines: CartLineInfoType[];
	selectedCartLine?: CartLineInfoType;
	openSetProductDialog: (productInfo: Omit<ProductInfoType, 'usedInPosBoard'>) => void;
}) => {
	const disabled =
		productInfo.type === 'OPTION' &&
		(!selectedCartLine || !CartLineTypeRules.canClickOptionOnBoard[selectedCartLine.type]);

	const handleClickBoardItem = useHandleClickPosBoardItem();

	const handleClickItem = () => {
		let targetCartLineId: string | undefined = undefined;
		try {
			targetCartLineId =
				selectedCartLine && productInfo.type === 'OPTION'
					? OrderState.cart.lines.boardUtils.getTargetCartLineFromOption({
							cartLines: originCartLines,
							targetCartLineId: selectedCartLine?.id,
					  }).targetCartLine.id
					: undefined;
		} catch {
			targetCartLineId = undefined;
		}

		const alreadyInCartLine = originCartLines
			.filter((cl) => CartLineTypeRules.canEditCartLine[cl.type])
			// 옵션이 없어야 함
			.filter((cl) => !originCartLines.some((optionCl) => optionCl.targetCartLineId === cl.id))
			.find((cl) =>
				OrderState.cart.board.utils.isSameCartLine(cl, {
					...cl,
					productInfoId: item.productInfoId,
					price: productInfo.price,
					name: productInfo.name,
					targetCartLineId,
				})
			);

		if (productInfo.type === 'SET') {
			openSetProductDialog(productInfo);
		} else {
			handleClickBoardItem({
				cartLineId: alreadyInCartLine ? alreadyInCartLine.id : getUuidV4(),
				productInfo,
			});
		}
	};

	return (
		<ProductBoardActionBoardItem
			productInfo={productInfo}
			item={item}
			disabled={disabled}
			onClick={handleClickItem}
		/>
	);
};
