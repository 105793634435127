import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { MultiUseTicketDetailType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';

export const getMutDetailAsync =
	(rootUrl: string) =>
	async ({ ticketId, memberId, pageable }: GetMutDetailAsyncRequestType) =>
		(
			await posAxiosClient.get<PageType<MultiUseTicketDetailType>>(`${rootUrl}/ticket/mut/detail`, {
				params: {
					...pageable.getPageableQueryParam(),
					ticketId,
					memberId,
				},
			})
		).data;

export type GetMutDetailAsyncRequestType = {
	ticketId: number;
	memberId: number;
	pageable: Pageable;
};
