import { OrderQueryState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { selectedOrderAndPaymentItemAtom } from '../OrderAndPaymentsContents';
import { ActionsInOrder } from './ActionsInOrder';
import { DdizListContentsInOrder } from './DdizListContentsInOrder';
import { ExchangeCouponInOrder } from './ExchangeCouponInOrder';
import { ExternalTicketsInOrder } from './ExternalTicketsInOrder';
import { OrderDetailContentsWrapper } from './OrderDetailContentsWrapper';
import { PaymentDetailContentsInOrder } from './PaymentDetailContentsInOrder';
import { PointProcessedStateInOrder } from './PointProcessedStateInOrder';

export const SelectedDetailContents = ({ orderId }: { orderId: string }) => {
	const selectedOrderAndPaymentItem = useAtomValue(selectedOrderAndPaymentItemAtom);
	const { data: orderWithDetail, isFetching } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});
	if (!orderWithDetail) return null;

	return (
		<>
			{selectedOrderAndPaymentItem.type === 'order' && (
				<OrderDetailContentsWrapper order={orderWithDetail} />
			)}
			{selectedOrderAndPaymentItem.type === 'ddizi' && (
				<DdizListContentsInOrder order={orderWithDetail} />
			)}
			{selectedOrderAndPaymentItem.type === 'payment' && (
				<PaymentDetailContentsInOrder
					orderWithDetail={orderWithDetail}
					paymentId={selectedOrderAndPaymentItem.id}
					withRefunds={false}
				/>
			)}
			{selectedOrderAndPaymentItem.type === 'point' && (
				<PointProcessedStateInOrder order={orderWithDetail} />
			)}
			{selectedOrderAndPaymentItem.type === 'exchangeCoupon' && (
				<ExchangeCouponInOrder order={orderWithDetail} />
			)}
			{selectedOrderAndPaymentItem.type === 'externalTickets' && (
				<ExternalTicketsInOrder order={orderWithDetail} />
			)}
			{selectedOrderAndPaymentItem.type === 'orderActions' && (
				<ActionsInOrder
					order={orderWithDetail}
					isFetching={isFetching}
				/>
			)}
		</>
	);
};

// const DiscountDetailContents = ({ discount }: { discount: CartDiscountType }) => {
// 	return (
// 		<>
// 			{discount.gubun === 'POINT' && (
// 				<PointUsageDetailContents pointDiscoint={discount as CartPointDiscountType} />
// 			)}
// 			{discount.gubun === 'COUPON' && (
// 				<DiscountCouponUsageDetailContents cartDiscountCoupon={discount as CouponDiscountType} />
// 			)}
// 		</>
// 	);
// };
