import { MuiReactTableUtils } from '@kinderlabs-pos/frameworks/table';
import { getNewCountLabel, numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { ColumnDef, Row, SortingFn } from '@tanstack/react-table';

const get금액ColumnDef = <TData,>(
	columnId: string,
	forExcel = false
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: ({ getValue }) => numberWithCommasAndWon(getValue() as number),
	footer: (headerContext) =>
		forExcel
			? MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
			  })
			: numberWithCommasAndWon(
					MuiReactTableUtils.getAggregatedValue({
						headerContext,
						columnId,
					})
			  ),
	aggregationFn: 'sum',
});

const 요일Mapping: Record<string, number> = {
	월: 1,
	화: 2,
	수: 3,
	목: 4,
	금: 5,
	토: 6,
	일: 7,
};

const 요일SortingFn = <TData,>(rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
	const dayA = 요일Mapping[rowA.getValue(columnId) as string];
	const dayB = 요일Mapping[rowB.getValue(columnId) as string];

	// 내림차순 정렬을 위해 비교
	if (dayA > dayB) {
		return -1;
	}
	if (dayA < dayB) {
		return 1;
	}
	return 0;
};

const get금액반올림ColumnDef = <TData,>(
	columnId: string
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn' | 'meta'> => ({
	cell: ({ getValue }) => numberWithCommasAndWon(Math.round(getValue() as number)),
	footer: (headerContext) =>
		numberWithCommasAndWon(
			Math.round(
				MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
				})
			)
		),
	aggregationFn: 'sum',
	// NX 에서 ts declare 를 전역적으로 쉽게 할 수 있는 방법이 없네요. excel반올림 으로 검색할 것
	meta: {
		excel반올림: true,
	},
});

const get건수ColumnDef = <TData,>(
	columnId: string,
	forExcel = false
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: (info) => getNewCountLabel(info.getValue() as number),
	footer: (headerContext) =>
		forExcel
			? MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
			  })
			: getNewCountLabel(
					MuiReactTableUtils.getAggregatedValue({
						headerContext,
						columnId,
					})
			  ),
	aggregationFn: 'sum',
});

const get개수세기ColumnDef = <TData,>(
	columnId: string,
	postFix?: string
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: (info) => `${info.getValue() as number}${postFix ? ` ${postFix}` : ''}`,
	footer: (headerContext) =>
		`${MuiReactTableUtils.getAggregatedValue({
			headerContext,
			columnId,
		})}${postFix ? ` ${postFix}` : ''}`,
	aggregationFn: 'sum',
});

const get가격ColumnDef = <TData,>(): Pick<
	ColumnDef<TData>,
	'cell' | 'footer' | 'aggregatedCell' | 'aggregationFn'
> => ({
	cell: (info) => numberWithCommasAndWon(info.getValue() as number),
	footer: undefined,
	//@ts-ignore
	aggregationFn: null,
});

export const ColumnDefsHelper = {
	요일SortingFn,
	get금액ColumnDef,
	get금액반올림ColumnDef,
	get건수ColumnDef,
	get가격ColumnDef,
	get개수세기ColumnDef,
};
