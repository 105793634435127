import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Pageable } from '@kinderlabs-pos/shared-data-type';
import { Box, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import {
	CellContext,
	ColumnDef,
	HeaderContext,
	PaginationState,
	SortingState,
	TableOptions,
	createTable,
	getCoreRowModel,
	getFilteredRowModel,
} from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';
import { printExcelFromReactTable } from './printExcelFromReactTable';
import { getTempFilteredRowModel } from './getTempFilteredRowModel';
interface ARG<DATA_ROW_TYPE> {
	columnId: string;
	headerContext: HeaderContext<DATA_ROW_TYPE, any>;
}

const getAggregatedValue = <DATA_ROW_TYPE,>({ headerContext, columnId }: ARG<DATA_ROW_TYPE>) => {
	const aggregate = headerContext.column.getAggregationFn();
	const rows = headerContext.table.getFilteredRowModel();
	const aggregateValue = aggregate
		? aggregate(columnId, [] /* 의미없는 더미*/, rows.rows)
		: undefined;

	return aggregateValue;
};

const ColumnWithExpandButton = <DATA_ROW_TYPE,>({
	info,
	label,
	depth = 0,
}: {
	info: CellContext<DATA_ROW_TYPE, any>;
	label: ReactNode;
	depth?: number;
}) => (
	<Stack
		width={'100%'}
		direction={'row'}
		alignItems={'center'}
		spacing={1}>
		<Typography sx={{ flex: 1 }}>{label}</Typography>
		{info.row.getCanExpand() && info.row.depth === depth ? (
			<IconButton
				style={{ fontSize: '1.2rem' }}
				sx={(theme) => ({
					fontSize: theme.typography.body1.fontSize,
				})}
				onClick={info.row.getToggleExpandedHandler()}>
				{info.row.getIsExpanded() ? (
					<DownOutlined style={{ fontSize: 'inherit' }} />
				) : (
					<RightOutlined style={{ fontSize: 'inherit' }} />
				)}
			</IconButton>
		) : (
			<Box width={28} />
		)}
	</Stack>
);

const getCheckerColumnDef = <TData,>(id: string = 'selected'): ColumnDef<TData> => ({
	id,
	size: 40,
	header: ({ table }) => (
		<Checkbox
			checked={table.getIsAllRowsSelected()}
			indeterminate={table.getIsSomeRowsSelected()}
			onChange={table.getToggleAllRowsSelectedHandler()}
		/>
	),
	cell: ({ row }) => (
		<Checkbox
			checked={row.getIsSelected()}
			onClick={row.getToggleSelectedHandler()}
		/>
	),
});

const convert_From_APISort_To_ReactTableSort = (sort: Pageable<string>['sort']): SortingState => {
	return sort ? [{ id: sort.name, desc: sort.direction === 'desc' }] : [];
};
export const convert_From_ReactTableSort_To_APISort = (
	sorting: SortingState
): Pageable<string>['sort'] => {
	return sorting.length > 0
		? {
				name: sorting[0].id,
				direction: sorting[0].desc ? 'desc' : 'asc',
		  }
		: undefined;
};

const useManualSortingState = <
	TOption extends {
		sort?: Pageable<string>['sort'];
	}
>({
	options,
	setOptions,
}: {
	options: TOption;
	setOptions: React.Dispatch<React.SetStateAction<TOption>>;
}) => {
	const [sorting, setSorting] = useState<SortingState>(() =>
		convert_From_APISort_To_ReactTableSort(options.sort as Pageable<string>['sort'])
	);

	useEffect(() => {
		setOptions((prev) => ({
			...prev,
			sort: convert_From_ReactTableSort_To_APISort(sorting),
		}));
	}, [sorting]);

	return {
		sorting,
		setSorting,
	};
};

const convert_From_APIPage_To_ReactTablePage = (pageOption: {
	page: Pageable['page'];
	size?: Pageable['size'];
}): PaginationState => {
	return {
		pageIndex: pageOption.page,
		pageSize: pageOption.size || 0,
	};
};
const convert_From_ReactTablePage_To_APIPage = (
	pagination: PaginationState
): {
	page: Pageable['page'];
	size: Pageable['size'];
} => {
	return {
		page: pagination.pageIndex,
		size: pagination.pageSize,
	};
};

const useManualPagingState = <
	TOption extends {
		page: number;
		size?: number;
	}
>({
	options,
	setOptions,
}: {
	options: TOption;
	setOptions: React.Dispatch<React.SetStateAction<TOption>>;
}) => {
	const [pagination, setPagination] = useState<PaginationState>(() =>
		convert_From_APIPage_To_ReactTablePage(options)
	);

	useEffect(() => {
		setOptions((prev) => ({
			...prev,
			...convert_From_ReactTablePage_To_APIPage(pagination),
		}));
	}, [pagination]);

	return {
		pagination,
		setPagination,
	};
};

/**
 * Tanstack Table createTable에 버그가 많이 있어서 기능 래핑
 *
 * @url https://github.com/TanStack/table/discussions/4928
 * @url https://github.com/TanStack/table/discussions/4804
 */
const printExcelFromReactTableOptions = <TData,>({
	options,
	fileName,
	footerFromOriginTable = false,
}: {
	options: Pick<TableOptions<TData>, 'columns' | 'data' | 'state' | 'globalFilterFn'>;
	fileName: string;
	footerFromOriginTable?: boolean;
}) => {
	const table = createTable({
		columns: options.columns,
		data: options.data,
		globalFilterFn: options.globalFilterFn,
		state: { ...options.state, columnPinning: {} },
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getTempFilteredRowModel(),
		onStateChange: () => {},
		renderFallbackValue: () => {},
	});

	printExcelFromReactTable({ table, fileName, footerFromOriginTable });
};

export const MuiReactTableUtils = {
	getAggregatedValue,
	getCheckerColumnDef,
	printExcelFromReactTable,
	printExcelFromReactTableOptions,
	ColumnWithExpandButton,
	useManualSortingState,
	useManualPagingState,
};
