import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Box, Stack, Typography } from '@mui/material';
import qrImage from 'assets/vaunce/image/iconqr.png';
import plusImage from 'assets/vaunce/image/magnifyplus.png';
import plusImage2x from 'assets/vaunce/image/magnifyplus@2x.png';
import React from 'react';
import QrImageDialog from './QrImageDialog';

import { CouponContentBox, CouponDescBox, CouponSubDescBox } from '../../components/CommonStyled';
import { EmptyContent } from '../../components/EmptyContent';
import QrImageBadge from '../../components/QrImageBadge';
import { PlusImageBox } from '../VipclubPage';
import { Coupon } from '@kinderlabs-pos/shared-data-type';

export interface IAvailableCouponPanelProps {
	couponList: Coupon[];
}
const AvailableCouponPanel: React.FC<IAvailableCouponPanelProps> = ({ couponList }) => {
	return (
		<Box
			marginTop={'10px'}
			height={'100%'}
			overflow={'auto'}>
			{couponList.length === 0 && <EmptyContent title='사용가능한 쿠폰이 없습니다' />}
			{couponList &&
				couponList.map((coupon, idx) => (
					<CouponContentBox
						key={coupon.qrData}
						style={{ display: 'flex', padding: '25px' }}>
						<WithDialog
							dialog={(open, closeDialog) => (
								<QrImageDialog
									open={open}
									onClose={closeDialog}
									coupon={coupon}
								/>
							)}>
							{(openDialog) => (
								<QrImageBadge
									sx={{ cursor: 'pointer' }}
									qrImage={qrImage}
									imageSize={'sm'}
									onClick={openDialog}
									badgeContent={
										<PlusImageBox>
											<img
												src={plusImage}
												srcSet={plusImage2x}
												width={14}
												height={14}
											/>
										</PlusImageBox>
									}
								/>
							)}
						</WithDialog>

						<CouponDescBox
							sx={{ ml: '2rem' }}
							width={'100%'}>
							<Typography variant='h3'>{coupon.title}</Typography>
							<Stack
								direction={'row'}
								justifyContent={'space-between'}>
								<Typography
									color='primary'
									variant='h5'
									marginRight={'auto'}
									fontWeight={500}>
									{coupon.subTitle}
								</Typography>
								<Typography
									variant='h5'
									fontWeight={500}>
									{coupon.expiredAt} 까지
								</Typography>
							</Stack>
						</CouponDescBox>
					</CouponContentBox>
				))}
		</Box>
	);
};

export default AvailableCouponPanel;
