import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import { ProductInfoType, ProductTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { Button, Unstable_Grid2 as Grid, ListItemButton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { ProductSetSelectDialog } from './ProductSetSelectDialog';
import { useHandleClickPosBoardItem } from './handleClickPosBoardItem';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';

export const ProductInfoItemOnPos = ({
	closeSearchDialog,
	productInfo,
}: {
	productInfo: ProductInfoType;
	closeSearchDialog?: () => void;
}) => {
	const [fandbSetDialogOpened, setFandBSetDialogOpened] = useState(false);
	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);

	const handleClickProductItemButton = useHandleClickPosBoardItem();
	const handleClickItem = () => {
		if (productInfo.type === 'SET') {
			setFandBSetDialogOpened(true);
		} else {
			handleClickProductItemButton({
				cartLineId: getUuidV4(),
				productInfo,
			});
			closeSearchDialog && closeSearchDialog();
		}
	};

	return (
		<>
			<SelectableListItem
				// 옵션이면 selected 가 있어야함
				disabled={productInfo.type === 'OPTION' ? !selectedCartLine : false}
				onClick={handleClickItem}>
				<Grid
					container
					columnGap={2}
					sx={{ width: '100%', alignItems: 'center' }}>
					<Grid xs={2.5}>
						<Button
							sx={{ width: '100%' }}
							variant='outlined'>
							{ProductTypeLabel[productInfo.type]}
						</Button>
					</Grid>
					<Grid xs={9}>
						<Stack flex={1}>
							<Typography variant='h4'>
								{productInfo.name}
								<Typography
									color='text.secondary'
									variant='h4'
									component='span'>{` #${productInfo.id}`}</Typography>
							</Typography>
							<Typography
								variant='body1'
								color={'text.secondary'}>
								{numberWithCommasAnd원(productInfo.price)}
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</SelectableListItem>
			{productInfo.type === 'SET' && (
				<ProductSetSelectDialog
					productInfo={productInfo}
					open={fandbSetDialogOpened}
					closeDialog={() => setFandBSetDialogOpened(false)}
					closeSearchDialog={closeSearchDialog}
				/>
			)}
		</>
	);
};
