import { getAnalytics, logEvent } from '@firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const FirebaseAnalytics = () => {
	const location = useLocation();
	const analytics = getAnalytics();

	useEffect(() => {
		if (!window.location.href.includes('localhost')) {
			logEvent(analytics, 'screen_view', {
				firebase_screen: location.pathname + location.search,
				firebase_screen_class: location.pathname + location.search,
			});
		}
	}, [location]);
};
