import { SNSProviderType, SNSRedirectType } from '@kinderlabs-pos/shared-data-type';
import { getAdAgreePushAsync, getAgreePushAsync } from './getAgreePushAsync';
import {
	getAlarmListAsync,
	getAllAlarmDeleteAsync,
	getAllAlarmReadAsync,
	getOneAlarmReadAsync,
} from './getAlarmListAsync';
import {
	getCenterCollectionAsync,
	getCenterHistoryAsync,
	postUserExtraInfoAsync,
} from './getCenterHistoryAsync';
import { getDeleteChildrenAsync, postChildrenAsync } from './getChildrenAsync';
import {
	getCouponListAsync,
	getEventListAsync,
	getEventOne,
	getRegisterCouponAsync,
} from './getCouponAsync';
import {
	getInfoAsync,
	getMyInfoAsync,
	getSignIn,
	getUsageInfoAsync,
	postAddressAsync,
	postInfoImageAsync,
	updateInfoPasswordAsync,
} from './getMyInfoAsync';
import {
	getAllNoticeListPageableAsync,
	getNoticeDetail,
	getSetting,
} from './getNoticeListPageableAsync';
import { getPayAsync } from './getPayAsync';
import { getPointAsync } from './getPointAsync';
import {
	addPremiumSeanAsync,
	getPremiumAsync,
	getPremiumOneAsync,
	postNicePayAsync,
	postNicePayOneAsync,
	postPremiumCollectionAsync,
	getPremiumOneRefundInfoAsync,
	postPremiumOneNicePayCancelAsync,
} from './getPremiumAsync';
import {
	getNormalQuestionListAsync,
	getPartyQuestionListAsync,
	deleteQuestion,
} from './getQuestionListAsync';
import { getVaunceAppAuthorizationToken } from './getVaunceAppAuthorizationToken';
import { getCheckWishListAsync, getWishListAsync, toggleWishListAsync } from './getWishListAsync';
import {
	getDuplicatedId,
	getWithdrawal,
	postAppleLoginAsync,
	postFindIdsync,
	postFindPwAsync,
	postLogInAsync,
	postSignUpAsync,
	postSignUpMoreInfoAsync,
	postSnsJoinAsync,
	postSnsLogInAsync,
	postSnsLogInForSignupAsync,
} from './loginAsync';
import {
	deleteFacilityByUsageId,
	enrollmentFacilityAsync,
	getInfoByUsageId,
	getUsageFacilityAsync,
	getUserRecentOneAsync,
	recestEnrollmentUsage,
	updateFacilityReqeustAsync,
} from './updateFacilityReqeustAsync';
import { updateNormalQuestAsync, updatePartyQuestionAsync } from './updateQuestionAsync';
import { getPopupAsync } from './PopupAsync';
import { postTechLabsLogging } from './postTechLabsLoggingAsync';
import {
	backendApiRootUrl,
	frontendRootURL,
	rootUrl,
	techLabsUrl,
} from '@kinderlabs-pos/shared-const';

export const VaunceAppApis = {
	//token
	getAuthorizationToken: getVaunceAppAuthorizationToken(backendApiRootUrl),
	//auth
	getDuplicatedId: getDuplicatedId(rootUrl),
	postLogInAsync: postLogInAsync(rootUrl),
	postSignUpAsync: postSignUpAsync(rootUrl),
	postSignUpMoreInfoAsync: postSignUpMoreInfoAsync(rootUrl),
	postSnsJoinAsnyc: postSnsJoinAsync(rootUrl),
	postSnsLogInAsync: postSnsLogInAsync(rootUrl),
	postSnsLogInForSignupAsync: postSnsLogInForSignupAsync(rootUrl),
	postAppleLoginAsync: postAppleLoginAsync(rootUrl),

	//popup
	getPopupAsync: getPopupAsync(rootUrl),

	// postSnsSignUpAsync: postSnsSignUpAsync(rootUrl),
	addFindIdsync: postFindIdsync(rootUrl),
	postFindPwAsync: postFindPwAsync(rootUrl),
	getWithdrawal: getWithdrawal(rootUrl),

	//homepage
	getAgreePush: getAgreePushAsync(rootUrl),
	getAdAgreePushAsync: getAdAgreePushAsync(rootUrl),

	//wishList
	getWishListAsync: getWishListAsync(rootUrl),
	toggleWishListAsync: toggleWishListAsync(rootUrl),
	getCheckWishListAsync: getCheckWishListAsync(rootUrl),

	//coupon
	getCouponListAsync: getCouponListAsync(rootUrl),
	getRegisterCouponAsync: getRegisterCouponAsync(rootUrl),

	//event
	getEventListAsync: getEventListAsync(rootUrl),
	getEventOne: getEventOne(rootUrl),

	//myInfo
	getMyInfoAsync: getMyInfoAsync(rootUrl),
	getSignIn: getSignIn(backendApiRootUrl),
	getInfoAsync: getInfoAsync(rootUrl),
	getUsageAsync: getUsageInfoAsync(rootUrl),

	updateInfoPasswordAsync: updateInfoPasswordAsync(rootUrl),

	postInfoImageAsync: postInfoImageAsync(rootUrl),

	//address
	postAddressAsync: postAddressAsync(rootUrl),

	//premium
	getPremiumAsync: getPremiumAsync(rootUrl),
	postPremiumCollectionAsync: postPremiumCollectionAsync(rootUrl),
	addPremiumSeanAsync: addPremiumSeanAsync(rootUrl),
	getPremiumOneAsync: getPremiumOneAsync(rootUrl),
	getPremiumOneRefundInfoAsync: getPremiumOneRefundInfoAsync(rootUrl),
	postPremiumOneNicePayCancelAsync: postPremiumOneNicePayCancelAsync(rootUrl),
	postNicePayAsync: postNicePayAsync(backendApiRootUrl),
	postNicePayOneAsync: postNicePayOneAsync(backendApiRootUrl),

	getCenterHistoryAsync: getCenterHistoryAsync(rootUrl),
	getCenterCollectionAsync: getCenterCollectionAsync(rootUrl),

	postUserExtraInfoAsync: postUserExtraInfoAsync(rootUrl),

	getPayAsync: getPayAsync(rootUrl),

	// vaunce club point
	getPointAsync: getPointAsync(rootUrl),

	deleteChildrenAsync: getDeleteChildrenAsync(rootUrl),
	postChildrenAsync: postChildrenAsync(rootUrl),

	// vaunce 일대일 문의
	sendNormQuestion: updateNormalQuestAsync(rootUrl),
	sendPartyQuestion: updatePartyQuestionAsync(rootUrl),
	getNormalQuestionList: getNormalQuestionListAsync(rootUrl),
	getPartyQeustionList: getPartyQuestionListAsync(rootUrl),
	deleteQuestion: deleteQuestion(rootUrl),

	// 간편 입장 서비스
	updateFacilityRqeust: updateFacilityReqeustAsync(rootUrl),
	updateEnrollment: enrollmentFacilityAsync(rootUrl),
	recestEnrollmentUsage: recestEnrollmentUsage(rootUrl),
	getInfoByUsageId: getInfoByUsageId(rootUrl),
	getUserRecnetone: getUserRecentOneAsync(rootUrl),
	getUsageFacilityAsync: getUsageFacilityAsync(rootUrl),
	deleteFacilityByUsageId: deleteFacilityByUsageId(rootUrl),

	// 공지 / 알림
	getAlarmList: getAlarmListAsync(rootUrl),
	getAllAlarmRead: getAllAlarmReadAsync(rootUrl),
	getAllAlarmDelete: getAllAlarmDeleteAsync(rootUrl),
	getOneAlarmRead: getOneAlarmReadAsync(rootUrl),
	getAllNoticeList: getAllNoticeListPageableAsync(rootUrl),
	getNoticeDetail: getNoticeDetail(rootUrl),

	getSNSLoginRedirectURL: (provider: SNSProviderType, type: SNSRedirectType) => {
		return `${frontendRootURL}/members/sns/redirect/${provider}/type/${type}`;
	},

	getSetting: getSetting(rootUrl),

	postTechLabsLogging: postTechLabsLogging(techLabsUrl),
};
