import { AdminOnlineTicketApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AvailableType,
	OnlineTicketInnerType,
	OnlineTicketProviderType,
	OnlineTicketUsageType,
	Pageable,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';

type OnlineTicketInfoListParamsType = {
	page: number;
	size?: number;
	sort?: Pageable['sort'];
	search?: string;
	storeIdList?: number[];
	usageStatus?: OnlineTicketUsageType;
	ticketProvider?: OnlineTicketProviderType;
	weekdayAvailable?: AvailableType;
	ticketType?: OnlineTicketInnerType;
	from: Dayjs;
	to: Dayjs;
	forUseDate?: boolean;
};

type OnlineTicketHistoryInfoParamsType = Pick<
	OnlineTicketInfoListParamsType,
	'from' | 'to' | 'page' | 'size' | 'sort' | 'storeIdList'
>;

const onlineTicketKeys = createQueryKeys('online-ticket', {
	list: ({ params }: { params: OnlineTicketInfoListParamsType }) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				const res = await AdminOnlineTicketApis.getListPage({
					search: params.search,
					from: params.from.startOf('d'),
					to: params.to.endOf('d'),
					storeIdList: params.storeIdList || storeIdList,
					weekdayAvailable: params.weekdayAvailable,
					ticketType: params.ticketType,
					pageable: Pageable.getPageable(params),
					usageStatus: params.usageStatus,
					ticketProvider: params.ticketProvider,
					forUseDate: params.forUseDate,
				});
				return res;
			},
		};
	},
	detail: ({ oltTicketId }: { oltTicketId: number }) => ({
		queryKey: [oltTicketId],
		queryFn: async () => {
			const res = await AdminOnlineTicketApis.getDetailInfo({ oltTicketId });
			return res;
		},
	}),
	history: ({
		params,
		storeIdList,
	}: {
		params: OnlineTicketHistoryInfoParamsType;
		storeIdList?: number[];
	}) => ({
		queryKey: [params],
		queryFn: async () => {
			const result = await AdminOnlineTicketApis.getHistoryInfo({
				from: params.from,
				to: params.to,
				storeIdList: params.storeIdList || storeIdList,
				pageable: Pageable.getPageable(params),
			});

			return result;
		},
	}),
});

const useOnlineDetailInfoQuery = (oltTicketId: number) => {
	const result = useQuery({
		...onlineTicketKeys.detail({ oltTicketId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error('[GET] Online-Ticket Detail Info API Error');
	}
};

const useOnlineHistoryInfoQuery = ({ params }: { params: OnlineTicketHistoryInfoParamsType }) => {
	const storeIdList = useAtomValue(authState.storeIdListForManager);
	const result = useQuery({
		...onlineTicketKeys.history({ params, storeIdList }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error('[GET] Online-Ticket History Info API Error');
	}
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: onlineTicketKeys._def });
};

export const AdminOnlineTicketInfoState = {
	keys: onlineTicketKeys,
	useDetailInfoQuery: useOnlineDetailInfoQuery,
	useHistoryInfoQuery: useOnlineHistoryInfoQuery,
	invalidateQueries,
};
