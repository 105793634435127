import { vaunceAppColors, vaunceColors } from '@kinderlabs-pos/shared-const';
import { PaletteOptions } from '@mui/material';

export const vaunceTempPaletteOptions: PaletteOptions = {
	common: {
		black: '#000',
	},
	text: {
		primary: '#000',
	},
	primary: {
		// pink
		main: vaunceAppColors.lightPink,
		contrastText: '#FFFFFF',
	},
	secondary: {
		// mint
		main: vaunceColors.mint,
		contrastText: vaunceColors.navy,
	},
	navy: {
		main: vaunceColors.navy,
		// dark : '#b2b2b2',
		contrastText: '#FFFFFF',
	},
	paleWhite: {
		main: '#FFFFFF',
		contrastText: vaunceColors.navy,
	},
	lightGray: {
		main: vaunceAppColors.lightGray,
		contrastText: vaunceColors.navy,
	},
	darkGray: {
		//kiosk Button
		main: '#1F2026',
		contrastText: vaunceColors.navy,
	},
	error: {
		// light Gray
		main: '#D8D8E2',
	},
	info: {
		// yellow
		main: '#FCC24F',
	},
	success: {
		// navy
		main: '#282B5F',
	},
	background: {
		default: '#eee',
	},
};
