import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductBoardInfoType,
	BaseInfoType,
	KioskBoardInfoType,
	StockCanDeleteInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const canDeleteProductInfoAsync =
	(rootUrl: string) =>
	async ({ productList }: DeleteProductInfoRequestType) =>
		(
			await posAxiosClient.get<{
				posBoardInfos: Record<number, (AdminProductBoardInfoType & BaseInfoType)[]>;
				kioskBoardInfos: Record<number, (KioskBoardInfoType & BaseInfoType)[]>;
				stockInfos: Record<number, StockCanDeleteInfoType[]>;
				usingCartLine: Record<number, boolean>;
			}>(`${rootUrl}/product/can-delete`, {
				params: { idList: productList },
			})
		).data;

export type DeleteProductInfoRequestType = { productList: number[] };
