import { OrderType, PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '@kinderlabs-pos/state';
import { useCallback, useState } from 'react';

export const useHandleSubmitPayment = ({
	orderId,
	mode,
}: {
	orderId?: string;
	mode: OrderType['type'];
}) => {
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const startOrder = OrderState.actions.useCreateOrderPayment({
		orderType: mode,
	});

	const continueOrder = OrderState.actions.useContinueOrderPayment();

	const handleSubmitPayment = useCallback(
		async (paymentInfo?: PaymentRequestInfoType) => {
			setErrorMessage(undefined);
			try {
				if (orderId) {
					await continueOrder.mutateAsync({ paymentRequestData: paymentInfo, orderId: orderId });
				} else {
					await startOrder.mutateAsync({ paymentRequestData: paymentInfo });
				}
			} catch (e) {
				console.log(e);
				//@ts-ignore
				e && setErrorMessage(e.message);
			}
		},
		[orderId]
	);

	return {
		handleSubmitPayment,
		isSubmitting: startOrder.isLoading || continueOrder.isLoading,
		errorMessage: errorMessage,
	};
};
