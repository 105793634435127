import { PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState, PrintReceiptState, StoreInfoState } from '@kinderlabs-pos/state';
import { SubmitButton, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { 현금결제OptionType } from 'apps/pos-main/src/dialogs/CashReceiptDialog/CashReceiptActionStacks';
import { useState } from 'react';
import { PaymentDialog } from '../PaymentDialog';
import { 현금결제DialogContent } from './CashPayDialogContent';
import { 현금결제ConfirmDialogContent } from './CashPaymentConfirmDialogContent';

export type HandleSubmitCashPaymentArgsType = {
	paymentAmount: number;
	customerId?: string;
	isEnterprise: boolean;
	isSignPadUsed: boolean;
	cashReceiptUsed: boolean;
};
export interface ICashPayDialogProps extends XlDialogProps {
	is결산모드?: boolean;
	storeId: number;
	posId: number;
	결제할금액: number;
	handleSubmitPayment: (args: PaymentRequestInfoType) => Promise<void>;
	// submitCashPayment: (args: HandleSubmitCashPaymentArgsType) => Promise<void>;
}
export const CashPayDialog: React.FC<ICashPayDialogProps> = ({
	결제할금액,
	is결산모드,
	storeId,
	posId,
	open,
	closeDialog,
	handleSubmitPayment,
	// submitCashPayment,
}) => {
	const [confirmMode, setConfirmMode] = useState(false);
	const [receivedMoney, setReceivedMoney] = useState(0);
	const { receiptPrinterList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();

	const handleClick금전함열기 = PrintReceiptState.useSendOpenBox({
		receiptPrinterList,
		printMode,
	});

	const handle확인버튼 = () => {
		setConfirmMode(true);
		handleClick금전함열기();
	};

	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		PaymentState.actions.submit.usePaymentFormik<{
			options: 현금결제OptionType;
		}>({
			storeId,
			posId,
			onClose: closeDialog,
			initialValues: {
				options: {
					type: '현금영수증미발급',
					customerId: undefined,
					isSignPadUsed: true,
				},
			},
			validationSchema: undefined,
			handleSubmitPayment,
			getHandlePaymentArgs: (values) =>
				values.options.type === '현금영수증미발급'
					? {
							method: 'CASH_현금영수증_미발급',
							amount: 결제할금액,
							receivedMoney,
					  }
					: {
							method: 'CASH_현금영수증_발급',
							amount: 결제할금액,
							customerId: values.options.customerId,
							isSignPadUsed: values.options.isSignPadUsed,
							isEnterprise: values.options.type === '지출증빙',
							receivedMoney,
					  },
		});

	return (
		<PaymentDialog
			dialogTitle={'현금 결제'}
			open={open}
			handleSubmitPayment={handleSubmitPayment}
			onClose={closeDialog}
			failedPaymentRequest={values.failedPaymentRequestData}>
			{!confirmMode ? (
				<현금결제DialogContent
					receivedMoney={receivedMoney}
					setReceivedMoney={setReceivedMoney}
					결제할금액={결제할금액}
				/>
			) : (
				<현금결제ConfirmDialogContent
					receivedMoney={receivedMoney}
					결제할금액={결제할금액}
					options={values.options}
					values={values}
					// @ts-ignore
					setValues={setValues}
				/>
			)}
			<DialogContent>
				<Typography
					variant='subtitle1'
					color={'error'}>
					{errors.submit}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Stack
					width={'100%'}
					direction={'row'}
					justifyContent={'space-between'}>
					<Button
						variant={'outlined'}
						onClick={handleClick금전함열기}>
						금전함 열기
					</Button>
					<Stack
						direction={'row'}
						spacing={1}>
						{!confirmMode ? (
							<Button
								variant={'contained'}
								onClick={handle확인버튼}>
								확인
							</Button>
						) : (
							<SubmitButton
								isSubmitting={isSubmitting}
								variant={'contained'}
								onClick={() => handleSubmit()}>
								결제 요청
							</SubmitButton>
						)}
						{!confirmMode ? (
							<Button
								variant={'outlined'}
								onClick={closeDialog}>
								취소
							</Button>
						) : (
							<Button
								disabled={isSubmitting}
								variant={'outlined'}
								onClick={() => setConfirmMode(false)}>
								이전
							</Button>
						)}
					</Stack>
				</Stack>
			</DialogActions>
		</PaymentDialog>
	);
};
