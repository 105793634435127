import { ExchangeCouponType, PrintingStatusType } from '@kinderlabs-pos/shared-data-type';
import { atom, useAtomValue } from 'jotai';

type ExchangeCouponStatus = Record<ExchangeCouponType['id'], PrintingStatusType>;

const exchangeCouponsAtom = atom<ExchangeCouponType[], ExchangeCouponType[] | undefined>(
	[],
	(get, set, payload) => {
		const defaultPayload = payload || [];
		set(exchangeCouponsAtom, defaultPayload);

		const statusMap: ExchangeCouponStatus = {};
		defaultPayload.forEach((ddizi) => {
			statusMap[ddizi.id] = 'READY';
		});

		set(exchangeCouponsStatusAtom, statusMap);
	}
);
const exchangeCouponsStatusAtom = atom<ExchangeCouponStatus>({});

const printingExchangeCouponsAtom = atom((get) => {
	const exchangeCouponsStatusMap = get(exchangeCouponsStatusAtom);
	return get(exchangeCouponsAtom).map((exchangeCoupon) => ({
		...exchangeCoupon,
		printStatus: exchangeCouponsStatusMap[exchangeCoupon.id],
	}));
});
const setExchangeCouponsStatus = atom(
	null,
	(
		get,
		set,
		payload: {
			id: ExchangeCouponType['id'];
			printingStatus: PrintingStatusType;
		}
	) => {
		set(exchangeCouponsStatusAtom, (state) => {
			state[payload.id] = payload.printingStatus;
			return { ...state };
		});
	}
);
// const printing;

const clearExchangeCoupon = atom(null, (get, set, payload) => {
	set(exchangeCouponsAtom, []);
});

const useIsPrintingComplete = () => {
	const printingExchangeCoupons = useAtomValue(printingExchangeCouponsAtom);

	if (printingExchangeCoupons.length === 0) return true;
	return (
		Object.values(printingExchangeCoupons).findIndex(
			(ddizi) => ddizi.printStatus !== 'COMPLETE'
		) === -1
	);
};

export const ExchangeCouponPrintingState = {
	value: exchangeCouponsAtom,
	changeStatus: setExchangeCouponsStatus,
	printing: printingExchangeCouponsAtom,
	useIsPrintingComplete,
	clear: clearExchangeCoupon,
};
