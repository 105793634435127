import {
	Column,
	ColumnDef,
	TableOptions,
	createTable,
	getCoreRowModel,
} from '@tanstack/react-table';
import { getExcelData, getHeaderRows } from './excelUtils';
import { printExcelDataFromReactTable } from './printExcelFromReactTable';
import { CursorPageType } from '@kinderlabs-pos/shared-data-type';
import { useState } from 'react';

export class MuiReactTableExcelDownload<TData> {
	private headerRows: any[];
	private dataRows: any[] = [];
	private state: TableOptions<TData>['state'];
	private columns: ColumnDef<TData, any>[] = [];

	constructor(tableOption: Pick<TableOptions<TData>, 'columns' | 'data' | 'state'>) {
		this.state = { ...tableOption.state, columnPinning: {} };
		this.columns = tableOption.columns;

		const table = createTable({
			columns: this.columns,
			data: tableOption.data,
			state: this.state,
			getCoreRowModel: getCoreRowModel(),
			onStateChange: () => {},
			renderFallbackValue: () => {},
		});

		this.headerRows = getHeaderRows(table);
		this.dataRows = this.dataRows.concat(getExcelData(table));
	}

	addData(tableOption: Pick<TableOptions<TData>, 'data'>) {
		const table = createTable({
			columns: this.columns,
			data: tableOption.data,
			state: this.state!,
			getCoreRowModel: getCoreRowModel(),
			onStateChange: () => {},
			renderFallbackValue: () => {},
		});

		this.dataRows = this.dataRows.concat(getExcelData(table));
	}

	download = (fileName: string) => {
		printExcelDataFromReactTable({
			headerRows: this.headerRows,
			data: this.dataRows,
			footers: [],
			fileName,
		});
	};
}

export const useDownloadWithInfiniteQuery = <TData,>({
	getInfiniteList,
	columns,
	state,
	fileName,
}: {
	getInfiniteList: (
		cursorId: CursorPageType<TData>['startCursorId']
	) => Promise<CursorPageType<TData>>;
	columns: TableOptions<TData>['columns'];
	state: TableOptions<TData>['state'];
	fileName: string;
}) => {
	const [downloading, setDownloading] = useState(false);

	const handleClickDownload전체Excel = async () => {
		let cursorId: CursorPageType<TData>['startCursorId'] = null;
		let hasMore = true;

		try {
			setDownloading(true);

			// INITIAL SETUP
			const result = await getInfiniteList(null);
			cursorId = result.startCursorId;
			hasMore = result.hasMore;

			const excelDownloader = new MuiReactTableExcelDownload<TData>({
				data: result.content,
				columns,
				state,
			});

			while (hasMore) {
				const result = await getInfiniteList(cursorId);
				cursorId = result.startCursorId;
				hasMore = result.hasMore;

				excelDownloader.addData({
					data: result.content,
				});
			}

			excelDownloader.download(fileName);
		} finally {
			setDownloading(false);
		}
	};

	return { handleClickDownload전체Excel, downloading };
};
