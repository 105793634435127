import { VaultCashCheckTypeValue, VaultCashHistoryType } from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat } from '@kinderlabs-pos/shared-util';
import { VaultCashState } from '@kinderlabs-pos/state';
import { Box, Stack, Typography } from '@mui/material';

export const VaultCashHistoryDetailContents = ({
	historyContent,
}: {
	historyContent: VaultCashHistoryType;
}) => {
	return (
		<Stack direction='column'>
			<Box sx={{ borderTop: 1, padding: 1, borderColor: '#BEBEBE' }}>
				<Typography
					variant='subtitle1'
					textAlign='center'>
					[상세 내역]
				</Typography>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>점검 시각</Typography>
					<Typography variant='subtitle1'>
						{getDateTimeFormat(historyContent.createdDate)}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>점검 항목</Typography>
					<Typography variant='subtitle1'>
						{historyContent ? VaultCashCheckTypeValue[historyContent.type] : ''}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>입력금액</Typography>
					<Typography variant='subtitle1'>
						{VaultCashState.utils.getCountedAmount(historyContent)}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>기대금액</Typography>
					<Typography variant='subtitle1'>
						{VaultCashState.utils.getExpectedAmount(historyContent)}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>과부족</Typography>
					<Typography variant='subtitle1'>
						{VaultCashState.utils.getOverAndShort(historyContent)}
					</Typography>
				</Stack>
				<Stack
					direction='row'
					justifyContent='space-between'>
					<Typography variant='subtitle1'>메모</Typography>
					<Typography variant='subtitle1'>
						{historyContent?.memo ? historyContent?.memo : '내용 없음'}
					</Typography>
				</Stack>
			</Box>
			<Box sx={{ borderTop: 1, borderBottom: 1, padding: 1, borderColor: '#BEBEBE' }}>
				<Typography
					variant='subtitle1'
					textAlign='center'>
					[입력금액 상세표]
				</Typography>
				{historyContent ? (
					<>
						{Object.entries(historyContent.cashUnits).map((content) => (
							<Stack
								direction='row'
								justifyContent='space-between'>
								<Typography variant='subtitle1'>
									{parseInt(content[0].slice(4)).toLocaleString()} 원
								</Typography>
								<Typography variant='subtitle1'>{content[1]} 장</Typography>
							</Stack>
						))}
					</>
				) : (
					<></>
				)}
			</Box>
		</Stack>
	);
};
