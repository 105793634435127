import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, CmtInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateCmtInfoAsync =
	(rootUrl: string) => async (updateRequestedCmtInfo: UpdateCmtInfoRequestType) =>
		(
			await posAxiosClient.put<CmtInfoType & BaseInfoType>(
				`${rootUrl}/cmt`,
				{
					...updateRequestedCmtInfo,
				},
				{
					params: {
						id: updateRequestedCmtInfo.id,
					},
				}
			)
		).data;

export type UpdateCmtInfoRequestType = CmtInfoType;
