import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { DialogProps, Stack, Typography } from '@mui/material';
import { FormattedDialog } from '../../components/FormattedDialog';
import { useVaunceAppRouter } from '../routes';
export interface IConfirmCouponDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
	couponState: string;
}
export const ConfirmCouponDialog: React.FC<IConfirmCouponDialogProps> = ({
	open,
	onClose,
	couponState,
}) => {
	const { navigateCouponList } = useVaunceAppRouter();
	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack spacing={3}>
				<Stack alignItems={'center'}>
					<Typography variant='h4'>{couponState}</Typography>
				</Stack>

				<Stack
					direction={'row'}
					spacing={2}
					pb={3}>
					<RoundedButton
						sx={{
							flex: 1,
							color: vaunceColors.lightPink,
							bgcolor: 'white',
							height: 38,
							fontWeight: 400,
							fontSize: 16,
						}}
						onClick={onClose}>
						추가등록
					</RoundedButton>
					<RoundedButton
						sx={{ flex: 1, height: 38, fontWeight: 400, fontSize: 16 }}
						onClick={() => {
							onClose();
							navigateCouponList();
						}}>
						내 쿠폰 확인
					</RoundedButton>
				</Stack>
			</Stack>
		</FormattedDialog>
	);
};
