import { AdminStoreInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { ManagerUserTypeRule, StoreInfoType } from '@kinderlabs-pos/shared-data-type';
import { AdminStoreInfoState, authState } from '@kinderlabs-pos/state';
import { InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

export const StoreSelectStack = ({
	selectedStore,
	selectStore,
}: {
	selectedStore?: StoreInfoType;
	selectStore: (storeInfo?: StoreInfoType) => void;
}) => {
	const [storeList, setStoreList] = useState<StoreInfoType[]>([]);
	const user = useAtomValue(authState.user);
	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
	const allStoreList = data ?? [];

	useEffect(() => {
		setStoreList(
			ManagerUserTypeRule.getStoreList({ allStoreInfoList: allStoreList, user: user || undefined })
		);
		selectStore(undefined);
	}, [user, allStoreList]);

	return (
		<Stack>
			<InputLabel>{'매장'}</InputLabel>
			<Select
				fullWidth
				value={selectedStore?.id}
				onChange={(e) => {
					selectStore(storeList.find((storeInfo) => storeInfo.id === e.target.value));
				}}>
				{storeList.map((storeInfo, idx) => (
					<MenuItem
						key={idx}
						value={storeInfo.id}>
						{storeInfo.name}
					</MenuItem>
				))}
			</Select>
		</Stack>
	);
};
