import { BaseInfoType, PosInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosInfoAsync =
	(rootUrl: string) => async (updatePosInfoRequestInfo: UpdatePosInfoRequestType) =>
		(
			await posAxiosClient.put<PosInfoType & BaseInfoType>(
				`${rootUrl}/store/pos`,
				{
					...updatePosInfoRequestInfo,
				},
				{
					params: {
						id: updatePosInfoRequestInfo.id,
					},
				}
			)
		).data;

export type UpdatePosInfoRequestType = Omit<PosInfoType, 'status' | 'deviceType'>;
