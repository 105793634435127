import { EventSubscriberProvider } from '@kinderlabs-pos/apis/common';
import {
	DomainEventType,
	GuestVisitHistoryType,
	GuestVisitType,
} from '@kinderlabs-pos/shared-data-type';
import { GuestVisitQueryState, OrderState, PosState } from '@kinderlabs-pos/state';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const GuestVisitSubscriber = ({
	storeId,
	posId,
	enterpriseId,
	children,
}: React.PropsWithChildren<{ storeId: number; posId: number; enterpriseId: string }>) => {
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const setRecentVisitIdSetCalledByOtherPos = useSetAtom(
		GuestVisitQueryState.recentVisitIdSetCalledByOtherPos
	);
	const setStatus = useSetAtom(PosState.status);
	const onConnect = () => {
		setStatus((state) => ({ ...state, sseConnected: true }));
	};

	const onDisconnect = () => {
		setStatus((state) => ({ ...state, sseConnected: false }));
	};

	const onGuestVisitRegister = useCallback((eventData: DomainEventType<GuestVisitType>) => {
		GuestVisitQueryState.invalidateQueries();
	}, []);

	const onGuestVisitCall = useCallback(
		(eventData: DomainEventType<GuestVisitType>) => {
			const data = eventData.source;
			if (guestMemberInfo?.memberId === data.id) return;

			if (
				data.calls &&
				data.calls.length > 0 &&
				data.calls[data.calls.length - 1].posId !== posId
			) {
				setRecentVisitIdSetCalledByOtherPos((old) => [...old, data.id]);

				GuestVisitQueryState.invalidateQueries();
			}
		},
		[guestMemberInfo]
	);

	const onGuestVisitHistory = useCallback((eventData: DomainEventType<GuestVisitHistoryType>) => {
		GuestVisitQueryState.invalidateQueries();
	}, []);

	return (
		<EventSubscriberProvider
			storeId={storeId}
			enterpriseId={enterpriseId}
			deviceType={'POS'}
			subscriberId={posId}
			onConnect={onConnect}
			onReconnect={() => GuestVisitQueryState.invalidateQueries()}
			onDisconnect={onDisconnect}
			guestVisitActions={{
				onGuestVisitCall: onGuestVisitCall,
				onGuestVisitRegister: onGuestVisitRegister,
				onGuestVisitHistory: onGuestVisitHistory,
			}}
			kitchenOrderActions={undefined}>
			{children}
		</EventSubscriberProvider>
	);
};
