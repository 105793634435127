import { OrderDetailType, VaunceMemberInfoType } from '@kinderlabs-pos/shared-data-type';
import { OrderState, SearchMemberState } from '@kinderlabs-pos/state';

import { useAtomValue } from 'jotai';
import { ImmediateAcc } from './ImmediateAcc';
import { PostAcc } from './PostAcc';
import { useQuery } from '@tanstack/react-query';

type PointAccType = 'immediate' | 'post';
export const PointAcc = ({
	mode,
	orderDetail,
}: {
	mode: PointAccType;
	orderDetail?: OrderDetailType;
}) => {
	const order = useAtomValue(OrderState.value);

	const { data } = useQuery({
		...SearchMemberState.keys.getByUserId(
			mode === 'immediate' ? order.pointMemberUserId : orderDetail?.pointMemberUserId
		),
	});

	const userMemberInfo = data ?? null;

	const changePointUserMemberId = OrderState.actions.useChangePointUserMemberId();
	const pointEarned = !!userMemberInfo;
	const selectMemberInfo = (memberInfo?: VaunceMemberInfoType) => {
		if (!memberInfo) return;
		switch (mode) {
			case 'immediate':
				if (!order.id) {
					return;
				}
				changePointUserMemberId.mutateAsync({
					orderId: order.id,
					memberUserId: memberInfo.userId,
				});
				break;
			case 'post':
				if (!orderDetail || orderDetail.pointProcessed) {
					console.log('날짜지남');
					return;
				}
				changePointUserMemberId.mutateAsync({
					orderId: orderDetail.id,
					memberUserId: memberInfo.userId,
				});
				break;
		}
	};

	return (
		<>
			{mode === 'immediate' && (
				<ImmediateAcc
					userMemberInfo={userMemberInfo}
					order={order}
					selectMemberInfo={selectMemberInfo}
					pointEarned={pointEarned}
				/>
			)}
			{mode === 'post' && orderDetail && (
				<PostAcc
					orderDetail={orderDetail}
					userMemberInfo={userMemberInfo}
					selectMemberInfo={selectMemberInfo}
					pointEarned={pointEarned}
				/>
			)}
		</>
	);
};
