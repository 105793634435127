import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ActionBoard, IActionBoardProps } from '@kinderlabs-pos/ui-components';
import { posMainTheme } from '@kinderlabs-pos/ui/themes';
import { ThemeProvider, useTheme } from '@mui/material';
import { BoardPageType, getPosBoardItemsFilterByPage } from './getPosBoardPage';
import { PosBoardItem } from './PosBoardItem';
import { PosBoardBaseItem } from './PosBoardItem/PosBoardBaseItem';
import { PosBoardLabel } from './PosBoardLabel';

type PosBoardPropsType = Omit<IActionBoardProps, 'numOfColumns'> & {
	page: BoardPageType;
	setPage?: (page: BoardPageType) => void;
};
export const PosBoard = ({ page, setPage, children, ...props }: PosBoardPropsType) => {
	const theme = useTheme();

	return (
		<ThemeProvider theme={posMainTheme}>
			<ActionBoard
				numOfColumns={5}
				{...props}>
				{[
					...children,
					<PosBoardBaseItem
						key={'prior'}
						CardActionProps={{
							sx: { bgcolor: theme.palette.primary.main },
							disabled: setPage ? page === 0 : true,
							onClick: setPage ? () => setPage((page - 1) as BoardPageType) : undefined,
						}}>
						<LeftOutlined style={{ fontSize: 24 }} />
					</PosBoardBaseItem>,
					<PosBoardBaseItem
						key={'next'}
						CardActionProps={{
							sx: { bgcolor: theme.palette.primary.main },
							disabled: setPage ? page === 2 : true,
							onClick: setPage ? () => setPage((page + 1) as BoardPageType) : undefined,
						}}>
						<RightOutlined style={{ fontSize: 24 }} />
					</PosBoardBaseItem>,
				]}
			</ActionBoard>
		</ThemeProvider>
	);
};

PosBoard.Item = PosBoardItem;
PosBoard.Label = PosBoardLabel;
PosBoard.getPosBoardItemsFilterByPage = getPosBoardItemsFilterByPage;
PosBoard.ITEM_LENTH_PER_PAGE = 23;

export type PosBoardSelectableEmptyItem<T> = {
	idx: number;
	item: T;
	selected: boolean;
	selectable: boolean;
	selectItemIdx: React.Dispatch<React.SetStateAction<number | null>>;
};
