import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

import { PosInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateAutomationOptionAsync =
	(rootUrl: string) => async (info: UpdateAutomationOptionType) =>
		(
			await posAxiosClient.put<UpdateAutomationOptionType>(
				`${rootUrl}/store/pos/auto-option`,
				{
					...info,
				},
				{
					params: {},
				}
			)
		).data;

export type UpdateAutomationOptionType = Pick<PosInfoType, 'id' | 'storeId' | 'receiptAutoPrint'>;
