import { SnackbarProvider, useSnackbar } from "notistack";
import { useBarcodeScanner } from "../../hooks/useBarcodeScanner";
import { OrderAction } from "./OrderAction";
import { TicketAction } from "./TicketAction";

export interface IWithBarcodeScannerProps {
}
export const WithBarcodeScanner: React.FC<React.PropsWithChildren<IWithBarcodeScannerProps>> = ({
  children
}) => {

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      // autoHideDuration={20000}
      autoHideDuration={8000}
      Components={{
        ticket: TicketAction,
        order: OrderAction
      }}>
      {/* <BarcodeScanHandler /> */}
      {children}
    </SnackbarProvider>
  )
};

const BarcodeScanHandler = ({ }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useBarcodeScanner((e) => {
    if (e[0] === "8") {
      // test 용
      enqueueSnackbar({ variant: 'order', orderId: e })
      return;
    }
    enqueueSnackbar({ variant: 'ticket', ticketId: e })
    console.log(e)
  })
  return (
    <>
    </>
  )
};