import { getMapFromList } from '@kinderlabs-pos/shared-util';
import { CartType } from './Order/Cart';
import { calculateVAT } from './statistics/StatisticsType';

export type CartAggregateType = {
	totalSum: number; // 결제 합계
	totalDiscount: number; // 할인 합계
	totalToPay: number; // 총 결제해야할 금액
	totalTax: number;
	totalTaxFreePay: number; // 총 결제해야할 면세 금액
	ticketCount: number; // 총 티켓 수
	// totalPaid: number, // 총 결제된 금액
};

export type PaymentAggregateType = {
	totalPaid: number; // 총 결제된 금액
	remainingPay: number; // 총 결제된 금액
};

export const getAggregate = (
	cart: CartType
): CartAggregateType & {
	discountCount: number;
	ticketCount: number;
	exchangeCount: number;
} => {
	const totalSum = cart.lines.reduce(
		(arr, cl, idx) => arr + (cl.price > 0 ? cl.price : 0) * cl.quantity,
		0
	);

	const totalDiscount = cart.lines.reduce(
		(arr, cl, idx) => arr - (cl.price < 0 ? cl.price : 0) * cl.quantity,
		0
	);

	const totalTaxFreePay = cart.lines
		.filter((cl) => cl.productAdditionalInfo.taxFree)
		.reduce((arr, cl, idx) => arr + cl.price * cl.quantity, 0);

	const totalToPay = totalSum - totalDiscount;
	const totalTax = Math.round(
		calculateVAT({ revenue: totalToPay - totalTaxFreePay, taxFree: false })
	);

	return {
		totalSum,
		totalToPay,
		totalTaxFreePay,
		totalTax,
		totalDiscount: totalDiscount,
		discountCount: cart.lines
			.filter((cl) => !!cl.productDiscountInfo)
			.reduce<number>((arr, cl, idx) => arr + cl.quantity, 0),
		ticketCount: cart.lines
			.filter((cl) => cl.productInfo.ddiziInfo)
			.reduce<number>((arr, cl, idx) => arr + cl.quantity, 0),
		exchangeCount: cart.lines
			.filter((cl) => cl.productInfo.exchangeInfo)
			.reduce<number>((arr, cl, idx) => arr + cl.quantity, 0),
	};
};
