import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceAppColors, vaunceColors } from '@kinderlabs-pos/shared-const';
import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { replaceAll } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import RoomIcon from '@mui/icons-material/Room';
import { Box, Skeleton, Stack, styled, Typography } from '@mui/material';
import selectHeartImage from 'assets/vaunce/image/detailstar.png';
import heartImage from 'assets/vaunce/image/nonestar.png';
import starImage from 'assets/vaunce/image/starpoint.png';

import selectHeartImage2x from 'assets/vaunce/image/detailstar@2x.png';
import heartImage2x from 'assets/vaunce/image/nonestar@2x.png';
import starImage2x from 'assets/vaunce/image/starpoint@2x.png';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { loggingUserCenter } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';
import { TelePhoneDialog } from './TelePhoneDialog';
import { useAtom } from 'jotai';
import { CenterDetailData } from '../../dummyData';
import { ImageRowBox } from '../ReservationPage';
import Carousel from 'react-material-ui-carousel';
import { favoritCenterLog } from '../../utils/TechLabsLogUtils';

export interface ICenterDetailPageProps {}
export const CenterDetailPage: React.FC<ICenterDetailPageProps> = ({}) => {
	const isLoginedUser: boolean = localStorage.getItem('accessToken') ? true : false;
	const { id } = useParams();
	const centerID = id ? +id : -1;

	const [tab, setTab] = useAtom(VaunceState.selectNoticeTabIdx);
	const [centerId, setCenterId] = useAtom(VaunceState.selectCenterId);

	const {
		navigateNoticeAndAlarm,
		navigateCenterLocation,
		navigateReservation,
		navigateCenterDescription,
	} = useVaunceAppRouter();

	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	const centerData = centerCollectionData.find((data) => data.centerId === centerID);

	const centerImageList = CenterDetailData.find((data) => data.centerId === centerID)?.imageList;

	const [isWishList, setIsWishList] = useState<boolean>(false);

	async function fetchData() {
		if (id) {
			const data = await VaunceAppApis.getCheckWishListAsync(Number(id));
			if (data) {
				setIsWishList(data.favorite);
			}
		}
	}
	useEffect(() => {
		fetchData();
	}, []);

	const toggleWishList = VaunceQueryState.useToggleWishList();

	const handleToggleWishList = (curCenterId: number, isFavorite: boolean) => {
		toggleWishList.mutate({ curCenterId, isFavorite });

		if (toggleWishList) {
			alert(isWishList ? '찜리스트 등록 해제했습니다.' : '찜리스트 등록 성공했습니다.');

			if (!isWishList && centerData) {
				loggingUserCenter(centerData?.centerDisplay);
				favoritCenterLog(centerData.centerId);
			}
			setIsWishList(!isWishList);
		}
	};

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader
				title='센터상세'
				navigateAlterUrl={navigateCenterDescription}
			/>
			{id !== undefined && centerData && (
				<Box overflow={'auto'}>
					<CenterTitleBox>
						<TitleBox>
							<Typography
								marginRight={'auto'}
								variant='h3'>
								{centerData.centerDisplay} {centerData.centerId === 50 && '(폐점)'}
							</Typography>
							<img
								src={isWishList ? selectHeartImage : heartImage}
								srcSet={isWishList ? selectHeartImage2x : heartImage2x}
								onClick={async () => {
									isLoginedUser
										? handleToggleWishList(+id as number, isWishList)
										: alert('찜리스트 등록을 할 수 없습니다.');
								}}
								width={24}
								height={24}
							/>
						</TitleBox>
						<DescriptionBox
							onClick={() => {
								setTab(1);
								setCenterId(centerID);
								navigateNoticeAndAlarm();
							}}>
							<Typography
								color={'primary'}
								variant='h6'
								fontWeight={400}>
								센터 공지사항 보기
							</Typography>
							<ChevronRightIcon sx={{ color: vaunceColors.lightPink }} />
						</DescriptionBox>
					</CenterTitleBox>

					{centerImageList && (
						<Carousel
							autoPlay={false}
							indicators={true}>
							{centerImageList.map((img, index) => (
								<Stack
									justifyContent='center'
									direction='row'
									height={170}
									width={310}
									sx={{
										overflow: 'hidden',
										margin: '0 auto',
									}}>
									<img
										fetchPriority={index === 0 ? 'high' : 'low'}
										src={img}
										decoding='async'
										// loading='lazy'
										// width='100%'
										// height={170}
										alt='center image loading'
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											borderRadius: '12px',
											boxShadow: '0 0 20px rgb(0 0 0 / 16%)',
										}}
									/>
								</Stack>
							))}
						</Carousel>
					)}

					<Stack spacing={2}>
						<CenterInfoSection centerData={centerData} />

						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={1}
							justifyContent={'flex-start'}>
							<img
								src={starImage}
								srcSet={starImage2x}
								height={18}
								width={18}
							/>
							<Typography
								variant='h6'
								fontWeight={400}>
								아래 서비스를 클릭하여 편리한 바운스를 즐겨보세요.
							</Typography>
						</Stack>

						<ServiceBox>
							<ServiceInnerBox>
								<TelePhoneDialogBtn centerData={centerData} />

								<Typography
									variant='h6'
									fontWeight={400}>
									전화 걸기
								</Typography>
							</ServiceInnerBox>

							<ServiceInnerBox>
								<RoomIcon
									sx={{ color: vaunceColors.lightPink, cursor: 'pointer' }}
									fontSize={'large'}
									onClick={() => {
										navigateCenterLocation(centerID);
									}}
								/>
								<Typography
									variant='h6'
									fontWeight={400}>
									위치 보기
								</Typography>
							</ServiceInnerBox>

							<ServiceInnerBox>
								<CelebrationIcon
									sx={{ color: vaunceColors.lightPink, cursor: 'pointer' }}
									fontSize={'large'}
									onClick={() => {
										navigateReservation();
									}}
								/>
								<Typography
									variant='h6'
									fontWeight={400}>
									파티/단체
								</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									예약
								</Typography>
							</ServiceInnerBox>
						</ServiceBox>

						<GuidSection centerData={centerData} />
					</Stack>
				</Box>
			)}
		</Stack>
	);
};

const CenterInfoSection: React.FC<{ centerData: CenterCollectionsType }> = ({ centerData }) => {
	return (
		<>
			<Typography variant='h3'>센터정보</Typography>
			<Stack
				direction={'row'}
				spacing={1}>
				<Typography
					color={'primary'}
					variant='h5'
					fontWeight={500}
					flex={1}>
					주소
				</Typography>
				<Typography
					variant='h5'
					flex={4}>
					{centerData.address}
				</Typography>
			</Stack>
			<Stack
				direction={'row'}
				spacing={1}>
				<Typography
					flex={1}
					color={'primary'}
					variant='h5'
					fontWeight={500}>
					전화번호
				</Typography>
				<Typography
					flex={4}
					variant='h5'>
					{centerData.centerTel}
				</Typography>
			</Stack>
			<Stack
				direction={'row'}
				spacing={1}>
				<>
					<Typography
						flex={1}
						color={'primary'}
						variant='h5'
						fontWeight={500}>
						운영시간
					</Typography>
					<Box flex={4}>
						{centerData.operTime && (
							<Typography
								variant='h5'
								dangerouslySetInnerHTML={{
									__html: replaceAll(centerData.operTime, '\r\n', '<br/>'),
								}}
							/>
						)}
					</Box>
				</>
			</Stack>

			<Stack
				direction={'row'}
				spacing={1}>
				<>
					<Typography
						flex={1}
						color={'primary'}
						variant='h5'
						fontWeight={500}>
						이용제한
					</Typography>
					<Box flex={4}>
						{centerData.restrictionInfo && (
							<Typography
								variant='h5'
								dangerouslySetInnerHTML={{
									__html: replaceAll(centerData.restrictionInfo, '\r\n', '<br/>'),
								}}
							/>
						)}
					</Box>
				</>
			</Stack>
		</>
	);
};

const GuidSection: React.FC<{ centerData: CenterCollectionsType }> = ({ centerData }) => {
	return (
		<Stack direction={'row'}>
			<Typography
				width={'25%'}
				color={'primary'}
				variant='h5'
				fontWeight={500}>
				오시는 길
			</Typography>
			{centerData.wayToCome && (
				<Typography
					variant='h5'
					dangerouslySetInnerHTML={{
						__html: replaceAll(centerData.wayToCome, '\r\n', '<br/>'),
					}}
				/>
			)}
		</Stack>
	);
};

interface TelePhoneDialogBtnProps {
	centerData: any;
}

export const TelePhoneDialogBtn: React.FC<TelePhoneDialogBtnProps> = ({ centerData }) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<TelePhoneDialog
					open={open}
					onClose={closeDialog}
					centerData={centerData}
				/>
			)}>
			{(openDialog) => (
				<Box onClick={openDialog}>
					<PhoneInTalkIcon
						sx={{ color: vaunceColors.lightPink, cursor: 'pointer' }}
						fontSize={'large'}
					/>
				</Box>
			)}
		</WithDialog>
	);
};
const CenterTitleBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#F4F4F4',
	borderRadius: '12px',
	padding: '15px',
	marginTop: '20px',
	marginBottom: ' 20px',
}));

const TitleBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	marginBottom: '0.5rem',
}));

const DescriptionBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	color: `${vaunceColors.lightPink}`,
	cursor: 'pointer',
}));

const ServiceBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	textAlign: 'center',
	marginBottom: '1.5rem',
}));

const ServiceInnerBox = styled(Box)(({ theme }) => ({
	flex: '0 0 auto',
	width: '33.3%',
}));

const GuideBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
}));
