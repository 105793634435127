import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { useEffect, useState } from 'react';

export const useElectronAutoUpdate = () => {
	const [updateAvailable, setUpdateAvailable] = useState(false);
	useEffect(() => {
		ReactCommonCommandIPC.registerUpdateAvailableListener((updateAvailable) =>
			setUpdateAvailable(updateAvailable)
		);

		ReactCommonCommandIPC.checkForUpdate();

		return () => {
			ReactCommonCommandIPC.unregisterUpdateAvailableListener();
		};
	}, []);

	return { updateAvailable };
};
