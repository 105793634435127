import { loginAsync } from './loginAsync';
import { rootUrl } from '../../../../pos/src/const';
import { signupAsync } from './signupAsync';
import { logoutAsync } from './logoutAsync';
import { verifyAsync } from './verifyAsync';
import { reissuePassword } from './reissuePassword';
import { modifyPassword } from './modifyPassword';

export const AuthApis = {
	login: loginAsync(rootUrl),
	signup: signupAsync(rootUrl),
	logout: logoutAsync(rootUrl),
	verify: verifyAsync(rootUrl),
	reissueTokenAsync: verifyAsync(rootUrl),
	reissuePasssword: reissuePassword(rootUrl),
	modifyPassword: modifyPassword(rootUrl),
};
