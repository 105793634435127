import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import qs from 'qs';

export const deleteProductInfoListAsync =
	(rootUrl: string) =>
	async ({ productList }: DeleteProductInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/product/list`, {
				params: {
					idList: productList,
				},
			})
		).data;

export type DeleteProductInfoListRequestType = { productList: number[] };
