import { OrderStatusType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const downloadOrderExcel =
	(rootUrl: string) =>
	({
		posIdList,
		storeIdList,
		from,
		to,
		search,
		status,
		pageable,
		dataOnPage = false,
	}: OrderExcelRequestType) => {
		const url = new URL(`${rootUrl}/excel/order/${dataOnPage ? 'page' : 'all'}`);

		if (posIdList) url.searchParams.append('posIdList', `${posIdList}`);
		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (from) url.searchParams.append('from', from.format('YYYY-MM-DD HH:mm:ss'));
		if (to) url.searchParams.append('to', to.format('YYYY-MM-DD HH:mm:ss'));
		if (search) url.searchParams.append('search', search);
		if (status) url.searchParams.append('status', status);
		if (pageable.page) url.searchParams.append('page', `${pageable.page}`);
		if (pageable.size) url.searchParams.append('size', `${pageable.size}`);
		if (pageable.sort)
			url.searchParams.append('sort', `${pageable.sort.name},${pageable.sort.direction}`);

		return `${url}`;
	};

type OrderExcelRequestType = {
	dataOnPage?: boolean;
	storeIdList?: number[];
	posIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	search?: string;
	status?: OrderStatusType;
	pageable: Pageable;
};
