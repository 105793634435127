import { ArrowDownOutlined, ArrowUpOutlined, LineOutlined } from '@ant-design/icons';
import {
	Box,
	Checkbox,
	lighten,
	Stack,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Column, flexRender, OnChangeFn, Row, RowData, SortingState } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { CSSProperties, useMemo } from 'react';
import { CustomTable } from '../types';
import { NewMuiTablePagination } from './NewMuiTablePagination';

export const NewMuiTables = <TData,>({
	table,
	setSorting,
	onScroll,
	hasCheckbox,
	withoutPagination,
	onlyPaginationNumbers,
}: {
	table: CustomTable<TData>;
	setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
	onScroll?: React.UIEventHandler<HTMLDivElement>;
	hasCheckbox?: boolean;
	withoutPagination?: boolean;
	onlyPaginationNumbers?: boolean;
}) => {
	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const { rows } = table.getRowModel();
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
		getScrollElement: () => tableContainerRef.current,
		//measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 5,
	});

	//scroll to top of table when sorting changes
	const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
		setSorting && setSorting(updater);
		if (table.getRowModel().rows.length) {
			rowVirtualizer.scrollToIndex?.(0);
		}
	};

	//since this table option is derived from table row model state, we're using the table.setOptions utility
	table.setOptions((prev) => ({
		...prev,
		onSortingChange: handleSortingChange,
	}));
	const hasFooter = useMemo(
		() => table.getFooterGroups().some((fg) => fg.headers.some((h) => h.column.columnDef.footer)),
		[table]
	);

	return (
		<StyledTableContainer
			leftColumnPinning={
				table.getAllColumns().filter((c) => c.getIsPinned() === 'left').length > 0
					? table.getAllColumns().filter((c) => c.getIsPinned() === 'left').length +
					  (hasCheckbox ? 1 : 0)
					: 0
			}
			rightColumnPinning={table.getAllColumns().filter((c) => c.getIsPinned() === 'right').length}
			className='container'
			onScroll={onScroll}
			ref={tableContainerRef}
			sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto' }}
			// style={{
			//   overflow: 'auto', //our scrollable table container
			//   position: 'relative', //needed for sticky header
			//   height: '600px', //should be a fixed height
			// }}
		>
			{/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
			<Table
				sx={{ position: 'relative', overflow: 'auto', maxHeight: '100%' }}
				size='small'>
				<TableHead
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 1,
					}}>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow
							key={headerGroup.id}
							style={{ display: 'flex', width: '100%' }}>
							{hasCheckbox && (
								<TableCell
									style={{
										position: 'sticky',
										width: 60,
										borderBottom: '1px solid white',
									}}>
									{table.options.enableMultiRowSelection && (
										<Checkbox
											checked={table.getIsAllRowsSelected()}
											indeterminate={table.getIsSomeRowsSelected()}
											onChange={table.getToggleAllRowsSelectedHandler()}
										/>
									)}
								</TableCell>
							)}
							{headerGroup.headers.map((header) => {
								const canSort = header.column.getCanSort();

								// let rowSpan = 1;
								// if (header.isPlaceholder) {
								// 	const leafs = header.getLeafHeaders();

								// 	console.log(`aaa`, header.id, header.depth, leafs);
								// 	rowSpan = leafs[leafs.length - 1].depth - header.depth;
								// }

								// console.log(header.id, header.isPlaceholder, columnRelativeDepth, rowSpan);

								// if (
								// 	!header.isPlaceholder &&
								// 	columnRelativeDepth > 1 &&
								// 	header.id === header.column.id
								// ) {
								// 	return null;
								// }
								return (
									<TableCell
										className={!header.isPlaceholder ? 'head-line' : ''}
										onClick={header.column.getToggleSortingHandler()}
										key={header.id}
										colSpan={header.colSpan}
										// rowSpan={rowSpan}
										style={{
											cursor: canSort ? 'pointer' : undefined,
											...getCommonPinningStyles(header.column),
											minWidth: header.getSize(),
											flex: header.getSize(),
										}}>
										{!header.isPlaceholder && (
											<Stack
												direction={'row'}
												spacing={1}
												alignItems={'center'}>
												<Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
												{canSort && header.column.getIsSorted() === 'asc' && <ArrowUpOutlined />}
												{canSort && header.column.getIsSorted() === 'desc' && <ArrowDownOutlined />}
												{canSort && header.column.getIsSorted() === false && <LineOutlined />}
											</Stack>
										)}
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableHead>
				<TableBody
					style={{
						display: 'grid',
						height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
						position: 'relative', //needed for absolute positioning of rows
					}}>
					{rowVirtualizer.getVirtualItems().map((virtualRow) => {
						const row = rows[virtualRow.index] as Row<TData>;
						const selected = row.getIsSelected();

						return (
							<TableRow
								onClick={row.getCanSelect() ? row.getToggleSelectedHandler() : undefined}
								data-index={virtualRow.index} //needed for dynamic row height measurement
								className={`${row.getCanExpand() && row.depth === 0 ? 'can-expand' : ''} ${
									selected ? 'selected' : ''
								} ${table.options.meta?.getRowColor ? table.options.meta.getRowColor(row) : ''}
                `}
								ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
								key={row.id}
								style={{
									display: 'flex',
									position: 'absolute',
									transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
									width: '100%',
								}}>
								{hasCheckbox && (
									<TableCell
										style={{
											position: 'sticky',
											width: 60,
											justifyContent: 'center',
										}}>
										<Checkbox
											checked={row.getIsSelected()}
											onClick={row.getToggleSelectedHandler()}
										/>
									</TableCell>
								)}
								{row.getVisibleCells().map((cell) => {
									return (
										<TableCell
											key={cell.id}
											style={{
												display: 'flex',
												...getCommonPinningStyles(cell.column),
												minWidth: cell.column.getSize(),
												flex: cell.column.getSize(),
											}}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
				{hasFooter && (
					<TableFooter
						style={{
							position: 'sticky',
							bottom: 0,
							zIndex: 1,
						}}>
						{true &&
							table.getFooterGroups().map((footerGroup, footerGroupIdx) => {
								if (footerGroupIdx !== 0) return null;
								return (
									<TableRow
										style={{
											display: 'flex',
											width: '100%',
										}}
										key={footerGroup.id}>
										{hasCheckbox && (
											<TableCell
												style={{
													position: 'sticky',
													width: 60,
												}}
											/>
										)}
										{footerGroup.headers.map((header, index) => {
											return (
												<TableCell
													key={header.id}
													style={{
														flexDirection: 'row',
														display: 'flex',
														...getCommonPinningStyles(header.column),
														minWidth: header.getSize(),
														flex: header.getSize(),
													}}>
													{!header.column.columnDef.footer
														? null
														: flexRender(header.column.columnDef.footer, header.getContext())}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
					</TableFooter>
				)}
			</Table>

			{!withoutPagination && table.options.getPaginationRowModel && (
				<Stack
					style={{
						width: '100%',
						position: 'sticky',
						bottom: 0,
						left: 0,
						zIndex: 1,
						backgroundColor: 'white',
					}}>
					<NewMuiTablePagination
						table={table}
						onlyPaginationNumbers={onlyPaginationNumbers}
					/>
				</Stack>
			)}
		</StyledTableContainer>
	);
};

const getCommonPinningStyles = <TData extends RowData>(column: Column<TData>): CSSProperties => {
	const isPinned = column.getIsPinned();

	return {
		// left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
		left: isPinned === 'left' ? `${0}px` : undefined,
		// right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
		right: isPinned === 'right' ? `${0}px` : undefined,
		opacity: isPinned ? 0.95 : 1,
		position: isPinned ? 'sticky' : 'relative',
		// width: column.getSize(),
		zIndex: isPinned ? 1 : 0,
		flexDirection: isPinned ? 'row-reverse' : 'row',
	};
};

const StyledTableContainer = styled(TableContainer, {
	shouldForwardProp: (props) => props !== 'leftColumnPinning' && props !== 'rightColumnPinning',
})<{ leftColumnPinning: number; rightColumnPinning: number }>(
	({ theme, leftColumnPinning, rightColumnPinning }) => ({
		// '& .MuiTable-root': {
		// 	pb: 2,
		// },
		'& .MuiTableCell-root': {
			backgroundColor: theme.palette.background.paper,
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root': {
			backgroundColor: grey[300],
			borderRight: '1px solid white',
			justifyContent: 'center',
			'&.head-line': {
				borderBottom: '1px solid white',
			},
		},
		'& .MuiTableBody-root': {
			...(leftColumnPinning && {
				[`& .MuiTableCell-root:nth-of-type(${leftColumnPinning})`]: {
					borderRight: `2px solid ${grey[300]}`,
				},
			}),
			...(rightColumnPinning && {
				[`& .MuiTableCell-root:nth-last-of-type(${rightColumnPinning})`]: {
					borderLeft: `2px solid ${grey[300]}`,
				},
			}),
			'& > .MuiTableRow-root > .MuiTableCell-root': {
				justifyContent: 'flex-end',
			},
			'& > .MuiTableRow-root.error > .MuiTableCell-root': {
				backgroundColor: lighten(theme.palette.error.main, 0.8),
			},
			'& > .MuiTableRow-root.success > .MuiTableCell-root': {
				backgroundColor: lighten(theme.palette.success.main, 0.8),
			},
			'& > .MuiTableRow-root.warning > .MuiTableCell-root': {
				backgroundColor: lighten(theme.palette.warning.main, 0.8),
			},
			'& > .MuiTableRow-root.can-expand > .MuiTableCell-root': {
				backgroundColor: lighten(theme.palette.primary.main, 0.95),
			},
			'& > .MuiTableRow-root.selected > .MuiTableCell-root': {
				backgroundColor: lighten(theme.palette.primary.main, 0.95),
			},
		},
		'& .MuiTableFooter-root > .MuiTableRow-root > .MuiTableCell-root': {
			backgroundColor: lighten(theme.palette.primary.main, 0.85),
			borderRight: '1px solid white',
			justifyContent: 'flex-end',
		},
	})
);
