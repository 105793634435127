import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VaultCashStatusDetailType } from '@kinderlabs-pos/shared-data-type';

export const getStoreStatusAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<VaultCashStatusDetailType[] | null>(`${rootUrl}/vault-cash/status`, {
				params: {
					storeId,
				},
			})
		).data;
