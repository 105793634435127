import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogActions, DialogContent } from '@mui/material';
import { CartDiscountContent } from './contents/CartDiscountContent';
import { OrderState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';

interface CartDiscountDialogProps extends XlDialogProps {}
export const CartDiscountDialog = ({ open, closeDialog }: CartDiscountDialogProps) => {
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);

	return (
		<WithDialog.XlDialog
			dialogTitle={'상품 할인'}
			open={open}
			closeDialog={closeDialog}>
			<DialogContent>
				<CartDiscountContent
					remainingValue={remainingPay}
					closeDialog={closeDialog}
				/>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
