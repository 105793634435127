import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { WarehouseInfoType } from '@kinderlabs-pos/shared-data-type';

export const getWarehouseInfoAsync = (rootUrl: string) => async (warehouseId: number) =>
	(
		await posAxiosClient.get<WarehouseInfoType>(`${rootUrl}/warehouse`, {
			params: {
				warehouseId,
			},
		})
	).data;
