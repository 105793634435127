import { VisitStatisticsWithStoreType } from '@kinderlabs-pos/shared-data-type';
import { useCalculateTime } from '@kinderlabs-pos/shared-react-util';
import {
	getShortDateFormatWithSlash,
	getTimeFormatWithoutSecond,
} from '@kinderlabs-pos/shared-util';
import { authState, StatisticsState, StoreInfoState } from '@kinderlabs-pos/state';
import { Box, Divider, Stack, Toolbar, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

const defaultStoreStatisticsInfo: Omit<VisitStatisticsWithStoreType, 'storeId' | 'storeName'> = {
	nowWaiting: 0,

	nowUsing: 0,
	nowParentGuests: 0,
	nowNormalGuests: 0,

	todayUsing: 0,
	todayParentGuests: 0,
	todayNormalGuests: 0,
};

const today = dayjs();

export const PosSubToolbar = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
	const loginedUser = useAtomValue(authState.user);

	const { value } = useCalculateTime();

	const { data } = useQuery({
		...StatisticsState.keys.gvDetail({
			params: {
				from: today,
				to: today,
				storeIdList: [storeInfo.id],
			},
		}),
		refetchOnWindowFocus: true,
		refetchInterval: 1000 * 10,
	});

	const gvDetailData = data ?? [];

	const staticsInStore = gvDetailData.find((data) => data.storeId === storeInfo.id) || {
		storeId: storeInfo.id,
		...defaultStoreStatisticsInfo,
	};

	return (
		<Toolbar
			variant='dense'
			sx={(theme) => ({
				display: 'flex',
				'& .MuiTypography-root': {
					fontSize: theme.typography.body2.fontSize,
					color: 'black',
				},
			})}>
			<Stack
				width={'100%'}
				direction={'row'}
				divider={
					<Divider
						orientation='vertical'
						flexItem
					/>
				}
				spacing={1}>
				<Typography>{`${enterpriseInfo?.name} ${storeInfo?.name}`}</Typography>
				<Typography>{`유저 : ${loginedUser?.name || ''}`}</Typography>
				{/* <Typography>{`대기 중인 팀 : ${waitingGuestVisits.length}`}</Typography> */}
				<Box flex={1} />
				<Typography>{`영업일자 : ${value && getShortDateFormatWithSlash(value)} ${
					value && getTimeFormatWithoutSecond(value)
				}`}</Typography>
				<Typography>{`현재 체류 중 : ${staticsInStore.nowUsing} (이용자 ${staticsInStore.nowNormalGuests} / 보호자 ${staticsInStore.nowParentGuests})`}</Typography>
				<Typography>{`당일 총 이용 : ${staticsInStore.todayUsing} (이용자 ${staticsInStore.todayNormalGuests} / 보호자 ${staticsInStore.todayParentGuests})`}</Typography>
			</Stack>
		</Toolbar>
	);
};
