import {
	CartLineCustomDiscountInfoType,
	CartLineCustomProductInfoType,
	CartLineInfoType,
	DiscountTypeUnitLabel,
	ProductDiscountInfoType,
	defaultProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import { CartLineState } from './CartLineBoardState';
import { findRealTargetCartLineForDiscount, getRealTargetName } from './cartLineDiscountCommon';

type CustomProductInfoType = {
	name: string;
	price: number;
};
type CustomProductDiscountInfoType = {
	name: string;
	value: ProductDiscountInfoType['value'];
	type: ProductDiscountInfoType['type'];
};

export type CartLineCustomActionType =
	| {
			type: 'ADD';
			cartLineId: string;
			productInfo: CustomProductInfoType;
	  }
	| {
			type: 'ADD_CUSTOM_DISCOUNT';
			cartLineId: string;
			productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
			quantity: number;
			targetPrice: number;
			targetCartLineId: string;
			taxFreeTarget: boolean;
	  }
	| {
			type: 'DELETE';
			cartLineId: string;
	  }
	| { type: 'CLEAR' }
	| { type: 'CLEAR_DISCOUNTS' };

const cartLineCustomReducer = (
	prev: CartLineInfoType[],
	action: CartLineCustomActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD':
			// new Date 체크할 것
			// return action.ddizi ? [{ ...createCartLineInput({ ddizi: action.ddizi }), quantity: 1 }] : [];
			return [
				...prev,
				createCustomCartLineInput({
					cartLineId: action.cartLineId,
					productInfo: action.productInfo,
				}),
			];
		case 'ADD_CUSTOM_DISCOUNT':
			const {
				타겟이옵션이냐,
				targetCartLine,
				targetOfTargetCartLine: realTargetCartLine,
			} = findRealTargetCartLineForDiscount({
				cartLines: prev,
				targetCartLineId: action.targetCartLineId,
			});

			if (타겟이옵션이냐) {
				return [
					...prev,
					createDiscountCartLineInput({
						productDiscountInfo: action.productDiscountInfo,
						quantity: action.quantity,
						targetName: targetCartLine.name,
						cartLineId: action.cartLineId,
						targetPrice: action.targetPrice,
						targetCartLineId: realTargetCartLine.id,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			} else {
				return [
					...prev,
					createDiscountCartLineInput({
						productDiscountInfo: action.productDiscountInfo,
						quantity: action.quantity,
						cartLineId: action.cartLineId,
						targetPrice: action.targetPrice,
						targetCartLineId: action.targetCartLineId,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			}
		case 'DELETE':
			return prev.filter((cl) => cl.id !== action.cartLineId);
		case 'CLEAR':
			return [];
		case 'CLEAR_DISCOUNTS':
			return prev.filter((cl) => !CartLineState.utils.isDiscountItem(cl));
	}
};

export const CartLineCustomState = {
	reducer: cartLineCustomReducer,
};

const createCustomCartLineInput = (props: {
	cartLineId: string;
	productInfo: CustomProductInfoType;
}): CartLineCustomProductInfoType => {
	const { productInfo } = props;
	return {
		id: props.cartLineId,
		type: 'CUSTOM',
		productInfoId: undefined,
		name: productInfo.name,
		price: productInfo.price,
		productInfo: {
			category: '임의 상품',
			isForKitchen: false,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		targetCartLineId: undefined,
		quantity: 1,
	};
};
const createDiscountCartLineInput = ({
	productDiscountInfo,
	quantity,
	cartLineId,
	targetName,
	targetPrice,
	targetCartLineId,
	taxFreeTarget,
}: {
	productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	quantity: number;
	cartLineId: string;
	targetName?: string;
	targetPrice: number;
	targetCartLineId: string;
	taxFreeTarget: boolean;
}): CartLineCustomDiscountInfoType => {
	return {
		id: cartLineId,
		type: 'CUSTOM_DISCOUNT',
		productInfoId: undefined,
		price: -CartLineState.utils.getUnitDiscount({
			price: targetPrice,
			productDiscountInfo,
		}),
		name: getRealTargetName({ discountName: '임의 할인', targetName, productDiscountInfo }),
		productInfo: {
			category: '임의 할인',
			isForKitchen: false,
		},
		productAdditionalInfo: { rawPrice: 0, supplyPrice: 0, taxFree: taxFreeTarget },
		productDiscountInfo: productDiscountInfo,
		targetCartLineId: targetCartLineId,
		quantity: quantity,
	};
};
