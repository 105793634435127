import {
	PaymentMethodFilterType,
	calculateVAT,
	결제수단별매출paymentAndCardRevenueDetailInfo,
	매출통계카드사별매출PaymentAndCardRevenueInfo,
} from '@kinderlabs-pos/shared-data-type';
import { StatisticsState, authState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';

export const CardCompanyStatisticsData = {
	useData: ({ filter }: { filter: PaymentMethodFilterType }) => {
		if (filter.dateType === 'TIME') throw Error;
		const storeIdList = useAtomValue(authState.storeIdListForManager);

		const { data: result, ...others } = useQuery({
			...StatisticsState.keys.salesByCardCompany({
				params: {
					...(filter.dateType === 'MONTH'
						? {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }
						: {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }),
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		const resultData = useMemo(() => result?.paymentAndCardRevenueList ?? [], [result]);
		return {
			...others,
			data: resultData,
		};
	},
	useAdminColumns: ({ X2List }: { X2List: 결제수단별매출paymentAndCardRevenueDetailInfo[] }) => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return useMemo<ColumnDef<매출통계카드사별매출PaymentAndCardRevenueInfo>[]>(
			() => [
				{
					accessorKey: 'verticalAxis',
					header: '카드 종류',
					size: 120,
					footer: '총 합',
				},
				{
					accessorKey: 'revenueSum',
					header: '카드사별 매출',
					size: 240,
					...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
				},
				{
					accessorKey: 'paymentCountSum',
					header: '건 수',
					size: 100,
					...ColumnDefsHelper.get건수ColumnDef('paymentCountSum'),
				},
				...(enterpriseInfo?.id === 'vaunce'
					? ([
							{
								id: 'vat',
								accessorFn: (row) => calculateVAT({ revenue: row.revenueSum, taxFree: false }),
								header: 'VAT',
								size: 240,
								...ColumnDefsHelper.get금액ColumnDef('vat'),
							},
							{
								id: 'revenueSumWithoutVat',
								accessorFn: (row) =>
									row.revenueSum - calculateVAT({ revenue: row.revenueSum, taxFree: false }),
								header: '매출 (VAT 제외)',
								size: 240,
								...ColumnDefsHelper.get금액ColumnDef('revenueSumWithoutVat'),
							},
					  ] as ColumnDef<매출통계카드사별매출PaymentAndCardRevenueInfo>[])
					: []),
				...X2List.map((x2, x2Index) => ({
					header: `${x2.horizontalAxis}`,
					id: `${x2.horizontalAxis}`,
					columns: [
						{
							header: `결제`,
							id: `${x2.horizontalAxis}_revenue`,
							size: 140,
							accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].revenue,
							...ColumnDefsHelper.get금액ColumnDef(`${x2.horizontalAxis}_revenue`),
						},
						{
							header: `취소`,
							id: `${x2.horizontalAxis}_refundAmount`,
							size: 140,
							accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].refundAmount,
							...ColumnDefsHelper.get금액ColumnDef(`${x2.horizontalAxis}_refundAmount`),
						},
					] as ColumnDef<매출통계카드사별매출PaymentAndCardRevenueInfo>[],
				})),
			],
			[enterpriseInfo, X2List]
		);
	},
	usePosColumns: () => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return useMemo<ColumnDef<매출통계카드사별매출PaymentAndCardRevenueInfo>[]>(
			() => [
				{
					accessorKey: 'verticalAxis',
					header: '카드 종류',
					size: 120,
					footer: '총 합',
				},
				{
					accessorKey: 'revenueSum',
					header: '카드사별 매출',
					size: 240,
					...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
				},
				{
					accessorKey: 'paymentCountSum',
					header: '건 수',
					size: 100,
					...ColumnDefsHelper.get건수ColumnDef('paymentCountSum'),
				},
			],
			[]
		);
	},
};
