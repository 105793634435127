import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deletePosInfoListAsync =
	(rootUrl: string) =>
	async ({ posIdList }: DeletePosInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/store/pos/list`, {
				params: {
					idList: posIdList,
				},
			})
		).data;

export type DeletePosInfoListRequestType = { posIdList: number[] };
