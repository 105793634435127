import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Divider, Stack, Typography } from '@mui/material';
import infoImage from 'assets/vaunce/image/infofolder.png';
import { useAtomValue } from 'jotai';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';
import { LoginPageHeader } from './LoginPageHeader';

export interface IFoundPagerops {}

export const FounddIdPage: React.FC<IFoundPagerops> = ({}) => {
	const { navigateLogin, navigateFindPwdStepOne } = useVaunceAppRouter();
	const findUserInfo = useAtomValue(VaunceState.findUserInfo);
	const isSnsMode = () => {
		const signUpTypeRegex = /(애플|네이버|카카오)/;
		return findUserInfo.findId && signUpTypeRegex.test(findUserInfo.findId);
	};
	return (
		<Stack direction={'column'}>
			<LoginPageHeader />

			<LoginHeaderTypograpy>SIGN UP</LoginHeaderTypograpy>
			<Stack
				textAlign={'center'}
				alignItems={'center'}
				spacing={3}>
				<img
					src={infoImage}
					height={68}
					width={68}
				/>
				<Box>
					<Typography
						variant='h5'
						data-cy='foundedUserName'>
						{findUserInfo.userName} 님,
					</Typography>
					<Typography variant='h5'>이미 바운스 회원으로 등록되어 있습니다.</Typography>
				</Box>

				<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />

				<Stack
					direction={'row'}
					spacing={2}>
					<Typography variant='h5'>가입 아이디 </Typography>
					<Typography
						variant='h5'
						color={'primary'}>
						{findUserInfo.findId}
					</Typography>
				</Stack>

				<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />

				<Stack
					direction={'row'}
					spacing={2}
					width='100%'>
					{!isSnsMode() && (
						<RoundedButton
							data-cy='findPwBtn'
							sx={{ height: 58, flex: 1 }}
							onClick={() => {
								navigateFindPwdStepOne();
								loggingEvent('아이디 찾기 > 비밀번호 찾기', 'login', 'findPWInIdPage');
							}}>
							<Typography
								variant='h4'
								fontWeight={400}>
								비밀번호 찾기
							</Typography>
						</RoundedButton>
					)}

					<RoundedButton
						data-cy='loginRouteBtnInFoundId'
						sx={{
							height: 58,
							flex: 1,
							bgcolor: '#FFF',
							color: `${vaunceColors.lightPink}`,
							border: `1px solid ${vaunceColors.lightPink}`,
							fontFamily: 'Lato',
						}}
						onClick={() => navigateLogin()}>
						<Typography
							variant='h4'
							fontWeight={400}
							fontFamily={'Lato'}
							textTransform={'none'}>
							Log in
						</Typography>
					</RoundedButton>
				</Stack>
			</Stack>
		</Stack>
	);
};
