import { AdminPosInfoState } from '@kinderlabs-pos/state';
import { SelectStringListTableFilter } from '../table-filter/SelectStringListTableFilter';

export const PosAndKioskSelector = ({
	storeId,
	setPosAndKioskIdList,
	posAndKioskIdList,
}: {
	storeId?: number;
	setPosAndKioskIdList: (idList?: number[]) => void;
	posAndKioskIdList?: number[];
}) => {
	const posAndKioskInfoList = AdminPosInfoState.usePosAndKioskSelectorList({ storeId });

	return (
		<SelectStringListTableFilter
			// selectedStore 가 하나일때만 동작한다.
			disabled={!storeId}
			displayEmptyLabel={'모든 포스/키오스크'}
			displayEmpty
			maxLength={2}
			handleChange={(value) => setPosAndKioskIdList(value)}
			items={posAndKioskInfoList}
			values={posAndKioskIdList}
			sx={{ width: '100%' }}
		/>
	);
};
