import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	PosInfoType,
	PosStatusDetailedType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { authState, DeviceConnectState } from '@kinderlabs-pos/state';
import { IconSquareButton } from '@kinderlabs-pos/ui-atoms';
import { RotatingRefreshButton } from '@kinderlabs-pos/ui-components';
import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';

import { atomWithReset, useResetAtom } from 'jotai/utils';
import { BackgroundPosPaper } from '../PosLoginPage';
import { usePosRouter } from '../routes';
import { PosGoToMainPageButton } from './PosGoToMainPageButton';
import { PosSelectList } from './PosSelectList';
import { SalesCloseCancelButton } from './SalesCloseCancelButton';
import { getDetailedVaultCashStateText } from './StatusGuideText';
import { StoreSelectStack } from './StoreSelectStack';
import { VaultcashCheckDialogButton } from './VaultcashCheckDialogButton';

// const selectedStoreAtom = atomWithReset<StoreInfoType | undefined>(undefined);
// const selectedPosAtom = atomWithReset<PosInfoType | undefined>(undefined);

const selectedStoreAndPosAtom = atomWithReset<{
	selectedStoreInfo?: StoreInfoType;
	selectedPosInfo?: PosInfoType;
}>({
	selectedStoreInfo: undefined,
	selectedPosInfo: undefined,
});

export interface ISelectStorePageProps {}
export const SelectStorePage: React.FC<ISelectStorePageProps> = ({}) => {
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
	// 스토어, 포스 관련
	const { selectedStoreInfo, selectedPosInfo } = useAtomValue(selectedStoreAndPosAtom);
	const setSelectedStoreInfoAndPosInfo = useSetAtom(selectedStoreAndPosAtom);
	const selectStoreInfo = (storeInfo?: StoreInfoType) => {
		setSelectedStoreInfoAndPosInfo((prev) => ({
			selectedStoreInfo: storeInfo,
			selectedPosInfo: undefined,
		}));
	};

	const selectPosInfo = (posInfo?: PosInfoType) => {
		setSelectedStoreInfoAndPosInfo((prev) => ({ ...prev, selectedPosInfo: posInfo }));
	};

	const resetStoreAndPos = useResetAtom(selectedStoreAndPosAtom);

	const { navigateToLogin } = usePosRouter();

	const [posState, setPosState] = useState<PosStatusDetailedType | undefined>();

	const handleClickReload = () => {
		DeviceConnectState.invalidateQueries();
	};

	useEffect(() => {
		return () => {
			resetStoreAndPos();
		};
	}, []);

	const disable시재점검체크 =
		selectedPosInfo?.posType === 'WEBPOS' ||
		!selectedPosInfo ||
		posState === 'alreadyClosed' ||
		posState === 'alreadyOpened';

	return (
		<BackgroundPosPaper
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<Card sx={{ position: 'relative', width: 520, height: 520 }}>
				<CardContent sx={{ height: '100%' }}>
					<Stack
						spacing={3}
						height={'100%'}>
						<Stack
							sx={{ position: 'relative' }}
							direction={'row'}
							alignItems={'center'}>
							<IconSquareButton onClick={() => navigateToLogin()}>
								<ArrowLeftOutlined />
							</IconSquareButton>
							<Typography
								variant={'h4'}
								sx={{ flex: 1, textAlign: 'center' }}>
								{`${enterpriseInfo?.name} 매장 및 POS 선택`}
							</Typography>
							<RotatingRefreshButton handleClick={handleClickReload} />
						</Stack>
						<StoreSelectStack
							selectStore={selectStoreInfo}
							selectedStore={selectedStoreInfo}
						/>
						<Stack
							flex={1}
							sx={{ overflowY: 'auto' }}>
							<Suspense>
								{selectedStoreInfo && (
									<PosSelectList
										selectedPos={selectedPosInfo}
										selectPos={selectPosInfo}
										selectedStore={selectedStoreInfo}
										setPosState={setPosState}
									/>
								)}
							</Suspense>
						</Stack>
						<Stack
							direction={'row'}
							spacing={1}
							alignItems={'center'}>
							<TextField
								size='small'
								variant='outlined'
								sx={{ flex: 1 }}
								InputProps={{
									readOnly: true,
									sx: {
										color: `${
											posState === 'needToBeClosed' || posState === 'alreadyClosed'
												? 'red'
												: 'black'
										}`,
									},
								}}
								value={getDetailedVaultCashStateText({
									posState,
									posType: selectedPosInfo?.posType,
								})}
							/>
							<VaultcashCheckDialogButton
								store={selectedStoreInfo}
								pos={selectedPosInfo}
								openOrClose={posState === 'needToBeClosed' ? 'CLOSE' : 'OPEN'}
								buttonDisabled={disable시재점검체크}
								setPosState={setPosState}
							/>
						</Stack>
						<Suspense>
							{selectedPosInfo && posState === 'alreadyClosed' ? (
								<SalesCloseCancelButton
									posInfo={selectedPosInfo}
									selectPosInfo={selectPosInfo}
								/>
							) : (
								<PosGoToMainPageButton
									posState={posState}
									selectedPosInfoId={selectedPosInfo?.id}
									selectedStoreInfo={selectedStoreInfo}
								/>
							)}
						</Suspense>
					</Stack>
				</CardContent>
			</Card>
		</BackgroundPosPaper>
	);
};
