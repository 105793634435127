import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { PointBodyType, PointHistoryType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { EmptyContent } from '../../components/EmptyContent';
export type PointPeriod = 'sixmonths' | 'oneyear' | 'all';

export interface IPointSvePanelProps {}
const PointSvePanel: React.FC<IPointSvePanelProps> = ({}) => {
	const sixMonthPoint = VaunceQueryState.usePointQuery('sixmonths');

	const [period, setPeriod] = useState<PointPeriod>('sixmonths');
	const [pointData, setPointData] = useState<PointBodyType[]>(sixMonthPoint);

	const handleClick = async (event: React.MouseEvent<HTMLElement>, newPeriod: PointPeriod) => {
		if (newPeriod !== null) {
			setPeriod(newPeriod);
		}
		const data = await VaunceAppApis.getPointAsync(newPeriod);
		if (data) {
			setPointData(data.vo);
		}
	};

	//3 : 적립 취소, 2: 사용, 1: 적립
	function isHighlightPoint(pointData: PointBodyType): boolean {
		return pointData.type === 'USE';
	}

	function getPointType(type: PointHistoryType): string {
		switch (type) {
			case 'REWARD':
				return '적립';
			case 'USE':
				return '사용';
			case 'DEDUCT':
				return '적립 취소';
			case 'EXPIRE':
				return '만료';
			default:
				return '';
		}
	}
	return (
		<Stack
			flex={1}
			overflow={'inherit'}
			spacing={2}>
			<Stack
				direction={'row'}
				alignItems={'center'}>
				<Typography
					variant='h5'
					sx={{ flex: 1 }}>
					조회 기간 :
				</Typography>
				<ToggleButtonGroup
					size='small'
					value={period}
					fullWidth
					exclusive
					onChange={handleClick}
					sx={{ flex: 3 }}>
					<ToggleButton
						color='primary'
						value={'sixmonths'}>
						{'6개월'}
					</ToggleButton>
					<ToggleButton
						color='primary'
						value={'oneyear'}>
						{'1년'}
					</ToggleButton>
					<ToggleButton
						color='primary'
						value={'all'}>
						{'전체'}
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>
			<Stack
				flex={1}
				overflow={'inherit'}>
				{pointData.length !== 0 ? (
					pointData.map((pointData, idx) => (
						<PointInnerBox key={idx}>
							<Typography
								flex={1}
								variant='h5'
								color={isHighlightPoint(pointData) ? 'primary' : 'text'}>
								{pointData.date}
							</Typography>

							<Typography
								flex={1}
								variant='h5'
								color={isHighlightPoint(pointData) ? 'primary' : 'text'}>
								{getPointType(pointData.type)}
							</Typography>

							<Typography
								flex={1}
								variant='h5'
								color={isHighlightPoint(pointData) ? 'primary' : 'text'}>
								{numberWithCommas(pointData.point)}
							</Typography>

							<Typography
								flex={2}
								variant='h5'
								color={isHighlightPoint(pointData) ? 'primary' : 'text'}>
								{pointData.storeDisplay}
							</Typography>
						</PointInnerBox>
					))
				) : (
					<EmptyContent title={'포인트 적립/사용 내역이 없습니다.'} />
				)}
			</Stack>
		</Stack>
	);
};
export default PointSvePanel;

const PointInnerBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
	padding: '14px 0',
	textAlign: 'center',
	justifyContent: 'space-around',
}));

/*


      


*/
