import {
	CartLineDiscountInfoType,
	CartLineInfoType,
	CartLineProductInfoType,
	CartLineProductOptionInfoType,
	CartLineTypeRules,
	OptionType,
	ProductDiscountInfoType,
	ProductInfoType,
	ProductTypeLabel,
	defaultProductAdditionalInfo,
	getProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import produce from 'immer';
import { CartLineState, getTargetCartLineFromOption } from '../CartLineBoardState';
import { adjustTargetCartLineForOneQuantity } from '../cartLineOptionCommon';

export type CartLineProductActionType =
	| {
			type: 'ADD';
			cartLineId: string;
			productInfo: ProductInfoType;
			options?: ProductInfoType[];
			imageUrl?: string; // 키오스크만 사용
			onComplete?: (cartLine?: CartLineInfoType) => void;
	  }
	| {
			type: 'ADD_OPTION';
			cartLineId: string;
			targetCartLineId: string;
			productInfo: ProductInfoType;
			onComplete?: (cartLine?: CartLineInfoType) => void;
	  }
	// 아직 ADD_DISCOUNT 는 개발 안했음
	| {
			type: 'ADD_DISCOUNT';
			discountName: string;
			discountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
			quantity: number;
			targetPrice: number;
			targetCartLineId: string;
			taxFreeTarget: boolean;
	  }
	| {
			type: 'ADD_SET';
			cartLineId: string;
			productInfo: ProductInfoType;
			selectedOptions: (OptionType & {
				isForKitchen: boolean;
			})[];
			imageUrl?: string; // 키오스크만 사용
			onComplete?: (cartLine?: CartLineInfoType) => void;
	  }
	| {
			type: 'CHANGE_PRICE';
			cartLineId: string;
			targetPrice: number;
			onComplete?: () => void;
	  };
const findTargetCartlinesWithUniqueKey = (
	cartLines: CartLineInfoType[],
	targetCartLine: CartLineProductInfoType
) => {
	const targetCartLineUniqueKey = CartLineTypeRules.getCartLineInfoTypeUniqueKey(targetCartLine);

	const alreadyExistProductCartLines = cartLines.filter(
		(cartLine) =>
			cartLine.type === 'PRODUCT' &&
			CartLineTypeRules.getCartLineInfoTypeUniqueKey(cartLine) === targetCartLineUniqueKey
	);

	return alreadyExistProductCartLines;
};

/**
 * 같은 Discount 성격인지 확인필요
 */
const findTargetCartlineWithSameDiscount = (
	cartLines: CartLineInfoType[],
	targetCartLines: CartLineInfoType[],
	inputDiscountInfo?: Pick<ProductDiscountInfoType, 'name' | 'value' | 'type'>
) => {
	const discountCartlines = cartLines.filter((cl) => cl.type === 'DISCOUNT');
	return targetCartLines.filter((cl) => {
		const targetCartLineId = cl.id;

		// 각 카트라인마다 먼저 discount 가 있었는지 검사
		const alreadyDiscountedCartline = discountCartlines.find(
			(discountCl) => discountCl.targetCartLineId === targetCartLineId
		);

		// 없었다면 inputDiscountInfo 도 없어야 한다.
		if (!alreadyDiscountedCartline) return !inputDiscountInfo;
		// 있었다면 inputDiscountInfo 가 같아야 한다.
		else {
			return (
				inputDiscountInfo &&
				isSameDiscountCartLine(alreadyDiscountedCartline, {
					targetCartLineId,
					targetDiscountInfo: inputDiscountInfo,
				})
			);
		}
	});
};

const isSameDiscountCartLine = (
	cartLine: CartLineInfoType,
	{
		targetCartLineId,
		targetDiscountInfo,
	}: {
		targetCartLineId: CartLineInfoType['id'];
		targetDiscountInfo: Pick<ProductDiscountInfoType, 'name' | 'value' | 'type'>;
	}
) => {
	return (
		cartLine.type === 'DISCOUNT' &&
		cartLine.targetCartLineId === targetCartLineId &&
		cartLine.name === targetDiscountInfo.name &&
		cartLine.productDiscountInfo?.type === targetDiscountInfo.type &&
		cartLine.productDiscountInfo?.value === targetDiscountInfo.value
	);
};

const cartLineProductReducer = (
	prev: CartLineInfoType[],
	action: CartLineProductActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD': {
			let resultCartLine: CartLineInfoType | undefined = undefined;

			const result = produce(prev, (draft) => {
				const inputCartLine = createProductCartLineInput({
					cartLineId: action.cartLineId,
					productInfo: action.productInfo,
					imageUrl: action.imageUrl,
				});

				const alreadyAddedCartLines = findTargetCartlineWithSameDiscount(
					draft,
					findTargetCartlinesWithUniqueKey(draft, inputCartLine),
					undefined
				);

				// 기존에 추가가 되어있으면서 옵션이 없는 cartLine 하나 찾기
				// 옵션이 있다면 어짜피 새로 만들어야 함
				const alreadyAddedCartLineWithoutOptions = alreadyAddedCartLines.find(
					// 하나라도 옵션이 있으면 안된다는 로직
					(alreadyCl) =>
						!draft.some((cl) => alreadyCl.id == cl.targetCartLineId && cl.type === 'OPTION')
				);

				if (alreadyAddedCartLineWithoutOptions) {
					const idx = draft.findIndex((cl) => cl.id === alreadyAddedCartLineWithoutOptions?.id);

					draft[idx].quantity++;

					resultCartLine = JSON.parse(JSON.stringify(draft[idx]));

					return draft;
				} else {
					// 이미 추가된 "옵션없는 카트라인" 이 없지만, "옵션 있는 카트라인" 은 있다면 ID 를 새로 발급해서 옵션이 다른 카트라인이 되도록 한다.
					resultCartLine =
						alreadyAddedCartLines.length > 0
							? {
									...inputCartLine,
									id: getUuidV4(),
							  }
							: inputCartLine;

					return [...draft, resultCartLine];
				}
			});

			if (resultCartLine === undefined) throw Error();

			const resultToReturn = JSON.parse(JSON.stringify(result));

			// 옵션이 만약 있다면 더 붙여주자
			action.options?.forEach((option) => {
				const optionCartLine = createOptionCartLineInput({
					cartLineId: getUuidV4(),
					productInfo: option,
					//@ts-ignore
					targetCartLineId: resultCartLine.id,
				});

				resultToReturn.push(optionCartLine);
			});

			action.onComplete && action.onComplete(resultCartLine);

			return resultToReturn;
		}
		case 'ADD_OPTION': {
			const { targetCartLine } = getTargetCartLineFromOption({
				cartLines: prev,
				targetCartLineId: action.targetCartLineId,
			});

			const {
				cartLines: newCartLines,
				targetCartLine: newTargetCartLine,
				targetCartLineIndex: newTargetCartLineIndex,
			} = adjustTargetCartLineForOneQuantity(prev, targetCartLine.id);

			return produce(newCartLines, (draft) => {
				const inputCartLine = createOptionCartLineInput({
					cartLineId: action.cartLineId,
					productInfo: action.productInfo,
					targetCartLineId: newTargetCartLine.id,
				});

				// 맨뒤에 옵션 추가
				const alreadyExistOptionLength = draft.filter(
					(cl) => cl.targetCartLineId === newTargetCartLine.id
				).length;

				draft.splice(newTargetCartLineIndex + 1 + alreadyExistOptionLength, 0, inputCartLine);

				action.onComplete && action.onComplete(newTargetCartLine);
			});
		}
		case 'ADD_DISCOUNT': {
			return [
				...prev,
				createDiscountCartLineInput({
					discountName: action.discountName,
					discountInfo: action.discountInfo,
					quantity: action.quantity,
					targetPrice: action.targetPrice,
					targetCartLineId: action.targetCartLineId,
					taxFreeTarget: action.taxFreeTarget,
				}),
			];
		}
		case 'ADD_SET': {
			const newCartLinesWithOptions = createSetCartLineInputList({
				cartLineId: action.cartLineId,
				productInfo: action.productInfo,
				selectedOptions: action.selectedOptions,
				imageUrl: action.imageUrl,
			});

			action.onComplete && action.onComplete(newCartLinesWithOptions[0]);

			return [...prev, ...newCartLinesWithOptions];
		}
		case 'CHANGE_PRICE': {
			const changePriceResult = prev.map((cartLine) =>
				cartLine.id === action.cartLineId
					? {
							...cartLine,
							price: action.targetPrice,
					  }
					: cartLine
			);

			action.onComplete && action.onComplete();

			return changePriceResult;
		}
	}
};

const createProductCartLineInput = ({
	cartLineId,
	productInfo,
	imageUrl,
}: {
	cartLineId: string;
	productInfo: ProductInfoType;
	imageUrl?: string; // KIOSK 전용
}): CartLineProductInfoType => {
	return {
		id: cartLineId,
		type: productInfo.type === 'SET' ? 'SET_PRODUCT' : 'PRODUCT',
		name: productInfo.name,
		price: productInfo.price,
		productInfoId: productInfo.id,
		productInfo: {
			category: ProductTypeLabel[productInfo.type],
			isForKitchen: productInfo.isForKitchen || productInfo.type === 'SET',

			ddiziInfo: productInfo.ddiziInfo,
			exchangeInfo: productInfo.exchangeInfo,
		},
		productAdditionalInfo: getProductAdditionalInfo(productInfo),
		// productDiscountInfo: productDiscountInfo
		// 	? {
		// 			...productDiscountInfo,
		// 			evaluatedValue: CartLineState.utils.getUnitDiscount({
		// 				price: productInfo.price,
		// 				productDiscountInfo: productDiscountInfo,
		// 			}),
		// 	  }
		// 	: undefined,
		// ticketUsageId: undefined,
		targetCartLineId: undefined,
		quantity: 1,
		imageUrl,
		i18n: productInfo.i18nName,
	};
};

const createOptionCartLineInput = ({
	cartLineId,
	productInfo,
	targetCartLineId,
}: {
	cartLineId: string;
	productInfo: ProductInfoType;
	targetCartLineId: string;
}): CartLineProductOptionInfoType => {
	return {
		id: cartLineId,
		type: 'OPTION',
		name: productInfo.name,
		price: productInfo.price,
		productInfoId: productInfo.id,
		productInfo: {
			category: ProductTypeLabel[productInfo.type],
			isForKitchen: productInfo.isForKitchen,

			ddiziInfo: productInfo.ddiziInfo,
			exchangeInfo: productInfo.exchangeInfo,
		},
		productAdditionalInfo: getProductAdditionalInfo(productInfo),
		ticketUsageId: undefined,
		targetCartLineId: targetCartLineId,
		quantity: 1,

		i18n: productInfo.i18nName,
	};
};

const createDiscountCartLineInput = ({
	discountName,
	discountInfo,
	quantity,
	targetPrice,
	targetCartLineId,
	taxFreeTarget,
}: {
	discountName: string;
	discountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	quantity: number;
	targetPrice: number;
	targetCartLineId: string;
	taxFreeTarget: boolean;
}): CartLineDiscountInfoType => {
	return {
		id: getDiscountCartLineId(targetCartLineId),
		type: 'DISCOUNT',
		productInfoId: undefined,
		price: -CartLineState.utils.getUnitDiscount({
			price: targetPrice,
			productDiscountInfo: discountInfo,
		}),
		name: discountName,
		productInfo: {
			category: '할인',
			isForKitchen: false,
		},
		productAdditionalInfo: { rawPrice: 0, supplyPrice: 0, taxFree: taxFreeTarget },
		productDiscountInfo: discountInfo,
		targetCartLineId: targetCartLineId,
		quantity: quantity,
	};
};

const getDiscountCartLineId = (targetCartLineId: string) => {
	return targetCartLineId + '-discount';
};

const createSetCartLineInputList = ({
	cartLineId,
	productInfo,
	selectedOptions,
	imageUrl,
	quantity,
}: {
	cartLineId: string;
	productInfo: ProductInfoType;
	selectedOptions: (OptionType & {
		isForKitchen: boolean;
	})[];
	imageUrl?: string; // KIOSK 전용
	quantity?: number;
}): CartLineInfoType[] => {
	return [
		createProductCartLineInput({ cartLineId, productInfo, imageUrl }),
		...selectedOptions.map((selectedOption) =>
			createSelectedOptionCartLineInputList({
				targetCartLineId: cartLineId,
				selectedOption,
				quantity,
			})
		),
	];
};

const createSelectedOptionCartLineInputList = ({
	targetCartLineId,
	selectedOption,
	quantity,
}: {
	targetCartLineId: string;
	selectedOption: OptionType & {
		isForKitchen: boolean;
	};
	quantity?: number;
}): CartLineInfoType => {
	return {
		id: getUuidV4(),
		type: 'SET_OPTION',
		name: selectedOption.name,
		price: selectedOption.price,
		productInfoId: undefined,
		productInfo: {
			category: 'SET_OPTION',
			isForKitchen: selectedOption.isForKitchen,

			ddiziInfo: undefined,
			exchangeInfo: undefined,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		targetCartLineId,
		quantity: quantity || 1,

		i18n: selectedOption.i18nName,
	};
};

export const CartLineProductState = {
	reducer: cartLineProductReducer,
};
