import { alpha, LinearProgress, styled } from '@mui/material';

export const StyledProgress = styled(LinearProgress, {
	shouldForwardProp: (props) => props !== 'isPaused',
})<{ isPaused: boolean }>(({ theme, isPaused }) => ({
	position: 'absolute',
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: alpha(isPaused ? theme.palette.error.light : theme.palette.success.light, 0.1),
	'& > .MuiLinearProgress-bar': {
		backgroundColor: alpha(isPaused ? theme.palette.error.light : theme.palette.success.light, 0.2),
	},
}));
