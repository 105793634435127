import { Popover, PopoverProps } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";

export interface IWithPopoverProps {
  children: (handleClick: (event: React.MouseEvent<HTMLElement>) => void) => ReactNode
  PopupComponent: ReactElement
  anchorOrigin?: PopoverProps["anchorOrigin"]
}
export const WithPopover: React.FC<IWithPopoverProps> = ({
  children,
  PopupComponent,
  anchorOrigin
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = !!anchorEl

  return (
    <>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin || {
          vertical: "bottom",
          horizontal: "left"
        }}>
        {PopupComponent}
      </Popover>
      {children(handleClick)}
    </>
  )
};