import { rootUrl } from '../const';
import { adjustTimeAsync } from './adjustTimeAsync';
import { cancelEnterAsync } from './cancelEnterAsync';
import { enterAsync } from './enterAsync';
import { getDdiziListAsync } from './getDdiziListAsync';
import { exitAsync } from './exitAsync';
import { cancelExitAsync } from './cancelExitAsync';
import { getDdiziListInOrderAsync } from './getDdiziListInOrderAsync';
import { downloadDdiziExcel } from './downloadDdiziExcel';
import { getDdiziListInfiniteAsync } from './getInfiniteDdiziListAsync';

export const DdiziApi = {
	getList: getDdiziListAsync(rootUrl),
	getListInOrder: getDdiziListInOrderAsync(rootUrl),
	getInfiniteList: getDdiziListInfiniteAsync(rootUrl),
	adjustTime: adjustTimeAsync(rootUrl),
	enter: enterAsync(rootUrl),
	cancelEnter: cancelEnterAsync(rootUrl),
	exit: exitAsync(rootUrl),
	cancelExit: cancelExitAsync(rootUrl),
	downloadExcel: downloadDdiziExcel(rootUrl),
};
