import {
	PosCouponInfoType,
	PosCouponStatusLabel,
	PosCouponTicketType,
	getExpiryDate,
	getUsedDate,
	isPosCouponAvailable,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Checkbox, IconButton, Stack, Typography } from '@mui/material';

export const PosCouponInfoItem = ({
	couponTicket,
	couponInfo,
	selectedCoupon,
	setSelectedCoupon,
	alreadyUsing = false,
	onCancel,
}: {
	couponTicket: PosCouponTicketType;
	couponInfo: PosCouponInfoType;
	selectedCoupon: PosCouponTicketType | undefined;
	setSelectedCoupon: (coupon?: PosCouponTicketType) => void;
	alreadyUsing?: boolean;
	// 있으면 cancel mode 임
	onCancel?: () => void;
}) => {
	const 사용상태라벨 = alreadyUsing
		? '현재 주문 목록에 포함됨'
		: couponTicket.useDTO?.used
		? `${getUsedDate(couponTicket)} 사용`
		: getExpiryDate(couponTicket);

	return (
		<SelectableListItem
			disabled={alreadyUsing || !isPosCouponAvailable[couponTicket.status] || couponInfo.isExpired}
			onClick={() => {
				setSelectedCoupon(selectedCoupon?.id === couponTicket.id ? undefined : couponTicket);
			}}
			selected={selectedCoupon?.id === couponTicket.id}>
			<Stack
				direction={'row'}
				width={'100%'}
				justifyContent={'space-between'}>
				<IconButton>
					<Checkbox
						sx={{ width: 18, height: 18 }}
						checked={selectedCoupon?.id === couponTicket.id}
					/>
				</IconButton>
				<Stack
					px={1}
					py={0.5}
					flex={1}
					spacing={2}
					alignItems={'center'}
					direction={'row'}>
					<Stack flex={1}>
						<Typography variant={'h4'}>{`${couponInfo.name} (${사용상태라벨})`}</Typography>
						<Typography
							variant={'body1'}
							color={'text.secondary'}>
							#{couponTicket.id}
						</Typography>
					</Stack>
					<Stack>
						{isPosCouponAvailable[couponTicket.status] && couponInfo.couponType === 'DISCOUNT' && (
							<Typography
								variant='h4'
								color={'primary'}>
								{couponInfo.discountCouponInfo.type === 'PERCENTAGE'
									? `${couponInfo.discountCouponInfo.value} % 할인`
									: `-${numberWithCommasAnd원(couponInfo.discountCouponInfo.value)} 할인`}
							</Typography>
						)}
						{!isPosCouponAvailable[couponTicket.status] && (
							<Stack>
								<Typography
									variant='h4'
									color={'error'}>
									{PosCouponStatusLabel[couponTicket.status]}
								</Typography>
							</Stack>
						)}
					</Stack>
				</Stack>
			</Stack>
		</SelectableListItem>
	);
};
