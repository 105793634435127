import {
	VaunceAppCMTTicketOnPosType,
	VaunceAppMUTTicketOnPosType,
	VaunceAppOTTTicketOnPosType,
	VaunceCouponSearchInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getGroupBy } from '@kinderlabs-pos/shared-util';
import {
	AppTicketSearchState,
	OrderState,
	StoreInfoState,
	VaunceAppTicketQueryState,
} from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import {
	FullSizeCircularProgress,
	RotatingRefreshButton,
	SearchBox,
} from '@kinderlabs-pos/ui-components';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { VaunceAppCouponListContent } from './VaunceAppCouponListContent';
import { VaunceAppTicketCmtListContent } from './VaunceAppTicketCmtListContent';
import { VaunceAppTicketMutListContent } from './VaunceAppTicketMutListContent';
import { VaunceAppTicketOttListContent } from './VaunceAppTicketOttListContent';

export const AppTicketUsageBoard = ({}) => {
	const [search, setSearch] = useAtom(AppTicketSearchState);
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const handleClickReload = () => {
		VaunceAppTicketQueryState.invalidateQueries();
	};

	useEffect(() => {
		// 맨 처음 자동 검색을 위함
		if (guestMemberInfo && search === '') setSearch(guestMemberInfo.telephone.substring(3));
	}, [guestMemberInfo, search, setSearch]);

	return (
		<Stack
			height={'100%'}
			spacing={1}
			alignItems={'center'}
			pt={1}
			overflow={'auto'}>
			<Stack
				width={'100%'}
				spacing={1}
				direction={'row'}>
				<RotatingRefreshButton handleClick={handleClickReload} />
				<MembershipTicketsPageSearchBox
					keyword={search}
					setSearch={setSearch}
				/>
			</Stack>
			<Typography
				variant={'body2'}
				textAlign={'center'}>
				{'티켓번호, 앱 QR코드, 회원 ID, 이메일, 전화번호 뒷 8자리로 검색됩니다.'}
			</Typography>
			<CardStack
				variant='nocolors'
				flex={1}
				spacing={1}
				overflow={'auto'}
				width={'100%'}
				height={'100%'}>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<AppTicketUsageBoardInternal search={search} />
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const MembershipTicketsPageSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword);
	useEffect(() => {
		setTempSearchValue(keyword);
	}, [keyword]);

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
			stackProps={{ width: '100%' }}
		/>
	);
};

const AppTicketUsageBoardInternal = ({ search }: { search: string }) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [selectedTicketType, setSelectedTicketType] = useState<'OTT' | 'MUT' | 'CMT' | 'APPCOUPON'>(
		'OTT'
	);

	const { data, isFetching } = useQuery({
		...VaunceAppTicketQueryState.keys.search({ search: search.trim() }),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		cacheTime: 30 * 1000,
	});

	const searchData = data ?? initial앱티켓쿠폰Data;

	const { idMatched, ottList, mutList, cmtList, couponList } = searchData;

	useEffect(() => {
		if (searchData.idMatched) {
			if (searchData.ottList.length > 0) setSelectedTicketType('OTT');
			else if (searchData.mutList.length > 0) setSelectedTicketType('MUT');
			else if (searchData.cmtList.length > 0) setSelectedTicketType('CMT');
			else if (searchData.couponList.length > 0) setSelectedTicketType('APPCOUPON');
		}
	}, [searchData]);

	const sortedAndUsableOTTList = useMemo(() => {
		return sortAndMarkUsableForTicket(ottList, storeId);
	}, [ottList, storeId]);

	const sortedAndUsableMUTList = useMemo(() => {
		return sortAndMarkUsableForTicket(mutList, storeId);
	}, [mutList, storeId]);

	const sortedAndUsableCMTList = useMemo(() => {
		return sortAndMarkUsableForTicket(cmtList, storeId);
	}, [cmtList, storeId]);

	// const sortedAndUsableCouponList = couponList.map((c) => ({ ...c, canUseTicket: true }));
	const sortedAndUsableCouponList = useMemo(() => {
		return sortAndMarkUsableForCoupon(couponList)
			.filter((c) => c.canUseTicket)
			.sort((before, next) => (before.expired > next.expired ? 1 : -1));
	}, [couponList]);

	const ottMapWithAdditionalInfo = useMemo(() => {
		return getGroupBy(sortedAndUsableOTTList, (data) => data.uid);
	}, [sortedAndUsableOTTList]);

	const mutMapWithAdditionalInfo = useMemo(() => {
		return getGroupBy(sortedAndUsableMUTList, (data) => data.uid);
	}, [sortedAndUsableMUTList]);

	const cmtMapWithAdditionalInfo = useMemo(() => {
		return getGroupBy(sortedAndUsableCMTList, (data) => data.uid);
	}, [sortedAndUsableCMTList]);

	const couponsMapWithAdditionalInfo = useMemo(() => {
		return getGroupBy(sortedAndUsableCouponList, (data) => data.uid);
	}, [sortedAndUsableCouponList]);

	return (
		<Stack>
			<ToggleButtonGroup
				fullWidth
				exclusive
				defaultValue={'OTT'}
				onChange={(e, value) => value && setSelectedTicketType(value)}
				size={'small'}
				value={selectedTicketType}>
				<ToggleButton value={'OTT'}>{`일회권 (${
					sortedAndUsableOTTList.filter((t) => t.canUseTicket).length
				})`}</ToggleButton>
				<ToggleButton value={'MUT'}>{`다회권 (${
					sortedAndUsableMUTList.filter((t) => t.canUseTicket).length
				})`}</ToggleButton>
				<ToggleButton value={'CMT'}>{`정기권 (${
					sortedAndUsableCMTList.filter((t) => t.canUseTicket).length
				})`}</ToggleButton>
				<ToggleButton value={'APPCOUPON'}>{`앱쿠폰 (${
					sortedAndUsableCouponList.filter((t) => t.canUseTicket).length
				})`}</ToggleButton>
			</ToggleButtonGroup>
			<Stack
				width={'100%'}
				overflow={'auto'}>
				{isFetching && <FullSizeCircularProgress />}
				{!isFetching && selectedTicketType === 'OTT' && (
					<VaunceAppTicketOttListContent appTicketInfoMap={ottMapWithAdditionalInfo} />
				)}
				{!isFetching && selectedTicketType === 'MUT' && (
					<VaunceAppTicketMutListContent appTicketInfoMap={mutMapWithAdditionalInfo} />
				)}
				{!isFetching && selectedTicketType === 'CMT' && (
					<VaunceAppTicketCmtListContent appTicketInfoMap={cmtMapWithAdditionalInfo} />
				)}
				{!isFetching && selectedTicketType === 'APPCOUPON' && (
					<VaunceAppCouponListContent couponInfoMap={couponsMapWithAdditionalInfo} />
				)}
			</Stack>
		</Stack>
	);
};

const sortAndMarkUsableForTicket = <
	T extends VaunceAppOTTTicketOnPosType | VaunceAppMUTTicketOnPosType | VaunceAppCMTTicketOnPosType
>(
	ticketList: T[],
	storeId: number
): (T & { canUseTicket: boolean })[] =>
	ticketList
		.map((ticket) => ({
			...ticket,
			canUseTicket: ticket.isMigrated && +ticket.storeId === storeId,
		}))
		.sort((ticket, nextTicket) => {
			if (ticket.canUseTicket) {
				return -1;
			} else if (nextTicket.canUseTicket) {
				return 1;
			} else {
				return 0;
			}
		});

const sortAndMarkUsableForCoupon = (
	couponList: VaunceCouponSearchInfoType[]
): (VaunceCouponSearchInfoType & { canUseTicket: boolean })[] =>
	couponList
		.map((coupon) => ({
			...coupon,
			canUseTicket: coupon.couponStatus === 'PUBLISHED' || coupon.couponStatus === 'USE_CANCELED',
		}))
		.sort((ticket, nextTicket) => {
			if (ticket.canUseTicket) {
				return -1;
			} else if (nextTicket.canUseTicket) {
				return 1;
			} else {
				return 0;
			}
		});

const initial앱티켓쿠폰Data: {
	idMatched: boolean;
	ottList: VaunceAppOTTTicketOnPosType[];
	mutList: VaunceAppMUTTicketOnPosType[];
	cmtList: VaunceAppCMTTicketOnPosType[];
	couponList: VaunceCouponSearchInfoType[];
} = {
	idMatched: false,
	ottList: [],
	mutList: [],
	cmtList: [],
	couponList: [],
};
