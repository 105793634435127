import { FilePdfOutlined } from '@ant-design/icons';
import { Box, Button } from '@mui/material';

export const PdfButton = () => (
	<Button
		variant={'outlined'}
		color={'error'}
	>
		Pdf
		<Box ml={1}>
			<FilePdfOutlined />
		</Box>
	</Button>
);
