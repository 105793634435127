import { AdminStockInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AdminStockInnerInfoType,
	StockHistoryLineType,
	StockHistoryType,
	StockInfoType,
	stockQuantityType,
	재고조정폐기FilterType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { QueryClient } from '../QueryClient';

const stockInfoKeys = createQueryKeys('stockInfo', {
	list: ({ params }: { params: 재고조정폐기FilterType }) => ({
		queryKey: [params],
		queryFn: async () => {
			if (!params.warehouseId) return [];
			const res = await AdminStockInfoApis.getInfoList(params);
			return res;
		},
	}),
	detail: (stockId?: number) => ({
		queryKey: [stockId],
		queryFn: async () => {
			if (!stockId) return;
			const res = await AdminStockInfoApis.getDetailInfo(stockId);
			return res;
		},
	}),
	warehouse: ({
		warehouseId,
		productIdList,
	}: {
		warehouseId?: number;
		productIdList?: number[];
	}) => ({
		queryKey: [warehouseId, productIdList],
		queryFn: async () => {
			if (!warehouseId) return null;
			const res = await AdminStockInfoApis.getStockInfoForWarehouse({
				warehouseId: warehouseId,
				productIdList: productIdList,
			});
			return res;
		},
	}),
	history: ({
		params,
	}: {
		params: { from: Dayjs; to: Dayjs; warehouseId?: number; type?: StockHistoryType };
	}) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminStockInfoApis.getHistoryList({
				type: params.type,
				warehouseId: params.warehouseId,
				from: params.from,
				to: params.to,
			});
			return res;
		},
	}),
	historyDetail: (historyId: number) => ({
		queryKey: [historyId],
		queryFn: async () => {
			const res = await AdminStockInfoApis.getDetailHistoryInfo(historyId);
			return res;
		},
	}),
});

const useCreateStockInfo = () => {
	return QueryClient.useMutation(
		async (input: {
			warehouseId: number;
			stockBulkList: Omit<AdminStockInnerInfoType, 'id'>[];
		}) => {
			return await AdminStockInfoApis.createBulkInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteStockInfo = () => {
	return QueryClient.useMutation(
		async (stockIdList: number[]) => {
			await AdminStockInfoApis.deleteInfo(stockIdList);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};
type UpdateQtyCommonType = Omit<StockHistoryLineType, 'id' | 'createdTime' | 'stockQuantity'> & {
	stockQuantity: stockQuantityType & { totalQuantity: number };
};

const useCountingStock = () => {
	return QueryClient.useMutation(
		async ({ warehouseId, input }: { warehouseId: number; input: UpdateQtyCommonType[] }) => {
			await AdminStockInfoApis.countingStock({ warehouseId, data: input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDisposalStock = () => {
	return QueryClient.useMutation(
		async ({ warehouseId, input }: { warehouseId: number; input: UpdateQtyCommonType[] }) => {
			await AdminStockInfoApis.disposalStock({ warehouseId, data: input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useRollBackStockHistory = () => {
	return QueryClient.useMutation(
		async (historyId: number) => {
			await AdminStockInfoApis.rollbackHistory(historyId);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: stockInfoKeys._def });
};

export const AdminStockInfoState = {
	keys: stockInfoKeys,
	deleteInfo: useDeleteStockInfo,
	createInfo: useCreateStockInfo,
	countingStock: useCountingStock,
	disposalStock: useDisposalStock,
	rollbackHistory: useRollBackStockHistory,
};
