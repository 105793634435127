import { useIsMobile } from '@kinderlabs-pos/shared-react-util';
import { SearchBox } from '@kinderlabs-pos/ui-components';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, InputBaseProps, Stack, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export interface ISearchBoxTableFilterProps<T extends string> {
	placeholder?: string;
	value?: T;
	setValue: (value: T | undefined) => void;
	disabled?: boolean;
}

export const SearchBoxTableFilter = <T extends string>({
	value,
	setValue,
	placeholder,
	disabled = false,
}: ISearchBoxTableFilterProps<T>) => {
	const isMobile = useIsMobile();
	const [tempSearchValue, setTempSearchValue] = useState<T | undefined>(value || undefined);

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				//@ts-ignore
				setTempSearchValue(val);
			}}
			disabled={disabled}
			onSearch={() => {
				setValue(tempSearchValue ? (tempSearchValue.trim() as T) : undefined);
			}}
			placeholder={placeholder}
			height={isMobile ? 35 : 36}
		/>
	);
};

export const NewSearchBoxTableFilter = ({ ...props }: InputBaseProps) => {
	const isMobile = useIsMobile();

	return (
		<StyledSearchBox height={isMobile ? 35 : 34}>
			<SearchIcon color={'inherit'} />
			<InputBase
				autoFocus
				sx={{ width: '100%' }}
				{...props}
			/>
		</StyledSearchBox>
	);
};

// JIN : 추후 궁극적으로 SearchBox 로 해결
const StyledSearchBox = styled(Stack)(({ theme }) => ({
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
	gap: theme.spacing(1),
	flex: 1,
	flexDirection: 'row',
	alignItems: 'center',
	border: `1px solid ${grey[400]}`,
	borderRadius: 4,
	color: grey[400],
}));
