import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

export interface IGrayBoxProps extends BoxProps {}
const GrayBox: React.FC<IGrayBoxProps> = ({ ...others }) => {
	return <StyledBox {...others} />;
};
export default GrayBox;

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#F4F4F4',
	borderRadius: '12px',
	padding: '15px',
	marginTop: '20px',
	overflowY: 'auto',
}));
