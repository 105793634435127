import {
	PosBoardEmptyItemType,
	PosBoardInfoType,
	PosBoardItemType,
} from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosBoardItemInfoAsync =
	(rootUrl: string) =>
	async ({ boardId, index, item }: UpdatePosBoardInfoRequestType) => {
		return (
			await posAxiosClient.put<PosBoardInfoType>(
				`${rootUrl}/pos-board/${boardId}/item`,
				{
					...item,
				},
				{
					params: {
						index: index,
					},
				}
			)
		).data;
	};

type UpdatePosBoardInfoRequestType = {
	item: Omit<PosBoardItemType, 'productInfo'> | Omit<PosBoardEmptyItemType, 'productInfo'>;
	boardId: number;
	index: number;
};
