import { Stack } from '@mui/material';
import { TabPanel } from '../VclubPage';
export interface IPremiumTabPanelProps {
	value: number;
	index: number;
	isEmpty?: boolean;
	EmptyContent?: JSX.Element;
	Content: JSX.Element;
}
export const PremiumTabPanel: React.FC<IPremiumTabPanelProps> = ({
	value,
	index,
	isEmpty,
	EmptyContent,
	Content,
}) => {
	return (
		<TabPanel
			index={index}
			value={value}>
			<Stack
				height={'100%'}
				width={'100%'}
				sx={{ overflowY: 'auto' }}>
				{isEmpty ? EmptyContent : Content}
			</Stack>
		</TabPanel>
	);
};
