import { PaymentRequestInfoType, is할부가능 } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { PaymentState } from '@kinderlabs-pos/state';
import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { InstallmentSelect, NoHeaderTable } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	Stack,
	Typography,
	alpha,
	styled,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useTheme } from '@mui/material/styles';
import Iccard from 'assets/ic-card.gif';
import { useCallback, useEffect } from 'react';
import { PaymentDialog } from '../PaymentDialog';
import { VirtualPayDetailContent } from './VirtualPayDetailContent';

export type HandleSubmitCardPaymentArgsType = {
	method: AvailablePaymentMethod;
	installment: number;
	memo?: string;
};
export type AvailablePaymentMethod = 'VIRTUAL_CARD' | 'CARD' | 'CARD_MS' | 'CASH_IC';
export const isAvailablePaymentMethod: Record<AvailablePaymentMethod, boolean> = {
	CARD: true,
	CASH_IC: true,
	CARD_MS: false,
	VIRTUAL_CARD: true,
};
export interface ICardPayDialogProps extends XlDialogProps {
	is결산모드?: boolean;
	결제할금액: number;
	handleSubmitPayment: (args: PaymentRequestInfoType) => Promise<void>;
	storeId: number;
	posId: number;
	orderId?: string;
	isVirtualCardDefaultMethod?: boolean;
}
export const CardPayDialog: React.FC<ICardPayDialogProps> = ({
	결제할금액,
	is결산모드 = false,
	open,
	closeDialog,
	handleSubmitPayment,
	storeId,
	posId,
	orderId,
	isVirtualCardDefaultMethod,
}) => {
	const theme = useTheme();

	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		PaymentState.actions.submit.usePaymentFormik<HandleSubmitCardPaymentArgsType>({
			storeId,
			posId,
			onClose: closeDialog,
			initialValues: {
				method: is결산모드 || isVirtualCardDefaultMethod ? 'VIRTUAL_CARD' : 'CARD',
				installment: 0,
				memo: undefined,
			},
			validationSchema: undefined,
			handleSubmitPayment,
			getHandlePaymentArgs: (values) => ({
				method: values.method,
				installment: values.installment,
				memo: values.memo,
				amount: 결제할금액,
			}),
		});

	const is결제할부가능 = is할부가능[values.method];

	useEffect(() => {
		if (!is결제할부가능) setValues((prev) => ({ ...prev, installment: 0 }));
	}, [is결제할부가능]);

	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	const handleCancelPayment = useCallback(async () => {
		await handle결제모듈강제취소();
		closeDialog && closeDialog();
	}, []);

	return (
		<PaymentDialog
			open={open}
			dialogTitle={'카드 결제'}
			handleSubmitPayment={handleSubmitPayment}
			onClose={closeDialog}
			failedPaymentRequest={values.failedPaymentRequestData}>
			<DialogContent sx={{ minWidth: 768 }}>
				<Stack
					direction={'row'}
					spacing={1}>
					<Stack
						direction={'column'}
						width={240}>
						<Stack>
							<Typography
								variant='h1'
								sx={{ flex: 1 }}>
								결제할 금액
							</Typography>
							<Typography
								variant='h1'
								sx={{ textAlign: 'right', fontSize: 20 }}>
								{`${numberWithCommasAnd원(결제할금액)}`}
							</Typography>
						</Stack>
						<Stack spacing={1}>
							<Stack>
								<Typography variant='subtitle1'>{'할부 선택'}</Typography>
								<InstallmentSelect
									disabled={!is결제할부가능}
									value={values.installment}
									onChange={(e) => setValues((prev) => ({ ...prev, installment: +e.target.value }))}
								/>
							</Stack>
							<Stack>
								<Typography variant='subtitle1'>{'결제 수단 선택'}</Typography>
								<StyledGridContainer container>
									{(
										[
											{
												value: 'CARD',
												label: 'IC카드',
											},
											{
												value: 'CARD_MS',
												label: 'MS카드',
											},
											{
												value: 'CASH_IC',
												label: '현금IC카드',
											},
											{
												value: 'VIRTUAL_CARD',
												label: '가상카드',
											},
										] as const
									).map((obj, idx) => {
										const selected = values.method === obj.value;
										return (
											<Grid2
												xs={6}
												key={idx}>
												<Button
													sx={{
														width: '100%',
														height: 50,
														opacity: selected ? 1 : 0.35,
														borderRadius: 0,
													}}
													disableElevation
													disabled={is결산모드 && 결제할금액 <= 0}
													color={selected ? 'primary' : 'inherit'}
													variant={selected ? 'contained' : 'outlined'}
													onClick={() => {
														isAvailablePaymentMethod[obj.value] &&
															setValues((prev) => ({ ...prev, method: obj.value }));
													}}>
													<Typography variant='h5'>{obj.label}</Typography>
												</Button>
											</Grid2>
										);
									})}
								</StyledGridContainer>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction={'column'}
						flex={1}>
						<Box height={'100%'}>
							<Stack
								p={3}
								height={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								spacing={1}>
								{isSubmitting ? (
									values.status === 'SUBMITTING_PM' ? (
										<Stack
											spacing={1}
											alignItems={'center'}>
											<InsertCard />
											<Typography
												variant={'h5'}
												color={'secondary'}>
												카드를 넣어주세요.
											</Typography>
										</Stack>
									) : (
										<Stack
											spacing={1}
											alignItems={'center'}>
											<CircularProgress
												color='secondary'
												sx={{ mb: 3 }}
											/>
											<Typography
												variant={'h4'}
												color={'secondary'}>
												데이터 전송중입니다.
											</Typography>
											<Typography
												variant={'h6'}
												color={'secondary'}>
												잠시만 기다려주세요. (최대 30초)
											</Typography>
										</Stack>
									)
								) : (
									<Stack>
										{values.method === 'VIRTUAL_CARD' && (
											<VirtualPayDetailContent
												values={values}
												setValues={setValues}
											/>
										)}
										<Button
											size={'large'}
											sx={{ px: 5, py: 5 }}
											onClick={() => handleSubmit()}
											variant={'contained'}>
											<Typography variant={'h2'}>결제하기</Typography>
										</Button>
									</Stack>
								)}
								<Stack>
									<Typography
										variant='subtitle1'
										color={'error'}>
										{errors.submit}
									</Typography>
								</Stack>
							</Stack>
						</Box>
					</Stack>
					<Stack
						direction={'column'}
						width={280}
						spacing={1}>
						<NoHeaderTable
							labelCellProps={{
								sx: {
									width: '40%',
									backgroundColor: alpha(theme.palette.primary.main, 0.1),
									color: theme.palette.primary.main,
								},
							}}
							valueCellProps={{
								sx: {
									width: '60%',
									color: theme.palette.primary.main,
								},
							}}
							data={[
								{
									label: '카드 번호',
									value: '',
								},
								{
									label: '카드사',
									value: '',
								},
								{
									label: '카드 소유자',
									value: '',
								},
								{
									label: '유효기간',
									value: '',
								},
								{
									label: '할부',
									value: '',
								},
							]}
						/>
						<Stack
							height={150}
							borderRadius={2}
							border={`1px solid ${alpha(theme.palette.text.secondary, 0.3)}`}
							alignItems={'center'}
							justifyContent={'center'}>
							<Typography
								variant={'subtitle1'}
								color={alpha(theme.palette.text.secondary, 0.3)}>
								서명
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'outlined'}
					disabled={values.status === 'SUBMITTING_SERVER'}
					onClick={handleCancelPayment}>
					취소
				</Button>
			</DialogActions>
		</PaymentDialog>
	);
};

const InsertCard = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${Iccard})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '120px',
	height: '120px',
}));

const StyledGridContainer = styled(Grid2)(({ theme }) => ({
	'& .MuiGrid2-root:nth-of-type(1) > button': {
		borderTopLeftRadius: 4,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	'& .MuiGrid2-root:nth-of-type(2) > button': {
		borderTopRightRadius: 4,
		borderBottomWidth: 0,
	},
	'& .MuiGrid2-root:nth-of-type(3) > button': {
		borderBottomLeftRadius: 4,
		borderRightWidth: 0,
	},
	'& .MuiGrid2-root:nth-of-type(4) > button': {
		borderBottomRightRadius: 4,
	},
}));
