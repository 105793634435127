import { ProductInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	CartLineBellInfoType,
	CartLineExcessChargeInfoType,
	CartLineInfoType,
	CartLineKlPosDiscountInfoType,
	CartLineKlPosProductInfoType,
	CartLineMemberTicketUsageInfoType,
	CartLineOnlineTicketOnoffmixInfoType,
	CartLineProductInfoType,
	CartLineTicketType,
	CartLineTicketUsageInfoType,
	CartLineType,
	CartLineTypeLabel,
} from './CartLineInfoType';

const canChangeQuantity = ({
	cartLine,
	cartLines,
}: {
	cartLine: CartLineInfoType;
	cartLines: CartLineInfoType[];
}) => {
	if (!canChangeQuantityType[cartLine.type]) return false;

	const optionsCartLines = cartLines.filter((cl) => cl.targetCartLineId === cartLine.id);

	return optionsCartLines.every((cl) => canChangeQuantityTypeInOption[cl.type]);
};

// 얘가 옵션으로 붙어있을 때 수량이 변경 가능한지 여부
const canChangeQuantityTypeInOption: Record<CartLineType, boolean> = {
	PRODUCT: false, // 옵션이아님
	CUSTOM: false, // 옵션이아님
	DISCOUNT: true, // 가능
	CUSTOM_DISCOUNT: true, // 가능
	VAUNCE_APP_MUT: false, // 옵션이아님
	VAUNCE_APP_CMT: false, // 옵션이아님
	VAUNCE_APP_OTT: false, // 옵션이아님
	VAUNCE_APP_DISCOUNT: false, // 불가능
	VAUNCE_APP_FREE_COUPON: false, // 옵션이아님
	EXCESS_CHARGE: false, // 옵션이아님
	OPTION: true, // 가능
	SET_PRODUCT: true,
	SET_OPTION: true, // 가능
	BELL: false, // 옵션이아님
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false, // 불가능
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

const canChangeQuantityType: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: true,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: true,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

const canChangeDiscount: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: true,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

/**
 * 장바구니 할인의 대상이 될 수 있는 놈들
 */
const canApplyDiscount: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: true,
	OPTION: true,
	SET_PRODUCT: true,
	SET_OPTION: true,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

// 옵션 등록 가능 여부
const canApplyOption: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: true,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

// 장바구니 테이블에서 선택시 보드에서 옵션 클릭이 가능한지 여부
const canClickOptionOnBoard: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: false,
	DISCOUNT: true,
	CUSTOM_DISCOUNT: true,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: true,
	SET_PRODUCT: false,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

// 장바구니 수정이 가능한지 여부
const canEditCartLine: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: false,
	DISCOUNT: true,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: true,
	SET_PRODUCT: true,
	SET_OPTION: true,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false, // 불가능
};

// ticket => 초과요금의 경우 원래 티켓의 ID / 다회권등의 경우 사용하려는 티켓의 ID
const displayInCartTable: Record<
	CartLineType,
	'default' | 'ticket' | 'options' | 'bell' | 'excess-charge'
> = {
	PRODUCT: 'default',
	CUSTOM: 'default',
	DISCOUNT: 'options',
	CUSTOM_DISCOUNT: 'options',
	VAUNCE_APP_MUT: 'ticket',
	VAUNCE_APP_CMT: 'ticket',
	VAUNCE_APP_OTT: 'ticket',
	VAUNCE_APP_DISCOUNT: 'options',
	VAUNCE_APP_FREE_COUPON: 'ticket',
	EXCESS_CHARGE: 'excess-charge',
	OPTION: 'options',
	SET_PRODUCT: 'default',
	SET_OPTION: 'options',
	BELL: 'bell',
	KL_COUPON_PRODUCT: 'ticket',
	KL_COUPON_DISCOUNT: 'options',
	ONLINE_TICKET_ONOFFMIX: 'ticket',
};

// 옵션인지 아닌지 displayInCartTable = options 랑 비슷한데 더 직관적인 이름
const isOptionsInCart: Record<CartLineType, boolean> = {
	PRODUCT: false,
	CUSTOM: false,
	DISCOUNT: true,
	CUSTOM_DISCOUNT: true,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: true,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: true,
	SET_PRODUCT: false,
	SET_OPTION: true,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: true,
	ONLINE_TICKET_ONOFFMIX: false,
};

const isDiscountInCart: Record<CartLineType, boolean> = {
	PRODUCT: false,
	CUSTOM: false,
	DISCOUNT: true,
	CUSTOM_DISCOUNT: true,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: true,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: false,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: true,
	ONLINE_TICKET_ONOFFMIX: false,
};

// 주문 목록에 그려질때 옵션을 달 수 있는 조건
const hasOptionsInCart: Record<CartLineType, boolean> = {
	PRODUCT: true, // 얘만 True
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: true, // 얘만 True
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false,
};

// 주문 목록에서 선택조차 못하게하는 것
const cannotSelectInCart: Record<CartLineType, boolean> = {
	PRODUCT: false,
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: false,
	SET_OPTION: true, // 얘만 True
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false,
};

const renderCartLineNameInTable = (cartLineInfo: CartLineInfoType): string => {
	switch (CartLineTypeRules.displayInCartTable[cartLineInfo.type]) {
		case 'default':
			return cartLineInfo.type === 'SET_PRODUCT'
				? `[세트] ${cartLineInfo.name}`
				: cartLineInfo.name;
		case 'options':
			return `└ㅤ${cartLineInfo.name}`;
		case 'ticket':
			switch (cartLineInfo.type) {
				case 'VAUNCE_APP_MUT':
				case 'VAUNCE_APP_CMT':
				case 'VAUNCE_APP_OTT':
					return (cartLineInfo as CartLineTicketUsageInfoType).ticketUsageInfo.name;
				case 'KL_COUPON_PRODUCT':
					return cartLineInfo.name;
				case 'VAUNCE_APP_FREE_COUPON':
					return (cartLineInfo as CartLineTicketUsageInfoType).ticketUsageInfo.name;
				case 'ONLINE_TICKET_ONOFFMIX':
					return (cartLineInfo as CartLineOnlineTicketOnoffmixInfoType).ticketUsageInfo.name;
				case 'KL_COUPON_DISCOUNT':
				case 'VAUNCE_APP_DISCOUNT':

				default:
					throw new Error(`${cartLineInfo.type} 에 대한 처리가 필요합니다.`);
			}
		case 'bell':
		case 'excess-charge':
		default:
			return cartLineInfo.name;
	}
};

const renderCartLineSubNameInTable = (cartLineInfo: CartLineInfoType): string | undefined => {
	switch (cartLineInfo.type) {
		case 'EXCESS_CHARGE':
			return `# ${(cartLineInfo as CartLineExcessChargeInfoType).ddiziUsageId}`;
		case 'VAUNCE_APP_MUT':
		case 'VAUNCE_APP_CMT':
		case 'VAUNCE_APP_OTT':
			return `# ${(cartLineInfo as CartLineTicketUsageInfoType).ticketUsageInfo.ticketId}`;
		case 'ONLINE_TICKET_ONOFFMIX':
			return `${
				(cartLineInfo as CartLineOnlineTicketOnoffmixInfoType).ticketUsageInfo.ticketUsageId
			}`;
		case 'VAUNCE_APP_FREE_COUPON':
			return `# ${(cartLineInfo as CartLineTicketUsageInfoType).ticketUsageInfo.ticketId}`;
		case 'VAUNCE_APP_DISCOUNT':
			return `ㅤㅤ# ${(cartLineInfo as CartLineTicketUsageInfoType).ticketUsageInfo.ticketId}`;
		case 'KL_COUPON_PRODUCT':
			return `# ${(cartLineInfo as CartLineProductInfoType).couponId}`;
		case 'KL_COUPON_DISCOUNT':
			return `ㅤㅤ# ${(cartLineInfo as CartLineKlPosDiscountInfoType).couponId}`;
		default:
			return undefined;
	}
};

// 낮을수록 우선
const cartLineSort우선순위: Record<CartLineType, number> = {
	PRODUCT: 1,
	CUSTOM: 1,
	DISCOUNT: 3,
	CUSTOM_DISCOUNT: 4,
	VAUNCE_APP_MUT: 1,
	VAUNCE_APP_CMT: 1,
	VAUNCE_APP_OTT: 1,
	VAUNCE_APP_DISCOUNT: 5,
	VAUNCE_APP_FREE_COUPON: 1,
	EXCESS_CHARGE: 1,
	OPTION: 2,
	SET_PRODUCT: 1,
	SET_OPTION: 2,
	BELL: 100,
	KL_COUPON_PRODUCT: 1,
	KL_COUPON_DISCOUNT: 1,
	ONLINE_TICKET_ONOFFMIX: 1,
};

// 낮을수록 우선
const canShowOn프론트영수증: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: true,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: true,
	SET_PRODUCT: true,
	SET_OPTION: true,
	BELL: true,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false,
};

const canShowOn주방영수증: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: true,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: false,
	OPTION: true,
	SET_PRODUCT: true,
	SET_OPTION: true,
	BELL: false,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false,
};

const getCartLineInfoTypeUniqueKey = (cartLineInfo: CartLineInfoType) => {
	switch (cartLineInfo.type) {
		case 'PRODUCT':
		case 'SET_PRODUCT':
		case 'SET_OPTION':
		case 'OPTION':
		case 'CUSTOM':
			return [
				cartLineInfo.type,
				cartLineInfo.productInfoId,
				cartLineInfo.price,
				cartLineInfo.name,
				// 이게 있어야 할인상품 구분이 됨
				cartLineInfo.productDiscountInfo?.type,
				cartLineInfo.productDiscountInfo?.value,
				cartLineInfo.targetCartLineId,
			].join(':');
		case 'CUSTOM_DISCOUNT':
		case 'DISCOUNT':
			return [
				cartLineInfo.type,
				cartLineInfo.productInfoId,
				cartLineInfo.price,
				cartLineInfo.name,
				cartLineInfo.productDiscountInfo?.type,
				cartLineInfo.productDiscountInfo?.value,
				cartLineInfo.targetCartLineId,
			].join(':');
		case 'BELL':
			const bellCartLineInfo = cartLineInfo as CartLineBellInfoType;
			return [bellCartLineInfo.type, bellCartLineInfo.bellNumber].join(':');
		case 'EXCESS_CHARGE':
			const excessChargeInfo = cartLineInfo as CartLineExcessChargeInfoType;
			return [excessChargeInfo.type, excessChargeInfo.ddiziUsageId].join(':');
		case 'VAUNCE_APP_MUT':
		case 'VAUNCE_APP_CMT':
		case 'VAUNCE_APP_OTT':
		case 'VAUNCE_APP_DISCOUNT':
			const vaunceAppTicketsInfo = cartLineInfo as CartLineMemberTicketUsageInfoType;
			return [
				vaunceAppTicketsInfo.type,
				vaunceAppTicketsInfo.ticketUsageInfo.ticketId,
				vaunceAppTicketsInfo.ticketUsageInfo.ticketUsageId,
			].join(':');
		case 'KL_COUPON_PRODUCT':
			const klcouponProductInfo = cartLineInfo as CartLineKlPosProductInfoType;
			return [klcouponProductInfo.type, klcouponProductInfo.couponId].join(':');
		case 'KL_COUPON_DISCOUNT':
			const klcouponDiscountInfo = cartLineInfo as CartLineKlPosDiscountInfoType;
			return [
				klcouponDiscountInfo.type,
				klcouponDiscountInfo.couponId,
				klcouponDiscountInfo.targetCartLineId,
			].join(':');
		case 'ONLINE_TICKET_ONOFFMIX':
			const onlineTicketCartLineInfo = cartLineInfo as CartLineOnlineTicketOnoffmixInfoType;
			return [
				onlineTicketCartLineInfo.type,
				onlineTicketCartLineInfo.ticketUsageInfo.ticketUsageId,
			].join(':');
		default:
			throw new Error('[dev]');
	}
};

const sortCartLines = (cartLines: CartLineInfoType[]) =>
	cartLines
		.filter((cl) => !CartLineTypeRules.isOptionsInCart[cl.type])
		// option 붙이기
		.map((cl) =>
			CartLineTypeRules.hasOptionsInCart[cl.type]
				? [
						cl,
						...cartLines
							.filter(
								// 옵션먼저 필터링해서 몇개 뽑아낸 후
								(optionCl) =>
									CartLineTypeRules.isOptionsInCart[optionCl.type] &&
									optionCl.targetCartLineId === cl.id
							)
							.sort((cl, nextCl) => {
								if (cl.productInfo.category < nextCl.productInfo.category) {
									return 1;
								} else if (cl.productInfo.category > nextCl.productInfo.category) {
									return -1;
								} else {
									return 0;
								}
							})
							// 우선순위 적용
							.sort((cl, nextCl) => {
								if (!nextCl) return 0;

								return CartLineTypeRules.cartLineSort우선순위[cl.type] <
									CartLineTypeRules.cartLineSort우선순위[nextCl.type]
									? -1
									: 1;
							}),
				  ]
				: [cl]
		)
		.flatMap((cl) => cl)
		.sort((cl, nextCl) => {
			if (cl.type === 'BELL') {
				return 1;
			} else if (nextCl.type === 'BELL') {
				return -1;
			} else {
				return 0;
			}
		});

const hasAlreadyDiscounts = (cartLines: CartLineInfoType[], targetCartLineId: string) => {
	return cartLines.some((c) => c.targetCartLineId === targetCartLineId && isDiscountInCart[c.type]);
};

const canChangePrice = (cartLine: CartLineInfoType, productInfoList: ProductInfoType[]) => {
	return (
		(['PRODUCT', 'SET_PRODUCT', 'OPTION'] as CartLineType[]).includes(cartLine.type) &&
		productInfoList.find((p) => p.id === cartLine.productInfoId)?.enabledPriceOverride
	);
};

const is외부티켓: Record<CartLineType, boolean> = {
	PRODUCT: false,
	CUSTOM: false,
	DISCOUNT: false,
	CUSTOM_DISCOUNT: false,
	VAUNCE_APP_MUT: true,
	VAUNCE_APP_CMT: true,
	VAUNCE_APP_OTT: true,
	VAUNCE_APP_DISCOUNT: true,
	VAUNCE_APP_FREE_COUPON: true,
	EXCESS_CHARGE: false,
	OPTION: false,
	SET_PRODUCT: false,
	SET_OPTION: false,
	BELL: false,
	KL_COUPON_PRODUCT: true,
	KL_COUPON_DISCOUNT: true,
	ONLINE_TICKET_ONOFFMIX: true,
};

export const is결산가능: Record<CartLineType, boolean> = {
	PRODUCT: true,
	CUSTOM: true,
	DISCOUNT: true,
	CUSTOM_DISCOUNT: true,
	VAUNCE_APP_MUT: false,
	VAUNCE_APP_CMT: false,
	VAUNCE_APP_OTT: false,
	VAUNCE_APP_DISCOUNT: false,
	VAUNCE_APP_FREE_COUPON: false,
	EXCESS_CHARGE: true,
	OPTION: true,
	SET_PRODUCT: true,
	SET_OPTION: true,
	BELL: true,
	KL_COUPON_PRODUCT: false,
	KL_COUPON_DISCOUNT: false,
	ONLINE_TICKET_ONOFFMIX: false,
};

export const CartLineTypeRules = {
	canChangeQuantity,
	canChangeDiscount,
	canApplyDiscount,
	canApplyOption,
	canClickOptionOnBoard,
	canEditCartLine,
	displayInCartTable,
	isOptionsInCart,
	hasOptionsInCart,
	cannotSelectInCart,
	cartLineSort우선순위,
	canShowOn프론트영수증,
	canShowOn주방영수증,
	canChangePrice,
	hasAlreadyDiscounts,
	getCartLineInfoTypeUniqueKey,
	renderCartLineNameInTable,
	renderCartLineSubNameInTable,
	sortCartLines,
	is외부티켓,
	is결산가능,
};
