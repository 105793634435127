import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteMemoAsync =
	(rootUrl: string) =>
	async ({ memoId }: { memoId: number }) =>
		(
			await posAxiosClient.delete(`${rootUrl}/memo`, {
				params: {
					memoId,
				},
			})
		).data;
