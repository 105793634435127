import { PrinterOutlined } from '@ant-design/icons';
import {
	ExchangeCouponStatusTypeLabel,
	ExchangeCouponType,
} from '@kinderlabs-pos/shared-data-type';
import { PrintReceiptState } from '@kinderlabs-pos/state';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { Button, Stack, StackProps, Typography, TypographyProps, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import { ReactNode } from 'react';

export interface IExchangeCouponDisplayProps extends StackProps {
	coupon: ExchangeCouponType;
	itemButton?: ReactNode;
	isSimple?: boolean;
}
export const ExchangeCouponDisplay: React.FC<IExchangeCouponDisplayProps> = ({
	coupon,
	itemButton,
	isSimple = false,
	...stackProps
}) => {
	const statusIndicator = getStatusIndicator(coupon);
	const { print쿠폰Async } = PrintReceiptState.useExchangePrinter({ deviceType: 'POS' });

	const printExchangeCouponAgain = async () => {
		await print쿠폰Async({ coupon });
	};

	return (
		<Stack
			direction={'row'}
			spacing={0.5}
			alignItems={'center'}
			{...stackProps}>
			<Stack
				alignItems={'center'}
				sx={{ minWidth: 80, pr: 1 }}>
				{statusIndicator && <ConfirmationNumberIcon style={{ color: statusIndicator.color }} />}
				{/* {statusIndicator.label} */}
			</Stack>
			<Stack
				direction={'column'}
				minWidth={190}
				flex={1}>
				<Typography variant={'h6'}>{`${coupon.name}`}</Typography>
				<Typography
					variant={'body1'}
					color={'text.secondary'}>{`#${coupon.id}`}</Typography>
			</Stack>
			<Button
				startIcon={<PrinterOutlined />}
				onClick={printExchangeCouponAgain}
				variant='outlined'
				size='small'>
				재발권
			</Button>
		</Stack>
	);
};

const getStatusIndicator = (coupon: ExchangeCouponType) => {
	const theme = useTheme();

	const typographyProps: TypographyProps = {
		variant: 'h5',
		sx: { textAlign: 'center' },
	};

	switch (coupon.status) {
		case 'PUBLISHED':
			return {
				label: (
					<Typography
						{...typographyProps}
						color={theme.palette.primary.main}>
						{ExchangeCouponStatusTypeLabel[coupon.status]}
					</Typography>
					// <Typography {...typographyProps}>'TEST'</Typography>
				),
				color: theme.palette.primary.main,
			};
		case 'COMPLETED':
			return {
				label: (
					<Typography
						{...typographyProps}
						color={theme.palette.success.main}>{`${
						ExchangeCouponStatusTypeLabel[coupon.status]
					}`}</Typography>
				),
				color: theme.palette.success.main,
			};
		case 'UNUSABLE':
			return {
				label: (
					<Typography
						{...typographyProps}
						color={theme.palette.error.main}>{`${
						ExchangeCouponStatusTypeLabel[coupon.status]
					}`}</Typography>
				),
				color: theme.palette.error.main,
			};
	}
};
