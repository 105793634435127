import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { GuestVisitActions } from './GuestVisitActions';
import { GuestVisitUtils } from './GuestVisitUtils';

type AimedStatusType = 'WAITING' | 'COMPLETED' | 'CANCELED';

type ActionType =
	| { type: 'initial'; list: GuestVisitType[] }
	| { type: 'addNewGuest'; list: GuestVisitType }
	| { type: 'callWithoutStatusChange'; list: GuestVisitType }
	| { type: 'callWithStatusChange'; list: GuestVisitType }
	| { type: 'changeStatus'; list: GuestVisitType; status: AimedStatusType };

const guestVisitReducer = (prev: GuestVisitType[], action: ActionType) => {
	switch (action.type) {
		case 'initial':
			return action.list;
		case 'addNewGuest':
			return [...prev, action.list];
		case 'callWithoutStatusChange':
			return prev.map((item) => (item.id === action.list.id ? action.list : item));
		case 'callWithStatusChange':
			const newCallWithStatusChangeArray = prev.map((item) => {
				return item.id === action.list.id ? action.list : item;
			});
			return newCallWithStatusChangeArray;
		case 'changeStatus':
			const newChangeStatusArray = prev.map((item) => {
				if (item.id === action.list.id) {
					item.status = action.status;
					return item;
				} else {
					return item;
				}
			});
			return newChangeStatusArray;
		default:
			throw new Error('Unknown Action Type');
	}
};

export const GuestVisitState = {
	actions: GuestVisitActions,
	utils: GuestVisitUtils,
};
