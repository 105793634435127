import { MonthCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { Stack, StackProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export const MonthSelectIndicator = ({
	onClick,
	option,
}: {
	onClick: StackProps['onClick'];
	option: MonthCalendarFilterOptionType;
}) => {
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			flex={1}
			spacing={1}
			borderRadius={1}
			height={34}
			px={1}
			sx={{ border: `1px solid ${grey[400]}` }}
			onClick={onClick}>
			<Typography
				variant={'body2'}
				color={'black'}>
				{`${option.fromMonth} ~ ${option.toMonth}`}
			</Typography>
		</Stack>
	);
};
