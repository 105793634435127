import { SentryApiLogging, VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { PayPeriod } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { QueryClient } from '../QueryClient';
import { atom } from 'jotai';

const wishListQueryKey = ['vaunce-app', 'wishList'];
const isFavoriteCenterKey = ['vaunce-app', 'isFavorite'];
const availableCouponQueryKey = ['vaunce-app', 'coupon'];
const eventListQueryKey = ['vaunce-app', 'event'];
const eventOneQueryKey = ['vaunce-app', 'event', 'one'];
const userInfoQueryKey = ['vaunce-app', 'userInfo'];
const userUsageQueryKey = ['vaunce-app', 'userUsage'];
const facilityQueryKey = ['vaunce-app', 'facility'];
const centerListQueryKey = ['vaunce-app', 'centerList'];
const ticketQueryKey = ['vaunce-app', 'ticket'];
const pointQueryKey = ['vaunce-app', 'point'];
const premiumQueryKey = ['vaunce-app', 'premium'];
const premiumOneQueryKey = ['vaunce-app', 'premiumOne'];
const premiumOneRefundInfoQueryKey = ['vaunce-app', 'premiumOneRefundInfo'];
const centerHistoryQueryKey = ['vaunce-app', 'centerHistory'];
const normalQuestionQueryKey = ['vaunce-app', 'normal'];
const partyQuestionQueryKey = ['vaunce-app', 'party'];
const alarmQueryKey = ['vaunce-app', 'alarm'];

const infoByUsageIdQueryKey = ['vaunce-app', 'infoByUsage'];
const userRecentQueryKey = ['vaunce-app', 'recentOne'];
const payQueryKey = ['vaunce-app', 'pay'];
const myInfoQueryKey = ['vaunce-app', 'myInfo'];
const noticeQueryKey = ['vaunce-app', 'notice', 'detail'];
const vipRegisterQueryKey = ['vaunce-app', 'premium', 'register'];
const settingQueryKey = ['vaunce-app', 'setting'];
const userPopupKey = ['vaunce-app', 'popip'];

const OneTicketCountAtom = atom<number>(0);

const useWishListQuery = () => {
	const result = useQuery({
		queryKey: [...wishListQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getWishListAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.favorite || undefined;
	}
	throw Error('wishList GET API error');
};

// 찜 리스트 > 찜 등록 혹은 해제
const useToggleWishList = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: { curCenterId: number; isFavorite: boolean }) => {
			await VaunceAppApis.toggleWishListAsync({
				centerId: input.curCenterId as number,
				toggle: !input.isFavorite,
			});
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...wishListQueryKey]);
			},
		}
	);
};

// availableList, unavailableList 있음
const useCouponListQuery = () => {
	const result = useQuery({
		queryKey: [...availableCouponQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getCouponListAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('couponList GET API error');
};

const useEventListQuery = () => {
	const result = useQuery({
		queryKey: [...eventListQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getEventListAsync();
			return res;
		},
		refetchOnWindowFocus: true,
		onError: (error) => {
			SentryApiLogging.setCaptureException(error);
			SentryApiLogging.setTag('API', 'getEventListAsync');
		},
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('eventList GET API error');
};

const useEventOneQuery = (id: string) => {
	const result = useQuery({
		queryKey: [...eventOneQueryKey, ...id],
		queryFn: async () => {
			const res = await VaunceAppApis.getEventOne(id);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('eventOne GET API error');
};

// 마이페이지 > 회원정보
const useUserInfoQuery = () => {
	const result = useQuery({
		queryKey: [...userInfoQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getInfoAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('userInfo GET API error');
};

// 마이페이지 > 회원정보 > 프로필 사진 변경
const useProfileImageQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (imageFormData: FormData) => {
			await VaunceAppApis.postInfoImageAsync(imageFormData);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...userInfoQueryKey]);
				alert('프로필 사진이 변경되었습니다.');
			},
		}
	);
};

// 마이페이지 > 회원정보 > 주소 변경
const useAddressQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (addressFormData: FormData) => {
			await VaunceAppApis.postAddressAsync(addressFormData);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...userInfoQueryKey]);
				alert('주소가 변경되었습니다.');
			},
		}
	);
};

// 마이페이지 > 회원정보 > 자녀 등록
const useChildrenQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (childrenForm: FormData) => {
			await VaunceAppApis.postChildrenAsync(childrenForm);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...userInfoQueryKey]);
				queryClient.invalidateQueries([...userUsageQueryKey]);
				alert('자녀 정보가 수정 되었습니다.');
			},
		}
	);
};

// 마이페이지 > 회원정보 >  자주이용하는센터, 추천인 등록
const useExtraInfoQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (centerFormData: FormData) => {
			await VaunceAppApis.postUserExtraInfoAsync(centerFormData);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...userInfoQueryKey]);
				alert('추가정보가 수정되었습니다.');
			},
		}
	);
};

// 팝업
const usePopupQuery = () => {
	const result = useQuery({
		queryKey: [...userPopupKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getPopupAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('popup GET API error');
};

// 바운스 이용하기 > 시설 이용 등록
const useUserUsageQuery = () => {
	const result = useQuery({
		queryKey: [...userUsageQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getUsageAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('usage GET API error');
};

const useFacilityUsageQuery = () => {
	const result = useQuery({
		queryKey: [...facilityQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getUsageFacilityAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('facilityUsage GET API error');
};

const useCenterListQuery = () => {
	const result = useQuery({
		queryKey: [...centerListQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getCenterCollectionAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('centerList GET API error');
};

// 다회권 1, 정기권 2, 이용권 6
type ticketType = 'PREMIUM_MULTI' | 'PREMIUM_SEASON' | 'PREMIUM_ONE';

const useTicketInfoQuery = (type: ticketType) => {
	const result = useQuery({
		queryKey: [...ticketQueryKey, type],
		queryFn: async () => {
			const res = await VaunceAppApis.postPremiumCollectionAsync(type);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('ticket GET API error');
};

// 마이멤버십 > 바운스 클럽
export type PointPeriod = 'sixmonths' | 'oneyear' | 'all';

// 마이페이지 > 바운스 프리미엄
const usePremiumQuery = () => {
	const result = useQuery({
		queryKey: [...premiumQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getPremiumAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('premiumList GET API error');
};

// 마이페이지 > 바운스 프리미엄 > 정기권 구매 이후 > 사용자 등록하기
const usePremiumRegister = (navigateVauncePremiumClub: () => void) => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (userForm: FormData) => {
			await VaunceAppApis.addPremiumSeanAsync(userForm);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...premiumQueryKey]);
				navigateVauncePremiumClub();
			},
		}
	);
};

// 아직 엔드포인트가 확정되지 않은 페이지
const usePremiumOneQuery = () => {
	const result = useQuery({
		queryKey: [...premiumOneQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getPremiumOneAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('premiumOne GET API error');
};

const usePremiumOneRefundInfoQuery = (orderNum: string) => {
	const result = useQuery({
		queryKey: [...premiumOneRefundInfoQueryKey, orderNum],
		queryFn: async () => {
			const res = await VaunceAppApis.getPremiumOneRefundInfoAsync(orderNum);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('premiumOneRefundInfo GET API error');
};

// 마의페이지 > 센터 이용 내역
const useCenterHistoryQuery = () => {
	const result = useQuery({
		queryKey: [...centerHistoryQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getCenterHistoryAsync();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('centerHistory GET API error');
};

const useNormalQuestionQuery = () => {
	const result = useQuery({
		queryKey: [...normalQuestionQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getNormalQuestionList();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('normalQuestion GET API error');
};

const usePartyQuestionQuery = () => {
	const result = useQuery({
		queryKey: [...partyQuestionQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getPartyQeustionList();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('partyQuestionList GET API error');
};

// 공지 사항
const useAlarmQuery = () => {
	const result = useQuery({
		queryKey: [...alarmQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getAlarmList();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('noticeList GET API error');
};

const useAlarmReadAllQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async () => {
			await VaunceAppApis.getAllAlarmRead();
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...alarmQueryKey]);
			},
		}
	);
};

const useAlarmDeleteQuery = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async () => {
			await VaunceAppApis.getAllAlarmDelete();
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...alarmQueryKey]);
			},
		}
	);
};

const useInfoByUsageIdQuery = (usageId: number) => {
	// const usageId = useAtomValue(VaunceState.usageId);
	const result = useQuery({
		queryKey: [...infoByUsageIdQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getInfoByUsageId(usageId);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('facilityRegisterUserInfo GET API error');
};

const useRecentlyOneQuery = () => {
	const result = useQuery({
		queryKey: [...userRecentQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getUserRecnetone();
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('recentOne GET API error');
};

const usePayQuery = (type: PayPeriod) => {
	const result = useQuery({
		queryKey: [...payQueryKey, type],
		queryFn: async () => {
			const res = await VaunceAppApis.getPayAsync(type);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('payList GET API error');
};

const usePointQuery = (period: PointPeriod) => {
	const result = useQuery({
		queryKey: [...pointQueryKey, period],
		queryFn: async () => {
			const res = await await VaunceAppApis.getPointAsync('sixmonths');
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data.vo || undefined;
	}
	throw Error('pointList GET API error');
};

const useMyInfoQuery = () => {
	const navigate = useNavigate();
	const accessToken = localStorage.getItem('accessToken');

	const result = useQuery({
		queryKey: [...myInfoQueryKey, accessToken],
		queryFn: async () => {
			return accessToken ? await VaunceAppApis.getMyInfoAsync() : null;

			// const res = await VaunceAppApis.getMyInfoAsync();
			// return res;
		},
		refetchOnWindowFocus: true,
		retry: 1,
		onError: () => {
			localStorage.removeItem('accessToken');
			alert('로그인 세션이 만료되어 로그아웃 됩니다. 다시 로그인을 해주세요.');
			navigate('/');
		},
	});

	if (result.isSuccess) {
		return result.data?.vo || undefined;
	}

	throw Error('myInfo GET API error');
};

//공지 다이얼로그
const useNoticeDetailQuery = (noticeId: number) => {
	const result = useQuery({
		queryKey: [...noticeQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getNoticeDetail(noticeId);
			return res;
		},
		refetchOnWindowFocus: true,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('noticeDetail GET API error');
};

const useSettingQuery = () => {
	const result = useQuery({
		queryKey: [...settingQueryKey],
		queryFn: async () => {
			const res = await VaunceAppApis.getSetting();
			return res;
		},
		refetchOnWindowFocus: true,
		retry: 1,
	});
	if (result.isSuccess) {
		return result.data || undefined;
	}
	throw Error('noticeDetail GET API error');
};

const useChangeAdAgreeMutation = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (pushCheck: boolean) => {
			return await VaunceAppApis.getAdAgreePushAsync(pushCheck ? 'DISAGREE' : 'AGREE');
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([settingQueryKey]);
			},
		}
	);
};

const useChangePushAgreeMutation = () => {
	const queryClient = QueryClient.useQueryClient();
	return QueryClient.useMutation(
		async (pushCheck: boolean) => {
			return await VaunceAppApis.getAgreePush(pushCheck ? false : true);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([settingQueryKey]);
			},
		}
	);
};

export const VaunceQueryState = {
	useWishListQuery,
	useToggleWishList,
	useCouponListQuery,
	useEventListQuery,
	useEventOneQuery,
	useUserInfoQuery,
	useProfileImageQuery,
	useAddressQuery,
	useChildrenQuery,
	useExtraInfoQuery,
	useUserUsageQuery,
	useFacilityUsageQuery,
	useCenterListQuery,
	useTicketInfoQuery,
	usePremiumQuery,
	usePremiumOneQuery,
	usePremiumOneRefundInfoQuery,
	usePremiumRegister,
	useCenterHistoryQuery,
	useNormalQuestionQuery,
	usePartyQuestionQuery,
	useAlarmQuery,
	useAlarmReadAllQuery,
	useAlarmDeleteQuery,
	useInfoByUsageIdQuery,
	useRecentlyOneQuery,
	usePayQuery,
	usePointQuery,
	useMyInfoQuery,
	useNoticeDetailQuery,
	useSettingQuery,
	useChangeAdAgreeMutation,
	useChangePushAgreeMutation,
	usePopupQuery,
	OneTicketCountAtom,
};
