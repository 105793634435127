import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';

interface RecentGuestVisits {
	storeId: number;
	telephone: string;
}
export const getRecentGuestVisitAsync =
	(rootUrl: string) =>
	async ({ storeId, telephone }: RecentGuestVisits) =>
		(
			await posAxiosClient.get<GuestVisitType[]>(
				`${rootUrl}/guest-visit/store/${storeId}/recent-calls`,
				{ params: { telephone: telephone } }
			)
		).data;
