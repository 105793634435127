const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

/**
 * 한글은 2글자 나머지는 1글자 취급해서 length 를 재준다.
 */
export const getTextRenderLength = (inputString: string): number => {
	let count = 0;
	for (let i = 0; i < inputString.length; i++) {
		if (koreanRegex.test(inputString.charAt(i))) {
			// 한글
			count += 2;
		} else {
			// 나머지 문자
			count += 1;
		}
	}
	return count;
};
