import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Pageable } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const downloadCreditPointExcel =
	(rootUrl: string) =>
	({ storeIdList, from, to, sort }: CreditPointExcelRequestType) => {
		const url = new URL(`${rootUrl}/excel/credit/point`);

		if (storeIdList) url.searchParams.append('storeIdList', `${storeIdList}`);
		if (from) url.searchParams.append('from', from.format('YYYY-MM-DD'));
		if (to) url.searchParams.append('to', to.format('YYYY-MM-DD'));
		if (sort) url.searchParams.append('sort', `${sort.name},${sort.direction}`);

		return `${url}`;
	};

export type CreditPointExcelRequestType = {
	storeIdList?: number[];
	from: Dayjs;
	to: Dayjs;
	sort: Pageable['sort'];
};
