import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosBoardOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posBoardIdsList }: UpdatePosBoardInfoType) => {
		return (
			await posAxiosClient.put<number[]>(
				`${rootUrl}/pos-board/store/${storeId}/adjust-order`,
				{},
				{
					params: {
						posBoardIds: posBoardIdsList,
					},
				}
			)
		).data;
	};

type UpdatePosBoardInfoType = {
	storeId: number;
	posBoardIdsList: number[];
};
