import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { QuestionFirstTypeLabel, QuestionInfoResponseType } from '@kinderlabs-pos/shared-data-type';
import { Box, Divider, Stack, Typography } from '@mui/material';
import AnswerIcon from 'assets/vaunce/image/answer.png';
import QuestionIcon from 'assets/vaunce/image/question.png';
import AnswerIcon2x from 'assets/vaunce/image/answer@2x.png';
import QuestionIcon2x from 'assets/vaunce/image/question@2x.png';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { useVaunceAppRouter } from '../routes';

import { FormattedDialog } from '../../components/FormattedDialog';
import GrayBox from '../../components/GrayBox';
import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';

export interface INormalQuestDialogProps {
	open: boolean;
	closeDialog: () => void;
	data: QuestionInfoResponseType;
}
export const NormalQuestDialog: React.FC<INormalQuestDialogProps> = ({
	open,
	closeDialog,
	data,
}) => {
	const { navigateOneToOne } = useVaunceAppRouter();
	const handleDeleteQuestion = async () => {
		closeDialog();
		const response = await VaunceAppApis.deleteQuestion(data.id);
		if (response) {
			data.status = 'DELETED';
			navigateOneToOne();
		}
	};
	return (
		<FormattedDialog
			open={open}
			closeDialog={closeDialog}>
			<Stack
				spacing={2}
				sx={{ paddingBottom: 5 }}>
				<Box>
					<Stack
						direction={'row'}
						spacing={1}
						alignItems={'center'}>
						<img
							src={QuestionIcon}
							srcSet={QuestionIcon2x}
							style={{ width: 18, height: 18 }}
						/>
						<Typography variant='h5'>{data.title}</Typography>
					</Stack>
					<Stack
						direction={'row'}
						spacing={1}>
						<Typography
							variant='h5'
							sx={{ color: vaunceAppColors.lightPink }}>
							{'문의 유형 :'}
						</Typography>
						<Typography variant='h5'>{QuestionFirstTypeLabel[data.questionType]} 문의</Typography>
					</Stack>
				</Box>
				<Stack spacing={2}>
					<Divider />
					<Typography variant='h5'>{data.memo}</Typography>
					<Divider />
				</Stack>
				<Stack>
					<Stack
						direction={'row'}
						spacing={1}
						alignItems={'center'}>
						<img
							src={AnswerIcon}
							srcSet={AnswerIcon2x}
							style={{ width: 18, height: 18 }}
						/>
						<Typography variant='h5'>{'답변'}</Typography>
					</Stack>
				</Stack>
				<GrayBox
					sx={{
						height: '163px',
					}}>
					{/* TODO 답변이 오지 않았을 경우 " " 이런식으로 api가 내려옴 */}
					{data.replyContents.length < 2 ? (
						<Typography variant='h5'>
							{data.replyContents.length < 2 ? '답변 미등록 상태입니다' : data.replyContents}
						</Typography>
					) : (
						<Typography
							variant='h5'
							dangerouslySetInnerHTML={{ __html: data.replyContents }}></Typography>
					)}
				</GrayBox>

				<Stack
					direction={'row'}
					spacing={2}
					marginTop={'25px'}>
					<RoundedButton
						sx={{ width: '50%', height: 60 }}
						disabled={data.replyContents.length < 2 ? false : true}
						onClick={handleDeleteQuestion}>
						문의취소
					</RoundedButton>
					<RoundedButton
						sx={{ width: '50%', height: 60 }}
						onClick={closeDialog}>
						닫기
					</RoundedButton>
				</Stack>
			</Stack>
		</FormattedDialog>
	);
};
