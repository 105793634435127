import { VaultCashHistoryDetailContents } from '@kinderlabs-pos/feature/pos/vault-cash';
import { VaultCashHistoryType } from '@kinderlabs-pos/shared-data-type';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Typography,
} from '@mui/material';

export const HistoryDetailDialog: React.FC<
	DialogProps & {
		closeDialog: () => void;
		historyContent?: VaultCashHistoryType;
	}
> = ({ open, closeDialog, historyContent }) => {
	// const cashStatus = VaultCashState.useStatus({ posId: checkMode.posId });

	return (
		<Dialog
			open={open}
			fullWidth={true}>
			<DialogTitle>
				<Typography variant="h1">시재 상세 조회</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{historyContent && <VaultCashHistoryDetailContents historyContent={historyContent} />}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => {
						closeDialog();
					}}>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	);
};
