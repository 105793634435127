import { KitchenOrderInfoType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { DialogContent } from '@mui/material';
import { KitchenOrderDetailContents } from '@kinderlabs-pos/feature/pos/kitchen-order';
import { Suspense } from 'react';

export interface IKitchenOrderDetailDialogProps {
	open: boolean;
	closeDialog: () => void;
	kitchenOrder: KitchenOrderInfoType;
}
export const KitchenOrderDetailDialog: React.FC<IKitchenOrderDetailDialogProps> = ({
	open,
	closeDialog,
	kitchenOrder,
}) => {
	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialogAction='닫기'
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 360 }}>
				<Suspense>
					<KitchenOrderDetailContents kitchenOrderInfo={kitchenOrder} />
				</Suspense>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
