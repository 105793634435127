import {
	has비공식영수증,
	OrderDetailType,
	PaymentInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CashReceiptRefundDialogContent } from '../CashReceiptDialog/CashReceiptRefundDialogContent';
import { CashReceiptSubmitDialogContent } from '../CashReceiptDialog/CashReceiptSubmitDialogContent';

interface ActionType {
	order: OrderDetailType;
	payment: OrderDetailType['payments'][number];
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
	closeDialog?: () => void;
	withRefunds: boolean;
}
export const PaymentDetail현금영수증Actions: React.FC<ActionType> = ({
	order,
	payment,
	withRefunds,
	onCompleteRefund,
	closeDialog,
}) => {
	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	useEffect(() => {
		return () => {
			handle결제모듈강제취소();
		};
	}, []);

	return (
		<Stack spacing={1}>
			<Stack
				height={40}
				direction={'row'}
				spacing={1}
				justifyContent='flex-end'>
				{has비공식영수증[payment.method] && !payment.refunded && !payment.receipt && (
					<WithDialog
						dialog={(open, closeDialog) => (
							<CashReceiptSubmitDialogContent
								payment={payment}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant={'outlined'}
								onClick={openDialog}
								sx={{ pl: 1, pr: 1 }}>
								<Typography variant={'body1'}>현금영수증 발급</Typography>
							</Button>
						)}
					</WithDialog>
				)}
				{withRefunds && !payment.refunded && (
					<WithDialog
						dialog={(open, closeDialog) => (
							<CashReceiptRefundDialogContent
								order={order}
								payment={payment}
								open={open}
								closeDialog={closeDialog}
								onCompleteRefund={onCompleteRefund}
							/>
						)}>
						{(openDialog) => (
							<Button
								onClick={openDialog}
								variant={'outlined'}
								sx={{ pl: 1, pr: 1 }}>
								<Typography variant={'body1'}>환불</Typography>
							</Button>
						)}
					</WithDialog>
				)}
				{closeDialog && (
					<Button
						variant='contained'
						onClick={closeDialog}>
						확인
					</Button>
				)}
			</Stack>
		</Stack>
	);
};
