import { DayCalendarFilterOptionType } from './CalendarFilterOptionType';
import { Pageable } from './PageType';

export type AdminVaultCashFilterType = {
	storeIdList?: number[];
	posId?: number;
	page: number;
	size?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	vaultCashCheckType?: VaultCashCheckType;
	sort?: Pageable['sort'];
};

export const CashUnitKeyTypeArray = [
	'cash50000',
	'cash10000',
	'cash5000',
	'cash1000',
	'cash500',
	'cash100',
	'cash50',
	'cash10',
] as const;
export type CashUnitKeyType = (typeof CashUnitKeyTypeArray)[number];
export type CashUnitType = Record<CashUnitKeyType, number>;

export const CashUnitTypeValue: Record<CashUnitKeyType, number> = {
	cash50000: 50000,
	cash10000: 10000,
	cash5000: 5000,
	cash1000: 1000,
	cash500: 500,
	cash100: 100,
	cash50: 50,
	cash10: 10,
};
export const CashUnitTypeIndex: Record<CashUnitKeyType, number> = {
	cash50000: 0,
	cash10000: 1,
	cash5000: 2,
	cash1000: 3,
	cash500: 4,
	cash100: 5,
	cash50: 6,
	cash10: 7,
};

export const VaultCashCheckTypeArray = ['OPEN', 'CLOSE', 'DEPOSIT', 'WITHDRAW', 'CHECK'] as const;
export type VaultCashCheckType = (typeof VaultCashCheckTypeArray)[number];
export const VaultCashCheckTypeValue: Record<VaultCashCheckType, string> = {
	OPEN: '영업 시작',
	CLOSE: '영업 마감',
	DEPOSIT: '입금',
	WITHDRAW: '출금',
	CHECK: '중간 점검',
};

export type VaultCashStatusType = {
	storeId: number;
	posId: number;
	cashUnits: CashUnitType;
	countedAmount: number;
	registerDate?: string;
	memo?: string;
};

/**
 * 시재 개장, 마감은 타입이 같다.
 */
export type VaultCashOpenCloseInfoType = {
	cashUnits: CashUnitType;
	totalCash: number;
	date: Date;
};

export type VaultCashStatusDetailType = VaultCashStatusType & {
	expectedAmount: number;
	overAndShort: number;
	deposits: number;
	withdraws: number;
	openInfo: VaultCashOpenCloseInfoType;
	closeInfo: VaultCashOpenCloseInfoType;
	createdDate: Date;
	modifiedDate: Date;
	createdBy: string;
	modifiedBy: string;
};

export type VaultCashHistoryType = {
	id: number;
	createdDate: Date;
	type: VaultCashCheckType;
	overAndShort: number;
	countedAmount: number;
	expectedAmount: number;
	cashUnits: {
		cash50000: number;
		cash10000: number;
		cash5000: number;
		cash1000: number;
		cash500: number;
		cash100: number;
		cash50: number;
		cash10: number;
	};
	storeId: number;
	posId: number;
	memoId: string;
	memo: string;
};

export type CashSalesType = {
	posId: number;
	from: string;
	to: string;
	cashAmount: number;
};
