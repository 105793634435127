import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockSubulStatisticsType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getStockSubulStatisticsAsync =
	(rootUrl: string) =>
	async ({ from, to, warehouseId }: GetStockSubulStatisticsReqType) =>
		(
			await posAxiosClient.get<StockSubulStatisticsType[]>(`${rootUrl}/statistics/stock`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					warehouseId,
				},
			})
		).data;

type GetStockSubulStatisticsReqType = {
	from: Dayjs;
	to: Dayjs;
	warehouseId?: number;
};
