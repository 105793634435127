import { EyeOutlined, PrinterOutlined, SelectOutlined } from '@ant-design/icons';
import { DdiziType, OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import {
	AdminProductInfoState,
	DdiziState,
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { IconButton, List, ListItem, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DdiziDisplay } from '../../../components/DdiziDisplay';
import { DdiziDetailDialog } from '../../DdiziManagementPage/DdiziDetailDialog';
import { usePosRouter } from '../../routes';

export const DdizListContentsInOrder = ({ order }: { order: OrderDetailType }) => {
	const { navigate퇴장관리 } = usePosRouter();
	const confirm = useConfirm();
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const handleClick = (ddiziId: string) => () => {
		confirm(`티켓 번호 ${ddiziId} 에 대해 퇴장 관리 화면으로 이동하시겠습니까?`, () => {
			navigate퇴장관리(ddiziId);
		});
	};

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId,
		deviceType: 'POS',
	});
	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList: curDeviceInfo.labelPrinterList,
	});

	const handlePrinterClick = (ddizi: DdiziType) => () => {
		confirm(`티켓 번호 ${ddizi.id} 를 재발권하시겠습니까?`, async () => {
			const ddiziData = { ...DdiziState.utils.getDdizi인쇄Data(ddizi) };
			await 띠지프린터라벨출력({
				...ddiziData,
				deviceType: 'POS',
				newDdizi:
					ddiziData.deviceNum === 'LabelPrinter1'
						? localSettings?.useNewDesignOnLabelPrinter1 ?? false
						: localSettings?.useNewDesignOnLabelPrinter2 ?? false,
			});

			await print영수증띠지Async({
				ddizi,
			});
		});
	};

	return (
		<Stack
			spacing={1}
			sx={{ overflowY: 'auto' }}>
			<List>
				{order.ddizis.map((ddizi, idx) => (
					<ListItem
						key={idx}
						sx={{ px: 0 }}>
						<DdiziDisplay
							sx={{ width: '100%' }}
							key={idx}
							ddizi={ddizi}
							userOnPage={'ORDER'}
							itemButton={
								<Stack direction={'row'}>
									<IconButton onClick={handlePrinterClick(ddizi)}>
										<PrinterOutlined style={{ fontSize: 18 }} />
									</IconButton>
									<WithDialog
										dialog={(open, closeDialog) => (
											<DdiziDetailDialog
												open={open}
												closeDialog={closeDialog}
												ddizi={ddizi}
											/>
										)}>
										{(openDialog) => (
											<IconButton onClick={openDialog}>
												<EyeOutlined style={{ fontSize: 18 }} />
											</IconButton>
										)}
									</WithDialog>
									<IconButton onClick={handleClick(ddizi.id)}>
										<SelectOutlined style={{ fontSize: 18 }} />
									</IconButton>
								</Stack>
							}
						/>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};
