import { ControlOutlined, FilterOutlined, SelectOutlined } from '@ant-design/icons';
import { DdiziDetailContent } from '@kinderlabs-pos/feature/pos/ddizis';
import { DayCalendarFilterOptionType, DdiziType } from '@kinderlabs-pos/shared-data-type';
import { getToday } from '@kinderlabs-pos/shared-util';
import { DdiziQueryState } from '@kinderlabs-pos/state';
import { CardStack, IconSquareButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	CalendarSelect,
	FullSizeCircularProgress,
	RotatingRefreshButton,
	SearchBox,
	useConfirm,
} from '@kinderlabs-pos/ui-components';
import { Box, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { atom, useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { usePosRouter } from '../routes';
import { DdiziFilterDialog, DdiziFilterType } from './DdiziFilterDialog';
import { DdiziListContent } from './DdiziListContent';
import TuneIcon from '@mui/icons-material/Tune';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';

const today = getToday();
export const DdiziManagementPage = ({}) => {
	const { searchParams, changeSearchParams } = useSearchParamsWrapper();
	const keyword = searchParams.get('keyword');
	const [filter, setFilter] = useState<DdiziFilterType>({});
	const selectedDdizi = useAtomValue(DdiziManagementPage.selectedDdizi);

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.hour(0).minute(0),
		to: today.endOf('day'),
	});

	useEffect(() => {
		setFilter((prev) => ({ ...prev, search: keyword || undefined }));
	}, [keyword]);

	const handleClickReload = () => {
		DdiziQueryState.invalidateQueries();
	};

	const hasFilter = filter.status || filter.targetPosId;
	const theme = useTheme();

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				flex={1}
				height={'100%'}
				direction={'column'}
				spacing={2}>
				<Stack
					direction={'row'}
					spacing={1}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<DdiziFilterDialog
								open={open}
								closeDialog={closeDialog}
								filter={filter}
								setFilter={setFilter}
							/>
						)}>
						{(openDialog) => (
							<IconSquareButton
								color={!!hasFilter ? theme.palette.primary.main : undefined}
								onClick={openDialog}>
								<TuneIcon />
							</IconSquareButton>
						)}
					</WithDialog>
					<RotatingRefreshButton handleClick={handleClickReload} />
					<DdiziPageSearchBox
						keyword={keyword}
						setSearch={(keyword: string) => {
							// 내부 검색은 replace True
							changeSearchParams({ keyword });
						}}
					/>
				</Stack>
				<Stack
					direction={'row'}
					spacing={1}
					justifyContent={'center'}>
					<Typography variant={'body1'}>
						{'티켓번호, 대표자 이름, 대표자 전화번호 뒷자리로 검색됩니다.'}
						{/* TODO: 추후 주문 번호 넣기 */}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 1, border: `1px solid ${grey[400]}`, pb: 1, overflowY: 'auto' }}>
					<Suspense>
						<DdiziListContent
							keyword={keyword || undefined}
							option={option}
							filter={filter}
						/>
					</Suspense>
				</Stack>
			</CardStack>
			{selectedDdizi && (
				<Stack
					spacing={1}
					width={380}
					height={'100%'}
					sx={{ overflowY: 'auto' }}>
					<CardStack
						flex={1}
						sx={{ overflowY: 'auto' }}>
						<Suspense fallback={<FullSizeCircularProgress />}>
							<Box flex={1}>
								<DdiziDetailContent
									ddizi={selectedDdizi}
									showPublishInfo
								/>
							</Box>
						</Suspense>
						<Divider />
						<GoExitPage ddizi={selectedDdizi} />
					</CardStack>
				</Stack>
			)}
			{!selectedDdizi && (
				<CardStack
					width={380}
					height={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ bgcolor: 'white', overflowY: 'auto' }}>
					<Typography
						sx={{ textAlign: 'center' }}
						variant={'subtitle1'}
						color={'text.secondary'}>
						티켓을 선택하고
						<br />
						상세 정보를 확인하세요.
					</Typography>
				</CardStack>
			)}
		</Stack>
	);
};
DdiziManagementPage.selectedDdizi = atom<DdiziType | undefined>(undefined);

const DdiziPageSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			width={220}
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};

const GoExitPage = ({ ddizi }: { ddizi: DdiziType }) => {
	const { navigate퇴장관리 } = usePosRouter();
	const confirm = useConfirm();
	const handleClickNavigate = (ddiziId: string) => () => {
		confirm(`티켓 번호 ${ddiziId} 에 대해 퇴장 관리 화면으로 이동하시겠습니까?`, () => {
			navigate퇴장관리(ddiziId);
		});
	};

	return (
		<Stack
			py={1}
			direction={'row'}
			alignItems={'center'}
			justifyContent={'flex-end'}>
			<Typography variant={'subtitle1'}>퇴장 관리 화면으로</Typography>
			<IconButton onClick={handleClickNavigate(ddizi.id)}>
				<SelectOutlined style={{ fontSize: 18 }} />
			</IconButton>
		</Stack>
	);
};
