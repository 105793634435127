import { Stack, Tab, Typography } from '@mui/material';
import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { TabPanel } from '../VclubPage';
import { VaunceClubTabPage } from './VaunceClubTab';
import { VauncePremiumClubTab } from './VauncePremiumClubTab';

export interface IMemberShipPageProps {}
export const MemberShipPage: React.FC<IMemberShipPageProps> = ({}) => {
	const [value, setValue] = React.useState<number>(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Stack height={'100%'}>
			<PageHeader title='멤버십안내' />

			<VauncePinkTabs
				value={value}
				onChange={handleChange}
				variant='fullWidth'>
				<Tab label={<Typography variant='h5'>바운스 클럽</Typography>} />
				<Tab label={<Typography variant='h5'>바운스 프리미엄 클럽</Typography>} />
			</VauncePinkTabs>

			<TabPanel
				value={value}
				index={0}
				overflow={'auto'}>
				<VaunceClubTabPage />
			</TabPanel>
			<TabPanel
				value={value}
				index={1}
				overflow={'auto'}>
				<VauncePremiumClubTab />
			</TabPanel>
		</Stack>
	);
};
