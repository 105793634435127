import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { DdiziType } from '@kinderlabs-pos/shared-data-type';

export const cancelExitAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		posId,
		ddiziId,
	}: {
		storeId: number;
		posId: number;
		ddiziId: DdiziType['id'];
	}) =>
		(
			await posAxiosClient.put<DdiziType>(
				`${rootUrl}/ddizi/store/${storeId}/pos/${posId}/ddizi/exit/cancel`,
				null,
				{
					params: {
						id: ddiziId,
					},
				}
			)
		).data;
