import { PosBffApi } from '@kinderlabs-pos/apis/pos';
import { guestMemberApis } from '@kinderlabs-pos/apis/waivers';
import {
	AppMemberInfoSearchType,
	GuestMemberInfoSearchType,
	GuestMemberInfoType,
	OrderType,
	Pageable,
	VaunceMemberInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { useDialogActions } from 'libs/ui/atoms/src/lib/WithDialog/useDialogActions';
import { useCallback, useState } from 'react';
import {
	AppMemberMultipleInfoListDialog,
	GuestMemberMultipleInfoListDialog,
} from '../MultipleUserInfoListDialog';

export type GuestMemberSearchingActionReturnType = {
	isSearching: boolean;
	isRendered: boolean;
	setIsRender: React.Dispatch<React.SetStateAction<boolean>>;
	result: GuestMemberInfoSearchType;
	setResult: React.Dispatch<React.SetStateAction<GuestMemberInfoSearchType>>;
	multipleUserInfoList: GuestMemberInfoType[];
	handleSearchByKeyword: (keyword: string) => Promise<void>;
	resetAllState: () => void;
	MultipleDialogComponent: () => JSX.Element;
};

export const useGetGuestMemberSearchingActions = (): GuestMemberSearchingActionReturnType => {
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [isRendered, setIsRender] = useState<boolean>(false);
	const [result, setResult] = useState<GuestMemberInfoSearchType>({
		info: undefined,
		status: '검색전',
	});

	const [multipleUserInfoList, setMultipleUserInfoList] = useState<GuestMemberInfoType[]>([]);
	const { open, openDialog, closeDialog } = useDialogActions();

	const handleSearchByKeyword = async (keyword: string) => {
		try {
			setIsSearching(true);
			const result = await guestMemberApis.getGuestMemberInfoList({
				search: keyword,
				pageable: Pageable.getPageable({
					page: 0,
					//DB에서 최대 검색 수 318개를 커버하기 위함
					size: 400,
				}),
			});
			if (result.content.length === 0) {
				setResult({
					info: undefined,
					status: '결과없음',
				});
			} else if (result.content.length === 1) {
				const targetUser = result.content[0];
				const { id, name, telephone } = targetUser;
				setResult({
					info: { memberId: id, name, telephone },
					status: '검색완료',
				});
			} else {
				setMultipleUserInfoList(result.content);
				openDialog();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsSearching(false);
		}
	};

	const MultipleDialogComponent = useCallback(
		() => (
			<GuestMemberMultipleInfoListDialog
				open={open}
				closeDialog={closeDialog}
				guestMemberInfoActions={{
					multipleUserInfoList,
					setGuestmemberInfo: (info: OrderType['guestMemberInfo']) => {
						setResult({ status: '검색완료', info });
					},
				}}
			/>
		),
		[multipleUserInfoList, result, open]
	);

	const resetAllState = () => {
		setResult({
			info: undefined,
			status: '검색전',
		});
		setIsRender(false);
	};

	return {
		isSearching,
		isRendered,
		setIsRender,
		result,
		setResult,
		multipleUserInfoList,
		handleSearchByKeyword,
		resetAllState,
		MultipleDialogComponent,
	};
};

export type AppMemberSearchingActionsReturnType = {
	result: AppMemberInfoSearchType;
	setResult: React.Dispatch<React.SetStateAction<AppMemberInfoSearchType>>;
	isSearching: boolean;
	multipleUserInfoList: VaunceMemberInfoType[];
	handleSearchByKeyword: (keyword: string) => Promise<void>;
	MultipleDialogComponent: () => JSX.Element;
};

export const useGetAppMemberSearchingActions = (): AppMemberSearchingActionsReturnType => {
	const { open, openDialog, closeDialog } = useDialogActions();
	const [result, setResult] = useState<AppMemberInfoSearchType>({
		info: undefined,
		status: '검색전',
	});
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [multipleUserInfoList, setMultipleUserInfoList] = useState<VaunceMemberInfoType[]>([]);
	const handleSearchByKeyword = async (keyword: string) => {
		try {
			setIsSearching(true);
			const res = await PosBffApi.getSearchMember({ search: keyword });

			if (!res || res.length === 0) {
				setResult({
					info: undefined,
					status: '결과없음',
				});
			} else if (res.length === 1) {
				const targetUser = res[0];
				setResult({
					info: targetUser,
					status: '검색완료',
				});
			} else {
				setMultipleUserInfoList(res);
				openDialog();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsSearching(false);
		}
	};

	const MultipleDialogComponent = useCallback(
		() => (
			<AppMemberMultipleInfoListDialog
				open={open}
				closeDialog={closeDialog}
				appUserMemberInfoActions={{
					multipleUserInfoList: multipleUserInfoList,
					setAppMemberInfo: (info: VaunceMemberInfoType) => setResult({ info, status: '검색완료' }),
				}}
			/>
		),
		[open, multipleUserInfoList, result]
	);

	return {
		result,
		setResult,
		isSearching,
		multipleUserInfoList,
		handleSearchByKeyword,
		MultipleDialogComponent,
	};
};
