import { Stack } from '@mui/material';

import { withProfiler } from '@sentry/react';
import { useRoutes } from 'react-router-dom';
import { allRoutes } from '../pages/routes';
import { FirebaseAnalytics } from './FirebaseAnalytics';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { useEffect } from 'react';
import { VaunceWebAppApis } from '@kinderlabs-pos/apis/vaunce';
import { useIdle } from 'react-use';
import { generateSessionID } from '../utils/TechLabsLogUtils';

export function App() {
	FirebaseAnalytics();

	// @ts-ignore
	const routes = useRoutes(allRoutes);
	const accessToken = localStorage.getItem('accessToken');
	const isIdle = useIdle(30 * 60 * 1000);
	if (isIdle) {
		localStorage.setItem('sessionId', generateSessionID());
	}

	return (
		<Stack
			height={'100%'}
			sx={{
				// overflowY: 'hidden',
				overflow: 'hidden',
				touchAction: 'none',
				userSelect: 'none',
				overscrollBehavior: 'none',
			}}>
			{accessToken && <WebAppPushTokenSetter accessToken={accessToken} />}
			{routes}
		</Stack>
	);
}

const WebAppPushTokenSetter = ({ accessToken }: { accessToken: string }) => {
	const setting = VaunceQueryState.useSettingQuery();

	// 최초 접속시 1번 수행
	// setting 이 invalidate 가 풀렸을땐 물론 다시 수행
	useEffect(() => {
		if (accessToken) {
			console.log('PUSH Token 갱신', setting);

			const pushAgreed = setting.push === true;
			const adAgreed = setting.ad === 'AGREE';

			// 원래 아래처럼 해야 하는데, user Table 에만 있는 adAgreed만 조치 안함.
			// if (pushAgreed && adAgreed) {
			if (adAgreed) {
				VaunceWebAppApis.sendPushTokenInApp(accessToken);
			}
		}
	}, [setting, accessToken]);

	return <></>;
};

export default withProfiler(App);
