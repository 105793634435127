import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorOrderStatisticsType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getVendorOrderStatisticsAsync =
	(rootUrl: string) =>
	async ({ vendorId, from, to, warehouseId }: GetVendorOrderStatisticsReqType) =>
		(
			await posAxiosClient.get<VendorOrderStatisticsType[]>(`${rootUrl}/statistics/vendor`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					vendorId,
					warehouseId,
				},
			})
		).data;

type GetVendorOrderStatisticsReqType = {
	from: Dayjs;
	to: Dayjs;
	vendorId?: number;
	warehouseId?: number;
	// productInfoId?: number;
};
