import { RoundedButton } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Stack,
	styled,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React, { Fragment } from 'react';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { useVaunceAppRouter } from '../routes';
import { TabPanel } from '../VclubPage';

interface Column {
	id: string;
	label: string;
	minWidth?: number;
	format?: (value: number) => string;
}

const groupImage = [
	'image/group01.png',
	'image/group02.png',
	'image/group03.png',
	'image/group04.png',
	'image/group05.png',
	'image/group06.png',
];

const groupColumn: Column[] = [
	{ id: '구분', label: '구분', minWidth: 120 },
	{ id: '대상', label: '대상', minWidth: 170 },
];

const groupRows: Record<string, string>[] = [
	{
		구분: '어린이',
		대상: '어린이집·유치원',
	},
	{
		구분: '학교·학원',
		대상: '초·중·고등학교, 학원',
	},
	{
		구분: '일반',
		대상: '교회, 소규모 단체, 동호회, 대학교, 성인 단체',
	},
];

const partyColumn: Column[] = [
	{ id: '베이직 파티', label: '베이직 파티', minWidth: 170 },
	{ id: '테마 파티', label: '테마 파티', minWidth: 100 },
];

const partyRows: Record<string, string>[] = [
	{
		'베이직 파티': '시설 이용 2시간(세션)',
		'테마 파티': '시설 이용 3시간(세션)',
	},
	{
		'베이직 파티': '파티음식',
		'테마 파티': '파티음식 + 보호자 음료 제공',
	},
	{
		'베이직 파티': '파티룸 데코레이션',
		'테마 파티': '파티룸 데코레이션',
	},
	{
		'베이직 파티': '파티전담 HERO 배치',
		'테마 파티': '파티전담 HERO 배치',
	},
	{
		'베이직 파티': 'GRIP양말 제공',
		'테마 파티': 'GRIP양말 제공',
	},
	{
		'베이직 파티': '웰컴보드',
		'테마 파티': '웰컴보드',
	},
];

const partyImage = [
	'image/party01.png',
	'image/party02.png',
	'image/party03.png',
	'image/party04.png',
	'image/party05.png',
	'image/party06.png',
];

const workShopColumn: Column[] = [
	{ id: '대상', label: '대상', minWidth: 120 },
	{ id: '제공사항', label: '제공사항', minWidth: 170 },
];

const workShopImage = [
	'image/workshop01.png',
	'image/workshop02.png',
	'image/workshop03.png',
	'image/workshop04.png',
	'image/workshop05.png',
	'image/workshop06.png',
];

export interface IReservationPageProps {}
export const ReservationPage: React.FC<IReservationPageProps> = ({}) => {
	const {} = useVaunceAppRouter();
	const [tab, setTab] = React.useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
		setTab(newTab);
	};

	return (
		<Stack
			height={'100%'}
			flexDirection={'column'}>
			<PageHeader title='파티/단체예약' />

			<VauncePinkTabs
				value={tab}
				onChange={handleTabChange}
				variant='fullWidth'>
				<Tab label='그룹' />
				<Tab label='파티' />
				<Tab label='워크숍' />
			</VauncePinkTabs>

			<TabPanel
				overflow={'auto'}
				value={tab}
				index={0}>
				<GroupReservationTabPanel />
			</TabPanel>

			<TabPanel
				overflow={'auto'}
				value={tab}
				index={1}>
				<PartyReservationTabPanel />
			</TabPanel>

			<TabPanel
				value={tab}
				index={2}>
				<WorkShopReservationTabPanel />
			</TabPanel>
		</Stack>
	);
};

const GroupReservationTabPanel = () => {
	const { navigatePartyQuestionStepOne } = useVaunceAppRouter();
	return (
		<>
			<Typography
				variant='h3'
				margin={'1.5rem 0 1.5rem'}>
				프로그램 안내
			</Typography>
			<BasicTable
				columns={groupColumn}
				rows={groupRows}
			/>
			<Typography
				variant='h3'
				marginBottom={'1.5rem'}>
				인원
			</Typography>
			<Typography
				variant='h5'
				marginBottom={'1.5rem'}>
				15명 이상 시 단체 요금 적용
			</Typography>

			<ImageGroup imageList={groupImage} />
			<RoundedButton
				sx={{ width: '100%', height: 60 }}
				onClick={navigatePartyQuestionStepOne}>
				예약문의
			</RoundedButton>
		</>
	);
};

const PartyReservationTabPanel = () => {
	const { navigatePartyQuestionStepOne } = useVaunceAppRouter();
	return (
		<>
			<Typography
				variant='h3'
				margin={'1.5rem 0 1.5rem'}>
				프로그램 안내
			</Typography>
			<BasicTable
				columns={partyColumn}
				rows={partyRows}
			/>

			<ImageGroup imageList={partyImage} />
			<RoundedButton
				sx={{ width: '100%', height: 60 }}
				onClick={navigatePartyQuestionStepOne}>
				예약문의
			</RoundedButton>
		</>
	);
};

const WorkShopReservationTabPanel = () => {
	const { navigatePartyQuestionStepOne } = useVaunceAppRouter();
	return (
		<>
			<Typography
				variant='h3'
				margin={'1.5rem 0 1.5rem'}>
				프로그램 안내
			</Typography>
			<SpanningTable columns={workShopColumn} />
			<Typography
				variant='h3'
				marginBottom={'1.5rem'}>
				인원
			</Typography>
			<Typography
				variant='h5'
				marginBottom={'1.5rem'}>
				20명 이상 시 단체 요금 적용
			</Typography>

			<ImageGroup imageList={workShopImage} />
			<RoundedButton
				sx={{ width: '100%', height: 60 }}
				onClick={navigatePartyQuestionStepOne}>
				예약문의
			</RoundedButton>
		</>
	);
};
interface ImageGroupProps {
	imageList: string[];
}

const ImageGroup: React.FC<ImageGroupProps> = ({ imageList }) => {
	return (
		<ImageRowBox>
			{imageList.map((imageUrl) => (
				<img
					key={imageUrl}
					src={`https://vaunce-app.cloud/${imageUrl}`}
					style={{ borderRadius: '12px', boxShadow: '0 0 20px rgb(0 0 0 / 16%)' }}
				/>
			))}
		</ImageRowBox>
	);
};

interface BasicTableProps {
	columns: Column[];
	rows: Record<string, string>[];
}
const BasicTable: React.FC<BasicTableProps> = ({ columns, rows }) => {
	return (
		<Box marginBottom={'1.5rem'}>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table
					stickyHeader
					aria-label='sticky table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={'center'}
									style={{ minWidth: column.minWidth, fontSize: '14px', fontWeight: '500' }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, idx) => {
							return (
								<TableRow
									hover
									role='checkbox'
									tabIndex={-1}
									key={idx}>
									{columns.map((column, index) => {
										const value = row[column.id];
										return (
											<TableCell
												colSpan={index === 1 ? 5 : 0}
												key={column.id}>
												{value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

interface SpanningTableProps {
	columns: Column[];
}

const rows = [
	{
		name: '기업',
		detail: ['2시간/3시간 선택 가능', '워크샵 진행 HERO 배치', 'GRIP 양말', '식사 (별도문의)'],
	},
];

const SpanningTable: React.FC<SpanningTableProps> = ({ columns }) => {
	return (
		<Box marginBottom={'1.5rem'}>
			<TableContainer sx={{ maxHeight: 440 }}>
				<Table
					stickyHeader
					aria-label='sticky table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={'center'}
									style={{ minWidth: column.minWidth, fontSize: '1.5em', fontWeight: '500' }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((item) => (
							<Fragment key={item.name}>
								<TableRow>
									<TableCell
										align={'center'}
										rowSpan={item.detail.length + 1}>
										{item.name}
									</TableCell>
								</TableRow>
								{item.detail.map((detail, index) => (
									<TableRow key={index}>
										<TableCell align={'center'}>{detail}</TableCell>
									</TableRow>
								))}
							</Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export const ImageRowBox = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'grid',
	gridTemplateColumns: `repeat(13, auto)`,
	gridColumnGap: '9px',
	flexWrap: 'nowrap',
	overflowX: 'auto',
	paddingBottom: '1.5rem',
	marginBottom: '1.5rem',
}));
