import { PaymentInfoType, PaymentMethodTypeLabel } from '@kinderlabs-pos/shared-data-type';
import {
	canceledStyleUtils,
	getDateFormat,
	getDateTimeFormat,
	getTimeFormat,
	numberWithCommasAndWon,
} from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

export const PaymentDisplay = ({
	payment,
	selected = false,
	variant,
}: {
	payment: PaymentInfoType;
	selected?: boolean;
	variant: 'withId' | 'withoutId';
}) =>
	// withId 는 결제페이지에서 사용 / withoutId 는 주문페이지에서 사용
	variant === 'withId' ? (
		<PaymentDisplayVariantWithId
			selected={selected}
			payment={payment}
		/>
	) : (
		<PaymentDisplayVariantWithoutId
			selected={selected}
			payment={payment}
		/>
	);

const PaymentDisplayVariantWithId = ({
	payment,
	selected = false,
}: {
	payment: PaymentInfoType;
	selected?: boolean;
}) => {
	const deviceInfo = AdminPosInfoState.useDeviceInfo({
		storeId: payment.storeId,
		deviceId: payment.storeId,
	});
	return (
		<Stack
			width={'100%'}
			spacing={1.5}
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}>
			<Stack
				flex={1}
				alignItems={'flex-start'}>
				<Typography
					variant={'h6'}
					sx={{
						...canceledStyleUtils(!!payment.refunded),
					}}>
					{`${payment.id}`}
				</Typography>
				<Typography
					variant={'body1'}
					sx={{
						...canceledStyleUtils(!!payment.refunded),
					}}>
					{`${numberWithCommasAndWon(payment.amount)}`}
				</Typography>
				<Typography
					variant={'body1'}
					sx={{
						...canceledStyleUtils(!!payment.refunded),
					}}>
					{`${payment.receipt ? payment.receipt.approvalId : '-'}`}
				</Typography>
			</Stack>
			<Button
				disabled={!!payment.refunded}
				size={'small'}
				variant={selected ? 'contained' : 'outlined'}>
				{PaymentMethodTypeLabel[payment.method]}
			</Button>
			<Stack alignItems={'center'}>
				<Typography
					variant='body2'
					color={'text.secondary'}
					sx={{ ...canceledStyleUtils(!!payment.refunded) }}>
					{deviceInfo?.type === 'KIOSK' ? 'KIOSK' : ''}
				</Typography>
				<Typography
					variant={'subtitle2'}
					sx={{ ...canceledStyleUtils(!!payment.refunded) }}>
					{`${getTimeFormat(payment.created)}`}
				</Typography>
			</Stack>
		</Stack>
	);
};

export const PaymentDisplayVariantWithoutId = ({
	payment,
	selected = false,
}: {
	payment: PaymentInfoType;
	selected?: boolean;
}) => (
	<Stack
		direction={'row'}
		sx={{ flex: 1 }}
		alignItems={'center'}
		spacing={1}>
		<Stack>
			<Button
				disabled={!!payment.refunded}
				size={'small'}
				variant={selected ? 'contained' : 'outlined'}>
				{PaymentMethodTypeLabel[payment.method]}
			</Button>
		</Stack>
		<Stack
			flex={1}
			alignItems={'flex-end'}>
			<Typography
				variant='h4'
				sx={{
					textAlign: 'right',
					flex: 1,
					...canceledStyleUtils(!!payment.refunded),
				}}>
				{`${numberWithCommasAndWon(payment.amount)}`}
			</Typography>
			{payment.receipt && (
				<Typography variant='body2'>{`승인 #${payment.receipt.approvalId}`}</Typography>
			)}
			{payment.created && (
				<Typography
					variant='body1'
					sx={{ textAlign: 'right', flex: 1, ...canceledStyleUtils(!!payment.refunded) }}
					color={'text.secondary'}>
					{dayjs(payment.created).isToday()
						? getTimeFormat(payment.created)
						: `${getDateFormat(payment.created)} ${getTimeFormat(payment.created)}`}
				</Typography>
			)}
		</Stack>
	</Stack>
);
