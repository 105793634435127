import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderType } from '@kinderlabs-pos/shared-data-type';

export const updateOrderMemberAsync =
	(rootUrl: string) =>
	async ({ orderId, memberInfo }: { orderId: string; memberInfo: OrderType['guestMemberInfo'] }) =>
		(await posAxiosClient.put(`${rootUrl}/order/${orderId}/member`, memberInfo)).data;

export const deleteOrderMemberAsync = (rootUrl: string) => async (orderId: string) =>
	(await posAxiosClient.delete(`${rootUrl}/order/${orderId}/member`)).data;
