import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deletePosBoardInfoAsync =
	(rootUrl: string) =>
	async ({ posBoardId }: DeletePosBoardInfoRequestType) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/pos-board`, {
				params: {
					id: posBoardId,
				},
			})
		).data;

export type DeletePosBoardInfoRequestType = { posBoardId: number };
