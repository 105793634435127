import {
	CartLineInfoType,
	ProductDiscountMgInfoType,
	getDiscountLabel,
} from '@kinderlabs-pos/shared-data-type';
import { AdminProductDiscountInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const ProuductDiscountSelect = ({
	selectedProductList,
	selectedProductDiscount,
	setSelectedProductDiscount,
}: {
	selectedProductList: (CartLineInfoType & { selected: boolean })[];
	selectedProductDiscount?: ProductDiscountMgInfoType;
	setSelectedProductDiscount: (input?: ProductDiscountMgInfoType) => void;
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { data } = useQuery({
		...AdminProductDiscountInfoState.keys.all(storeInfo.id),
		refetchOnWindowFocus: true,
	});
	const productDiscountList = data ?? [];

	const filteredProductDiscountList = getFilteredProductDiscountList({
		productDiscountList,
		selectedProductList,
	});

	return (
		<Stack
			sx={{ overflow: 'auto' }}
			spacing={1}>
			{filteredProductDiscountList.map((discount, idx) => {
				if (!discount) return;
				return (
					<Button
						key={idx}
						sx={{ py: 2 }}
						size={'large'}
						variant={selectedProductDiscount?.id === discount.id ? 'contained' : 'outlined'}
						onClick={() => setSelectedProductDiscount(discount)}>
						<Stack
							width={'100%'}
							alignItems={'flex-start'}>
							<Typography variant={'h3'}>{discount.name}</Typography>
							<Typography variant={'subtitle1'}>{getDiscountLabel(discount)}</Typography>
							{discount.discountProductWhitelist && (
								<Typography variant='caption'>
									{discount.discountProductWhitelist.length === 0
										? ' 전상품'
										: getApplicableProductNameList({
												productWhiteList: discount.discountProductWhitelist,
												selectedProductList: selectedProductList,
										  })}
								</Typography>
							)}
						</Stack>
					</Button>
				);
			})}
		</Stack>
	);
};

const getFilteredProductDiscountList = ({
	productDiscountList,
	selectedProductList,
}: {
	productDiscountList: ProductDiscountMgInfoType[];
	selectedProductList: (CartLineInfoType & { selected: boolean })[];
}) => {
	const selectedProductIdList = selectedProductList.map((product) => product.productInfoId);
	return productDiscountList
		.filter((discount) => !discount.isDisabled)
		.map((discount) => {
			const list = discount.discountProductWhitelist;
			if (!list) return;
			if (list.length === 0) return discount;
			else
				return list.some((productId) => selectedProductIdList.includes(productId))
					? discount
					: null;
		});
};

const getApplicableProductNameList = ({
	productWhiteList,
	selectedProductList,
}: {
	productWhiteList: number[];
	selectedProductList: (CartLineInfoType & { selected: boolean })[];
}): string => {
	const filteredProductNameList = selectedProductList
		.filter((product) => productWhiteList.includes(product.productInfoId || 0))
		.map((filteredProduct) => filteredProduct.name);

	if (filteredProductNameList.length < 4) {
		return ` ${filteredProductNameList.join(',')}`;
	} else {
		const productNameListToBeShown = filteredProductNameList.slice(0, 3);

		return ` ${productNameListToBeShown.join(',')} 외 ${filteredProductNameList.length - 3}개`;
	}
};
