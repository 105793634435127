import {
	CenterCollectionsType,
	HoursValueType,
	HoursValueTypeLabel,
	PartyQuestionType,
	PartyTypeLabel,
	QuestionInfoResponseType,
} from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, TextField, Typography, styled } from '@mui/material';
import { FormattedDialog } from '../../components/FormattedDialog';
import GrayBox from '../../components/GrayBox';

export interface IPartyQuestDialogProps {
	open: boolean;
	closeDialog: () => void;
	data: QuestionInfoResponseType;
}
export const PartyQuestDialog: React.FC<IPartyQuestDialogProps> = ({ open, closeDialog, data }) => {
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	return (
		<FormattedDialog
			open={open}
			closeDialog={closeDialog}>
			<Stack
				spacing={4}
				sx={{ paddingBottom: 5 }}
				flex={1}>
				<Typography variant='h3'>{'답변작성'}</Typography>
				<GrayBox
					sx={{
						height: '163px',
					}}>
					{/* TODO 답변이 오지 않았을 경우 " " 이런식으로 api가 내려옴 */}
					{data.replyContents.length < 2 ? (
						<Typography variant='h4'>
							{data.replyContents.length < 2 ? '답변 미등록 상태입니다' : data.replyContents}
						</Typography>
					) : (
						<Typography
							variant='h4'
							dangerouslySetInnerHTML={{ __html: data.replyContents }}></Typography>
					)}
				</GrayBox>
				<Typography variant='h3'>{'프로그램 정보'}</Typography>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>센터</Typography>}
					value={centerCollectionData.find((item) => item.centerId === +data.center)?.centerDisplay}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>상품</Typography>}
					value={PartyTypeLabel[data.goods as PartyQuestionType]}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<Typography variant='h3'>{'신청인 정보'}</Typography>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>이름</Typography>}
					value={data.memberinfoName}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>전화번호</Typography>}
					value={data.memberinfoPhone}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>회원이름</Typography>}
					value={data.memberinfoName}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<Typography variant='h3'>{'이용 정보'}</Typography>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>이용인원</Typography>}
					value={data.numberOfUsers}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<ReadOnlyTextField
					fullWidth
					label={<Typography variant='h5'>예약일</Typography>}
					value={data.usedate}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
				<Typography variant='h5'>희망이용시간</Typography>
				<Stack
					direction={'row'}
					spacing={1}
					alignItems={'baseline'}
					justifyContent={'center'}>
					<ReadOnlyBox>
						<Typography variant='h5'>{data.usehour?.slice(0, 2)}</Typography>
					</ReadOnlyBox>
					<ReadOnlyBox>
						<Typography variant='h5'>{data.useminute === '0' ? '00' : data.useminute}</Typography>
					</ReadOnlyBox>
					<Typography variant='h5'>{'부터 ~'}</Typography>
					<ReadOnlyBox>
						<Typography variant='h5'>
							{data.usehours}시간
							{/* {data && HoursValueTypeLabel[data.usehours as HoursValueType]} */}
						</Typography>
					</ReadOnlyBox>
				</Stack>
				<Typography variant='h3'>{'추가문의사항'}</Typography>
				<TextField
					fullWidth
					value={data.memo}
					variant='filled'
					multiline
					rows={5}
					sx={{ fontSize: '1.5em' }}
					InputProps={{
						readOnly: true,
						style: { fontSize: '16px', fontWeight: 500 },
					}}
				/>
			</Stack>
		</FormattedDialog>
	);
};

const ReadOnlyTextField = styled(TextField)(() => ({
	variant: 'standard',
	fontSize: '1.5em',
	pt: 2,
}));

const ReadOnlyBox = styled(Box)(() => ({
	flex: 1,
	fontSize: '1.5em',
	borderBottom: `1px solid darkGray`,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));
