import { LoadingOutlined } from '@ant-design/icons';
import {
	CartLineOnlineTicketOnoffmixInfoType,
	CartLineTypeLabel,
	OnlineTicketChannelTypeLabel,
	OrderDetailType,
} from '@kinderlabs-pos/shared-data-type';
import {
	OnlineTicketQueryState,
	OrderQueryState,
	StoreInfoState,
	onlineTicketKeys,
} from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, Stack, StackProps, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export interface IOnlineTicketsDisplayProps extends StackProps {
	orderInfo: OrderDetailType;
	cartLine: CartLineOnlineTicketOnoffmixInfoType;
	itemButton?: ReactNode;
	isSimple?: boolean;
}
export const OnlineTicketsDisplay: React.FC<IOnlineTicketsDisplayProps> = ({
	orderInfo,
	cartLine,
	itemButton,
	isSimple = false,
	...stackProps
}) => {
	const onlineTicketCancelMutation = OrderQueryState.useCancelOnlineTicketMutation();

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { data } = useQuery({
		...onlineTicketKeys.list({ storeId, search: cartLine.ticketUsageInfo.ticketUsageId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	// 임시
	const ot = data ?? [];

	const customConfirm = useConfirm();
	const handleClickOnlineTicketCancel = () => {
		customConfirm('온라인권을 사용 취소하시겠습니까?', () => {
			onlineTicketCancelMutation.mutate({
				orderId: orderInfo.id,
				cartLine,
			});
		});
	};
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			{...stackProps}>
			<Stack
				alignItems={'center'}
				sx={{ minWidth: 80, pr: 1 }}>
				<Button
					size='small'
					sx={{ cursor: 'none' }}
					color={'success'}
					variant='outlined'>
					{CartLineTypeLabel[cartLine.type]}
				</Button>
			</Stack>
			<Stack
				flex={1}
				overflow='auto'>
				<Stack
					minWidth={190}
					flex={1}>
					<Typography
						variant={'h6'}
						sx={{
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflowX: 'hidden',
						}}>{`${cartLine.name}`}</Typography>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}>
						{ot.length > 0 && ot[0].ticketChannel && (
							<Typography
								variant='body1'
								fontWeight={'bold'}
								color='primary'>
								{OnlineTicketChannelTypeLabel[ot[0].ticketChannel]}
							</Typography>
						)}
						<Typography
							variant={'body1'}
							color={'text.secondary'}>{`#${cartLine.ticketUsageInfo.ticketUsageId}`}</Typography>
					</Stack>
				</Stack>
				<Stack
					direction='row'
					justifyContent={'flex-end'}
					alignItems={'center'}>
					<Button
						size='small'
						sx={{ width: 120 }}
						onClick={handleClickOnlineTicketCancel}
						disabled={orderInfo.status === 'CANCELED' || onlineTicketCancelMutation.isLoading}
						startIcon={onlineTicketCancelMutation.isLoading && <LoadingOutlined />}
						variant='outlined'>
						사용취소
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
