import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getGroupBy } from '@kinderlabs-pos/shared-util';
import { OrderQueryState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { OrderPage } from '.';
import { OrderDisplay } from './OrderDisplay';
import { OrderFilterType } from './OrderFilterDialog';

export const OrderListContent = ({
	filter,
	keyword,
	option,
}: {
	keyword?: string;
	filter: OrderFilterType;
	option: DayCalendarFilterOptionType;
}) => {
	const [selectedOrderId, selectOrderId] = useAtom(OrderPage.selectedOrder);
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const {
		data: orderDataPage,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...OrderQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				keyword: keyword,
				status: filter.status,
				type: filter.type,
				posIdList: filter.targetPosId ? [filter.targetPosId] : undefined,
				...option,
			},
		}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		refetchOnWindowFocus: true,
	});

	const orderData = useMemo(
		() => (orderDataPage ? orderDataPage.pages.flatMap((data) => data.content) : []),
		[orderDataPage]
	);

	const ordersGroupBy = getGroupBy(orderData, (order) => getDateFormat(order.created));

	useEffect(() => {
		if (selectedOrderId)
			if (!orderData.map((order) => order.id).includes(selectedOrderId)) selectOrderId(undefined);
	}, [selectedOrderId, orderData]);

	return (
		<List sx={{ flex: 1, py: 0, overflowY: 'auto' }}>
			{[...Object.keys(ordersGroupBy)].map((key, idx) => (
				<Fragment key={idx}>
					<ListSubheader
						sx={(theme) => ({
							py: 1,
							borderBottom: `1px solid ${theme.palette.divider}`,
						})}>
						<Typography variant={'body1'}>{key}</Typography>
					</ListSubheader>
					{ordersGroupBy[key].map((order) => {
						return (
							<Fragment key={order.id}>
								<SelectableListItem
									selected={order.id === selectedOrderId}
									onClick={() => selectOrderId(order.id)}>
									<OrderDisplay order={order} />
								</SelectableListItem>
								<Divider component={'li'} />
							</Fragment>
						);
					})}
				</Fragment>
			))}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
