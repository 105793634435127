import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';

interface GetAllGuestVisitsProps {
	storeId: number;
	posId: number;
	guestVisitId: number;
	status: GuestVisitType['status'];
}
export const changeGuestVisitStatus =
	(rootUrl: string) =>
	async ({ storeId, posId, guestVisitId, status }: GetAllGuestVisitsProps) =>
		(
			await posAxiosClient.post<GuestVisitType>(
				`${rootUrl}/guest-visit/store/${storeId}/pos/${posId}/guest-visit`,
				{},
				{
					params: {
						id: guestVisitId,
						status: status,
					},
				}
			)
		).data;
