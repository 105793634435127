import { Box, styled } from '@mui/material';
import plusImage from 'assets/vaunce/image/photoplus.png';
import plusImage2X from 'assets/vaunce/image/photoplus@2x.png';

export interface IPlusBoxProps {}
export const PlusBox: React.FC<IPlusBoxProps> = ({}) => {
	return (
		<label htmlFor='profileImg'>
			<StylePlusBox>
				<img
					src={plusImage}
					srcSet={plusImage2X}
					width={24}
					height={24}
				/>
			</StylePlusBox>
		</label>
	);
};

const StylePlusBox = styled(Box)(({ theme }) => ({
	backgroundColor: `${theme.palette.primary.main}`,
	borderRadius: '50%',
	cursor: 'pointer',
	padding: '3px 3px 3px',
}));
