import { ReceiptAPI } from '@kinderlabs-pos/apis/pos';
import { useSortedCartLines } from '@kinderlabs-pos/feature/pos/cart-table';
import { ReceiptInfoType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import produce from 'immer';
import { QueryClient } from '../QueryClient';

const receiptKeys = createQueryKeys('receipt', {
	byPaymentId: ({
		paymentId,
		type,
	}: {
		paymentId: string;
		type: ReceiptInfoType['receiptType'];
	}) => {
		const sortCartLines = useSortedCartLines();
		return {
			queryKey: [paymentId, type],
			queryFn: async () => {
				const { order, payment, receipt } = await ReceiptAPI.getReceiptForOrder({
					paymentId,
					type,
				});

				return {
					order: produce(order, (draft) => {
						draft.cart.lines = sortCartLines(draft.cart.lines);
					}),
					payment,
					receipt,
				};
			},
		};
	},
});
const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: receiptKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: receiptKeys._def });
};

export const ReceiptQueryState = {
	keys: receiptKeys,
	invalidateQueries,
};
