import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deletePosInfoAsync =
	(rootUrl: string) =>
	async ({ idList }: DeletePosInfoRequestType) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/store/pos/list`, {
				params: {
					idList,
				},
			})
		).data;

export type DeletePosInfoRequestType = { idList: number[] };
