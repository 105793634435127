import { BaseInfoType, StoreInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updateStoreInfoAsync =
	(rootUrl: string) => async (updateStoreInfoRequestInfo: UpdateStoreInfoRequestType) =>
		(
			await posAxiosClient.put<StoreInfoType & BaseInfoType>(
				`${rootUrl}/store/`,
				{
					...updateStoreInfoRequestInfo,
				},
				{
					params: {
						id: updateStoreInfoRequestInfo.id,
					},
				}
			)
		).data;

export type UpdateStoreInfoRequestType = StoreInfoType;
