import { CashUnitType } from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat } from '@kinderlabs-pos/shared-util';
import { VaultCashState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

export const LoadPrevStatusDialog = ({
	open,
	closeDialog,
	numBills,
	setNumBills,
	posId,
}: {
	open: boolean;
	closeDialog: () => void;
	numBills: CashUnitType;
	setNumBills: React.Dispatch<React.SetStateAction<CashUnitType>>;
	posId: number;
}) => {
	const { data } = useQuery(VaultCashState.keys.posStatus(posId));

	const handleLoad = () => {
		if (data) setNumBills(data.closeInfo.cashUnits);
		closeDialog();
	};

	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={<Typography variant='h1'>직전 시재 불러오기</Typography>}>
			<DialogContent>
				{data ? (
					<Stack
						direction='column'
						minHeight={100}
						justifyContent='center'
						alignItems='center'
						spacing={1}>
						<Typography
							variant='h1'
							color='red'>
							{getDateTimeFormat(data.closeInfo.date)}
						</Typography>
						<Typography>이전에 마감된 시재를 불러오시겠습니까?</Typography>
					</Stack>
				) : (
					<Typography>직전에 마감된 시재가 없습니다</Typography>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleLoad}
					variant='contained'>
					확인
				</Button>
				<Button
					onClick={closeDialog}
					variant='outlined'>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
