import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import GrayBox from '../../components/GrayBox';
import { PageHeader } from '../../components/PageHeader';
export interface IEventDetailPageProps {}
export const EventDetailPage: React.FC<IEventDetailPageProps> = ({}) => {
	const { id } = useParams();

	const event = VaunceQueryState.useEventOneQuery(id as string);
	if (!event) return <></>;
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='이벤트 상세내용' />
			<Stack
				flex={1}
				overflow={'auto'}
				spacing={2}>
				<GrayBox sx={{ overflowY: 'visible' }}>
					<Typography variant='h4'>{event.title}</Typography>
					<Stack
						direction={'row'}
						spacing={1}>
						<Typography
							variant='h6'
							fontWeight={400}>
							기간 : {event.startDate}
						</Typography>
						<Typography
							variant='h6'
							fontWeight={400}>
							~
						</Typography>
						<Typography
							variant='h6'
							fontWeight={400}>
							{' '}
							{event.endDate}
						</Typography>
					</Stack>
				</GrayBox>
				<img src={event.contentsImageUrl2X} />
			</Stack>
		</Stack>
	);
};
