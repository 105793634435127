import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OnlineTicketType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';

const searchOnlineTicketAsync =
	(rootUrl: string) =>
	async ({ storeId, search }: { storeId: number; search: string }) => {
		return (
			await posAxiosClient.get<OnlineTicketType[]>(`${rootUrl}/pos-bff/online-ticket/search`, {
				params: {
					storeId,
					search,
				},
			})
		).data;
	};

const useOnlineTicketAsync =
	(rootUrl: string) =>
	async ({ storeId, ticketId, posId }: { ticketId: number; storeId: number; posId: number }) => {
		return (
			await posAxiosClient.get<OnlineTicketType>(
				`${rootUrl}/pos-bff/online-ticket/store/${storeId}/pos/${posId}/use`,
				{
					params: {
						ticketId,
					},
				}
			)
		).data;
	};

const cancelUseOnlineTicketAsync =
	(rootUrl: string) =>
	async ({ storeId, ticketId, posId }: { ticketId: number; storeId: number; posId: number }) => {
		return (
			await posAxiosClient.get<OnlineTicketType>(
				`${rootUrl}/pos-bff/online-ticket/store/${storeId}/pos/${posId}/use-cancel`,
				{
					params: {
						ticketId,
					},
				}
			)
		).data;
	};

const getStatisticsAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		from,
		to,
	}: {
		storeIdList: number[];
		posIdList?: number[];
		from: string;
		to: string;
	}) => {
		return (
			await posAxiosClient.get<OnlineTicketType>(`${rootUrl}/pos-bff/online-ticket/statistics`, {
				params: {
					storeIdList,
					posIdList,
					from,
					to,
				},
			})
		).data;
	};

export const OnlineTicketAPI = {
	search: searchOnlineTicketAsync(rootUrl),
	use: useOnlineTicketAsync(rootUrl),
	cancelUse: cancelUseOnlineTicketAsync(rootUrl),
	getStatistics: getStatisticsAsync(rootUrl),
};
