// 우선 가상카드만을 위해서 임시로 만든것. 쓸진 모르겠지만 나중에 논의하고 타입을 뜯어고쳐야할듯
export const CardMainIssuerNameArray = [
	'비씨카드',
	'국민카드',
	'하나카드',
	'신한카드',
	'NH카드',
	'롯데카드',
	'삼성카드',
	'현대카드',
	'우리카드',
] as const;
export const CardSubIssuerNameArray = [
	'수협카드',
	'씨티카드',
	'제주비자',
	'광주비자',
	'전북카드',
	'해외JCB',
	'해외다이너스/디스커버',
	'해외비자',
	'해외마스터',
	'해외은련카드',
] as const;
export const CardIssuerNameArray = [...CardMainIssuerNameArray, ...CardSubIssuerNameArray];
export type CardIssuerNameType = typeof CardIssuerNameArray[number];

export type CardIssureInfoType = {
	issuerName: CardIssuerNameType;
	issuerCode: number;
	issuerVendor: string;
	issuerCategory: string;
};
