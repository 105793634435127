import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductDiscountMgInfoType } from '@kinderlabs-pos/shared-data-type';

export const disableProductDiscountInfoAsync =
	(rootUrl: string) =>
	async ({ discountId, isDisabled }: { discountId: number; isDisabled: boolean }) =>
		(
			await posAxiosClient.put<ProductDiscountMgInfoType>(
				`${rootUrl}/product-discount/disable`,
				null,
				{
					params: {
						id: discountId,
						isDisabled,
					},
				}
			)
		).data;
