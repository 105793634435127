import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { WarehouseInfoType } from '@kinderlabs-pos/shared-data-type';

export const getWarehouseInfoListByStoreIdList =
	(rootUrl: string) => async (storeIdList?: number[]) =>
		(
			await posAxiosClient.get<WarehouseInfoType[]>(`${rootUrl}/warehouse/store`, {
				params: {
					storeIdList,
				},
			})
		).data;
