import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderType } from '@kinderlabs-pos/shared-data-type';

export const postponeOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, orderId }: RequestType) =>
		(
			await posAxiosClient.post<OrderType>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/order/postpone`,
				null,
				{
					params: { orderId },
				}
			)
		).data;

export type RequestType = {
	storeId: number;
	posId: number;
	orderId: string;
};
