import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import {
	CartLineInfoType,
	CartLineTypeRules,
	PosBoardItemType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { useAtomValue } from 'jotai';
import { useHandleClickPosBoardItem } from './handleClickPosBoardItem';
import { PosBoardBase } from './PosBoardBase';
import { ProductBoardActionBoardItem } from './ProductBoardActionBoardItem';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { ProductSetSelectDialog } from './ProductSetSelectDialog';
import { useState } from 'react';

export const PosBoardInMainPage = ({}) => {
	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);
	const [selectedSetProductInfo, setSelectedSetProductInfo] = useState<ProductInfoType | undefined>(
		undefined
	);

	return (
		<WithDialog
			dialog={(open, closeDialog) =>
				selectedSetProductInfo ? (
					<ProductSetSelectDialog
						productInfo={selectedSetProductInfo}
						open={open}
						closeDialog={closeDialog}
					/>
				) : null
			}>
			{(openDialog) => (
				<PosBoardBase
					renderItem={({ item, productInfo, idx }) => (
						<ProductBoardActionBoardItemInMainPge
							productInfo={productInfo}
							selectedCartLine={selectedCartLine}
							openSetProductDialog={(productInfo) => {
								setSelectedSetProductInfo(productInfo);
								openDialog();
							}}
							item={item}
							key={idx}
						/>
					)}
				/>
			)}
		</WithDialog>
	);
};

const ProductBoardActionBoardItemInMainPge = ({
	productInfo,
	item,
	selectedCartLine,
	openSetProductDialog,
}: {
	productInfo: Omit<ProductInfoType, 'usedInPosBoard'>;
	item: PosBoardItemType;
	selectedCartLine?: CartLineInfoType;
	openSetProductDialog: (productInfo: Omit<ProductInfoType, 'usedInPosBoard'>) => void;
}) => {
	const disabled =
		productInfo.type === 'OPTION' &&
		(!selectedCartLine || !CartLineTypeRules.canClickOptionOnBoard[selectedCartLine.type]);
	const handleClickBoardItem = useHandleClickPosBoardItem();

	const handleClickItem = () => {
		if (productInfo.type === 'SET') {
			openSetProductDialog(productInfo);
		} else {
			handleClickBoardItem({
				cartLineId: getUuidV4(),
				productInfo,
			});
		}
	};

	return (
		<ProductBoardActionBoardItem
			productInfo={productInfo}
			item={item}
			disabled={disabled}
			onClick={handleClickItem}
		/>
	);
};
