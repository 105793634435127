import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import photoImage from 'assets/vaunce/image/photo_exmample.png';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';

export interface IUserPhotoNoticeDialogProps {
	open: boolean;
	onClose: () => void;
}
export const UserPhotoNoticeDialog: React.FC<IUserPhotoNoticeDialogProps> = ({ open, onClose }) => {
	return (
		<Dialog
			maxWidth={'lg'}
			open={open}
			onClose={onClose}
			fullWidth>
			<DialogContent>
				<Stack
					spacing={2}
					direction={'column'}
					sx={{ pb: 5 }}
					textAlign={'center'}>
					<Typography
						color={'primary'}
						variant='h4'
						fontWeight={700}>
						사진 등록 예시
					</Typography>
					<Box textAlign={'center'}>
						<img
							src={photoImage}
							height={192}
							width={241}
						/>
					</Box>

					<Stack
						direction={'row'}
						justifyContent={'center'}
						spacing={1}
						flexWrap={'wrap'}>
						<Typography variant='h4'>위의 사진처럼 얼굴 식별이 가능한</Typography>
						<Typography
							color={'primary'}
							variant='h4'>
							정방형(1:1)
						</Typography>
						<Typography variant='h4'>사진을 선택하여 등록해 주세요 </Typography>
					</Stack>

					<Typography
						color={'primary'}
						variant='h4'>
						한번 등록된 사진은 변경 불가합니다.
					</Typography>

					<RoundedButton
						data-cy='registerCmtPhotoNoticeBtn'
						onClick={onClose}
						sx={{ height: 58, backgroundColor: '#FFFFFF', color: vaunceAppColors.lightPink }}>
						확인
					</RoundedButton>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
