import { getAnalytics } from '@firebase/analytics';
import { logEvent, setUserProperties } from 'firebase/analytics';

type EventContentType = 'agree' | 'route' | 'login' | 'submit';
export const loggingEvent = (logType: string, contentType: EventContentType, contentId: string) => {
	const analytics = getAnalytics();
	logEvent(analytics, logType, {
		content_type: contentType,
		content_id: contentId,
	});
};

export const loggingUserCenter = (centerName: string) => {
	const analytics = getAnalytics();
	setUserProperties(analytics, { favorite_center: centerName });
};
