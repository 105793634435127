import {
	CartLineDiscountCouponUsageInfoType,
	CartLineTicketUsageInfoType,
	CartLineTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { Button, Stack, StackProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface IAppTicketsDisplayProps extends StackProps {
	cartLine: CartLineTicketUsageInfoType | CartLineDiscountCouponUsageInfoType;
	itemButton?: ReactNode;
	isSimple?: boolean;
}
export const AppTicketsDisplay: React.FC<IAppTicketsDisplayProps> = ({
	cartLine,
	itemButton,
	isSimple = false,
	...stackProps
}) => {
	return (
		<Stack
			direction={'row'}
			spacing={0.5}
			alignItems={'center'}
			{...stackProps}>
			<Stack
				alignItems={'center'}
				sx={{ minWidth: 80, pr: 1 }}>
				<Button
					size='small'
					sx={{ cursor: 'none' }}
					variant='outlined'>
					{CartLineTypeLabel[cartLine.type]}
				</Button>
			</Stack>
			<Stack
				direction={'column'}
				minWidth={190}
				flex={1}>
				<Typography
					variant={'h6'}
					sx={{
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflowX: 'hidden',
					}}>{`${cartLine.name}`}</Typography>
				<Typography
					variant={'body1'}
					color={'text.secondary'}>{`#${cartLine.ticketUsageInfo.ticketUsageId}`}</Typography>
			</Stack>
		</Stack>
	);
};
