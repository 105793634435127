import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { isSnsMode } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Badge, Box, Stack, Typography } from '@mui/material';
import noImage from 'assets/vaunce/image/noimage.png';
import { useRef, useState } from 'react';
import { ProfileImageBox } from '../../components/CommonStyled';
import { PlusBox } from '../../components/PlusBox';

export interface IProfileImgPartProps {}
export const ProfileImgPart: React.FC<IProfileImgPartProps> = ({}) => {
	const userInfo = VaunceQueryState.useUserInfoQuery();

	const [imageFile, setImageFile] = useState<string>(userInfo.infoProfileImageUrl);
	const fileInput = useRef<HTMLInputElement | null>(null);

	const userProfileImageQuery = VaunceQueryState.useProfileImageQuery();

	const handleChangeProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = (event.target.files as FileList)[0];

		if (file) {
			const maxSizeInBytes = 5 * 1024 * 1024; // 5MB 제한
			if (file.size > maxSizeInBytes) {
				alert('파일 크기가 너무 큽니다. 3MB 이하의 파일을 선택해주세요.');
				fileInput.current = null;
			} else {
				let imageFormData = new FormData();
				imageFormData.append('file', file);

				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setImageFile(reader.result as string);
				};

				userProfileImageQuery.mutate(imageFormData);
			}
		}
	};
	return (
		<Stack alignItems={'center'}>
			<ProfileImageBox>
				<form>
					<input
						type='file'
						style={{ display: 'none' }}
						accept='image/*'
						onChange={handleChangeProfileImage}
						ref={fileInput}
						id='profileImg'
					/>
				</form>

				<Badge
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					sx={{
						'& .MuiBadge-badge': {
							bottom: 12,
							right: 12,
						},
					}}
					badgeContent={<PlusBox />}>
					<img
						src={imageFile ? imageFile : noImage}
						width={64}
						height={64}
						style={{ borderRadius: '50%' }}
					/>
				</Badge>
			</ProfileImageBox>
			<Box>
				<Typography variant='h3'>{userInfo.infoName}</Typography>

				{/* sns id가 infoEmail로 옴  */}
				{!isSnsMode() && (
					<Typography
						variant='h6'
						sx={{ opacity: 0.6 }}>
						{userInfo.infoEmail}
					</Typography>
				)}
			</Box>
		</Stack>
	);
};
