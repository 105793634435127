import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { DialogProps, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedDialog } from '../../components/FormattedDialog';

const noticeContent: { content: string; childContent?: string[] }[] = [
	{
		content:
			'바운스 내 모든 활동은 부상의 위험이 있으므로 직원의 안내와 지시 사항을 준수해 주시기 바랍니다. 이용·안전수칙에 관련된 직원의 지시에 불응할 시 퇴장 조치될 수 있습니다.',
	},
	{
		content: '입장 전 바운스 안전 수칙을 숙지해 주시기 바랍니다.',
	},
	{
		content: '입장 전 준비운동을 철저히 해주시기 바랍니다.',
	},
	{
		content:
			'놀이 시설물 안으로 음식물 및 음료 반입을 금지하고, 껌이나 음식물을 입에 넣은 채로 시설을 이용하지 않습니다.',
	},
	{
		content:
			'트램폴린 전용 미끄럼방지 양말을 착용하지 않은 고객님 또는 보호자는 놀이시설 밖에서 촬영을 진행해 주시기 바랍니다.(단 시설 이용을 원하는 고객님의 경우 이용권까지 구매 후 이용)',
	},
	{
		content:
			'개인물건은 락커에 보관해 주시기 바랍니다. 고객의 부주의로 인한 물건의 분실·도난의 책임은 고객 본인이 부담합니다.',
	},
	{
		content: '다음 사항에 해당되시는 고객께서는 시설 이용이 제한됩니다.',
		childContent: [
			'임산부 및 만 60세 이상 어르신(단, 유아보호자로서 입장 가능)',
			'수술, 척추지환, 골절 등의 질병을 최근 6개월 이내 앓으신분',
			'미끄럼 방지 양말을 착용하지 않은자',
			'음주 및 금지된 약물 복용자',
			'타인에게 피해주는자',
			'시설 안전 이용 수칙동의서에 서명하지 않은자',
		],
	},
	{
		content: '본인의 부주의로 인한 사고에 대해서는 책임지지 않습니다',
	},
	{
		content: '보호자는 본 내용에 관해 인지하신 후 고객(자녀)에게 고지해야 합니다.',
	},
];

export interface INoticeBeforeUsingFacilityDialogProps extends DialogProps {
	open: boolean;
	onClose: () => void;
}

const NoticeBeforeUsingFacilityDialog: React.FC<INoticeBeforeUsingFacilityDialogProps> = ({
	open,
	onClose,
}) => {
	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack
				spacing={2}
				sx={{ pb: 5 }}>
				<Typography
					variant='h3'
					color={vaunceColors.lightPink}
					fontWeight={700}>
					[필수] 시설 이용 전 안내 사항
				</Typography>
				<Divider />
				<ol>
					{noticeContent.map((item, idx) => (
						<>
							<Typography
								variant='h5'
								key={idx}>
								<li>{item.content}</li>
							</Typography>
							{item.childContent &&
								item.childContent.map((item, idx) => (
									<Typography
										key={idx}
										variant='h5'>
										-{item}
									</Typography>
								))}
						</>
					))}
				</ol>
			</Stack>
		</FormattedDialog>
	);
};

export default NoticeBeforeUsingFacilityDialog;
