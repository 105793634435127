import {
	KitchenOrderInfoType,
	KitchenOrderMethodType,
	kitchenOrderMethodTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import {
	AdminKitchenSystemInfoState,
	KitchenOrderQueryState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
	Badge,
	Button,
	CircularProgress,
	Divider,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { KitchenOrderDisplay } from 'apps/pos-main/src/pages/KitchenOrderPage/KitchenOrderDisplay';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { KitchenOrderActionButtons } from '../../../KitchenOrderKitchenButtons';
import { CompleteButton } from '../buttons/CompleteButton';
import { DeleteButton } from '../buttons/DeleteButton';
import { KitchenOrderDetailDialog } from './KitchenOrderDetailDialog';

export interface IKitchenOrderPannelsProps {
	tabValue: 'ALL' | 'ORDER-COOK' | 'COMPLETE-CANCEL';
	closePannel: () => void;
}

const todayStart = dayjs().startOf('day');
const todayEnd = dayjs().endOf('day');
export const KitchenOrderPannels: React.FC<IKitchenOrderPannelsProps> = ({
	tabValue,
	closePannel,
}) => {
	const confirm = useConfirm();
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const kitchenSystemInfo = StoreInfoState.useKitchenSystemInfo();

	const {
		data: kitchenOrderPage,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...KitchenOrderQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				kitchenSystemId: kitchenSystemInfo?.id,
				statusList:
					tabValue === 'ALL'
						? []
						: tabValue === 'ORDER-COOK'
						? ['ORDERED', 'COOKING']
						: ['COMPLETED', 'CANCELED'],
				from: todayStart,
				to: todayEnd,
			},
		}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const kitchenOrderData = useMemo(() => {
		if (!kitchenOrderPage) return [];
		else return kitchenOrderPage.pages.flatMap((data) => data.content);
	}, [kitchenOrderPage]);

	const filteredKitchenOrder = kitchenOrderData.filter(
		(order) => order.status === 'ORDERED' || order.status === 'COOKING'
	);

	const updateStatus = KitchenOrderQueryState.updateKitchenOrderStatus();
	const handleSwitchStatus = (targetStatusMethod: KitchenOrderMethodType) => {
		confirm(
			`총 ${filteredKitchenOrder.length}개의 상품을 ${kitchenOrderMethodTypeLabel[targetStatusMethod]} 상태로 전체 변경하시겠습니까?`,
			() =>
				updateStatus.mutate(
					{
						kitchenOrderId: filteredKitchenOrder.map((order) => order.id),
						kitchenOrderLineIds: undefined,
						targetStatus: targetStatusMethod,
					},
					{
						onSuccess: () => {
							closePannel && closePannel();
						},
					}
				)
		);
	};

	return (
		<List>
			{tabValue === 'ORDER-COOK' && (
				<Stack
					direction={'row'}
					spacing={3}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'flex-end'}
					p={1}>
					<CompleteButton
						disabled={filteredKitchenOrder.length === 0}
						onClick={() => handleSwitchStatus('complete')}
						label={'전체 조리 완료'}
					/>
					<DeleteButton
						disabled={filteredKitchenOrder.length === 0}
						onClick={() => handleSwitchStatus('cancel')}
						label={'전체 조리 취소'}
					/>
				</Stack>
			)}
			{kitchenOrderData.map((kitchenOrder, idx) => {
				return (
					<Stack
						key={idx}
						py={1}>
						<WithDialog
							key={idx}
							dialog={(open, closeDialog) => (
								<KitchenOrderDetailDialog
									open={open}
									closeDialog={closeDialog}
									kitchenOrder={kitchenOrder}
								/>
							)}>
							{(openDialog) => (
								<KitchenOrderDisplay
									kitchenOrder={kitchenOrder}
									openDialog={openDialog}
								/>
							)}
						</WithDialog>
						<ListItem>
							<Stack
								direction={'row'}
								spacing={1}
								justifyContent={'space-between'}
								width={'100%'}>
								<KitchenOrderActionButtons
									kitchOrder={kitchenOrder}
									closePannel={closePannel}
								/>
								<KitchenCallButton kitchenOrderInfo={kitchenOrder} />
							</Stack>
						</ListItem>
						<Divider />
					</Stack>
				);
			})}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};

interface IKitchenCallButtonProps {
	kitchenOrderInfo: KitchenOrderInfoType;
}
const KitchenCallButton: React.FC<IKitchenCallButtonProps> = ({ kitchenOrderInfo }) => {
	const { kitchenSystemId, id, kitchenOrderCalls, telephone, dailySeq } = kitchenOrderInfo;

	const { data: kitchenSystemInfo } = useQuery({
		...AdminKitchenSystemInfoState.keys.detail(kitchenSystemId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const updateKitchenOrderCall = KitchenOrderQueryState.updateKitchenOrderCall();
	const callKitchenOrder = async () => {
		await updateKitchenOrderCall.mutateAsync(
			{ kitchenOrderId: id },
			{
				onSuccess: () => {},
			}
		);
	};

	if (!kitchenSystemInfo || kitchenSystemInfo.callingMethod === 'NONE') return null;
	return (
		<Badge
			badgeContent={kitchenOrderCalls.length}
			color={'primary'}>
			<Button
				onClick={callKitchenOrder}
				variant='outlined'
				color='success'>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Stack>
						<Stack
							spacing={1}
							direction={'row'}
							alignItems={'center'}
							justifyContent={'center'}>
							<NotificationsIcon />
							<Typography variant={'subtitle1'}>{'호출'}</Typography>
						</Stack>
						{kitchenOrderInfo.telephone && (
							<Typography variant={'body2'}>
								{getTelephoneNumberWithDash(kitchenOrderInfo.telephone)}
							</Typography>
						)}
					</Stack>
				</Stack>
			</Button>
		</Badge>
	);
};
