import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	CmtInfoListFilterType,
	CmtInfoType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';

export const getCmtInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: CmtInfoListFilterType) =>
		(
			await posAxiosClient.get<PageType<CmtInfoType & BaseInfoType>>(`${rootUrl}/cmt/list`, {
				params: {
					...options,
					...Pageable.getPageable({
						page,
						size,
						sort,
					}).getPageableQueryParam(),
				},
			})
		).data;
