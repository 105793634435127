import { rootUrl } from '../../const';
import { canDeleteWarehouseInfoAsync } from './canDeleteWarehouseInfoAsync';
import { creaateWarehouseInfoAsync } from './createWarehouseInfoAsync';
import { deleteWarehouseInfoAsync } from './deleteWarehouseInfoAsync';
import { getWarehouseInfoAsync } from './getWarehouseInfoAsync';
import { getWarehouseInfoListAsync } from './getWarehouseInfoListAsync';
import { getWarehouseInfoListByStoreIdList } from './getWarehouseInfoListByStoreIdListAsync';
import { updateWarehouseInfoAsync } from './updateWarehouseInfoAsync';

export const AdminWarehouseInfoApis = {
	getInfo: getWarehouseInfoAsync(rootUrl),
	getInfoList: getWarehouseInfoListAsync(rootUrl),
	createInfo: creaateWarehouseInfoAsync(rootUrl),
	deleteInfo: deleteWarehouseInfoAsync(rootUrl),
	updateInfo: updateWarehouseInfoAsync(rootUrl),
	canDeleteInfo: canDeleteWarehouseInfoAsync(rootUrl),
	getWarehouseInfoList: getWarehouseInfoListByStoreIdList(rootUrl),
};
