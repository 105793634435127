import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';

export interface PointPaymentInfoToRefundType extends PaymentInfoType {
	method: 'POINT';
}

export const useHandlePointFormik = ({
	payment,
	order,
	onComplete,
}: {
	payment: PointPaymentInfoToRefundType;
	order: OrderDetailType;
	onComplete?: () => void;
}) => {
	const confirm = useConfirm();
	const refund = PaymentState.actions.refund.useRefund();

	return useFormik<{ submit: string | null }>({
		initialValues: {
			submit: null,
		},
		onSubmit: async (values, { setErrors }) => {
			confirm(
				dayjs(payment.created).isToday()
					? '환불하시겠습니까?'
					: '오늘 결제한 건이 아니면 환불시 문제가 될수 있습니다. 환불하시겠습니까?',
				async () => {
					try {
						await refund({
							payment: payment,
							order: order,
							method: payment.method,
						});
						onComplete && onComplete();
					} catch (e) {
						//@ts-ignore
						setErrors({ submit: e.message });
					}
				}
			);
		},
	});
};
