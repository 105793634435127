import { AdminWarehouseInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AdminWarehouseInfoListFilterType,
	WarehouseInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const warehouseInfoKeys = createQueryKeys('warehouseInfo', {
	list: ({ params }: { params: AdminWarehouseInfoListFilterType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminWarehouseInfoApis.getInfoList(params);
			return res;
		},
	}),
	detail: (warehouseId?: number) => ({
		queryKey: [warehouseId],
		queryFn: async () => {
			if (!warehouseId) return null;
			const res = await AdminWarehouseInfoApis.getInfo(warehouseId);
			return res;
		},
	}),
	listByStoreId: (storeIdList?: number[]) => ({
		queryKey: [storeIdList],
		queryFn: async () => {
			const res = await AdminWarehouseInfoApis.getWarehouseInfoList(storeIdList);
			return res;
		},
	}),
	canDelete: (warehouseId: number) => ({
		queryKey: [warehouseId],
		queryFn: async () => {
			return await AdminWarehouseInfoApis.canDeleteInfo(warehouseId);
		},
	}),
});

const useCreateWarehouseInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<WarehouseInfoType, 'id' | 'storeId'> & { storeId: number | null }) => {
			await AdminWarehouseInfoApis.createInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateWarehouseInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<WarehouseInfoType, 'storeId'> & { storeId: number | null }) => {
			await AdminWarehouseInfoApis.updateInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteWarehouseInfo = () => {
	return QueryClient.useMutation(
		async (warehouseId: number) => {
			await AdminWarehouseInfoApis.deleteInfo(warehouseId);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: warehouseInfoKeys._def });
};

export const AdminWarehouseInfoState = {
	keys: warehouseInfoKeys,

	createInfo: useCreateWarehouseInfo,
	deleteInfo: useDeleteWarehouseInfo,
	updateInfo: useUpdateWarehouseInfo,
};
