import { AdminWarehouseOrderApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	Pageable,
	WarehouseOrderRequestType,
	WarehouseOrderStatusType,
} from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

type WarehouseOrderInfoListParamsType = {
	warehouseIdList?: number[];
	status?: WarehouseOrderStatusType;
	search?: string;
	page: number;
	size: number;
	sort?: Pageable['sort'];
	from: Dayjs;
	to: Dayjs;
};

const warehouseOrderKeys = createQueryKeys('warehouse-order', {
	list: ({ params }: { params: WarehouseOrderInfoListParamsType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminWarehouseOrderApis.getWarehouseOrderList(params);
			return res;
		},
	}),
	detail: (warehouseOrderId?: number) => ({
		queryKey: [warehouseOrderId],
		queryFn: async () => {
			if (!warehouseOrderId) return null;
			const res = AdminWarehouseOrderApis.getDetailWarehouseOrderInfo(warehouseOrderId);
			return res;
		},
	}),
});

const createWarehouseOrder = () => {
	return QueryClient.useMutation(
		async (data: WarehouseOrderRequestType) => {
			await AdminWarehouseOrderApis.createWarehouseOrder(data);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const updateWarehouseOrderStatus = () => {
	return QueryClient.useMutation(
		async (
			params: Parameters<typeof AdminWarehouseOrderApis.updateWarehouseOrderStatus>[number]
		) => {
			await AdminWarehouseOrderApis.updateWarehouseOrderStatus(params);
		},
		{
			onSuccess: () => invalidateQueries(),
		}
	);
};

const updateWarehouseOrderModifyStatus = () => {
	return QueryClient.useMutation(
		async (
			params: Parameters<typeof AdminWarehouseOrderApis.updateWarehouseOrderModifyStatus>[number]
		) => {
			await AdminWarehouseOrderApis.updateWarehouseOrderModifyStatus(params);
		},
		{
			onSuccess: () => invalidateQueries(),
		}
	);
};

const uploadWarehouseFile = () => {
	return QueryClient.useMutation(
		async ({ id, fileData }: { id?: number; fileData: FormData }) => {
			if (!id) return;
			await AdminWarehouseOrderApis.uploadWarehouseOrderFiles({
				id: id,
				fileData: fileData,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const deleteWarehouseOrderFileInfo = () => {
	return QueryClient.useMutation(
		async ({ orderId, fileId }: { orderId?: number; fileId: number }) => {
			if (!orderId) return;
			await AdminWarehouseOrderApis.deleteWarehouseOrderFiles({
				orderId,
				fileId,
			});
		},
		{
			onSuccess: () => invalidateQueries(),
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: warehouseOrderKeys._def });
};

export const AdminWarehouseOrderInfoState = {
	keys: warehouseOrderKeys,

	createOrder: createWarehouseOrder,
	updateStatus: updateWarehouseOrderStatus,
	updateModifyStatus: updateWarehouseOrderModifyStatus,
	uploadFiles: uploadWarehouseFile,
	deleteFiles: deleteWarehouseOrderFileInfo,
};
