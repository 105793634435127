import { InputLabel, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import React from 'react';

export interface INumberFormProps extends Omit<TextFieldProps, 'fullWidth' | 'size' | 'variant'> {
	defaultPlaceholder?: string;
	helperText?: string;
	changed?: boolean;
	readOnly?: boolean;
	disabledHelperText?: boolean;
	isDisabledRequired?: boolean;
}
export const NumberForm: React.FC<INumberFormProps> = ({
	label,
	InputProps,
	helperText = 'ㅤ',
	changed = false,
	defaultPlaceholder,
	placeholder,
	readOnly = false,
	disabledHelperText = false,
	isDisabledRequired = false,
	...others
}) => {
	return (
		<Stack>
			<InputLabel>
				<Typography
					variant={'body2'}
					color={changed ? 'primary' : 'unset'}>
					{label}
				</Typography>
			</InputLabel>
			<TextField
				fullWidth
				disabled={isDisabledRequired}
				type={'number'}
				size={'small'}
				placeholder={`${placeholder}` || defaultPlaceholder}
				InputProps={{
					...InputProps,
					readOnly: readOnly,
				}}
				{...others}
				helperText={
					!disabledHelperText && (
						<Typography
							variant='caption'
							component={'span'}
							color={'error'}>
							{helperText}
						</Typography>
					)
				}
			/>
		</Stack>
	);
};
