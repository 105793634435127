import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PosBoardInfoType } from '@kinderlabs-pos/shared-data-type';

export const getPosBoardInfoListInStoreAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<PosBoardInfoType[]>(`${rootUrl}/pos-board/list/in-store`, {
				params: {
					id: storeId,
				},
			})
		).data;
