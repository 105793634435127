import { Box, Grid, Stack, styled, Tab, Typography } from '@mui/material';
import firstMenuImage1 from 'assets/vaunce/image/infotab01.png';
import firstMenuImage2 from 'assets/vaunce/image/infotab02.png';
import firstMenuImage3 from 'assets/vaunce/image/infotab04.png';
import firstMenuImage4 from 'assets/vaunce/image/infotab09.png';
import firstMenuImage5 from 'assets/vaunce/image/infotab06.png';
import firstMenuImage6 from 'assets/vaunce/image/infotab07.png';
import firstMenuImage7 from 'assets/vaunce/image/infotab08.png';

import firstMenuImage1x from 'assets/vaunce/image/infotab01@2x.png';
import firstMenuImage2x from 'assets/vaunce/image/infotab02@2x.png';
import firstMenuImage3x from 'assets/vaunce/image/infotab04@2x.png';
import firstMenuImage4x from 'assets/vaunce/image/infotab09@2x.png';
import firstMenuImage5x from 'assets/vaunce/image/infotab06@2x.png';
import firstMenuImage6x from 'assets/vaunce/image/infotab07@2x.png';
import firstMenuImage7x from 'assets/vaunce/image/infotab08@2x.png';

import React from 'react';
import { TabPanel } from '../VclubPage';

import secondMenuImage1 from 'assets/vaunce/image/usetab01.png';
import secondMenuImage2 from 'assets/vaunce/image/usetab03.png';
import secondMenuImage3 from 'assets/vaunce/image/usetab02.png';
import secondMenuImage4 from 'assets/vaunce/image/usetab06.png';
import secondMenuImage5 from 'assets/vaunce/image/usetab07.png';
import secondMenuImage6 from 'assets/vaunce/image/usetab08.png';

import secondMenuImage1x from 'assets/vaunce/image/usetab01@2x.png';
import secondMenuImage2x from 'assets/vaunce/image/usetab03@2x.png';
import secondMenuImage3x from 'assets/vaunce/image/usetab02@2x.png';
import secondMenuImage4x from 'assets/vaunce/image/usetab06@2x.png';
import secondMenuImage5x from 'assets/vaunce/image/usetab07@2x.png';
import secondMenuImage6x from 'assets/vaunce/image/usetab08@2x.png';

import { MyInfoBodyType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';

import thirdMenuImage1 from 'assets/vaunce/image/centertab01.png';
import thirdMenuImage2 from 'assets/vaunce/image/centertab02.png';
import thirdMenuImage3 from 'assets/vaunce/image/centertab03.png';
import thirdMenuImage4 from 'assets/vaunce/image/centertab04.png';
import thirdMenuImage5 from 'assets/vaunce/image/centertab05.png';
import thirdMenuImage6 from 'assets/vaunce/image/centertab06.png';
import qrImage from 'assets/vaunce/image/iconqr.png';
import plusImage from 'assets/vaunce/image/magnifyplus.png';

import thirdMenuImage1x from 'assets/vaunce/image/centertab01@2x.png';
import thirdMenuImage2x from 'assets/vaunce/image/centertab02@2x.png';
import thirdMenuImage3x from 'assets/vaunce/image/centertab03@2x.png';
import thirdMenuImage4x from 'assets/vaunce/image/centertab04@2x.png';
import thirdMenuImage5x from 'assets/vaunce/image/centertab05@2x.png';
import thirdMenuImage6x from 'assets/vaunce/image/centertab06@2x.png';
import qrImagex from 'assets/vaunce/image/iconqr.png';
import plusImagex from 'assets/vaunce/image/magnifyplus.png';

import qrImage2x from 'assets/vaunce/image/iconqr@2x.png';
import plusImage2x from 'assets/vaunce/image/magnifyplus@2x.png';
import { useAtom } from 'jotai';
import { GradeTextBox } from '../../components/GradeTextBox';
import QrImageBox from '../../components/QrImageBadge';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import MemberQrDialog, { getGradeMemberShip } from '../../dialog/MemberQrDialog';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';

interface MenuType {
	title: string;
	imgUrl: string;
	srcsetUrl: string;
	navigate: () => void;
}

export interface IMyPagePageProps {}
export const MyPagePage: React.FC<IMyPagePageProps> = ({}) => {
	// const [value, setValue] = React.useState(0);

	const [value, setValue] = useAtom(VaunceState.selectMenuTabIdx);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const myInfo = VaunceQueryState.useMyInfoQuery();

	const {
		navigateMyInfo,
		navigateMyMembership,
		navigateVauncePremiumClub,
		navigateCouponList,
		navigateWishList,
		navigateCenterHistory,
		navigatePayHistory,
		navigateCenterDescription,
		navigateMemberShip,
		navigateFacilityRegister,
		navigateReservation,
		navigateEvent,
		navigateNoticeAndAlarm,
		navigateCenterGuide,
		navigateFAQ,
		navigateOneToOne,
		navigateAppGuide,
		navigateTermsOfUse,
		navigatePrivacy,
		navigateOneTicketWithRefund,
	} = useVaunceAppRouter();

	const firstMenuList: MenuType[] = [
		{
			title: '회원정보',
			imgUrl: firstMenuImage1,
			srcsetUrl: firstMenuImage1x,
			navigate: navigateMyInfo,
		},
		{
			title: 'MY 멤버십',
			imgUrl: firstMenuImage2,
			srcsetUrl: firstMenuImage2x,
			navigate: navigateMyMembership,
		},
		{
			title: '바운스 프리미엄',
			imgUrl: firstMenuImage3,
			srcsetUrl: firstMenuImage3x,
			navigate: navigateVauncePremiumClub,
		},
		{
			title: '쿠폰',
			imgUrl: firstMenuImage4,
			srcsetUrl: firstMenuImage4x,
			navigate: navigateCouponList,
		},
		{
			title: '찜리스트',
			imgUrl: firstMenuImage5,
			srcsetUrl: firstMenuImage5x,
			navigate: navigateWishList,
		},
		{
			title: '센터이용내역',
			imgUrl: firstMenuImage6,
			srcsetUrl: firstMenuImage6x,
			navigate: navigateCenterHistory,
		},
		{
			title: '결제내역',
			imgUrl: firstMenuImage7,
			srcsetUrl: firstMenuImage7x,
			navigate: navigatePayHistory,
		},
	];

	const secondMenuList: MenuType[] = [
		{
			title: '센터안내',
			imgUrl: secondMenuImage1,
			srcsetUrl: secondMenuImage1x,
			navigate: navigateCenterDescription,
		},
		{
			title: '멤버십 안내',
			imgUrl: secondMenuImage2,
			srcsetUrl: secondMenuImage2x,
			navigate: navigateMemberShip,
		},
		{
			title: '간편 입장 서비스',
			imgUrl: secondMenuImage3,
			srcsetUrl: secondMenuImage3x,
			navigate: navigateFacilityRegister,
		},
		{
			title: '파티/단체예약',
			imgUrl: secondMenuImage4,
			srcsetUrl: secondMenuImage4x,
			navigate: navigateReservation,
		},
		{
			title: '이벤트',
			imgUrl: secondMenuImage5,
			srcsetUrl: secondMenuImage5x,
			navigate: navigateEvent,
		},
		{
			title: '공지/알림',
			imgUrl: secondMenuImage6,
			srcsetUrl: secondMenuImage6x,
			navigate: navigateNoticeAndAlarm,
		},
	];

	const thirdMenuList: MenuType[] = [
		{
			title: '센터이용 가이드',
			imgUrl: thirdMenuImage1,
			srcsetUrl: thirdMenuImage1x,
			navigate: navigateCenterGuide,
		},
		{
			title: '자주 묻는 질문',
			imgUrl: thirdMenuImage2,
			srcsetUrl: thirdMenuImage2x,
			navigate: navigateFAQ,
		},
		{
			title: '1:1 문의',
			imgUrl: thirdMenuImage3,
			srcsetUrl: thirdMenuImage3x,
			navigate: navigateOneToOne,
		},
		{
			title: '앱 이용안내',
			imgUrl: thirdMenuImage4,
			srcsetUrl: thirdMenuImage4x,
			navigate: navigateAppGuide,
		},
		{
			title: '이용약관',
			imgUrl: thirdMenuImage5,
			srcsetUrl: thirdMenuImage5x,
			navigate: navigateTermsOfUse,
		},
		{
			title: '개인정보처리방침',
			imgUrl: thirdMenuImage6,
			srcsetUrl: thirdMenuImage6x,
			navigate: navigatePrivacy,
		},
		{
			title: '이용권 환불',
			imgUrl: firstMenuImage7,
			srcsetUrl: firstMenuImage7x,
			navigate: navigateOneTicketWithRefund,
		},
	];
	const commonStyle = { fontSize: '1.5rem', fontWeight: 700, px: 0 };

	if (!myInfo) return <></>;

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				sx={{ py: 4, px: 2 }}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<MemberQrDialog
							open={open}
							closeDialog={closeDialog}
							info={myInfo}
						/>
					)}>
					{(openDialog) => (
						<QrImageBox
							sx={{ cursor: 'pointer' }}
							onClick={openDialog}
							qrImage={qrImage}
							imageSize={'lg'}
							badgeContent={
								<QrButton>
									<img
										src={plusImage}
										srcSet={plusImage2x}
										height={18}
										width={18}
									/>
								</QrButton>
							}
							style={{ marginRight: 'auto' }}
						/>
					)}
				</WithDialog>
				<Stack alignItems={'flex-end'}>
					<Stack
						spacing={1}
						alignItems={'inherit'}>
						<GradeTextBox
							grade={myInfo.membership}
							withBackgroundColor
							fontSize={'1.35em'}
						/>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={1}>
							<Typography
								variant='h2'
								fontWeight={700}>
								{myInfo.userName}
							</Typography>
							<Typography variant='h2'>님</Typography>
						</Stack>
					</Stack>

					<Stack
						direction={'row'}
						alignItems={'baseline'}
						spacing={1}>
						<Typography
							fontWeight={700}
							fontSize={'4em'}>
							{myInfo.point}
						</Typography>
						<Typography
							fontWeight={700}
							fontSize={'3em'}>
							p
						</Typography>
					</Stack>
				</Stack>
			</Stack>

			<VauncePinkTabs
				value={value}
				onChange={handleChange}
				variant='fullWidth'>
				<Tab
					label='내 정보관리'
					sx={commonStyle}
				/>
				<Tab
					label='바운스이용하기'
					sx={commonStyle}
				/>
				<Tab
					label='고객센터'
					sx={commonStyle}
				/>
			</VauncePinkTabs>
			<Stack
				flex={1}
				overflow={'inherit'}>
				<TabPanel
					value={value}
					index={0}>
					<ContentBox menuList={firstMenuList} />
				</TabPanel>

				<TabPanel
					value={value}
					index={1}>
					<ContentBox menuList={secondMenuList} />
				</TabPanel>

				<TabPanel
					value={value}
					index={2}>
					<ContentBox menuList={thirdMenuList} />
				</TabPanel>
			</Stack>
		</Stack>
	);
};

interface ContentBoxProps {
	menuList: MenuType[];
}
const ContentBox: React.FC<ContentBoxProps> = ({ menuList }) => {
	return (
		<Box
			flex={1}
			overflow={'auto'}>
			<Grid
				container
				spacing={1}>
				{menuList.map((menu, idx) => (
					<Grid
						item
						xs={6}
						key={idx}>
						<Stack
							data-cy={menu.title}
							direction={'row'}
							alignItems={'center'}
							spacing={2}
							sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.14)', py: 2.5, pl: 2 }}
							onClick={() => {
								menu.navigate();
								loggingEvent(`MY페이지 > ${menu.title}`, 'route', `${menu.title}ClickInMy`);
							}}>
							<img
								src={menu.imgUrl}
								srcSet={menu.srcsetUrl}
								width={36}
								height={36}
							/>
							<Typography
								fontSize={'1.3em'}
								fontWeight={700}>
								{menu.title}
							</Typography>
						</Stack>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

interface QrImageBtnProps {
	info: MyInfoBodyType;
}

export const QrImageBtn = ({ info }: QrImageBtnProps) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<MemberQrDialog
					open={open}
					closeDialog={closeDialog}
					info={info}
				/>
			)}>
			{(openDialog) => (
				<QrButton onClick={openDialog}>
					<img
						src={plusImage}
						srcSet={plusImage2x}
					/>
				</QrButton>
			)}
		</WithDialog>
	);
};

const QrButton = styled('button')(({ theme }) => ({
	width: '30px',
	height: '30px',
	cursor: 'pointer',
	backgroundColor: `${theme.palette.primary.main}`,
	position: 'absolute',
	border: 'none',
	borderRadius: '50%',
}));
